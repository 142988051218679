import { ReactNode, UIEvent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationType, useLocation, useNavigationType, useSearchParams } from 'react-router-dom';

import { Devices } from 'constants/app';
import { POKER_STAR } from 'constants/icons';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import { getAppDevice, getIsIframeEnabled, getOperator } from 'redux/modules/appConfigs/selectors';
import { setIsScrollingToTop, setUSerIsScrolling } from 'redux/modules/appSettings';
import { getIsScrollingToTop, getScrollContentHeight } from 'redux/modules/appSettings/selectors';

import styles from './styles.module.scss';

interface ScrollableContentProps {
  children: ReactNode;
  isSearchVisible: boolean;
}

const ScrollableContent = ({ children, isSearchVisible }: ScrollableContentProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const height = useSelector(getScrollContentHeight);
  const device = useSelector(getAppDevice);
  const operator = useSelector(getOperator);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const isScrollingToTop = useSelector(getIsScrollingToTop);

  const navigationType = useNavigationType();

  const timeout = useRef<NodeJS.Timeout | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const searchScrollHeightValue = useRef<number>(0);

  const isPokerstars = isIframeEnabled && operator === POKER_STAR;
  const query = searchParams.get(PARAMS_SEARCH_KEY);

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    if (isSearchVisible) {
      searchScrollHeightValue.current = event.currentTarget.scrollTop;
      dispatch(setUSerIsScrolling(true));
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        dispatch(setUSerIsScrolling(false));
      }, 200);
    }
  };

  useEffect(() => {
    if (divRef.current && isScrollingToTop) {
      divRef.current.scrollTo(0, 0);
      dispatch(setIsScrollingToTop(false));
    }
  }, [isScrollingToTop]);

  useEffect(() => {
    if (divRef.current) {
      if (isSearchVisible) {
        divRef.current.scrollTo({ top: searchScrollHeightValue.current });
      } else {
        divRef.current.scrollTo(0, 0);
      }
    }
  }, [location.pathname, isSearchVisible]);

  useEffect(() => {
    if (isSearchVisible && divRef.current && navigationType === NavigationType.Push) {
      divRef.current.scrollTo({ top: 0 });
    }
  }, [query, isSearchVisible, navigationType]);

  return (
    <>
      {device === Devices.DESKTOP ? (
        <div
          ref={divRef}
          className={styles.scrollableContent}
          style={{ height: isPokerstars ? 'none' : height }}
          onScroll={handleScroll}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default ScrollableContent;
