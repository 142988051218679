import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices } from 'constants/app';
import { RunnersStatuses } from 'constants/markets';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { IMarketDescription } from 'types/markets';
import { getMarketTypes } from 'utils/market';

import styles from './styles.module.scss';

interface MarketStatusLabelProps {
  /**
   * Market description */
  marketDescription: IMarketDescription;
  /**
   * Runner status */
  runnerStatus?: string | null;
  /**
   * Row container width */
  containerWidth: string;
}

const MarketStatusLabel = ({ marketDescription, runnerStatus, containerWidth }: MarketStatusLabelProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);

  const { isCombinedTotal, isLineMarket } = getMarketTypes(marketDescription.marketType, marketDescription.bettingType);
  let statusLabel = '';

  const isMobile = device === Devices.MOBILE;

  if (!isLineMarket && runnerStatus) {
    if (!isCombinedTotal) {
      statusLabel =
        runnerStatus === RunnersStatuses.WINNER || runnerStatus === RunnersStatuses.PLACED
          ? t(`market.selection.status.${runnerStatus.toLowerCase()}`)
          : '';
    } else {
      statusLabel = t(`market.selection.status.${runnerStatus.toLowerCase()}`);
    }
  }

  return (
    <div
      className={classNames('biab_selection-status-value', styles.runnerStatusLabel)}
      style={{ width: isMobile ? `50%` : containerWidth }}
    >
      {statusLabel}
    </div>
  );
};

export default MarketStatusLabel;
