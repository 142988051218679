import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import PokerImg from 'assets/images/pss-400x114px-negative.png';
import { CookieNames } from 'constants/app';
import { getLanguageCookieName } from 'utils/cookie';

import { translations } from './translations';

import styles from './styles/styles.module.scss';

const SplashScreenOisBundle = () => {
  const [cookies] = useCookies([CookieNames.LANGUAGE]);
  const lang = getLanguageCookieName();

  const {
    title,
    description,
    h1,
    h2,
    firstDescription,
    secondDescription = '',
    thirdDescription = '',
    here,
    link
  } = translations[cookies[CookieNames.LANGUAGE] ?? lang] ?? translations.en;

  return (
    <>
      <Helmet>
        <title data-rh="true">{title}</title>
        <meta data-rh="true" name="description" content={description} id="custom-description" />
        <meta data-rh="true" name="google" content="notranslate" />
        <meta data-rh="true" name="apple-mobile-web-app-capable" content="yes" />
        <meta data-rh="true" name="mobile-web-app-capable" content="yes" />
        <meta data-rh="true" name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta data-rh="true" name="apple-mobile-web-app-title" content="CMS Page" />
      </Helmet>

      <div className={classNames(styles.container)}>
        <div className={styles.wrapperTextSetting}>
          <div id="promotion-banner" className={styles.promotionBanner}>
            <div className={styles.content}>
              <div className={styles['sports-maintenance']}>
                <div className="styles.sports">
                  <div className="message-container">
                    <div className={styles.message}>
                      <h1>{h1}</h1>
                      <h2>{h2}</h2>
                      <p />
                      <p>
                        {`${firstDescription} `}
                        <br />
                        {`${secondDescription} `}
                        <span>
                          <a href={link}>{here}</a>
                        </span>
                        {`${thirdDescription}`}.
                      </p>
                    </div>
                  </div>
                  <div className={styles.logo}>
                    <p>
                      <img alt="Pokerstars Sports" src={PokerImg} data-image="pokerstars-sports" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SplashScreenOisBundle;
