import { EventViewTypes, SportIds } from 'constants/app';
import { BettingTypes } from 'constants/markets';
import { IEventViewSettings } from 'redux/modules/appConfigs/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';

const isCricketMarket = (betItem: TCurrentBet) => betItem.eventTypeId.toString() === SportIds.CRICKET;
const isLineMarket = (betItem: TCurrentBet) => betItem.bettingType === BettingTypes.LINE;

export const isUnderOverLabel = (betItem: TCurrentBet, replaceBackLayWithUnderOver: boolean) =>
  replaceBackLayWithUnderOver && !isCricketMarket(betItem) && isLineMarket(betItem);

const isFancyMarketEnabled = (
  betItem: TCurrentBet,
  eventViewSettings: IEventViewSettings[],
  fancyMarketsOnCricket: boolean
) => {
  const eventSettings = eventViewSettings.find(event => event.eventTypeId === betItem.eventTypeId.toString());

  return fancyMarketsOnCricket && eventSettings?.viewType !== EventViewTypes.TOP_5_VIEW;
};

export const isFancyMarket = (
  betItem: TCurrentBet,
  eventViewSettings: IEventViewSettings[],
  fancyMarketsOnCricket: boolean
) => !!betItem.fancyView && isFancyMarketEnabled(betItem, eventViewSettings, fancyMarketsOnCricket);

export const isYesNoLabel = (
  betItem: TCurrentBet,
  lineBackLayToNoYes: boolean,
  eventViewSettings: IEventViewSettings[],
  fancyMarketsOnCricket: boolean
) =>
  lineBackLayToNoYes &&
  (isFancyMarket(betItem, eventViewSettings, fancyMarketsOnCricket) ||
    (isCricketMarket(betItem) && isLineMarket(betItem)));

export const getEventTime = (marketStartDate: number): string => {
  const date = new Date(marketStartDate);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const getIsRaceType = (eventTypeId: number) =>
  [
    SportIds.GRAY_HOUND_RACING,
    SportIds.HORSE_RACING,
    SportIds.TODAY_GRAY_HOUND_RACING,
    SportIds.TODAY_HORSE_RACING
  ].includes(String(eventTypeId) as '4339' | '7' | 'tc-7' | 'tc-4339');
