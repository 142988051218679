import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AsianPopularLinkEntryTypes } from 'constants/asianView';
import { NONE } from 'constants/icons';
import { IconsConfig } from 'constants/iconsConfig';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { AsianViewPopularLink } from 'redux/modules/asianViewPopularLinks/type';

import styles from './styles.module.scss';

type AsianViewPopularLinkContentProps = {
  link: AsianViewPopularLink;
  arrowClassName?: string;
  noTruncate?: boolean;
};

const AsianViewPopularLinkContent = ({
  link: { iconName, localizedNames, entryType, sportId },
  arrowClassName = '',
  noTruncate = false
}: AsianViewPopularLinkContentProps) => {
  const { t } = useTranslation();

  const translation = useSelector(getTranslation);
  const isMobile = useSelector(getIsMobileAsianView);

  const iconRef = useRef<HTMLElement>(null);

  const popupPrefix = isMobile ? 'mobile.' : '';
  let icon = iconName;
  const isCustomPopUp = entryType === AsianPopularLinkEntryTypes.CUSTOM_POP_UP;
  const isCompetition = entryType === AsianPopularLinkEntryTypes.COMPETITION;

  if (isCustomPopUp) {
    icon = t(`${popupPrefix}custom.pop.up.icon`);
  } else if (isCompetition) {
    icon = IconsConfig.SPORT_ICONS[String(sportId)];
  }

  // 250 - width of right section, 10 - left + right paddings, 8 - gap between name and icon, 12 - arrow icon width
  const maxWidth = 250 - 10 - (icon && icon !== NONE ? 8 : 0) - (iconRef.current?.offsetWidth ?? 0) - 12;

  return (
    <>
      <div className={styles.content__left}>
        {icon && icon !== NONE && <i className={icon} ref={iconRef} />}
        <span style={!noTruncate ? { maxWidth } : undefined} className={!noTruncate ? styles.content__name : undefined}>
          {localizedNames[translation]}
        </span>
      </div>
      {!isCustomPopUp && <i className={classNames('fa2 fa2-arrow-right', arrowClassName)} />}
    </>
  );
};

export default AsianViewPopularLinkContent;
