import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MatchStatuses } from 'constants/app';
import {
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesAddedRegularTime,
  getMarketPricesAsianViewElapsedRegularTime,
  getMarketPricesAwayScore,
  getMarketPricesHomeScore,
  getMarketPricesMatchStatus,
  getMarketPricesRegularTime
} from 'redux/modules/marketsPrices/selectors';

import styles from './styles.module.scss';

interface AsianViewInPlayTimeProps {
  marketId: string;
}

const AsianViewInPlayTime = ({ marketId }: AsianViewInPlayTimeProps) => {
  const { t } = useTranslation();

  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const homeScore = useSelector(getMarketPricesHomeScore(marketId));
  const awayScore = useSelector(getMarketPricesAwayScore(marketId));
  const elapsedRegularTime = useSelector(getMarketPricesAsianViewElapsedRegularTime(marketId));
  const regularTime = useSelector(getMarketPricesRegularTime(marketId));
  const addedTime = useSelector(getMarketPricesAddedRegularTime(marketId));
  const matchStatus = useSelector(getMarketPricesMatchStatus(marketId));

  const isLiveScoreData =
    isInPlay &&
    homeScore !== undefined &&
    awayScore !== undefined &&
    (elapsedRegularTime !== undefined || regularTime !== undefined);

  if (!isLiveScoreData) {
    return null;
  }

  const isHalfTime = matchStatus === MatchStatuses.HALF_TIME;
  const isFinished = matchStatus === MatchStatuses.END;
  const isFirstHalf = matchStatus === MatchStatuses.FIRST_HALF;
  const isSecondHalf = matchStatus === MatchStatuses.SECOND_HALF;
  let label = '';

  if (isHalfTime || isFinished) {
    label = `${t(`asianView.labels.inPlayScore.${isHalfTime ? 'HT' : 'END'}`)}`;
  } else if (isFirstHalf || isSecondHalf) {
    label = `${t(`asianView.labels.inPlayScore.${isFirstHalf ? '1H' : '2H'}`)} ${elapsedRegularTime}'${
      addedTime ? `+${addedTime}'` : ''
    }`;
  }

  return <p className={styles.time}>{label}</p>;
};

export default AsianViewInPlayTime;
