import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ResponsibleBettingOperatorLimits, TODAY_BUTTON_DETECTION_CLASS_NAME } from 'constants/responsibleBetting';
import {
  getIsEnabledBettingDisplaySetting,
  getMultiCurrencySupported,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { updateResponsibleBettingSettings } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import {
  BettingDisplaySettingEnabled,
  ResponsibleBettingDisplayedContent,
  ResponsibleBettingEndDate,
  ResponsibleBettingLimit,
  ResponsibleBettingLimitEnabled,
  ResponsibleBettingOperatorLimit,
  ResponsibleBettingStartDate,
  TLimitError
} from 'types/responsibleBetting';
import { applySelectedTimezoneToDate, applySelectedTimezoneToUTCDate } from 'utils/date';
import { getInitDate, getInitLimit } from 'utils/responsibleBetting';
import { checkIsNumber } from 'utils/string';

interface CalendarsParams {
  startDateKey: ResponsibleBettingStartDate;
  endDateKey: ResponsibleBettingEndDate;
  operatorLimitKey: ResponsibleBettingOperatorLimit;
}

export const useResponsibleBettingCalendars = ({ startDateKey, endDateKey, operatorLimitKey }: CalendarsParams) => {
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const [startDate, setStartDate] = useState<Date | null>(
    getInitDate({ rbSettings, dateKey: startDateKey, operatorLimitKey })
  );
  const [endDate, setEndDate] = useState<Date | null>(
    getInitDate({ rbSettings, dateKey: endDateKey, operatorLimitKey })
  );

  const handleSetStartDate = (date: Date, event: SyntheticEvent) => {
    const isTodayButtonClicked =
      !!event?.target && (event.target as Element).classList.contains(TODAY_BUTTON_DETECTION_CLASS_NAME);

    if (!isTodayButtonClicked) {
      const now = applySelectedTimezoneToDate(new Date(), timezone, timezoneCookieEnabled);
      now.setSeconds(0);
      now.setMilliseconds(0);
      date.setSeconds(0);
      const dateTime = date.getTime();

      if ((!endDate || dateTime < endDate.getTime()) && dateTime >= now.getTime()) {
        setStartDate(date);
      }
    }
  };

  const handleSetEndDate = (date: Date, event: SyntheticEvent) => {
    const now = applySelectedTimezoneToDate(new Date(), timezone, timezoneCookieEnabled);
    now.setSeconds(0);
    now.setMilliseconds(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    const dateTime = date.getTime();
    const nowTime = now.getTime();
    const isTodayButtonClicked =
      !!event?.target && (event.target as Element).classList.contains(TODAY_BUTTON_DETECTION_CLASS_NAME);

    if ((!startDate || dateTime > startDate.getTime()) && dateTime >= now.getTime()) {
      if (!isTodayButtonClicked) {
        setEndDate(date);
      }

      if (startDate && startDate.getTime() < nowTime) {
        setStartDate(now);
      }
    }
  };

  const filterStartDateTime = (date: Date) => {
    const curDate = applySelectedTimezoneToDate(new Date(), timezone, timezoneCookieEnabled);
    const selectedDateTime = date.getTime();

    return curDate.getTime() < selectedDateTime;
  };

  const filterEndDateTime = (date: Date) => {
    const currentTime = applySelectedTimezoneToDate(new Date(), timezone, timezoneCookieEnabled).getTime();

    if (startDate) {
      const startDateTime = startDate.getTime();
      const endDateTime = date.getTime();

      if (currentTime > startDateTime) {
        return currentTime < endDateTime;
      }

      return startDateTime < endDateTime;
    }

    return true;
  };

  const handleClickStartDateToday = () => {
    const date = applySelectedTimezoneToDate(new Date(), timezone, timezoneCookieEnabled);
    date.setSeconds(0);
    date.setMilliseconds(0);

    setStartDate(date);

    if (endDate && endDate < date) {
      setEndDate(date);
    }
  };

  const handleClickEndDateToday = () => {
    const date = applySelectedTimezoneToDate(new Date(), timezone, timezoneCookieEnabled);
    date.setSeconds(0);
    date.setMilliseconds(0);

    if (!startDate || startDate <= date) {
      setEndDate(date);
    }
  };

  useEffect(() => {
    setStartDate(getInitDate({ rbSettings, dateKey: startDateKey, operatorLimitKey }));
    setEndDate(getInitDate({ rbSettings, dateKey: endDateKey, operatorLimitKey }));
  }, [JSON.stringify(rbSettings)]);

  return {
    handleSetStartDate,
    handleSetEndDate,
    filterStartDateTime,
    filterEndDateTime,
    setStartDate,
    setEndDate,
    handleClickStartDateToday,
    handleClickEndDateToday,
    startDate,
    endDate
  };
};

interface LimitParams {
  limitKey: ResponsibleBettingLimit;
  max: number;
  min: number;
  operatorLimitKey?: ResponsibleBettingOperatorLimit;
}

export const useResponsibleBettingLimit = ({ limitKey, max, min, operatorLimitKey }: LimitParams) => {
  const rbSettings = useSelector(getResponsibleBettingSettings);

  const [limit, setLimit] = useState<string>(getInitLimit(rbSettings, limitKey, operatorLimitKey));
  const [limitError, setLimitError] = useState<TLimitError>({
    show: false,
    label: '',
    options: {}
  });

  const handleChangeLimit = (event: ChangeEvent<HTMLInputElement>) => {
    const { valueAsNumber, value } = event.target;

    if (valueAsNumber < min) {
      setLimitError({
        show: true,
        label: 'responsibleBetting.validation.minNumber',
        options: { N: min }
      });
    } else if (isNaN(valueAsNumber) || !checkIsNumber(value)) {
      setLimitError({ show: true, label: 'responsibleBetting.validation.invalidNumber', options: {} });
    } else if (parseInt(value) > max) {
      setLimitError({
        show: true,
        label: 'responsibleBetting.validation.maxNumber',
        options: { N: max }
      });
    } else if (limitError.show) {
      setLimitError({ show: false, label: '', options: {} });
    }

    setLimit(value);
  };

  useEffect(() => {
    setLimit(getInitLimit(rbSettings, limitKey, operatorLimitKey));
  }, [JSON.stringify(rbSettings)]);

  return { limit, handleChangeLimit, limitError, setLimit, setLimitError };
};

interface DeactivationParams {
  endDateKey: ResponsibleBettingEndDate;
  limitEnabledKey: ResponsibleBettingLimitEnabled;
  operatorLimitKey: ResponsibleBettingOperatorLimit;
  displayEnabledKey: BettingDisplaySettingEnabled;
  type: ResponsibleBettingDisplayedContent;
}

export const useResponsibleBettingDeactivationCounter = ({
  limitEnabledKey,
  endDateKey,
  operatorLimitKey,
  displayEnabledKey,
  type
}: DeactivationParams) => {
  const dispatch = useDispatch();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const multiCurrencySupported = useSelector(getMultiCurrencySupported);
  const isSettingEnabled = useSelector(getIsEnabledBettingDisplaySetting(displayEnabledKey));
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const operatorLimit = operatorLimitKey ? rbSettings?.[operatorLimitKey] : false;
  const databaseEndDate = rbSettings?.rgTools?.[endDateKey];
  const isLimitEnabled = rbSettings?.rgTools?.[limitEnabledKey];

  const deactivateLimit = () => {
    if (!operatorLimit && rbSettings?.rgTools && isLimitEnabled) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            [limitEnabledKey]: false,
            type
          },
          type
        })
      );
    }
  };

  useEffect(() => {
    if (isLoggedIn && isSettingEnabled && !operatorLimit && databaseEndDate && isLimitEnabled) {
      // Exposure and Loss limits not supported if multiCurrencySupported is enabled
      if (
        operatorLimitKey !== ResponsibleBettingOperatorLimits.LossLimit &&
        (operatorLimitKey !== ResponsibleBettingOperatorLimits.ExposureLimit || !multiCurrencySupported)
      ) {
        let timeout: NodeJS.Timeout | undefined;
        const now = applySelectedTimezoneToDate(new Date(), timezone, timezoneCookieEnabled).getTime();
        const endDate = applySelectedTimezoneToUTCDate(databaseEndDate, timezone, timezoneCookieEnabled).getTime();

        if (now >= endDate) {
          deactivateLimit();
        } else {
          timeout = setTimeout(() => {
            deactivateLimit();
          }, endDate - now);
        }

        return () => {
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }
    }
  }, [
    databaseEndDate,
    isLimitEnabled,
    operatorLimit,
    multiCurrencySupported,
    isLoggedIn,
    isSettingEnabled,
    timezone,
    timezoneCookieEnabled
  ]);
};
