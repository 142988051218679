import { TFunction } from 'react-i18next';
import { isNull, isString, isUndefined, sortBy, values } from 'lodash';

import { BetsStatusesTypes } from 'constants/app';
import {
  ASIAN_MOBILE_NAVIGATION,
  AsianBettingActions,
  AsianCorrectScoreRunners,
  AsianEventsCounters,
  AsianMobileNavTypes,
  AsianViewMarketLinks,
  AsianViewTimeFilters,
  AsianViewTimeFrames,
  COMPETITION_KEY_DIVIDER,
  LINE_VIEW_FILTER_ICONS,
  LINE_VIEW_FILTER_LABELS,
  SORTING_FILTER_ICONS,
  SORTING_FILTER_LABELS
} from 'constants/asianView';
import { EXPOSURE_LIMIT_ERROR_CODE, LOSS_LIMIT_ERROR_CODE } from 'constants/placement';
import { TCurrencySteps } from 'redux/modules/appConfigs/type';
import { ISportEventsCounter } from 'redux/modules/asianSportsNavigation/type';
import { TAsianViewMarket } from 'redux/modules/asianView/type';
import {
  TAsianSelectedBet,
  TAsianSelectedBetIdentifierParams,
  TPlacedBetException
} from 'redux/modules/asianViewBetslip/type';
import { TAsianViewLeaguesFilterData } from 'redux/modules/asianViewLeaguesFilter/type';
import { BetsStatusesType } from 'redux/modules/betsStatuses/type';
import { AsianLineViewType, AsianSortingType, TAsianMobileNavigationItem, TCorrectScoreRunners } from 'types/asianView';
import { THandicap, TProfit, TSize } from 'types/bets';
import { addDays } from 'utils/date';
import { round } from 'utils/liability';

export const convertSortingFilterToDropdown = (t: TFunction) => {
  return Object.keys(SORTING_FILTER_LABELS).map((key, index) => ({
    id: index,
    value: key,
    icon: `${SORTING_FILTER_ICONS[key as AsianSortingType]}`,
    label: `${t(SORTING_FILTER_LABELS[key as AsianSortingType])}`
  }));
};

export const convertLineViewFilterToDropdown = (t: TFunction) => {
  return Object.keys(LINE_VIEW_FILTER_LABELS).map((key, index) => ({
    id: index,
    value: key,
    icon: `${LINE_VIEW_FILTER_ICONS[key as AsianLineViewType]}`,
    label: `${t(LINE_VIEW_FILTER_LABELS[key as AsianLineViewType])}`
  }));
};

export const mapAsianViewLeaguesByColumns = ({
  domainLanguage,
  columnsNumber,
  leagues
}: {
  domainLanguage: string;
  columnsNumber: number;
  leagues: Record<string, TAsianViewLeaguesFilterData>;
}) => {
  const leaguesList = sortBy(values(leagues), ({ name, translations }) => {
    return translations[domainLanguage] ?? name;
  });
  const isTwoColumn = columnsNumber === 2;

  if (isTwoColumn) {
    const leftColumn: TAsianViewLeaguesFilterData[] = [];
    const rightColumn: TAsianViewLeaguesFilterData[] = [];

    leaguesList.forEach((league, index) => {
      if (index % 2 === 0) {
        leftColumn.push(league);
      } else {
        rightColumn.push(league);
      }
    });

    return [leftColumn, rightColumn];
  } else {
    return [leaguesList];
  }
};

export const mapAsianViewLeaguesToStore = (leagues: Record<string, TAsianViewLeaguesFilterData>) => {
  const selectedLeagues: string[] = [];
  let isAllSelected = true;

  values(leagues).forEach(({ id, isSelected }) => {
    if (isSelected) {
      selectedLeagues.push(id);
    } else {
      isAllSelected = false;
    }
  });

  return { ids: isAllSelected ? [] : selectedLeagues, isAll: isAllSelected };
};

export const mapCorrectScoreRunners = ({ runners }: TAsianViewMarket) => {
  const homeRunners: TCorrectScoreRunners = { type: AsianCorrectScoreRunners.HOME, runners: [] };
  const drawRunners: TCorrectScoreRunners = { type: AsianCorrectScoreRunners.DRAW, runners: [] };
  const awayRunners: TCorrectScoreRunners = { type: AsianCorrectScoreRunners.AWAY, runners: [] };
  const otherRunners: TCorrectScoreRunners = {
    type: runners.some(({ name }) => name.toLowerCase().includes('any other'))
      ? AsianCorrectScoreRunners.ANY_OTHER
      : AsianCorrectScoreRunners.OTHER,
    runners: []
  };

  runners.forEach(runner => {
    const isScore = runner.name.includes('-');

    if (isScore) {
      const scores = runner.name.split('-');
      const homeScore = +scores[0].trim();
      const awayScore = +scores[1].trim();

      if (homeScore > awayScore) {
        homeRunners.runners.push(runner);
      } else if (awayScore > homeScore) {
        awayRunners.runners.push(runner);
      } else {
        drawRunners.runners.push(runner);
      }
    } else {
      otherRunners.runners.push(runner);
    }
  });

  homeRunners.runners = sortBy(homeRunners.runners, ({ name }) => +name.split('-')?.[1]);
  awayRunners.runners = sortBy(awayRunners.runners, ({ name }) => +name.split('-')?.[0]);

  return { homeRunners, drawRunners, awayRunners, otherRunners };
};

export const sortCorrectScoreMarkets = (markets: TAsianViewMarket[]) => {
  return sortBy(markets, 'marketType').reverse();
};

export const getCompetitionIdFromKey = (competitionKey: string) => competitionKey.split(COMPETITION_KEY_DIVIDER)[0];

export const getAsianRunnerName = (runnerName: string, t: TFunction) => {
  switch (runnerName.trim()) {
    case 'Any Other Home Win':
      return t('asianView.labels.market.name.AOH');
    case 'Any Other Away Win':
      return t('asianView.labels.market.name.AOA');
    case 'Any Other Draw':
      return t('asianView.labels.market.name.AOD');
    case 'Any unquoted':
      return t('asianView.labels.market.name.AU');
    case 'Any Unquoted':
      return t('asianView.labels.market.name.AU');
    default:
      return runnerName;
  }
};

export const getSelectedBetIdentifier = ({
  marketId,
  selectionId,
  handicap,
  betType
}: TAsianSelectedBetIdentifierParams) => `${marketId}_${selectionId}_${handicap ?? 0}_${betType}`;

export const getBetPayout = ({ profit = 0, size = 0 }: { profit?: TProfit; size?: TSize }) =>
  round(+(profit || 0) + +(size || 0));

export const getHeadingSportId = ({
  isSingleView,
  singleViewSportId,
  isFavouriteView,
  favouriteSportId,
  isPopularLinkView,
  popularLinkSportId
}: {
  isFavouriteView: boolean;
  favouriteSportId: string | null;
  isPopularLinkView: boolean;
  popularLinkSportId: string | null;
  isSingleView: boolean;
  singleViewSportId: string | null;
}) => {
  if (isFavouriteView) {
    return favouriteSportId;
  }

  if (isPopularLinkView) {
    return popularLinkSportId;
  }

  if (isSingleView) {
    return singleViewSportId;
  }

  return '';
};

export const getDayLabel = (day: Date, t: TFunction) => {
  const month = t(`dates.month.${day.getMonth()}`);
  const weekDay = t(`dates.shortDay.${day.getDay()}`);

  return `${day.getDate()} ${month} (${weekDay})`;
};

export const getEarlyDaysList = (date: Date, t: TFunction) => {
  return [
    { timeFrame: AsianViewTimeFrames.DAY_1, label: getDayLabel(addDays(date, 1), t) },
    { timeFrame: AsianViewTimeFrames.DAY_2, label: getDayLabel(addDays(date, 2), t) },
    { timeFrame: AsianViewTimeFrames.DAY_3, label: getDayLabel(addDays(date, 3), t) },
    { timeFrame: AsianViewTimeFrames.DAY_4, label: getDayLabel(addDays(date, 4), t) },
    { timeFrame: AsianViewTimeFrames.DAY_5, label: getDayLabel(addDays(date, 5), t) },
    { timeFrame: AsianViewTimeFrames.DAY_6, label: getDayLabel(addDays(date, 6), t) },
    { timeFrame: AsianViewTimeFrames.DAY_7, label: getDayLabel(addDays(date, 7), t) }
  ];
};

export const getAsianMobileNavigation = ({
  eventsCounter,
  date,
  t
}: {
  eventsCounter: ISportEventsCounter;
  date: Date;
  t: TFunction;
}) => {
  const navItemList: TAsianMobileNavigationItem[] = [];

  ASIAN_MOBILE_NAVIGATION.forEach(navItem => {
    const navItemCounter =
      navItem.counter !== AsianEventsCounters.numberOfEarlyEvents
        ? eventsCounter?.[navItem.counter] ?? 0
        : eventsCounter?.[navItem.counter]?.[AsianViewTimeFrames.ALL] ?? 0;

    if (navItemCounter || navItem.type === AsianMobileNavTypes.LIVE) {
      const updatedNavItem: TAsianMobileNavigationItem = {
        ...navItem,
        label: t(navItem.label),
        counterValue: navItemCounter,
        subNavItems: []
      };

      if (navItem.type === AsianMobileNavTypes.EARLY) {
        getEarlyDaysList(date, t).forEach(day => {
          const dayCounter = eventsCounter?.[AsianEventsCounters.numberOfEarlyEvents]?.[day.timeFrame] ?? 0;
          updatedNavItem.subNavItems?.push({
            ...day,
            type: AsianMobileNavTypes.EARLY,
            counterValue: dayCounter,
            counter: AsianEventsCounters.numberOfEarlyEvents,
            subNavItems: []
          });
        });
      }
      navItemList.push(updatedNavItem);
    }
  });

  return navItemList;
};

export const isPlacedBetStatusPlaced = (status?: BetsStatusesType) =>
  status &&
  (
    [
      BetsStatusesTypes.PLACED,
      BetsStatusesTypes.MATCHED,
      BetsStatusesTypes.CANCELLED,
      BetsStatusesTypes.EXPIRED
    ] as BetsStatusesType[]
  ).includes(status);

export const getSizeStepValue = ({
  currencySteps,
  sizeValue,
  step
}: {
  currencySteps: TCurrencySteps[];
  sizeValue: TSize;
  step: number;
}) => {
  const valueInt = +(sizeValue || 0);
  let subSizeStep = 0;
  let addSizeStep = 0;

  currencySteps.forEach(curStep => {
    if (valueInt >= curStep.min && (curStep.max === null || valueInt < curStep.max)) {
      addSizeStep = step > curStep.step ? step : curStep.step;
    }
    if (valueInt > curStep.min && (curStep.max === null || valueInt <= curStep.max)) {
      subSizeStep = step > curStep.step ? step : curStep.step;
    }
  });

  return { subStep: subSizeStep, addStep: addSizeStep };
};

export const generateHandicapString = (value: THandicap | null | undefined, isShort?: boolean | undefined) => {
  const handicap = +(value ?? 0);
  const isRange = !isShort && handicap % 0.5 !== 0;
  const sign = handicap > 0 ? '+' : '';

  if (isRange) {
    const lowerBound = handicap > 0 ? handicap - 0.25 : handicap + 0.25;
    const upperBound = handicap > 0 ? handicap + 0.25 : handicap - 0.25;
    let result = `${lowerBound > 0 ? '+' : ''}${lowerBound.toFixed(lowerBound ? 1 : 0)}`;

    if (upperBound !== lowerBound) {
      result += ` & ${upperBound > 0 ? '+' : ''}${upperBound.toFixed(upperBound ? 1 : 0)}`;
    }

    return result;
  }

  if (isShort && handicap % 0.25 === 0) {
    return `${sign}${handicap}`;
  }

  return `${sign}${handicap.toFixed(handicap ? 1 : 0)}`;
};

export const replaceHandicaps = (
  selectionName = '',
  handicap: number | string | null = 0,
  adjHandicap: number | null
) => {
  if (isNull(adjHandicap) || isUndefined(adjHandicap)) {
    return selectionName;
  }

  handicap = +(handicap || 0);

  let updatedName = selectionName.replace(generateHandicapString(handicap), generateHandicapString(adjHandicap));

  if (adjHandicap === 0) {
    updatedName = updatedName.replace(/[+-]0/g, '0');
  }

  return updatedName;
};

export const getIsValidTimeFilter = (timeFilter: string) => {
  return (
    timeFilter === AsianViewTimeFilters.Today ||
    timeFilter === AsianViewTimeFilters.Early ||
    timeFilter === AsianViewTimeFilters.LIVE
  );
};

export const getIsValidMarketLink = (marketLink: string) => {
  return (
    marketLink === AsianViewMarketLinks.HDP_AND_OU ||
    marketLink === AsianViewMarketLinks.MATCH_ODDS_HALF_TIME ||
    marketLink === AsianViewMarketLinks.CORRECT_SCORE ||
    marketLink === AsianViewMarketLinks.ODD_EVEN ||
    marketLink === AsianViewMarketLinks.HALF_TIME_FULL_TIME ||
    marketLink === AsianViewMarketLinks.TOTAL_GOALS ||
    marketLink === AsianViewMarketLinks.OUTRIGHT
  );
};

export const isAsianFullName = (runnerName: string) => {
  return ['Any Other Home Win', 'Any Other Away Win', 'Any Other Draw', 'Any unquoted', 'Any Unquoted'].includes(
    runnerName.trim()
  );
};

export const isAVResponsibleGamblingError = (error: TPlacedBetException) =>
  !isString(error) && [EXPOSURE_LIMIT_ERROR_CODE, LOSS_LIMIT_ERROR_CODE].includes(error?.id ?? '');

export const getIsAsianViewBetActive = ({
  bet,
  ignoreBettingAction
}: {
  bet: TAsianSelectedBet;
  ignoreBettingAction?: boolean;
}) => {
  return (
    !bet.isDisabled &&
    !!bet.profit &&
    !!bet.size &&
    Number(bet.profit) !== 0 &&
    Number(bet.size) !== 0 &&
    !bet.validationMessage &&
    bet.isValid !== false &&
    (!bet.offerId || bet.betStatusError || bet.betStatus === BetsStatusesTypes.CANCELLED) &&
    (ignoreBettingAction || bet.bettingAction !== AsianBettingActions.PROGRESS)
  );
};
