import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface DatepickerCustomHeaderProps {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  customHeaderCount?: number;
  monthsShown?: number;
  minDate?: Date;
  maxDate?: Date;
  isMobile: boolean;
}

const DatePickerCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  customHeaderCount,
  monthsShown,
  minDate,
  maxDate,
  isMobile
}: DatepickerCustomHeaderProps) => {
  const { t } = useTranslation();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();
  const hasPrevButton = monthsShown === 1 || (monthsShown === 2 && customHeaderCount === 0);
  const hasNextButton = monthsShown === 1 || (monthsShown === 2 && customHeaderCount === 1);

  // Disable previous button if going back exceeds minDate
  const prevDisabled =
    minDate &&
    (currentYear < minDate.getFullYear() ||
      (currentYear === minDate.getFullYear() && currentMonth <= minDate.getMonth()));

  const navigateNext =
    minDate &&
    (currentYear < minDate.getFullYear() ||
      (currentYear === minDate.getFullYear() && currentMonth < minDate.getMonth()));

  // Disable next button if going forward exceeds today's month
  const nextDisabled =
    maxDate &&
    (currentYear > maxDate.getFullYear() ||
      (currentYear === maxDate.getFullYear() && currentMonth >= maxDate.getMonth()));

  const navigatePrev =
    maxDate &&
    (currentYear > maxDate.getFullYear() ||
      (currentYear === maxDate.getFullYear() && currentMonth > maxDate.getMonth()));

  useEffect(() => {
    if (navigateNext && monthsShown === 2 && customHeaderCount === 0) {
      increaseMonth();
    }
  }, [navigateNext]);

  useEffect(() => {
    if (navigatePrev && monthsShown === 2 && customHeaderCount === 1) {
      decreaseMonth();
    }
  }, [navigatePrev]);

  return (
    <div className={classNames('ui-datepicker-header', styles.datepickerCustomheader)}>
      {hasPrevButton && (
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled || prevDisabled}>
          <i
            className={classNames(
              'ui-datepicker-arrow-icon biab_custom-icon-chevron-left',
              styles.datepickerCustomheader__arrowLeft,
              {
                biab_disabled: prevMonthButtonDisabled || prevDisabled
              }
            )}
          />
        </button>
      )}
      <div className={classNames('ui-datepicker-title', { [styles.datepickerCustomheader__mobileTitle]: isMobile })}>
        {t(`dates.shortMonth.${[date.getMonth()]}`)} &nbsp;
      </div>
      <div className={classNames('ui-datepicker-title', { [styles.datepickerCustomheader__mobileTitle]: isMobile })}>
        {date.getFullYear()}
      </div>
      {hasNextButton && (
        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled || nextDisabled}>
          <i
            className={classNames(
              'ui-datepicker-arrow-icon biab_custom-icon-chevron-right',
              styles.datepickerCustomheader__arrowRight,
              {
                biab_disabled: nextMonthButtonDisabled || nextDisabled
              }
            )}
          />
        </button>
      )}
    </div>
  );
};

export default DatePickerCustomHeader;
