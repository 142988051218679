import { useSelector } from 'react-redux';

import { MarketStatuses } from 'constants/app';
import useOddsDisplayFormat from 'hooks/useOddsDisplayFormat';
import { TAsianSelectedBet } from 'redux/modules/asianViewBetslip/type';
import { TAsianMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip/type';
import { TAsianQuickBet } from 'redux/modules/asianViewQuickBetting/type';
import { getMarketPricesStatusById } from 'redux/modules/marketsPrices/selectors';
import { getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';

const useAsianOdds = ({ bet }: { bet: TAsianSelectedBet | TAsianMobileSelectedBet | TAsianQuickBet }) => {
  const userAutoRefresh = useSelector(getUserAsianViewAutoRefresh);
  const odds = useOddsDisplayFormat(bet);

  const status = useSelector(getMarketPricesStatusById(bet.marketId));
  const isSuspended = status === MarketStatuses.SUSPENDED;
  const isDisabledBet = userAutoRefresh && (!odds || isSuspended);
  const hasAutoRefresh = userAutoRefresh && !!odds && odds != bet.price && !(!odds || isSuspended);

  return hasAutoRefresh && odds && !isDisabledBet ? odds : bet.price;
};

export default useAsianOdds;
