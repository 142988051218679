import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Dropdown from 'components/Dropdown';
import { componentsBranding } from 'constants/branding';
import { MyBetsLocations, NavigationButtons, PLBetTypes, SEARCH_CURRENCY_KEY, TExchangeTypes } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { getCurrenciesDropdownItems } from 'redux/modules/appConfigs/selectors';
import { getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { fetchProfitGames, fetchProfitSports, setMultiCurrencyValue } from 'redux/modules/myBets';
import { getMyBetsMultiCurrencyValue } from 'redux/modules/myBets/selectors';
import { DropdownClasses, DropdownItem } from 'types';
import { PLBetType } from 'types/myBets';

interface MultiCurrencyDropdownProps {
  activePLTab?: PLBetType | TExchangeTypes;
  customClasses?: DropdownClasses;
}

const MultiCurrencyDropdown = ({ activePLTab, customClasses }: MultiCurrencyDropdownProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { page = '' } = useParams<{ page?: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { refreshData } = useMyBetsPagesNavigation();

  const currenciesDropdownItems = useSelector(getCurrenciesDropdownItems);
  const isCancellingBets = useSelector(getIsCancelActionsInProgress);

  const { selectedTab } = useAccountUrlParams();
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);

  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);
  const isMyBetsPage = location.pathname.includes(`${MyBetsLocations.MyBets}/`);
  const disabled = isMyBetsPage && isCancellingBets;
  const currenciesItems = isMyBetsPage
    ? (
        [
          { id: 'all', value: 'all', label: 'account.currencies.allCurrencies', isLabelTranslatable: true }
        ] as DropdownItem[]
      ).concat(currenciesDropdownItems)
    : currenciesDropdownItems;
  const defaultValue = currenciesItems.find(({ value }) =>
    searchCurrency ? value === searchCurrency : value === multiCurrencyValue
  );

  const handleSelectCurrency = ({ id, value }: DropdownItem) => {
    if (selectedTab === NavigationButtons.ProfitAndLoss) {
      if (activePLTab) {
        if (activePLTab === PLBetTypes.Sports) {
          dispatch(fetchProfitSports(value));
        } else if (activePLTab === PLBetTypes.Games) {
          dispatch(fetchProfitGames(value));
        }
      }
    }

    refreshData({ currency: value });

    searchParams.set(SEARCH_CURRENCY_KEY, value);
    setSearchParams(searchParams);
    dispatch(setMultiCurrencyValue(id === 'all' ? '' : value));

    if (page !== '1') {
      const urlWithoutPageIndex = location.pathname.substring(0, location.pathname.lastIndexOf('/'));

      navigate(`${urlWithoutPageIndex}/1`);
    }
  };

  const dropdownBranding = {
    dropdownItems: componentsBranding.DROPDOWN_LIST,
    dropdownItem: componentsBranding.DROPDOWN_ITEM,
    selected: componentsBranding.ACTIVE
  };

  return (
    <Dropdown
      defaultValue={defaultValue}
      data={currenciesItems}
      onSelectOption={handleSelectCurrency}
      classes={customClasses}
      brandingClasses={dropdownBranding}
      disabled={disabled}
    />
  );
};

export default MultiCurrencyDropdown;
