import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import SEO from 'components/SEO';
import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import { CookieNames, ElementNames, MetaPages } from 'constants/app';
import { PERIOD_TABS } from 'constants/date';
import useElementSize from 'hooks/useElementSize';
import { getHorseRacingSportPageInterval, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { fetchRaces } from 'redux/modules/racing';
import { getRacingSportLoading, getTabs } from 'redux/modules/racing/selectors';

import Header from './components/Header';
import Tabs from './components/Tabs';

import styles from './styles.module.scss';

const RacingSport = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([CookieNames.RACING_PERIOD]);
  const { sportId = '' } = useParams();
  const { t } = useTranslation();

  const popularMarketsHeight = useSelector(getElementHeightByName(ElementNames.POPULAR_MARKETS_HEIGHT));
  const tabs = useSelector(getTabs);
  const timezone = useSelector(getTimezone);
  const horseRacingSportPageInterval = useSelector(getHorseRacingSportPageInterval);
  const language = useSelector(getLanguage);
  const loading = useSelector(getRacingSportLoading);

  const racingPeriod = cookies[CookieNames.RACING_PERIOD] as string;

  const [timeRange, setTimeRange] = useState(racingPeriod || PERIOD_TABS[0].id);

  const [componentRef, { height: componentHeight }] = useElementSize<HTMLDivElement>();

  useEffect(() => {
    dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: componentHeight + popularMarketsHeight }));
  }, [componentHeight, popularMarketsHeight]);

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    dispatch(fetchRaces({ sportId, timeRange }));

    const interval = setInterval(() => {
      dispatch(fetchRaces({ sportId, timeRange, noUpdateLoading: true }));
    }, horseRacingSportPageInterval);
    return () => clearInterval(interval);
  }, [sportId, timeRange, language, timezone]);

  const changePeriod = (id: string) => {
    setTimeRange(id);
    setCookie(CookieNames.RACING_PERIOD, id);
  };

  const getContent = () => {
    if (loading) {
      return <MiddleSectionPageSkeleton itemsCount={6} isAbsolute={false} />;
    }

    if (tabs.length) {
      return <Tabs tabs={tabs} timeRange={timeRange} sportId={sportId} />;
    }

    return <div className={styles.noMarkets}>{t('horseRacingPage.noMarketsToDisplay')}</div>;
  };

  return (
    <>
      <SEO page={MetaPages.SPORT} id={sportId} />
      <div className="biab_horse-racing-page-wrapper js-content-wrapper">
        <div ref={componentRef}>
          <Header timeRange={timeRange} setTimeRange={changePeriod} />
          {getContent()}
        </div>
      </div>
    </>
  );
};

export default RacingSport;
