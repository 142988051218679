import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AsianLineViewTypes } from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import useAsianViewLineType from 'hooks/useAsianViewLineType';
import { TAsianViewMarket } from 'redux/modules/asianView/type';
import { getHighlightedSelectionByMarketId } from 'redux/modules/marketsPrices/selectors';
import { FTSL } from 'types/markets';

import styles from './styles.module.scss';

interface SelectionNamesProps {
  home: string;
  away: string;
  draw?: string;
  market?: TAsianViewMarket;
  isFirstEvent?: boolean;
}

const SelectionNames = ({ home, away, draw, market, isFirstEvent = false }: SelectionNamesProps) => {
  const highlightedSelection = useSelector(getHighlightedSelectionByMarketId(market ? market.id : ''));

  const lineViewFilter = useAsianViewLineType();
  const isLayBetting = useAsianLayBetting();
  const isDoubleLine = lineViewFilter === AsianLineViewTypes.DOUBLE;

  const isHomeTeamHighlighted = highlightedSelection == FTSL.HOME;
  const isAwayTeamHighlighted = highlightedSelection == FTSL.AWAY;

  return (
    <div
      className={classNames('biab_asian-view-event-selections-names', styles.selections, {
        [styles.selections__layBetting]: isLayBetting && isFirstEvent && isDoubleLine
      })}
    >
      <div
        className={classNames('biab_asian-view-event-selections-home', styles.selection__name, styles.selection__home, {
          [styles.selection__active]: isHomeTeamHighlighted,
          [branding.FAVOURITE_TEAM]: isHomeTeamHighlighted
        })}
      >
        <span data-tooltip-id={'tooltip'} data-tooltip-html={home || ''}>
          {home || ''}
        </span>
      </div>
      <div
        className={classNames('biab_asian-view-event-selections-away', styles.selection__name, styles.selection__away, {
          [styles.selection__active]: isAwayTeamHighlighted,
          [branding.FAVOURITE_TEAM]: isAwayTeamHighlighted
        })}
      >
        <span data-tooltip-id={'tooltip'} data-tooltip-html={away || ''}>
          {away || ''}
        </span>
      </div>

      {draw && (
        <div
          className={classNames(
            'biab_asian-view-event-selections-draw',
            styles.selection__name,
            styles.selection__draw
          )}
        >
          <span data-tooltip-id={'tooltip'} data-tooltip-html={draw || ''}>
            {draw || ''}
          </span>
        </div>
      )}
    </div>
  );
};

export default SelectionNames;
