import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import BannerImageComponent from 'components/BannersContainer/components/BannerImage';
import { BannerSections } from 'constants/banners';
import { navigationBranding } from 'constants/branding';
import { ROOT_URL } from 'constants/locations';
import { BannerImage, BannerSection } from 'redux/modules/banners/type';
import { getDefaultLinkTarget } from 'utils/navigation';

import styles from '../styles.module.scss';

interface BannerComponentProps {
  banner: BannerImage;
  isIframeEnabled: boolean;
  type?: BannerSection;
}

const BannerComponent = ({ banner, isIframeEnabled, type }: BannerComponentProps) => {
  const [bannerSelected, isBannerSelected] = useState(false);

  const bannerLink =
    type === BannerSections.PROMOTION_BANNER && !banner.absolutePathEnabled
      ? banner.link.replace(ROOT_URL, '')
      : banner.link;

  const target = getDefaultLinkTarget({
    url: bannerLink,
    absolutePath: banner.absolutePathEnabled,
    isIframeEnabled: isIframeEnabled,
    currentTarget: banner.openInNewTab ? '_blank' : '_self',
    openInNewTab: banner.openInNewTab
  });
  const { t } = useTranslation();

  return (
    <li
      className={classNames(styles.promotionBanner, navigationBranding.MULTIPLE_BANNER_ITEM)}
      onMouseEnter={() => isBannerSelected(true)}
      onMouseLeave={() => isBannerSelected(false)}
    >
      <div className={styles.bannerLabel}>{banner.title}</div>
      {bannerSelected && <div className={styles.promotionBannerOverlay} />}
      <BannerImageComponent banner={banner} />
      {bannerSelected && (
        <>
          {banner.absolutePathEnabled ? (
            <a href={bannerLink} target={target} className={navigationBranding.MULTIPLE_BANNER_LINK}>
              {t('multipleBanners.labels.playNow')}
            </a>
          ) : (
            <Link to={bannerLink} target={target} className={navigationBranding.MULTIPLE_BANNER_LINK}>
              {t('multipleBanners.labels.playNow')}
            </Link>
          )}
        </>
      )}
    </li>
  );
};

export default BannerComponent;
