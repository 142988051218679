import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import EventHeader from 'components/EventHeader';
import EventHeaderBasketball from 'components/EventHeaderBasketball';
import EventHeaderCricket from 'components/EventHeaderCricket';
import EventHeaderMobileInPlay from 'components/EventHeaderMobileInPlay';
import EventHeaderSoccer from 'components/EventHeaderSoccer';
import EventHeaderTennis from 'components/EventHeaderTennis';
import {
  Devices,
  EventStatuses,
  FAVORITES_TYPES,
  GAME_BETTING,
  IS_SPECIAL_HEADER_ON_EVENT,
  MarketStatuses,
  SET,
  SportIds
} from 'constants/app';
import EventsUpdatesInjection from 'injections/EventsUpdatesInjection';
import { getAppDevice, getHideLiveScoreInHeader } from 'redux/modules/appConfigs/selectors';
import { getEventParents } from 'redux/modules/competitions/selectors';
import { getEventLoading } from 'redux/modules/event/selectors';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { removeEventUpdatedDataByEventId } from 'redux/modules/marketsPrices';
import { getEventUpdatedDataStatus, getIsEventsUpdatedDataByEventId } from 'redux/modules/marketsPrices/selectors';
import { LiveWidgetHeaderType } from 'types';

type EventHeaderHandlerProps = {
  eventId: string;
  competitionId?: string;
  sportId: string;
  startDate: number;
  inPlay: boolean;
  title: string;
  /**
   * Title class name, f.e. market or event
   */
  type: LiveWidgetHeaderType;
  status?: string;
  closedDate?: number | null;
  videoStreamingEnabled: boolean;
  matchStatEnabled: boolean;
  /**
   * Type of the parent entity(event or competition)
   */
  parentType?: string;
};

const EventHeaderHandler = ({
  sportId,
  title,
  startDate,
  eventId,
  competitionId,
  inPlay,
  type,
  status,
  closedDate,
  videoStreamingEnabled,
  matchStatEnabled,
  parentType
}: EventHeaderHandlerProps) => {
  const dispatch = useDispatch();
  const { marketId } = useParams();

  const device = useSelector(getAppDevice);
  const hideLiveScoreInHeader = useSelector(getHideLiveScoreInHeader);
  const eventUpdatedDataStatus = useSelector(getEventUpdatedDataStatus(eventId));
  const isEventUpdatedData = useSelector(getIsEventsUpdatedDataByEventId(eventId));
  const eventParents = useSelector(getEventParents);
  const loading = useSelector(getEventLoading);

  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const isMobile = device === Devices.MOBILE;
  const isCloseMarket =
    status === MarketStatuses.CLOSED || !!closedDate || eventUpdatedDataStatus == EventStatuses.CLOSED;
  const isFavorite = useSelector(getFavoriteById(competitionId || eventId));
  const isTennis = sportId === SportIds.TENNIS;

  const lastParent = eventParents?.at(-1);
  const isGameBettingGroup = isMobile
    ? title.toLowerCase().includes(SET)
    : lastParent && lastParent.type === FAVORITES_TYPES.group && isTennis && lastParent.name === GAME_BETTING;

  const favoriteData = useMemo(
    () => ({
      entryId: competitionId || eventId,
      entryName: title,
      entryType: competitionId ? FAVORITES_TYPES.competition : FAVORITES_TYPES.event,
      starred: !isFavorite,
      sportId
    }),
    [eventId, competitionId, isFavorite, parentType, sportId, title]
  );

  const eventIds = useMemo(() => [eventId], [eventId]);

  useEffect(() => {
    return () => {
      dispatch(removeEventUpdatedDataByEventId(eventId));
    };
  }, []);

  const renderContent = () => {
    if (IS_SPECIAL_HEADER_ON_EVENT[sportId] && isEventUpdatedData && !(matchStatEnabled && hideLiveScoreInHeader)) {
      const commonProps = {
        isCloseMarket,
        favoriteData,
        videoStreamingEnabled,
        eventId,
        matchStatEnabled,
        sportId
      };

      switch (sportId) {
        case SportIds.TENNIS:
          return <EventHeaderTennis {...commonProps} />;
        case SportIds.CRICKET:
          return <EventHeaderCricket {...commonProps} />;
        case SportIds.SOCCER:
          return <EventHeaderSoccer {...commonProps} />;
        case SportIds.BASKETBALL:
          return <EventHeaderBasketball {...commonProps} />;
      }
    }

    if (isMobile) {
      return (
        <EventHeaderMobileInPlay
          eventTypeId={sportId}
          eventId={eventId}
          title={title}
          startDate={startDate}
          type={type}
          inPlay={inPlay}
          sportId={sportId}
          favoriteData={favoriteData}
          isCloseMarket={isCloseMarket}
          videoStreamingEnabled={videoStreamingEnabled}
          matchStatEnabled={matchStatEnabled}
          showFavoriteStar={!isGameBettingGroup}
        />
      );
    }

    return (
      <EventHeader
        marketId={marketId!}
        eventId={eventId}
        title={title}
        startDate={startDate}
        type={type}
        sportId={sportId}
        inPlay={inPlay}
        favoriteData={favoriteData}
        isCloseMarket={isCloseMarket}
        videoStreamingEnabled={videoStreamingEnabled}
        matchStatEnabled={matchStatEnabled}
        showFavoriteStar={!isGameBettingGroup}
      />
    );
  };

  return (
    <>
      {IS_SPECIAL_HEADER_ON_EVENT[sportId] && eventId && !isCloseMarket && inPlay && (
        <EventsUpdatesInjection eventIds={eventIds} />
      )}
      {renderContent()}
    </>
  );
};

export default EventHeaderHandler;
