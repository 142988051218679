import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import { LOCATION_BEFORE_LOGIN_SEARCH_PARAM_NAME } from 'constants/app';
import { asianViewIcons, betslipBranding, mobileComponents } from 'constants/branding';
import { getTokenTypeCookieName } from 'redux/modules/appConfigs/selectors';
import { authenticateUser, resetAuthError } from 'redux/modules/auth';
import { getAuthErrorMessage, getIsAuthError, getLoggedInLoading } from 'redux/modules/auth/selectors';

import FloatingLabelInput from '../FloatingLabelInput/FloatingLabelInput';

import styles from './MobileLoginForm.module.scss';

const MobileLoginForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const tokenTypeCookieName = useSelector(getTokenTypeCookieName);
  const isAuthError = useSelector(getIsAuthError);
  const authErrorMessage = useSelector(getAuthErrorMessage);
  const isLoading = useSelector(getLoggedInLoading);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      authenticateUser({
        username,
        password,
        cookieName: tokenTypeCookieName,
        onSuccessCallback: () => {
          const url = searchParams.get(LOCATION_BEFORE_LOGIN_SEARCH_PARAM_NAME);

          if (url) {
            navigate(url);
          }
        }
      })
    );
  };

  const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);

    if (authErrorMessage) {
      dispatch(resetAuthError());
    }
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);

    if (authErrorMessage) {
      dispatch(resetAuthError());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.loginForm__inputs}>
        <FloatingLabelInput
          name="username"
          value={username}
          onChangeValue={handleChangeUsername}
          id="username"
          label={t('header.login.username')}
        />
        <FloatingLabelInput
          name="password"
          value={password}
          onChangeValue={handleChangePassword}
          id="password"
          label={t('header.login.password')}
          type="password"
        />
        {isAuthError && (
          <div className={styles.loginForm__error__container}>
            <i
              className={classNames(
                'biab_custom-icon-warning-circle',
                betslipBranding.WARNING_ICON,
                styles.loginForm__error__icon
              )}
            />
            <p className={styles.loginForm__error}>{authErrorMessage}</p>
          </div>
        )}
        <button
          type="submit"
          className={classNames(styles.loginForm__button, mobileComponents.BUTTON, mobileComponents.PRIMARY)}
          disabled={!username || !password || !!authErrorMessage || isLoading}
        >
          {isLoading ? (
            <Loader
              circleColor={CircleColors.BLACK}
              className={classNames(styles.loginForm__loading, asianViewIcons.BETSLIP_SPINNER)}
            />
          ) : (
            t('header.login')
          )}
        </button>
      </div>
    </form>
  );
};

export default MobileLoginForm;
