import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';

import { BetsStatusesType, TBetsStatuses, TBetStatusPayload, TBetStatusPayloadError } from './type';

const initialState: TBetsStatuses = {
  statuses: {}
};

const slice = createSlice({
  name: SLICES_NAMES.BETS_STATUSES,
  initialState,
  reducers: {
    fetchBetsStatuses: (_, __: PayloadAction<TBetStatusPayload>) => {},
    startGetBetsStatuses: (state, { payload }: PayloadAction<number[]>) => {
      payload.forEach(offerId => {
        if (!state.statuses[offerId]) {
          state.statuses[offerId] = {};
        }

        state.statuses[offerId].loading = true;
      });
    },
    successGetBetsStatuses: (state, { payload }: PayloadAction<Record<string, string>>) => {
      Object.keys(payload).forEach(offerId => {
        if (!state.statuses[offerId]) {
          state.statuses[offerId] = {};
        }

        state.statuses[offerId].loading = false;
        state.statuses[offerId].status = payload[offerId] as BetsStatusesType;
      });
    },
    failureGetBetsStatuses: (state, { payload }: PayloadAction<TBetStatusPayloadError>) => {
      payload.offerIds.forEach(offerId => {
        if (!state.statuses[offerId]) {
          state.statuses[offerId] = {};
        }

        state.statuses[offerId].loading = false;
        state.statuses[offerId].error = payload.error;
      });
    },

    removeBetsStatuses: (state, { payload }: PayloadAction<number[]>) => {
      Object.keys(state.statuses).forEach(offerId => {
        if (!payload.includes(+offerId)) {
          if (!state.statuses[offerId]) {
            state.statuses[offerId] = {};
          }

          state.statuses[offerId].error = null;
        }
      });
    }
  }
});

export const {
  fetchBetsStatuses,
  removeBetsStatuses,
  startGetBetsStatuses,
  successGetBetsStatuses,
  failureGetBetsStatuses
} = slice.actions;

export default slice.reducer;
