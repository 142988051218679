import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { PopularEventTypes } from 'constants/app';
import { IconsConfig } from 'constants/iconsConfig';
import { SPORT_BASE_URL } from 'constants/locations';
import { urlTypeMapper } from 'constants/popularLinks';
import useConvertTimeToString from 'hooks/useConvertTimeToString';
import { useAppThunkDispatch } from 'redux/configureStore';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { fetchRace } from 'redux/modules/competitions';
import { TPopularLinks } from 'redux/modules/popular/type';

import PopularLinkContent from '../PopularLinkContent';

import styles from '../../styles.module.scss';

interface BasicPopularLinkProps {
  value: TPopularLinks;
}

const BasicPopularLink = ({ value }: BasicPopularLinkProps) => {
  const appDispatch = useAppThunkDispatch();

  const domainLanguage = useSelector(getTranslation);

  const [link, setLink] = useState('');

  const { hoursAndMinutes } = useConvertTimeToString({ startDate: value.marketStartTime || 0 });

  const iconSport = value.eventTypeId || value.id;
  let name = value.translations[domainLanguage];

  if (value.type === PopularEventTypes.RACE && value.marketStartTime && !value.id?.includes('tc-')) {
    name = `${hoursAndMinutes} ${value.translations[domainLanguage]}`;
  }

  useEffect(() => {
    const generateLink = async () => {
      if (value.type === PopularEventTypes.EVENT_TYPE) {
        setLink(`${SPORT_BASE_URL}/${value.id}`);
      } else if (value.type === PopularEventTypes.RACE && value.id) {
        const res = await appDispatch(fetchRace(value.id)).unwrap();
        setLink(`${SPORT_BASE_URL}/${res.eventTypeId}/market/${res.children[0].id}`);
      } else {
        setLink(`${SPORT_BASE_URL}/${value.eventTypeId}/${urlTypeMapper[value.type]}/${value.id}`);
      }
    };

    generateLink();
  }, []);

  return (
    <li
      className={classnames('biab_sport-item', styles.popularLink, {
        'biab_c-highlighted': value.highlighted && value.type !== PopularEventTypes.EVENT_TYPE
      })}
    >
      <Link to={link}>
        <PopularLinkContent
          type={value.type}
          icon={iconSport ? IconsConfig.SPORT_ICONS[iconSport] : ''}
          name={name}
          videoStreamingEnabled={value.videoStreamingEnabled}
          eventId={value.id!}
          matchStatEnabled={value.matchStatEnabled}
          sportId={value.id || value.eventTypeId}
        />
      </Link>
    </li>
  );
};

export default BasicPopularLink;
