import { createSelector } from '@reduxjs/toolkit';

import { BetsStatusesTypes } from 'constants/app';
import { AppState } from 'redux/reducers';

import { BetsStatusesType } from './type';

export const getBetsStatuses = (state: AppState) => state.betsStatuses.statuses;

export const getBetsStatusesByOfferIds = (offerIds: number[]) =>
  createSelector(getBetsStatuses, statuses =>
    offerIds.map((offerId: number) => {
      return statuses[offerId];
    })
  );

export const getIsLoadingBetsStatusesByOfferIds = (offerIds: number[]) =>
  createSelector(getBetsStatusesByOfferIds(offerIds), statuses => statuses?.some(status => status?.loading));

export const getBetsStatusesValuesByOffersIds = (offerIds: number[]) =>
  createSelector(getBetsStatusesByOfferIds(offerIds), statuses =>
    statuses?.map(status => status?.status).filter(status => status)
  );

export const getBetsStatusesErrorByOffersIds = (offerIds: number[]) =>
  createSelector(getBetsStatusesByOfferIds(offerIds), statuses => statuses?.find(status => status?.error));

export const getBetStatusErrorByOfferId =
  (offerId: number | undefined) =>
  ({ betsStatuses }: AppState) =>
    offerId ? betsStatuses.statuses[offerId]?.error?.message : null;

export const getBetStatusErrorByOfferIds =
  (offerIds: number[]) =>
  ({ betsStatuses }: AppState) => {
    let errorMessage = '';

    offerIds.forEach(offerId => {
      if (betsStatuses.statuses[offerId]?.error?.message) {
        errorMessage = betsStatuses.statuses[offerId]?.error?.message ?? '';
      }
    });

    return errorMessage;
  };

export const getPlacedBetStatusByOfferId =
  (offerId: number | undefined | null) =>
  ({ betsStatuses }: AppState) =>
    offerId ? betsStatuses.statuses[offerId]?.status : null;

export const getExpiredOfferIds = createSelector(getBetsStatuses, statuses =>
  Object.keys(statuses).filter(offerId => {
    const item = statuses[offerId];
    return item.loading === false && item.status === BetsStatusesTypes.EXPIRED;
  })
);

export const getStatusesByOfferIds = (offersIds: number[]) =>
  createSelector(getBetsStatuses, statuses => {
    return offersIds.reduce<Record<string, BetsStatusesType | undefined>>((acc, offerId) => {
      return {
        ...acc,
        [offerId]: statuses[offerId]?.status
      };
    }, {});
  });
