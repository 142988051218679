import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Skeleton from 'components/Skeleton';
import { EDUCATIONAL_PORTAL_GUIDES_URL } from 'constants/locations';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import useDevice from 'hooks/useDevice';
import { setIsSearchActive } from 'redux/modules/educationalPortal';
import { ISearchArticle } from 'types/educationalPortal';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

const SearchItem = ({ article, onSelectItem }: { article: ISearchArticle; onSelectItem?: () => void }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const { isMobile } = useDevice();

  const searchQuery = searchParams.get(PARAMS_SEARCH_KEY) ?? '';

  const handleClick = () => {
    dispatch(setIsSearchActive(false));

    if (onSelectItem) {
      onSelectItem();
    }
  };

  return (
    <Link
      to={{
        pathname: `${EDUCATIONAL_PORTAL_GUIDES_URL}/${article.localeCode}/${article.permalink}`,
        search: isMobile ? `${PARAMS_SEARCH_KEY}=${searchQuery}` : undefined
      }}
      className={classNames(styles.link, { [styles.link__mobile]: isMobile })}
      key={article.permalink}
      onClick={handleClick}
    >
      {!isLoaded && <Skeleton className={classNames(styles.image, { [styles.image__mobile]: isMobile })} />}
      <img
        onLoad={() => setIsLoaded(true)}
        className={classNames(styles.image, { [styles.image__mobile]: isMobile, [styles.image__hide]: !isLoaded })}
        src={`${
          window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL
        }${getEnvironmentRootPath()}/api/images/thumbnail/${article.thumbnail}`}
        alt={`${article.name}-cover`}
      />
      <span>{article.name}</span>
    </Link>
  );
};

export default SearchItem;
