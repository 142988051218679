import { useSelector } from 'react-redux';

import { Devices } from 'constants/app';
import {
  getAppDevice,
  getDesktopHeaderHidden,
  getIsIframeEnabled,
  getMobileBackButtonMobile
} from 'redux/modules/appConfigs/selectors';

const useBackButtonInIFrame = () => {
  const device = useSelector(getAppDevice);
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const desktopHeaderHidden = useSelector(getDesktopHeaderHidden);
  const backButtonMobile = useSelector(getMobileBackButtonMobile);

  return iframeEnabled && desktopHeaderHidden && backButtonMobile && device === Devices.MOBILE;
};

export default useBackButtonInIFrame;
