import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ArrowScroll from 'components/ArrowScroll';
import CashOutButton from 'components/CashOutButton';
import FavoriteStar from 'components/FavoriteStar';
import MarketCollapseRow from 'components/MarketCollapseRow';
import MarketLineRangeInfoRow from 'components/MarketSport/components/MarketLineRangeInfoRow';
import MarketSelectionsRow from 'components/MarketSport/components/MarketSelectionsRow';
import StatusOverlay from 'components/MarketsTable/components/MarketsTableRow/components/StatusOverlay';
import { FAVORITES_TYPES, PageBlocks, PlacementPages, tooltipStatus } from 'constants/app';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { TFavorite } from 'redux/modules/favorites/type';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';
import {
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesId,
  getMarketPricesIsBettingEnabled,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import {
  resetMultiMarketArrowScrollParams,
  setMultiMarketLinesResetLinesText,
  setMultiMarketReset,
  setMultiMarketShowAll
} from 'redux/modules/multiMarket';
import {
  getMultiMarketLines,
  getMultiMarketLinesText,
  getMultiMarketReset,
  getMultiMarketShowAll
} from 'redux/modules/multiMarket/selectors';
import { getMarketTypes } from 'utils/market';

import multiMarketStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

interface MobileExtraTabMultiMarketProps {
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Whether is landscape mode
   */
  isLandscape: boolean;
}

const MobileExtraTabMultiMarket = ({ isDepthEnabled, isLandscape }: MobileExtraTabMultiMarketProps) => {
  const dispatch = useDispatch();

  const market = useSelector(getSingleMarketInfo);
  const isFavorite = useSelector(getFavoriteById(market?.marketId));
  const marketPricesId = useSelector(getMarketPricesId(market?.marketId ?? ''));
  const bettingEnabled = useSelector(getMarketPricesIsBettingEnabled(market?.marketId ?? ''));
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(market?.marketId ?? ''));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(market?.marketId ?? ''));
  const lines = useSelector(getMultiMarketLines);
  const reset = useSelector(getMultiMarketReset);
  const showAll = useSelector(getMultiMarketShowAll);
  const linesText = useSelector(getMultiMarketLinesText);

  const favoriteData = {
    starred: !isFavorite,
    entryId: market?.marketId,
    entryName: market?.marketName,
    entryType: FAVORITES_TYPES.market,
    sportId: market?.eventType.id
  };

  const { status, showStatus } = useMarketStatusAndLockIcon(bettingEnabled, marketPricesStatus);

  const [collapsed, setCollapsed] = useState<boolean>(false);

  let isLineMarket = false;

  if (market) {
    const marketTypes = getMarketTypes(market.description.marketType, market.description.bettingType);
    isLineMarket = marketTypes.isLineMarket;
  }

  useEffect(() => {
    dispatch(resetMultiMarketArrowScrollParams());

    return () => {
      dispatch(resetMultiMarketArrowScrollParams());
    };
  }, []);

  const handleShowAllRunners = useCallback(() => {
    dispatch(setMultiMarketShowAll(true));
  }, []);

  const handleMinimize = useCallback(() => {
    dispatch(setMultiMarketShowAll(false));
  }, []);

  const handleResetView = useCallback(() => {
    if (!showAll) {
      dispatch(setMultiMarketReset(true));
    }
  }, [showAll]);

  const handleSetGroupByIndex = (
    index: number,
    step: number,
    defaultLine: number,
    totalLines: number,
    newLinesText: string
  ) => {
    const newLines = {
      from: index,
      to: index + step,
      currentLine: index,
      defaultLine,
      totalLines
    };

    dispatch(setMultiMarketLinesResetLinesText({ lines: newLines, reset: false, linesText: newLinesText }));
  };

  const collapseMarket = () => {
    setCollapsed(prevState => !prevState);
  };

  if (!marketPricesId || !market?.marketId) {
    return null;
  }

  return (
    <>
      <MarketCollapseRow
        onMarketCollapse={collapseMarket}
        inPlay={market.inPlay || isInPlay}
        marketId={market.marketId}
        marketStartTime={market.marketStartTime}
        marketName={market.marketName}
        sportId={market.eventType.id}
        className="biab_multi-market-title"
        classes={{
          header: classNames(
            multiMarketStyles.multiMarket__otherMarkets__collapseHeader,
            multiMarketStyles.multiMarket__otherMarkets__collapseHeader__mobile
          )
        }}
        rightIcon={
          <i
            className={classNames('biab_collapse-toggler fa2', {
              'fa2-arrow-up': !collapsed,
              'fa2-arrow-down': collapsed
            })}
          />
        }
        inPlayClassName="biab_multi-market-inplay"
        isMarketCashOutEnabled={market.cashOutEnabled}
        inPlayStatusFirst
      >
        {market && <FavoriteStar entryData={favoriteData as TFavorite} className={styles.eventMarket__starIcon} />}
        <span className={styles.marketName}>{market.marketName}</span>
      </MarketCollapseRow>
      {!collapsed && (
        <>
          {isLineMarket && <MarketLineRangeInfoRow market={market} />}
          <div className="biab_handicap-market">
            {market.cashOutEnabled && !tooltipStatus[status || ''] && (
              <CashOutButton marketId={market.marketId} partialCashOutEnabled={market.partialCashOutEnabled} />
            )}
            <MarketSelectionsRow
              market={market}
              showAll={showAll}
              linesText={linesText}
              lines={lines}
              onMinimize={handleMinimize}
              onShowAllRunners={handleShowAllRunners}
              onResetView={handleResetView}
              showMatchedAmount
              isDepthEnabled={isDepthEnabled}
              actionsClasses={{ actions: styles.mobileExtraTabSingleMarket__actions }}
              page={PlacementPages.MULTI_MARKET}
            />
            <div className={styles.mobileExtraTabSingleMarket__runnersContainer}>
              <ArrowScroll
                market={market}
                onSetGroupByIndex={handleSetGroupByIndex}
                showAll={showAll}
                reset={reset}
                pageBlock={PageBlocks.MULTI_MARKET_VIEW}
                isDepthEnabled={isDepthEnabled}
                isLandscape={isLandscape}
                page={PlacementPages.MULTI_MARKET}
              />
              {showStatus && <StatusOverlay status={status} inMiddle />}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MobileExtraTabMultiMarket;
