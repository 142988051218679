import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Modal from 'components/Modal';
import useAsyncEffect from 'hooks/useAsyncEffect';
import useDevice from 'hooks/useDevice';
import { getBackendPage } from 'redux/modules/appConfigs/selectors';
import { fetchPageContent } from 'redux/modules/pages';
import { PageFromBackend } from 'redux/modules/pages/types';
import { ModalClasses } from 'types';

import styles from './styles.module.scss';

type CashOutRulesModalProps = {
  link: PageFromBackend;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  modalClassNames?: ModalClasses;
};

const CashOutRulesModal = ({ link, openModal, setOpenModal, modalClassNames }: CashOutRulesModalProps) => {
  const contentLink = useSelector(getBackendPage(link));
  const { isAsianViewPage } = useDevice();

  const { response } = useAsyncEffect({
    asyncCallback: fetchPageContent,
    params: contentLink
  });

  return (
    <Modal
      customClassNames={{
        ...modalClassNames,
        header: classNames(styles.modalHeader, modalClassNames?.header ?? ''),
        closeIcon: classNames(modalClassNames?.closeIcon ?? '', {
          biab_close: !isAsianViewPage
        })
      }}
      title={response?.name ?? ''}
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <div dangerouslySetInnerHTML={{ __html: response?.content ?? '' }} />
    </Modal>
  );
};

export default CashOutRulesModal;
