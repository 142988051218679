import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices } from 'constants/app';
import { maxMobileSlidesToShow } from 'constants/webTours';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { TourStep } from 'redux/modules/appConfigs/type';

import Slide from './components/Slide';
import SlideArrow from './components/SlideArrow';

import styles from '../../styles.module.scss';

interface PaginationProps {
  handleClickNext: () => void;
  handleClickPrev: () => void;
  stepOrder: number;
  handleGoToSlide: (slide: number) => void;
  currentTourSteps: TourStep[];
  nextStep: number;
  prevStep: number;
}

const Pagination = ({
  handleClickNext,
  handleClickPrev,
  handleGoToSlide,
  stepOrder,
  currentTourSteps,
  nextStep,
  prevStep
}: PaginationProps) => {
  const device = useSelector(getAppDevice);

  const isMobile = device === Devices.MOBILE;
  let slideNumber: number | undefined;
  let mobilePaginationBlock: TourStep[] = currentTourSteps;

  if (nextStep && currentTourSteps[nextStep]?.content) {
    slideNumber = nextStep;
  } else if (prevStep && currentTourSteps[prevStep]?.content && !currentTourSteps[nextStep]?.content) {
    slideNumber = prevStep;
  } else {
    slideNumber = stepOrder;
  }

  if (slideNumber !== undefined && isMobile) {
    if (slideNumber < 4) {
      mobilePaginationBlock = currentTourSteps.slice(0, maxMobileSlidesToShow);
    } else {
      mobilePaginationBlock = currentTourSteps.slice(
        slideNumber - 3,
        slideNumber + 3 > currentTourSteps.length ? currentTourSteps.length : slideNumber + 3
      );
    }
  }

  const isShowLeftArr = slideNumber !== 0;
  const isShowRightArr = slideNumber !== currentTourSteps.length - 1;
  const blurLastCondition =
    currentTourSteps.length > maxMobileSlidesToShow &&
    slideNumber !== undefined &&
    slideNumber < currentTourSteps.length - 3;
  const blurFirstCondition = currentTourSteps.length > maxMobileSlidesToShow && slideNumber && slideNumber > 3;

  return (
    <div className={styles.pagination}>
      <div className={styles.pagination__slideWrapper}>
        <SlideArrow
          handleSlide={handleClickPrev}
          showCondition={isShowLeftArr}
          classname={styles.pagination__leftArr}
          iconClass="fa2 fa2-arrow-left"
        />
        {isMobile ? (
          <div
            className={classNames(styles.pagination__mobileSlidesWrapper, {
              [styles.pagination__blurLast]: blurLastCondition,
              [styles.pagination__blurFirst]: blurFirstCondition
            })}
          >
            {mobilePaginationBlock?.map(({ id }, index) => (
              <Slide key={id} index={index} handleGoToSlide={handleGoToSlide} slideNumber={slideNumber} />
            ))}
          </div>
        ) : (
          <>
            {currentTourSteps?.map(({ id }, index) => (
              <Slide key={id} index={index} handleGoToSlide={handleGoToSlide} slideNumber={slideNumber} />
            ))}
          </>
        )}
        <SlideArrow
          handleSlide={handleClickNext}
          showCondition={isShowRightArr}
          classname={styles.pagination__rightArr}
          iconClass="fa2 fa2-arrow-right"
        />
      </div>
    </div>
  );
};

export default Pagination;
