import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { mobileAccountPagesBranding } from 'constants/branding';
import {
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  ASIAN_MOBILE_FAVOURITES_URL,
  GAMES_BASE_URL,
  HOME_BASE_URL
} from 'constants/locations';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { getAccountPageReferer } from 'redux/modules/appSettings/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

import styles from './styles.module.scss';

interface MobileAccountBackBtnProps {
  className?: string;
  hideLabel?: boolean;
  iconName?: string;
  iconClassName?: string;
}

const MobileAccountBackBtn = ({ className, hideLabel, iconName, iconClassName }: MobileAccountBackBtnProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const accountPageReferer = useSelector(getAccountPageReferer);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isGamesEnabled = useSelector(getIsExchangeGamesEnabled);

  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL) || (!isSportsEnabled && !isGamesEnabled);
  const isGamesPage = pathname.includes(GAMES_BASE_URL) || (!isSportsEnabled && !isAsianViewEnabled);
  const isBetListReferer = accountPageReferer.includes(ASIAN_MOBILE_BET_LIST_URL);
  const isFavReferer = accountPageReferer.includes(ASIAN_MOBILE_FAVOURITES_URL);
  const label = t(`header.navigation.${(isAsianViewPage && 'asianView') || (isGamesPage && 'games') || 'sports'}`);
  const basePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || HOME_BASE_URL;
  const isValidPageReferer = !(!isLoggedIn && (isBetListReferer || isFavReferer));
  const url = (isValidPageReferer && accountPageReferer) || basePage;

  return (
    <Link className={classNames(styles.link, 'biab_btn', className, mobileAccountPagesBranding.BUTTON)} to={url}>
      <i className={classNames(iconName || 'biab_left-arrow fa2 fa2-arrow-left', styles.icon, iconClassName)} />
      {!hideLabel && label}
    </Link>
  );
};

export default MobileAccountBackBtn;
