import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ResponsibleBettingCreateLimit from 'components/ResponsibleBetting/components/ResponsibleBettingCreateLimit';
import ResponsibleBettingLossLimitView from 'components/ResponsibleBetting/components/ResponsibleBettingLossLimitView';
import ResponsibleBettingRemoveLimit from 'components/ResponsibleBetting/components/ResponsibleBettingRemoveLimit';
import { ResponsibleBettingSection } from 'components/ResponsibleBetting/components/ResponsibleBettingSection';
import ResponsibleBettingUpdateLimit from 'components/ResponsibleBetting/components/ResponsibleBettingUpdateLimit';
import { ResponsibleBettingDisplayedContents } from 'constants/responsibleBetting';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import {
  getBettingDisplaySettings,
  getCurrency,
  getDisplayCurrencySymbol,
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { updateResponsibleBettingSettings } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';
import { ResponsibleBettingTab } from 'types/responsibleBetting';
import { addCommasToNumber } from 'utils';
import { applyTimezone, getLongDateFormat } from 'utils/date';
import {
  calculateLimitIndexes,
  calculateNewPeriodStartDate,
  convertTimeUnitsToDropdown,
  getTimeUnitKey
} from 'utils/responsibleBetting';

interface ResponsibleBettingLossLimitProps {
  tab: ResponsibleBettingTab;
}

const ResponsibleBettingLossLimit = ({ tab }: ResponsibleBettingLossLimitProps) => {
  const dispatch = useDispatch();

  const { isMobile } = useDevice();
  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState(false);
  const [isUpdatingMode, setIsUpdatingMode] = useState(false);
  const [isRemovalMode, setIsRemovalMode] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isApplyingMode, setIsApplyingMode] = useState(false);

  const rbSettings = useSelector(getResponsibleBettingSettings);
  const bettingDisplaySettings = useSelector(getBettingDisplaySettings);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const { minBetSize } = useSelector(getCurrency);
  const displayCurrencySymbol = useSelector(getDisplayCurrencySymbol);
  const defaultCurrency = useSelector(getCurrency);

  const operatorLimit = rbSettings?.operatorsLossLimit;
  const maxLimitInUsersCurrency = rbSettings?.maxLimitInUsersCurrency ?? 0;
  const {
    lossLimit = 0,
    lossLimitAvailableAmount = 0,
    lossLimitTimeUnit = null,
    lossLimitStartDate = null,
    lossLimitEndDate = null
  } = rbSettings?.rgTools || {};
  const { lossLimitCooldownDays } = bettingDisplaySettings;
  const selectedLimitEndDate = lossLimitEndDate
    ? applyTimezone(new Date(lossLimitEndDate), timezone, timezoneCookieEnabled)
    : null;

  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const [selectedLimit, setSelectedLimit] = useState<number | null>(lossLimit);
  const [selectedTimeUnit, setSelectedTimeUnit] = useState(lossLimitTimeUnit);

  const lossLimitTimeUnits = convertTimeUnitsToDropdown(t);

  const limitAmountLabel = t('responsibleBetting.labels.limitAmount');
  const limitPeriodLabel = t('responsibleBetting.labels.limitPeriod');
  const inputLabel = displayCurrencySymbol ? `${limitAmountLabel}, ${defaultCurrency?.symbol}` : limitAmountLabel;

  const { formattedAmount: formattedNewLimit } = useFormatCurrency(selectedLimit || 0, defaultCurrency.symbol, {
    isCheckIndian: true,
    noRounding: false,
    ignoreFlexibleDisplayFormat: true
  });
  const { formattedAmount: formattedCurrentLimit } = useFormatCurrency(lossLimit || 0, defaultCurrency?.symbol, {
    isCheckIndian: true,
    noRounding: false
  });

  const initializeSelectedLimit = () => {
    setSelectedLimit(lossLimit);
    setSelectedTimeUnit(lossLimitTimeUnit);
  };

  const validateLimitInput = (limit: number) => {
    const minLimit = Math.ceil(minBetSize);
    setError(null);
    if (limit !== undefined && limit < minLimit) {
      setError(
        t('responsibleBetting.validation.minNumber', {
          N: `<strong>${addCommasToNumber(minLimit)}</strong>`,
          interpolation: { escapeValue: false }
        })
      );
    } else if (limit && limit > maxLimitInUsersCurrency) {
      setError(
        t('responsibleBetting.validation.maxNumber', {
          N: `<strong>${addCommasToNumber(maxLimitInUsersCurrency)}</strong>`,
          interpolation: { escapeValue: false }
        })
      );
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const limit = +event.target.value;
    setSelectedLimit(limit);
    validateLimitInput(limit);
  };

  const resetInputs = () => {
    setSelectedLimit(null);
    setSelectedTimeUnit(null);
  };

  const handleOnCreateApply = () => {
    if (rbSettings && selectedTimeUnit && selectedLimit) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            lossLimitEnabled: true,
            lossLimit: selectedLimit,
            lossLimitTimeUnit: selectedTimeUnit,
            type: ResponsibleBettingDisplayedContents.LossLimit
          },
          type: ResponsibleBettingDisplayedContents.LossLimit
        })
      );
    }
  };

  const handleOnCreateCancel = () => {
    if (isUpdatingMode) {
      setIsUpdatingMode(false);
    } else {
      setIsOpened(false);
    }

    resetInputs();
    setError(null);
  };

  const handleOnUpdateApply = () => {
    if (!isApplyingMode) {
      setIsApplyingMode(true);
      return;
    }
    if (rbSettings) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            lossLimitEnabled: true,
            lossLimit: selectedLimit || lossLimit,
            lossLimitTimeUnit: selectedTimeUnit || lossLimitTimeUnit,
            type: ResponsibleBettingDisplayedContents.LossLimit
          },
          type: ResponsibleBettingDisplayedContents.LossLimit
        })
      );
    }
    initializeSelectedLimit();
    setIsApplyingMode(false);
    setIsUpdatingMode(false);
  };

  const handleOnUpdateCancel = () => {
    if (isApplyingMode) {
      setIsApplyingMode(false);
    } else {
      initializeSelectedLimit();
      setError(null);
      setIsUpdatingMode(false);
    }
  };

  const handleOnRemoveCancel = () => {
    setIsRemovalMode(false);
  };

  const handleOnRemoveApply = () => {
    if (rbSettings) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            lossLimitEnabled: false,
            type: ResponsibleBettingDisplayedContents.LossLimit
          },
          type: ResponsibleBettingDisplayedContents.LossLimit
        })
      );
    }
    setIsRemovalMode(false);
  };

  const handleOnSelectOption = (option: DropdownItem) => {
    setSelectedTimeUnit(
      selectedTimeUnit === option.value ? null : (option.value as CombinedResponsibleBettingTimeUnit)
    );
  };

  const confirmationMessage = useMemo(() => {
    if (!isRemovalMode && !isUpdatingMode) {
      return;
    }

    const startingNewLimitDate = calculateNewPeriodStartDate(
      lossLimitStartDate,
      lossLimitTimeUnit,
      lossLimitCooldownDays,
      timezone,
      timezoneCookieEnabled
    );
    const formattedDate = getLongDateFormat(
      startingNewLimitDate,
      t,
      timezone,
      timezoneCookieEnabled,
      americanDateFormatEnabled
    );

    if (isRemovalMode) {
      if (lossLimitCooldownDays && lossLimitCooldownDays > 0) {
        return t('responsibleBetting.message.LOSS_LIMIT_START_COOLDOWN_FOR_REMOVAL', {
          cooldown_period_value: lossLimitCooldownDays,
          estimated_date_of_the_limit_removal: formattedDate
        });
      } else {
        return t('responsibleBetting.message.LOSS_LIMIT_NO_COOLDOWN_FOR_REMOVAL', {
          estimated_date_of_the_limit_removal: formattedDate
        });
      }
    }

    if (isUpdatingMode) {
      const { newLossLimitIndex, currentLossLimitIndex, initialLossLimitIndex } = calculateLimitIndexes(
        lossLimitAvailableAmount,
        lossLimit,
        selectedLimit,
        selectedLimitEndDate,
        lossLimitTimeUnit,
        selectedTimeUnit
      );

      if (newLossLimitIndex <= initialLossLimitIndex && newLossLimitIndex <= currentLossLimitIndex) {
        return;
      }

      if (newLossLimitIndex <= initialLossLimitIndex) {
        return t('responsibleBetting.message.LOSS_LIMIT_NO_COOLDOWN_FOR_UPDATE_TO_LESS_STRICT', {
          estimated_date_of_the_limit_change: formattedDate
        });
      }

      if (newLossLimitIndex > initialLossLimitIndex) {
        return lossLimitCooldownDays === 0
          ? t('responsibleBetting.message.LOSS_LIMIT_NO_COOLDOWN_FOR_UPDATE_TO_LESS_STRICT', {
              estimated_date_of_the_limit_change: formattedDate
            })
          : t('responsibleBetting.message.LOSS_LIMIT_START_COOLDOWN_FOR_UPDATE_TO_LESS_STRICT', {
              cooldown_period_value: lossLimitCooldownDays,
              estimated_date_of_the_limit_change: formattedDate
            });
      }
    }
  }, [
    isUpdatingMode,
    isRemovalMode,
    lossLimitStartDate,
    lossLimitTimeUnit,
    lossLimitCooldownDays,
    timezone,
    timezoneCookieEnabled,
    lossLimitAvailableAmount,
    lossLimit,
    selectedLimit,
    selectedLimitEndDate,
    selectedTimeUnit,
    americanDateFormatEnabled,
    t
  ]);

  return (
    <ResponsibleBettingSection
      tab={tab}
      onClick={() => setIsOpened(true)}
      isOpened={isOpened || !!lossLimit || !!operatorLimit}
    >
      <>
        {isOpened && !lossLimit && (
          <ResponsibleBettingCreateLimit
            isMobile={isMobile}
            error={error}
            selectedTimeUnit={selectedTimeUnit || lossLimitTimeUnit}
            selectedLimit={selectedLimit}
            inputLabel={inputLabel}
            inputPlaceholder={t('responsibleBetting.placeholders.enterAmount')}
            dropdownLabel={limitPeriodLabel}
            data={lossLimitTimeUnits}
            onChange={handleOnChange}
            onCancel={handleOnCreateCancel}
            onApply={handleOnCreateApply}
            onSelectOption={handleOnSelectOption}
          />
        )}
        {lossLimit && isRemovalMode && (
          <ResponsibleBettingRemoveLimit
            isMobile={isMobile}
            onCancel={handleOnRemoveCancel}
            onApply={handleOnRemoveApply}
            confirmationMessage={confirmationMessage}
            currentLimitValue={`${formattedCurrentLimit} ${t(getTimeUnitKey(lossLimitTimeUnit))}`}
          />
        )}
        {lossLimit && isUpdatingMode && (
          <ResponsibleBettingUpdateLimit
            isMobile={isMobile}
            isApplyingMode={isApplyingMode}
            error={error}
            currentTimeUnit={lossLimitTimeUnit}
            currentLimit={lossLimit}
            newLimit={selectedLimit}
            newLimitTimeUnit={selectedTimeUnit || lossLimitTimeUnit}
            onChange={handleOnChange}
            onCancel={handleOnUpdateCancel}
            onApply={handleOnUpdateApply}
            onSelectOption={handleOnSelectOption}
            confirmationMessage={confirmationMessage}
            inputLabel={inputLabel}
            newLimitAppliedFieldValue={formattedNewLimit}
            currentLimitComparedFieldLabel={`${formattedCurrentLimit} ${t(getTimeUnitKey(lossLimitTimeUnit))}`}
            newLimitComparedFieldLabel={`${formattedNewLimit} ${t(getTimeUnitKey(selectedTimeUnit))}`}
            data={lossLimitTimeUnits}
          />
        )}
        {(lossLimit || !!operatorLimit) && !isUpdatingMode && !isRemovalMode && (
          <ResponsibleBettingLossLimitView
            onEdit={() => {
              setIsUpdatingMode(true);
              initializeSelectedLimit();
            }}
            onRemove={() => setIsRemovalMode(true)}
          />
        )}
      </>
    </ResponsibleBettingSection>
  );
};

export default ResponsibleBettingLossLimit;
