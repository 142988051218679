import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import Collapse from 'components/Collapse';
import FavouritesHeader from 'components/FavoritesSection/FavouritesHeader';
import NoFavorites from 'components/FavoritesSection/NoFavorites';
import { ElementNames, SportIds } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE,
  ASIAN_VIEW_PAGE_PARAM,
  AsianViewMarketLinks,
  AsianViewTimeFilters
} from 'constants/asianView';
import { asianViewNavigation as branding } from 'constants/branding';
import { ASIAN_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import { getIsDesktopInfiniteScrollEnabled } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import {
  getAsianViewFavouritesList,
  getAsianViewIsFavouritesLoaded
} from 'redux/modules/asianViewFavourites/selectors';
import { ASIAN_VIEW_FAVORITES_COLLAPSE, LEFT_PANEL_COLLAPSE_GROUP_ASIAN } from 'redux/modules/collapse/constants';
import { getIsValidMarketLink, getIsValidTimeFilter } from 'utils/asianView';

import styles from './styles.module.scss';

const AsianFavourites = () => {
  const dispatch = useDispatch();
  const { sportId, timeFilter, marketLink } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const favorites = useSelector(getAsianViewFavouritesList);
  const isFavouritesLoaded = useSelector(getAsianViewIsFavouritesLoaded);
  const isInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.ASIAN_FAVOURITES_NAVIGATION, height }));
    }
  });

  const isFavourites = !!favorites.length;
  const searchPageParam = isInfiniteScrollEnabled ? '' : `&${ASIAN_VIEW_PAGE_PARAM}=0`;
  const betSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);
  const betSlipCashOutPageParam = betSlipCashOutPage
    ? `&${ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE}=${betSlipCashOutPage}`
    : '';

  useEffect(() => {
    if (isFavouritesLoaded) {
      const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
      const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
      const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);

      if (!isFavourites && favouriteId && favouriteType && favouriteSportId) {
        searchParams.delete(ASIAN_FAVOURITE_ID_PARAM);
        searchParams.delete(ASIAN_FAVOURITE_TYPE_PARAM);
        searchParams.delete(ASIAN_FAVOURITE_SPORT_ID_PARAM);
        setSearchParams(searchParams);
      }
    }
  }, [isFavourites, isFavouritesLoaded]);

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.ASIAN_FAVOURITES_NAVIGATION, height: 0 }));
    };
  }, []);

  return (
    <div
      className={classNames({ biab_active: isFavourites, [branding.FAVOURITE_EMPTY]: !isFavourites })}
      ref={componentRef}
    >
      <Collapse
        customClass={styles.asianViewFavourites__collapse}
        group={LEFT_PANEL_COLLAPSE_GROUP_ASIAN}
        collapse={ASIAN_VIEW_FAVORITES_COLLAPSE}
        title={<FavouritesHeader isFavourites={isFavourites} />}
        withoutMargin
        withoutCookie
      >
        {isFavourites ? (
          favorites.map(favourite => (
            <div
              key={favourite.entryId}
              className={classNames(styles.asianViewFavourites__item, branding.FAVOURITE_ITEM)}
            >
              <AsianViewFavouriteStar favourite={favourite} />
              <Link
                to={{
                  pathname: `${ASIAN_BASE_URL}/sport/${sportId ?? SportIds.SOCCER}/timeFilter/${
                    !timeFilter || !getIsValidTimeFilter(timeFilter) ? AsianViewTimeFilters.Today : timeFilter
                  }/marketLink/${
                    !marketLink || !getIsValidMarketLink(marketLink) ? AsianViewMarketLinks.HDP_AND_OU : marketLink
                  }`,
                  search: `${ASIAN_FAVOURITE_TYPE_PARAM}=${favourite.entryType}&${ASIAN_FAVOURITE_SPORT_ID_PARAM}=${favourite.sportId}&${ASIAN_FAVOURITE_ID_PARAM}=${favourite.entryId}${searchPageParam}${betSlipCashOutPageParam}`
                }}
                className={styles.asianViewFavourites__item__name}
              >
                {favourite.entryName}
              </Link>
            </div>
          ))
        ) : (
          <NoFavorites />
        )}
      </Collapse>
    </div>
  );
};

export default AsianFavourites;
