import { pickBy } from 'lodash';

import { PLBetTypes, PLExchangeTypes } from 'constants/myBets';
import { AppState } from 'redux/reducers';
import { AccountStatementSortByField, PLBetType } from 'types/myBets';

import { SortByFields, TBetsType, TSortByType } from './type';

export const getMyBets = ({ myBets }: AppState) => myBets.data;
export const getStatementData = ({ myBets }: AppState) => myBets.statementData;
export const myBetsLoading = ({ myBets }: AppState) => myBets.loading;
export const bettingPLLoading = ({ myBets }: AppState) => myBets.plDataLoading;
export const charges = ({ myBets }: AppState) => myBets.charges;
export const getPLData = ({ myBets }: AppState) => myBets.PLData;
export const getPLTotalData = ({ myBets }: AppState) => myBets.PLTotal;
export const getPLTotalEventData = ({ myBets }: AppState) => myBets.PLTotalEvent;
export const getTimeRange = ({ myBets }: AppState) => myBets.timeRange;
export const getMyBetsMultiCurrencyValue = ({ myBets }: AppState) => myBets.multiCurrencyValue;

export const isShowMobileBetDetails = ({ myBets }: AppState) => myBets.showBetDetails;

export const betDetails = ({ myBets }: AppState) => myBets.bet;

export const getStatementLoading = ({ myBets }: AppState) => myBets.statementDataLoading;

export const isBetUpdated = ({ myBets }: AppState) => myBets.betUpdated;

export const cancelledBet = ({ myBets }: AppState) => myBets.cancelledBet;

export const getStartDate = ({ myBets }: AppState) => myBets.startDate;

export const getEndDate = ({ myBets }: AppState) => myBets.endDate;

export const getPageNumber = ({ myBets }: AppState) => myBets.pageNumber;

export const getSearchValue = ({ myBets }: AppState) => myBets.search;

export const getPageSize = ({ myBets }: AppState) => myBets.pageSize;
export const getMobileStatementItemDetails = ({ myBets }: AppState) => myBets.mobileStatementItemDetails;
export const getIsMobileStatementItemDetails = ({ myBets }: AppState) => !!myBets.mobileStatementItemDetails;

export const getMyBetsSports = ({ myBets }: AppState) => myBets.sportsList ?? 0;
export const getMyBetsGames = ({ myBets }: AppState) => myBets.gamesList ?? 0;

export const getBetsTypeList = (activePLBetType?: PLBetType) => (state: AppState) => {
  if (activePLBetType) {
    return activePLBetType === PLExchangeTypes[PLBetTypes.Sports] ? state.myBets.sportsList : state.myBets.gamesList;
  }

  return [];
};

export const getAccountProductsByBetsType = (betsType?: TBetsType) => (state: AppState) => {
  return betsType ? state.myBets.accountProducts[betsType] : null;
};

export const getPLTotalElements = ({ myBets }: AppState) => myBets.PLData.totalElements;

export const getSelectedTabIsFirstLoaded =
  (selectedTab: string) =>
  ({ myBets }: AppState) => {
    switch (selectedTab) {
      case 'mybets':
        return myBets.isMyBetsFirstLoaded;

      case 'profitandloss':
        return myBets.isProfitLossFirstLoaded;

      case 'statement':
        return myBets.isStatementFirstLoaded;

      default:
        return myBets.isMyBetsFirstLoaded;
    }
  };

export const getMyBetsTotalElements =
  (section: 'data' | 'statementData' | 'PLData') =>
  ({ myBets }: AppState) =>
    myBets[section]?.totalElements;

export const getEventTypeFilter = (state: AppState) => state.myBets.eventTypeFilter;

export const getSortValueByType = (type: TSortByType) => (state: AppState) => state.myBets.sortBy?.[type];

export const getIsMobileFiltersOpen = (state: AppState) => state.myBets.isMobileFiltersOpen;

export const getIsMobileFiltersChanged = (state: AppState) =>
  state.myBets.mobileFilters.isBetTypesChanged ||
  state.myBets.mobileFilters.isCurrencyChanged ||
  state.myBets.mobileFilters.isProductChanged ||
  state.myBets.mobileFilters.isTimeDisplayFormatChanged ||
  state.myBets.mobileFilters.isDateRangeChanged ||
  state.myBets.mobileFilters.isStatementSortingChanged ||
  state.myBets.mobileFilters.isTransactionsTypesChanged ||
  state.myBets.mobileFilters.isNetCommissionChanged ||
  state.myBets.mobileFilters.isEventTypeIdChanged ||
  state.myBets.mobileFilters.isSortingChanged;

export const getMobileFiltersChanged = (state: AppState) => {
  return state.myBets.mobileFilters;
};

export const getProductMobileFilter = (state: AppState) => {
  return state.myBets.mobileFilters.product;
};

export const getBettingPLSort = ({ myBets }: AppState) =>
  pickBy(
    myBets.sortBy,
    (order, sort) =>
      [SortByFields.START_DATE, SortByFields.SETTLED_DATE].includes(sort as 'market_start_time' | 'settled_date') &&
      order !== null
  );

export const getAccountStatementTransactionsTypes = ({ myBets }: AppState) =>
  myBets.accountStatementFilters.transactions;

export const getAccountStatementSort = ({ myBets }: AppState) => myBets.accountStatementFilters.sort;
export const getAccountStatementSortByName =
  (name: AccountStatementSortByField) =>
  ({ myBets }: AppState) =>
    myBets.accountStatementFilters.sort?.[name];

export const getMobileStatementSorting = ({ myBets }: AppState) => {
  return myBets.mobileStatementSorting;
};

export const getMobileSorting = ({ myBets }: AppState) => {
  return myBets.mobileSorting;
};

export const getMyBetsCancelBetStateValue =
  (id?: string) =>
  ({ myBets }: AppState) => {
    if (!id || !myBets.myBetsCancelBetsState[id]) {
      return {
        isLoading: false,
        isSuccess: false,
        isError: false
      };
    }

    return myBets.myBetsCancelBetsState[id];
  };

export const getExportNotification = ({ myBets }: AppState) => myBets.exportNotification;

export const getIsExportNotification = ({ myBets }: AppState) => myBets.exportNotification.error;

export const getMobileEditOfferId = ({ myBets }: AppState) => myBets.mobileEditOfferId;

export const getIsLoadingProducts = ({ myBets }: AppState) => myBets.loadingProducts;
export const getAreProductsLoaded = ({ myBets }: AppState) => myBets.areProductsLoaded;
