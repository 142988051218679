import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CookieNames } from 'constants/app';
import { cancelParam } from 'constants/betslip';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setSettings } from 'redux/modules/user';
import { getAccountSettingsConfirmBetsBeforePlacement, getIsUserAccountSettings } from 'redux/modules/user/selectors';

const useConfirmBets = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [cookies, setCookie] = useCookies([CookieNames.CONFIRM_BETS]);

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const isConfirmBetsBeforePlacementAccountSettingsEnabled = useSelector(getAccountSettingsConfirmBetsBeforePlacement);

  const { confirmBetsBeforePlace } = useDeviceSettings();
  const urlStringAfterLastSlash = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const isCookieEnabled = cookies[CookieNames.CONFIRM_BETS] === 'true';

  let isConfirmBetsBeforePlacement =
    confirmBetsBeforePlace &&
    ((isLoggedIn && isConfirmBetsBeforePlacementAccountSettingsEnabled) || (!isLoggedIn && isCookieEnabled));

  if (urlStringAfterLastSlash === cancelParam) {
    isConfirmBetsBeforePlacement = false;
  }

  if (confirmBetsBeforePlace) {
    if (isLoggedIn && isAccountSettings) {
      isConfirmBetsBeforePlacement = isConfirmBetsBeforePlacementAccountSettingsEnabled;
    } else if (cookies[CookieNames.CONFIRM_BETS]) {
      isConfirmBetsBeforePlacement = cookies[CookieNames.CONFIRM_BETS] === 'true';
    }
  }

  const saveConfirmBetsBeforePlacement = (confirmBetsBeforePlacement: boolean) => {
    if (isLoggedIn && isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            confirmBetsBeforePlacement: confirmBetsBeforePlacement
          }
        })
      );
    } else {
      setCookie(CookieNames.CONFIRM_BETS, confirmBetsBeforePlacement);
    }
  };

  return {
    isConfirmBetsEnabled: confirmBetsBeforePlace,
    isConfirmBetsBeforePlacement,
    saveConfirmBetsBeforePlacement
  };
};

export default useConfirmBets;
