import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { CookieNames } from 'constants/app';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, MY_BETS_BASE_URL } from 'constants/locations';
import { BetsTypesByPeriods, ExchangeTypes, MyBetsPeriods } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import { setMobileSorting, setSortBy } from 'redux/modules/myBets';
import { SortByFields } from 'redux/modules/myBets/type';
import { TimeFormats } from 'types';
import { BetType, MyBetsPeriod } from 'types/myBets';

export const useMyBetsFilters = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    CookieNames.EXCHANGE_TYPE_NEW,
    CookieNames.MY_BETS_TYPES,
    CookieNames.MY_BETS_SORT_BY,
    CookieNames.MY_BETS_SORT_ORDER,
    CookieNames.TIME_FORMAT
  ]);

  const navigate = useNavigate();
  const { periodType } = useParams();

  const { isAsianViewPage, isMobile } = useDevice();

  const getPeriodType = () => {
    if (periodType === MyBetsPeriods.Open.toLowerCase() || periodType === MyBetsPeriods.Settled.toLowerCase()) {
      return periodType === MyBetsPeriods.Open.toLowerCase() ? MyBetsPeriods.Open : MyBetsPeriods.Settled;
    } else {
      return MyBetsPeriods.Open;
    }
  };

  const navigateToPeriod = (period: MyBetsPeriod) => {
    navigate(
      `${isAsianViewPage ? ASIAN_BASE_URL : ''}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${period.toLowerCase()}/1`
    );
  };

  const setBetsTypes = (betsTypes: BetType[]) => {
    setCookie(CookieNames.MY_BETS_TYPES, betsTypes, { path: '/' });
  };

  const setPeriodType = (period: MyBetsPeriod) => {
    navigateToPeriod(period);

    if (!isMobile) {
      dispatch(
        setSortBy({
          type: period === MyBetsPeriods.Settled ? SortByFields.DATE : SortByFields.PLACED_DATE,
          value: 'desc'
        })
      );
    } else {
      dispatch(
        setMobileSorting({
          type: period === MyBetsPeriods.Settled ? SortByFields.DATE : SortByFields.PLACED_DATE,
          order: 'desc'
        })
      );
    }

    setBetsTypes(BetsTypesByPeriods[period]);
  };

  return {
    product: cookies.EXCHANGE_TYPE_NEW,
    periodType: getPeriodType(),
    betsTypes: cookies[CookieNames.MY_BETS_TYPES] ?? [],
    isAsianViewProduct: cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView,
    isCurrentPeriod: getPeriodType() === MyBetsPeriods.Open,
    isLocalTime: cookies[CookieNames.TIME_FORMAT] !== TimeFormats.BETTING_DAY,
    setPeriodType,
    setBetsTypes
  };
};
