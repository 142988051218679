import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices, POPULAR_MARKET_MAX_COUNT, POPULAR_MARKET_MIN_COUNT } from 'constants/app';
import withCollapse from 'hoc/withCollapse';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { getPopularMarkets } from 'redux/modules/popularMarket/selectors';
import { PlacementPage } from 'types';

import Market from './components/Market';

import styles from './styles.module.scss';

interface PopularMarketsProps {
  page?: PlacementPage;
}

const PopularMarkets = ({ page }: PopularMarketsProps) => {
  const markets = useSelector(getPopularMarkets);
  const device = useSelector(getAppDevice);

  const isDesktop = device === Devices.DESKTOP;

  if (!markets.length || (markets.length < POPULAR_MARKET_MIN_COUNT && markets.length > POPULAR_MARKET_MAX_COUNT)) {
    return null;
  }

  return (
    <div
      className={classNames('biab_inplay-sports-container', {
        [styles.popularMarketWrapper]: device === Devices.DESKTOP
      })}
    >
      {markets.map(market => (
        <Market market={market} key={market.event.id} notHighlighted={isDesktop} page={page} />
      ))}
    </div>
  );
};

export default withCollapse(PopularMarkets);
