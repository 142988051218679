import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { SportIds } from 'constants/app';
import { AsianViewMarketLinks, AsianViewTimeFilters } from 'constants/asianView';
import {
  ACCOUNT_BASE_URL,
  ASIAN_BASE_URL,
  CUSTOM_PAGES_BASE_URL,
  GAMES_BASE_URL,
  HOME_BASE_URL
} from 'constants/locations';
import { getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';

import styles from '../../styles.module.scss';

interface HeaderButtonsProps {
  isGames?: boolean;
  isAsianView?: boolean;
  sportEnabled?: boolean;
}

const HeaderButtons = ({ isGames, isAsianView, sportEnabled }: HeaderButtonsProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  const isGamesPage = location.pathname.includes(GAMES_BASE_URL);
  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const isAccountPage = location.pathname.includes(ACCOUNT_BASE_URL);
  const isCustomPage = location.pathname.includes(CUSTOM_PAGES_BASE_URL);

  if (isTimeOutEnabled) {
    return null;
  }

  return (
    <div id="biab_main-navigation" className={classNames('biab_main-navigation', styles.header__navigation)}>
      {isAsianView && (
        <div className="biab_main-navigation-item">
          <Link
            to={`${ASIAN_BASE_URL}/sport/${SportIds.SOCCER}/timeFilter/${AsianViewTimeFilters.Today}/marketLink/${AsianViewMarketLinks.HDP_AND_OU}`}
            className={classNames(styles.header__navigationItemLink, {
              [`biab_active ${styles.active}`]: isAsianViewPage && !isAccountPage && !isCustomPage
            })}
          >
            {t('header.navigation.asianView')}
          </Link>
        </div>
      )}
      {sportEnabled && (
        <div className="biab_main-navigation-item">
          <Link
            to={HOME_BASE_URL}
            className={classNames(styles.header__navigationItemLink, {
              [`biab_active ${styles.active}`]: !isAsianViewPage && !isGamesPage && !isAccountPage && !isCustomPage
            })}
          >
            {t('header.navigation.sports')}
          </Link>
        </div>
      )}
      {isGames && (
        <div className="biab_main-navigation-item">
          <Link
            to={GAMES_BASE_URL}
            className={classNames(styles.header__navigationItemLink, {
              [`biab_active ${styles.active}`]: isGamesPage && !isAccountPage && !isCustomPage
            })}
          >
            {t('header.navigation.games')}
          </Link>
        </div>
      )}
    </div>
  );
};

export default HeaderButtons;
