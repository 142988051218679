import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import CouponSettings from 'components/AsianViewPageModule/components/CouponSettings';
import ViewByDropdown from 'components/AsianViewPageModule/components/Filters/ViewByDropdown';
import LeaguesFilter from 'components/AsianViewPageModule/components/LeaguesFilter';
import AsianSearch from 'components/Header/components/AsianSearch';
import AsianToolbarWrapper from 'components/MobileToolbar/components/AsianToolbarWrapper';
import { ElementNames, SportIds } from 'constants/app';
import { asianViewMiddleSection } from 'constants/branding';
import useElementSize from 'hooks/useElementSize';
import { getAsianViewMobileNavigationMenu } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { fetchEventsCounter } from 'redux/modules/asianSportsNavigation';
import { getIsAsianSearchExpanded, getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';

import AsianViewMobileMenuButton from './partials/AsianViewMobileMenuButton';

import styles from './styles.module.scss';

const AsianViewMobileHeader = () => {
  const dispatch = useDispatch();
  const { sportId = SportIds.SOCCER } = useParams();

  const expanded = useSelector(getIsAsianSearchExpanded);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const asianViewMobileNavigationMenu = useSelector(getAsianViewMobileNavigationMenu);

  const isLandscapeToolbar = isLandscapeAsianView && asianViewMobileNavigationMenu;

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.MOBILE_ASIAN_SUB_HEADER, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.MOBILE_ASIAN_SUB_HEADER, height: 0 }));
    };
  }, []);

  useEffect(() => {
    dispatch(fetchEventsCounter({ sportId }));
  }, []);

  return (
    <AsianToolbarWrapper noLandscapeMargin>
      <div
        ref={componentRef}
        className={classNames(styles.main, asianViewMiddleSection.MOBILE_SUB_HEADER, {
          [styles.main__margin]: isLandscapeToolbar
        })}
      >
        <AsianViewMobileMenuButton />
        <AsianSearch />
        {!expanded && (
          <>
            <ViewByDropdown />
            <LeaguesFilter />
            <CouponSettings />
          </>
        )}
      </div>
    </AsianToolbarWrapper>
  );
};

export default AsianViewMobileHeader;
