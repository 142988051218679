import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { DEFAULT_GAMES_RESULT_PAGE, GameActionsTypes } from 'constants/games';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { fetchResultsHistory, setSelectedResultIndex } from 'redux/modules/games';
import { getSelectedIndex } from 'redux/modules/games/selectors';
import { THistory } from 'redux/modules/games/type';
import { applyTimezone, parseDateToTime } from 'utils/date';

import styles from './styles.module.scss';

interface IGamesResultsPagination {
  history: THistory;
}

const GamesResultsPagination = ({ history: { last, first, totalPages, number, content } }: IGamesResultsPagination) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gameId = '' } = useParams();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const selectedIndex = useSelector(getSelectedIndex);

  const getCurrentDate = (time: number) => {
    return applyTimezone(new Date(time), timezone, timezoneCookieEnabled);
  };

  const handleFirstClick = () => {
    if (last) {
      return;
    }
    dispatch(fetchResultsHistory({ channelId: gameId, page: totalPages - 1, actionType: GameActionsTypes.FIRST }));
  };

  const handlePreviousClick = () => {
    if (last) {
      return;
    }
    dispatch(fetchResultsHistory({ channelId: gameId, page: number + 1 }));
  };

  const handleNextClick = () => {
    if (first) {
      return;
    }
    dispatch(fetchResultsHistory({ channelId: gameId, page: number - 1 }));
  };

  const handleLastClick = () => {
    if (first) {
      return;
    }
    dispatch(
      fetchResultsHistory({ channelId: gameId, page: DEFAULT_GAMES_RESULT_PAGE, actionType: GameActionsTypes.LAST })
    );
  };

  const handlePageClick = (index: number) => {
    dispatch(setSelectedResultIndex(index));
  };

  return (
    <div className={'biab_pagination-wrapper'}>
      <div className={classNames('biab_pagination-links', styles.main)}>
        <div className={styles.leftSide}>
          <span
            className={classNames('biab_pagination-link', styles.paginationLink, {
              [`disabled ${styles.disabled}`]: last
            })}
            onClick={handleFirstClick}
          >
            <i className={'fa fa-angle-double-left'} />
            <div>{t('pagination.first')}</div>
          </span>
          <span
            className={classNames('biab_pagination-link', styles.paginationLink, {
              [`disabled ${styles.disabled}`]: last
            })}
            onClick={handlePreviousClick}
          >
            <i className={'fa fa-angle-left'} />
            <div>{t('pagination.previous')}</div>
          </span>
        </div>
        <div className={classNames('biab_pagination-games', styles.pages)}>
          {content.map((i, index) => (
            <div
              className={classNames('biab_pagination-link', styles.paginationLink, {
                biab_active: index === selectedIndex
              })}
              key={`${i.startDate}-${index}`}
              onClick={() => handlePageClick(index)}
            >
              {parseDateToTime(getCurrentDate(i.startDate))}
            </div>
          ))}
        </div>
        <div className={styles.rightSide}>
          <span
            className={classNames('biab_pagination-link', styles.paginationLink, {
              [`disabled ${styles.disabled}`]: first
            })}
            onClick={handleNextClick}
          >
            <div>{t('pagination.next')}</div>
            <i className={'fa fa-angle-right'} />
          </span>
          <span
            className={classNames('biab_pagination-link', styles.paginationLink, {
              [`disabled ${styles.disabled}`]: first
            })}
            onClick={handleLastClick}
          >
            <div>{t('pagination.last')}</div>
            <i className={'fa fa-angle-double-right'} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default GamesResultsPagination;
