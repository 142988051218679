import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AutoCashOutStatuses } from 'constants/app';
import useDevice from 'hooks/useDevice';
import {
  asianBetSlipCashOutCloseSettingsTab,
  asianBetSlipRemoveSuccessFullCreatedAutoCashOut,
  asianBetSlipRemoveSuccessFullDeletedAutoCashOut
} from 'redux/modules/asianViewBetSlipCashOut';
import {
  getAsianBetSlipAutoCashOutStatusByMarketId,
  getAsianBetSlipCashOutCashedValueByMarketId,
  getAsianBetSlipCashOutPossibleProfitByMarketId,
  getAsianBetSlipCashOutSuccessFullCreatedAutoCashOutByMarketId,
  getAsianBetSlipCashOutSuccessFullDeletedAutoCashOutByMarketId
} from 'redux/modules/asianViewBetSlipCashOut/selectors';
import {
  asianViewCashOutCloseSettingsTab,
  asianViewRemoveSuccessFullCreatedAutoCashOut,
  asianViewRemoveSuccessFullDeletedAutoCashOut
} from 'redux/modules/asianViewCashOut';
import {
  getAsianViewAutoCashOutStatusByMarketId,
  getAsianViewCashOutCashedValueByMarketId,
  getAsianViewCashOutPossibleProfitByMarketId,
  getAsianViewCashOutSuccessFullCreatedAutoCashOutByMarketId,
  getAsianViewCashOutSuccessFullDeletedAutoCashOutByMarketId
} from 'redux/modules/asianViewCashOut/selectors';
import {
  closeSettingsTab,
  removeSuccessFullCreatedAutoCashOut,
  removeSuccessFullDeletedAutoCashOut
} from 'redux/modules/cashOut';
import {
  getAutoCashOutProfitDataByMarketId,
  getAutoCashOutStatus,
  getCashedValueByMarket,
  getSuccessFullCreatedAutoCashOut,
  getSuccessFullDeletedAutoCashOuts
} from 'redux/modules/cashOut/selectors';
import {
  eventCashOutCloseSettingsTab,
  eventRemoveSuccessFullCreatedAutoCashOut,
  eventRemoveSuccessFullDeletedAutoCashOut
} from 'redux/modules/eventCashOut';
import {
  getEventAutoCashOutStatusByMarketId,
  getEventCashOutCashedValueByMarketId,
  getEventCashOutPossibleProfitByMarketId,
  getEventCashOutSuccessFullCreatedAutoCashOutByMarketId,
  getEventCashOutSuccessFullDeletedAutoCashOutByMarketId
} from 'redux/modules/eventCashOut/selectors';

import ExistingCashOutState from './partials/ExistingCashOutState';
import NewCashOutState from './partials/NewCashOutState';
import TemporaryState from './partials/TemporaryState';

interface INewCashOutState {
  marketId: string;
  isAsianBetSlip?: boolean;
  isEvent?: boolean;
  isAVMobileCashOutPage?: boolean;
  isAsianView?: boolean;
  isAVMobileBetList?: boolean;
  ignoreFlexibleDisplayFormat?: boolean;
}

const CashOutState = ({
  marketId,
  isAsianBetSlip,
  isEvent = false,
  isAVMobileCashOutPage = false,
  isAsianView = false,
  isAVMobileBetList = false,
  ignoreFlexibleDisplayFormat = false
}: INewCashOutState) => {
  const dispatch = useDispatch();

  const autoCashOutProfitDataSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutPossibleProfitByMarketId
    : isEvent
    ? getEventCashOutPossibleProfitByMarketId
    : isAsianView
    ? getAsianViewCashOutPossibleProfitByMarketId
    : getAutoCashOutProfitDataByMarketId;
  const autoCashOutStatusSelector = isAsianBetSlip
    ? getAsianBetSlipAutoCashOutStatusByMarketId
    : isEvent
    ? getEventAutoCashOutStatusByMarketId
    : isAsianView
    ? getAsianViewAutoCashOutStatusByMarketId
    : getAutoCashOutStatus;
  const successFullCreatedAutoCashOutSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutSuccessFullCreatedAutoCashOutByMarketId
    : isEvent
    ? getEventCashOutSuccessFullCreatedAutoCashOutByMarketId
    : isAsianView
    ? getAsianViewCashOutSuccessFullCreatedAutoCashOutByMarketId
    : getSuccessFullCreatedAutoCashOut;
  const successFullDeletedAutoCashOutSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutSuccessFullDeletedAutoCashOutByMarketId
    : isEvent
    ? getEventCashOutSuccessFullDeletedAutoCashOutByMarketId
    : isAsianView
    ? getAsianViewCashOutSuccessFullDeletedAutoCashOutByMarketId
    : getSuccessFullDeletedAutoCashOuts;
  const cashedValueSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutCashedValueByMarketId
    : isEvent
    ? getEventCashOutCashedValueByMarketId
    : isAsianView
    ? getAsianViewCashOutCashedValueByMarketId
    : getCashedValueByMarket;

  const autoCashOutProfitData = useSelector(autoCashOutProfitDataSelector(marketId));
  const autoCashOutStatus = useSelector(autoCashOutStatusSelector(marketId));
  const successFullCreatedAutoCashOut = useSelector(successFullCreatedAutoCashOutSelector(marketId));
  const successFullDeletedAutoCashOuts = useSelector(successFullDeletedAutoCashOutSelector(marketId));
  const cashedValue = useSelector(cashedValueSelector(marketId));

  const { isMobile } = useDevice();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (successFullCreatedAutoCashOut) {
      timeout = setTimeout(() => {
        if (isAsianBetSlip) {
          dispatch(asianBetSlipRemoveSuccessFullCreatedAutoCashOut(marketId));
        } else if (isEvent) {
          dispatch(eventRemoveSuccessFullCreatedAutoCashOut(marketId));
        } else if (isAsianView) {
          dispatch(asianViewRemoveSuccessFullCreatedAutoCashOut(marketId));
        } else {
          dispatch(removeSuccessFullCreatedAutoCashOut(marketId));
        }
        if (isMobile) {
          if (isAsianBetSlip) {
            dispatch(asianBetSlipCashOutCloseSettingsTab(marketId));
          } else if (isEvent) {
            dispatch(eventCashOutCloseSettingsTab(marketId));
          } else if (isAsianView) {
            dispatch(asianViewCashOutCloseSettingsTab(marketId));
          } else {
            dispatch(closeSettingsTab(marketId));
          }
        }
      }, 3000);
    }
    if (successFullDeletedAutoCashOuts) {
      timeout = setTimeout(() => {
        if (isAsianBetSlip || isEvent || isAsianView) {
          dispatch(asianBetSlipRemoveSuccessFullDeletedAutoCashOut(marketId));
          dispatch(eventRemoveSuccessFullDeletedAutoCashOut(marketId));
          dispatch(asianViewRemoveSuccessFullDeletedAutoCashOut(marketId));
        } else {
          dispatch(removeSuccessFullDeletedAutoCashOut(marketId));
        }
        if (isMobile) {
          if (isAsianBetSlip) {
            dispatch(asianBetSlipCashOutCloseSettingsTab(marketId));
          } else if (isEvent) {
            dispatch(eventCashOutCloseSettingsTab(marketId));
          } else if (isAsianView) {
            dispatch(asianViewCashOutCloseSettingsTab(marketId));
          } else {
            dispatch(closeSettingsTab(marketId));
          }
        }
      }, 3000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [marketId, successFullDeletedAutoCashOuts, successFullCreatedAutoCashOut, isMobile]);

  if (successFullCreatedAutoCashOut) {
    return (
      <TemporaryState
        value={autoCashOutProfitData?.value}
        currentCase={AutoCashOutStatuses.NEW_CREATED}
        isAsianBetSlip={isAsianBetSlip}
        isAVMobileCashOutPage={isAVMobileCashOutPage}
        isAVMobileBetList={isAVMobileBetList}
        isEvent={isEvent}
        isAsianView={isAsianView}
        ignoreFlexibleDisplayFormat={ignoreFlexibleDisplayFormat}
      />
    );
  }

  if (successFullDeletedAutoCashOuts) {
    return (
      <TemporaryState
        value={autoCashOutProfitData?.value || cashedValue}
        currentCase={AutoCashOutStatuses.EXISTS_REMOVED}
        isAsianBetSlip={isAsianBetSlip}
        isAVMobileCashOutPage={isAVMobileCashOutPage}
        isAVMobileBetList={isAVMobileBetList}
        isEvent={isEvent}
        isAsianView={isAsianView}
        ignoreFlexibleDisplayFormat={ignoreFlexibleDisplayFormat}
      />
    );
  }

  const getAutoCashOutContent = () => {
    switch (autoCashOutStatus) {
      case AutoCashOutStatuses.ACTIVE:
      case AutoCashOutStatuses.IN_PROGRESS:
        return (
          <ExistingCashOutState
            marketId={marketId}
            isAsianBetSlip={isAsianBetSlip}
            isEvent={isEvent}
            isAVMobileCashOutPage={isAVMobileCashOutPage}
            isAsianView={isAsianView}
            isAVMobileBetList={isAVMobileBetList}
            ignoreFlexibleDisplayFormat={ignoreFlexibleDisplayFormat}
          />
        );
      default:
        return (
          <NewCashOutState
            marketId={marketId}
            isAsianBetSlip={isAsianBetSlip}
            isEvent={isEvent}
            isAVMobileCashOutPage={isAVMobileCashOutPage}
            isAsianView={isAsianView}
            isAVMobileBetList={isAVMobileBetList}
          />
        );
    }
  };

  return getAutoCashOutContent();
};

export default CashOutState;
