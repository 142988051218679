import { BettingTypes } from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BettingType } from 'types/markets';

export const isShowPLTablePopup = (bettingType: BettingType, marketType: string) => {
  return !(
    bettingType === BettingTypes.LINE ||
    marketType === MARKET_TYPES.altTotalGoals ||
    marketType === MARKET_TYPES.combinedTotal
  );
};
