import { BETTING_TYPES, MAX_PRICE, MIN_PRICE } from 'constants/app';
import { dontShowWhatIf, MARKET_TYPES } from 'constants/marketTypes';
import { BetSides } from 'constants/myBets';
import { TNewWhatValue } from 'redux/modules/whatIf/type';
import { round } from 'utils/liability';

export const isAnyNumberOfWinners = (marketType: string) => {
  return (
    marketType === MARKET_TYPES.anyNumberOfWinners ||
    marketType === MARKET_TYPES.matchShots ||
    marketType === MARKET_TYPES.matchShotsTarget ||
    marketType === MARKET_TYPES.toScoreHattrick ||
    marketType === MARKET_TYPES.anytimeAssist ||
    marketType === MARKET_TYPES.shotsOnTargetP1 ||
    marketType === MARKET_TYPES.shotsOnTargetP2 ||
    marketType === MARKET_TYPES.shotsOnTargetP3 ||
    marketType === MARKET_TYPES.shownACard ||
    marketType === MARKET_TYPES.toScore ||
    marketType === MARKET_TYPES.toScore2OrMore
  );
};

const isValidPrice = (price: number | undefined) => price && (price > MAX_PRICE || price < MIN_PRICE);

export const isNoMoreOneWinners = ({
  numberOfWinners,
  marketType
}: {
  numberOfWinners: number;
  marketType: string;
}) => {
  return (numberOfWinners === 1 || numberOfWinners === 0) && !isAnyNumberOfWinners(marketType);
};

const compareHandicap = (handicap1: string, handicap2: string) => {
  return parseFloat(handicap1) === parseFloat(handicap2);
};

const getEachWayProfit = (size: number, price: number, divider: number, type: string) => {
  size = size || 0;
  price = price || 0;
  divider = divider || 1;

  let profitWin = 0,
    profitPlace = 0,
    profitLose = 0;
  const ewPrice = 1 + (price - 1) / divider;

  switch (type.toLowerCase()) {
    case 'back':
      profitWin = size * (price - 1) + size * (ewPrice - 1);
      profitPlace = -size + size * (ewPrice - 1);
      profitLose = -2 * size;
      break;
    case 'lay':
      profitWin = -size * (price - 1) - size * (ewPrice - 1);
      profitPlace = size - size * (ewPrice - 1);
      profitLose = 2 * size;
      break;
  }

  return {
    win: profitWin,
    placed: profitPlace,
    lose: profitLose
  };
};

const calculateSingleMarket = ({
  bets,
  selectionId,
  handicap = '',
  isTie = false
}: {
  bets: TNewWhatValue[];
  selectionId: number;
  handicap?: number | string;
  isTie?: boolean;
}) => {
  let unmatchedPL: number | undefined = undefined;

  bets.forEach(bet => {
    const betAveragePrice = bet.averagePrice ? +bet.averagePrice : 0;
    if (!bet.sizeMatched || isValidPrice(betAveragePrice)) {
      return;
    }

    const betSizeMatched = bet.sizeMatched || 0;
    const type = bet.side;
    const isHandicap = compareHandicap(handicap.toString(), (bet.handicap ?? 0).toString());

    if (+bet.handicap === handicap && bet.selectionId === selectionId) {
      if (type === BetSides.Back) {
        unmatchedPL = (unmatchedPL || 0) + round((betAveragePrice - 1) * betSizeMatched, 2);
      } else {
        unmatchedPL = (unmatchedPL || 0) - round((betAveragePrice - 1) * betSizeMatched, 2);
      }
    } else if (isHandicap) {
      if (isTie) {
        unmatchedPL = 0;
      } else if (type === BetSides.Back) {
        unmatchedPL = (unmatchedPL || 0) - round(betSizeMatched, 2);
      } else {
        unmatchedPL = (unmatchedPL || 0) + round(betSizeMatched, 2);
      }
    }
  });

  return [...(unmatchedPL !== undefined ? [`${unmatchedPL}`] : [])];
};

const calculateMultiMarket = ({
  bets,
  selectionId,
  eachWayDivisor
}: {
  bets: TNewWhatValue[];
  selectionId: number;
  eachWayDivisor: number | null;
}) => {
  let unmatchedPL1: number | undefined;
  let unmatchedPL2: number | undefined;
  let unmatchedPL3: number | undefined;

  bets.forEach(bet => {
    if (!bet.size) {
      return;
    }

    const betAveragePrice = bet.averagePrice ? +bet.averagePrice : 0;
    const betSizeMatched = bet.sizeMatched || 0;
    const type = bet.side;

    if (bet.selectionId === selectionId) {
      if (eachWayDivisor) {
        const EW = getEachWayProfit(betSizeMatched, betAveragePrice, eachWayDivisor, type);

        unmatchedPL1 = (unmatchedPL1 ?? 0) + EW.win;
        unmatchedPL2 = (unmatchedPL2 ?? 0) + EW.placed;
        unmatchedPL3 = (unmatchedPL3 ?? 0) + EW.lose;
      } else {
        if (type === BetSides.Back) {
          unmatchedPL1 = (unmatchedPL1 || 0) + round((betAveragePrice - 1) * betSizeMatched, 2);
          unmatchedPL2 = (unmatchedPL2 || 0) - round(betSizeMatched, 2);
        } else {
          unmatchedPL1 = (unmatchedPL1 || 0) - round((betAveragePrice - 1) * betSizeMatched, 2);
          unmatchedPL2 = (unmatchedPL2 || 0) + round(betSizeMatched, 2);
        }
      }
    }
  });

  return [
    ...(unmatchedPL1 !== undefined ? [unmatchedPL1.toFixed(2)] : []),
    ...(unmatchedPL2 !== undefined ? [unmatchedPL2.toFixed(2)] : []),
    ...(unmatchedPL3 !== undefined ? [unmatchedPL3.toFixed(2)] : [])
  ];
};

export const getCurrentWhatIf = ({
  bets = [],
  selectionId,
  handicap = 0,
  numberOfWinners = 0,
  isNew,
  eachWayDivisor = null,
  isTie = false
}: {
  bets: TNewWhatValue[];
  selectionId: number;
  handicap?: number;
  numberOfWinners?: number;
  isNew?: boolean;
  isTie?: boolean;
  eachWayDivisor?: number | null;
}) => {
  const type = bets[0]?.marketType;
  const bettingType = bets[0]?.bettingType;
  if (
    bets.length === 0 ||
    dontShowWhatIf.includes(type as typeof dontShowWhatIf[number]) ||
    bettingType === BETTING_TYPES.line ||
    bettingType === MARKET_TYPES.asianHandicapSingleLine
  ) {
    return [];
  }

  const isOneWinner = isNoMoreOneWinners({
    marketType: type,
    numberOfWinners: bets[0].numberOfWinners || numberOfWinners
  });

  const data = {
    bets: isOneWinner ? bets : isNew && bets.length === 1 ? [] : bets,
    eachWayDivisor,
    selectionId,
    handicap,
    isTie
  };

  return isOneWinner ? calculateSingleMarket(data) : calculateMultiMarket(data);
};
