import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import GameLoader from 'components/GameLoader';
import GamesRoundsList from 'components/GamesRoundsList';
import PlayerCardsTable from 'components/PlayerCardsTable';
import { Devices } from 'constants/app';
import { BLACKJACK_GAMES_ROUNDS, GamePages, GameTypes } from 'constants/games';
import { MyBetsLocations } from 'constants/myBets';
import {
  getAppDevice,
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { getCurrentGameRound, getCurrentGameRoundPercent, getSelectedGamePage } from 'redux/modules/games/selectors';
import { TGameData } from 'redux/modules/games/type';
import { timeStampToStartDate } from 'utils/date';

import styles from './styles.module.scss';

interface IGameCartsTableBlackjack {
  gameData: TGameData[];
  startDate?: number;
}

const GameCartsTableBlackjack = ({ gameData, startDate }: IGameCartsTableBlackjack) => {
  const location = useLocation();
  const { t } = useTranslation();

  const selectedGamePage = useSelector(getSelectedGamePage);
  const round = useSelector(getCurrentGameRound);
  const percent = useSelector(getCurrentGameRoundPercent);
  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const isMyBetsPage = location.pathname.includes(MyBetsLocations.MyBets);
  const isResultPage = selectedGamePage === GamePages.RESULTS || isMyBetsPage;
  const startedParsedDate = timeStampToStartDate(startDate, americanDateFormatEnabled, timezoneCookieEnabled, timezone);

  const [player1, player2, player3, player4, dealer] = gameData;

  return (
    <div
      className={classNames({
        'biab_games-wrapper': !isResultPage,
        [styles.myBetsResults]: isMyBetsPage
      })}
    >
      <div className={styles.main}>
        {isResultPage && (
          <div className={classNames('biab_games-start-date', styles.title)}>
            {t('games.results.started')}: {isMyBetsPage ? <strong>{startedParsedDate}</strong> : startedParsedDate}
          </div>
        )}
        {device === Devices.MOBILE ? (
          <div className={styles.roundAndLoader}>
            {!isResultPage && <GamesRoundsList gamesRounds={BLACKJACK_GAMES_ROUNDS} currentRound={round || 0} />}
            <div className={classNames(styles.row1Wrapper)}>
              {!isResultPage && <GameLoader loaderPercentage={percent || 0} />}
            </div>
          </div>
        ) : (
          <>
            {!isResultPage && <GamesRoundsList gamesRounds={BLACKJACK_GAMES_ROUNDS} currentRound={round || 0} />}
            <div className={classNames(styles.row1Wrapper)}>
              <PlayerCardsTable gameType={GameTypes.BLACKJACK} isDealer gameData={dealer} />
              {!isResultPage && <GameLoader loaderPercentage={percent || 0} />}
            </div>
          </>
        )}
        <div
          className={classNames(styles.row2Wrapper, {
            [styles.resultsRow2]: isResultPage
          })}
        >
          <PlayerCardsTable gameType={GameTypes.BLACKJACK} gameData={player1} />
          {device === Devices.MOBILE && <PlayerCardsTable gameType={GameTypes.BLACKJACK} isDealer gameData={dealer} />}
          <PlayerCardsTable gameType={GameTypes.BLACKJACK} gameData={player4} />
        </div>
        <div className={classNames(styles.row3Wrapper)}>
          <PlayerCardsTable gameType={GameTypes.BLACKJACK} gameData={player2} />
          <PlayerCardsTable gameType={GameTypes.BLACKJACK} gameData={player3} />
        </div>
      </div>
    </div>
  );
};

export default GameCartsTableBlackjack;
