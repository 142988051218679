import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import CashOut from 'components/CashOut';
import InPlayStatus from 'components/InPlayStatus';
import { Devices } from 'constants/app';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getAppDevice, getIsCashOutEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsMarketInPlayByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { IMarketRules, MarketCollapseRowClasses } from 'types/markets';

import MarketCollapseRowRules from './components/MarketCollapseRowRules';

import styles from './styles.module.scss';

interface MarketCollapseRowProps {
  /**
   * Market title
   */
  children: ReactNode;
  /**
   * Market ID
   */
  marketId: string;
  /**
   * Is market in play or not from market data
   */
  inPlay: boolean;
  /**
   * Market start time
   */
  marketStartTime: number;
  /**
   * Market name
   */
  marketName: string;
  /**
   * Market sport id
   */
  sportId: string;
  /**
   * Will be invoked when collapse row is clicked
   */
  onMarketCollapse?: () => void;
  /**
   * Method for requesting rules for a particular market
   * @param marketId
   */
  onFetchMarketRules?: (marketId: string, sportId: string) => void;
  /**
   * Determines if rules are loaded or not
   */
  rulesLoading?: boolean;
  /**
   * Determines is date will be shown or not
   */
  isDateEnable?: boolean;
  /**
   * BIAB class name for title
   */
  className?: string;
  /**
   * Classes to customize component styles
   */
  classes?: MarketCollapseRowClasses;
  /**
   * Icon for right side
   */
  rightIcon?: ReactNode;
  /**
   * Markets rules collection
   */
  marketsRules?: Record<string, IMarketRules> | null;
  /**
   * BIAB className for in play icon
   */
  inPlayClassName?: string;
  /**
   * Is cash out enabled for single market
   */
  isMarketCashOutEnabled?: boolean;
  /**
   * Should in play status be at the end or at the beginning
   */
  inPlayStatusFirst?: boolean;
}

const MarketCollapseRow = ({
  marketId,
  rulesLoading,
  inPlay,
  marketStartTime,
  marketName,
  onFetchMarketRules,
  onMarketCollapse,
  isDateEnable = false,
  sportId,
  children,
  className,
  classes,
  rightIcon,
  marketsRules,
  inPlayClassName,
  isMarketCashOutEnabled,
  inPlayStatusFirst = false
}: MarketCollapseRowProps) => {
  const device = useSelector(getAppDevice);
  const cashOutEnabled = useSelector(getIsCashOutEnabled);
  const marketPricesInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const isInPlay = inPlay || marketPricesInPlay;

  const marketTime = useLongDateFormat({
    time: marketStartTime ?? 0,
    hasYear: true,
    divider: ', '
  });

  const isDesktop = device === Devices.DESKTOP;

  const handleCollapseToggle = () => {
    if (onMarketCollapse) {
      onMarketCollapse();
    }
  };

  const inPlayStatus = (
    <div className={styles.marketHeader__status}>
      <InPlayStatus isInPlay={isInPlay} className={inPlayClassName} />
    </div>
  );

  return (
    <div
      onClick={handleCollapseToggle}
      className={classNames('biab_search-item-wrapper marketHeader__header', styles.marketHeader__header, className, {
        'biab_event-market-title': !className,
        [classes?.header ?? '']: classes?.header
      })}
    >
      <div className={classNames(styles.marketHeader__header__collapse, 'marketHeader__header__collapse')}>
        {children}
      </div>
      <div className={classNames('biab_event-icons', styles.marketHeader__header__icons)}>
        {!isInPlay && isDateEnable && <span>{marketTime}</span>}
        {inPlayStatusFirst && isDesktop && inPlayStatus}
        {inPlayStatusFirst && (
          <div className={classNames(styles.marketHeader__header__cashoutWrapper)}>
            {cashOutEnabled && isMarketCashOutEnabled && <CashOut showLabel={false} showInfoIcon={false} />}
          </div>
        )}
        {isDesktop && (
          <>
            <MarketCollapseRowRules
              marketId={marketId}
              sportId={sportId}
              onFetchMarketRules={onFetchMarketRules}
              marketsRules={marketsRules}
              marketTime={marketTime}
              marketName={marketName}
              rulesLoading={rulesLoading}
            />
          </>
        )}
        {!inPlayStatusFirst && cashOutEnabled && isMarketCashOutEnabled && (
          <CashOut showLabel={false} showInfoIcon={false} />
        )}
        {!inPlayStatusFirst && isDesktop && inPlayStatus}
        {rightIcon}
      </div>
    </div>
  );
};

export default MarketCollapseRow;
