import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import Button from 'components/Button';
import { CancelActionStatuses, EXCHANGE, GAME } from 'constants/app';
import { CancelAllBtnStates } from 'constants/betslip';
import { betslipBranding, componentsBranding, mobileAccountPagesBranding } from 'constants/branding';
import {
  CANCEL_ACTION_FETCHING_TIMEOUT,
  HIDE_ERROR_NOTIFICATION_TIMEOUT,
  HIDE_SUCCESS_NOTIFICATION_TIMEOUT
} from 'constants/placement';
import useDevice from 'hooks/useDevice';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import {
  getBalanceWsEnabled,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled
} from 'redux/modules/appConfigs/selectors';
import {
  fetchCancelActionStatus,
  removeCancelActionStatus,
  setCancelAllBtnState,
  setCancelBetsNotification
} from 'redux/modules/cancelActions';
import { getCancelActionStatus, getCancelBetsNotification, getLoading } from 'redux/modules/cancelActions/selectors';
import { setCurrentBetsListCanBeRemoved } from 'redux/modules/currentBets';
import { getCurrentUnmatchedBets } from 'redux/modules/currentBets/selectors';
import { cancelAllBets, successCancelAllBets } from 'redux/modules/placement';
import { getCancelledBetsStatusId } from 'redux/modules/placement/selectors';
import { fetchBalance } from 'redux/modules/user';

import styles from './CancelAllUnmatchedBets.module.scss';

const CancelAllUnmatchedBets = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isMobile } = useDevice();
  const loading = useSelector(getLoading);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const cancelledBetsStatusId = useSelector(getCancelledBetsStatusId);
  const cancelActionStatus = useSelector(getCancelActionStatus);
  const cancelBetsNotification = useSelector(getCancelBetsNotification);
  const unmatchedBets = useSelector(getCurrentUnmatchedBets({ ignoreCancelled: true, ignoreFullyMatchedAction: true }));
  const numberOfUnmatchedBets = unmatchedBets.length;
  const inProgress = cancelBetsNotification?.type === 'progress';

  const { refreshData } = useMyBetsPagesNavigation();

  const [showDialog, setShowDialog] = useState(false);
  const [expectedNumber, setExpectedNumber] = useState(0);
  const isError = cancelBetsNotification && cancelBetsNotification.type === 'error';
  const isSuccess =
    (cancelActionStatus?.status === CancelActionStatuses.SUCCESSFUL ||
      cancelActionStatus?.status === CancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED) &&
    cancelActionStatus.expectedNumberToCancel === cancelActionStatus.actualNumberCancelled;

  const timeoutValueRef = useRef(0);

  if (cancelActionStatus) {
    timeoutValueRef.current = isSuccess ? HIDE_SUCCESS_NOTIFICATION_TIMEOUT : HIDE_ERROR_NOTIFICATION_TIMEOUT;
  }

  const ref = useRef<HTMLDivElement>(null);
  const closeTimeOutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const statusesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  useOnClickOutside(ref, () => setShowDialog(false));

  const handleClickCancelAllPreview = () => {
    setShowDialog(true);
  };

  const handleCancelAllError = () => {
    dispatch(setCancelBetsNotification({ type: 'error', text: getMessage() }));
  };

  const onCloseNotification = () => {
    dispatch(setCancelBetsNotification(null));
  };

  const cancelAllHandler = () => {
    dispatch(
      setCancelBetsNotification({
        type: 'progress',
        text: t('marketBetslip.messages.cancellingBets', {
          N: `<strong>${cancelActionStatus?.expectedNumberToCancel || numberOfUnmatchedBets}</strong>`,
          interpolation: { escapeValue: false }
        })
      })
    );
    setExpectedNumber(numberOfUnmatchedBets);
    dispatch(setCancelAllBtnState(CancelAllBtnStates.CANCELLING));
    dispatch(
      cancelAllBets({
        data: { betTypes: [GAME, EXCHANGE] },
        errorCallback: handleCancelAllError
      })
    );

    setShowDialog(false);
  };

  const clearStatusInterval = () => {
    if (statusesInterval.current) {
      clearInterval(statusesInterval.current);
    }
  };

  const getMessage = () => {
    return t('marketBetslip.messages.cancelledAllBets', {
      NUMBER_OF_CANCELLED: `<strong>${cancelActionStatus?.actualNumberCancelled || 0}</strong>`,
      NUMBER_OF_ALL: `<strong>${cancelActionStatus?.expectedNumberToCancel || expectedNumber}</strong>`,
      interpolation: { escapeValue: false }
    });
  };

  useEffect(() => {
    if (cancelledBetsStatusId) {
      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }

      statusesInterval.current = setInterval(() => {
        if (!loadingRef.current) {
          dispatch(
            fetchCancelActionStatus({
              cancelActionId: cancelledBetsStatusId,
              onSuccess: response => {
                if (
                  (response.status === CancelActionStatuses.SUCCESSFUL ||
                    response.status === CancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED) &&
                  response.offerIds
                ) {
                  dispatch(
                    setCurrentBetsListCanBeRemoved(
                      response.offerIds.map(offerId => {
                        return {
                          offerId,
                          canBeRemoved: true
                        };
                      })
                    )
                  );
                }
              }
            })
          );
        }
      }, CANCEL_ACTION_FETCHING_TIMEOUT);
    }
  }, [cancelledBetsStatusId]);

  useEffect(() => {
    if (cancelActionStatus) {
      if (
        cancelActionStatus.status === CancelActionStatuses.SUCCESSFUL ||
        cancelActionStatus.status === CancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED
      ) {
        dispatch(setCancelBetsNotification({ type: isSuccess ? 'success' : 'error', text: getMessage() }));
        dispatch(removeCancelActionStatus());

        clearStatusInterval();
        dispatch(successCancelAllBets(null));
        dispatch(setCancelAllBtnState(CancelAllBtnStates.HIDDEN));
      } else if (cancelActionStatus.status === CancelActionStatuses.FAILED) {
        dispatch(setCancelBetsNotification({ type: 'error', text: getMessage() }));
        clearStatusInterval();
      }
    }
  }, [cancelActionStatus?.id, cancelActionStatus?.status]);

  useEffect(() => {
    if (cancelBetsNotification !== null) {
      if (closeTimeOutRef.current) {
        clearTimeout(closeTimeOutRef.current);
        closeTimeOutRef.current = null;
      }

      if (!inProgress) {
        const timeout =
          cancelBetsNotification.type === 'success'
            ? HIDE_SUCCESS_NOTIFICATION_TIMEOUT
            : HIDE_ERROR_NOTIFICATION_TIMEOUT;

        closeTimeOutRef.current = setTimeout(() => {
          onCloseNotification();
        }, timeout);

        refreshData();
      }
    }

    return () => {
      if (closeTimeOutRef.current) {
        clearTimeout(closeTimeOutRef.current);
        closeTimeOutRef.current = null;
      }
    };
  }, [cancelBetsNotification]);

  useEffect(() => {
    return () => {
      clearStatusInterval();
      dispatch(successCancelAllBets(null));
      dispatch(removeCancelActionStatus());
    };
  }, []);

  if (inProgress || cancelBetsNotification !== null) {
    return (
      <div
        className={classNames(styles.notification, componentsBranding.NOTIFICATION, {
          [componentsBranding.ERROR]: !inProgress && isError,
          [styles.notification__error]: !inProgress && isError,
          [styles.notification__mobile]: isMobile,
          [styles.notification__desktop]: !isMobile
        })}
      >
        <div
          className={classNames(styles.notification__body, {
            [styles.notification__mobile]: isMobile
          })}
        >
          <div className={styles.notification__icon}>
            {inProgress && (
              <i
                className={classNames(
                  'fa fa-spinner fa-pulse fa-fw',
                  styles.notification__icon__loading,
                  betslipBranding.SPINNER_ICON
                )}
              />
            )}
            {!inProgress && isError && (
              <i className={classNames('biab_custom-icon-warning-circle', betslipBranding.WARNING_ICON)} />
            )}
            {!isError && !inProgress && (
              <i className={classNames('biab_custom-icon-info-circle', betslipBranding.INFO_ICON)} />
            )}
          </div>
          <div dangerouslySetInnerHTML={{ __html: cancelBetsNotification?.text }} />
          {!inProgress && (
            <div className={classNames(styles.notification__close, styles.close)}>
              <i
                onClick={() => onCloseNotification()}
                className={classNames('biab_custom-icon-close', styles.notification__close__icon)}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  if (numberOfUnmatchedBets === 0 && cancelBetsNotification === null) {
    return null;
  }

  return (
    <div
      className={classNames(styles.cancelAll, {
        [styles.cancelAll__mobile]: isMobile,
        [betslipBranding.BETSLIP_WRAP]: !isMobile
      })}
      ref={ref}
    >
      <button
        className={classNames(styles.cancelAll__btn, {
          [styles.cancelAll__btn__mobile]: isMobile,
          [betslipBranding.CANCEL_ALL_LINK]: !isMobile,
          [mobileAccountPagesBranding.CANCEL_ALL_UNMATCHED_BETS_LINK]: isMobile
        })}
        onClick={handleClickCancelAllPreview}
      >
        {t('account.myBets.labels.cancelAllUnmatchedBets')} ({numberOfUnmatchedBets})
      </button>
      {showDialog && (
        <div className={classNames(styles.cancelAll__dialog, { [styles.cancelAll__dialog__mobile]: isMobile })}>
          <p className={styles.cancelAll__dialog__ensureText}>{t('betslip.labels.ensureToCancelAllUnmatched')}</p>
          <div className={styles.cancelAll__dialog__buttons}>
            <Button className={styles.cancelAll__dialog__btn} onClick={() => setShowDialog(false)} variant="secondary">
              {t('betslip.labels.type.no')}
            </Button>
            <Button onClick={cancelAllHandler} className={styles.cancelAll__dialog__btn}>
              {t('betslip.labels.type.yes')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CancelAllUnmatchedBets;
