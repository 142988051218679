import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { AV_SUCCESS_STATUS_CASH_OUT_MARKETS_REQUEST_INTERVAL, SportIds } from 'constants/app';
import {
  ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE,
  ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM,
  ASIAN_VIEW_PAGE_PARAM
} from 'constants/asianView';
import { ASIAN_VIEW_CASH_OUT_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import {
  getCashOutGetStatusInterval,
  getDesktopCashoutPageSize,
  getDeviceAsianViewCashOutPaginationButtons,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { getAsianViewIsCashOutTabOpened, getOpenEventId } from 'redux/modules/asianView/selectors';
import { getIsAsianViewBetSlipCashOutTabOpened } from 'redux/modules/asianViewBetslip/selectors';
import {
  fetchAsianBetSlipCashOutMarkets,
  fetchAsianViewBetSlipCashOutStatus
} from 'redux/modules/asianViewBetSlipCashOut';
import { getAsianBetSlipPendingCashOuts } from 'redux/modules/asianViewBetSlipCashOut/selectors';
import { fetchAsianViewCashOutMarkets, fetchAsianViewCashOutStatus } from 'redux/modules/asianViewCashOut';
import { getAsianViewPendingCashOuts } from 'redux/modules/asianViewCashOut/selectors';
import { fetchCashOutStatus } from 'redux/modules/cashOut';
import { getPendingCashOuts } from 'redux/modules/cashOut/selectors';
import { fetchEventCashOutMarkets, fetchEventCashOutStatus } from 'redux/modules/eventCashOut';
import { getEventPendingCashOuts } from 'redux/modules/eventCashOut/selectors';

const CashOutStatusesIntervalsInjection = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { sportId = SportIds.SOCCER } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const cashOutGetStatusInterval = useSelector(getCashOutGetStatusInterval);
  const pendingCashOuts = useSelector(getPendingCashOuts);
  const asianBetSlipPendingCashOuts = useSelector(getAsianBetSlipPendingCashOuts);
  const asianViewPendingCashOuts = useSelector(getAsianViewPendingCashOuts);
  const eventPendingCashOuts = useSelector(getEventPendingCashOuts);
  const desktopCashOutPageSize = useSelector(getDesktopCashoutPageSize);
  const asianViewOpenedTabsEventCouponId = useSelector(getOpenEventId);
  const isCashOutTabOpened = useSelector(getAsianViewIsCashOutTabOpened);
  const isAsianViewBetSlipTab = useSelector(getIsAsianViewBetSlipCashOutTabOpened);

  const { isMobile, isDesktop } = useDevice();

  const asianViewCashOutPaginationButtons = useSelector(getDeviceAsianViewCashOutPaginationButtons(isMobile));

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;
    const pendingCashOutsAmount = Object.keys(pendingCashOuts).length;

    if (pendingCashOutsAmount) {
      interval = setInterval(() => {
        Object.entries(pendingCashOuts).forEach(([, statusId]) => {
          dispatch(fetchCashOutStatus({ id: statusId }));
        });
      }, cashOutGetStatusInterval);
    }

    if (!pendingCashOutsAmount && interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [pendingCashOuts, cashOutGetStatusInterval, language, timezone]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;
    const pendingCashOutsAmount = Object.keys(asianBetSlipPendingCashOuts).length;

    if (pendingCashOutsAmount) {
      const asianViewCashOutPage = searchParams.get(ASIAN_VIEW_PAGE_PARAM);
      const asianViewCashOutTabPage = searchParams.get(ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM);
      const isAsianViewCashOutPage = location.pathname.includes(ASIAN_VIEW_CASH_OUT_URL);

      const onSuccess = () => {
        setTimeout(() => {
          if (isAsianViewCashOutPage) {
            dispatch(
              fetchAsianViewCashOutMarkets({
                page: asianViewCashOutPage ? Number(asianViewCashOutPage) : 0,
                size: desktopCashOutPageSize,
                resetPrev: !asianViewCashOutPage,
                changePage: asianViewCashOutPaginationButtons
                  ? (page: number) => {
                      searchParams.set(ASIAN_VIEW_PAGE_PARAM, String(page));
                      setSearchParams(searchParams);
                    }
                  : undefined,
                isPaginationEnabled: asianViewCashOutPaginationButtons
              })
            );
          } else if (isCashOutTabOpened && asianViewOpenedTabsEventCouponId) {
            dispatch(
              fetchEventCashOutMarkets({
                page: asianViewCashOutTabPage ? Number(asianViewCashOutTabPage) : 0,
                size: desktopCashOutPageSize,
                sportId,
                eventId: asianViewOpenedTabsEventCouponId,
                resetPrev: !asianViewCashOutTabPage,
                changePage: asianViewCashOutPaginationButtons
                  ? (page: number) => {
                      searchParams.set(ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM, String(page));
                      setSearchParams(searchParams);
                    }
                  : undefined,
                isPaginationEnabled: asianViewCashOutPaginationButtons
              })
            );
          }
        }, AV_SUCCESS_STATUS_CASH_OUT_MARKETS_REQUEST_INTERVAL);
      };

      interval = setInterval(() => {
        Object.entries(asianBetSlipPendingCashOuts).forEach(([, statusId]) => {
          dispatch(
            fetchAsianViewBetSlipCashOutStatus({ statusId, onSuccessStatus: isDesktop ? onSuccess : undefined })
          );
        });
      }, cashOutGetStatusInterval);
    }

    if (!pendingCashOutsAmount && interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [asianBetSlipPendingCashOuts, cashOutGetStatusInterval, language, timezone]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;
    const pendingCashOutsAmount = Object.keys(eventPendingCashOuts).length;

    if (pendingCashOutsAmount) {
      const asianViewBetSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);

      const onSuccess = () => {
        setTimeout(() => {
          if (isAsianViewBetSlipTab) {
            dispatch(
              fetchAsianBetSlipCashOutMarkets({
                page: asianViewBetSlipCashOutPage ? Number(asianViewBetSlipCashOutPage) : 0,
                size: desktopCashOutPageSize,
                resetPrev: !asianViewBetSlipCashOutPage,
                changePage: asianViewCashOutPaginationButtons
                  ? (page: number) => {
                      searchParams.set(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE, String(page));
                      setSearchParams(searchParams);
                    }
                  : undefined,
                isPaginationEnabled: asianViewCashOutPaginationButtons
              })
            );
          }
        }, AV_SUCCESS_STATUS_CASH_OUT_MARKETS_REQUEST_INTERVAL);
      };

      interval = setInterval(() => {
        Object.entries(eventPendingCashOuts).forEach(([, statusId]) => {
          dispatch(fetchEventCashOutStatus({ statusId, onSuccessStatus: isDesktop ? onSuccess : undefined }));
        });
      }, cashOutGetStatusInterval);
    }

    if (!pendingCashOutsAmount && interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [eventPendingCashOuts, cashOutGetStatusInterval, language, timezone]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = null;
    const pendingCashOutsAmount = Object.keys(asianViewPendingCashOuts).length;

    if (pendingCashOutsAmount) {
      const asianViewBetSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);

      const onSuccess = () => {
        setTimeout(() => {
          if (isAsianViewBetSlipTab) {
            dispatch(
              fetchAsianBetSlipCashOutMarkets({
                page: asianViewBetSlipCashOutPage ? Number(asianViewBetSlipCashOutPage) : 0,
                size: desktopCashOutPageSize,
                resetPrev: !asianViewBetSlipCashOutPage,
                changePage: asianViewCashOutPaginationButtons
                  ? (page: number) => {
                      searchParams.set(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE, String(page));
                      setSearchParams(searchParams);
                    }
                  : undefined,
                isPaginationEnabled: asianViewCashOutPaginationButtons
              })
            );
          }
        }, AV_SUCCESS_STATUS_CASH_OUT_MARKETS_REQUEST_INTERVAL);
      };

      interval = setInterval(() => {
        Object.entries(asianViewPendingCashOuts).forEach(([, statusId]) => {
          dispatch(fetchAsianViewCashOutStatus({ statusId, onSuccessStatus: isDesktop ? onSuccess : undefined }));
        });
      }, cashOutGetStatusInterval);
    }

    if (!pendingCashOutsAmount && interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [asianViewPendingCashOuts, cashOutGetStatusInterval, language, timezone]);

  return null;
};

export default CashOutStatusesIntervalsInjection;
