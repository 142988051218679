import { ChangeEvent, HTMLInputTypeAttribute, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './FloatingLabelInput.module.scss';

type FloatingLabelInputProps = {
  value: string | number;
  label: string;
  onChangeValue: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  name?: string;
  type?: HTMLInputTypeAttribute;
  containerClassName?: string;
  inputClassName?: string;
  labelClassName?: string;
  labelActiveClassName?: string;
  disabled?: boolean;
};

const FloatingLabelInput = ({
  value,
  onChangeValue,
  label,
  id,
  type = 'text',
  disabled,
  containerClassName = '',
  inputClassName = '',
  labelClassName = '',
  labelActiveClassName = '',
  name
}: FloatingLabelInputProps) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsFirstRender(false);
    }, 300);
  }, []);

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.container__focused]: focused
        },
        containerClassName
      )}
    >
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChangeValue}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder=""
        disabled={disabled}
        name={name}
        className={classNames(
          styles.container__input,
          {
            [styles.container__input__focused]: focused || value
          },
          inputClassName
        )}
      />
      <label
        htmlFor={id}
        className={classNames(
          styles.container__label,
          {
            [styles.container__label__transition]: !isFirstRender,
            [styles.labelActive]: focused || value,
            [labelActiveClassName]: focused || value
          },
          labelClassName
        )}
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelInput;
