import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { GAME, PostMessageTypes } from 'constants/app';
import { accountPagesBranding } from 'constants/branding';
import { StatementDataStatuses } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import {
  getIsIframeEnabled,
  getMobileSwapColorsFancyMarketsOnCricket,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { setMobileStatementItemDetails } from 'redux/modules/myBets';
import { TStatementContentItem } from 'redux/modules/myBets/type';
import { parseMillisecondsToTime } from 'utils/date';
import { sendIframeMessages } from 'utils/iframe';
import {
  getAccountStatementIndicators,
  getAccountStatementStatusLabel,
  getOriginLabel,
  getSelectionName
} from 'utils/myBetsValues';

import styles from './AccountStatementMobileTableItem.module.scss';

type AccountStatementMobileTableItemProps = {
  data: TStatementContentItem;
  isMultiCurrencySupported: boolean;
};

const AccountStatementMobileTableItem = ({ data, isMultiCurrencySupported }: AccountStatementMobileTableItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const swapColorsFancyMarketsOnCricket = useSelector(getMobileSwapColorsFancyMarketsOnCricket);

  const currencySettings = {
    noRounding: true,
    isCheckIndian: true,
    noSymbol: !isMultiCurrencySupported
  };

  const { noFormattedAmount: balance } = useFormatCurrency(data.balance || 0, data.currency, currencySettings);
  const { noFormattedAmount: debit } = useFormatCurrency(data.debit || 0, data.currency, currencySettings);
  const { noFormattedAmount: credit } = useFormatCurrency(data.credit || 0, data.currency, currencySettings);

  const originLabel = getOriginLabel({ data, t });

  const getNames = () => {
    if (data.betType === 'GAME') {
      return data.sportName;
    }

    if (data.outright) {
      return `${data.competitionName} - ${data.marketName}`;
    }

    if (data.raceName) {
      return `${data.eventName} - ${parseMillisecondsToTime(data.marketStartTime, timezone, timezoneCookieEnabled)} ${
        data.raceName
      } - ${data.marketName}`;
    }

    return `${data.mainEventName || data.eventName} - ${data.marketNameWithParents || data.marketName}`;
  };

  const balanceChange = Number(data.debit) > 0 ? data.debit : data.credit;

  const isWon = data.status === StatementDataStatuses.WON || data.status === StatementDataStatuses.HALF_WON;
  const isLost = data.status === StatementDataStatuses.LOST || data.status === StatementDataStatuses.HALF_LOST;
  const { isBackIndicator, isLayIndicator } = getAccountStatementIndicators({ data, swapColorsFancyMarketsOnCricket });

  return (
    <button
      onClick={() => {
        dispatch(setMobileStatementItemDetails(data));
        window.scrollTo({ top: 0 });

        if (isIframeEnabled) {
          sendIframeMessages({ type: PostMessageTypes.ACCOUNT_BETTING_PAGE_SCROLL_TO_TOP });
        }
      }}
      className={classNames(styles.record, accountPagesBranding.BET_INDICATOR, {
        [styles.record__back]: isBackIndicator,
        [styles.record__lay]: isLayIndicator,
        [accountPagesBranding.BET_SIDE_BACK]: isBackIndicator,
        [accountPagesBranding.BET_SIDE_LAY]: isLayIndicator
      })}
    >
      <span>
        <p className={styles.record__selectionName}>{getSelectionName({ data, t })}</p>
        {originLabel && <p className={styles.record__originLabel}>{originLabel}</p>}
        {!data.depositWithdrawal && <p className={styles.record__names}>{getNames()}</p>}
        <p className={styles.record__status}>
          <span
            className={classNames(styles.record__status__dot, {
              [styles.record__status__label__won__dot]: isWon,
              [styles.record__status__label__lost__dot]: isLost,
              [accountPagesBranding.POSITIVE]: isWon,
              [accountPagesBranding.NEGATIVE]: isLost
            })}
          />
          <span
            className={classNames(styles.record__status__label, {
              [styles.record__status__label__won]: isWon,
              [styles.record__status__label__lost]: isLost,
              [accountPagesBranding.POSITIVE]: isWon,
              [accountPagesBranding.NEGATIVE]: isLost
            })}
          >
            {getAccountStatementStatusLabel({ status: data.status, t })}
          </span>
          <span
            className={classNames(styles.record__balanceChange, {
              [styles.record__balanceChange__plus]: Number(balanceChange) > 0 && Number(data.credit) > 0,
              [styles.record__balanceChange__minus]: Number(balanceChange) > 0 && Number(data.debit) > 0
            })}
          >
            {Number(balanceChange) > 0 ? (Number(data.credit) > 0 ? '+' : '-') : ''}
            {Number(data.debit) > 0 ? debit : credit}
          </span>
        </p>
        {(data.triggeredByCashOut || data.asian) &&
          data.status !== StatementDataStatuses.TRANSACTION &&
          data.betType !== GAME && (
            <p className={styles.record__cashOut__wrapper}>
              {data.triggeredByCashOut && <span className={styles.record__cashOut}>{t('market.cashOut')}</span>}
              {data.asian &&
                t(data.inPlay ? 'account.mybets.labels.livePlacement' : 'account.statement.labels.prePlayPlacement')}
            </p>
          )}
      </span>
      <p className={styles.record__balance}>
        {balance}
        <i className={classNames('biab_custom-icon-chevron-right-thin', styles.record__rightIcon)} />
      </p>
    </button>
  );
};

export default AccountStatementMobileTableItem;
