import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { get } from 'lodash';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';

import { APP_TYPE, X_VERSION } from 'configs/app';
import { ErrorStatusCodes, ErrorStatusCodesType, ignoreShowingForbiddenContent } from 'configs/HTTPErrorsConfig';
import { CookieNames, CSRF_TOKEN_URLS, Devices, IOS_BUNDLE, Methods, PostMessageTypes } from 'constants/app';
import { HOME_BASE_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import { store } from 'redux/configureStore';
import { fetchAppConfigs, setBackendContent, setSplashScreen } from 'redux/modules/appConfigs';
import { getIsIframeEnabled, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { TCSRFCookieType } from 'redux/modules/appConfigs/type';
import { logoutUser } from 'redux/modules/auth';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { fetchUserInfo, setOperatorCurrency } from 'redux/modules/user';
import { AppState } from 'redux/reducers';
import { Method, RequestParams } from 'types';
import { iosBundleRedirect } from 'utils';
import { dispatchEvent, sendIframeMessages } from 'utils/iframe';

export class ApiError extends Error {
  readonly response: any = {};

  readonly status: ErrorStatusCodesType;

  readonly data: any = {};

  constructor(error: AxiosError) {
    super();
    this.response = get(error, 'response', {});
    this.status = get(error, ['response', 'status'], 'NOT_RESOLVED');
    this.data = get(error, ['response', 'data'], {});
    this.message = error?.message;
  }
}

class Api {
  readonly instance: AxiosInstance;

  constructor(
    isMobile: boolean,
    { cookies, setCookie }: TCSRFCookieType,
    iframeEnabled: boolean,
    navigate: NavigateFunction
  ) {
    this.instance = axios.create({
      paramsSerializer: params => {
        return qs.stringify(params);
      }
    });
    const { dispatch, getState } = store;

    this.instance.interceptors.response.use(
      (response: AxiosResponse) => {
        const headerCurrency = response.headers['x-currency'];
        const {
          user: { operatorCurrency }
        }: AppState = getState();

        if (headerCurrency && headerCurrency !== operatorCurrency) {
          dispatch(setOperatorCurrency(headerCurrency));
        }

        return response.data;
      },
      (e: any): never => {
        const apiError = new ApiError(e);
        const useHeadersInsteadOfCookies = window.environmentConfig?.useHeadersInsteadOfCookies;
        const {
          response: { status }
        } = e;

        if (e.request.responseURL.includes('exceedDayLimit')) {
          dispatch(logoutUser(HOME_BASE_URL));
          throw apiError;
        }

        switch (status) {
          case ErrorStatusCodes.UNAUTHORIZED:
            dispatch(setBackendContent(PagesFromBackend.UNAUTHORIZED));
            if (iosBundleRedirect || useHeadersInsteadOfCookies) {
              setCookie(CookieNames.TOKEN, null, { expires: new Date(0) });
            } else {
              dispatch(logoutUser(''));
            }

            if (iframeEnabled) {
              sendIframeMessages({ type: PostMessageTypes.DAY_LIMIT });
            } else {
              dispatchEvent({ type: PostMessageTypes.DAY_LIMIT });
            }
            break;
          case ErrorStatusCodes.FORBIDDEN:
            if (!ignoreShowingForbiddenContent.some(item => e.request.responseURL.includes(item))) {
              dispatch(setBackendContent(PagesFromBackend.FORBIDDEN));
            }
            dispatch(fetchUserInfo(true));
            break;
          case ErrorStatusCodes.NOT_FOUND:
            dispatch(setBackendContent(PagesFromBackend.PAGE_NOT_FOUND));
            break;
          case ErrorStatusCodes.BAD_GATEWAY:
            break;
          case ErrorStatusCodes.UNKNOWN_ERROR:
          case ErrorStatusCodes.SERVICE_UNAVAILABLE:
            if (iosBundleRedirect) {
              dispatch(setSplashScreen(IOS_BUNDLE));
            } else {
              dispatch(setSplashScreen(e.response.data || ''));
            }
            break;
          case ErrorStatusCodes.PRECONDITION_FAILED:
            dispatch(fetchAppConfigs({ disabledProductError: true, navigate }));
            break;
          default:
            // TODO here we can show general notification based on error
            break;
        }
        throw apiError;
      }
    );

    //TODO here we can add Bearer token for mobile app in the future

    this.instance.interceptors.request.use(config => {
      const iframeDomainsConfig = window?.environmentConfig?.iframeDomain || '';
      config!.headers!['access-control-allow-credentials'] = true;
      config!.headers!['access-control-allow-origin'] = '*';
      config!.headers!['x-device'] = isMobile ? Devices.MOBILE : Devices.DESKTOP;
      if (iosBundleRedirect || window.environmentConfig?.useHeadersInsteadOfCookies) {
        config!.headers!['X-Version'] = X_VERSION;
        config!.headers!['App-Type'] = APP_TYPE;
        if (cookies?.BIAB_TOKEN) {
          config!.headers!['X-Auth'] = cookies?.BIAB_TOKEN || '';
        }
        config!.headers!['X-Locale'] = cookies?.BIAB_LANGUAGE || '';
        config!.headers!['X-Timezone'] = cookies?.BIAB_TZ || '';
      }
      if (config.url?.match(CSRF_TOKEN_URLS) || ['post', 'put', 'delete'].includes(config!.method!)) {
        let token = uuidv4();
        if (cookies[CookieNames.CSRF]) {
          token = cookies[CookieNames.CSRF];
        }
        const date = new Date();
        let min;
        if (window.environmentConfig?.csrfCookieExpireTime) {
          min = parseInt(window.environmentConfig?.csrfCookieExpireTime);
        } else {
          min = parseInt(process.env.REACT_APP_CSRF_EXPIRE_TIME || '60');
        }
        date.setTime(date.getTime() + min * 60 * 1000);

        if (iframeDomainsConfig !== '') {
          setCookie(CookieNames.CSRF, token, { path: '/', expires: date, secure: true, sameSite: 'none' });
        } else {
          setCookie(CookieNames.CSRF, token, { path: '/', expires: date });
        }

        config!.headers!['X-CSRF-TOKEN'] = token;
      }
      return config;
    });
  }

  getInstance(): AxiosInstance {
    return this.instance;
  }
}

let api: AxiosInstance;

export function AxiosInterceptorsInjection() {
  const [cookies, setCookie] = useCookies([
    CookieNames.CSRF,
    CookieNames.LANGUAGE,
    CookieNames.TIMEZONE,
    CookieNames.TOKEN
  ]);

  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const navigate = useNavigate();

  const { currentDevice } = useDevice();

  useEffect(() => {
    const isMobile = currentDevice === Devices.MOBILE;
    api = new Api(isMobile, { cookies, setCookie }, iframeEnabled, navigate).getInstance();
  }, [currentDevice, iframeEnabled, language, timezone, cookies]);

  return null;
}

export async function fetchRequest<T = null>(
  method: Method,
  url: string,
  data?: T,
  params?: Record<string, string | number>,
  isApiResource = true,
  responseType?: ResponseType
): Promise<any> {
  const apiResource = isApiResource ? 'api/' : '';
  const postParams = method === Methods.POST && params ? { params } : {};
  const request: RequestParams<T> = {
    method,
    url: `${window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL}/customer/${apiResource}${url}`,
    [method === Methods.GET ? 'params' : 'data']: data,
    ...postParams,
    ...(responseType ? { responseType } : {}),
    withCredentials: true
  };

  return api(request);
}
