import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import CheckboxField from 'components/Checkbox';
import Tooltip from 'components/Tooltip';
import { BetFocusFields, KEY_CODES, PlaceBetsStates, tabulation } from 'constants/betslip';
import { betslipBranding } from 'constants/branding';
import { SettingsBettingCheckboxes } from 'constants/navigation';
import { BETSLIP_CONFIRM_CHECKBOX } from 'constants/tooltip';
import useBetslipNetPL from 'hooks/useBetslipNetPL';
import useConfirmBets from 'hooks/useConfirmBets';
import useImperativeTooltip from 'hooks/useImperativeTooltip';
import {
  getDesktopSettingsInlineBetting,
  getDesktopSettingsNetOfCommissionBetslip,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setBetslipFocusedButton, updateSelectedBet } from 'redux/modules/betslip';
import {
  getBetslipPlaceBetsState,
  getFirstSelectedBet,
  getIsGameBetSlip,
  getIsSelectedBetsPlacementValid,
  getSelectedBets
} from 'redux/modules/betslip/selectors';
import { setSettings } from 'redux/modules/user';
import {
  getIsUserAccountSettings,
  getUserAccountSettingsAsianViewAutoRefresh,
  getUserAccountSettingsBetSlipPlacement
} from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

const BetslipSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const netOfCommissionBetslip = useSelector(getDesktopSettingsNetOfCommissionBetslip);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const firstSelectedBet = useSelector(getFirstSelectedBet);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const selectedBetsList = useSelector(getSelectedBets);
  const isPlacementValid = useSelector(getIsSelectedBetsPlacementValid);
  const isAutoRefresh = useSelector(getUserAccountSettingsAsianViewAutoRefresh);
  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const settingsBetSlipPlacement = useSelector(getUserAccountSettingsBetSlipPlacement);
  const placeBetsState = useSelector(getBetslipPlaceBetsState);
  const inlineBetting = useSelector(getDesktopSettingsInlineBetting);

  const { isConfirmBetsBeforePlacement, isConfirmBetsEnabled, saveConfirmBetsBeforePlacement } = useConfirmBets();
  const { tooltipRef, tooltipHtml, tooltipMouseEvents, tooltipAnchorId } =
    useImperativeTooltip(BETSLIP_CONFIRM_CHECKBOX);
  const { isChecked: isProfitNetOfCommissionBetSlipChecked, onToggle: onToggleNetPL } = useBetslipNetPL();

  const isSelectBetsState = placeBetsState === PlaceBetsStates.SELECT;
  const isConfirmBetsState = placeBetsState === PlaceBetsStates.CONFIRM;

  const changeConfirm = () => {
    saveConfirmBetsBeforePlacement(!isConfirmBetsBeforePlacement);
  };

  const onKeyDownButton = (event: KeyboardEvent, isRestartTabNavigation: boolean) => {
    if (event.key === KEY_CODES.TAB && isRestartTabNavigation && firstSelectedBet) {
      event.preventDefault();

      if (isSelectBetsState) {
        const { marketId, betUuid, type } = firstSelectedBet;

        dispatch(
          updateSelectedBet({
            marketId,
            betUuid,
            type,
            focusedField: !isPNCEnabled || isGameBetslip ? BetFocusFields.PRICE : BetFocusFields.SIZE
          })
        );
      } else if (isConfirmBetsState && isConfirmBetsBeforePlacement) {
        dispatch(setBetslipFocusedButton('confirm'));
      }
    }
  };

  const handleChangeAutoRefresh = () => {
    dispatch(
      setSettings({
        settings: {
          [SettingsBettingCheckboxes.AUTO_REFRESH]: !isAutoRefresh
        }
      })
    );
  };

  const getButtonTabIndex = (index: number) => {
    return !isPlacementValid && index === tabulation.PLACE_BTN_ORDER
      ? -1
      : (selectedBetsList.length || 0) *
          (index === tabulation.PLACE_BTN_ORDER ? tabulation.BET_TABS : tabulation.FULL_BET_TABS) +
          index;
  };

  const onToggleInlineBetting = () => {
    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            betslipPlacement: !settingsBetSlipPlacement
          }
        })
      );
    }
  };

  return (
    <div className={classNames(styles.settings, betslipBranding.SETTINGS)}>
      {isPNCEnabled && !isGameBetslip && (
        <CheckboxField
          checked={isAutoRefresh}
          tabIndex={getButtonTabIndex(tabulation.AUTO_REFRESH_CHECKBOX_ORDER)}
          onChange={handleChangeAutoRefresh}
          onKeyDown={(ev: KeyboardEvent) =>
            onKeyDownButton(ev, !(isLoggedIn && netOfCommissionBetslip && !isGameBetslip) && !isConfirmBetsEnabled)
          }
        >
          {t('asianView.labels.betslip.autoRefresh')}
        </CheckboxField>
      )}
      {isConfirmBetsEnabled && (
        <CheckboxField
          checked={isConfirmBetsBeforePlacement}
          id={tooltipAnchorId}
          tabIndex={getButtonTabIndex(tabulation.CONFIRM_CHECKBOX_ORDER)}
          onChange={changeConfirm}
          onKeyDown={ev => onKeyDownButton(ev, !(isLoggedIn && netOfCommissionBetslip && !isGameBetslip))}
          {...tooltipMouseEvents}
        >
          {t('betslip.actions.confirmBets')}
          <Tooltip ref={tooltipRef} anchorSelect={`#${tooltipAnchorId}`} html={tooltipHtml} imperativeModeOnly />
        </CheckboxField>
      )}
      {isLoggedIn && netOfCommissionBetslip && !isGameBetslip && (
        <CheckboxField
          checked={isProfitNetOfCommissionBetSlipChecked}
          tabIndex={getButtonTabIndex(tabulation.BETTING_PL_CHECKBOX_ORDER)}
          onChange={onToggleNetPL}
          onKeyDown={ev => onKeyDownButton(ev, !inlineBetting)}
        >
          {t('betslip.netOfCommission.checkbox')}
        </CheckboxField>
      )}
      {isLoggedIn && inlineBetting && !isGameBetslip && (
        <CheckboxField
          checked={!settingsBetSlipPlacement}
          tabIndex={getButtonTabIndex(tabulation.INLINE_BETTING_CHECKBOX_ORDER)}
          onChange={onToggleInlineBetting}
          onKeyDown={ev => onKeyDownButton(ev, true)}
        >
          {t('betslip.labels.inlineBetting')}
        </CheckboxField>
      )}
    </div>
  );
};

export default BetslipSettings;
