import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { CookieNames, PageBlocks } from 'constants/app';
import { BetsStatusesTypes } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { mobileBetslip as branding } from 'constants/branding';
import { BetSides } from 'constants/myBets';
import { useIsMobilePlacementDisabled } from 'hooks/useMobilePlacement';
import { getMobileSettingsFillOrKill, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getCurrentBetsBySelection } from 'redux/modules/currentBets/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getUserAccountSettingsFillOrKillEnabled } from 'redux/modules/user/selectors';
import { PageBlock, PlacementPage } from 'types';
import { MatchType } from 'types/bets';

import MobileOpenBet from './MobileOpenBet';

import styles from './styles.module.scss';

type MobilePlacementProps = {
  marketId: string;
  selectionId?: number;
  handicap?: number;
  pageBlock: PageBlock;
  marketPricesCurrency?: string;
  page?: PlacementPage;
  containerClassName?: string;
  sportId?: string;
};

function MobileOpenBets({
  marketId,
  selectionId,
  handicap,
  pageBlock = PageBlocks.HOME,
  marketPricesCurrency = '',
  page,
  containerClassName = '',
  sportId
}: MobilePlacementProps) {
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.MOBILE_OPEN_BETS]);

  const fillOrKill = useSelector(getMobileSettingsFillOrKill);
  const fillOrKillEnabled = useSelector(getUserAccountSettingsFillOrKillEnabled);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const unmatchedBets = useSelector(
    getCurrentBetsBySelection({
      type: MatchTypes.UNMATCHED,
      marketId,
      selectionId,
      handicap,
      showLapsedAlways: true,
      ignoreCancelled: true,
      showCancelledIfEditing: true
    })
  ).filter(bet => (fillOrKill || fillOrKillEnabled ? !bet.canBeRemoved : true));
  const matchedBets = useSelector(
    getCurrentBetsBySelection({ type: MatchTypes.MATCHED, marketId, selectionId, handicap })
  );

  const { isBettingEnabled } = useIsMobilePlacementDisabled({
    marketId,
    selectionId,
    handicap,
    pageBlock,
    type: BetSides.Lay,
    sportId
  });

  const [closedUnmatchedBets, setClosedUnmatchedBets] = useState<number[]>([]);
  const [closedMatchedBets, setClosedMatchedBets] = useState<number[]>([]);
  const [isUnmatchedCollapsed, setIsUnmatchedCollapsed] = useState(unmatchedBets.length === 0);
  const [isMatchedCollapsed, setIsMatchedCollapsed] = useState(!(unmatchedBets.length === 0 && matchedBets.length > 0));

  const isOpenBetsEnabled = cookies[CookieNames.MOBILE_OPEN_BETS] === 'true';

  const getFilteredBets = (matchType: MatchType): TCurrentBet[] => {
    if (matchType == MatchTypes.MATCHED) {
      if (isOpenBetsEnabled) {
        return matchedBets.filter(bet => bet.offerState !== BetsStatusesTypes.CANCELLED);
      }
      return matchedBets
        .filter(bet => !closedMatchedBets.includes(bet.offerId))
        .filter(bet => bet.offerState !== BetsStatusesTypes.CANCELLED);
    } else {
      if (isOpenBetsEnabled) {
        return unmatchedBets;
      }
      return unmatchedBets.filter(bet => !closedUnmatchedBets.includes(bet.offerId));
    }
  };

  const filteredMatchedBets = getFilteredBets(MatchTypes.MATCHED);
  const filteredUnmatchedBets = getFilteredBets(MatchTypes.UNMATCHED);
  const hasUnmatchedHeader =
    (filteredMatchedBets.length > 0 && filteredUnmatchedBets.length > 0) || filteredUnmatchedBets.length > 1;
  const hasMatchedHeader =
    (filteredMatchedBets.length > 0 && filteredUnmatchedBets.length > 0) || filteredMatchedBets.length > 1;
  const hasVisibleMatchedBets =
    !isMatchedCollapsed || (filteredMatchedBets.length === 1 && filteredUnmatchedBets.length === 0);
  const hasVisibleUnmatchedBets =
    !isUnmatchedCollapsed || (filteredUnmatchedBets.length === 1 && filteredMatchedBets.length === 0);

  const toggleUnmatchedBets = () => {
    if (isUnmatchedCollapsed && filteredMatchedBets.length) {
      setIsMatchedCollapsed(true);
    }
    setIsUnmatchedCollapsed(!isUnmatchedCollapsed);
  };

  const toggleMatchedBets = () => {
    if (isMatchedCollapsed && filteredUnmatchedBets.length) {
      setIsUnmatchedCollapsed(true);
    }
    setIsMatchedCollapsed(!isMatchedCollapsed);
  };

  useEffect(() => {
    setIsUnmatchedCollapsed(filteredUnmatchedBets.length == 0);
    setIsMatchedCollapsed(!(filteredUnmatchedBets.length == 0 && filteredMatchedBets.length > 0));
  }, [filteredMatchedBets.length, filteredUnmatchedBets.length, isOpenBetsEnabled]);

  const onHideBet = (matchType: MatchType, offerId: number) => {
    if (matchType == MatchTypes.MATCHED) {
      setClosedMatchedBets([...closedMatchedBets, offerId]);
    } else {
      setClosedUnmatchedBets([...closedUnmatchedBets, offerId]);
    }
  };

  useEffect(() => {
    if (isOpenBetsEnabled) {
      if (closedMatchedBets.length) {
        setClosedMatchedBets([]);
      }
      if (closedUnmatchedBets.length) {
        setClosedUnmatchedBets([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenBetsEnabled]);

  if (!isUndefined(isBettingEnabled) && !isBettingEnabled) {
    return null;
  }

  return (
    <div className={classNames(styles.openBets, containerClassName, branding.OPENED_BETS)}>
      <div className={styles.openBets__byType}>
        {hasUnmatchedHeader && (
          <button
            className={classNames(styles.unmatchedHeader, branding.TAB, branding.UNMATCHED_INDICATOR, {
              biab_opened: !isUnmatchedCollapsed,
              [styles.unmatchedHeader__active]: hasVisibleUnmatchedBets,
              [branding.SELECTED]: !isUnmatchedCollapsed
            })}
            onClick={toggleUnmatchedBets}
          >
            <span className={classNames(styles.unmatchedHeader__betCount, branding.UNMATCHED_INDICATOR)}>
              {filteredUnmatchedBets.length}
            </span>
            {t('betslip.openBets.tabs.unmatched')}
            <i className={classNames('fa', styles.expand, !isUnmatchedCollapsed && styles.expand__opened)} />
          </button>
        )}
        {hasMatchedHeader && <div className={styles.divider} />}
        {hasMatchedHeader && (
          <button
            className={classNames(styles.matchedHeader, branding.TAB, branding.MATCHED_INDICATOR, {
              biab_opened: !isMatchedCollapsed,
              [styles.matchedHeader__active]: hasVisibleMatchedBets,
              [branding.SELECTED]: !isMatchedCollapsed
            })}
            onClick={toggleMatchedBets}
          >
            <span className={classNames(styles.matchedHeader__betCount, branding.MATCHED_INDICATOR)}>
              {filteredMatchedBets.length}
            </span>
            {t('betslip.openBets.tabs.matched')}
            <i className={classNames('fa', styles.expand, !isMatchedCollapsed && styles.expand__opened)} />
          </button>
        )}
      </div>
      {(hasVisibleMatchedBets || hasVisibleUnmatchedBets) && (
        <div className={classNames('biab_mobile-open-bets-list', styles.openBetsList)}>
          {hasVisibleMatchedBets &&
            filteredMatchedBets.map((bet, index) => (
              <MobileOpenBet
                bet={bet}
                pageBlock={pageBlock}
                matchType={MatchTypes.MATCHED}
                isPNCEnabled={isPNCEnabled}
                isOpenBetsEnabled={isOpenBetsEnabled}
                key={`${bet.offerId}-${index}`}
                currency={marketPricesCurrency}
                onHide={onHideBet}
                page={page}
                noHeader={!hasUnmatchedHeader && !hasMatchedHeader}
              />
            ))}
          {hasVisibleUnmatchedBets &&
            filteredUnmatchedBets.map((bet, index) => (
              <MobileOpenBet
                bet={bet}
                pageBlock={pageBlock}
                matchType={MatchTypes.UNMATCHED}
                isPNCEnabled={isPNCEnabled}
                isOpenBetsEnabled={isOpenBetsEnabled}
                key={`${bet.offerId}-${index}`}
                currency={marketPricesCurrency}
                onHide={onHideBet}
                page={page}
                noHeader={!hasUnmatchedHeader && !hasMatchedHeader}
              />
            ))}
        </div>
      )}
      {(hasVisibleMatchedBets || hasVisibleUnmatchedBets) && <div className={styles.openBets__empty} />}
    </div>
  );
}

export default MobileOpenBets;
