import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { MarketStatuses } from 'constants/app';
import {
  AsianBettingActions,
  QUICK_BETTING_BOTTOM_PADDING,
  QUICK_BETTING_LEFT_RIGHT_PADDING
} from 'constants/asianView';
import { BetDatabaseNames } from 'constants/bets';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import { BetSides } from 'constants/myBets';
import { getWindowHeight } from 'redux/modules/appConfigs/selectors';
import { getAsianSectionSize } from 'redux/modules/asianView/selectors';
import { getAsianQuickBettingItem } from 'redux/modules/asianViewQuickBetting/selectors';
import {
  getBetOdds,
  getMarketPricesRunnerLockedBySelectionId,
  getMarketPricesStatusById
} from 'redux/modules/marketsPrices/selectors';

import QuickBettingActions from './components/QuickBettingActions';
import QuickBettingForm from './components/QuickBettingForm';
import QuickBettingProgress from './components/QuickBettingProgress';

import styles from './styles.module.scss';

type QuickBettingProps = {
  betIdentifier: string;
};

const QuickBetting = ({ betIdentifier }: QuickBettingProps) => {
  const bet = useSelector(getAsianQuickBettingItem(betIdentifier));
  const sectionSize = useSelector(getAsianSectionSize);
  const status = useSelector(getMarketPricesStatusById(bet.marketId));
  const isSuspended = status === MarketStatuses.SUSPENDED;
  const isBack = bet.betType === BetSides.Back;
  const betName = isBack ? BetDatabaseNames.BACK : BetDatabaseNames.LAY;
  const betOdds = useSelector(getBetOdds(bet.marketId, bet?.selectionId ?? 0, +(bet?.handicap ?? 0), betName, 0));
  const isLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(bet.marketId, bet.selectionId, +(bet.handicap ?? 0))
  );
  const windowHeight = useSelector(getWindowHeight);

  const [position, setPosition] = useState({ left: 0, y: 0 });

  const isDisabledBet = !betOdds || isSuspended || isLocked;
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (elementRef.current) {
      const { left, y } = elementRef.current.getBoundingClientRect();
      setPosition({ left, y });
    }
  }, [windowHeight]);

  const isLeft = position.left - (sectionSize?.left ?? 0) < QUICK_BETTING_LEFT_RIGHT_PADDING;
  const isRight =
    (sectionSize?.left ?? 0) + (sectionSize?.width ?? 0) - position.left < QUICK_BETTING_LEFT_RIGHT_PADDING;
  const isBottom = windowHeight - (position.y ?? 0) < QUICK_BETTING_BOTTOM_PADDING;
  const isProgress = bet.action === AsianBettingActions.PROGRESS;
  const isHidden = bet.action === AsianBettingActions.HIDDEN;
  const isComponentHidden = isProgress || isHidden || !position.left || !windowHeight;

  return (
    <div className={classNames(styles.quickBet__wrap, branding.QUICK_BETTING)} ref={elementRef}>
      <div
        className={classNames(styles.quickBet, branding.SELECTED_BET, {
          [styles.quickBet__back]: isBack && !isDisabledBet,
          [styles.quickBet__lay]: !isBack && !isDisabledBet,
          [styles.quickBet__disabled]: isDisabledBet,
          [styles.quickBet__bottom]: isBottom,
          [styles.quickBet__left]: isLeft,
          [styles.quickBet__right]: isRight,
          [styles.quickBet__hidden]: isComponentHidden,
          [branding.BACK_BET]: isBack && !isDisabledBet,
          [branding.LAY_BET]: !isBack && !isDisabledBet,
          [branding.LOCKED_BET]: isDisabledBet
        })}
      >
        {!isComponentHidden && <QuickBettingForm bet={bet} />}
        {!isProgress && !isDisabledBet && <QuickBettingActions bet={bet} />}
        {isProgress && <QuickBettingProgress />}
      </div>
    </div>
  );
};

export default QuickBetting;
