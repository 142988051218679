import { all, call, put, takeLatest } from 'redux-saga/effects';

import { MetaPages } from 'constants/app';
import api from 'redux/api/methods';

import { failureFetchPageMetaContent, fetchPageMetaContent, setMetaPageId, successFetchPageMetaContent } from './index';
import { FetchMetaPageContentResponse } from './type';

function* getPageMetaWorker(action: ReturnType<typeof fetchPageMetaContent>) {
  try {
    const response: FetchMetaPageContentResponse = yield call(api.meta, action.payload);
    const pageId =
      action.payload.page === MetaPages.CUSTOM && action.payload.id ? action.payload.id : action.payload.page;
    yield put(
      successFetchPageMetaContent({
        page: pageId,
        content: response
      })
    );
    yield put(setMetaPageId(pageId));
  } catch (error: any) {
    yield put(failureFetchPageMetaContent(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchPageMetaContent.type, getPageMetaWorker)]);
}
