import { useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import ActiveMarketsSection from 'components/ActiveMarketsSection';
import ContextualHelpSection from 'components/ContextualHelpSection';
import FavoritesSection from 'components/FavoritesSection';
import HowToSection from 'components/HowToSection';
import CollapsedLeftPanel from 'components/LeftNavigation/CollapsedLeftPanel';
import HideMenuButton, { COLLAPSE_NAVIGATION_COOKIE_KEY } from 'components/LeftNavigation/HideMenuButton';
import SportsSection from 'components/SportsSection';
import { Devices, ElementNames } from 'constants/app';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useLeftNavigationSizes from 'hooks/useLeftNavigationSizes';
import {
  getAppDevice,
  getDesktopSettingsCollapseSportsBar,
  getPropertiesTours
} from 'redux/modules/appConfigs/selectors';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';

import styles from './styles.module.scss';

const LeftNavigation = () => {
  const [cookies] = useCookies([COLLAPSE_NAVIGATION_COOKIE_KEY]);

  const device = useSelector(getAppDevice);
  const tours = useSelector(getPropertiesTours);
  const collapseSportsBar = useSelector(getDesktopSettingsCollapseSportsBar);
  const headerHeight = useSelector(getElementHeightByName(ElementNames.HEADER_HEIGHT));

  const { activeMarkets } = useDeviceSettings();

  const leftNavigationRef = useRef<HTMLDivElement>(null);
  const favoritesWrapperRef = useRef<HTMLDivElement>(null);
  const activeMarketsWrapperRef = useRef<HTMLDivElement>(null);

  const isClosed = cookies[COLLAPSE_NAVIGATION_COOKIE_KEY] === 'true' && collapseSportsBar;
  const isCollapsedView = isClosed && device !== Devices.MOBILE;

  useLeftNavigationSizes({
    leftNavigationRef,
    favoritesWrapperRef,
    activeMarketsWrapperRef
  });

  return (
    <div
      className={classnames('biab_aside-left', styles.leftNav, {
        [styles.leftNav__collapsed]: isClosed && device === Devices.DESKTOP
      })}
    >
      {isCollapsedView && <CollapsedLeftPanel />}
      <div
        className={classnames(styles.collapsedMenuWrapper, {
          hidden: isCollapsedView
        })}
        style={{ height: `calc(100vh - ${headerHeight}px)` }}
        ref={leftNavigationRef}
      >
        {collapseSportsBar && <HideMenuButton />}
        <ContextualHelpSection />
        {!!tours.length && <HowToSection />}
        <FavoritesSection favoritesWrapperRef={favoritesWrapperRef} />
        {activeMarkets && <ActiveMarketsSection activeMarketsWrapperRef={activeMarketsWrapperRef} />}
        <SportsSection />
      </div>
    </div>
  );
};

export default LeftNavigation;
