import { memo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { SportIds } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { BetTooltips } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import { BettingTypes } from 'constants/markets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useBetslipLabels } from 'hooks/useLabels';
import {
  getEventViewSettings,
  getLineMarketsSwitchBackLayOnCricket,
  getReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { MatchType } from 'types/bets';
import { TLabels } from 'types/betslip';
import { BetSide } from 'types/myBets';
import { isFancyMarket, isUnderOverLabel, isYesNoLabel } from 'utils/openedBets';

import BetTooltip from '../BetTooltip';

import styles from './styles.module.scss';

const OpenedBetsHeader = ({
  bet,
  prevBet,
  side,
  matchType,
  alwaysDisplay
}: {
  bet: TCurrentBet;
  prevBet: TCurrentBet | null;
  side: BetSide;
  matchType?: MatchType;
  alwaysDisplay?: boolean;
}) => {
  const eventViewSettings = useSelector(getEventViewSettings);
  const lineBackLayToNoYes = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getReplaceBackLayWithUnderOver);

  const { fancyMarketsOnCricket, swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const labels: TLabels = useBetslipLabels({
    eventTypeId: bet.eventTypeId.toString(),
    bettingType: bet.bettingType,
    marketType: bet.marketType,
    marketUnit: bet.marketUnit
  });

  const isHeader =
    matchType === MatchTypes.UNMATCHED ||
    !prevBet ||
    bet.side !== prevBet?.side ||
    bet.matchType !== prevBet?.matchType ||
    isFancyMarket(bet, eventViewSettings, fancyMarketsOnCricket) !==
      isFancyMarket(prevBet, eventViewSettings, fancyMarketsOnCricket) ||
    isUnderOverLabel(bet, replaceBackLayWithUnderOver) !== isUnderOverLabel(prevBet, replaceBackLayWithUnderOver) ||
    isYesNoLabel(bet, lineBackLayToNoYes, eventViewSettings, fancyMarketsOnCricket) !==
      isYesNoLabel(prevBet, lineBackLayToNoYes, eventViewSettings, fancyMarketsOnCricket);

  if (!alwaysDisplay && !isHeader) {
    return null;
  }

  const isCricket = bet.eventTypeId.toString() === SportIds.CRICKET;
  const isLineMarket = bet.bettingType === BettingTypes.LINE;
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;
  const isUnmatched = matchType === MatchTypes.UNMATCHED;

  return (
    <div
      className={classNames(
        styles.openedBetsBySideHeader,
        styles[`openedBetsBySideHeader__${side.toLowerCase()}`],
        branding.BET_HEADER,
        branding[side],
        { [branding.FANCY_SWAP]: isFancySwapColors }
      )}
    >
      <div className={classNames(styles.openedBetsBySideHeader__label, styles.openedBetsBySideHeader__label__left)}>
        {labels[side]?.bet}
      </div>
      <div
        className={classNames(styles.openedBetsBySideHeader__right, {
          [styles.openedBetsBySideHeader__right__unmatched]: isUnmatched
        })}
      >
        <div
          className={classNames(styles.openedBetsBySideHeader__label, {
            [styles.odds]: isUnmatched
          })}
        >
          <BetTooltip matchType={matchType} side={side} tooltipType={BetTooltips.ODDS}>
            {labels[side]?.odds}
          </BetTooltip>
        </div>
        <div
          className={classNames(styles.openedBetsBySideHeader__label, {
            [styles.stake]: isUnmatched
          })}
        >
          <BetTooltip matchType={matchType} side={side} tooltipType={BetTooltips.SIZE}>
            {labels[side]?.stake}
          </BetTooltip>
        </div>
        <div
          className={classNames(styles.openedBetsBySideHeader__label, {
            [styles.profit]: isUnmatched
          })}
        >
          <BetTooltip matchType={matchType} side={side} tooltipType={BetTooltips.PROFIT}>
            {labels[side]?.profit}
          </BetTooltip>
        </div>
      </div>
    </div>
  );
};

export default memo(OpenedBetsHeader);
