import { RefObject, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ElementNames } from 'constants/app';
import { getWindowHeight } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import {
  ACTIVE_MARKETS_COLLAPSE,
  FAVORITES_COLLAPSE,
  LEFT_PANEL_COLLAPSE_GROUP
} from 'redux/modules/collapse/constants';
import { getCollapse } from 'redux/modules/collapse/selectors';

interface UseLeftNavigationSizesProps {
  leftNavigationRef: RefObject<HTMLDivElement>;
  favoritesWrapperRef: RefObject<HTMLDivElement>;
  activeMarketsWrapperRef: RefObject<HTMLDivElement>;
}

const useLeftNavigationSizes = ({
  leftNavigationRef,
  favoritesWrapperRef,
  activeMarketsWrapperRef
}: UseLeftNavigationSizesProps) => {
  const collapseStore = useSelector(getCollapse)[LEFT_PANEL_COLLAPSE_GROUP];
  const windowHeight = useSelector(getWindowHeight);

  const dispatch = useDispatch();

  useEffect(() => {
    const navigationHeight = leftNavigationRef?.current?.clientHeight;

    if (navigationHeight) {
      const isActiveMarketsOpened = collapseStore[ACTIVE_MARKETS_COLLAPSE].openedState;
      const isFavoriteMarketsOpened = collapseStore[FAVORITES_COLLAPSE].openedState;

      const favoritesMaxHeight = navigationHeight - ((favoritesWrapperRef?.current?.offsetTop || 0) + 20 + 40);
      const activeMarketsMaxHeight = navigationHeight - ((activeMarketsWrapperRef?.current?.offsetTop || 0) + 40);

      if (isActiveMarketsOpened && isFavoriteMarketsOpened) {
        const favoritesScrollHeight = favoritesMaxHeight / 2;

        dispatch(
          setElementHeight({
            name: ElementNames.FAVORITES_CONTENT_HEIGHT,
            height: favoritesScrollHeight
          })
        );

        dispatch(
          setElementHeight({
            name: ElementNames.ACTIVE_MARKETS_CONTENT_HEIGHT,
            height: favoritesScrollHeight
          })
        );
      } else {
        dispatch(
          setElementHeight({
            name: ElementNames.FAVORITES_CONTENT_HEIGHT,
            height: isFavoriteMarketsOpened ? favoritesMaxHeight : 0
          })
        );

        dispatch(
          setElementHeight({
            name: ElementNames.ACTIVE_MARKETS_CONTENT_HEIGHT,
            height: isActiveMarketsOpened ? activeMarketsMaxHeight : 0
          })
        );
      }
    }
  }, [activeMarketsWrapperRef, collapseStore, dispatch, favoritesWrapperRef, leftNavigationRef, windowHeight]);
};

export default useLeftNavigationSizes;
