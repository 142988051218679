import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import Button from 'components/Button';
import { CancelActionStatuses, CurrentBetActions, EXCHANGE, GAME } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { CancelAllBtnStates } from 'constants/betslip';
import { betslipBranding } from 'constants/branding';
import { CANCEL_ACTION_FETCHING_TIMEOUT } from 'constants/placement';
import {
  getBalanceWsEnabled,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled
} from 'redux/modules/appConfigs/selectors';
import { setErrorForUnmatchedOffers } from 'redux/modules/betslip';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { fetchCancelActionStatus, removeCancelActionStatus, setCancelAllBtnState } from 'redux/modules/cancelActions';
import { getCancelActionStatus, getLoading } from 'redux/modules/cancelActions/selectors';
import { setCurrentBetActionForAll, setCurrentBetsListCanBeRemoved } from 'redux/modules/currentBets';
import {
  getCurrentBetsByType,
  getCurrentBetsLengthByType,
  getIsAllUnmatchedBetsCancellingOrEditing
} from 'redux/modules/currentBets/selectors';
import { cancelAllBets, successCancelAllBets } from 'redux/modules/placement';
import { getCancelledBetsStatusId } from 'redux/modules/placement/selectors';
import { TPlacementError } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';

import styles from './CancelAllUnmatched.module.scss';

const CancelAllUnmatched = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const isCancelAllDisabled = useSelector(getIsAllUnmatchedBetsCancellingOrEditing(isGameBetSlip));
  const loading = useSelector(getLoading);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const cancelledBetsStatusId = useSelector(getCancelledBetsStatusId);
  const cancelActionStatus = useSelector(getCancelActionStatus);
  const unmatchedBets = useSelector(
    getCurrentBetsByType({ type: MatchTypes.UNMATCHED, isGameType: isGameBetSlip, ignoreCancelled: true })
  );
  const unmatchedBetsCountWithoutCancelled = useSelector(
    getCurrentBetsLengthByType({ type: MatchTypes.UNMATCHED, isGameType: isGameBetSlip })
  );

  const [showDialog, setShowDialog] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const statusesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  useOnClickOutside(ref, () => setShowDialog(false));

  const handleClickCancelAllPreview = () => {
    if (!isCancelAllDisabled) {
      setShowDialog(true);
    }
  };

  const handleCancelAllError = (error: TPlacementError | string) => {
    const offerIds = unmatchedBets.map(({ offerId }) => offerId);

    dispatch(setErrorForUnmatchedOffers({ offerIds, error }));
    dispatch(
      setCurrentBetActionForAll(
        offerIds.map(offerId => {
          return { offerId, action: null };
        })
      )
    );
  };

  const cancelAllHandler = () => {
    dispatch(setCancelAllBtnState(CancelAllBtnStates.CANCELLING));
    dispatch(
      cancelAllBets({
        data: { betTypes: [isGameBetSlip ? GAME : EXCHANGE] },
        errorCallback: handleCancelAllError
      })
    );

    dispatch(
      setCurrentBetActionForAll(
        unmatchedBets.map(({ offerId }) => {
          return { offerId, action: CurrentBetActions.CANCELLING };
        })
      )
    );

    setShowDialog(false);
  };

  const clearStatusInterval = () => {
    if (statusesInterval.current) {
      clearInterval(statusesInterval.current);
    }
  };

  useEffect(() => {
    if (cancelledBetsStatusId) {
      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }

      statusesInterval.current = setInterval(() => {
        if (!loadingRef.current) {
          dispatch(
            fetchCancelActionStatus({
              cancelActionId: cancelledBetsStatusId,
              onSuccess: response => {
                if (
                  (response.status === CancelActionStatuses.SUCCESSFUL ||
                    response.status === CancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED) &&
                  response.offerIds
                ) {
                  dispatch(
                    setCurrentBetsListCanBeRemoved(
                      response.offerIds.map(offerId => {
                        return {
                          offerId,
                          canBeRemoved: true
                        };
                      })
                    )
                  );
                }
              }
            })
          );
        }
      }, CANCEL_ACTION_FETCHING_TIMEOUT);
    }
  }, [cancelledBetsStatusId]);

  useEffect(() => {
    if (cancelActionStatus) {
      if (
        cancelActionStatus.status === CancelActionStatuses.SUCCESSFUL ||
        cancelActionStatus.status === CancelActionStatuses.SUCCESSFUL_WITH_LESS_CANCELLED
      ) {
        dispatch(removeCancelActionStatus());

        clearStatusInterval();
        dispatch(successCancelAllBets(null));
        dispatch(setCancelAllBtnState(CancelAllBtnStates.HIDDEN));
      } else if (cancelActionStatus.status === CancelActionStatuses.FAILED) {
        clearStatusInterval();
      }
    }
  }, [cancelActionStatus?.id, cancelActionStatus?.status]);

  useEffect(() => {
    return () => {
      clearStatusInterval();
      dispatch(successCancelAllBets(null));
      dispatch(removeCancelActionStatus());
    };
  }, []);

  if (!unmatchedBetsCountWithoutCancelled) {
    return null;
  }

  return (
    <div className={styles.cancelAll} ref={ref}>
      <button
        className={classNames(styles.cancelAll__btn, betslipBranding.CANCEL_ALL_LINK)}
        onClick={handleClickCancelAllPreview}
        disabled={isCancelAllDisabled}
      >
        {t('betslip.labels.cancelAllUnmatched')}
      </button>
      {showDialog && (
        <div className={styles.cancelAll__dialog}>
          <p className={styles.cancelAll__dialog__ensureText}>{t('betslip.labels.ensureToCancelAllUnmatched')}</p>
          <div className={styles.cancelAll__dialog__buttons}>
            <Button className={styles.cancelAll__dialog__btn} onClick={() => setShowDialog(false)} variant="secondary">
              {t('betslip.labels.type.no')}
            </Button>
            <Button onClick={cancelAllHandler} className={styles.cancelAll__dialog__btn}>
              {t('betslip.labels.type.yes')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CancelAllUnmatched;
