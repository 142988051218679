import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices } from 'constants/app';
import { getAppDevice, getIsLandscape } from 'redux/modules/appConfigs/selectors';
import { IMarket } from 'types/markets';

import Comments from '../Comments';
import TimeForm123 from '../TimeForm123';

import styles from './styles.module.scss';

interface ContentProps {
  /**
   * Market data
   */
  market: IMarket;
}

const Content = ({ market }: ContentProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const isLandscape = useSelector(getIsLandscape);

  const verdict = market.timeformStatistics?.verdict;
  const isDesktop = device === Devices.DESKTOP;
  const isFullView = isDesktop || isLandscape;
  const isShowTimeForm123 = verdict?.timeform123;

  return (
    <div
      className={classNames('biab_timeform-content', styles.timeForm__content, {
        'biab_timeform-xs': isFullView
      })}
    >
      <div
        className={classNames(styles.timeForm__wrapper, {
          [styles.timeForm__wrapper__mobile]: !isFullView
        })}
      >
        <div>
          <div className={classNames('biab_timeform-section', styles.timeForm__section)}>
            <h4 className={classNames('biab_tf-header', styles.timeForm__header)}>{t('timeform.timefromView')}</h4>
            <p>{verdict?.timeformView}</p>
          </div>

          {verdict?.timeformCashOurHints && (
            <div className={classNames('biab_timeform-section', styles.timeForm__section)}>
              <h4 className={classNames('biab_tf-header', styles.timeForm__header)}>
                {t('timeform.timefromCashOutHints')}
              </h4>
              <p>{verdict.timeformCashOurHints}</p>
            </div>
          )}
        </div>

        {isShowTimeForm123 && <TimeForm123 timeform123={verdict.timeform123} />}
      </div>

      {isDesktop && verdict?.timeformComments && (
        <div className="biab_timeform-section biab_timeform-comments-section">
          <h4 className={classNames('biab_tf-header', styles.timeForm__header)}>{t('timeform.timeformComments')}</h4>
          <Comments timeformComments={verdict.timeformComments} />
        </div>
      )}
    </div>
  );
};

export default Content;
