import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { componentsBranding, inlinePlacementBranding } from 'constants/branding';
import { useInlineSelectedBetView } from 'hooks/useInlinePlacement';
import { getCurrentBetByOfferId } from 'redux/modules/currentBets/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getInlinePlacedOfferStatuses } from 'utils/inlinePlacement';

import InlineBetActions from '../InlineBetActions';
import InlineBetInfo from '../InlineBetInfo';
import InlineBetRemove from '../InlineBetRemove';
import InlinePlacedBetIcon from '../InlinePlacedBetIcon';
import InlinePlacedBetMessage from '../InlinePlacedBetMessage';

import styles from './styles.module.scss';

type TInlinePlacedBetProps = { bet: TInlineSelectedBet };

const InlinePlacedBet = ({ bet }: TInlinePlacedBetProps) => {
  const currentBet = useSelector(getCurrentBetByOfferId(bet?.placedBet?.offerId || 0));

  const { isFullyMatched, isUnmatched, isPartiallyMatched, isCancelled, isLapsed } = getInlinePlacedOfferStatuses(
    currentBet ?? bet.placedBet
  );

  const { isShortView } = useInlineSelectedBetView({ sportId: bet.sportId, pageBlock: bet.pageBlock });

  return (
    <div className={classNames(styles.inlinePlacedBet, inlinePlacementBranding.PLACED_BET_WRAPPER)}>
      <div
        className={classNames(styles.inlinePlacedBet__block, {
          [styles.inlinePlacedBet__block__matched]: isFullyMatched,
          [styles.inlinePlacedBet__block__unmatched]: isUnmatched || isPartiallyMatched,
          [styles.inlinePlacedBet__block__cancelled]: isCancelled || isLapsed,
          [inlinePlacementBranding.PLACED_BET]: isUnmatched || isPartiallyMatched,
          [inlinePlacementBranding.PLACED_BET_BORDER]: isUnmatched || isPartiallyMatched,
          [componentsBranding.NOTIFICATION]: isFullyMatched || isCancelled || isLapsed,
          [componentsBranding.SUCCESS]: isFullyMatched
        })}
      >
        {isShortView && (
          <div
            className={classNames(styles.inlinePlacedBet__remove, {
              [styles.inlinePlacedBet__remove__middle]: isFullyMatched || isCancelled
            })}
          >
            <InlineBetRemove bet={bet} />
          </div>
        )}
        <div
          className={classNames(styles.inlinePlacedBet__inner, {
            [styles.inlinePlacedBet__shortView]: isShortView
          })}
        >
          <div
            className={classNames(styles.inlinePlacedBet__infoWrap, {
              [styles.inlinePlacedBet__infoWrap__shortView]: isShortView
            })}
          >
            <InlinePlacedBetIcon bet={bet} />
            <div className={styles.inlinePlacedBet__info}>
              <InlineBetInfo bet={bet} />
              <InlinePlacedBetMessage bet={bet} />
            </div>
          </div>
          {(isUnmatched || isPartiallyMatched) && (
            <div
              className={classNames(styles.inlinePlacedBet__actions, {
                [styles.inlinePlacedBet__actions__shortView]: isShortView
              })}
            >
              <InlineBetActions bet={bet} />
            </div>
          )}
          {!isShortView && <InlineBetRemove bet={bet} />}
        </div>
      </div>
    </div>
  );
};

export default InlinePlacedBet;
