import { memo } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import PLTable from 'components/PLTable';
import { CookieNames, CurrencySettingsPlacements, Devices, MarketStatuses } from 'constants/app';
import { PLTableType } from 'constants/marketsTable';
import { DEFAULT_MARKET_UNITS } from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getAppDevice, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { TMarketLines, TMarketResponse } from 'redux/modules/market/type';
import {
  getMarketPricesCurrency,
  getMatchedAmountByMarketPricesId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { PlacementPage } from 'types';
import { IMarket, MarketActionsRowClasses } from 'types/markets';

import MarketActionsRow from '../MarketActionsRow';
import MarketBackLayRow from '../MarketBackLayRow';

import styles from './styles.module.scss';

interface MarketSelectionsRowProps {
  /**
   * Sport or racing markets
   * @return object
   */
  market: IMarket | TMarketResponse;
  /**
   * Show all runners?
   */
  showAll?: boolean;
  /**
   * Lines text
   */
  linesText?: string;
  /**
   * Lines
   */
  lines?: TMarketLines;
  /**
   * Callback fired to show all runners
   */
  onShowAllRunners?: () => void;
  /**
   * Callback fired to minimize
   */
  onMinimize?: () => void;
  /**
   * Callback fired to reset view
   */
  onResetView?: () => void;
  /**
   * Whether to show the matched amount of market
   */
  showMatchedAmount?: boolean;
  /**
   * Show space for deep prices always even if market depth is hidden
   */
  showDeepPrices?: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;

  /**
   * Classes for MarketActionsRow component customization
   */
  actionsClasses?: MarketActionsRowClasses;
  page: PlacementPage;
}

const MarketSelectionsRow = ({
  market,
  lines,
  linesText,
  showAll,
  onShowAllRunners,
  onResetView,
  onMinimize,
  showMatchedAmount = false,
  showDeepPrices = false,
  isDepthEnabled,
  actionsClasses,
  page
}: MarketSelectionsRowProps) => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.PL_TABLE_VISIBLE]);

  const device = useSelector(getAppDevice);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const marketStatus = useSelector(getStatusByMarketPricesId(market.marketId));
  const marketPricesCurrency = useSelector(getMarketPricesCurrency(market.marketId));
  const totalMatched = useSelector(getMatchedAmountByMarketPricesId(market.marketId));

  const { formattedAmount: matchedAmount } = useFormatCurrency(totalMatched, marketPricesCurrency, {
    isCheckIndian: true,
    placement: CurrencySettingsPlacements.MATCHED_AMOUNT
  });

  const isClosed = marketStatus === MarketStatuses.CLOSED;
  const isMarketClosedInitially = isClosed && market?.closedDate;

  const isShowPlTable = PLTableType.includes(market?.description?.bettingType) && !isClosed;

  const handlerPLTable = () => {
    setCookie(CookieNames.PL_TABLE_VISIBLE, cookies.PL_TABLE_VISIBLE ? '' : true);
  };

  const marketBackLayRow = !isClosed && marketStatus && (
    <MarketBackLayRow market={market} isDepthEnabled={isDepthEnabled} showDeepPrices={showDeepPrices} page={page} />
  );

  return (
    <div
      className={classNames(styles.marketSelections, {
        [styles.marketSelections__depthDisabled]: !isDepthEnabled,
        [styles.marketSelections__closeBlock]: isClosed
      })}
    >
      {isMarketClosedInitially && (
        <span className={classNames('biab_closed-market-status', styles.marketSelections__closed)}>
          {t('market.selections.status.closed')}
        </span>
      )}

      {!!onShowAllRunners && !!onMinimize && !!onResetView && !!linesText && !!lines && (
        <MarketActionsRow
          market={market}
          showAll={showAll}
          onShowAllRunners={onShowAllRunners}
          onMinimize={onMinimize}
          onResetView={onResetView}
          linesText={linesText}
          lines={lines}
          classes={actionsClasses}
          isShowPlTable={isShowPlTable}
          handlerPLTable={handlerPLTable}
        />
      )}
      {device === Devices.MOBILE && cookies.PL_TABLE_VISIBLE && isShowPlTable && (
        <PLTable
          currencyCode={marketPricesCurrency}
          marketId={market.marketId}
          bettingType={market.description.bettingType}
          marketType={market.description.marketType}
          units={market?.description?.lineRangeInfo?.marketUnit || DEFAULT_MARKET_UNITS}
        />
      )}

      {showMatchedAmount ? (
        <div className={styles.marketSelections__matchedRow}>
          <span className={styles.marketSelections__matchedRow__matchedAmount}>
            {t(`${isPNCEnabled ? 'pnc.market.matched' : 'account.mybets.btns.matched'}`)}: {matchedAmount}
          </span>
          {marketBackLayRow}
        </div>
      ) : (
        marketBackLayRow
      )}
    </div>
  );
};

export default memo(MarketSelectionsRow);
