export const BannerSections = {
  HOME_PAGE_BANNER: 'HOME_PAGE_BANNER',
  SUB_PAGE_CONTENT_BANNER: 'SUB_PAGE_CONTENT_BANNER',
  BADGE_BANNER: 'BADGE_BANNER',
  BADGE_BANNER_SKYSCRAPER: 'BADGE_BANNER_SKYSCRAPER',
  PROMOTION_BANNER: 'PROMOTION_BANNER',
  LOGO: 'LOGO',
  FOOTER_LOGO: 'FOOTER_LOGO',
  FAVICON: 'FAVICON',
  NOT_FOUND_BACKGROUND: 'NOT_FOUND_BACKGROUND'
} as const;

export const BannersContentOptions = {
  IMAGES: 'IMAGES',
  HTML: 'HTML'
} as const;
