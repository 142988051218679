import { ChangeEvent, useRef } from 'react';
import classNames from 'classnames';

import { mobileIcons } from 'constants/branding';

import styles from './ToggleSwitch.module.scss';

type ToggleSwitchProps = {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  containerClassName?: string;
};

const ToggleSwitch = ({ checked, onChange, containerClassName = '' }: ToggleSwitchProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const onClick = () => {
    setTimeout(() => {
      ref.current?.blur();
    }, 300);
  };

  return (
    <label className={classNames(styles.switch, containerClassName)}>
      <input
        ref={ref}
        type="checkbox"
        className={styles.switch__checkbox}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
      />
      <span className={classNames(styles.switch__slider, mobileIcons.TOGGLE, { [mobileIcons.ACTIVE]: checked })} />
    </label>
  );
};

export default ToggleSwitch;
