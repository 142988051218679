import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import MarketRules from 'components/AsianViewPageModule/components/CompetitionTitle/MarketRules';
import CorrectScoreRunners from 'components/AsianViewPageModule/components/CorrectScoreRunners';
import { CORRECT_SCORE_MARKETS_LABELS } from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { TAsianViewMarket } from 'redux/modules/asianView/type';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { AsianCorrectScoreMarket } from 'types/asianView';
import { mapCorrectScoreRunners } from 'utils/asianView';

import styles from './styles.module.scss';

interface CorrectScoreMarketProps {
  market: TAsianViewMarket;
  competitionId: string;
  eventId: string;
  showBackLayLabels: boolean;
}

const CorrectScoreMarket = ({ market, competitionId, eventId, showBackLayLabels }: CorrectScoreMarketProps) => {
  const { t } = useTranslation();

  const { homeRunners, drawRunners, awayRunners, otherRunners } = useMemo(
    () => mapCorrectScoreRunners(market),
    [market]
  );

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market.id,
    eventId,
    section: MarketsPricesSocketParamsSections.AsianViewMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });

  return (
    <div
      ref={ref}
      className={classNames('biab_asian-view-market', styles.market)}
      data-market-id={market.id}
      data-event-id={eventId}
      data-market-prices="true"
    >
      <div className={classNames(styles.marketName, branding.MARKET_HEADER)}>
        <span>{t(CORRECT_SCORE_MARKETS_LABELS[market.marketType as AsianCorrectScoreMarket])}</span>
        <MarketRules marketId={market.id} />
      </div>
      <div
        className={classNames(styles.runnersColumns, branding.MAIN_CONTAINER_BORDER, {
          [styles.runnersColumns__full]: [2, 4].includes(drawRunners.runners.length)
        })}
      >
        {[homeRunners, drawRunners, awayRunners, otherRunners].map((runners, index) => (
          <CorrectScoreRunners
            competitionId={competitionId}
            eventId={eventId}
            marketId={market.id}
            showBackLayLabels={showBackLayLabels}
            {...runners}
            key={`${market.id}_${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default CorrectScoreMarket;
