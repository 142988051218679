import VirtualKeyboard from 'components/CashOutButton/components/CashOutState/partials/VirtualKeyboard';
import { InputFieldTypes } from 'constants/betslip';
import { TAsianMobileSelectedBet, TAsianMobileUpdateData } from 'redux/modules/asianViewMobileBetslip/type';

interface IMobileKeyboard {
  bet: TAsianMobileSelectedBet;
  updateMobileBet: ({ size, profit, focusedField }: TAsianMobileUpdateData) => void;
}
const MobileKeyboard = ({ bet, updateMobileBet }: IMobileKeyboard) => {
  const handleKeyClick = (key: string) => {
    const validation = /^\d+\.{0,1}\d{0,2}$/;

    const value = bet.focusedField === InputFieldTypes.SIZE ? bet.size : bet.profit;
    const updatedValue = value + key;
    const isValid = !((value || '').toString() === '0' && key !== '.') && validation.test(updatedValue);

    if (bet.focusedField && isValid) {
      updateMobileBet(bet.focusedField === InputFieldTypes.SIZE ? { size: updatedValue } : { profit: updatedValue });
    }
  };

  const handleBackSpace = () => {
    if (bet.focusedField) {
      updateMobileBet(
        bet.focusedField === InputFieldTypes.SIZE
          ? { size: bet.size?.toString().slice(0, -1) }
          : { profit: bet.profit?.toString().slice(0, -1) }
      );
    }
  };

  return (
    <VirtualKeyboard onKeyClick={handleKeyClick} onBackSpace={handleBackSpace} isWidthBorder shouldIgnoreBlurEvent />
  );
};

export default MobileKeyboard;
