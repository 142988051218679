import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useUnmount } from 'usehooks-ts';

import BettingProfitAndLoss from 'components/BettingProfitAndLoss';
import Footer from 'components/Footer';
import PokerStarsFooter from 'components/PokerStarsFooter';
import MobileMyBetsSkeleton from 'components/Skeleton/MobileMyBetsSkeleton';
import MyBetsSkeleton from 'components/Skeleton/MyBetsSkeleton';
import { CookieNames, ElementNames } from 'constants/app';
import { POKER_STAR } from 'constants/icons';
import { ACCOUNT_BASE_URL, MY_BETS_BASE_URL } from 'constants/locations';
import { NavigationButtons } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useElementSize from 'hooks/useElementSize';
import AccountStatementPage from 'pages/AccountStatementPage/AccountStatementPage';
import MyExchangeBetsPage from 'pages/MyExchangeBetsPage/MyExchangeBetsPage';
import { getOperator } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import {
  getIsLoadingProducts,
  getIsMobileStatementItemDetails,
  getSelectedTabIsFirstLoaded,
  isShowMobileBetDetails,
  myBetsLoading
} from 'redux/modules/myBets/selectors';
import { PLBetType } from 'types/myBets';
import { iosBundleRedirect } from 'utils';

import AccountPagesHeader from '../AccountPagesHeader/AccountPagesHeader';
import MobileMyBetsPageHeader from '../MobileMyBetsPageHeader/MobileMyBetsPageHeader';

import styles from './MyBetsPagesContent.module.scss';

type MyBetsPagesContentProps = {
  selectedTab: string;
  activePLTab: PLBetType;
};

const MyBetsPagesContent = ({ selectedTab, activePLTab }: MyBetsPagesContentProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [, setCookie] = useCookies([CookieNames.PROFITANDLOSS_BETTYPE, CookieNames.MY_BETS_SEARCH]);

  const isMyBetsPage = location.pathname.includes(`${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`);

  const operator = useSelector(getOperator);
  const headerHeightFromStore = useSelector(getElementHeightByName(ElementNames.HEADER_HEIGHT));
  const footerHeight = useSelector(getElementHeightByName(ElementNames.FOOTER_HEIGHT));
  const isLoading = useSelector(myBetsLoading);
  const isFirstLoaded = useSelector(getSelectedTabIsFirstLoaded(selectedTab));
  const isDetailsOpen = useSelector(isShowMobileBetDetails);
  const isMobileStatementItemDetails = useSelector(getIsMobileStatementItemDetails);
  const isLoadingProducts = useSelector(getIsLoadingProducts);

  const { isMobile } = useDevice();

  const showPokerStarFooter = operator === POKER_STAR && iosBundleRedirect;

  const contentStyles = isMobile
    ? { minHeight: `calc(100vh - ${footerHeight}px)` }
    : { height: `calc(100vh - (${headerHeightFromStore}px))` };

  const [wrapperRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useUnmount(() => {
    setCookie(CookieNames.MY_BETS_SEARCH, '', { path: '/' });
  });

  const getContent = () => {
    if ((isLoading && !isFirstLoaded) || isLoadingProducts) {
      if (isMobile) {
        return <MobileMyBetsSkeleton isShowHeader isShowTable />;
      }

      return <MyBetsSkeleton isShowHeader isShowTable isUsePadding />;
    }

    return (
      <>
        {!isMobile && <AccountPagesHeader />}
        {isMobile && !isDetailsOpen && !isMobileStatementItemDetails && isMyBetsPage && <MobileMyBetsPageHeader />}
        {selectedTab == NavigationButtons.MyBets && <MyExchangeBetsPage />}
        {selectedTab === NavigationButtons.Statement && <AccountStatementPage />}
        {selectedTab === NavigationButtons.ProfitAndLoss && <BettingProfitAndLoss activePLTab={activePLTab} />}
      </>
    );
  };

  return (
    <main
      className={classNames(styles.contentWrapWithoutScroll, {
        [styles.contentWrapWithoutScroll__mobile]: isMobile
      })}
      style={contentStyles}
      id="my-bets-pages-content"
    >
      <div ref={wrapperRef}>
        <div
          className={classNames(styles.scrollableContentWrapper, {
            [styles.scrollableContentWrapper__mobile]: isMobile
          })}
        >
          {getContent()}
        </div>
      </div>
      {showPokerStarFooter ? (
        <PokerStarsFooter />
      ) : (
        <div className={styles.contentWrapWithoutScroll__footer}>
          <Footer />
        </div>
      )}
    </main>
  );
};

export default MyBetsPagesContent;
