import { useTranslation } from 'react-i18next';
import { isInteger, toNumber } from 'lodash';

import { SportIds } from 'constants/app';
import { THandicap } from 'types/bets';
import { getDecimal } from 'utils/betValidation';

/**
 * Labels are used to show Profit and Loss values in Betslip for bets with AH Double line, AH Single line, Line markets,
 * Alt total goals, Spreads and Totals, Each way markets
 */
const useBetLabels = () => {
  const { t } = useTranslation();

  // AH Double Line
  const teamWin = (team: string) => t('betslip.labels.teamWin', { team: team });
  const matchDraw = () => t('betslip.labels.matchDraw');
  const teamWinByN = (team: string, n: number) => t('betslip.labels.teamWinByN', { team: team, N: n });
  const teamWinByNOrMore = (team: string, n: number) => t('betslip.labels.teamWinByNOrMore', { team: team, N: n });
  const teamDrawOrWin = (team: string) => t('betslip.labels.teamDrawOrWin', { team: team });
  const teamDoNotWin = (team: string) => t('betslip.labels.teamDoNotWin', { team: team });
  const teamDoNotWinByNOrMore = (team: string, n: number) =>
    t('betslip.labels.teamDoNotWinByNOrMore', { team: team, N: n });

  // AH Single Line
  const ifNRunsOrMore = (n: number) => t('betslip.labels.ifNRunsOrMore', { N: n });
  const ifLessThanNRuns = (n: number) => t('betslip.labels.ifLessThanNRuns', { N: n });
  const ifNGoalsOrMore = (n: number) => t('betslip.labels.ifNGoalsOrMore', { N: n });
  const ifLessThanNGoals = (n: number) => t('betslip.labels.ifLessThanNGoals', { N: n });

  // Alt Total Goals
  const NGoalsOrLess = (n: number) => {
    switch (n) {
      case 0:
        return t('betslip.labels.NGoals', { N: n });
      case 1:
        return t('betslip.labels.OneGoalOrLess');
      default:
        return t('betslip.labels.NGoalsOrLess', { N: n });
    }
  };

  const NGoalsOrMore = (n: number) => {
    switch (n) {
      case 1:
        return t('betslip.labels.OneGoalOrMore');
      default:
        return t('betslip.labels.NGoalsOrMore', { N: n });
    }
  };

  const ExactlyNGoals = (n: number) => {
    switch (n) {
      case 1:
        return t('betslip.labels.ExactlyOneGoal');
      default:
        return t('betslip.labels.ExactlyNGoals', { N: n });
    }
  };

  // Line & Spreads and Totals
  const ifEqualOrMoreRuns = (n: number, runs: string) => t('betslip.labels.line.isEqualOrMore', { N: n, R: runs });
  const ifLessRuns = (n: number, runs: string) => t('betslip.labels.line.isLessThan', { N: n, R: runs });

  const NPointsOrLess = (n: number, units: string) => {
    switch (n) {
      case 0:
        return t('betslip.labels.NPoints', { N: n, units: units });
      default:
        return t('betslip.labels.NPointsOrLess', { N: n, units: units });
    }
  };

  const NPointsOrMore = (n: number, units: string) => t('betslip.labels.NPointsOrMore', { N: n, units: units });
  const ExactlyNPoints = (n: number, units: string) => t('betslip.labels.ExactlyNPoints', { N: n, units: units });

  // Variable handicap
  const ifCardNOrFurther = (n: number | string) => t('betslip.labels.ifCardNOrFurther', { N: n });
  const ifNotAsFarAsCardN = (n: number | string) => t('betslip.labels.ifNotAsFarAsCardN', { N: n });

  /**
   * Get labels for AH Double line bets
   *
   * @param handicapValue
   * @param team1
   * @param team2
   */
  const getAHDoubleLineLabels = (handicapValue: number, team1: string, team2: string) => {
    let n: number;
    let labelW = '';
    let labelS = '';
    let labelL = '';

    switch (true) {
      // ------------------ CASE 1 -------------------
      case handicapValue === 0:
        labelW = teamWin(team1);
        labelS = matchDraw();
        labelL = teamWin(team2);
        break;

      case handicapValue === -1:
        labelW = teamWinByNOrMore(team1, 2);
        labelS = teamWinByN(team1, 1);
        labelL = teamDoNotWin(team1);
        break;

      case handicapValue === 1:
        labelW = teamDrawOrWin(team1);
        labelS = teamWinByN(team2, 1);
        labelL = teamDoNotWinByNOrMore(team2, 2);
        break;

      // -N, where N = 2, 3, ...
      case handicapValue <= -2 && handicapValue % 1 === 0:
        n = Math.abs(handicapValue);
        labelW = teamWinByNOrMore(team1, n + 1);
        labelS = teamWinByN(team1, n);
        labelL = teamDoNotWinByNOrMore(team1, n);
        break;

      // N, where N = 2, 3, ...
      case handicapValue >= 2 && handicapValue % 1 === 0:
        n = handicapValue;
        labelW = teamDoNotWinByNOrMore(team2, n);
        labelS = teamWinByN(team2, n);
        labelL = teamDoNotWinByNOrMore(team2, n + 1);
        break;

      // ------------------ CASE 2 -------------------
      case handicapValue === -0.5:
        labelW = teamWin(team1);
        labelS = teamDoNotWin(team1);
        labelL = '';
        break;

      case handicapValue === 0.5:
        labelW = teamDrawOrWin(team1);
        labelS = teamWin(team2);
        labelL = '';
        break;

      // ------------------ CASE 3 -------------------
      //  -N.5, where N = 1, 2, 3, ...
      case handicapValue <= -1.5 && getDecimal(handicapValue) === 0.5:
        n = Math.abs(handicapValue) - 0.5;
        labelW = teamWinByNOrMore(team1, n + 1);
        labelS = '';
        labelL = teamDoNotWinByNOrMore(team1, n + 1);
        break;

      //  N.5, where N = 1, 2, 3, ...
      case handicapValue >= 1.5 && getDecimal(handicapValue) === 0.5:
        n = handicapValue - 0.5;
        labelW = teamDoNotWinByNOrMore(team2, n + 1);
        labelS = '';
        labelL = teamWinByNOrMore(team2, n + 1);
        break;

      // ------------------ CASE 4 -------------------
      // 0 && -0.5
      case handicapValue === -0.25:
        labelW = teamWin(team1);
        labelS = matchDraw();
        labelL = teamWin(team2);
        break;

      // -0.5 && -1.0
      case handicapValue === -0.75:
        labelW = teamWinByNOrMore(team1, 2);
        labelS = teamWinByN(team1, 1);
        labelL = teamDoNotWin(team1);
        break;

      // -1.0 && -1.5
      case handicapValue === -1.25:
        labelW = teamWinByNOrMore(team1, 2);
        labelS = teamWinByN(team1, 1);
        labelL = teamDoNotWin(team1);
        break;

      // N.5 && (N + 1).0, where N = 1, 2, 3, ...
      case handicapValue >= 1.75 && getDecimal(handicapValue) === 0.75:
        n = handicapValue - 0.75;
        labelW = teamDoNotWinByNOrMore(team2, n + 1);
        labelS = teamWinByN(team2, n + 1);
        labelL = teamWinByNOrMore(team2, n + 2);
        break;

      // -N.0 && -N.5, where N = 2, 3, ...
      case handicapValue <= -2 && getDecimal(handicapValue) === 0.25:
        n = Math.abs(handicapValue) - 0.25;
        labelW = teamWinByNOrMore(team1, n + 1);
        labelS = teamWinByN(team1, n);
        labelL = teamDoNotWinByNOrMore(team1, n);
        break;

      // ------------------ CASE 5 -------------------
      // 0 && 0.5
      case handicapValue === 0.25:
        labelW = teamWin(team1);
        labelS = matchDraw();
        labelL = teamWin(team2);
        break;

      // 0.5 && 1
      case handicapValue === 0.75:
        labelW = teamDrawOrWin(team1);
        labelS = teamWinByN(team2, 1);
        labelL = teamWinByNOrMore(team2, 2);
        break;

      // 1 && 1.5
      case handicapValue === 1.25:
        labelW = teamDrawOrWin(team1);
        labelS = teamWinByN(team2, 1);
        labelL = teamWinByNOrMore(team2, 2);
        break;

      // -N.5 && -(N + 1).0, where N = 1, 2, 3, ...
      case handicapValue <= -1.75 && getDecimal(handicapValue) === 0.75:
        n = Math.abs(handicapValue) - 0.75;
        labelW = teamWinByNOrMore(team1, n + 2);
        labelS = teamWinByN(team1, n + 1);
        labelL = teamDoNotWinByNOrMore(team1, n + 1);
        break;

      // N && N.5, where N = 2, 3, ...
      case handicapValue >= 2 && getDecimal(handicapValue) === 0.25:
        n = handicapValue - 0.25;
        labelW = teamDoNotWinByNOrMore(team2, n);
        labelS = teamWinByN(team2, n);
        labelL = teamWinByNOrMore(team2, n + 1);
        break;
    }

    return {
      labelW,
      labelS,
      labelL
    };
  };

  /**
   * Get labels for AH Single line bets
   *
   * @param eventTypeId
   * @param handicap
   */
  const getAHSingleLineLabels = (eventTypeId: string, handicap?: THandicap | null) => {
    const handicapValue = Math.abs(toNumber(handicap || 0));

    let labelW = '';
    let labelL = '';

    switch (eventTypeId) {
      case SportIds.SOCCER:
        labelW = ifNGoalsOrMore(handicapValue);
        labelL = ifLessThanNGoals(handicapValue);
        break;
      case SportIds.CRICKET:
        labelW = ifNRunsOrMore(handicapValue);
        labelL = ifLessThanNRuns(handicapValue);
        break;
    }

    return { labelW, labelL };
  };
  /**
   * Get labels for Alt total goals bets
   *
   * @param handicapValue
   */
  const getAltTotalGoalsLabels = (handicapValue: number) => {
    let n: number;
    let labelW = '';
    let labelS = '';
    let labelL = '';

    switch (true) {
      case getDecimal(handicapValue) === 0.5:
        n = handicapValue - 0.5;

        labelW = NGoalsOrLess(n);
        labelL = NGoalsOrMore(n + 1);

        break;

      case handicapValue === 1:
        n = handicapValue;

        labelW = ExactlyNGoals(n - 1);
        labelS = ExactlyNGoals(n);
        labelL = NGoalsOrMore(n + 1);

        break;

      case handicapValue % 1 === 0:
        n = handicapValue;

        labelW = NGoalsOrLess(n - 1);
        labelS = ExactlyNGoals(n);
        labelL = NGoalsOrMore(n + 1);

        break;

      case getDecimal(handicapValue) === 0.75:
        n = handicapValue - 0.75;

        labelW = NGoalsOrLess(n);
        labelS = ExactlyNGoals(n + 1);
        labelL = NGoalsOrMore(n + 2);

        break;

      case getDecimal(handicapValue) === 0.25:
        n = handicapValue - 0.25;

        labelW = NGoalsOrLess(n - 1);
        labelS = ExactlyNGoals(n);
        labelL = NGoalsOrMore(n + 1);

        break;
    }

    return {
      labelW,
      labelS,
      labelL
    };
  };

  /**
   * Get labels for Line markets bets
   *
   * @param odds
   * @param rangeInfoStep
   * @param marketUnitTranslated
   */
  const getLineLabels = (odds: number, rangeInfoStep: number, marketUnitTranslated: string) => {
    let labelW = '';
    let labelL = '';
    let labelS = '';

    if (!isInteger(rangeInfoStep)) {
      if (isInteger(odds)) {
        labelW = NPointsOrMore(odds + 1, marketUnitTranslated.toLowerCase());
        labelS = ExactlyNPoints(odds, marketUnitTranslated.toLowerCase());
        labelL = NPointsOrLess(odds - 1, marketUnitTranslated.toLowerCase());
      } else {
        labelW = NPointsOrMore(Math.floor(odds) + 1, marketUnitTranslated.toLowerCase());
        labelL = NPointsOrLess(Math.floor(odds), marketUnitTranslated.toLowerCase());
      }
    } else {
      if (isInteger(odds)) {
        labelW = ifEqualOrMoreRuns(odds, marketUnitTranslated.toLowerCase());
        labelL = ifLessRuns(odds, marketUnitTranslated.toLowerCase());
      } else {
        labelW = NPointsOrMore(Math.floor(odds) + 1, marketUnitTranslated.toLowerCase());
        labelL = NPointsOrLess(Math.floor(odds), marketUnitTranslated.toLowerCase());
      }
    }

    return { labelW, labelS, labelL };
  };

  return {
    // Line & Spreads and Totals
    ifEqualOrMoreRuns,
    ifLessRuns,
    NPointsOrLess,
    NPointsOrMore,
    ExactlyNPoints,
    // Variable handicap
    ifCardNOrFurther,
    ifNotAsFarAsCardN,
    // AH Double Line labels
    getAHDoubleLineLabels,
    // AH Single Line labels
    getAHSingleLineLabels,
    // Alt Total Goals labels
    getAltTotalGoalsLabels,
    getLineLabels
  };
};

export default useBetLabels;
