import { useDispatch } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { SportIds } from 'constants/app';
import {
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE,
  ASIAN_VIEW_PAGE_PARAM,
  AsianViewMarketLinks,
  AsianViewTimeFilters
} from 'constants/asianView';
import { ASIAN_BASE_URL } from 'constants/locations';
import { setIsCollapsedRightSide } from 'redux/modules/asianView';
import { AsianViewPopularLink } from 'redux/modules/asianViewPopularLinks/type';
import { getIsValidMarketLink, getIsValidTimeFilter } from 'utils/asianView';

import AsianViewPopularLinkContent from '../AsianViewPopularLinkContent';

import styles from '../../styles.module.scss';

type AsianViewCompetitionPopularLinkProps = {
  link: AsianViewPopularLink;
  linkClassName?: string;
  arrowClassName?: string;
  noTruncate?: boolean;
};

const AsianViewCompetitionPopularLink = ({
  link,
  linkClassName = '',
  arrowClassName = '',
  noTruncate = false
}: AsianViewCompetitionPopularLinkProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { timeFilter, marketLink, sportId } = useParams();

  const page = searchParams.get(ASIAN_VIEW_PAGE_PARAM);
  const competitionIdParam = `${ASIAN_POPULAR_LINK_COMPETITION_ID}=${link.entryId}`;
  const sportIdParam = `${ASIAN_POPULAR_LINK_SPORT_ID}=${link.sportId}`;
  const pageParam = page ? `&${ASIAN_VIEW_PAGE_PARAM}=0` : '';
  const betSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);
  const betSlipCashOutPageParam = betSlipCashOutPage
    ? `&${ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE}=${betSlipCashOutPage}`
    : '';
  const search = `${competitionIdParam}&${sportIdParam}${pageParam}${betSlipCashOutPageParam}`;
  const sportIdLinkParam = sportId || link.sportId || SportIds.SOCCER;
  const timeFilterLinkParam =
    !timeFilter || !getIsValidTimeFilter(timeFilter) ? AsianViewTimeFilters.Today : timeFilter;
  const marketLinkParam =
    !marketLink || !getIsValidMarketLink(marketLink) ? AsianViewMarketLinks.HDP_AND_OU : marketLink;

  return (
    <Link
      to={{
        pathname: `${ASIAN_BASE_URL}/sport/${sportIdLinkParam}/timeFilter/${timeFilterLinkParam}/marketLink/${marketLinkParam}`,
        search
      }}
      className={classNames(styles.link, linkClassName)}
      onClick={() => dispatch(setIsCollapsedRightSide(true))}
    >
      <AsianViewPopularLinkContent link={link} arrowClassName={arrowClassName} noTruncate={noTruncate} />
    </Link>
  );
};

export default AsianViewCompetitionPopularLink;
