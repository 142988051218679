import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { PLBetTypes, PLExchangeTypes } from 'constants/myBets';
import useActivePLProduct from 'hooks/useActivePLProduct';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { fetchProfitGames, fetchProfitSports } from 'redux/modules/myBets';
import {
  bettingPLLoading,
  getEventTypeFilter,
  getMyBetsGames,
  getMyBetsMultiCurrencyValue,
  getMyBetsSports,
  getPLTotalData,
  getPLTotalElements,
  getPLTotalEventData
} from 'redux/modules/myBets/selectors';

import styles from './TotalPL.module.scss';

const TotalPL = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const betType = useActivePLProduct();
  const eventType = useSelector(getEventTypeFilter);
  const totalElements = useSelector(getPLTotalElements);
  const total = useSelector(getPLTotalData);
  const totalForEvent = useSelector(getPLTotalEventData);
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);
  const isLoading = useSelector(bettingPLLoading);
  const sports = useSelector(getMyBetsSports);
  const games = useSelector(getMyBetsGames);
  const name = useMemo(() => {
    const gameName =
      eventType?.id && betType === PLExchangeTypes.Games
        ? games.find(item => item.channelId == eventType.id)?.name
        : '';
    const sportName =
      eventType?.id && betType === PLExchangeTypes.Sports ? sports.find(item => item.id == eventType.id)?.name : '';

    return eventType?.label || sportName || gameName || '';
  }, [eventType, games, sports, betType]);

  const { isMobile } = useDevice();
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const currencyCode = isMultiCurrencySupported && multiCurrencyValue ? multiCurrencyValue : '';

  const { noFormattedAmount: totalValue } = useFormatCurrency(isLoading ? 0 : total, currencyCode, {
    noRounding: true,
    isCheckIndian: true,
    isPositiveSign: true,
    noZeroSign: true
  });
  const { noFormattedAmount: totalForEventValue } = useFormatCurrency(isLoading ? 0 : totalForEvent, currencyCode, {
    noRounding: true,
    isCheckIndian: true,
    isPositiveSign: true,
    noZeroSign: true
  });

  useEffect(() => {
    if (isMobile) {
      if (betType === PLBetTypes.Sports) {
        dispatch(fetchProfitSports(currencyCode));
      } else if (betType === PLBetTypes.Games) {
        dispatch(fetchProfitGames(currencyCode));
      }
    }
  }, []);

  return (
    <div className={classNames('biab_total-block', styles.totalBlock, { [styles.totalBlock__mobile]: isMobile })}>
      {eventType?.id ? (
        <>
          <span className={styles.sportPL}>
            {name} {t('account.profitandloss.labels.pl')}:&nbsp;
          </span>
          <span
            className={classNames({
              [styles.pl__positive]: totalForEvent > 0,
              [styles.pl__negative]: totalForEvent < 0
            })}
          >
            {totalElements > 0 ? totalForEventValue : '--'}
          </span>
        </>
      ) : (
        <>
          <span>{t('account.profitandloss.labels.totalPL')}:&nbsp;</span>
          <span
            className={classNames({
              [styles.pl__positive]: total > 0,
              [styles.pl__negative]: total < 0
            })}
          >
            {totalElements > 0 ? totalValue : '--'}
          </span>
        </>
      )}
    </div>
  );
};

export default TotalPL;
