import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { AsianViewSection } from 'types/asianView';
import { IMarketRules } from 'types/markets';

import {
  failureFetchAsianViewList,
  failureFetchMarketRules,
  failureFetchTab,
  fetchAsianViewList,
  fetchCountMarkets,
  fetchMarketRules,
  fetchSearchResults,
  fetchTab,
  setCollapses,
  successFetchAsianViewList,
  successFetchCountMarkets,
  successFetchMarketRules,
  successFetchTab
} from './index';
import { getAsianViewStringifiedCountMarkets } from './selectors';
import { AsianMarket, TFetchAsianViewListResponse } from './type';

export function* getAsianViewList(action: ReturnType<typeof fetchAsianViewList>) {
  try {
    let response: TFetchAsianViewListResponse = yield call(api.asianView.list, action.payload);

    // This condition needs if page from payload is bigger than last available page (totalPages) in response, so we need to do one more request with valid page
    if (
      !action.payload.isInfiniteScrollEnabled &&
      action.payload.page &&
      action.payload.changePage &&
      action.payload.page + 1 > response.totalPages
    ) {
      response = yield call(api.asianView.list, { ...action.payload, page: response.totalPages - 1 });
      action.payload.changePage(response.totalPages - 1);
    }

    yield put(
      successFetchAsianViewList({ ...response, isInfiniteScrollEnabled: action.payload.isInfiniteScrollEnabled })
    );
  } catch (error: any) {
    yield put(failureFetchAsianViewList(error.data));
  }
}

export function* fetchTabDataWorker({ payload }: ReturnType<typeof fetchTab>) {
  try {
    const response: Record<AsianViewSection, AsianMarket[]> = yield call(api.asianView.getMarketByTab, payload);
    if (response[payload.sections[0]]) {
      const section = payload?.sections[0] || [];

      const markets = response[payload.sections[0]];
      const collapse = markets.map(item => item.id);

      yield put(setCollapses({ collapse, section }));
      yield put(successFetchTab(markets));
    } else {
      yield put(successFetchTab([]));
    }
  } catch (error: any) {
    yield put(failureFetchTab(error.data));
  }
}

export function* getMarketRulesWorker(action: ReturnType<typeof fetchMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.asianView.marketRules, action.payload);
    yield put(successFetchMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchMarketRules(error.data));
  }
}

export function* getAsianViewMarketsCount(action: ReturnType<typeof fetchCountMarkets>) {
  try {
    const response: Record<string, number> = yield call(api.asianView.getCountMarkets, action.payload);
    const stringifiedPrevCountMarkets: string = yield select(getAsianViewStringifiedCountMarkets);
    const stringifiedResponse = JSON.stringify(response);

    if (stringifiedPrevCountMarkets !== stringifiedResponse) {
      yield put(successFetchCountMarkets({ countMarkets: response, stringifiedCountMarkets: stringifiedResponse }));
    }
  } catch (error: any) {}
}

export function* fetchSearchWorker(action: ReturnType<typeof fetchSearchResults>) {
  try {
    let response: TFetchAsianViewListResponse = yield call(api.asianSearch.getEvents, action.payload);

    if (
      !action.payload.isInfiniteScrollEnabled &&
      action.payload.page &&
      action.payload.changePage &&
      action.payload.page + 1 > response.totalPages
    ) {
      response = yield call(api.asianSearch.getEvents, { ...action.payload, page: response.totalPages - 1 });
      action.payload.changePage(response.totalPages - 1);
    }

    yield put(
      successFetchAsianViewList({
        ...response,
        isInfiniteScrollEnabled: action.payload.isInfiniteScrollEnabled,
        isSearchResults: true
      })
    );
  } catch (error: any) {
    yield put(failureFetchAsianViewList(error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchAsianViewList.type, getAsianViewList),
    takeLatest(fetchCountMarkets.type, getAsianViewMarketsCount),
    takeEvery(fetchTab.type, fetchTabDataWorker),
    takeLatest(fetchMarketRules.type, getMarketRulesWorker),
    takeLatest(fetchSearchResults.type, fetchSearchWorker)
  ]);
}
