import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import OpenBetsEvents from 'components/Betslip/components/OpenBetsEvents/OpenBetsEvents';
import OpenBetsActions from 'components/Betslip/components/OpenBetsFilters/OpenBetsActions';
import { CookieNames, SortingCookieValues, TODAY_CARD_PREFIX } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { BetSides } from 'constants/myBets';
import { useEnabledView } from 'hooks/useEnabledView';
import { getConsolidatedBets, getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { getCurrentBetsByType, getCurrentBetsLengthBySideType } from 'redux/modules/currentBets/selectors';
import { getMultiMarketEventId } from 'redux/modules/multiMarket/selectors';

import OpenedBetsMatchedSorted from '../OpenedBetsMatchedSorted/OpenedBetsMatchedSorted';
import OpenedBetsTabEmpty from '../OpenedBetsTabEmpty/OpenedBetsTabEmpty';

const OpenedBetsMatched = () => {
  const [cookies, setCookie] = useCookies([
    CookieNames.BETSLIP_FILTER_MARKET,
    CookieNames.BETSLIP_FILTER_SORTING,
    CookieNames.BETSLIP_FILTER_EVENT
  ]);
  const { eventId = '', marketId: urlMarketId = '' } = useParams();
  const marketId = urlMarketId.toString().replace(TODAY_CARD_PREFIX, '');

  const { isMultiMarketView } = useEnabledView();

  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const matchedBets = useSelector(getCurrentBetsByType({ type: MatchTypes.MATCHED, isGameType: isGameBetSlip }));
  const consolidateBets = useSelector(getConsolidatedBets);
  const multiMarketEventId = useSelector(getMultiMarketEventId);
  const filteredMatchedBackCurrentBets = useSelector(
    getCurrentBetsLengthBySideType(
      BetSides.Back,
      MatchTypes.MATCHED,
      isMultiMarketView ? undefined : cookies.FILTER_BETS_BY_MARKET_ID,
      cookies.FILTER_BETS_BY_EVENT_ID,
      consolidateBets,
      isGameBetSlip
    )
  );
  const filteredMatchedLayCurrentBets = useSelector(
    getCurrentBetsLengthBySideType(
      BetSides.Lay,
      MatchTypes.MATCHED,
      isMultiMarketView ? undefined : cookies.FILTER_BETS_BY_MARKET_ID,
      cookies.FILTER_BETS_BY_EVENT_ID,
      consolidateBets,
      isGameBetSlip
    )
  );

  useEffect(() => {
    const curEventId = isMultiMarketView ? multiMarketEventId : eventId;

    if (cookies.FILTER_BETS_BY_EVENT_ID !== undefined && curEventId && cookies.FILTER_BETS_BY_EVENT_ID !== curEventId) {
      setCookie(CookieNames.BETSLIP_FILTER_EVENT, curEventId, { path: '/' });
    }

    if (cookies.FILTER_BETS_BY_MARKET_ID !== undefined && marketId && cookies.FILTER_BETS_BY_MARKET_ID !== marketId) {
      setCookie(CookieNames.BETSLIP_FILTER_MARKET, marketId, { path: '/' });
    }

    if (!curEventId && cookies.FILTER_BETS_BY_EVENT_ID) {
      setCookie(CookieNames.BETSLIP_FILTER_EVENT, '', { path: '/' });
    }

    if (!marketId && cookies.FILTER_BETS_BY_MARKET_ID) {
      setCookie(CookieNames.BETSLIP_FILTER_MARKET, '', { path: '/' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eventId,
    marketId,
    cookies.FILTER_BETS_BY_MARKET_ID,
    cookies.FILTER_BETS_BY_EVENT_ID,
    isMultiMarketView,
    multiMarketEventId
  ]);

  if (!matchedBets.length) {
    return <OpenedBetsTabEmpty type="matched" />;
  }

  return (
    <>
      {!isGameBetSlip && <OpenBetsActions marketId={marketId} eventId={eventId} />}
      {filteredMatchedBackCurrentBets > 0 || filteredMatchedLayCurrentBets > 0 ? (
        <>
          {cookies.SORTED_BETS === SortingCookieValues.BY_EVENT && !isGameBetSlip ? (
            <OpenBetsEvents />
          ) : (
            <OpenedBetsMatchedSorted />
          )}
        </>
      ) : (
        <OpenedBetsTabEmpty type="matched" />
      )}
    </>
  );
};

export default OpenedBetsMatched;
