import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import CollapseTime from 'components/AsianViewPageModule/components/CollapseTime';
import MarketRules from 'components/AsianViewPageModule/components/CompetitionTitle/MarketRules';
import CashOut from 'components/CashOut';
import { FAVORITES_TYPES, SportIds } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  AsianViewMarketLinks
} from 'constants/asianView';
import { asianViewMiddleSection } from 'constants/branding';

import styles from './styles.module.scss';

interface CompetitionTitleProps {
  competitionId: string;
  name: string;
  isOpen: boolean;
  onCollapse: (isOpen: boolean) => void;
  date?: number;
  isHideFavorite?: boolean;
  isTab?: boolean;
  isCollapseEnabled?: boolean;
  cashOutEnabled?: boolean;
  showCashOut?: boolean;
}

const CompetitionTitleMobile = ({
  competitionId,
  name,
  isOpen,
  onCollapse,
  date,
  isHideFavorite = false,
  isCollapseEnabled = true,
  cashOutEnabled,
  showCashOut
}: CompetitionTitleProps) => {
  const { marketLink, sportId = SportIds.SOCCER } = useParams();
  const [searchParams] = useSearchParams();

  const isOutRight = marketLink === AsianViewMarketLinks.OUTRIGHT;
  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);

  return (
    <div
      className={classNames(
        'biab_asian-view-competition-title',
        styles.competitionMobile,
        asianViewMiddleSection.COUPON_BORDER,
        {
          [styles.competitionMobile__openTab]: isOpen
        }
      )}
    >
      <div
        className={classNames('biab_asian-view-competition-actions', styles.competitionMobile__actions, {
          [styles.competitionMobile__actionsTab]: isHideFavorite || !isCollapseEnabled
        })}
      >
        {isCollapseEnabled && (
          <i
            onClick={() => onCollapse(!isOpen)}
            className={classNames('fa2 biab_expand', styles.competitionMobile__expand, {
              'fa2-arrow-up': isOpen,
              'fa2-arrow-down': !isOpen
            })}
          />
        )}
        {!isHideFavorite && (
          <AsianViewFavouriteStar
            favourite={{
              entryName: name,
              entryId: competitionId,
              entryType: FAVORITES_TYPES.competition,
              sportId
            }}
          />
        )}
      </div>
      <div className={classNames('biab_asian-view-competition-name-wrapper', styles.competitionMobile__name__wrapper)}>
        <span title={name} className={classNames('biab_asian-view-competition-name', styles.competitionMobile__name)}>
          {name}
        </span>
        {date && <CollapseTime date={date} className={asianViewMiddleSection.OUTRIGHT_TIME} />}
      </div>

      {isOutRight && !isFavouriteView && (
        <div className={styles.competitionMobile__outRight}>
          {showCashOut && (
            <CashOut
              showLabel={false}
              showInfoIcon={false}
              className={{ icon: styles.cashoutIcon }}
              disabled={!cashOutEnabled}
            />
          )}
          <div className={styles.rulesIcon}>
            <MarketRules marketId={competitionId} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompetitionTitleMobile;
