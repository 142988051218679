import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { componentsBranding, header, mobileAccountPagesBranding, mobileComponents } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { getIsAmericanDateFormatEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { TPeriods } from 'types';
import { convertPeriodsToDateRanges } from 'utils';
import { addDays, getPeriod } from 'utils/date';

import DatePickerCustomHeader from '../DatePickerCustomHeader';

import styles from './styles.module.scss';

interface IDateRangePickerProps {
  setStartDate?: (date: Date) => void;
  setEndDate?: (date: Date) => void;
  setPeriod: (period: TPeriods) => void;
  setDateRange: (fromDate: Date, toDate: Date) => void;
  dayShift: number;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  calendarStartDay: number;
  dateFormat?: string | string[] | undefined;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  disabled?: boolean;
}

const DateRangePicker = ({
  setStartDate,
  setEndDate,
  setPeriod,
  setDateRange,
  dayShift,
  startDate,
  endDate,
  calendarStartDay,
  dateFormat,
  minDate,
  maxDate,
  disabled
}: IDateRangePickerProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const isLandscape = useSelector(getIsLandscapeAsianView);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);
  const [selectedStartDate, setSelectedDate] = useState<Date>(startDate ?? new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(endDate ?? new Date());
  const [isOpen, setIsOpen] = useState(false);
  const convertedData = convertPeriodsToDateRanges(t);

  const currentPeriod =
    selectedStartDate && selectedEndDate
      ? getPeriod(
          addDays(selectedStartDate, -1 * dayShift),
          addDays(selectedEndDate, -1 * dayShift),
          undefined,
          dayShift
        )
      : '';

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const activeElement = container.querySelector(`.${componentsBranding.SELECTED}`);
      if (activeElement) {
        activeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'nearest'
        });
      }
    }
  }, []);

  const handleChange = ([newStartDate, newEndDate]: [Date, Date | null]) => {
    setSelectedDate(newStartDate);
    setSelectedEndDate(newEndDate);

    if (newStartDate && newEndDate) {
      if (!isUndefined(setStartDate) && !isUndefined(setEndDate)) {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
      } else {
        setDateRange(newStartDate, newEndDate);
      }
    }
  };

  useEffect(() => {
    if (startDate) {
      setSelectedDate(startDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setSelectedEndDate(endDate);
    }
  }, [endDate]);

  useEffect(() => {
    if (minDate && minDate.getTime() > selectedStartDate?.getTime()) {
      setSelectedDate(minDate);
    }
  }, [minDate]);

  useEffect(() => {
    if (!isOpen && !selectedEndDate) {
      handleChange([selectedStartDate, selectedStartDate]);
    }
  }, [isOpen]);

  const formatPlaceholderDate = (date: Date | null | undefined) => {
    if (date) {
      if (americanDateFormatEnabled) {
        return `${t(`dates.shortMonth.${[date.getMonth()]}`)} ${date
          .getDate()
          .toString()
          .padStart(2, '0')}, ${date.getFullYear()}`;
      }
      return `${date.getDate().toString().padStart(2, '0')} ${t(
        `dates.shortMonth.${[date.getMonth()]}`
      )} ${date.getFullYear()}`;
    }
    return '';
  };

  const CustomInput = (
    <div>
      <div
        onClick={() => !disabled && setIsOpen(!isOpen)}
        className={classNames(styles.calendar__input, {
          [styles.calendar__input__active]: isOpen,
          [styles.calendar__input__disabled]: disabled
        })}
      >
        {formatPlaceholderDate(selectedStartDate)} - {formatPlaceholderDate(selectedEndDate)}
      </div>
    </div>
  );

  return (
    <div className={classNames({ [styles.wrapper__mobile]: isMobile }, componentsBranding.PERIOD_SELECTOR)}>
      {isMobile && (
        <>
          <div className={styles.title}>{t(`account.labels.dateRange`)}</div>
          <div className={styles.calendar__placeholder}>
            <div className={styles.calendar__placeholder__date}>{formatPlaceholderDate(selectedStartDate)}</div>
            <div>–</div>
            <div className={styles.calendar__placeholder__date}>{formatPlaceholderDate(selectedEndDate)}</div>
          </div>
          <div ref={containerRef} className={classNames(styles.periods)}>
            {convertedData.map((item, index) => (
              <button
                key={index}
                className={classNames(styles.periods__item, {
                  [header.SELECTED]: item.value === currentPeriod,
                  [styles.periods__item__active]: item.value === currentPeriod,
                  [mobileAccountPagesBranding.SELECTED_STATE]: item.value === currentPeriod
                })}
                onClick={() => setPeriod(item.value as TPeriods)}
              >
                {item.label}
              </button>
            ))}
          </div>
        </>
      )}
      {(!isMobile || isOpen) && (
        <DatePicker
          customInput={CustomInput}
          calendarStartDay={calendarStartDay}
          dateFormat={dateFormat}
          disabled={disabled}
          selected={selectedStartDate}
          onChange={handleChange}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          openToDate={selectedEndDate ?? maxDate}
          shouldCloseOnSelect={false}
          {...(minDate ? { minDate: minDate } : {})}
          {...(isMobile ? { inline: true } : {})}
          maxDate={maxDate}
          selectsRange
          open={isOpen}
          onInputClick={() => {}}
          monthsShown={isMobile && !isLandscape ? 1 : 2}
          showPreviousMonths
          showPopperArrow={false}
          calendarClassName={classNames('date-range-picker', {
            [styles.calendar__mobile]: isMobile,
            [styles.calendar]: !isMobile
          })}
          onCalendarClose={() => setIsOpen(false)}
          onCalendarOpen={() => setIsOpen(true)}
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <DatePickerCustomHeader
              date={monthDate}
              monthsShown={isMobile && !isLandscape ? 1 : 2}
              customHeaderCount={customHeaderCount}
              increaseMonth={increaseMonth}
              decreaseMonth={decreaseMonth}
              prevMonthButtonDisabled={prevMonthButtonDisabled}
              nextMonthButtonDisabled={nextMonthButtonDisabled}
              minDate={minDate}
              maxDate={maxDate}
              isMobile={isMobile}
            />
          )}
          onKeyDown={e => {
            e.preventDefault();
          }}
        >
          {!isMobile && (
            <div className={classNames(styles.panel, styles.panel__left, componentsBranding.PERIOD_SELECTOR)}>
              {convertedData.map((item, index) => (
                <button
                  key={index}
                  className={classNames(styles.panel__item, {
                    [componentsBranding.SELECTED]: item.value === currentPeriod,
                    [styles.panel__item__active]: item.value === currentPeriod
                  })}
                  onClick={() => setPeriod(item.value as TPeriods)}
                >
                  {item.label}
                </button>
              ))}
            </div>
          )}
        </DatePicker>
      )}
      {isMobile && (
        <div
          className={classNames(styles.toggle, mobileComponents.BUTTON, mobileComponents.TEXT)}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <i
            className={classNames('av-icon', styles.toggle__icon, {
              'av-icon-arrows-down': !isOpen,
              'av-icon-arrows-up': isOpen
            })}
          />
          {t(`account.labels.${isOpen ? 'hide' : 'show'}Calendar`)}
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
