import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices, OPERATOR } from 'constants/app';
import { asianViewNavigation as branding } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { getAppDevice, getOperator, getWindowHeight, getWindowWidth } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setCollapseNewState, setCollapseState } from 'redux/modules/collapse';
import { getCollapse } from 'redux/modules/collapse/selectors';
import { getFavorites } from 'redux/modules/favorites/selectors';
import { ElementName } from 'types';
import { getArrowDownByOperator } from 'utils/operator';

import styles from './styles.module.scss';

interface ICollapse {
  title?: string | ReactNode;
  children: ReactNode;
  group: string;
  collapse: string;
  testId?: string;
  customClass?: string;
  isMore?: boolean;
  childHeightKey?: ElementName | null;
  defaultState?: boolean;
  withoutMargin?: boolean;
  withoutHidden?: boolean;
  componentRef?: MutableRefObject<HTMLDivElement | null>;
  withoutCookie?: boolean;
}

const Collapse = ({
  title = '',
  children,
  group,
  collapse,
  testId = '',
  customClass = '',
  defaultState,
  isMore,
  childHeightKey = null,
  withoutMargin = false,
  withoutHidden = false,
  componentRef,
  withoutCookie = false
}: ICollapse) => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([`COLLAPSE-${group}-${collapse}`]);
  const { isAsianViewPage } = useDevice();

  const collapseStore = useSelector(getCollapse)[group][collapse];
  const device = useSelector(getAppDevice);
  const operator = useSelector(getOperator);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const favorites = useSelector(getFavorites);
  const windowWidth = useSelector(getWindowWidth);
  const windowHeight = useSelector(getWindowHeight);

  const [isExpanded, setIsExpanded] = useState(false);

  const isDesktop = device === Devices.DESKTOP;
  const isPokerStar = operator === OPERATOR.POKER_STAR;
  const cookieOpenedState = cookies[`COLLAPSE-${group}-${collapse}`];

  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (childHeightKey) {
      dispatch(setElementHeight({ name: childHeightKey, height: childRef?.current?.clientHeight || 0 }));
    }
  }, [windowWidth, windowHeight, childRef?.current?.clientHeight, isLoggedIn, favorites, childHeightKey, dispatch]);

  const changeCollapseState = (state: boolean, isChange?: boolean) => {
    const currentCollapse = { group, collapse, openedState: state };

    if (childHeightKey) {
      dispatch(setElementHeight({ name: childHeightKey, height: childRef?.current?.clientHeight || 0 }));
    }

    if (isChange) {
      dispatch(setCollapseNewState(currentCollapse));
    } else {
      dispatch(setCollapseState(currentCollapse));
    }

    if (!collapseStore?.withoutCookie && !withoutCookie) {
      setCookie(`COLLAPSE-${group}-${collapse}`, state);
    }

    setIsExpanded(state);
  };

  useEffect(() => {
    setIsExpanded(collapseStore?.openedState);
  }, [collapseStore?.openedState]);

  useEffect(() => {
    const defaultValue = defaultState || collapseStore?.openedState;

    changeCollapseState(!withoutCookie && cookieOpenedState ? cookieOpenedState === 'true' : defaultValue);
  }, [cookieOpenedState, isLoggedIn]);

  const onChangeCollapseState = () => {
    changeCollapseState(!isExpanded, true);
  };

  return (
    <>
      <div
        ref={componentRef}
        id={testId}
        onClick={onChangeCollapseState}
        className={classNames(
          styles.collapse,
          'biab_max-nav-item',
          'biab_btn-selected-sports',
          'biab_hidden-xs',
          'js-sports-available-content',
          {
            [`biab_opened ${styles.open}`]: isExpanded,
            'biab_sports-available-title': isDesktop && !isMore && !isAsianViewPage,
            [styles.noMargin]: withoutMargin,
            [branding.NAVIGATION_HEADER]: isAsianViewPage
          },
          customClass
        )}
      >
        {title}
        <i
          className={classNames(styles.collapse__icon, getArrowDownByOperator(operator), {
            biab_expand: isDesktop,
            [styles.collapse__icon__desktopDefault]: isDesktop && !isPokerStar,
            [styles.collapse__icon__desktopPS]: isDesktop && isPokerStar,
            [styles.collapse__icon__ps]: isPokerStar,
            [styles.collapse__icon__default]: !isPokerStar
          })}
        />
      </div>
      <div
        ref={childRef}
        className={classNames({
          hidden: !withoutHidden && !isExpanded
        })}
      >
        {children}
      </div>
    </>
  );
};

export default Collapse;
