import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices } from 'constants/app';
import { getAppDevice, getBetfairCdnImageFeedPath, getIsLandscape } from 'redux/modules/appConfigs/selectors';
import { ITimeForm123 } from 'types/markets';

import RatingStars from '../RatingStars';

import styles from './styles.module.scss';

interface TimeForm123Props {
  /**
   * TimeForm123 data
   */
  timeform123: ITimeForm123[];
}

const TimeForm123 = ({ timeform123 }: TimeForm123Props) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const betfairCdnImageFeedPath = useSelector(getBetfairCdnImageFeedPath);
  const isLandscape = useSelector(getIsLandscape);

  const isFullView = device === Devices.DESKTOP || isLandscape;

  const onHandleBrokenImage = (event: any) => {
    if (event) {
      event.nativeEvent.target.style.display = 'none';
    }
  };

  return (
    <div
      className={classNames('biab_timeform-content-right timeform-123', styles.timeForm123, {
        [styles.timeForm123__fullView]: isFullView
      })}
    >
      <span className={classNames('biab_tf-header', styles.timeForm123__header)}>{t('timeform.timefrom123')}</span>
      <ul className={styles.timeForm123__list}>
        {timeform123.map(
          ({ selectionId, runnerName, silkFileName, stars }) =>
            runnerName && (
              <li className={classNames('biab_timeform-runner', styles.timeForm123__ratingWrapper)} key={selectionId}>
                <span className={classNames('biab_timeform-runner-silk')}>
                  {silkFileName && (
                    <img
                      src={`${betfairCdnImageFeedPath}${silkFileName}`}
                      onError={onHandleBrokenImage}
                      alt={runnerName}
                    />
                  )}
                </span>
                <span className={classNames('biab_timeform-runner-rating', styles.timeForm123__rating)}>
                  <span className={styles.timeForm123__rating__runnerName}>{runnerName}</span>
                  <RatingStars stars={stars} />
                </span>
              </li>
            )
        )}
      </ul>
    </div>
  );
};

export default TimeForm123;
