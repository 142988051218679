import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

import { AsianViewSelectedBetStates } from 'constants/asianView';
import { AppState } from 'redux/reducers';

import { AsianViewMobileBetActions } from './type';

const getAVMobilePlacedBets = ({ asianViewMobileBetslip }: AppState) => asianViewMobileBetslip.placedBets;

export const getIsAsianMobileSelectedBet = (placeholder: string) => (state: AppState) =>
  state.asianViewMobileBetslip.selectedBets.hasOwnProperty(placeholder);

export const getAsianMobileSelectedBet = (placeholder: string) => (state: AppState) =>
  state.asianViewMobileBetslip.selectedBets[placeholder];

export const getIsAsianMobileSelectedBetByCell =
  ({ betslipId = '', identifier }: { betslipId: string | undefined; identifier: string }) =>
  (state: AppState) => {
    return (
      state.asianViewMobileBetslip.selectedBets[betslipId]?.identifier === identifier &&
      state.asianViewMobileBetslip.selectedBets[betslipId].state === AsianViewSelectedBetStates.selected
    );
  };

export const getAsianMobilePlacedBets = createSelector(getAVMobilePlacedBets, placedBets => {
  return sortBy(Object.values(placedBets), 'order').reverse();
});

export const getAsianMobilePlacedBetsAmount = (state: AppState) =>
  Object.values(state.asianViewMobileBetslip.placedBets).length;

export const getIsAsianMobileSelectedBetHidden = (placeholder: string | undefined) => (state: AppState) =>
  placeholder && state.asianViewMobileBetslip.selectedBets[placeholder]?.action === AsianViewMobileBetActions.HIDDEN;

export const getMobileRGErrorMessage = ({ asianViewMobileBetslip }: AppState) => asianViewMobileBetslip.rgErrorMessage;
export const getPlacedBetIdentifierToRemove =
  (identifier: string | number) =>
  ({ asianViewMobileBetslip }: AppState) =>
    asianViewMobileBetslip.placedBetsIdentifiersToRemove[identifier];
