import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import {
  failFetchArticles,
  failFetchSearchArticles,
  fetchArticles,
  fetchSearchArticles,
  successFetchArticles,
  successFetchSearchArticles
} from 'redux/modules/educationalPortal';
import { IArticle, ISearchArticle } from 'types/educationalPortal';

function* fetchArticlesWorker(action: ReturnType<typeof fetchArticles>) {
  try {
    const type = action.payload;
    const response: IArticle[] = yield call(api.education.getArticles, type);

    yield put(successFetchArticles(response));
  } catch (error: unknown) {
    yield put(failFetchArticles(error as Error));
  }
}

function* fetchSearchArticlesWorker(action: ReturnType<typeof fetchSearchArticles>) {
  try {
    const { query } = action.payload;
    const response: ISearchArticle[] = yield call(api.education.searchArticles, query);

    yield put(successFetchSearchArticles(response));
  } catch (error: unknown) {
    yield put(failFetchSearchArticles(error as Error));
  }
}

export default function* educationSaga() {
  yield all([
    takeEvery(fetchArticles.type, fetchArticlesWorker),
    takeLatest(fetchSearchArticles.type, fetchSearchArticlesWorker)
  ]);
}
