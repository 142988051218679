import { PageBlock } from 'types';

import { PageBlocks } from './app';

export const INLINE_PLACEMENT_PROGRESS_INTERVAL = 1000;

export const INLINE_PLACEMENT_AVAILABLE: PageBlock[] = [
  PageBlocks.HOME,
  PageBlocks.IN_PLAY,
  PageBlocks.POPULAR,
  PageBlocks.FEATURE_MARKET,
  PageBlocks.EVENT,
  PageBlocks.SPORT,
  PageBlocks.SEARCH,
  PageBlocks.MULTI_MARKET_VIEW,
  PageBlocks.MULTI_MARKET_VIEW_TOP_MARKET,
  PageBlocks.TOP_5_VIEW,
  PageBlocks.TOP_5_VIEW_TOP_MARKET,
  PageBlocks.CASH_OUT,
  PageBlocks.MARKET_ODDS,
  PageBlocks.FANCY_VIEW,
  PageBlocks.FANCY_TOP_VIEW
];

export const INLINE_PLACEMENT_TWO_COLUMNS_VIEW: PageBlock[] = [
  PageBlocks.CASH_OUT,
  PageBlocks.EVENT,
  PageBlocks.FEATURE_MARKET,
  PageBlocks.SEARCH,
  PageBlocks.FANCY_VIEW
];

export const MOBILE_PLACEMENT_SUCCESS_NOTIFICATION_REMOVE_TIME_OUT = 5000;
export const MOBILE_PLACEMENT_ERROR_NOTIFICATION_REMOVE_TIME_OUT = 10000;

export const MobilePlacementTypes = {
  Place: 'Place',
  Edit: 'Edit',
  Cancel: 'Cancel',
  MatchedBySystem: 'MatchedBySystem'
} as const;

export const INLINE_PLACEMENT_PROCESS_MESSAGE_TIMEOUT = 10000;
export const INLINE_PLACEMENT_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT = 300000;

export const INLINE_PLACEMENT_POPULAR_FULL_WIDTH = 1366;

export const InlinePlacementStatuses = {
  NEW: 'NEW',
  EDIT: 'EDIT',
  PLACED: 'PLACED',
  CONFIRM: 'CONFIRM',
  CONFIRMED: 'CONFIRMED',
  CONFIRMED_ENTER: 'CONFIRMED_ENTER',
  HIDDEN: 'HIDDEN',
  TIMEOUT_HIDDEN: 'TIMEOUT_HIDDEN',
  PROGRESS: 'PROGRESS',
  PLACED_EDIT: 'PLACED_EDIT'
} as const;

export const InlinePlacementActions = {
  SELECT: 'SELECT',
  CONFIRM: 'CONFIRM',
  PLACE: 'PLACE',
  PLACED: 'PLACED',
  EDIT: 'EDIT',
  CANCEL: 'CANCEL',
  TAKE: 'TAKE',
  UPDATE: 'UPDATE',
  PROGRESS: 'PROGRESS',
  LONG_PROGRESS: 'LONG_PROGRESS',
  CANCELLED: 'CANCELLED',
  REMOVED: 'REMOVED'
} as const;

export const InlinePlacementButtonActionTypes = {
  PLACE: 'PLACE',
  CONFIRM: 'CONFIRM',
  EDIT: 'EDIT',
  UPDATE: 'UPDATE',
  TAKE_OFFER: 'TAKE_OFFER',
  CANCEL: 'CANCEL',
  EDIT_UNMATCHED: 'EDIT_UNMATCHED',
  CANCEL_UNMATCHED: 'CANCEL_UNMATCHED',
  CANCEL_EDIT: 'CANCEL_EDIT'
} as const;
