import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MOBILE_MAX_DISPLAY_SIZE, SLICES_NAMES } from 'constants/app';
import { EDUCATIONAL_PORTAL_BASE_URL } from 'constants/locations';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import { EducationSliceState, TFetchSearchPayload } from 'redux/modules/educationalPortal/type';
import { ArticleType, IArticle, ISearchArticle } from 'types/educationalPortal';

const getInitIsSearchActive = () => {
  const isEducationPortal = window.location.pathname.includes(EDUCATIONAL_PORTAL_BASE_URL);
  const isMobile = window.innerWidth < MOBILE_MAX_DISPLAY_SIZE;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const searchValue = urlSearchParams.get(PARAMS_SEARCH_KEY);

  return isEducationPortal && isMobile && !!searchValue;
};

const initialState: EducationSliceState = {
  articles: null,
  loading: false,
  error: null,
  query: '',
  searchError: null,
  isSearchLoading: false,
  searchResults: [],
  isSearchActive: getInitIsSearchActive()
};

const slice = createSlice({
  name: SLICES_NAMES.EDUCATION,
  initialState,
  reducers: {
    fetchArticles: (state, _: PayloadAction<ArticleType | undefined>) => {
      state.loading = true;
    },
    successFetchArticles: (state, { payload }: PayloadAction<IArticle[]>) => {
      state.loading = false;
      state.articles = payload;
    },
    failFetchArticles: (state, { payload }: PayloadAction<Error>) => {
      state.loading = false;
      state.error = payload;
    },
    fetchSearchArticles: (state, _: PayloadAction<TFetchSearchPayload>) => {
      state.isSearchLoading = true;
      state.searchResults = [];
    },
    successFetchSearchArticles: (state, { payload }: PayloadAction<ISearchArticle[]>) => {
      state.isSearchLoading = false;
      state.searchResults = payload;
    },
    failFetchSearchArticles: (state, { payload }: PayloadAction<Error>) => {
      state.isSearchLoading = false;
      state.searchError = payload;
    },
    setIsSearchActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearchActive = payload;
    },
    resetSearchArticlesResults: state => {
      state.searchResults = [];
    },
    setSearchLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearchLoading = payload;
    }
  }
});

export const {
  fetchArticles,
  successFetchArticles,
  failFetchArticles,
  fetchSearchArticles,
  successFetchSearchArticles,
  failFetchSearchArticles,
  setIsSearchActive,
  resetSearchArticlesResults,
  setSearchLoading
} = slice.actions;
const educationReducer = slice.reducer;

export default educationReducer;
