import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { MarketStatuses } from 'constants/app';
import useLineMarket from 'hooks/useLineMarket';
import { getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { IMarket } from 'types/markets';

import styles from './styles.module.scss';

interface MarketLineRangeInfoRowProps {
  /**
   * Market
   */
  market: IMarket;
}

const MarketLineRangeInfoRow = ({ market }: MarketLineRangeInfoRowProps) => {
  const { marketId = '' } = useParams();

  const status = useSelector(getStatusByMarketPricesId(marketId));

  const { lineRangeInfo, lineRangeTooltip } = useLineMarket(market.description);

  return (
    <>
      {status !== MarketStatuses.CLOSED && (
        <div className={classNames('biab_line-range-info', styles.marketLineRangeInfo)}>
          <span data-tooltip-id={'tooltip'} data-tooltip-html={unescape(lineRangeTooltip)} className={'cursor-help'}>
            {lineRangeInfo}
          </span>
        </div>
      )}
    </>
  );
};

export default MarketLineRangeInfoRow;
