import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { CookieNames } from 'constants/app';
import { getEndDate, getStartDate } from 'redux/modules/myBets/selectors';
import { getTodayDate, getTodayStartDate } from 'utils/date';

const useAccountPageDates = () => {
  const [cookies] = useCookies([CookieNames.ACCOUNT_DATEFROM, CookieNames.ACCOUNT_DATETO]);

  const accountStartDate = useSelector(getStartDate);
  const accountEndDate = useSelector(getEndDate);
  const startDateCookie = cookies.BIAB_ACCOUNT_DATEFROM ? new Date(cookies.BIAB_ACCOUNT_DATEFROM) : null;
  const endDateCookie = cookies.BIAB_ACCOUNT_DATETO ? new Date(cookies.BIAB_ACCOUNT_DATETO) : null;
  const startDate = accountStartDate || startDateCookie || getTodayStartDate();
  const endDate = accountEndDate || endDateCookie || getTodayDate();

  return { startDate, endDate };
};

export default useAccountPageDates;
