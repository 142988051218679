import { TFunction } from 'i18next';

import { BETTING_TYPES, SportIds } from 'constants/app';
import {
  BetSides,
  lineAvgPriceFlatValue,
  lineBettingType,
  MyBetsPages,
  StatementDataStatuses,
  StatementOriginNoteTypes,
  StatementOriginTypes
} from 'constants/myBets';
import { StatementDataStatus, TMyBetsContentItem, TStatementContentItem } from 'redux/modules/myBets/type';
import { MyBetsPage, OfferStates, TOfferState } from 'types/myBets';

export const alternativeBackOddsValue = (bet: TMyBetsContentItem, { isRounded } = { isRounded: true }) => {
  if (bet.bettingType === lineBettingType) {
    return lineAvgPriceFlatValue;
  }

  if (bet.side === BetSides.Back) {
    if (bet.averagePrice) {
      return isRounded ? bet.averagePriceRounded : bet.averagePrice;
    } else {
      return bet.price || '--';
    }
  }

  return bet.alternativeBackOddsRounded || '--';
};

export const alternativeLayOddsValue = (bet: TMyBetsContentItem, { isRounded } = { isRounded: true }) => {
  if (bet.side === BetSides.Back) {
    return '--';
  } else {
    if (bet.bettingType === lineBettingType) {
      return lineAvgPriceFlatValue;
    } else {
      if (bet.averagePrice) {
        return isRounded ? bet.averagePriceRounded : bet.averagePrice;
      } else {
        return bet.price || '--';
      }
    }
  }
};

export const oddsValue = (bet: TMyBetsContentItem, { isRounded } = { isRounded: true }) => {
  if (bet.bettingType === lineBettingType) {
    return lineAvgPriceFlatValue;
  } else if (bet.averagePrice) {
    return isRounded ? bet.averagePriceRounded : bet.averagePrice;
  } else {
    return bet.price;
  }
};

export const shouldSwapColors = (item: TMyBetsContentItem, swapColorsFancyMarketsOnCricket: boolean) => {
  const isCricket = item.eventTypeId.toString() === SportIds.CRICKET;
  return swapColorsFancyMarketsOnCricket && item.bettingType === lineBettingType && (isCricket || item.fancyView);
};

export const getRaceStartTime = (date: number) =>
  `${new Date(date).getHours()}:${
    new Date(date).getMinutes() < 10 ? `0${new Date(date).getMinutes()}` : `${new Date(date).getMinutes()}`
  }`;

export const commission = (chargesCommission: string[]) => {
  if (chargesCommission.length === 0) {
    return '';
  }
  return chargesCommission.length === 1
    ? parseFloat(chargesCommission[0])
    : ` ${parseFloat(chargesCommission[0])}% - ${parseFloat(chargesCommission[1])}%`;
};

export const getBetSideLabel = (item: TMyBetsContentItem | TStatementContentItem, extendedLabel?: boolean) => {
  if (item.side === BetSides.Lay && item.disabledLayOdds && item.triggeredByCashOut) {
    return 'account.mybets.labels.cashedOut';
  }

  const sideLabel = item.side.toLowerCase();
  return extendedLabel ? `betslip.labels.type.${sideLabel}` : `account.mybets.labels.${sideLabel}`;
};

export const betsSideValue = ({
  item,
  lineMarketsSwitchBackLayOnCricket,
  replaceBackLayWithUnderOver,
  extendedLabel
}: {
  item: TMyBetsContentItem | TStatementContentItem;
  lineMarketsSwitchBackLayOnCricket: boolean;
  replaceBackLayWithUnderOver: boolean;
  extendedLabel?: boolean;
  alternativeLayOdds?: boolean;
}) => {
  const isCricket = item?.eventTypeId.toString() === SportIds.CRICKET;
  if (item.bettingType === lineBettingType) {
    if (isCricket && lineMarketsSwitchBackLayOnCricket) {
      return `account.mybets.labels.${item.side === BetSides.Back ? 'no' : 'yes'}`;
    }
    if (!isCricket && replaceBackLayWithUnderOver) {
      return `account.mybets.labels.${item.side === BetSides.Back ? 'under' : 'over'}`;
    }
  }

  return getBetSideLabel(item, extendedLabel);
};

export const getMultiCurrencyCode = (userCurrencyCode: string, isMultiCurrencySupported: boolean, page: MyBetsPage) => {
  if (isMultiCurrencySupported) {
    switch (page) {
      case MyBetsPages.ExchangeBets:
        return undefined;
      case MyBetsPages.ProfitLoss:
      case MyBetsPages.AccountStatement:
        return userCurrencyCode;
    }
  }

  return undefined;
};

export const getMultiCurrencyCodeFromArg = (
  multiCurrencySupported: boolean,
  userHasMultiCurrency: boolean,
  currentCurrency: string,
  multiCurrencyValue: string | null,
  currencyArg?: string
) => {
  if (currencyArg) {
    return currencyArg;
  }
  if (multiCurrencySupported) {
    if (userHasMultiCurrency) {
      return multiCurrencyValue || undefined;
    }
    return currentCurrency;
  }
  return undefined;
};

export const roundedPriceLineBet = ({ price, avgPriceMatched }: { price: number; avgPriceMatched: number }) =>
  avgPriceMatched || price;

export const getStatusTranslationKey = (offerState: TOfferState, isPNCEnabled = false, isAsian = false) => {
  if (offerState === OfferStates.WON || offerState === OfferStates.LOST) {
    return `account.mybets.labels.${offerState.toLowerCase()}`;
  }

  if (offerState === OfferStates.PLACED) {
    if (isPNCEnabled) {
      return 'pnc.account.mybets.labels.types.matched';
    }

    return 'account.mybets.labels.types.unmatched';
  }

  if (isAsian && offerState === OfferStates.MATCHED) {
    return 'account.mybets.labels.placed';
  }

  if (isPNCEnabled && offerState === OfferStates.MATCHED) {
    return 'pnc.account.mybets.labels.types.matched';
  }

  return `account.mybets.labels.types.${offerState.toLowerCase()}`;
};

export const getOriginLabel = ({ data, t }: { data: TStatementContentItem; t: TFunction }) => {
  if (data.status === StatementDataStatuses.TRANSACTION && !data.depositWithdrawal) {
    if (
      data.originNoteType === StatementOriginNoteTypes.CORRECTION ||
      data.originNoteType === StatementOriginNoteTypes.RESETTLEMENT
    ) {
      return `${data.originNoteTypeLabel}${data.deadHeated ? ` - ${t('account.statement.labels.deadheated')}` : ''}`;
    }

    return null;
  }

  let originTypeLabel = '';
  let originTypeNoteLabel = '';

  if (data.originType === StatementOriginTypes.RESETTLEMENT || data.originType === StatementOriginTypes.CORRECTION) {
    originTypeLabel = data.originTypeLabel;
  }

  if (data.originNoteType === StatementOriginNoteTypes.STAKE_REFUND && data.originNoteTypeLabel) {
    originTypeNoteLabel = data.originNoteTypeLabel;
  }

  if ((originTypeLabel || originTypeNoteLabel || data.deadHeated) && data.betType === 'EXCHANGE') {
    return `${originTypeLabel}${originTypeLabel && originTypeNoteLabel ? ' - ' : ''}${originTypeNoteLabel}${
      data.deadHeated
        ? `${originTypeLabel || originTypeNoteLabel ? ' - ' : ''}${t('account.statement.labels.deadheated')}`
        : ''
    }`;
  }

  return null;
};

export const getSelectionName = ({ data, t }: { data: TStatementContentItem; t: TFunction }) => {
  if (data.status === StatementDataStatuses.TRANSACTION) {
    if (data.depositWithdrawal) {
      return data.description;
    }

    return t('accountStatement.commission');
  }

  return `${data.selectionName}${data.bettingType === BETTING_TYPES.line ? ` ${Number(data.avgPriceMatched)}` : ''}`;
};

export const getAccountStatementIndicators = ({
  data,
  swapColorsFancyMarketsOnCricket
}: {
  data: TStatementContentItem;
  swapColorsFancyMarketsOnCricket: boolean;
}) => {
  const isCricket = data.eventTypeId.toString() === SportIds.CRICKET;
  const areColorsSwapped =
    swapColorsFancyMarketsOnCricket && data.bettingType === lineBettingType && (isCricket || data.fancyMarket);
  const isBackIndicator =
    data.status !== StatementDataStatuses.TRANSACTION &&
    (areColorsSwapped ? data.side === BetSides.Lay : data.side === BetSides.Back);
  const isLayIndicator =
    data.status !== StatementDataStatuses.TRANSACTION &&
    (areColorsSwapped ? data.side === BetSides.Back : data.side === BetSides.Lay);

  return { isBackIndicator, isLayIndicator, isCricket, areColorsSwapped };
};

export const getAccountStatementStatusLabel = ({ status, t }: { status: StatementDataStatus | null; t: TFunction }) => {
  if (status === StatementDataStatuses.VOIDED) {
    return t('account.mybets.labels.types.voided');
  } else if (status === null) {
    return t('account.statement.labels.undefined');
  }

  return t(`account.statement.labels.${status.toLowerCase()}`);
};
