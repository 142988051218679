export const timeSections = ['inPlay', 'comingUp'];

export const SportPageContextFilters = {
  COUNTRY: 'COUNTRY',
  COMPETITION: 'COMPETITION',
  EVENT_TYPE: 'EVENT_TYPE'
} as const;

export const SportPageTypes = {
  SPORT: 'SPORT',
  COUNTRY: 'COUNTRY',
  COMPETITION: 'COMPETITION',
  EVENT_TYPE: 'EVENT_TYPE',
  EVENT: 'EVENT'
} as const;
