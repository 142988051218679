import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ResponsibleBettingBtn from 'components/ResponsibleBetting/components/ResponsibleBettingBtn';
import ResponsibleBettingDropdown from 'components/ResponsibleBetting/components/ResponsibleBettingDropdown';
import ResponsibleBettingMobileDropdown from 'components/ResponsibleBetting/components/ResponsibleBettingMobileDropdown';
import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import { ResponsibleBettingButtonTypes, ResponsibleBettingMobileDropdownTypes } from 'constants/responsibleBetting';
import useDevice from 'hooks/useDevice';
import { DropdownItem } from 'types';
import { ResponsibleBettingSelfExclusionPeriod } from 'types/responsibleBetting';

import styles from './style.module.scss';

interface ResponsibleBettingCreateSelfExclusionFormProps {
  onCancel: () => void;
  onContinue: () => void;
  selfExclusionPeriods: DropdownItem[];
  onSelectOption: (option: DropdownItem) => void;
  selectedTimeUnit: ResponsibleBettingSelfExclusionPeriod | null;
}

const ResponsibleBettingCreateSelfExclusionForm = ({
  onCancel,
  onContinue,
  onSelectOption,
  selfExclusionPeriods,
  selectedTimeUnit
}: ResponsibleBettingCreateSelfExclusionFormProps) => {
  const { isMobile } = useDevice();
  const { t } = useTranslation();

  return (
    <div
      className={classNames(commonStyles.content, {
        [commonStyles.content__mobile]: isMobile
      })}
    >
      <div
        className={classNames(commonStyles.inputs, {
          [commonStyles.inputs__mobile]: isMobile
        })}
      >
        {isMobile ? (
          <ResponsibleBettingMobileDropdown
            data={selfExclusionPeriods}
            onSelectOption={onSelectOption}
            selectedValue={selectedTimeUnit}
            label={t('responsibleBetting.labels.excludeMe')}
            type={ResponsibleBettingMobileDropdownTypes.Vertical}
            centered={false}
          />
        ) : (
          <div className={styles.form}>
            <span className={styles.formLabel}>{t('responsibleBetting.labels.excludeMe')}</span>
            <ResponsibleBettingDropdown
              onChangeOption={onSelectOption}
              data={selfExclusionPeriods}
              value={selectedTimeUnit}
            />
          </div>
        )}
      </div>

      <div className={commonStyles.divider} />

      <div
        className={classNames(styles.controllersContainer, {
          [styles.container__mobile]: isMobile
        })}
      >
        <div
          className={classNames(styles.controllers, {
            [styles.controllers__mobile]: isMobile
          })}
        >
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.cancel')}
            onClick={onCancel}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Secondary}
          />
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.continue')}
            onClick={onContinue}
            isMobile={isMobile}
            disabled={!selectedTimeUnit}
            type={ResponsibleBettingButtonTypes.Primary}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponsibleBettingCreateSelfExclusionForm;
