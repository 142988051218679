import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useResizeObserver } from 'usehooks-ts';

import { OPERATOR, SportIds } from 'constants/app';
import { AsianViewMarketLinks, AsianViewTimeFilters } from 'constants/asianView';
import { DEFAULT } from 'constants/icons';
import { IconsConfig } from 'constants/iconsConfig';
import { ASIAN_BASE_URL, HOME_BASE_URL, IN_PLAY_BASE_URL } from 'constants/locations';
import { getDisplayIconsInSubHeader, getIsIframeEnabled, getOperator } from 'redux/modules/appConfigs/selectors';
import { NavigationType } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setElementWidth } from 'redux/modules/headerNavigation';
import { getDefaultLinkTarget, getIconName } from 'utils/navigation';

import NavigationLink from './components/NavigationLink';

import styles from './styles.module.scss';

type DesktopNavigationItemProps = {
  /**
   * Subheader navigation link for desktop view
   */
  navigationType: NavigationType;
  pathname?: string;
  isMoreDropdownItem?: boolean;
  customClassName?: string;
};

const DesktopNavigationItem = ({
  navigationType: {
    absolutePath,
    label,
    url,
    iconName,
    sportId,
    modifiable,
    openInNewTab,
    authUserVisible,
    noneAuthUserVisible,
    page,
    placeOnTheRight,
    orderNumber
  },
  pathname,
  isMoreDropdownItem = false,
  customClassName = ''
}: DesktopNavigationItemProps) => {
  const dispatch = useDispatch();
  const { marketLink } = useParams();
  const { search } = useLocation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const operator = useSelector(getOperator);
  const displayIconsInSubHeader = useSelector(getDisplayIconsInSubHeader);

  const linkRef = useRef<HTMLAnchorElement>(null);

  useResizeObserver({
    ref: linkRef,
    box: 'border-box',
    onResize: ({ width }) => {
      if (!isMoreDropdownItem && !placeOnTheRight) {
        dispatch(setElementWidth({ key: orderNumber, width: Math.ceil(width ?? 0) }));
      }
    }
  });

  const iconNameClassName = getIconName(
    {
      iconName,
      sportId,
      modifiable,
      url,
      page
    },
    operator
  );
  const isAsianViewPage = !!pathname?.includes(ASIAN_BASE_URL);
  const isHomePageUrl = url === HOME_BASE_URL;
  const isHomePage = label.toLowerCase() === 'home' || isHomePageUrl;
  const isInPlayUrl = url === IN_PLAY_BASE_URL;
  const isActiveAsianViewHomePageActive =
    isAsianViewPage && isHomePage && marketLink === AsianViewMarketLinks.HDP_AND_OU;
  const urlValue =
    isAsianViewPage && isHomePage
      ? `${ASIAN_BASE_URL}/sport/${SportIds.SOCCER}/timeFilter/${AsianViewTimeFilters.Today}/marketLink/${AsianViewMarketLinks.HDP_AND_OU}`
      : url;
  const displayContent = isLoggedIn ? authUserVisible : noneAuthUserVisible;
  const isActiveFullUrl = `${pathname}${search}`.includes(urlValue);

  return (
    <NavigationLink
      linkRef={linkRef}
      absolutePath={absolutePath}
      isActiveLink={isActiveAsianViewHomePageActive || (isInPlayUrl && !!pathname?.includes(url))}
      isActiveFullUrl={isActiveFullUrl}
      to={urlValue}
      target={getDefaultLinkTarget({
        url: urlValue,
        absolutePath: absolutePath,
        isIframeEnabled,
        currentTarget: openInNewTab ? '_blank' : '',
        openInNewTab
      })}
      title={label}
      customClassName={classNames(customClassName, { hidden: !displayContent })}
      isMoreDropdownItem={isMoreDropdownItem}
    >
      {displayContent && (
        <>
          {displayIconsInSubHeader && (
            <i
              className={classNames(
                'biab_navigation-submenu-icon',
                styles.headerNavigationItem__icon,
                iconNameClassName,
                {
                  [styles.cashOutIcon]:
                    IconsConfig.SUBHEADER_ICONS[DEFAULT]?.cashoutIcon === iconNameClassName &&
                    operator !== OPERATOR.ORBIT,
                  [styles.cashOutIconOrbit]:
                    IconsConfig.SUBHEADER_ICONS[DEFAULT]?.cashoutIcon === iconNameClassName &&
                    operator === OPERATOR.ORBIT
                }
              )}
            />
          )}
          <span className={styles.headerNavigationItem__label}>{label}</span>
          {isMoreDropdownItem && isActiveFullUrl && (
            <i className={classNames('av-icon av-icon-tick', styles.tickIcon)} />
          )}
        </>
      )}
    </NavigationLink>
  );
};

export default DesktopNavigationItem;
