import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ActionTypes, InputFieldTypes, KEY_CODES, PlaceBetsStates } from 'constants/betslip';
import { BetSides } from 'constants/myBets';
import { ODDS_REGEX_PATTERN } from 'constants/placement';
import { usePlaceBetWithEnterKey } from 'hooks/usePlaceBetWithEnterKey';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { removeBetSlipUnmatchedOfferError } from 'redux/modules/betslip';
import {
  getBetslipPlaceBetsState,
  getIsBetSlipUnmatchedOfferErrorById,
  getIsGameBetSlip
} from 'redux/modules/betslip/selectors';
import { TBetslipPriceChangeProps, TPriceValidation } from 'redux/modules/betslip/type';
import { TPrice } from 'types/bets';
import { ActionType, InputFieldType, TFormCustomClassNames } from 'types/betslip';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';
import { BetSide } from 'types/myBets';
import { formatFloatNumber, getLineRangeParams } from 'utils/betValidation';
import { recalculatePriceByStep, validatePrice } from 'utils/price';

import BFZeroPriceChangeIndicator from '../BFZeroPriceChangeIndicator/BFZeroPriceChangeIndicator';
import ButtonArrow from '../ButtonArrow';
import InputField from '../InputField';

import styles from './styles.module.scss';

type BetPriceProps = {
  betUuid?: string;
  price: TPrice;
  betType: BetSide;
  bettingType: string;
  marketType: string;
  bestPrice?: TPrice | null;
  lineRangeInfo: TMarketLineRangeInfo | null;
  priceLadderDescription: TPriceLadderDescription | null;
  onChangePrice: ({ changedPrice, isValid, errorMessage, focusedField }: TBetslipPriceChangeProps) => void;
  onEnterClick: () => void;
  onTabNavigate?: (fieldType: InputFieldType) => void | null;
  fieldOrder?: number;
  isError?: boolean;
  isFocus?: boolean;
  isDirty?: boolean;
  isUnmatchedOpenedBetTab: boolean;
  offerId?: number;
  showOddsChangedIndicators?: boolean;
  prevPrice?: TPrice;
  label?: string;
  isReadOnly?: boolean;
  isTakeOfferState?: boolean;
  isOddsRefreshEnabled?: boolean;
  customClassNames?: TFormCustomClassNames;
};

const BetPrice = ({
  betUuid,
  price,
  betType,
  bettingType,
  marketType,
  bestPrice,
  lineRangeInfo,
  priceLadderDescription,
  onChangePrice,
  onEnterClick,
  onTabNavigate,
  fieldOrder,
  isError,
  isFocus,
  isUnmatchedOpenedBetTab,
  offerId,
  showOddsChangedIndicators = false,
  prevPrice = 0,
  label,
  isReadOnly,
  isTakeOfferState,
  isOddsRefreshEnabled,
  customClassNames
}: BetPriceProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const placeBetsState = useSelector(getBetslipPlaceBetsState);
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const isUnmatchedOfferError = useSelector(
    getIsBetSlipUnmatchedOfferErrorById(isUnmatchedOpenedBetTab ? offerId : undefined)
  );

  const isPlaceBetWithEnterKey = usePlaceBetWithEnterKey();
  const inputRef = useRef<HTMLInputElement>(null);

  const { min: minValue, max: maxValue } = getLineRangeParams({ lineRangeInfo, bettingType });
  const isTakeOffer = isPNCEnabled && (placeBetsState === PlaceBetsStates.TAKE_OFFER || isTakeOfferState);
  const priceFieldOrder = !isPNCEnabled || isGameBetSlip ? fieldOrder : -1;
  const isUpArrowEnabled = +(price || 0) < maxValue;
  const isDownArrowEnabled = +(price || 0) > minValue;
  const isButtonArrowsEnabled = !isPNCEnabled || isGameBetSlip;
  const isBack = betType === BetSides.Back;
  const isPrevOddsLower = !!(prevPrice && +prevPrice < +(bestPrice || 0));
  const isGoingUp = isBack ? isPrevOddsLower : !isPrevOddsLower;

  useEffect(() => {
    if ((isTakeOffer || isOddsRefreshEnabled) && bestPrice) {
      onChangePriceHandler(bestPrice);
    }
  }, [bestPrice, isTakeOffer, isOddsRefreshEnabled, isReadOnly]);

  const onChangePriceHandler = (changedPrice: TPrice) => {
    const validation: TPriceValidation = validatePrice({
      price: changedPrice,
      betType,
      bettingType,
      marketType,
      lineRangeInfo,
      priceLadderDescription
    });

    onChangePrice({ changedPrice, isValid: validation.isValid });
  };

  // Change price and check if new price meets the new step and min/max values.
  const changePriceValue = (type: ActionType) => {
    const updatedPrice = recalculatePriceByStep({
      type,
      price,
      marketType,
      bettingType,
      lineRangeInfo,
      priceLadderDescription
    });

    onChangePriceHandler(formatFloatNumber(updatedPrice));

    if (isUnmatchedOpenedBetTab && offerId && isUnmatchedOfferError) {
      dispatch(removeBetSlipUnmatchedOfferError(offerId));
    }
  };

  const onBlurHandler = () => {
    const validation: TPriceValidation = validatePrice({
      price,
      betType,
      bettingType,
      marketType,
      lineRangeInfo,
      priceLadderDescription
    });

    onChangePrice({
      changedPrice: validation.validValue,
      isValid: !!validation.validValue,
      ...(validation.errorMessage
        ? { errorMessage: t(validation.errorMessage.text, validation.errorMessage.params ?? {}) }
        : {}),
      focusedField: null
    });
  };

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === KEY_CODES.ARROW_UP && document.activeElement === inputRef.current) {
        event.preventDefault();
        changePriceValue(ActionTypes.ADD);
      }
      if (event.key === KEY_CODES.ARROW_DOWN && document.activeElement === inputRef.current) {
        event.preventDefault();
        changePriceValue(ActionTypes.SUBSTR);
      }
    };
    if (isPlaceBetWithEnterKey) {
      window.addEventListener('keydown', onKeyPress);
    }

    return () => {
      if (isPlaceBetWithEnterKey) {
        window.removeEventListener('keydown', onKeyPress);
      }
    };
  }, [price, isPlaceBetWithEnterKey]);

  return (
    <div className={classNames(styles.bet, customClassNames?.priceWrap)}>
      {showOddsChangedIndicators && (
        <BFZeroPriceChangeIndicator
          isGoingUp={isGoingUp}
          price={price}
          customClassName={customClassNames?.pncIndicator}
        />
      )}
      <InputField
        betUuid={betUuid}
        value={price}
        inputPattern={ODDS_REGEX_PATTERN}
        onChange={onChangePriceHandler}
        onBlur={onBlurHandler}
        onEnterClick={onEnterClick}
        onTabNavigate={onTabNavigate}
        isError={isError}
        fieldType={InputFieldTypes.PRICE}
        fieldTabIndex={priceFieldOrder}
        isFocus={isFocus}
        isUnmatchedOpenedBetTab={isUnmatchedOpenedBetTab}
        offerId={offerId}
        label={label}
        customClassName={customClassNames?.price}
        isReadOnly={isReadOnly}
        fieldRef={inputRef}
      />
      {isButtonArrowsEnabled && (
        <>
          <ButtonArrow
            type={ActionTypes.ADD}
            onClickHandler={changePriceValue}
            isArrowEnabled={isUpArrowEnabled && !isReadOnly}
          />
          <ButtonArrow
            type={ActionTypes.SUBSTR}
            onClickHandler={changePriceValue}
            isArrowEnabled={isDownArrowEnabled && !isReadOnly}
          />
        </>
      )}
    </div>
  );
};

export default BetPrice;
