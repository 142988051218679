import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Collapse from 'components/Collapse';
import Content from 'components/SportsSection/Content';
import { Devices, ElementNames } from 'constants/app';
import useElementSize from 'hooks/useElementSize';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { LEFT_PANEL_COLLAPSE_GROUP, SPORT_COLLAPSE } from 'redux/modules/collapse/constants';

const SportsSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.NAVIGATION_ALL_SPORT_ITEM, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.NAVIGATION_ALL_SPORT_ITEM, height: 0 }));
    };
  }, []);

  return (
    <>
      {device === Devices.DESKTOP && (
        <Collapse
          testId="a-sportsSection"
          group={LEFT_PANEL_COLLAPSE_GROUP}
          collapse={SPORT_COLLAPSE}
          title={t('navigation.sportsAvailable')}
          withoutMargin
          componentRef={componentRef}
        >
          <Content />
        </Collapse>
      )}
      {device === Devices.MOBILE && <Content />}
    </>
  );
};

export default SportsSection;
