import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import GamesCardItem from 'components/GamesCardItem';
import { GamePages, GameSelectionStatuses, GameTypes } from 'constants/games';
import { MyBetsLocations } from 'constants/myBets';
import { getSelectedGamePage } from 'redux/modules/games/selectors';
import { GameType, TGameData } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface IPlayerCardsTable {
  gameData: TGameData;
  gameType: GameType;
  currentRound?: number;
  isDealer?: boolean;
  isBanker?: boolean;
}

const PlayerCardsTable = ({
  gameData,
  gameType,
  currentRound = 0,
  isDealer = false,
  isBanker = false
}: IPlayerCardsTable) => {
  const { t } = useTranslation();
  const selectedGamePage = useSelector(getSelectedGamePage);
  const location = useLocation();
  const isResultPage = selectedGamePage === GamePages.RESULTS || location.pathname.includes(MyBetsLocations.MyBets);

  const getStatus = () => {
    const statusKey = `games.statuses.${gameType.toLowerCase()}.${gameData.status}`;
    const status = t(statusKey);
    return status !== statusKey ? `-${status}-` : '';
  };

  return (
    <div
      className={classNames(styles.cardTableMain, {
        [styles.withShadow]: !isResultPage,
        [styles.baccarat]: gameType === GameTypes.BACCARAT
      })}
    >
      <div className={classNames('biab_games-player-name', styles.playerName)}>{gameData.name}</div>
      {!isDealer && gameData?.status !== GameSelectionStatuses.IN_PLAY && (
        <div className={classNames('biab_games-player-status', styles.playerStatus)}>{getStatus()}</div>
      )}
      <div className={classNames(styles.cards)}>
        <GamesCardItem
          properties={gameData.properties}
          type={gameType}
          currentRound={currentRound}
          isBanker={isBanker}
        />
      </div>
      <div className={classNames('biab_games-player-points', styles.points)}>{gameData.description}</div>
    </div>
  );
};

export default PlayerCardsTable;
