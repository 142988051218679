import { SyntheticEvent, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import BannersContainer from 'components/BannersContainer';
import Betslip from 'components/Betslip';
import PopularLinks from 'components/PopularLinks';
import PromotionBanners from 'components/PromotionBanners';
import { BannerNames, CookieNames, Devices, ElementNames } from 'constants/app';
import { BannerSections } from 'constants/banners';
import { betslipBranding } from 'constants/branding';
import { AB, POKER_STAR } from 'constants/icons';
import { GAMES_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import { getAppDevice, getLanguage, getOperator } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchBanner } from 'redux/modules/banners';
import { getIsDisplayedBannerByName } from 'redux/modules/banners/selectors';
import { closeDutchingCalculators, removeAllSelectedBets } from 'redux/modules/betslip';
import {
  getIsAtLeastOneOpenedBetSlipDutchingCalculator,
  getIsMarketsLimitNotification
} from 'redux/modules/betslip/selectors';
import { getIsUserInfoLoaded } from 'redux/modules/user/selectors';
import { getBooleanValue } from 'utils';

import CollapsedRightNavigation from './CollapsedRightNavigation';

import styles from './RightNavigation.module.scss';

const RightNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [cookies, setCookie] = useCookies([CookieNames.COLLAPSE_SIDEBAR]);

  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const operator = useSelector(getOperator);
  const headerHeight = useSelector(getElementHeightByName(ElementNames.HEADER_HEIGHT));
  const isBadgeBannerDisplayed = useSelector(getIsDisplayedBannerByName(BannerNames.badge));
  const isBadgeSkyscraperBannerDisplayed = useSelector(getIsDisplayedBannerByName(BannerNames.badgeSkyscraper));
  const isOpenedDutchingCalculator = useSelector(getIsAtLeastOneOpenedBetSlipDutchingCalculator);

  const isGamesPage = location.pathname.startsWith(GAMES_BASE_URL);
  const isPokerstars = operator === POKER_STAR;
  const isAdjaraBet = operator === AB;

  const isCollapsed = getBooleanValue(cookies[CookieNames.COLLAPSE_SIDEBAR]);
  const isAmountLimitReached = useSelector(getIsMarketsLimitNotification);
  const isUserInfoLoaded = useSelector(getIsUserInfoLoaded);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const onToggleSidebar = (event: SyntheticEvent) => {
    if (isCollapsed && !isAmountLimitReached) {
      event.preventDefault();
      event.stopPropagation();
      setCookie(CookieNames.COLLAPSE_SIDEBAR, false, { path: '/' });
    }
  };

  useEffect(() => {
    if (isUserInfoLoaded && !isLoggedIn) {
      dispatch(removeAllSelectedBets());
    }
  }, [isLoggedIn, isUserInfoLoaded]);

  useEffect(() => {
    if (language) {
      if (!isGamesPage) {
        dispatch(
          fetchBanner({
            name: BannerNames.badge,
            platform: Devices.DESKTOP,
            section: BannerSections.BADGE_BANNER,
            locale: language
          })
        );
        dispatch(
          fetchBanner({
            name: BannerNames.badgeSkyscraper,
            platform: Devices.DESKTOP,
            section: BannerSections.BADGE_BANNER_SKYSCRAPER,
            locale: language
          })
        );
      }
      dispatch(
        fetchBanner({
          name: BannerNames.promotion,
          platform: Devices.DESKTOP,
          section: BannerSections.PROMOTION_BANNER,
          locale: language
        })
      );
    }
  }, [language, device]);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.SIDEBAR_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.SIDEBAR_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div
      style={{ height: isPokerstars || isAdjaraBet ? 'none' : `calc(100vh - ${headerHeight}px)` }}
      className={classNames('biab_sidebar-container rightNavigation', styles.rightNav, {
        [styles.rightNav__collapsed]: isCollapsed,
        [betslipBranding.MIN_NAV]: isCollapsed
      })}
      onClick={onToggleSidebar}
      onScroll={isOpenedDutchingCalculator ? () => dispatch(closeDutchingCalculators()) : undefined}
    >
      <div ref={componentRef}>
        {isCollapsed && <CollapsedRightNavigation hoveredIconClass={styles.hoveredIconClass} />}
        <div className={classNames({ [styles.noBetslip]: isCollapsed })}>
          <Betslip />
        </div>
        {!isCollapsed && !isGamesPage && <PopularLinks />}
        {!isCollapsed && <PromotionBanners />}
        {!isCollapsed && !isGamesPage && (
          <>
            {isBadgeBannerDisplayed && <BannersContainer bannerType={BannerSections.BADGE_BANNER} />}
            {isBadgeSkyscraperBannerDisplayed && (
              <BannersContainer bannerType={BannerSections.BADGE_BANNER_SKYSCRAPER} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RightNavigation;
