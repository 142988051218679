import classNames from 'classnames';

import BetLabelsItemTpl from 'components/Betslip/components/BetLabels/components/BetLabelsItemTpl';
import { betslipBranding } from 'constants/branding';
import { BetSides } from 'constants/myBets';
import { BetSide } from 'types/myBets';

import styles from './styles.module.scss';

type BetLabelsTplProps = {
  betType: BetSide;
  /** Label if Win */
  labelW: string;
  labelS?: string;
  /** Label if Loss */
  labelL: string;
  /** Profit if Win */
  profitLossW?: number | null;
  profitLossS?: number | null;
  /** Liability if Loss */
  profitLossL?: number | null;
  /** Currency */
  currency?: string;
  isSwapColors?: boolean;
};

const BetLabelsTpl = ({
  labelW,
  labelS,
  labelL,
  profitLossW,
  profitLossS,
  profitLossL,
  betType,
  ...props
}: BetLabelsTplProps) => {
  const isBack = betType === BetSides.Back;

  return (
    <div className={classNames(styles.list, isBack ? styles.list__back : styles.list__lay, betslipBranding.WHAT_IF)}>
      <BetLabelsItemTpl label={labelW} profitLoss={profitLossW} betType={betType} {...props} />
      {labelS && <BetLabelsItemTpl label={labelS} profitLoss={profitLossS} betType={betType} {...props} />}
      <BetLabelsItemTpl label={labelL} profitLoss={profitLossL} betType={betType} {...props} />
    </div>
  );
};

export default BetLabelsTpl;
