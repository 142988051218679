import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import classNames from 'classnames';
import { unescape } from 'lodash';

import ResponsibleBettingAlert from 'components/ResponsibleBetting/components/ResponsibleBettingAlert';
import ResponsibleBettingBtn from 'components/ResponsibleBetting/components/ResponsibleBettingBtn';
import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import { componentsBranding, mobileComponents } from 'constants/branding';
import { ResponsibleBettingAlertTypes, ResponsibleBettingButtonTypes } from 'constants/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingReminderViewProps {
  isMobile: boolean;
  isUpdatingMode: boolean;
  error: string | null;
  selectedLimit: number | null;
  reminderPeriod: number | null;
  reminderEnabled: boolean;
  inputLabel: string;
  inputPlaceholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onApply: () => void;
  onUpdate: () => void;
  onRemove: () => void;
}

const ResponsibleBettingReminderView = ({
  isMobile,
  isUpdatingMode,
  error,
  selectedLimit,
  reminderPeriod,
  reminderEnabled,
  inputLabel,
  inputPlaceholder,
  onChange,
  onCancel,
  onApply,
  onUpdate,
  onRemove
}: ResponsibleBettingReminderViewProps) => {
  const { t } = useTranslation();

  const disabled = !selectedLimit || !!error || selectedLimit === reminderPeriod;

  return isUpdatingMode || !reminderEnabled ? (
    <div
      className={classNames(commonStyles.content, {
        [commonStyles.content__mobile]: isMobile
      })}
    >
      <div
        className={classNames(commonStyles.inputs, styles.reminderView__column, {
          [commonStyles.inputs__with_error]: !!error,
          [commonStyles.inputs__mobile]: isMobile
        })}
      >
        <div
          className={classNames(styles.reminderView__container, {
            [styles.reminderView__container__bottom__gap]: !!error
          })}
        >
          <div className={styles.reminderView__input__wrapper}>
            <span className={classNames(styles.reminderView__label__remindMe)}>{inputLabel}</span>
            <NumericFormat
              className={classNames(commonStyles.input, styles.reminderView__input, {
                [commonStyles.input__with_error]: error,
                [commonStyles.input__mobile]: isMobile,
                [styles.reminderView__input__mobile]: isMobile,
                [componentsBranding.FORM_INPUT]: !isMobile,
                [componentsBranding.ERROR]: error,
                [mobileComponents.INPUT]: isMobile
              })}
              placeholder={inputPlaceholder}
              value={reminderPeriod || selectedLimit}
              onChange={onChange}
              decimalScale={0}
              allowNegative={false}
            />
            <span className={classNames(styles.reminderView__label, styles.reminderView__label__minutes)}>
              {t('responsibleBetting.labels.sessionReminder.minute.plural')}
            </span>
          </div>
        </div>
        {error && (
          <ResponsibleBettingAlert type={ResponsibleBettingAlertTypes.Error}>
            <span dangerouslySetInnerHTML={{ __html: unescape(error) }} />
          </ResponsibleBettingAlert>
        )}
      </div>

      <div className={commonStyles.divider} />

      <div
        className={classNames(commonStyles.controllersContainer, {
          [commonStyles.container__mobile]: isMobile
        })}
      >
        <div
          className={classNames(commonStyles.controllers, {
            [commonStyles.controllers__mobile]: isMobile
          })}
        >
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.cancel')}
            onClick={onCancel}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Secondary}
          />
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.apply')}
            onClick={onApply}
            isMobile={isMobile}
            disabled={disabled}
            type={disabled ? ResponsibleBettingButtonTypes.Primary : ResponsibleBettingButtonTypes.Applied}
          />
        </div>
      </div>
    </div>
  ) : (
    <div
      className={classNames(commonStyles.lossLimitControllsContainer, styles.reminderView__wrapper, {
        [styles.reminderView__wrapper__mobile]: isMobile
      })}
    >
      <div className={commonStyles.limitView__container}>
        <div
          className={classNames(commonStyles.limitView__wrapper, {
            [commonStyles.limitView__wrapper__mobile]: isMobile
          })}
        >
          <span
            className={classNames(commonStyles.limitView__label, {
              [styles.reminderView__label__remindEvery]: !isMobile
            })}
          >
            {t(`responsibleBetting.labels.remind${isMobile ? 'Me' : ''}Every`)}:
          </span>
          <span className={commonStyles.limitView__value}>
            {t('responsibleBetting.labels.minutesMinutes', {
              minutes: selectedLimit
            })}
          </span>
        </div>
        <div className={commonStyles.limitView__controls}>
          <button
            className={classNames(commonStyles.limitView__controlIcon, {
              [componentsBranding.SECONDARY_BTN]: !isMobile,
              [mobileComponents.BUTTON]: isMobile,
              [mobileComponents.SECONDARY]: isMobile
            })}
            onClick={onRemove}
          >
            <i className="fa2 fa2-trash-icon biab_fav-manage-icon" />
          </button>
          <button
            className={classNames(commonStyles.limitView__controlIcon, {
              [componentsBranding.SECONDARY_BTN]: !isMobile,
              [mobileComponents.BUTTON]: isMobile,
              [mobileComponents.SECONDARY]: isMobile
            })}
            onClick={onUpdate}
          >
            <i className="fa2 fa2-edit-icon biab_fav-manage-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResponsibleBettingReminderView;
