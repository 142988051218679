import classNames from 'classnames';

import { ActionTypes } from 'constants/betslip';
import { ActionType } from 'types/betslip';

import styles from './styles.module.scss';

const ButtonArrow = ({
  type,
  isArrowEnabled,
  onClickHandler
}: {
  type: ActionType;
  isArrowEnabled: boolean;
  onClickHandler: (type: ActionType) => void;
}) => {
  return (
    <button
      className={classNames(styles.arrow, {
        [styles.arrow__up]: type === ActionTypes.ADD,
        [styles.arrow__down]: type === ActionTypes.SUBSTR,
        [styles.arrow__disabled]: !isArrowEnabled
      })}
      disabled={!isArrowEnabled}
      onClick={() => onClickHandler(type)}
      tabIndex={-1}
    >
      <i className="fa2 fa2-sort-up" aria-hidden="true" />
    </button>
  );
};

export default ButtonArrow;
