import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { toNumber } from 'lodash';

import { BetsStatusesTypes, CurrentBetActions } from 'constants/app';
import { betslipBranding } from 'constants/branding';
import { GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import useConvertTimeToString from 'hooks/useConvertTimeToString';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { removeUnmatchedOfferIdToShowLapsed, setClosedUnmatchedOfferId } from 'redux/modules/currentBets';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getIsMarketInPlayByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { getIsFullyMatched, isCancelled } from 'utils/betslip';

import styles from './OpenedBetsUnmatchedHeader.module.scss';

type OpenedBetsUnmatchedHeaderProps = {
  bet: TCurrentBet;
};

const OpenedBetsUnmatchedHeader = ({ bet }: OpenedBetsUnmatchedHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(bet.marketId));

  const isCancelledBet = isCancelled(bet);
  const isFullyMatchedBet = getIsFullyMatched(bet);
  const isLapsedBet = bet.offerState === BetsStatusesTypes.LAPSED;
  const isEditing = bet.action === CurrentBetActions.EDITING;
  const isFullyMatchedAfterEditing = bet.action === CurrentBetActions.FULLY_MATCHED_AFTER_EDITING;

  const isDefaultHeader =
    !isEditing && (isCancelledBet || isFullyMatchedBet || isLapsedBet || isFullyMatchedAfterEditing);

  const { hoursAndMinutes } = useConvertTimeToString({ startDate: toNumber(bet.marketStartDate || 0) });

  const eventId = bet.mainEventId || bet.eventId;
  const isEventLinkEnabled = eventId && bet.marketType !== MARKET_TYPES.dailyGoals && !isGameBetSlip;
  const eventLink = isGameBetSlip
    ? `${GAMES_BASE_URL}/${bet.eventTypeId}`
    : `${SPORT_BASE_URL}/${bet.eventTypeId}/event/${eventId}`;
  const marketLink = isGameBetSlip
    ? `${GAMES_BASE_URL}/${bet.eventTypeId}`
    : `${SPORT_BASE_URL}/${bet.eventTypeId}/market/${bet.marketId}`;
  const eventName = bet.mainEventName || bet.eventName;
  let fullMarketName = bet.marketNameWithParents || bet.marketName;
  const startTimeFormatted = bet.marketStartDate ? hoursAndMinutes : '';

  if (bet.raceName) {
    fullMarketName = `${startTimeFormatted} ${bet.raceName} - ${bet.marketName}`;
  } else if (isGameBetSlip && bet.sportName) {
    fullMarketName = bet.sportName;
  }

  const handleRemoveBet = () => {
    dispatch(setClosedUnmatchedOfferId(bet.offerId));
    dispatch(removeUnmatchedOfferIdToShowLapsed(bet.offerId));
  };

  return (
    <div
      className={classNames(styles.header, {
        [betslipBranding.OPEN_BET_EVENT_NAME]: !isDefaultHeader,
        [betslipBranding.BET_EVENT_NAME]: isDefaultHeader,
        [styles.header__canBeRemoved]: bet.canBeRemoved
      })}
    >
      <div className={styles.header__left}>
        {isInPlay && !isGameBetSlip && (
          <p className={classNames(styles.header__live, betslipBranding.LIVE)}>{t('betslip.openBets.live')}</p>
        )}
        <p className={styles.header__title}>
          {isEventLinkEnabled ? (
            <Link to={eventLink} className={styles.eventName}>
              {eventName}
            </Link>
          ) : (
            <span>{eventName}</span>
          )}
          {!isGameBetSlip && (
            <>
              <span>&nbsp;&ndash;&nbsp;</span>
              <Link to={marketLink} className={styles.marketName}>
                {fullMarketName}
              </Link>
            </>
          )}
        </p>
      </div>
      {bet.canBeRemoved && (
        <button className={styles.header__closeWrapper} onClick={handleRemoveBet}>
          <i className={classNames('biab_custom-icon-close', styles.header__closeIcon)} />
        </button>
      )}
    </div>
  );
};

export default OpenedBetsUnmatchedHeader;
