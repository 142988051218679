import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AutoCashOutStatuses } from 'constants/app';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';

import styles from './styles.module.scss';

interface ITemporaryState {
  currentCase: 'NEW_CREATED' | 'EXISTS_REMOVED';
  value?: string | number;
  isAsianBetSlip?: boolean;
  isAVMobileCashOutPage?: boolean;
  isAsianView?: boolean;
  isAVMobileBetList?: boolean;
  isEvent?: boolean;
  ignoreFlexibleDisplayFormat?: boolean;
}

const TemporaryState = ({
  currentCase,
  value,
  isAsianBetSlip = false,
  isAVMobileCashOutPage = false,
  isAVMobileBetList = false,
  isEvent = false,
  isAsianView = false,
  ignoreFlexibleDisplayFormat = false
}: ITemporaryState) => {
  const { t } = useTranslation();

  const { formattedAmount: formattedProfit } = useFormatCurrency(value || 0, '', {
    noRounding: true,
    isCheckIndian: true,
    ignoreFlexibleDisplayFormat
  });
  const { isMobile } = useDevice();

  const isRemove = currentCase === AutoCashOutStatuses.EXISTS_REMOVED;
  const isAsianMobile = isAVMobileCashOutPage || isAVMobileBetList || (isEvent && isAsianView && isMobile);

  return (
    <div
      className={classNames(styles.main, {
        [styles.betslip]: isAsianBetSlip,
        [styles.coMobPage]: isAsianMobile
      })}
    >
      <span>
        <span>{t('cashout.page.labels.ifProfitReaches')}</span>
        <strong className="biab_cash-out-auto-value">{formattedProfit}</strong>
      </span>
      <span
        className={classNames(styles.status, {
          'biab_cash-out-auto-removed-label': isRemove,
          'biab_cash-out-auto-saved-label': !isRemove,
          [styles.coMobPage__status]: isAsianMobile
        })}
      >
        {isAsianBetSlip || isAsianMobile ? (
          <>
            {isRemove ? (
              <i
                className={classNames('av-icon av-icon-remove', {
                  [styles.coMobPage__removeIcon]: isAsianMobile
                })}
              >
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </i>
            ) : (
              <i
                className={classNames('av-icon av-icon-tick', styles.tickIcon, {
                  [styles.coMobPage__checkIcon]: isAsianMobile
                })}
              />
            )}
          </>
        ) : (
          <i
            className={classNames({
              'fa fa-times-circle': isRemove,
              'fa2 fa2-check-circle': !isRemove
            })}
          />
        )}
        {t(isRemove ? 'cashout.page.labels.autoCashOutRemoved' : 'cashout.page.labels.autoCashOutSaved')}
      </span>
    </div>
  );
};

export default TemporaryState;
