import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import { OddsTypes } from 'constants/app';
import { mobileComponents } from 'constants/branding';
import { GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { BettingTypes } from 'constants/markets';
import { BetSides } from 'constants/myBets';
import useAccountMyBetsCancelAction from 'hooks/useAccountMyBetsCancelAction';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import {
  getIsIframeEnabled,
  getMobileSettingsAlternativeLayOdds,
  getMobileSettingsTotalWinnings
} from 'redux/modules/appConfigs/selectors';
import { setMobileEditOfferId } from 'redux/modules/myBets';
import { betDetails } from 'redux/modules/myBets/selectors';
import { OfferStates } from 'types/myBets';
import { alternativeBackOddsValue, alternativeLayOddsValue, oddsValue } from 'utils/myBetsValues';
import convertOdds from 'utils/oddsConverter';

import MobileBetDescription from './components/MobileBetDescription';
import MobileDetailsField from './components/MobileDetailsField';
import MobileEventInfo from './components/MobileEventInfo';
import MobileNotification from './components/MobileNotification';

import styles from './styles.module.scss';

const MobileBetDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const iframeEnabled = useSelector(getIsIframeEnabled);

  const alternativeLayOdds = useSelector(getMobileSettingsAlternativeLayOdds);
  const totalWinnings = useSelector(getMobileSettingsTotalWinnings);
  const bet = useSelector(betDetails);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const { isCancelBetSuccess, isCancelBetLoading, isCancelBetError, onCancelBet } = useAccountMyBetsCancelAction({
    bet,
    isMobile: true,
    isBetDetail: true
  });

  const isMatched = bet!.offerState === OfferStates.MATCHED;
  const isUnmatched = bet!.offerState === OfferStates.PLACED;
  const isCancelled = bet!.offerState === OfferStates.CANCELLED;
  const isSettled = bet!.offerState === OfferStates.WON || bet!.offerState === OfferStates.LOST;
  const isCurrentStatus = isMatched || isUnmatched;

  const profitValue = isSettled ? bet!.profit : bet!.potentialProfit;
  const liabilityValue = isSettled ? bet!.pastTotalLiability : bet!.liability;
  const isLaySide = bet?.side === BetSides.Lay;

  const getDateByStatus = () => {
    if (isCancelled) {
      return bet!.cancelledDate;
    }

    return !isCurrentStatus ? bet!.settledDate : bet!.placedDate;
  };

  const { date } = useParseMyBetsDates({ dateToParse: getDateByStatus(), divider: ',' });
  const { date: placedDate } = useParseMyBetsDates({ dateToParse: bet!.placedDate, divider: ',' });

  const formatCurrencySetting = { noRounding: true, isCheckIndian: true, noSymbol: !isMultiCurrencySupported };

  const { noFormattedAmount: winnings } = useFormatCurrency(
    bet?.totalWinnings || 0,
    bet?.currency,
    formatCurrencySetting
  );
  const { noFormattedAmount: profit } = useFormatCurrency(profitValue, bet?.currency, formatCurrencySetting);
  const { noFormattedAmount: liability } = useFormatCurrency(liabilityValue, bet?.currency, formatCurrencySetting);
  const { noFormattedAmount: stake } = useFormatCurrency(bet?.size || 0, bet?.currency, formatCurrencySetting);

  const isMatchedOrSettled = isMatched || isSettled;

  const getProfitValue = (): string => {
    if (!profitValue) return '';

    if (isSettled) {
      const operator = Number(profitValue) > 0 ? '+' : '';
      return `${operator}${profit}`;
    }

    return profit;
  };

  const getAlternativeOddsLabel = (): string => {
    const sideLabel = bet!.side === BetSides.Back ? 'layOdds' : 'backOdds';
    return t(`account.mybets.labels.${sideLabel}`);
  };

  const getAlternativeOddsValue = (isRounded: boolean) => {
    return bet!.side === BetSides.Back
      ? alternativeLayOddsValue(bet!, { isRounded })
      : alternativeBackOddsValue(bet!, { isRounded });
  };

  const onUpdateClick = () => {
    if (bet) {
      const url = `${bet.betType === BettingTypes.GAME ? GAMES_BASE_URL : SPORT_BASE_URL}/${bet.eventTypeId}/market/${
        bet.marketId
      }`;

      dispatch(setMobileEditOfferId(bet.offerId));

      navigate(url);
    }
  };

  return (
    <div className={styles.betDetailWrap}>
      {!bet?.asian && alternativeLayOdds && (
        <MobileNotification text={t('account.mybets.messages.alternativeLayOdds')} />
      )}
      <MobileEventInfo bet={bet!} />
      <div className={styles.betDetail}>
        <MobileBetDescription bet={bet!} isCancelBetError={isCancelBetError} isCancelBetSuccess={isCancelBetSuccess} />
        {bet?.asian && (
          <MobileDetailsField
            name={`${t(`asianView.labels.oddsType.short.${OddsTypes.DEC}`)} ${t('account.mybets.labels.odds')}`}
            value={`@${convertOdds(oddsValue(bet!) || '', OddsTypes.DEC)}`}
          />
        )}
        {!bet?.asian && alternativeLayOdds && (
          <MobileDetailsField
            isWithTooltip={isMatchedOrSettled}
            tooltipValue={getAlternativeOddsValue(false)}
            name={getAlternativeOddsLabel()}
            value={getAlternativeOddsValue(true)}
          />
        )}
        <MobileDetailsField name={t('account.mybets.labels.stake')} value={stake} />
        {bet?.asian && (
          <MobileDetailsField name={t('account.mybets.labels.risk')} value={(isLaySide ? liability : stake) || '--'} />
        )}
        {!bet?.asian && totalWinnings && (
          <>
            <MobileDetailsField name={t('account.mybets.labels.liability')} value={liability || '--'} />
            <MobileDetailsField
              name={t('account.mybets.labels.totalWinnings')}
              value={bet?.totalWinnings ? winnings : ''}
            />
          </>
        )}
        <MobileDetailsField
          name={isSettled ? t('account.mybets.labels.profit.past') : t('account.mybets.labels.profit.current')}
          value={getProfitValue()}
          valueClassName={classNames({
            [styles.profitValue__positive]: isSettled && profitValue && Number(profitValue) > 0,
            [styles.profitValue__negative]: isSettled && profitValue && Number(profitValue) < 0
          })}
        />
        {!isCurrentStatus && (
          <MobileDetailsField
            name={isCancelled ? t('account.mybets.labels.cancelled') : t('account.mybets.labels.settled')}
            value={date}
            borderTop
          />
        )}
        <MobileDetailsField name={t('account.mybets.labels.placed')} value={placedDate} borderTop={isCurrentStatus} />
        {bet?.asian && (
          <MobileDetailsField
            name={t('account.mybets.labels.type')}
            value={t(`account.mybets.labels.${bet?.inPlay ? 'live' : 'prePlay'}Placement`)}
          />
        )}
        <MobileDetailsField
          name={t('account.mybets.labels.refID')}
          value={bet?.offerId}
          copyToClipboard={!iframeEnabled}
        />
      </div>
      {isUnmatched && !isCancelBetSuccess && (
        <div className={styles.unmatchedActions}>
          <button
            className={classNames(styles.unmatchedActions__action, mobileComponents.BUTTON, mobileComponents.SECONDARY)}
            onClick={onCancelBet}
          >
            {t('account.mybets.btns.cancelBet')}
          </button>
          <button
            className={classNames(styles.unmatchedActions__action, mobileComponents.BUTTON, mobileComponents.SECONDARY)}
            onClick={onUpdateClick}
          >
            {t('account.mybets.btns.updateBet')}
          </button>
        </div>
      )}
      {isUnmatched && isCancelBetLoading && (
        <div className={styles.loader__wrapper}>
          <Loader circleColor={CircleColors.BLUE_2} className={styles.loader} />
        </div>
      )}
    </div>
  );
};

export default MobileBetDetail;
