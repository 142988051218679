import { flatten, values } from 'lodash';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { BetsStatusesTypes } from 'constants/app';
import api from 'redux/api/methods';

import {
  failureAVPlaceActiveSelectedBets,
  failureFetchAsianViewBetStatuses,
  fetchAsianViewBetStatuses,
  placeAVActiveSelectedBets,
  successAVPlaceActiveSelectedBets,
  successFetchAsianViewBetsStatuses
} from './index';
import { AsianViewBetStatusResponse, AsianViewPlacedBetsByMarket } from './type';

export function* asianViewPlaceSelectedBetsWorker(action: ReturnType<typeof placeAVActiveSelectedBets>) {
  try {
    const response: AsianViewPlacedBetsByMarket = yield call(api.asianPlacement.placeBets, action.payload.data);

    yield put(successAVPlaceActiveSelectedBets(response));

    action.payload.onSuccessCallback(flatten(values(response).map(({ offerIds }) => values(offerIds))));
  } catch (error: any) {
    yield put(failureAVPlaceActiveSelectedBets(error.data));
    action.payload.onErrorCallback();
  }
}

export function* getAsianViewBetsStatusesWorker(action: ReturnType<typeof fetchAsianViewBetStatuses>) {
  try {
    const betStatuses: AsianViewBetStatusResponse = yield call(
      api.asianPlacement.getBetsStatuses,
      action.payload.offerIds.join()
    );

    if (
      !values(betStatuses).some(status => status.state === BetsStatusesTypes.PENDING) &&
      action.payload?.onSuccessCallback
    ) {
      action.payload.onSuccessCallback();
    }
    yield put(successFetchAsianViewBetsStatuses(betStatuses));
  } catch (error: any) {
    yield put(failureFetchAsianViewBetStatuses({ offersIds: action.payload.offerIds, error: error?.response?.data }));
    action.payload.onErrorCallback();
  }
}

export default function* saga() {
  yield all([
    takeEvery(placeAVActiveSelectedBets.type, asianViewPlaceSelectedBetsWorker),
    takeLatest(fetchAsianViewBetStatuses.type, getAsianViewBetsStatusesWorker)
  ]);
}
