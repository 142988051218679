import { useSelector } from 'react-redux';

import BetsIndicator from 'components/SearchContent/components/BetsIndicator';
import { MatchTypes } from 'constants/bets';
import { getCurrentSelectionBetsAmountByType } from 'redux/modules/currentBets/selectors';

interface BetsIndicatorSearchPageProps {
  eventTypeId: string;
  marketId: string;
  selectionId: number;
  handicap: number;
}

const BetsIndicatorSearchPage = ({ eventTypeId, marketId, selectionId, handicap }: BetsIndicatorSearchPageProps) => {
  const unmatchedBets = useSelector(
    getCurrentSelectionBetsAmountByType({
      marketId,
      selectionId,
      handicap,
      type: MatchTypes.UNMATCHED,
      ignoreCancelled: true,
      ignoreFullyMatchedAction: true
    })
  );

  const matchedBets = useSelector(
    getCurrentSelectionBetsAmountByType({
      marketId,
      selectionId,
      handicap,
      type: MatchTypes.MATCHED,
      ignoreCancelled: true
    })
  );

  return (
    <BetsIndicator
      matchedBets={matchedBets}
      unmatchedBets={unmatchedBets}
      eventTypeId={eventTypeId}
      marketId={marketId}
    />
  );
};

export default BetsIndicatorSearchPage;
