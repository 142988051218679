import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import LoginForm from 'components/forms/LoginForm';
import MobileLoginForm from 'components/forms/MobileLoginForm/MobileLoginForm';
import TwoFactorAuthForm from 'components/forms/TwoFactorAuthForm';
import Modal from 'components/Modal';
import { CookieNames, PopupTypes, PostMessageTypes } from 'constants/app';
import useDevice from 'hooks/useDevice';
import { getPostLoginNotificationEnabled } from 'redux/modules/appConfigs/selectors';
import { resetAuthError, setAuthenticatedUser } from 'redux/modules/auth';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setLoginPopup } from 'redux/modules/popup';
import { getLoginPopup } from 'redux/modules/popup/selectors';

import styles from './styles.module.scss';

const LogInPopup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([CookieNames.BIAB_LOGIN_POP_UP_SHOWN]);

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const loading = useSelector(getLoggedInLoading);
  const postLoginNotificationEnabled = useSelector(getPostLoginNotificationEnabled);
  const { type, params } = useSelector(getLoginPopup);

  const { currentDevice, isMobile } = useDevice();

  const isLoginPopupDisabled = cookies[CookieNames.BIAB_LOGIN_POP_UP_SHOWN] !== 'true';
  const isLoginPopupEnabled = isLoggedIn && postLoginNotificationEnabled && isLoginPopupDisabled;

  const onLoginPopupClose = () => {
    setCookie(CookieNames.BIAB_LOGIN_POP_UP_SHOWN, true, { path: '/' });
  };

  const onCloseVerificationPopup = () => {
    dispatch(setLoginPopup({ type: '' }));
    dispatch(setAuthenticatedUser(false));
  };

  useEffect(() => {
    if (!loading && !isLoggedIn && !isLoginPopupDisabled) {
      removeCookie(CookieNames.BIAB_LOGIN_POP_UP_SHOWN, {
        path: '/'
      });
    }
  }, [isLoggedIn, isLoginPopupDisabled, loading, removeCookie]);

  useEffect(() => {
    dispatch(setLoginPopup({ type: '' }));
  }, [currentDevice, dispatch]);

  return (
    <>
      {!isLoggedIn && (
        <>
          {type === PopupTypes.FAVORITE && (
            <Modal
              clickableBackground
              open={type === PopupTypes.FAVORITE}
              onClose={() => dispatch(setLoginPopup({ type: '' }))}
              title={t('navigation.favouritesModalTitle')}
            >
              <div className={styles.loginMessage}>{t('favourites.labels.login')}</div>
            </Modal>
          )}

          {type === PostMessageTypes.LOGIN_TO_PLACE_BET && (
            <>
              {isMobile ? (
                <MobileLoginPopUp topLabel={t('betslip.messages.logInToPlaceBets')} />
              ) : (
                <Modal
                  isDisableScroll
                  clickableBackground
                  open={type === PostMessageTypes.LOGIN_TO_PLACE_BET}
                  onClose={() => dispatch(setLoginPopup({ type: '' }))}
                  customClassNames={{ content: styles.loginToPlaceBet }}
                  title={t('login.pop.up.title')}
                >
                  <div className={styles.loginMessage}>{t('betslip.messages.logInToPlaceBets')}</div>
                </Modal>
              )}
            </>
          )}

          {type === PopupTypes.LOGIN_POPUP && (
            <>
              {isMobile ? (
                <MobileLoginPopUp topLabel={t('account.messages.login')} showLoginForm />
              ) : (
                <Modal
                  isDisableScroll
                  clickableBackground
                  open={type === PopupTypes.LOGIN_POPUP}
                  onClose={() => dispatch(setLoginPopup({ type: '' }))}
                  title={t('login.pop.up.title')}
                >
                  <div className={styles.loginMessage}>{t('account.messages.login')}</div>
                  <LoginForm isModal />
                </Modal>
              )}
            </>
          )}

          {type === PopupTypes.TWO_FACTOR_AUTH && (
            <Modal
              clickableBackground
              open={type === PopupTypes.TWO_FACTOR_AUTH}
              onClose={onCloseVerificationPopup}
              customClassNames={{
                dialog: styles.mobileLoginPopUp__modal__dialog,
                body: styles.mobileLoginPopUp__modal__body,
                header: classNames(styles.mobileLoginPopUp__modal__header, {
                  [styles.mobileLoginPopUp__modal__header__desktop]: !isMobile
                }),
                content: styles.mobileLoginPopUp__modal__content,
                title: styles.mobileLoginPopUp__modal__title
              }}
              title={t('authentication.labels.authenticationCode')}
            >
              {params?.username && <TwoFactorAuthForm username={params.username} />}
            </Modal>
          )}
        </>
      )}
      {isLoginPopupEnabled && (
        <Modal
          open={isLoginPopupEnabled}
          onClose={onLoginPopupClose}
          title={t('notification.login.title')}
          customClassNames={{ content: styles.postLoginPopup }}
          clickableBackground
        >
          <div
            className={styles.loginMessage}
            dangerouslySetInnerHTML={{ __html: unescape(t('notification.login.body')) }}
          />
          <div className={classNames('biab_btns', styles.closePopup)}>
            <button className="biab_btn-continue" onClick={onLoginPopupClose}>
              {t('notification.login.button')}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default LogInPopup;

type MobileLoginPopUpProps = {
  topLabel: string;
  showLoginForm?: boolean;
};

function MobileLoginPopUp({ topLabel, showLoginForm }: MobileLoginPopUpProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (showLoginForm) {
        dispatch(resetAuthError());
      }
    };
  }, []);

  return (
    <Modal
      isDisableScroll
      clickableBackground
      open={true}
      onClose={() => dispatch(setLoginPopup({ type: '' }))}
      customClassNames={{
        dialog: styles.mobileLoginPopUp__modal__dialog,
        body: styles.mobileLoginPopUp__modal__body,
        header: styles.mobileLoginPopUp__modal__header,
        content: styles.mobileLoginPopUp__modal__content,
        title: styles.mobileLoginPopUp__modal__title
      }}
      title={t('login.pop.up.title')}
    >
      <p
        className={classNames(styles.mobileLoginPopUp__topLabel, {
          [styles.mobileLoginPopUp__topLabel__mb]: showLoginForm
        })}
      >
        {topLabel}
      </p>
      {showLoginForm && <MobileLoginForm />}
    </Modal>
  );
}
