import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PopularMarket from 'components/PopularMarket';
import SEO from 'components/SEO';
import SportPageModule from 'components/SportPageModule';
import SportsSection from 'components/SportsSection';
import { Devices, MetaPages, PlacementPages } from 'constants/app';
import { SportPageContextFilters, SportPageTypes } from 'constants/sportPage';
import { getAppDevice, getIsShowMarketOdd, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchPopularMarkets } from 'redux/modules/popularMarket';
import { fetchSports } from 'redux/modules/sports';

const CountrySportPage = () => {
  const { countryId } = useParams();
  const dispatch = useDispatch();

  const timezone = useSelector(getTimezone);
  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isShowMarketOdds = useSelector(getIsShowMarketOdd);

  useEffect(() => {
    dispatch(fetchSports());
  }, [timezone, isLoggedIn, language]);

  useEffect(() => {
    if (isShowMarketOdds) {
      dispatch(fetchPopularMarkets());
    }
  }, [isLoggedIn, dispatch, isShowMarketOdds]);

  return (
    <>
      <SEO page={MetaPages.COUNTRY} id={countryId} />
      {device === Devices.DESKTOP && <PopularMarket page={PlacementPages.COUNTRY_ODDS_BANNER} />}
      {device === Devices.MOBILE && <SportsSection />}
      {device === Devices.DESKTOP && (
        <SportPageModule
          pageDetailsId={countryId ?? ''}
          contextFilter={SportPageContextFilters.COUNTRY}
          pageType={SportPageTypes.COUNTRY}
          placementPage={PlacementPages.COUNTRY}
        />
      )}
    </>
  );
};

export default CountrySportPage;
