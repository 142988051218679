import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { BetsTypesByPeriods, BetTypes, ExchangeTypes } from 'constants/myBets';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import MobileMultiselect from 'pages/MyBetsPages/components/MobileAccountFilters/components/MobileMultiselect';
import { setMobileFilters } from 'redux/modules/myBets';
import { getMobileFiltersChanged } from 'redux/modules/myBets/selectors';
import { BetType, MyBetsPeriod, TMobileSelectOption } from 'types/myBets';

const MobileFiltersBetTypes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { periodType, betsTypes, isCurrentPeriod } = useMyBetsFilters();

  const { product } = useSelector(getMobileFiltersChanged);

  const isAsianViewProduct = product === ExchangeTypes.AsianView;

  const betTypesOptions: TMobileSelectOption[] = (BetsTypesByPeriods[periodType as MyBetsPeriod] ?? [])
    .map(option => {
      return {
        id: option,
        label: t(`account.mybets.btns.${option.toLowerCase()}`),
        isSelected: betsTypes.includes(option),
        defaultIsSelected: betsTypes.includes(option)
      };
    })
    .filter(({ id }) => (isCurrentPeriod || isAsianViewProduct ? id !== BetTypes.Lapsed : true));

  const changeBetTypesHandler = (betTypesChanged: TMobileSelectOption[], isBetTypesChanged: boolean) => {
    dispatch(
      setMobileFilters({
        betTypes: betTypesChanged.filter(({ isSelected }) => isSelected).map(({ id }) => id as BetType),
        isBetTypesChanged
      })
    );
  };

  return (
    <MobileMultiselect
      title={t('account.myBets.labels.betTypes')}
      defaultOptions={betTypesOptions}
      onChangeCallback={changeBetTypesHandler}
    />
  );
};

export default MobileFiltersBetTypes;
