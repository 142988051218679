import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { MarketStatuses } from 'constants/app';
import { asianViewBetslipBranding } from 'constants/branding';
import { BetSides } from 'constants/myBets';
import useAsianOdds from 'hooks/useAsianOdds';
import { useCurrency } from 'hooks/useCurrency';
import useOddsDisplayFormat from 'hooks/useOddsDisplayFormat';
import { getCurrency, getIsOperatorBettingLimitsEnabled } from 'redux/modules/appConfigs/selectors';
import { getIsAsianCouponSettingsOpen } from 'redux/modules/asianView/selectors';
import { getLeaguesOpened } from 'redux/modules/asianViewLeaguesFilter/selectors';
import { removeMobileSelectedBet, updateAVMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip';
import { getAsianMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip/selectors';
import {
  AsianViewMobileBetActions,
  TAsianMobileSelectedBet,
  TAsianMobileUpdateData,
  TAsianMobileUpdatedBet
} from 'redux/modules/asianViewMobileBetslip/type';
import {
  getMarketPricesCurrency,
  getMarketPricesFirstKeyLineAdjustedHandicap,
  getMarketPricesRunnerLockedBySelectionId,
  getMarketPricesScoreById,
  getMarketPricesSecondKeyLineAdjustedHandicap,
  getMarketPricesStatusById
} from 'redux/modules/marketsPrices/selectors';
import { calculateLiability, calculateSize } from 'utils/liability';
import { getMarketTypes } from 'utils/market';
import { validateSize } from 'utils/size';

import MobileKeyboard from './components/Keyboard';
import MobileMessage from './components/Message';
import MobilePlacementForm from './components/MobilePlacementForm';
import MobileProgress from './components/Progress';
import MobileSelectedOdds from './components/SelectedOdds';
import MobileTitle from './components/Title';

import styles from './styles.module.scss';

interface IAsianViewMobileSelectedBet {
  betslipId: string;
  isMargin?: boolean;
}

const AsianViewMobileSelectedBet = ({ betslipId, isMargin }: IAsianViewMobileSelectedBet) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const bet = useSelector(getAsianMobileSelectedBet(betslipId));
  const status = useSelector(getMarketPricesStatusById(bet?.marketId ?? ''));
  const score = useSelector(getMarketPricesScoreById(bet?.marketId ?? ''));
  const defaultCurrency = useSelector(getCurrency);
  const currencyCode = useSelector(getMarketPricesCurrency(bet?.marketId ?? ''));
  const isLeaguesModalOpened = useSelector(getLeaguesOpened);
  const isSettingsModalOpened = useSelector(getIsAsianCouponSettingsOpen);
  const asianHandicapFirstKeyLineAdjHandicap = useSelector(getMarketPricesFirstKeyLineAdjustedHandicap(bet?.marketId));
  const asianHandicapSecondKeyLineAdjHandicap = useSelector(
    getMarketPricesSecondKeyLineAdjustedHandicap(bet?.marketId)
  );
  const isOperatorBettingLimitsEnabled = useSelector(getIsOperatorBettingLimitsEnabled);
  const isLocked = useSelector(
    getMarketPricesRunnerLockedBySelectionId(bet?.marketId ?? '', bet?.selectionId ?? 0, +(bet?.handicap ?? 0))
  );

  const currency = useCurrency(currencyCode);
  const betPrice = useAsianOdds({ bet: bet ?? ({} as TAsianMobileSelectedBet) });
  const odds = useOddsDisplayFormat(bet ?? ({} as TAsianMobileSelectedBet));

  const { isAsianHandicap, isTotalGoals } = getMarketTypes(bet?.marketType ?? '', bet?.bettingType ?? '');
  const isBack = bet?.betType === BetSides.Back;
  const isSuspended = status === MarketStatuses.SUSPENDED;
  const isDisabledBet = !odds || isSuspended || isLocked;
  const isNew = bet?.action === AsianViewMobileBetActions.NEW;
  const isValidation = bet?.action === AsianViewMobileBetActions.VALIDATION;
  const isProgress = bet?.action === AsianViewMobileBetActions.PROGRESS;
  const isScoreChanged = !!((isAsianHandicap || isTotalGoals) && score && bet?.score !== score);

  const adjKLHandicap =
    bet?.runnerIndex === 0 ? asianHandicapFirstKeyLineAdjHandicap : asianHandicapSecondKeyLineAdjHandicap;

  const updateMobileBet = ({ price, size, profit, focusedField, action, ignoreValidation }: TAsianMobileUpdateData) => {
    if (bet) {
      const updateData: TAsianMobileUpdatedBet = {
        size: bet.size,
        profit: bet.profit
      };

      if (action) {
        updateData.action = action;
      }

      if (focusedField) {
        updateData.focusedField = focusedField;
      }

      if (!isUndefined(size)) {
        updateData.size = size;
        updateData.profit = calculateLiability(betPrice, size, bet);
      } else if (!isUndefined(profit)) {
        updateData.size = calculateSize(betPrice, profit);
        updateData.profit = profit;
      } else if (!isUndefined(price)) {
        updateData.price = price;
        updateData.profit = calculateLiability(price, bet.size, bet);
      }

      if (!ignoreValidation) {
        const validation = validateSize({
          ...updateData,
          betType: bet.betType,
          currency,
          defaultCurrency,
          currencyCode,
          isOperatorBettingLimitsEnabled
        });

        if (!validation.isValid && validation.errorMessage?.text) {
          updateData.validationMessage = t(validation.errorMessage?.text, validation.errorMessage.params ?? {});
          updateData.sizeValidationType = validation.type;
          updateData.validationMessageId = validation.messageId;
        } else if (action !== AsianViewMobileBetActions.VALIDATION) {
          updateData.validationMessage = '';
          updateData.sizeValidationType = null;
          updateData.validationMessageId = null;
          updateData.action = AsianViewMobileBetActions.NEW;
        }

        updateData.isValid = validation.isValid;
        updateData.validSizeValue = validation.validValue;
      }

      if (bet.betslipId) {
        dispatch(updateAVMobileSelectedBet({ betslipId: bet.betslipId, data: updateData }));
      }
    }
  };

  const removeMobileBet = () => dispatch(removeMobileSelectedBet({ betslipId }));

  useEffect(() => {
    if (isScoreChanged || isLeaguesModalOpened || isSettingsModalOpened) {
      removeMobileBet();
    }

    return () => {
      removeMobileBet();
    };
  }, [isScoreChanged, isLeaguesModalOpened, isSettingsModalOpened]);

  useEffect(() => {
    if (!isDisabledBet && isAsianHandicap && adjKLHandicap !== bet?.adjKLHandicap) {
      removeMobileBet();
    }
  }, [adjKLHandicap, bet?.adjKLHandicap]);

  useEffect(() => {
    if (isValidation && bet?.sizeValidationType === 'invalid' && bet.validSizeValue) {
      updateMobileBet({ size: bet.validSizeValue, action: AsianViewMobileBetActions.VALIDATION });
    }
  }, [isValidation]);

  useEffect(() => {
    if (bet?.price && bet?.size) {
      updateMobileBet({ size: bet.size, action: AsianViewMobileBetActions.VALIDATION });
    }
  }, []);

  return (
    <div
      className={classNames(asianViewBetslipBranding.BETSLIP, {
        [asianViewBetslipBranding.BACK_BET]: isBack,
        [asianViewBetslipBranding.LAY_BET]: !isBack,
        [asianViewBetslipBranding.LOCKED_BET]: isDisabledBet,
        [styles.bet__margin]: isMargin,
        [styles.bet__back]: isBack,
        [styles.bet__lay]: !isBack,
        [styles.bet__disabled]: isDisabledBet
      })}
    >
      <div className={styles.inner}>
        <div className={styles.row}>
          {!!bet && <MobileTitle bet={bet} />}
          {(isNew || isValidation) && (
            <i onClick={removeMobileBet} className={classNames('biab_tour-icon-close', styles.closeIcon)} />
          )}
        </div>
        {!!bet && <MobileSelectedOdds bet={bet} isDisabledBet={isDisabledBet} />}
        <div className={classNames(styles.dashBorder, asianViewBetslipBranding.BET_SEPARATOR)} />
        {!isProgress && !isDisabledBet && !!bet && <MobilePlacementForm bet={bet} updateMobileBet={updateMobileBet} />}
        {isDisabledBet && (
          <MobileMessage type="info" message={t('asianView.labels.selectedBet.unavailable')} isLockIcon />
        )}
        {isProgress && <MobileProgress />}
      </div>
      {(isNew || isValidation) && !isDisabledBet && !!bet && (
        <MobileKeyboard bet={bet} updateMobileBet={updateMobileBet} />
      )}
    </div>
  );
};

export default AsianViewMobileSelectedBet;
