import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { CashOutStatuses } from 'constants/app';
import api from 'redux/api/methods';
import {
  AutoCashOut,
  CashOutMarket,
  CashOutQuote,
  MarketCashOutResponse,
  TResponseCashOutStatus
} from 'redux/modules/cashOut/type';
import { IMarketRules } from 'types/markets';

import {
  asianViewCashOutRemovePendingCashOut,
  asianViewCreateAutoCashOut,
  deleteAsianViewAutoCashOut,
  failureAsianViewCreateAutoCashOut,
  failureDeleteAsianViewAutoCashOut,
  failureFetchAsianViewAutoCashOutMarkets,
  failureFetchAsianViewCashOutMarket,
  failureFetchAsianViewCashOutMarketRules,
  failureFetchAsianViewCashOutMarkets,
  failureFetchAsianViewCashOutQuotes,
  failureFetchAsianViewCashOutStatus,
  fetchAsianViewAutoCashOutMarkets,
  fetchAsianViewCashOutMarket,
  fetchAsianViewCashOutMarketRules,
  fetchAsianViewCashOutMarkets,
  fetchAsianViewCashOutQuotes,
  fetchAsianViewCashOutStatus,
  setQuotesLoading,
  successAsianViewCreateAutoCashOut,
  successDeleteAsianViewAutoCashOut,
  successFetchAsianViewAutoCashOutMarkets,
  successFetchAsianViewCashOutMarket,
  successFetchAsianViewCashOutMarketRules,
  successFetchAsianViewCashOutMarkets,
  successFetchAsianViewCashOutQuotes,
  successFetchAsianViewCashOutStatus
} from './index';
import { getASianViewCashOutStringifiedQuotes, getAsianViewStringifiedAutoCashOutMarkets } from './selectors';

function* fetchAsianViewCashOutQuotesWorker(action: ReturnType<typeof fetchAsianViewCashOutQuotes>) {
  try {
    const response: CashOutQuote[] = yield call(api.cashOut.getCashOut, { ids: action.payload.ids, isAsianView: true });
    const prevStringifiedQuotes: string = yield select(getASianViewCashOutStringifiedQuotes);
    const stringifiedResponse = JSON.stringify(response);

    if (action.payload.firstLoading || prevStringifiedQuotes !== stringifiedResponse) {
      yield put(
        successFetchAsianViewCashOutQuotes({
          quotes: response,
          firstLoaded: action.payload.firstLoading,
          stringifiedQuotes: stringifiedResponse,
          isWebSocketResponse: false
        })
      );
    } else {
      yield put(setQuotesLoading(false));
    }
  } catch (error: any) {
    yield put(failureFetchAsianViewCashOutQuotes(error.data));
  }
}

function* fetchAsianViewCashOutMarketsWorker(action: ReturnType<typeof fetchAsianViewCashOutMarkets>) {
  try {
    let response: MarketCashOutResponse = yield call(api.cashOut.getMarkets, { ...action.payload, isAsianView: true });

    // This condition needs if page from payload is bigger than last available page (totalPages) in response, so we need to do one more request with valid page
    if (
      action.payload.isPaginationEnabled &&
      action.payload.page > 0 &&
      action.payload.changePage &&
      action.payload.page + 1 > response.markets.totalPages
    ) {
      response = yield call(api.cashOut.getMarkets, {
        ...action.payload,
        page: response.markets.totalPages - 1,
        isAsianView: true
      });
      action.payload.changePage(response.markets.totalPages - 1);
    }

    yield put(
      successFetchAsianViewCashOutMarkets({
        ...response,
        withLoader: action.payload.withLoader,
        isUpdated: !action.payload.isPaginationEnabled
      })
    );
  } catch (error: any) {
    yield put(
      failureFetchAsianViewCashOutMarkets({
        error: error.data,
        withLoader: action.payload.withLoader
      })
    );
  }
}

function* asianViewCreateAutoCashOutWorker(action: ReturnType<typeof asianViewCreateAutoCashOut>) {
  try {
    const response: AutoCashOut = yield call(api.cashOut.createAutoCashOut, action.payload, true);
    yield put(successAsianViewCreateAutoCashOut(response));
  } catch (error: any) {
    yield put(failureAsianViewCreateAutoCashOut(error.data));
  }
}

function* asianViewDeleteAutoCashOutWorker(action: ReturnType<typeof deleteAsianViewAutoCashOut>) {
  try {
    yield call(api.cashOut.deleteAutoCashOut, action.payload.id, true);
    yield put(successDeleteAsianViewAutoCashOut(action.payload.marketId));
  } catch (error: any) {
    yield put(failureDeleteAsianViewAutoCashOut(error));
  }
}

function* asianViewAutoCashOutMarketsWorker(action: ReturnType<typeof fetchAsianViewAutoCashOutMarkets>) {
  try {
    const response: AutoCashOut[] = yield call(api.cashOut.getAutoCashOutMarkets, action.payload, true);
    const prevStringifiedAutoCashOutMarkets: string = yield select(getAsianViewStringifiedAutoCashOutMarkets);
    const stringifiedResponse = JSON.stringify(response);

    if (prevStringifiedAutoCashOutMarkets !== stringifiedResponse) {
      yield put(
        successFetchAsianViewAutoCashOutMarkets({
          autoCashOuts: response,
          isWebSocketResponse: false,
          stringifiedAutoCashOuts: stringifiedResponse
        })
      );
    }
  } catch (error: any) {
    yield put(failureFetchAsianViewAutoCashOutMarkets(error.data));
  }
}

function* fetchAsianViewCashOutMarketWorker(action: ReturnType<typeof fetchAsianViewCashOutMarket>) {
  try {
    const response: CashOutMarket = yield call(api.cashOut.setCashOut, action.payload, true);
    if (response.offers?.length && response.id) {
      yield put(successFetchAsianViewCashOutMarket({ ...response, marketId: action.payload.marketId }));
    } else {
      yield put(
        asianViewCashOutRemovePendingCashOut({
          marketId: action.payload.marketId,
          status: response.status
        })
      );
    }
  } catch (error: any) {
    yield put(failureFetchAsianViewCashOutMarket(error.data));
  }
}

function* fetchAsianViewCashOutStatusWorker(action: ReturnType<typeof fetchAsianViewCashOutStatus>) {
  try {
    const response: TResponseCashOutStatus = yield call(api.cashOut.getCashOutStatus, action.payload.statusId, true);

    yield put(
      successFetchAsianViewCashOutStatus({
        statusId: action.payload.statusId,
        status: response.status,
        marketId: response.marketId
      })
    );

    if (
      (response.status === CashOutStatuses.SUCCESS || response.status === CashOutStatuses.SUCCESS_WITH_BETTER_VALUE) &&
      action.payload.onSuccessStatus
    ) {
      action.payload.onSuccessStatus();
    }
  } catch (error: any) {
    yield put(failureFetchAsianViewCashOutStatus({ error: error, statusId: action.payload.statusId }));
  }
}

function* fetchAsianViewCashOutMarketRulesWorker(action: ReturnType<typeof fetchAsianViewCashOutMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.asianView.marketRules, action.payload);
    yield put(successFetchAsianViewCashOutMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchAsianViewCashOutMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeEvery(fetchAsianViewCashOutQuotes.type, fetchAsianViewCashOutQuotesWorker),
    takeEvery(fetchAsianViewCashOutMarkets.type, fetchAsianViewCashOutMarketsWorker),
    takeEvery(asianViewCreateAutoCashOut.type, asianViewCreateAutoCashOutWorker),
    takeEvery(deleteAsianViewAutoCashOut.type, asianViewDeleteAutoCashOutWorker),
    takeEvery(fetchAsianViewAutoCashOutMarkets.type, asianViewAutoCashOutMarketsWorker),
    takeEvery(fetchAsianViewCashOutMarket.type, fetchAsianViewCashOutMarketWorker),
    takeEvery(fetchAsianViewCashOutStatus.type, fetchAsianViewCashOutStatusWorker),
    takeLatest(fetchAsianViewCashOutMarketRules.type, fetchAsianViewCashOutMarketRulesWorker)
  ]);
}
