import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { GameFilterValues } from 'constants/games';

import styles from './styles.module.scss';

interface FiltersBlockProps {
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
}

//TODO add labels for filter names on admin console and rewrite here
const FiltersBlock = ({ handleChangeFilter }: FiltersBlockProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.radioBlock}>
      <div>
        <label className="radio-inline">
          <input
            type="radio"
            name="optradio"
            value={GameFilterValues.All}
            onChange={handleChangeFilter}
            defaultChecked
          />
          {t('game.results.all')}
        </label>
        <label className="radio-inline">
          <input type="radio" name="optradio" value={GameFilterValues.BetPlaced} onChange={handleChangeFilter} />
          {t('game.results.betPlaced')}
        </label>
      </div>
    </div>
  );
};

export default FiltersBlock;
