import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Platforms } from 'constants/app';
import useAsianPlacementPage from 'hooks/useAsianPlacementPage';
import useAsianView from 'hooks/useAsianView';
import useDevice from 'hooks/useDevice';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { fetchAsianBetSlipCashOutMarket } from 'redux/modules/asianViewBetSlipCashOut';
import { fetchAsianViewCashOutMarket } from 'redux/modules/asianViewCashOut';
import { fetchSetCashOutMarket } from 'redux/modules/cashOut';
import { TSetCashOut } from 'redux/modules/cashOut/type';
import { fetchEventCashOutMarket } from 'redux/modules/eventCashOut';

import styles from './styles.module.scss';

interface ButtonProps {
  marketId: string;
  value: number | undefined;
  profit: number | undefined;
  currency: string | undefined;
  quotePercentage: number | undefined;
  isDisabled?: boolean;
  onHover?: () => void;
  onLeave?: () => void;
  isAsianBetSlip?: boolean;
  isEvent?: boolean;
  isAVMobileCashOutPage?: boolean;
  isAsianView?: boolean;
  buttonClassName?: string;
  isAVMobileBetList?: boolean;
  cashOutTextClassName?: string;
  ignoreFlexibleDisplayFormat?: boolean;
}

const Button = ({
  marketId,
  value,
  profit,
  currency,
  quotePercentage,
  isDisabled,
  onHover = () => {},
  onLeave = () => {},
  isAsianBetSlip = false,
  isEvent = false,
  isAVMobileCashOutPage,
  isAsianView = false,
  buttonClassName = '',
  isAVMobileBetList = false,
  cashOutTextClassName = '',
  ignoreFlexibleDisplayFormat = false
}: ButtonProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAsianViewPage } = useAsianView();
  const asianViewPage = useAsianPlacementPage();

  const { isMobile } = useDevice();

  const { formattedAmount: cashOutValue } = useFormatCurrency(value ?? '', currency, {
    noRounding: true,
    ignoreFlexibleDisplayFormat
  });

  const { formattedAmount: profitValue } = useFormatCurrency(profit ?? '', currency, {
    noRounding: true,
    ignoreFlexibleDisplayFormat,
    isPositiveSign: true,
    noZeroSign: true
  });

  const cashOutDisplayedValue = value !== undefined ? cashOutValue : '--';

  const handlerClick = () => {
    if (!isDisabled && value != undefined && quotePercentage != undefined) {
      const payload: TSetCashOut = {
        marketId,
        profit: profit!,
        quote: +value.toFixed(2),
        statistics: {
          mobile: isMobile,
          applicationType: Platforms.WEB,
          page: isAsianViewPage ? asianViewPage : 'market'
        },
        ...(quotePercentage === 100 ? {} : { quotePercentage })
      };

      if (isAsianBetSlip) {
        dispatch(fetchAsianBetSlipCashOutMarket(payload));
      } else if (isEvent) {
        dispatch(fetchEventCashOutMarket(payload));
      } else if (isAsianView) {
        dispatch(fetchAsianViewCashOutMarket(payload));
      } else {
        dispatch(fetchSetCashOutMarket(payload));
      }
    }
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerBetslip]: isAsianBetSlip,
        [styles.containerAvMobilePage]: isAVMobileCashOutPage || isAVMobileBetList,
        [styles.avEventTab]: isEvent && isMobile
      })}
    >
      <div className="biab_cash-out-page-wrapper">
        <button
          className={classNames(
            'biab_has-partial-cashout',
            styles.cashOutBtn,
            {
              [styles.cashOutBtn__betslip]: isAsianBetSlip,
              [styles.cashOutBtn__avMobPage]: isAVMobileCashOutPage || isAVMobileBetList,
              [styles.cashOutBtn__avEventTab]: isEvent && isMobile,
              'biab_cash-out-page-market-button': !isAVMobileBetList
            },
            buttonClassName
          )}
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
          onClick={handlerClick}
        >
          <span
            className={classNames('biab_cash-out-page-market-button-value', styles.container__topText, {
              [styles.container__topText__avMobile]: isAsianView && isMobile,
              [styles.container__topText__ignoreFlexibleDisplayFormat]: ignoreFlexibleDisplayFormat
            })}
          >
            <span className={cashOutTextClassName}>{t('cashout.page.button.cashOut')}&nbsp;</span>
            {ignoreFlexibleDisplayFormat ? <span>{cashOutDisplayedValue}</span> : cashOutDisplayedValue}
          </span>
          <span
            className={classNames('biab_cash-out-page-market-button-profit', {
              [styles.container__profitText__avMobile]: isMobile && isAsianView
            })}
          >
            {`${t('cashout.page.button.profit')}: `}
            <span
              className={classNames('biab_profit', {
                [styles.cashOutBtn__negative]: profit ? profit < 0 : false,
                biab_positive: profit ? profit > 0 : false,
                biab_negative: profit ? profit < 0 : false
              })}
            >
              {profit != undefined ? profitValue : '--'}
            </span>
          </span>
        </button>
      </div>
      {isDisabled && <div className={styles.disabled} />}
    </div>
  );
};

export default memo(Button);
