import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ViewByDropdown from 'components/ViewByDropdown';
import { CookieNames, Devices, ElementNames, TIME_FILTERS } from 'constants/app';
import { TimeFilters as TimeFiltersMap } from 'constants/competitions';
import { SportPageTypes } from 'constants/sportPage';
import useElementSize from 'hooks/useElementSize';
import {
  getAppDevice,
  getDesktopCompetitionPageSize,
  getPageDefaultSorting,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { fetchSportPageDetails } from 'redux/modules/sportPage';
import { getSportPageMarkets } from 'redux/modules/sportPage/selectors';
import { SportPageContextFilter, SportPageType } from 'redux/modules/sportPage/type';
import { DropdownItem, PlacementPage, TimeFilter, ViewBy } from 'types';
import { getViewByOptions } from 'utils/sportPage';

import SportPageSportsSections from './components/SportPageSportsSections';
import TimeFilters from './components/TimeFilters';

interface SportPageModuleProps {
  /**
   * Current page general id
   */
  pageDetailsId: string;

  /**
   * Current page request data contextFilter
   */
  contextFilter: SportPageContextFilter;

  /**
   * Page type (Sport, Country or Competition)
   */
  pageType?: SportPageType;

  isNotRealEvent?: boolean;
  placementPage: PlacementPage;
}

const SportPageModule = ({
  pageDetailsId,
  pageType = SportPageTypes.COMPETITION,
  contextFilter,
  isNotRealEvent,
  placementPage
}: SportPageModuleProps) => {
  const dispatch = useDispatch();
  const { competitionId = '', timeFilter: competitionTimeFilter = '', sportId = '' } = useParams();
  const [cookies, setCookie] = useCookies([CookieNames.COMPETITION_VIEW_BY, CookieNames.COMPETITION_TIME_FILTER]);

  const device = useSelector(getAppDevice);
  const desktopCompetitionPageSize = useSelector(getDesktopCompetitionPageSize);
  const defaultSorting = useSelector(getPageDefaultSorting);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const markets = useSelector(getSportPageMarkets);
  const popularMarketsHeight = useSelector(getElementHeightByName(ElementNames.POPULAR_MARKETS_HEIGHT));

  const [viewBy, setViewBy] = useState<ViewBy>(cookies.BIAB_COMPETITION_VIEW_BY || defaultSorting);
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(cookies.BIAB_COMPETITION_TIME_FILTER ?? TimeFiltersMap.ALL);
  const [page, setPage] = useState(0);

  const isSingleCompetitionPage = !!competitionId && !TIME_FILTERS.includes(competitionId);
  const timeFilterByCompetition =
    device === Devices.DESKTOP || (isSingleCompetitionPage && !competitionTimeFilter)
      ? timeFilter
      : ((competitionTimeFilter || competitionId).toUpperCase() as TimeFilter);
  const options = isSingleCompetitionPage
    ? getViewByOptions(isPNCEnabled).filter(option => option.id !== 'view-by-competition')
    : getViewByOptions(isPNCEnabled);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: height + popularMarketsHeight }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    dispatch(
      fetchSportPageDetails({
        page: 0,
        size: device === Devices.DESKTOP ? desktopCompetitionPageSize : undefined,
        data: {
          viewBy,
          timeFilter: timeFilterByCompetition,
          id: pageDetailsId,
          contextFilter
        }
      })
    );
  }, [device, timeFilterByCompetition, sportId, competitionId]);

  const handleSelectViewBy = (newViewBy: DropdownItem) => {
    if (newViewBy.value !== viewBy) {
      if (page !== 0) {
        setPage(0);
      }

      dispatch(
        fetchSportPageDetails({
          page: 0,
          size: device === Devices.DESKTOP ? desktopCompetitionPageSize : undefined,
          data: {
            viewBy: newViewBy.value as ViewBy,
            timeFilter: timeFilterByCompetition,
            id: pageDetailsId,
            contextFilter
          }
        })
      );
      setCookie(CookieNames.COMPETITION_VIEW_BY, newViewBy.value, { path: '/' });
      setViewBy(newViewBy.value as ViewBy);
    }
  };

  useEffect(() => {
    if (page !== 0) {
      setPage(0);
    }
  }, [competitionId]);

  return (
    <div ref={device === Devices.DESKTOP ? componentRef : null} className="biab_competition-page">
      {device === Devices.DESKTOP && (
        <TimeFilters
          viewBy={viewBy}
          timeFilter={timeFilter}
          setTimeFilter={setTimeFilter}
          page={page}
          setPage={setPage}
          pageDetailsId={pageDetailsId}
          pageType={pageType}
          contextFilter={contextFilter}
          isNotRealEvent={isNotRealEvent}
        />
      )}
      {!!markets?.length && <ViewByDropdown viewBy={viewBy} onSelectViewBy={handleSelectViewBy} options={options} />}
      <SportPageSportsSections
        viewBy={viewBy}
        timeFilter={timeFilterByCompetition}
        page={page}
        setPage={setPage}
        pageDetailsId={pageDetailsId}
        contextFilter={contextFilter}
        placementPage={placementPage}
      />
    </div>
  );
};

export default SportPageModule;
