import { TFunction } from 'react-i18next';

import {
  ALL_FANCY_MARKET_TYPES,
  FancyMarketTypes,
  FIRST_INNINGS_FANCY,
  SECOND_INNINGS_FANCY
} from 'constants/fancyMultiMarket';
import { SPORT_BASE_URL } from 'constants/locations';
import { TFancyMarketsSet, TMultiMarketTabDetails } from 'redux/modules/multiMarket/type';
import { TSocketMarketParams } from 'types';
import {
  FancyAndMultiMarketMobileLink,
  IMarket,
  IMarketDescription,
  TFancyMultiMarketData,
  TMarketSportInfo
} from 'types/markets';
import { isLineBettingType } from 'utils/betslip';

export const getIsNoFancyMarket = ({ bettingType, marketType }: IMarketDescription) => {
  return !isLineBettingType(bettingType) || !ALL_FANCY_MARKET_TYPES.includes(marketType);
};

export const getIsFancyMarket = ({ bettingType, marketType }: IMarketDescription) => {
  return isLineBettingType(bettingType) && ALL_FANCY_MARKET_TYPES.includes(marketType);
};

export const filterFancyMarkets = (markets: IMarket[]) => {
  return markets.filter(({ description }) => getIsNoFancyMarket(description));
};

const getIsInningMarkets = (fancyMarketsSet: TFancyMarketsSet) => {
  return (
    !!fancyMarketsSet.inningsFancyCatalogues?.length ||
    !!fancyMarketsSet.inningsFoursCatalogues?.length ||
    !!fancyMarketsSet.inningsSixesCatalogues?.length ||
    !!fancyMarketsSet.batsmenMarketCatalogues?.length ||
    !!fancyMarketsSet.otherLineMarketsCatalogues?.length ||
    !!fancyMarketsSet.fallOfWicketCatalogues?.length ||
    !!fancyMarketsSet.totalInningsRunsCatalogues?.length
  );
};

const isFirstInningsByMarketName = (marketName: string) => {
  return marketName.includes('1st');
};

const isSecondInningsByMarketName = (marketName: string) => {
  return marketName.indexOf('2nd') === 0;
};

const isFirstInningNOverLine = (marketType = '') => {
  return /1_INNING_(\d*)_OVR_LINE/g.test(marketType);
};

const isSecondInningNOverLine = (marketType: string) => {
  return /2_INNING_(\d*)_OVR_LINE/g.test(marketType);
};

const isFirstInningsByMarketType = (marketType: string) => {
  return marketType.includes(FIRST_INNINGS_FANCY) || isFirstInningNOverLine(marketType);
};

const isSecondInningsByMarketType = (marketType: string) => {
  return marketType.includes(SECOND_INNINGS_FANCY) || isSecondInningNOverLine(marketType);
};

const isFirstInnings = (marketType: string, marketName: string) => {
  return marketName
    ? isFirstInningsByMarketName(marketName) || isFirstInningsByMarketType(marketType)
    : isFirstInningsByMarketType(marketType);
};

const isSecondInnings = (marketType: string, marketName: string) => {
  return marketName
    ? isSecondInningsByMarketName(marketName) || isSecondInningsByMarketType(marketType)
    : isSecondInningsByMarketType(marketType);
};

const getFancyAllMarkets = (fancyMarketsSet: TFancyMarketsSet) => {
  let allMarkets: IMarket[] = [];
  const {
    inningsSixesCatalogues,
    inningsFoursCatalogues,
    inningsFancyCatalogues,
    batsmenMarketCatalogues,
    totalInningsRunsCatalogues,
    otherLineMarketsCatalogues,
    fallOfWicketCatalogues
  } = fancyMarketsSet;

  if (inningsFancyCatalogues?.length) {
    allMarkets = allMarkets.concat(inningsFancyCatalogues);
  }

  if (inningsFoursCatalogues?.length) {
    allMarkets = allMarkets.concat(inningsFoursCatalogues);
  }

  if (inningsSixesCatalogues?.length) {
    allMarkets = allMarkets.concat(inningsSixesCatalogues);
  }

  if (batsmenMarketCatalogues?.length) {
    allMarkets = allMarkets.concat(batsmenMarketCatalogues);
  }

  if (fallOfWicketCatalogues?.length) {
    allMarkets = allMarkets.concat(fallOfWicketCatalogues);
  }

  if (totalInningsRunsCatalogues?.length) {
    allMarkets = allMarkets.concat(totalInningsRunsCatalogues);
  }

  if (otherLineMarketsCatalogues?.length) {
    allMarkets = allMarkets.concat(otherLineMarketsCatalogues);
  }

  return allMarkets;
};

export const getFancyMarketsData = (marketInfo: IMarket, activeTab: TMultiMarketTabDetails) => {
  const eventInfo: TMarketSportInfo = {
    id: marketInfo.event.id,
    name: marketInfo.event.name,
    eventTypeId: marketInfo.eventType.id
  };

  const { firstInningsMarkets, secondInningsMarkets } = activeTab;
  const isFirstInningMarkets = getIsInningMarkets(firstInningsMarkets);
  const isSecondInningMarkets = getIsInningMarkets(secondInningsMarkets);
  const isBothInnings = isFirstInningMarkets && isSecondInningMarkets;

  if (isBothInnings) {
    const firstInningAllMarkets = getFancyAllMarkets(firstInningsMarkets);
    const firstInningMarketsParams: TSocketMarketParams[] = firstInningAllMarkets.map(({ marketId, event }) => ({
      marketId,
      eventId: event.id
    }));
    const secondInningAllMarkets = getFancyAllMarkets(secondInningsMarkets);
    const secondInningMarketsParams: TSocketMarketParams[] = secondInningAllMarkets.map(({ marketId, event }) => ({
      marketId,
      eventId: event.id
    }));

    const fancyMarkets: TFancyMultiMarketData[] = [
      {
        id: 1,
        inningsFancy: firstInningsMarkets.inningsFancyCatalogues ?? [],
        inningsFours: firstInningsMarkets.inningsFoursCatalogues ?? [],
        inningsSixes: firstInningsMarkets.inningsSixesCatalogues ?? [],
        totalInningsRuns: firstInningsMarkets.totalInningsRunsCatalogues ?? [],
        batsmen: firstInningsMarkets.batsmenMarketCatalogues ?? [],
        otherLineMarkets: firstInningsMarkets.otherLineMarketsCatalogues ?? [],
        fallOfWicket: firstInningsMarkets.fallOfWicketCatalogues ?? [],
        eventInfo,
        type: FancyMarketTypes.FIRST_INNINGS_FANCY
      },
      {
        id: 2,
        inningsFancy: secondInningsMarkets.inningsFancyCatalogues ?? [],
        inningsFours: secondInningsMarkets.inningsFoursCatalogues ?? [],
        inningsSixes: secondInningsMarkets.inningsSixesCatalogues ?? [],
        totalInningsRuns: secondInningsMarkets.totalInningsRunsCatalogues ?? [],
        batsmen: secondInningsMarkets.batsmenMarketCatalogues ?? [],
        otherLineMarkets: secondInningsMarkets.otherLineMarketsCatalogues ?? [],
        fallOfWicket: secondInningsMarkets.fallOfWicketCatalogues ?? [],
        eventInfo,
        type: FancyMarketTypes.SECOND_INNINGS_FANCY
      }
    ];

    return {
      markets: fancyMarkets,
      marketsParams: firstInningMarketsParams.concat(secondInningMarketsParams)
    };
  } else if (isFirstInningMarkets || isSecondInningMarkets) {
    const type = isFirstInningMarkets ? FancyMarketTypes.FIRST_INNINGS_FANCY : FancyMarketTypes.SECOND_INNINGS_FANCY;

    const currentInningMarkets =
      type === FancyMarketTypes.FIRST_INNINGS_FANCY ? firstInningsMarkets : secondInningsMarkets;

    const allMarkets = getFancyAllMarkets(currentInningMarkets);
    const fancyMarketsParams: TSocketMarketParams[] = allMarkets.map(({ marketId, event }) => ({
      marketId,
      eventId: event.id
    }));

    const fancyMarkets: TFancyMultiMarketData[] = [
      {
        id: 1,
        inningsFancy: currentInningMarkets.inningsFancyCatalogues ?? [],
        inningsFours: currentInningMarkets.inningsFoursCatalogues ?? [],
        inningsSixes: currentInningMarkets.inningsSixesCatalogues ?? [],
        totalInningsRuns: currentInningMarkets.totalInningsRunsCatalogues ?? [],
        batsmen: currentInningMarkets.batsmenMarketCatalogues ?? [],
        otherLineMarkets: currentInningMarkets.otherLineMarketsCatalogues ?? [],
        fallOfWicket: currentInningMarkets.fallOfWicketCatalogues ?? [],
        eventInfo,
        type
      }
    ];

    return { markets: fancyMarkets, marketsParams: fancyMarketsParams };
  }

  return {};
};

export const getMobileFancyNavLinks = (mobileMarketsLinks: IMarket[]) => {
  const links: FancyAndMultiMarketMobileLink[] = [];

  for (let i = 0; i < mobileMarketsLinks.length; i++) {
    const { event, eventType, marketName } = mobileMarketsLinks[i];

    if (links.length === 2) {
      break;
    } else if (marketName.startsWith('1st') && !links.find(({ id }) => id === 1)) {
      links.push({
        to: `${SPORT_BASE_URL}/${eventType.id}/fancyEvent/${event.id}/${FancyMarketTypes.FIRST_INNINGS_FANCY}`,
        titleKey: 'navigation.fancy.markets.FIRST_INNINGS_FANCY',
        id: 1
      });
    } else if (marketName.startsWith('2nd') && !links.find(({ id }) => id === 1)) {
      links.push({
        to: `${SPORT_BASE_URL}/${eventType.id}/fancyEvent/${event.id}/${FancyMarketTypes.SECOND_INNINGS_FANCY}`,
        titleKey: 'navigation.fancy.markets.SECOND_INNINGS_FANCY',
        id: 2
      });
    }
  }

  return links;
};

export const getDesktopMarketTitle = (
  marketName: string,
  description: IMarketDescription,
  isFancyView: boolean,
  t: TFunction
) => {
  if (isFancyView) {
    if (getIsFancyMarket(description)) {
      if (marketName.startsWith('1st')) {
        return t(`navigation.fancy.markets.${FancyMarketTypes.FIRST_INNINGS_FANCY}`);
      } else if (marketName.startsWith('2nd')) {
        return t(`navigation.fancy.markets.${FancyMarketTypes.SECOND_INNINGS_FANCY}`);
      }

      return marketName;
    }

    return marketName;
  }

  return marketName;
};

export const getInningsType = (marketType: string, marketName: string) => {
  const isFirstInningMarkets = isFirstInnings(marketName, marketType);
  const isSecondInningMarkets = isSecondInnings(marketName, marketType);

  return isFirstInningMarkets
    ? 'FIRST_INNINGS_FANCY'
    : isSecondInningMarkets
    ? 'SECOND_INNINGS_FANCY'
    : 'FIRST_INNINGS_FANCY'; // Batsmen exception
};
