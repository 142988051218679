import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { mobileIcons } from 'constants/branding';
import {
  ACCOUNT_BASE_URL,
  ACCOUNT_RESPONSIBLE_BETTING_URL,
  ACCOUNT_STATEMENT_BASE_URL,
  ASIAN_BASE_URL,
  BETTING_PROFIT_LOSS_BASE_URL,
  GAMES_BASE_URL,
  MY_BETS_BASE_URL
} from 'constants/locations';
import { firstPageIndex, MyBetsPeriods } from 'constants/myBets';
import { ACCOUNT_PAGE_ACCOUNT_STATUS_LINK, ACCOUNT_PAGE_BETTING_PL_LINK } from 'constants/tooltip';
import TooltipContext, { TooltipProvider } from 'contexts/tooltipContext';
import useTooltip from 'hooks/useTooltip';
import { getIsResponsibleBettingMenuEnabled, getWindowWidth } from 'redux/modules/appConfigs/selectors';

import styles from './AccountLinksSection.module.scss';

type TAccountLink = {
  icon: string;
  labelKey: string;
  href: string;
  tooltipKey?: string;
};

const getLinks = ({
  showResponsibleBettingLink,
  exchangePage
}: {
  showResponsibleBettingLink: boolean;
  exchangePage: string;
}) => {
  const links: TAccountLink[] = [
    {
      icon: 'biab_custom-icon-my-bets',
      labelKey: 'account.mybets.title',
      href: `${exchangePage}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${MyBetsPeriods.Open.toLowerCase()}/${firstPageIndex}`
    },
    {
      icon: 'biab_custom-icon-betting-pl',
      labelKey: 'accountNavigation.profitandloss',
      href: `${exchangePage}${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/${firstPageIndex}`,
      tooltipKey: ACCOUNT_PAGE_BETTING_PL_LINK
    },
    {
      icon: 'biab_custom-icon-account-statement',
      labelKey: 'account.statement.title',
      href: `${exchangePage}${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/${firstPageIndex}`,
      tooltipKey: ACCOUNT_PAGE_ACCOUNT_STATUS_LINK
    }
  ];

  if (showResponsibleBettingLink) {
    return [
      ...links,
      {
        icon: 'biab_custom-icon-responsible-betting',
        labelKey: 'responsibleBetting.labels.responsibleBetting',
        href: `${exchangePage}${ACCOUNT_RESPONSIBLE_BETTING_URL}`
      }
    ];
  }

  return links;
};

const AccountLinksSection = () => {
  const location = useLocation();

  const showResponsibleBettingLink = useSelector(getIsResponsibleBettingMenuEnabled);
  const width = useSelector(getWindowWidth);

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const isGamesPage = location.pathname.includes(GAMES_BASE_URL);
  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || '';
  const links = getLinks({ showResponsibleBettingLink, exchangePage });

  return (
    <div
      className={classNames(styles.links, {
        [styles.links__oneColumn]: width >= 768 || !showResponsibleBettingLink
      })}
    >
      {links.map(link => {
        if (link.tooltipKey) {
          return <AccountLinkWithTooltip key={link.href} link={link} />;
        }

        return <AccountLink key={link.href} link={link} />;
      })}
    </div>
  );
};

type AccountLinkProps = {
  link: TAccountLink;
};

function AccountLink({ link }: AccountLinkProps) {
  const { t } = useTranslation();

  const { tooltipKey = '' } = useContext(TooltipContext);
  const { isEnabled, translationKey, isShowIcon } = useTooltip(tooltipKey);

  return (
    <NavLink to={link.href} className={styles.links__link}>
      <i className={classNames(link.icon, styles.links__link__icon)} />
      <p className={styles.links__link__label}>{t(link.labelKey)}</p>
      {isEnabled && translationKey && isShowIcon && (
        <i
          onClick={e => e.preventDefault()}
          data-tooltip-id={'tooltip'}
          data-tooltip-html={unescape(t(translationKey))}
          data-tooltip-class-name={styles.tooltip}
          className={classNames('biab_custom-icon-info-24', styles.links__link__icon__tooltip, mobileIcons.TOOLTIP)}
        >
          <span className={classNames('path1', mobileIcons.TOOLTIP_BG)} />
          <span className={classNames('path2', mobileIcons.TOOLTIP_INNER)} />
          <span className={classNames('path3', mobileIcons.TOOLTIP_INNER)} />
        </i>
      )}
    </NavLink>
  );
}

function AccountLinkWithTooltip({ link }: AccountLinkProps) {
  const { isEnabled, translationKey } = useTooltip(link.tooltipKey!);

  return (
    <TooltipProvider value={{ isEnabled, translationKey, tooltipKey: link.tooltipKey }}>
      <AccountLink link={link} />
    </TooltipProvider>
  );
}

export default AccountLinksSection;
