import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { CookieNames } from 'constants/app';
import {
  ExchangeTypes,
  ExchangeTypeTabButtons,
  mapAccountPageToBetsType,
  PLExchangeTypes,
  PLTabButtons
} from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { getAccountProductsByBetsType } from 'redux/modules/myBets/selectors';

const useAccountProducts = () => {
  const { selectedTab, isProfitLossPage } = useAccountUrlParams();
  const betsType = mapAccountPageToBetsType[selectedTab];
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW, CookieNames.PROFITANDLOSS_BETTYPE]);

  const accountProducts = useSelector(getAccountProductsByBetsType(betsType));

  const isAsianViewActive = accountProducts?.asianView;
  const isSportsGamesActive = accountProducts?.sports || accountProducts?.games;
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);

  const isSportsEnabled = isExchangeSportsEnabled || !!accountProducts?.sports;
  const isGamesEnabled = isExchangeGamesEnabled || !!accountProducts?.games;
  const isSportsGamesEnabled = isSportsEnabled || isGamesEnabled;

  const isProductDropdownEnabled =
    (isAsianViewActive && isSportsGamesActive) || (isProfitLossPage ? isGamesEnabled && isSportsEnabled : false);

  const availableProducts = (isProfitLossPage ? PLTabButtons : ExchangeTypeTabButtons).filter(tab => {
    return (
      (isAsianViewActive && (tab === ExchangeTypes.AsianView || tab === PLExchangeTypes.AsianView)) ||
      (isGamesEnabled && (tab === ExchangeTypes.SportsGames || tab === PLExchangeTypes.Games)) ||
      (isSportsEnabled && (tab === ExchangeTypes.SportsGames || tab === PLExchangeTypes.Sports))
    );
  });

  const selectedCookieProduct = isProfitLossPage ? cookies.PROFITANDLOSS_BETTYPE : cookies.EXCHANGE_TYPE_NEW;

  const isAsianViewSelected =
    (isAsianViewEnabled || isAsianViewActive) &&
    (selectedCookieProduct || availableProducts[0]) === ExchangeTypes.AsianView;

  const selectedProduct = availableProducts.includes(selectedCookieProduct)
    ? selectedCookieProduct
    : availableProducts[0];

  return {
    isAsianViewEnabled,
    isSportsGamesActive,
    isAsianViewActive,
    isAsianViewSelected,
    isSportsEnabled,
    isGamesEnabled,
    isSportsGamesEnabled,
    isProductDropdownEnabled,
    availableProducts,
    selectedProduct: accountProducts ? selectedProduct : undefined,
    defaultProduct: availableProducts[0]
  };
};

export default useAccountProducts;
