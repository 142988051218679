import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { sortBy, uniqBy } from 'lodash';
import { useIntersectionObserver } from 'usehooks-ts';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import AsianViewInPlayCell from 'components/AsianViewInPlayCell';
import AsianViewTabs from 'components/AsianViewPageModule/components/AsianViewTabs';
import CashOut from 'components/CashOut';
import MatchStatistics from 'components/MatchStatistics';
import MatchStatisticsWidgetContent from 'components/MobileEventWidgets/components/MatchStatisticsWidgetContent';
import VideoStreamingWidgetContent from 'components/MobileEventWidgets/components/VideoStreamingWidgetContent';
import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import VideoStream from 'components/VideoStream';
import { FAVORITES_TYPES } from 'constants/app';
import { AsianViewSections } from 'constants/asianView';
import { asianViewIcons, asianViewMiddleSection } from 'constants/branding';
import { RunnersStatuses } from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import useCustomResizeObserver from 'hooks/useCustomResizeObserver';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getIsMobileInfiniteScrollEnabled, getLanguage, getTranslation } from 'redux/modules/appConfigs/selectors';
import { setClosedEventId, setOpenEventId } from 'redux/modules/asianView';
import { getCountMarketsById, getIsLandscapeAsianView, getOpenEventId } from 'redux/modules/asianView/selectors';
import { TAsianViewEvent, TAsianViewMarket } from 'redux/modules/asianView/type';
import { getAsianViewCashOutCounterByEventId } from 'redux/modules/asianViewCashOutCounter/selectors';
import { getIsAVCurrentBetByEventId } from 'redux/modules/asianViewCurrentBets/selectors';
import { AsianViewFavouritePayload } from 'redux/modules/asianViewFavourites/type';
import { closeLiveVideo, closeMatchStatistics, setLiveVideo, setMatchStatistics } from 'redux/modules/asianViewWidgets';
import { getLiveVideoById, getMatchStatisticsById } from 'redux/modules/asianViewWidgets/selectors';
import {
  getClosedMarketsIds,
  getHighlightedSelectionByMarketId,
  getInPlayMarketsIds,
  getIsMarketPricesKeyLineDefinition,
  getMarketPricesFirstKeyLineHandicap,
  getMarketPricesFirstKeyLineId,
  getMarketPricesRunners,
  getMarketPricesSecondKeyLineId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { FTSL, TMarketPricesRunner } from 'types/markets';
import { getHandicapLine } from 'utils/avMarketCell';

import ColumnName from '../CompetitionTitle/ColumnName';

import AdditionalSelections from './AdditionalSelections';

import styles from './styles.module.scss';

const getIsRunnersByIndex = ({
  index,
  market,
  runnerStatuses,
  isKeyLine,
  firstKeyLineHandicap,
  firstKeyLineId,
  secondKeyLineId
}: {
  index: number;
  market?: TAsianViewMarket;
  runnerStatuses: TMarketPricesRunner[];
  isKeyLine: boolean;
  firstKeyLineHandicap?: number;
  firstKeyLineId?: number;
  secondKeyLineId?: number;
}) => {
  const allRunners = market?.runners ? [...market.runners] : [];
  const runners = allRunners.filter(runner => {
    const curRunnerStatus = runnerStatuses.find(runnerStatus => {
      return +runner.id === runnerStatus.id && runner.handicap === runnerStatus.handicap;
    });
    return !curRunnerStatus || curRunnerStatus?.status === RunnersStatuses.ACTIVE;
  });
  const isGoalLines = market?.marketType === MARKET_TYPES.altTotalGoals;
  const isAsianHandicap = market?.marketType === MARKET_TYPES.asianHandicap;

  if (isAsianHandicap || isGoalLines) {
    if (isKeyLine) {
      const homeHandicap = firstKeyLineHandicap || 0;
      const hasNextLine = !!getHandicapLine({
        runners,
        handicap: homeHandicap + 0.25,
        isAsianHandicap,
        firstKeyLineId,
        secondKeyLineId
      }).length;
      const hasPrevLine = !!getHandicapLine({
        runners,
        handicap: homeHandicap - 0.25,
        isAsianHandicap,
        firstKeyLineId,
        secondKeyLineId
      }).length;

      const lineIndex =
        (index === 1 && !hasNextLine) || (index === 2 && hasPrevLine && hasNextLine)
          ? -1
          : index === 2 && !hasNextLine
          ? -2
          : index;

      return !!getHandicapLine({
        runners,
        handicap: homeHandicap + 0.25 * lineIndex,
        isAsianHandicap,
        firstKeyLineId,
        secondKeyLineId
      }).length;
    }

    return !!runners.slice(0, 2).length;
  }

  return !!runners.length;
};

interface MobileEventProps {
  event: TAsianViewEvent;
  sportId: string;
  competitionId: string;
  onRemoveFavorite?: (favourite?: AsianViewFavouritePayload) => void;
}

const MobileEvent = ({ event, sportId, competitionId, onRemoveFavorite }: MobileEventProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const language = useSelector(getLanguage);
  const closedMarketIds = useSelector(getClosedMarketsIds);
  const inPlayMarketsIds = useSelector(getInPlayMarketsIds);
  const marketsCounter = useSelector(getCountMarketsById(event.id));
  const translation = useSelector(getTranslation);
  const openEventId = useSelector(getOpenEventId);
  const cashOutCounter = useSelector(getAsianViewCashOutCounterByEventId(sportId, event.id));
  const isCurrentBet = useSelector(getIsAVCurrentBetByEventId(event.id));
  const matchStatisticsWidget = useSelector(getMatchStatisticsById(event.id));
  const liveVideoWidget = useSelector(getLiveVideoById(event.id));
  const isInfiniteScrollEnabled = useSelector(getIsMobileInfiniteScrollEnabled);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isFullTimeActive, setIsFullTimeActive] = useState(true);

  const isShowTab = openEventId && openEventId.toString() === event.id;
  const { markets, matchOdds, halfTime, goalLines, asianHandicap, firstHalfGoalsMarkets } = useMemo(() => {
    const eventMarkets = Object.values(event.sections).flat();
    const activeMarkets = (eventMarkets || []).filter(market => !closedMarketIds.includes(market.id));
    return {
      markets: activeMarkets,
      matchOdds: activeMarkets.find(market => market.marketType === MARKET_TYPES.matchOdds),
      halfTime: activeMarkets.find(market => market.marketType === MARKET_TYPES.halfTime),
      goalLines: activeMarkets.find(market => market.marketType === MARKET_TYPES.altTotalGoals),
      asianHandicap: activeMarkets.find(market => market.marketType === MARKET_TYPES.asianHandicap),
      firstHalfGoalsMarkets: sortBy(
        uniqBy(activeMarkets, market => market.id),
        ['marketType']
      ).filter(market => market.marketType.includes(MARKET_TYPES.firstHalfGoals))
    };
  }, [event.sections, closedMarketIds]);

  const topEventSections = event.sections[AsianViewSections.TOP];
  const showCashOut = useMemo(() => {
    return topEventSections?.some(({ cashOutEnabled }) => cashOutEnabled);
  }, [topEventSections]);

  const containerRef = useRef<HTMLDivElement>(null);

  const { ref, isIntersecting } = useIntersectionObserver({ rootMargin: '600px' });
  const { height } = useCustomResizeObserver({ ref: containerRef });

  const highlightedSelection = useSelector(getHighlightedSelectionByMarketId(matchOdds ? matchOdds.id : ''));
  const asianHandicapFirstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(asianHandicap?.id));
  const asianHandicapFirstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(asianHandicap?.id));
  const asianHandicapSecondKeyLineId = useSelector(getMarketPricesSecondKeyLineId(asianHandicap?.id));
  const isAsianHandicapKeyLine = useSelector(getIsMarketPricesKeyLineDefinition(asianHandicap?.id));
  const goalLinesFirstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(goalLines?.id));
  const goalLinesFirstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(goalLines?.id));
  const goalLinesSecondKeyLineId = useSelector(getMarketPricesSecondKeyLineId(goalLines?.id));
  const isGoalLinesKeyLine = useSelector(getIsMarketPricesKeyLineDefinition(goalLines?.id));
  const asianHandicapRunnerStatuses = useSelector(getMarketPricesRunners(asianHandicap?.id));
  const goalLinesRunnerStatuses = useSelector(getMarketPricesRunners(goalLines?.id));

  const { isAHFirstKLRunners, isAHSecondKLRunners } = useMemo(() => {
    return {
      isAHFirstKLRunners: getIsRunnersByIndex({
        index: 1,
        market: asianHandicap,
        runnerStatuses: asianHandicapRunnerStatuses,
        isKeyLine: isAsianHandicapKeyLine,
        firstKeyLineHandicap: asianHandicapFirstKeyLineHandicap,
        secondKeyLineId: asianHandicapSecondKeyLineId,
        firstKeyLineId: asianHandicapFirstKeyLineId
      }),
      isAHSecondKLRunners: getIsRunnersByIndex({
        index: 2,
        market: asianHandicap,
        runnerStatuses: asianHandicapRunnerStatuses,
        isKeyLine: isAsianHandicapKeyLine,
        firstKeyLineHandicap: asianHandicapFirstKeyLineHandicap,
        secondKeyLineId: asianHandicapSecondKeyLineId,
        firstKeyLineId: asianHandicapFirstKeyLineId
      })
    };
  }, [
    asianHandicapFirstKeyLineHandicap,
    asianHandicapFirstKeyLineId,
    asianHandicapSecondKeyLineId,
    asianHandicap,
    isAsianHandicapKeyLine,
    asianHandicapRunnerStatuses
  ]);

  const { isGLFirstKLRunners, isGLSecondKLRunners } = useMemo(() => {
    return {
      isGLFirstKLRunners: getIsRunnersByIndex({
        index: 1,
        market: goalLines,
        runnerStatuses: goalLinesRunnerStatuses,
        isKeyLine: isGoalLinesKeyLine,
        firstKeyLineHandicap: goalLinesFirstKeyLineHandicap,
        firstKeyLineId: goalLinesFirstKeyLineId,
        secondKeyLineId: goalLinesSecondKeyLineId
      }),
      isGLSecondKLRunners: getIsRunnersByIndex({
        index: 2,
        market: goalLines,
        runnerStatuses: goalLinesRunnerStatuses,
        isKeyLine: isGoalLinesKeyLine,
        firstKeyLineHandicap: goalLinesFirstKeyLineHandicap,
        firstKeyLineId: goalLinesFirstKeyLineId,
        secondKeyLineId: goalLinesSecondKeyLineId
      })
    };
  }, [
    goalLinesFirstKeyLineHandicap,
    goalLinesFirstKeyLineId,
    goalLinesSecondKeyLineId,
    goalLines,
    isGoalLinesKeyLine,
    goalLinesRunnerStatuses
  ]);

  const home = event.homeTeamTranslations ? event.homeTeamTranslations[translation] : '';
  const away = event.awayTeamTranslations ? event.awayTeamTranslations[translation] : '';
  const currentDate = new Date().getTime();
  const isStartingSoon = event.startTime < currentDate;
  const isInPlay = markets.filter(market => inPlayMarketsIds.includes(market.id)).length > 0;
  const isVisible = !!home && !!away && !!language;
  const hasFirstHalf = !!((firstHalfGoalsMarkets && firstHalfGoalsMarkets[0]?.id) || halfTime?.id);
  const hasFirstTabMarkets = isFullTimeActive || !hasFirstHalf || isLandscapeView;
  const hasSecondTabMarkets = (!isFullTimeActive || isLandscapeView) && hasFirstHalf;
  const visibleMarketId =
    (hasFirstTabMarkets && (matchOdds?.id || asianHandicap?.id || goalLines?.id)) ||
    (hasSecondTabMarkets && (halfTime?.id || (firstHalfGoalsMarkets && firstHalfGoalsMarkets[0]?.id)));
  const scoreMarketId = visibleMarketId || markets[0]?.id;
  const hasVisibleMarkets = !!visibleMarketId;
  const areRunners =
    isLandscapeView || isFullTimeActive
      ? isAHFirstKLRunners || isAHSecondKLRunners || isGLFirstKLRunners || isGLSecondKLRunners
      : !!firstHalfGoalsMarkets[1]?.runners.length || !!firstHalfGoalsMarkets[2]?.runners.length;
  const hasMoreLines = (!!asianHandicap || !!goalLines || hasFirstHalf) && areRunners;
  const additionalSelectionsProps = {
    asianHandicap,
    goalLines,
    matchOdds,
    halfTime,
    firstHalfGoalsMarkets,
    competitionId,
    eventId: event.id,
    isFullTimeActive
  };
  const modalClassNames = {
    header: styles.modal__header,
    body: styles.modal__body,
    content: classNames(styles.modal__content, {
      [styles.modal__content__landscape]: isLandscapeView
    }),
    title: styles.modal__title
  };
  const isHomeTeamHighlighted = highlightedSelection == FTSL.HOME;

  const isAllBottomButtons = isCurrentBet && event.videoStreamingEnabled && event.matchStatEnabled;

  const { ref: eventRef } = useMarketsPricesVisibleSocketParam({
    marketId: hasVisibleMarkets ? undefined : scoreMarketId,
    eventId: hasVisibleMarkets ? undefined : event.id,
    observerOptions: { rootMargin: '200px' },
    section: MarketsPricesSocketParamsSections.AsianViewMiddleSection
  });

  const handlerOpenTab = () => {
    dispatch(setOpenEventId(isShowTab ? null : event.id));
  };

  const setLiveVideoWidget = () => dispatch(setLiveVideo(event.id));
  const setMatchStatisticsWidget = () => dispatch(setMatchStatistics(event.id));
  const closeWidgets = () => {
    if (matchStatisticsWidget) {
      dispatch(closeMatchStatistics(event.id));
    }

    if (liveVideoWidget) {
      dispatch(closeLiveVideo(event.id));
    }
  };

  useEffect(() => {
    if (markets.length === 0) {
      dispatch(setClosedEventId(event.id));
    }
  }, [markets.length]);

  useEffect(() => {
    ref(containerRef.current);

    return () => {
      if (isShowTab) {
        dispatch(setOpenEventId(null));
      }

      closeWidgets();
    };
  }, []);

  return (
    <div ref={containerRef}>
      {!isIntersecting && <div style={{ height }} />}
      {isVisible && isIntersecting && (
        <div
          data-market-id={scoreMarketId}
          data-event-id={event.id}
          data-market-prices={!hasVisibleMarkets}
          ref={!hasVisibleMarkets ? eventRef : undefined}
          className={classNames('biab_asian-view-event', styles.mobileEvent, {
            [styles.mobileEvent__inPlay]: isInPlay,
            [styles.mobileEvent__startingSoon]: isStartingSoon && !isInPlay,
            [styles.mobileEvent__lastItemMargin]: isInfiniteScrollEnabled
          })}
        >
          <div
            className={classNames('biab_asian-view-event-header', styles.mobileEvent__header, {
              biab_live: isInPlay
            })}
          >
            <AsianViewInPlayCell
              marketStartTime={event.startTime}
              containerClassName={styles.score}
              marketId={scoreMarketId}
              hideIcon
            />
            <div className={classNames('biab_asian-view-event-selections-wrapper', styles.mobileEvent__selections)}>
              <div
                className={classNames('biab_asian-view-event-selections-names', styles.mobileEvent__selections__names)}
              >
                <span
                  className={classNames({
                    biab_active: isHomeTeamHighlighted,
                    [styles.active]: isHomeTeamHighlighted
                  })}
                >
                  {home}
                </span>{' '}
                {t('asianView.labels.v')}{' '}
                <span
                  className={classNames({
                    biab_active: !isHomeTeamHighlighted,
                    [styles.active]: !isHomeTeamHighlighted
                  })}
                >
                  {away}
                </span>
              </div>
              <div className={classNames('biab_asian-view-event-icons', styles.mobileEvent__header__icons)}>
                <AsianViewFavouriteStar
                  favourite={{
                    entryType: FAVORITES_TYPES.event,
                    sportId,
                    entryId: event.id,
                    entryName: event.translations[translation]
                  }}
                  onRemoveFavorite={onRemoveFavorite}
                />
                {showCashOut && (
                  <CashOut
                    showLabel={false}
                    showInfoIcon={false}
                    className={{ container: styles.event__cashout }}
                    disabled={!cashOutCounter}
                    modalClassNames={modalClassNames}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={classNames('biab_asian-view-event-markets-headers', styles.mobileEvent__headers)}>
            <div
              onClick={isLandscapeView ? undefined : () => setIsFullTimeActive(true)}
              className={classNames('biab_asian-view-event-markets-header', styles.mobileEvent__headers__fullTime, {
                biab_tab: !isLandscapeView && hasFirstHalf,
                biab_active: !isLandscapeView && hasFirstHalf && isFullTimeActive,
                [styles.mobileEvent__headers__noTab]: !(!isLandscapeView && hasFirstHalf),
                [styles.mobileEvent__headers__tab]: !isLandscapeView && hasFirstHalf,
                [styles.mobileEvent__headers__tab__active]: !isLandscapeView && hasFirstHalf && isFullTimeActive
              })}
            >
              {t('asianView.labels.column.fullTime')}
            </div>
            {hasFirstHalf && (
              <div
                onClick={isLandscapeView ? undefined : () => setIsFullTimeActive(false)}
                className={classNames('biab_asian-view-event-markets-header', styles.mobileEvent__headers__firstHalf, {
                  biab_tab: !isLandscapeView,
                  biab_active: !isLandscapeView && !isFullTimeActive,
                  [styles.mobileEvent__headers__noTab]: isLandscapeView,
                  [styles.mobileEvent__headers__tab]: !isLandscapeView,
                  [styles.mobileEvent__headers__tab__leftBorder]:
                    !(!isLandscapeView && !isFullTimeActive) && !isLandscapeView,
                  [styles.mobileEvent__headers__tab__active]: !isLandscapeView && !isFullTimeActive
                })}
              >
                {t('asianView.labels.column.firstHalf')}
              </div>
            )}
          </div>
          <div className={classNames('biab_asian-view-event-markets-col', styles.mobileEvent__markets__columns)}>
            {hasFirstTabMarkets && (
              <>
                <ColumnName
                  name={t('asianView.labels.column.HDP')}
                  marketId={asianHandicap?.id || null}
                  modalClasses={modalClassNames}
                />
                <ColumnName
                  name={t('asianView.labels.column.OU')}
                  marketId={goalLines?.id || null}
                  modalClasses={modalClassNames}
                />
                <ColumnName
                  name={t('asianView.labels.column.1X2')}
                  marketId={matchOdds?.id || null}
                  modalClasses={modalClassNames}
                />
              </>
            )}
            {hasSecondTabMarkets && (
              <>
                <ColumnName
                  name={t('asianView.labels.column.OU')}
                  marketId={(firstHalfGoalsMarkets && firstHalfGoalsMarkets[0]?.id) || null}
                  modalClasses={modalClassNames}
                />
                <ColumnName
                  name={t('asianView.labels.column.1X2')}
                  marketId={halfTime?.id || null}
                  modalClasses={modalClassNames}
                />
              </>
            )}
          </div>
          <div
            className={classNames('biab_av-event-selections-wrapper', styles.eventSelectionsWrapper, {
              biab_live: isInPlay
            })}
          >
            {hasVisibleMarkets && (
              <>
                <AdditionalSelections {...additionalSelectionsProps} selectionsOrder={0} />
                {isExpanded && areRunners && (
                  <>
                    <AdditionalSelections {...additionalSelectionsProps} selectionsOrder={1} />
                    <AdditionalSelections {...additionalSelectionsProps} selectionsOrder={2} />
                  </>
                )}
              </>
            )}

            <div className={classNames('biab_asian-view-event-bottom', styles.mobileEvent__bottom)}>
              <div
                className={classNames(styles.mobileEvent__streaming, {
                  [styles.mobileEvent__streaming__compact]: isAllBottomButtons
                })}
              >
                {isCurrentBet && <ProfitLossTablePopUpButton eventId={event.id} marketId={markets[0]?.id} />}
                {event.videoStreamingEnabled && (
                  <VideoStream
                    hideLabel
                    visible={event.videoStreamingEnabled}
                    isInHeader
                    eventId={event.id}
                    hideCollapseIcon
                    buttonClassName={classNames(styles.mobileEvent__streaming__button, 'biab_streaming-icon-wrapper')}
                    iconClassName={styles.mobileEvent__streaming__icon}
                    tooltipClassName={styles.mobileEvent__streaming__tooltip}
                    bgClassName={classNames(
                      liveVideoWidget ? styles.mobileEvent__streaming__bgActive : styles.mobileEvent__streaming__bg,
                      {
                        biab_active: liveVideoWidget
                      }
                    )}
                    setOpenedWidgetTab={setLiveVideoWidget}
                  />
                )}
                {event.matchStatEnabled && (
                  <MatchStatistics
                    hideLabel
                    isSoccer
                    visible={event.matchStatEnabled}
                    isInHeader
                    eventId={event.id}
                    hideCollapseIcon
                    buttonClassName={classNames(
                      styles.mobileEvent__streaming__button,
                      styles.mobileEvent__statistics__button
                    )}
                    iconClassName={styles.mobileEvent__streaming__icon}
                    tooltipClassName={styles.mobileEvent__streaming__tooltip}
                    bgClassName={classNames(
                      matchStatisticsWidget
                        ? styles.mobileEvent__streaming__bgActive
                        : styles.mobileEvent__streaming__bg,
                      {
                        biab_active: matchStatisticsWidget
                      }
                    )}
                    setOpenedWidgetTab={setMatchStatisticsWidget}
                  />
                )}
              </div>
              {hasMoreLines && (
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className={classNames('biab_asian-view-event-expand', styles.mobileEvent__expand)}
                >
                  {t(`asianView.labels.${isExpanded ? 'less' : 'more'}Lines`)}
                  <i
                    className={classNames('fa2 biab_expand', {
                      'fa2-arrow-up': isExpanded,
                      'fa2-arrow-down': !isExpanded
                    })}
                  />
                </button>
              )}
              {marketsCounter > 0 && (
                <div
                  className={classNames(styles.mobileEvent__moreMarketsWrapper, asianViewIcons.EXPAND_EVENT_ICON, {
                    [styles.mobileEvent__moreMarketsWrapper__compact]: isAllBottomButtons
                  })}
                  onClick={handlerOpenTab}
                >
                  <div
                    className={classNames('biab_asian-view-more-markets', styles.mobileEvent__moreMarkets, {
                      [styles.mobileEvent__moreMarkets__opened]: isShowTab
                    })}
                  >
                    {marketsCounter}
                    <i className={classNames(`fa2 fa2-sort-${isShowTab ? 'up' : 'down'}`)} />
                  </div>
                </div>
              )}
            </div>
            {(liveVideoWidget || matchStatisticsWidget) && (
              <div className={classNames(styles.widgetHeader, asianViewMiddleSection.WIDGET_HEADER)}>
                <span>{liveVideoWidget ? t('video.streaming.live') : t('match.statistics')}</span>
                <i className={classNames('biab_tour-icon-close', styles.widgetHeader__icon)} onClick={closeWidgets} />
              </div>
            )}
            {liveVideoWidget && <VideoStreamingWidgetContent />}
            {matchStatisticsWidget && <MatchStatisticsWidgetContent eventId={event.id} />}
            {isShowTab && <AsianViewTabs eventId={event.id} sportId={sportId} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileEvent;
