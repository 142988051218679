import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { debounce } from 'lodash';

import TourModalContainer from 'components/HowToSection/components/TourModalContainer';
import Modal from 'components/Modal';
import WelcomePopup from 'components/WelcomePopup';
import { Devices } from 'constants/app';
import { welcomeFadeAnimation } from 'constants/webTours';
import { USUAL_CLOSE_BEHAVIOUR } from 'constants/webTours';
import { CLOSE_BACKDROP_BEHAVIOUR } from 'constants/webTours';
import useWebTours from 'hooks/useWebTours';
import {
  getAppDevice,
  getHideToursFadePeriodMs,
  getIsLandscape,
  getNewCustomerCookieName
} from 'redux/modules/appConfigs/selectors';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

const WelcomeNewUserComponent = () => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const hideToursFadePeriodMs = useSelector(getHideToursFadePeriodMs);
  const newCustomerCookieName = useSelector(getNewCustomerCookieName);
  const isLandscape = useSelector(getIsLandscape);

  const [, setCookie] = useCookies([newCustomerCookieName]);

  const [withBackdrop, setWithBackdrop] = useState<boolean>(false);
  const [closeWelcomePopup, setCloseWelcomePopup] = useState<string>('');
  const [isWelcomeOpen, setIsWelcomeOpen] = useState<boolean>(true);
  const [showOverlayBlock, setShowOverlayBlock] = useState<boolean>(false);

  const {
    selectedTour,
    setSelectedTour,
    openTourModal,
    setOpenTourModal,
    changeSelectedTour,
    handleStartTour,
    tourLoading
  } = useWebTours();

  const isMobile = device === Devices.MOBILE;
  const showBackdropWithoutAnimation = withBackdrop && !openTourModal;
  const isShowLandscapeWarning = isLandscape && isMobile;

  useEffect(() => {
    if (showOverlayBlock) {
      const overlayExistenceTime = welcomeFadeAnimation + hideToursFadePeriodMs;
      const timer = setTimeout(() => setShowOverlayBlock(false), overlayExistenceTime);

      return () => clearTimeout(timer);
    }
  }, [hideToursFadePeriodMs, showOverlayBlock]);

  const clearNewCustomerCookie = () => {
    setCookie(newCustomerCookieName, null, {
      path: getEnvironmentRootPath(),
      expires: new Date(0)
    });
  };

  useEffect(() => {
    if (closeWelcomePopup === USUAL_CLOSE_BEHAVIOUR) {
      const timer = setTimeout(() => {
        setIsWelcomeOpen(false);
        setCloseWelcomePopup('');
        setShowOverlayBlock(true);
        clearNewCustomerCookie();
      }, 400);

      return () => clearTimeout(timer);
    }
    if (closeWelcomePopup === CLOSE_BACKDROP_BEHAVIOUR) {
      const timer = setTimeout(() => {
        setIsWelcomeOpen(false);
        setWithBackdrop(true);
        setCloseWelcomePopup('');
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [closeWelcomePopup]);

  useEffect(() => {
    if (withBackdrop) {
      handleStartTour();
    }
  }, [withBackdrop]);

  const handleCloseWelcomePopup = () => {
    setCloseWelcomePopup(USUAL_CLOSE_BEHAVIOUR);
  };

  const handleCloseModal = () => {
    setWithBackdrop(false);
    setSelectedTour(undefined);
    setOpenTourModal(false);
    setShowOverlayBlock(true);
    clearNewCustomerCookie();
  };

  const handleLearnMore = () => {
    setCloseWelcomePopup(CLOSE_BACKDROP_BEHAVIOUR);
  };

  const debouncedCloseWelcomePopup = debounce(handleCloseWelcomePopup, 500);

  const debouncedLearnMore = debounce(handleLearnMore, 500);

  return (
    <>
      <TourModalContainer
        tourLoading={tourLoading}
        onClose={handleCloseModal}
        tourHeader={selectedTour?.tourHeader as string}
        tourId={selectedTour?.id as number}
        openTourModal={openTourModal}
        setOpenTourModal={setOpenTourModal}
        changeSelectedTour={changeSelectedTour}
        fromWelcomePopup={isMobile}
        noFadeIn={withBackdrop}
      />
      <Modal
        isDisableScroll
        open={isWelcomeOpen}
        onClose={debouncedCloseWelcomePopup}
        title={t('dialog.welcome.title')}
        customClassNames={{
          body: styles.welcomeModalBody,
          dialog: styles.welcomeModal,
          container: classNames({ [styles.welcomeModalFadeout]: closeWelcomePopup }),
          backdrop: classNames({ [styles.hideModalBackdrop]: closeWelcomePopup }),
          header: styles.welcomeModalHeader,
          headerContent: styles.welcomeModalHeaderContent
        }}
      >
        <WelcomePopup onLearnMore={debouncedLearnMore} isLandscape={isShowLandscapeWarning} />
      </Modal>
      {showOverlayBlock && (
        <div
          style={{ animationDelay: `${hideToursFadePeriodMs * 0.001}s` }}
          className={classNames(styles.backdrop, styles.withAnimation)}
        />
      )}
      {(isWelcomeOpen || showBackdropWithoutAnimation) && (
        <div
          onClick={handleCloseWelcomePopup}
          className={classNames(styles.backdrop, {
            [styles.overlapHowTo]: !isWelcomeOpen && !isMobile,
            [styles.overlapMobileMenu]: !isWelcomeOpen && isMobile,
            [styles.backdropFadein]: isWelcomeOpen
          })}
        />
      )}
    </>
  );
};

export default WelcomeNewUserComponent;
