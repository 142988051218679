import { useRef } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AV_LANDSCAPE_LEFT_PANEL_WIDTH } from 'constants/asianView';
import { BET_RADAR_VIDEO_PLAYER_HEIGHT_COEFFICIENT } from 'constants/videoStream';
import { getWindowWidth } from 'redux/modules/appConfigs/selectors';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getBetRadarPlayerUrl, getHlsPlayerData, getVideoStreamError } from 'redux/modules/videoStream/selectors';

import styles from './styles.module.scss';

const VideoStreamingWidgetContent = () => {
  const { t } = useTranslation();

  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const { hlsUrl } = useSelector(getHlsPlayerData);
  const betRadarUrl = useSelector(getBetRadarPlayerUrl);
  const error = useSelector(getVideoStreamError);
  const windowWidth = useSelector(getWindowWidth);

  const playerRef = useRef<HTMLVideoElement>(null);

  const iFrameWidth = isLandscapeAsianView ? windowWidth - AV_LANDSCAPE_LEFT_PANEL_WIDTH : windowWidth;

  const getPlayerContent = () => {
    if (!isLoggedIn) {
      return <p className={styles.playerWrapper__error}>{t('video.streaming.messages.login')}</p>;
    } else {
      if (error) {
        return <p className={styles.playerWrapper__error}>{t('video.streaming.messages.unavailable')}</p>;
      } else if (hlsUrl) {
        return (
          <ReactHlsPlayer src={hlsUrl} playerRef={playerRef} autoPlay={false} controls width="100%" height="auto" />
        );
      } else if (betRadarUrl) {
        return (
          <iframe
            src={betRadarUrl}
            width={iFrameWidth}
            height={Math.round(iFrameWidth * BET_RADAR_VIDEO_PLAYER_HEIGHT_COEFFICIENT)}
            className={styles.playerWrapper__frame}
          />
        );
      }
    }
  };

  return <div className={classNames('biab_video-streaming-wrapper', styles.playerWrapper)}>{getPlayerContent()}</div>;
};

export default VideoStreamingWidgetContent;
