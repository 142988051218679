import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import Calculators from 'components/Calc/Calculators';
import Icon from 'components/Icon';
import { educationBranding } from 'constants/branding';
import { EDUCATIONAL_PORTAL_GUIDES_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import ArticlesList from 'pages/EducationalPortal/components/ArticlesList/ArticlesList';
import api from 'redux/api/methods';
import { IArticle } from 'types/educationalPortal';

import styles from './styles.module.scss';

const EducationalPortalGuidePage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useDevice();
  const urlParams = useParams();

  const [articleData, setArticleData] = useState<IArticle | null>(null);

  useEffect(() => {
    const getArticle = async () => {
      try {
        const data = await api.education.getArticle(urlParams.permalink!, urlParams.locale!);
        setArticleData(data);
      } catch {
        setArticleData(null);
      }
    };

    if (urlParams.permalink) {
      void getArticle();
    }
  }, [urlParams.permalink, dispatch]);

  return (
    <main className={styles.content}>
      {articleData !== null && (
        <div className={styles.guideContent}>
          {isDesktop && (
            <section>
              <Link to={EDUCATIONAL_PORTAL_GUIDES_URL} className={styles.backLink}>
                <Icon fontFamily="fa2" iconClass="fa2-arrow-left" /> <span>{t('education.labels.allGuides')}</span>
              </Link>
            </section>
          )}
          <section
            className={classNames(styles.contentSection, educationBranding.GUIDE_CONTENT, {
              [styles.contentSection__desktop]: isDesktop
            })}
          >
            {articleData?.content && <div dangerouslySetInnerHTML={{ __html: articleData.content }} />}
          </section>
          <ArticlesList
            cardSize="small"
            displayAmount={3}
            title={t('education.labels.nextInTheRow')}
            horizontalScroll={isMobile}
          />
        </div>
      )}
      <Calculators />
    </main>
  );
};

export default EducationalPortalGuidePage;
