import { toNumber } from 'lodash';

import useBetLabels from 'hooks/useBetLabels';
import { THandicap, TPrice, TSize } from 'types/bets';
import { BetSide } from 'types/myBets';
import { getAHDoubleLineProfitLoss, getTeamsNamesFromEventName } from 'utils/betLabels';

import BetLabelsTpl from '../BetLabelsTpl';

type AHDoubleLineLabelProps = {
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetSide;
  eventTypeId: string;
  selectionName?: string;
  eventName?: string;
  currency?: string;
  runnersNames?: string[];
};

const AHDoubleLineLabel = ({
  price,
  size,
  handicap,
  betType,
  selectionName = '',
  eventName = '',
  currency,
  runnersNames
}: AHDoubleLineLabelProps) => {
  const { getAHDoubleLineLabels } = useBetLabels();

  const TEAM1 = '{TEAM1}';
  const TEAM2 = '{TEAM2}';

  const trim = (str: string) => str.replace(/\s/g, '');

  const runners = runnersNames || getTeamsNamesFromEventName(eventName) || [];

  const team1 = runners.find(runner => trim(selectionName).indexOf(trim(runner)) !== -1) ?? '';
  const team2 = runners.find(runner => trim(selectionName).indexOf(trim(runner)) === -1) ?? '';

  const handicapValue = toNumber(handicap || 0);

  const { labelW, labelS, labelL } = getAHDoubleLineLabels(handicapValue, TEAM1, TEAM2);
  const { profitLossW, profitLossS, profitLossL } = getAHDoubleLineProfitLoss({
    price,
    size,
    betType,
    handicap
  });

  const labelWHtml = labelW.replace(TEAM1, `<strong>${team1}</strong>`).replace(TEAM2, `<strong>${team2}</strong>`);
  const labelSHtml = labelS.replace(TEAM1, `<strong>${team1}</strong>`).replace(TEAM2, `<strong>${team2}</strong>`);
  const labelLHtml = labelL.replace(TEAM1, `<strong>${team1}</strong>`).replace(TEAM2, `<strong>${team2}</strong>`);

  return (
    <BetLabelsTpl
      betType={betType}
      labelW={labelWHtml}
      labelS={labelSHtml}
      labelL={labelLHtml}
      profitLossW={profitLossW}
      profitLossS={profitLossS}
      profitLossL={profitLossL}
      currency={currency}
    />
  );
};

export default AHDoubleLineLabel;
