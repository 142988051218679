import Calculators from 'components/Calc/Calculators';
import ArticlesList from 'pages/EducationalPortal/components/ArticlesList/ArticlesList';

import styles from './styles.module.scss';

const EducationalPortalGuidesPage = () => {
  return (
    <main className={styles.content}>
      <ArticlesList />
      <Calculators />
    </main>
  );
};

export default EducationalPortalGuidesPage;
