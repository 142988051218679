import { ButtonHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import { componentsBranding, mobileComponents } from 'constants/branding';
import useDevice from 'hooks/useDevice';

import styles from './styles.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outline';
  className?: string;
  disabled?: boolean;
  isSelected?: boolean;
}

const Button = ({
  children,
  className,
  isSelected,
  disabled,
  variant = 'primary',
  ...props
}: ButtonProps): JSX.Element => {
  const { isMobile } = useDevice();

  return (
    <button
      className={classNames(styles.button, className, {
        [`${componentsBranding.PRIMARY_BTN}`]: variant === 'primary',
        [`${componentsBranding.SECONDARY_BTN} ${styles.button__secondary}`]: variant === 'secondary',
        [`${componentsBranding.TERTIARY_BTN} ${styles.button__tertiary}`]: variant === 'tertiary',
        [componentsBranding.DISABLED]: disabled,
        [mobileComponents.BUTTON]: isMobile,
        [mobileComponents.PRIMARY]: isMobile && variant === 'primary',
        [mobileComponents.SECONDARY]: isMobile && variant === 'secondary',
        [mobileComponents.TERTIARY]: isMobile && variant === 'tertiary',
        [styles.button__selected]: isSelected
      })}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
