import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { CookieNames } from 'constants/app';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { getBooleanValue } from 'utils';

import useDeviceSettings from './useDeviceSettings';

const useNetOfCommission = () => {
  const [cookies, setCookie] = useCookies([CookieNames.BIAB_MARKET_NET_COMMISSION]);

  const accountSettings = useSelector(getAccountSettings);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const { netOfCommission, netOfCommissionDefaultState } = useDeviceSettings();

  const cookieValue = cookies[CookieNames.BIAB_MARKET_NET_COMMISSION];
  const isEnabled = isLoggedIn && netOfCommission;
  let showNetOfCommission: boolean;

  if (cookieValue !== undefined) {
    showNetOfCommission = getBooleanValue(cookieValue);
  } else if (!isEnabled) {
    showNetOfCommission = false;
  } else if (isLoggedIn) {
    showNetOfCommission = accountSettings?.netOfCommissionEnabledState || false;
  } else {
    showNetOfCommission = netOfCommissionDefaultState;
    setCookie(CookieNames.BIAB_MARKET_NET_COMMISSION, cookieValue, { path: '/' });
  }

  return showNetOfCommission;
};

export default useNetOfCommission;
