import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import SortingField from 'components/BetsTable/components/SortingField';
import { CookieNames } from 'constants/app';
import { AccountStatementSortByFields } from 'constants/myBets';
import useAccountProducts from 'hooks/useAccountProducts';
import useCommissionRange from 'hooks/useCommissionRange';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import FiltersButton from 'pages/MyBetsPages/components/FiltersButton';
import {
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { setMobileStatementSorting } from 'redux/modules/myBets';
import { getMobileStatementSorting, getStatementLoading } from 'redux/modules/myBets/selectors';
import { TStatementContentItem } from 'redux/modules/myBets/type';
import { AccountStatementSortByField } from 'types/myBets';
import { addDays, getDaysDifference, getPeriod, getRangeDate, getTodayDate, getTodayEndDate } from 'utils/date';

import AccountStatementMobileItemSkeleton from '../AccountStatementMobileItemSkeleton/AccountStatementMobileItemSkeleton';
import AccountStatementMobileTableItem from '../AccountStatementMobileTableItem/AccountStatementMobileTableItem';

import styles from './AccountStatementTableMobileContent.module.scss';

type AccountStatementTableMobileContentProps = {
  isAsianViewTable: boolean;
  content: TStatementContentItem[];
};

const AccountStatementTableMobileContent = ({ isAsianViewTable, content }: AccountStatementTableMobileContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW]);

  const isLoading = useSelector(getStatementLoading);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const mobileSorting = useSelector(getMobileStatementSorting);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const { selectedProduct, isProductDropdownEnabled } = useAccountProducts();
  const { commissionRange, showCommissionRange } = useCommissionRange();
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const { refreshData, bettingDay, startDate, endDate, timezoneOffset } = useMyBetsPagesNavigation();

  const todayDate = getTodayEndDate(timezoneOffset || timezone, timezoneCookieEnabled || bettingDay);
  const dayDifference = getDaysDifference(getTodayDate(), todayDate);

  const currentPeriod =
    startDate && endDate
      ? getPeriod(
          addDays(startDate, -1 * dayDifference),
          addDays(endDate, -1 * dayDifference),
          undefined,
          dayDifference
        )
      : '';

  const handleSortMobile = ({ sortBy, value }: { sortBy: string; value: 'asc' | 'desc' }) => {
    dispatch(setMobileStatementSorting({ name: sortBy as AccountStatementSortByField, order: value }));
    refreshData({
      sortBy: {
        [sortBy]: value
      }
    });
  };

  return (
    <>
      <div className={styles.mobile__commissionRange__wrapper}>
        {showCommissionRange && !isAsianViewTable && (
          <p className={styles.mobile__commissionRange}>
            {t('accountStatement.commissionRange')}:{commissionRange}
          </p>
        )}
        <FiltersButton
          showLabel={!showCommissionRange || isAsianViewTable}
          className={classNames({
            [styles.mobile__filterBtn]: !showCommissionRange || isAsianViewTable
          })}
        />
      </div>
      <div className={styles.mobileTableHeader}>
        <div className={styles.mobileTableHeader__left}>
          <p className={styles.mobileTableHeader__dateRange}>
            {currentPeriod === 'custom'
              ? `${getRangeDate({ date: startDate, t, americanDateFormatEnabled })} - ${getRangeDate({
                  date: endDate,
                  t,
                  americanDateFormatEnabled
                })}`
              : t(`account.labels.period.${currentPeriod}`)}
          </p>
          {isProductDropdownEnabled && (
            <p className={styles.mobileTableHeader__product}>
              {t(`asianView.labels.account.exchangeType.${cookies.EXCHANGE_TYPE_NEW || selectedProduct}`)}
            </p>
          )}
        </div>
        <SortingField
          sortBy={mobileSorting?.name ?? AccountStatementSortByFields.SETTLED_DATE}
          sortingValue={mobileSorting?.order ?? 'desc'}
          onSorting={handleSortMobile}
          isDisabled={isLoading || !content?.length}
        />
      </div>
      {isLoading ? (
        new Array(4).fill(0).map((_, index) => {
          return <AccountStatementMobileItemSkeleton key={index} />;
        })
      ) : content?.length ? (
        content.map(data => {
          return (
            <AccountStatementMobileTableItem
              key={data.refId}
              data={data}
              isMultiCurrencySupported={isMultiCurrencySupported}
            />
          );
        })
      ) : (
        <p className={styles.noData}>{t('account.statement.messages.noData')}</p>
      )}
    </>
  );
};

export default AccountStatementTableMobileContent;
