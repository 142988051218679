import { useDispatch } from 'react-redux';

import { InlinePlacementActions, InlinePlacementButtonActionTypes } from 'constants/inlinePlacement';
import { updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getBetIdentityData, getInlinePlacedOfferStatuses } from 'utils/inlinePlacement';

import InlineBetButton from '../InlineBetButton';

type TInlineBetEditProps = {
  bet: TInlineSelectedBet;
};
const InlineBetEdit = ({ bet }: TInlineBetEditProps) => {
  const dispatch = useDispatch();

  const { isUnmatched } = getInlinePlacedOfferStatuses(bet.placedBet);

  const betIdData = getBetIdentityData(bet);

  const onEditBtnClick = () => {
    dispatch(
      updateInlineSelectedBet({
        ...betIdData,
        action: isUnmatched ? InlinePlacementActions.EDIT : InlinePlacementActions.SELECT
      })
    );
  };

  return (
    <InlineBetButton bet={bet} buttonType={InlinePlacementButtonActionTypes.EDIT} onClickCallback={onEditBtnClick} />
  );
};

export default InlineBetEdit;
