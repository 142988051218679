import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isNull } from 'lodash';

import { SportIds } from 'constants/app';
import {
  getAwayScoreByEventId,
  getHomeScoreByEventId,
  getIsEventsUpdatedDataByEventId,
  getIsValidEventUpdatedDataCricket
} from 'redux/modules/marketsPrices/selectors';

import BasketballMarketScore from '../BasketballMarketScore';
import CricketMarketScore from '../CricketMarketScore';
import SoccerMarketScore from '../SoccerMarketScore';
import TennisMarketScore from '../TennisMarketScore';

type MarketScoreProps = {
  sportId?: string;
  eventId: string;
  isCashOut?: boolean;
};

const MarketScore = ({ sportId, isCashOut, eventId }: MarketScoreProps) => {
  const { t } = useTranslation();

  const isData = useSelector(getIsEventsUpdatedDataByEventId(eventId));
  const homeScore = useSelector(getHomeScoreByEventId(eventId));
  const awayScore = useSelector(getAwayScoreByEventId(eventId));
  const isValidCricketData = useSelector(getIsValidEventUpdatedDataCricket(eventId));

  const isValidEventUpdatedData =
    sportId === SportIds.CRICKET
      ? isValidCricketData
      : homeScore !== '' && !isNull(homeScore) && awayScore !== '' && !isNull(awayScore);

  if (isValidEventUpdatedData) {
    if (sportId && isData) {
      switch (sportId) {
        case SportIds.CRICKET:
          return <CricketMarketScore eventId={eventId} isCashOut={isCashOut} />;

        case SportIds.SOCCER:
          return <SoccerMarketScore eventId={eventId} isCashOut={isCashOut} />;

        case SportIds.TENNIS:
          return <TennisMarketScore eventId={eventId} isCashOut={isCashOut} />;

        case SportIds.BASKETBALL:
          return <BasketballMarketScore eventId={eventId} isCashOut={isCashOut} />;

        default:
          return null;
      }
    }

    return null;
  }

  if (isCashOut) {
    return null;
  }

  return <span>{t('market.inPlay')}</span>;
};

export default MarketScore;
