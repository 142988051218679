import { Dispatch, SetStateAction, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import { CookieNames, Devices } from 'constants/app';
import { TimeFilters as TimeFiltersMap } from 'constants/competitions';
import { SportPageTypes } from 'constants/sportPage';
import useTimeFiltersSortingAndTranslation from 'hooks/useTimeFiltersSortingAndTranslation';
import { getAppDevice, getDesktopCompetitionPageSize, getTranslation } from 'redux/modules/appConfigs/selectors';
import { getCompetitionsNameTranslations, getCountryNameTranslations } from 'redux/modules/competitions/selectors';
import { getEventGroup } from 'redux/modules/event/selectors';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { fetchSportPageDetails } from 'redux/modules/sportPage';
import { getSportPageMarkets, getSportPageSportInfo } from 'redux/modules/sportPage/selectors';
import { SportPageContextFilter, SportPageType } from 'redux/modules/sportPage/type';
import { getSportsList } from 'redux/modules/sports/selectors';
import { TimeFilter, ViewBy } from 'types';

import styles from './styles.module.scss';

interface SportFiltersProps {
  /**
   * 'View by' sort value
   */
  viewBy: ViewBy;

  /**
   * Current time filter for data on sport page
   */
  timeFilter: TimeFilter;

  /**
   * Set state action for current time filter
   */
  setTimeFilter: Dispatch<SetStateAction<TimeFilter>>;

  /**
   * Current page for infinite scroll
   */
  page: number;

  /**
   * Set state action for current page
   */
  setPage: Dispatch<SetStateAction<number>>;

  /**
   * Current page request data id
   */
  pageDetailsId: string;

  /**
   * Current page request data contextFilter
   */
  contextFilter: SportPageContextFilter;

  /**
   * Page type (Sport, Country or Competition)
   */
  pageType: SportPageType;
  isNotRealEvent?: boolean;
}

const TimeFilters = ({
  viewBy,
  timeFilter,
  setTimeFilter,
  page,
  setPage,
  pageDetailsId,
  contextFilter,
  pageType,
  isNotRealEvent
}: SportFiltersProps) => {
  const dispatch = useDispatch();
  const { sportId = '', competitionId } = useParams();

  const [, setCookie] = useCookies([CookieNames.COMPETITION_TIME_FILTER]);

  const device = useSelector(getAppDevice);
  const domainLanguage = useSelector(getTranslation);
  const desktopCompetitionPageSize = useSelector(getDesktopCompetitionPageSize);
  const sportInfo = useSelector(getSportPageSportInfo);
  const markets = useSelector(getSportPageMarkets);
  const eventGroup = useSelector(getEventGroup);
  const isFavorite = useSelector(getFavoriteById(pageDetailsId));
  const competitionNameTranslations = useSelector(getCompetitionsNameTranslations);
  const countryNameTranslations = useSelector(getCountryNameTranslations);
  const sportList = useSelector(getSportsList);

  const timeFilters = useTimeFiltersSortingAndTranslation(sportInfo?.availableTimeFilters ?? []);

  useEffect(() => {
    if (sportInfo?.availableTimeFilters && !sportInfo.availableTimeFilters.includes(timeFilter)) {
      setTimeFilter(TimeFiltersMap.ALL);
      setCookie(CookieNames.COMPETITION_TIME_FILTER, TimeFiltersMap.ALL);
    }
  }, [sportInfo?.availableTimeFilters]);

  const handleChangeTimeFilter = (newFilter: TimeFilter) => {
    if (newFilter !== timeFilter) {
      if (page !== 0) {
        setPage(0);
      }

      setTimeFilter(newFilter);
      setCookie(CookieNames.COMPETITION_TIME_FILTER, newFilter);

      dispatch(
        fetchSportPageDetails({
          page: 0,
          size: device === Devices.DESKTOP ? desktopCompetitionPageSize : undefined,
          data: { timeFilter: newFilter, viewBy, contextFilter, id: pageDetailsId }
        })
      );
    }
  };

  const getFiltersTitle = () => {
    if (markets) {
      const countryName =
        typeof countryNameTranslations === 'string' ? countryNameTranslations : countryNameTranslations[domainLanguage];
      const [market] = markets;
      const sport = sportList.find(sportItem => sportItem.id === sportId);
      const sportName = sport?.translations?.[domainLanguage] ?? '';

      if (isNotRealEvent) {
        const event = eventGroup?.parents?.find((item: { type: string }) => item.type === SportPageTypes.COMPETITION);
        return event?.translations?.[domainLanguage] ?? '';
      } else if (competitionId || isNotRealEvent) {
        return competitionNameTranslations?.[domainLanguage] ?? '';
      } else if (pageType === SportPageTypes.COUNTRY) {
        return countryName ? `${sportName} - ${countryName}` : sportName;
      } else if (pageType === SportPageTypes.COMPETITION) {
        return market?.competition?.competitionNameTranslations?.[domainLanguage] ?? '';
      }

      return sportName;
    }

    return '';
  };

  const favoriteData = {
    entryId: pageDetailsId,
    entryName: getFiltersTitle(),
    entryType: contextFilter,
    starred: !isFavorite,
    sportId
  };

  return (
    <div className={classNames('biab_competition-header', styles.filters)}>
      {device === Devices.DESKTOP && (
        <h2 className={classNames('biab_competition-name', styles.filters__title)}>{getFiltersTitle()}</h2>
      )}
      <div className={styles.filters__typeWrapper}>
        <ul role="tablist" className={classNames('biab_view-type-wrapper', styles.filters__list)}>
          {timeFilters?.map(({ value, label }) => (
            <li
              key={value}
              role="tab"
              className={classNames('biab_tab', styles.filters__filter, {
                biab_active: timeFilter === value
              })}
              onClick={() => handleChangeTimeFilter(value)}
            >
              {label}
            </li>
          ))}
        </ul>

        <div className={styles.filters__favouriteIcon}>
          <FavoriteStar entryData={favoriteData} />
        </div>
      </div>
    </div>
  );
};

export default TimeFilters;
