import { useTranslation } from 'react-i18next';

import { BetSides } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import AVBetListMobilePriceAndOddsType from '../AVBetListMobilePriceAndOddsType/AVBetListMobilePriceAndOddsType';
import AVBetListMobileSideIndicator from '../AVBetListMobileSideIndicator/AVBetListMobileSideIndicator';

import styles from './AVBetListMobileMarketContainerBet.module.scss';

type AVBetListMobileMarketContainerBetProps = {
  bet: TCurrentBet;
  hideHeader: boolean;
};

const formatCurrencySettings = {
  isCheckIndian: true,
  noRounding: true
};

const AVBetListMobileMarketContainerBet = ({
  bet: {
    triggeredByCashOut,
    side,
    selectionName,
    averagePriceRounded,
    oddsType,
    offerId,
    placedDate,
    liability,
    sizeMatched,
    currency,
    potentialProfit
  },
  hideHeader
}: AVBetListMobileMarketContainerBetProps) => {
  const { t } = useTranslation();

  const formattedDate = useLongDateFormat({
    isTimeFirst: true,
    isShortYear: true,
    time: placedDate ?? 0,
    hasYear: true,
    divider: ' ',
    dateDivider: '-',
    isAsian: true,
    isAsianYearLast: true,
    applyAsianAmericanFormat: true
  });

  const { noFormattedAmount: stake } = useFormatCurrency(sizeMatched || 0, currency, formatCurrencySettings);

  const { noFormattedAmount: formattedLiability } = useFormatCurrency(liability || 0, currency, formatCurrencySettings);

  const { noFormattedAmount: profit } = useFormatCurrency(potentialProfit || 0, currency, formatCurrencySettings);

  return (
    <div className={styles.bet}>
      {triggeredByCashOut && (
        <div className={styles.bet__triggeredByCashOut}>
          <p className={styles.bet__triggeredByCashOut__text}>{t('asianView.labels.triggeredByCashOut')}</p>
        </div>
      )}
      {!hideHeader && (
        <div className={styles.bet__header}>
          <div className={styles.bet__header__left}>
            <AVBetListMobileSideIndicator isSingleBet side={side} />
            <p className={styles.bet__header__title}>{selectionName}</p>
          </div>
          <AVBetListMobilePriceAndOddsType price={averagePriceRounded} side={side} oddsType={oddsType} />
        </div>
      )}
      <div className={styles.bet__riskProfit__container}>
        <div className={styles.bet__riskProfit}>
          <p className={styles.bet__riskProfit__top}>
            {t(`asianView.labels.betslip.${side === BetSides.Back ? 'stake' : 'risk'}`)}
          </p>
          <p className={styles.bet__riskProfit__bottom}>{side === BetSides.Back ? stake : formattedLiability}</p>
        </div>
        <div className={styles.bet__riskProfit}>
          <p className={styles.bet__riskProfit__top}>{t('asianView.labels.betslip.profit')}</p>
          <p className={styles.bet__riskProfit__bottom}>{side === BetSides.Back ? profit : stake}</p>
        </div>
      </div>
      <p className={styles.bet__bottom}>
        {t('asianView.labels.betList.ref')} {offerId} {t('asianView.labels.betList.placed')}: {formattedDate}
      </p>
    </div>
  );
};

export default AVBetListMobileMarketContainerBet;
