import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useResizeObserver } from 'usehooks-ts';

import SEOFooter from 'components/SEOFooter';
import { ElementNames } from 'constants/app';
import useDevice from 'hooks/useDevice';
import useDeviceSettings from 'hooks/useDeviceSettings';
import {
  getDeviceFooterContent,
  getIsDesktopFooterHidden,
  getIsIframeEnabled
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getDefaultLinkTarget } from 'utils/navigation';

import styles from './styles.module.scss';

interface FooterProps {
  isSearchVisible?: boolean;
}

const Footer = ({ isSearchVisible = false }: FooterProps) => {
  const dispatch = useDispatch();

  const isFooterHidden = useSelector(getIsDesktopFooterHidden);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const footerRef = useRef<HTMLDivElement>(null);

  const { footerLinks, footerImages } = useDeviceSettings();
  const { isMobile, isAsianViewPage } = useDevice();
  useResizeObserver({
    ref: footerRef,
    box: 'content-box',
    onResize: ({ height = 0 }) => {
      dispatch(setElementHeight({ name: ElementNames.FOOTER_HEIGHT, height }));
    }
  });

  const getTarget = (url: string, currentTarget: string) =>
    getDefaultLinkTarget({
      url,
      absolutePath: true,
      isIframeEnabled,
      currentTarget
    });

  return (
    <footer
      id="biab_footer"
      ref={footerRef}
      className={classNames(styles.footer, {
        [styles.footer__asianView__mobile]: isMobile && isAsianViewPage,
        hidden: isFooterHidden
      })}
    >
      {!isFooterHidden && (
        <>
          {!isSearchVisible && <SEOFooter />}
          <div className="biab_footer-inner biab_visible-xs">
            <FooterContent isMobile={isMobile} />
            {!!footerLinks.length && (
              <ul
                className={classNames('biab_footer-nav', styles.footer__nav, {
                  [styles.footer__nav__mobile]: isMobile
                })}
              >
                {footerLinks
                  .filter(link => (isLoggedIn ? link.authUserVisible : true))
                  .map((link, index) => (
                    <li key={index}>
                      {index !== 0 && <span className={classNames('biab_separator', styles.footer__divider)} />}
                      {link.absolutePath && (
                        <a target={getTarget(link.url, link.openInNewTab ? '_blank' : '')} href={link.url}>
                          {link.label}
                        </a>
                      )}
                      {!link.absolutePath && (
                        <NavLink target={getTarget(link.url, link.openInNewTab ? '_blank' : '')} to={link.url}>
                          {link.label}
                        </NavLink>
                      )}
                    </li>
                  ))}
              </ul>
            )}
            {!!footerImages?.length && (
              <ul
                className={classNames('biab_footer-icons', styles.footer__icons, {
                  [styles.footer__icons__mobile]: isMobile
                })}
              >
                {footerImages?.map(image => (
                  <li key={image.id}>
                    <div className="biab_logo">
                      {image.absolutePathEnabled && (
                        <a
                          className={styles.footer__logo}
                          target={getTarget(image.link, image.openInNewTab ? '_blank' : '')}
                          href={image.link}
                        >
                          <img
                            src={`${window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL}/customer/api${
                              image.imageUrl
                            }`}
                            srcSet={`${
                              window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL
                            }/customer/api${image.retinaImageUrl || image.imageUrl} 2x`}
                            alt="footer-logo"
                          />
                        </a>
                      )}
                      {!image.absolutePathEnabled && (
                        <NavLink
                          className={styles.footer__logo}
                          target={getTarget(image.link, image.openInNewTab ? '_blank' : '')}
                          to={image.link}
                        >
                          <img
                            src={`${window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL}/customer/api${
                              image.imageUrl
                            }`}
                            srcSet={`${
                              window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL
                            }/customer/api${image.retinaImageUrl || image.imageUrl} 2x`}
                            alt="footer-logo"
                          />
                        </NavLink>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </>
      )}
    </footer>
  );
};

export default Footer;

function FooterContent({ isMobile }: { isMobile: boolean }) {
  const footerContent = useSelector(getDeviceFooterContent(isMobile));

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const footerEl = ref.current;

    if (footerEl && footerContent) {
      const fragment = document.createRange().createContextualFragment(footerContent);
      footerEl.append(fragment);
    }

    return () => {
      if (footerEl) {
        footerEl.remove();
      }
    };
  }, [footerContent]);

  return (
    <div
      id="footerContent"
      ref={ref}
      className={classNames('biab_footer-content', styles.footer__content, {
        [styles.footer__content__mobile]: isMobile
      })}
    />
  );
}
