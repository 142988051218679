import classNames from 'classnames';

import MarketSelections from 'components/MarketSelections';
import RowRunner from 'components/RowRunner';
import { FEATURED_MARKET_MAX_RUNNERS_LENGTH_TO_DISPLAY, PageBlocks, PlacementPages } from 'constants/app';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { IMarket } from 'types/markets';

import MarketStatus from './MarketStatus';
import TitleMarket from './TitleMarket';
import ViewFullMarket from './ViewFullMarket';

import styles from '../styles.module.scss';

type FeaturedMarketProps = {
  market: IMarket;
  isDepthEnabled: boolean;
  isLandscape: boolean;
  desktopCellsContainerWidth: string;
  mobileCellsContainerWidth: string;
};

const FeaturedMarket = ({
  market,
  isDepthEnabled,
  isLandscape,
  mobileCellsContainerWidth,
  desktopCellsContainerWidth
}: FeaturedMarketProps) => {
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market.marketId,
    eventId: market.event.id,
    section: MarketsPricesSocketParamsSections.SportsMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });

  return (
    <div
      ref={ref}
      className={classNames('biab_featured-market', styles.market)}
      data-market-id={market.marketId}
      data-event-id={market.event.id}
      data-market-prices="true"
    >
      <TitleMarket market={market} />

      <MarketSelections market={market} isHomePage betCellsMarketView showEventWidgetsIcons />

      <div className={styles.marketStatus__container}>
        <MarketStatus marketId={market.marketId} />

        {market.runners.slice(0, FEATURED_MARKET_MAX_RUNNERS_LENGTH_TO_DISPLAY).map(runner => (
          <RowRunner
            key={runner.selectionId + runner.runnerName}
            market={market}
            runner={runner}
            pageBlock={PageBlocks.FEATURE_MARKET}
            isHomePage
            isDepthEnabled={isDepthEnabled}
            isLandscape={isLandscape}
            page={PlacementPages.FEATURED_MARKETS}
            desktopCellsContainerWidth={desktopCellsContainerWidth}
            mobileCellsContainerWidth={mobileCellsContainerWidth}
          />
        ))}
      </div>

      {market.runners.length > FEATURED_MARKET_MAX_RUNNERS_LENGTH_TO_DISPLAY ? (
        <div className={classNames('blurLastMarker', styles.blurLastMarker)}>
          <ViewFullMarket marketId={market.marketId} sportId={market.eventType.id} />
        </div>
      ) : (
        <ViewFullMarket marketId={market.marketId} sportId={market.eventType.id} />
      )}
    </div>
  );
};

export default FeaturedMarket;
