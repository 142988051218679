import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import GameResultPopup from 'components/GameResultPopup';
import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import MyBetsStandardCell from 'components/MyBetsStandardCell';
import MyBetsStatusCell from 'components/MyBetsStatusCell';
import { CommissionTypes, GAME, OddsTypes } from 'constants/app';
import { accountPagesBranding, betslipBranding, componentsBranding } from 'constants/branding';
import { GameTypes } from 'constants/games';
import { BettingTypes } from 'constants/markets';
import { BetSides, lineBettingType, MY_BETS_CONTENT_SM_WIDTH_DESKTOP, myBetsTableHeader } from 'constants/myBets';
import useAccountMyBetsCancelAction from 'hooks/useAccountMyBetsCancelAction';
import useAccountPages from 'hooks/useAccountPages';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import {
  getDesktopSettingsAlternativeLayOdds,
  getDesktopSettingsLineMarketsSwitchBackLayOnCricket,
  getDesktopSettingsReplaceBackLayWithUnderOver,
  getDesktopSettingsTotalWinnings,
  getWindowWidth
} from 'redux/modules/appConfigs/selectors';
import { getGameType } from 'redux/modules/games/selectors';
import { setSortBy } from 'redux/modules/myBets';
import { getSortValueByType } from 'redux/modules/myBets/selectors';
import { SortByFields, TMyBetsContentItem, TSortByType } from 'redux/modules/myBets/type';
import { OfferStates } from 'types/myBets';
import {
  alternativeBackOddsValue,
  alternativeLayOddsValue,
  betsSideValue,
  oddsValue,
  shouldSwapColors
} from 'utils/myBetsValues';
import convertOdds from 'utils/oddsConverter';

import DescriptionCell from '../DescriptionCell';
import SortingField from '../SortingField';

import styles from './styles.module.scss';

type TTableRowProps = {
  isHeader?: boolean;
  isDisabled?: boolean;
  item?: TMyBetsContentItem;
  descriptionWidth?: number;
};

const TableRow = ({ isHeader, isDisabled, item, descriptionWidth }: TTableRowProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const alternativeLayOdds = useSelector(getDesktopSettingsAlternativeLayOdds);
  const totalWinnings = useSelector(getDesktopSettingsTotalWinnings);
  const lineMarketsSwitchBackLayOnCricket = useSelector(getDesktopSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getDesktopSettingsReplaceBackLayWithUnderOver);
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const sortByPlacedDate = useSelector(getSortValueByType(SortByFields.PLACED_DATE));
  const sortBySettledDate = useSelector(getSortValueByType(SortByFields.DATE));
  const gameType = useSelector(getGameType);
  const windowWidth = useSelector(getWindowWidth);

  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const { isAsianView } = useAccountPages();
  const { isCurrentPeriod } = useMyBetsFilters();
  const { refreshData } = useMyBetsPagesNavigation();
  const { isCancelBetSuccess, isCancelBetLoading, isCancelBetError, onCancelBet } = useAccountMyBetsCancelAction({
    bet: item
  });

  const [isRowHovered, setIsRowHovered] = useState(false);
  const [openGameResultModal, setOpenGameResultModal] = useState(false);

  const dateToShow =
    item?.offerState === OfferStates.CANCELLED
      ? item?.cancelledDate
      : item?.offerState !== OfferStates.PLACED && item?.offerState !== OfferStates.MATCHED
      ? item?.settledDate
      : item?.placedDate;

  const { date } = useParseMyBetsDates({ dateToParse: dateToShow ?? 0, divider: ',' });

  const { date: placedDate } = useParseMyBetsDates({ dateToParse: item?.placedDate ?? 0, divider: ',' });

  const currencySettings = {
    noRounding: true,
    isCheckIndian: true,
    noSymbol: !isMultiCurrencySupported
  };

  const isSettled = item?.offerState === OfferStates.WON || item?.offerState === OfferStates.LOST;
  const isMatched = item?.offerState === OfferStates.MATCHED;

  const { noFormattedAmount: winnings } = useFormatCurrency(item?.totalWinnings || 0, item?.currency, currencySettings);

  const profitValue = isSettled ? item?.profit : item?.profitNet;
  const { noFormattedAmount: profit } = useFormatCurrency(profitValue || 0, item?.currency, currencySettings);

  const { noFormattedAmount: liability } = useFormatCurrency(
    isSettled ? item?.pastTotalLiability || 0 : item?.liability || 0,
    item?.currency,
    currencySettings
  );

  const { noFormattedAmount: stake } = useFormatCurrency(item?.size || 0, item?.currency, currencySettings);
  const { noFormattedAmount: successCancelStake } = useFormatCurrency(item?.size || 0, item?.currency, {
    ...currencySettings,
    noSymbol: isMultiCurrencySupported
  });

  const getOddsTooltipValue = () => {
    const avgOdds = item && oddsValue(item, { isRounded: false });

    if (isAsianView) {
      return `${avgOdds} ${t(`asianView.labels.oddsType.short.${OddsTypes.DEC}`)}`;
    }

    return unescape(t('betslip.tooltip.avgPrice', { N: alternativeLayOdds ? item?.averagePrice : avgOdds }));
  };

  const oddsTooltipValue = getOddsTooltipValue();

  const setSortingTypeHandler = ({ sortBy, value }: { sortBy: string; value: 'asc' | 'desc' }) => {
    dispatch(setSortBy({ type: sortBy as TSortByType, value }));

    refreshData({ sortBy: { [sortBy]: value } });
  };

  const isWideScreenView = windowWidth > MY_BETS_CONTENT_SM_WIDTH_DESKTOP;
  const isSwapColors = item && shouldSwapColors(item, swapColorsFancyMarketsOnCricket);
  const isBackIndicator = isSwapColors ? item?.side === BetSides.Lay : item?.side === BetSides.Back;
  const isUnmatched = item?.offerState === OfferStates.PLACED;
  const isGame = item?.betType === BettingTypes.GAME;

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.wrap__header]: isHeader,
        [styles.wrap__item]: !isHeader,
        [styles.wrap__border]: !isHeader && !isCancelBetSuccess
      })}
      onMouseEnter={() => {
        if (item?.offerState === OfferStates.PLACED) {
          setIsRowHovered(true);
        }
      }}
      onMouseLeave={() => {
        if (item?.offerState === OfferStates.PLACED) {
          setIsRowHovered(false);
        }
      }}
    >
      <div
        className={classNames(styles.row, accountPagesBranding.BET_INDICATOR, {
          [styles.row__header]: isHeader,
          [styles.row__small]: !isWideScreenView,
          [styles.row__back]: !isHeader && isBackIndicator && !isCancelBetSuccess,
          [styles.row__lay]: !isHeader && !isBackIndicator && !isCancelBetSuccess,
          [styles.row__succesCancel]: !isHeader && isUnmatched && isCancelBetSuccess,
          [accountPagesBranding.BET_SIDE_BACK]: !isHeader && isBackIndicator,
          [accountPagesBranding.BET_SIDE_LAY]: !isHeader && !isBackIndicator
        })}
      >
        {isWideScreenView && (
          <div className={styles.col__sm}>
            {item ? <MyBetsStandardCell entity="offerId" value={item.offerId} /> : t(myBetsTableHeader.REF_ID)}
          </div>
        )}
        {!isCurrentPeriod && (
          <div className={styles.col__md}>
            {item ? (
              <MyBetsStandardCell entity="date" value={date} />
            ) : (
              <SortingField
                sortBy={SortByFields.DATE}
                sortingValue={sortBySettledDate}
                onSorting={setSortingTypeHandler}
                isDisabled={isDisabled}
                isInactive={!!sortByPlacedDate}
              >
                {t(myBetsTableHeader.SETTLED)}
              </SortingField>
            )}
          </div>
        )}
        <div className={styles.col__md}>
          {item ? (
            <MyBetsStandardCell entity="placedDate" value={placedDate} />
          ) : (
            <SortingField
              sortBy={SortByFields.PLACED_DATE}
              sortingValue={sortByPlacedDate}
              onSorting={setSortingTypeHandler}
              isDisabled={isDisabled}
              isInactive={!!sortBySettledDate}
            >
              {t(myBetsTableHeader.PLACED)}
            </SortingField>
          )}
        </div>
        <div className={styles.col}>
          {item ? (
            <DescriptionCell
              bet={item}
              isRowHovered={isRowHovered && !isCancelBetLoading}
              onCancelBet={onCancelBet}
              windowWidth={windowWidth}
              onClickEventName={setOpenGameResultModal}
              columnWidth={descriptionWidth}
            />
          ) : (
            t(myBetsTableHeader.DESCRIPTION)
          )}
        </div>
        {isWideScreenView && (
          <div className={styles.col__xs}>
            {item ? (
              <MyBetsStandardCell
                entity="side"
                value={t(betsSideValue({ item, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver }))}
                capitalise
              />
            ) : (
              t(myBetsTableHeader.TYPE)
            )}
          </div>
        )}
        {isAsianView && (
          <div className={classNames(styles.col__xs, styles.col__right)}>
            {item ? (
              <MyBetsStandardCell entity="oddsType" value={t(`asianView.labels.oddsType.short.${item.oddsType}`)} />
            ) : (
              t(myBetsTableHeader.ODDS_TYPE)
            )}
          </div>
        )}
        {!isAsianView && alternativeLayOdds ? (
          <>
            <div className={classNames(styles.col__xs, styles.col__right)}>
              {item ? (
                <MyBetsStandardCell
                  entity="alternativeBackOdds"
                  isWithTooltip={item.side === BetSides.Back && (isMatched || isSettled)}
                  tooltipValue={oddsTooltipValue}
                  value={alternativeBackOddsValue(item, { isRounded: true })}
                  capitalise
                  highlight={item.side === BetSides.Back}
                />
              ) : (
                t(myBetsTableHeader.BACK_ODDS)
              )}
            </div>
            <div className={classNames(styles.col__xs, styles.col__right)}>
              {item ? (
                <MyBetsStandardCell
                  entity="alternativeLayOdds"
                  isWithTooltip={item.side !== BetSides.Back && (isMatched || isSettled)}
                  tooltipValue={oddsTooltipValue}
                  value={alternativeLayOddsValue(item, { isRounded: true })}
                  capitalise
                  highlight={item.side === BetSides.Lay}
                />
              ) : (
                t(myBetsTableHeader.LAY_ODDS)
              )}
            </div>
          </>
        ) : (
          <div className={classNames(styles.col__xs, styles.col__right)}>
            {item ? (
              <MyBetsStandardCell
                entity="odds"
                isWithTooltip={(item.bettingType !== lineBettingType && (isMatched || isSettled)) || item.asian}
                tooltipValue={oddsTooltipValue}
                value={isAsianView ? convertOdds(oddsValue(item) || '', item.oddsType) : oddsValue(item)}
                capitalise
                highlight
              />
            ) : (
              t(myBetsTableHeader.ODDS)
            )}
          </div>
        )}
        <div className={classNames(styles.col__xs, styles.col__right)}>
          {item ? <MyBetsStandardCell entity="size" value={stake} /> : t(myBetsTableHeader.STAKE)}
        </div>
        {isAsianView && (
          <div className={classNames(styles.col__xs, styles.col__right)}>
            {item ? (
              <MyBetsStandardCell entity="liability" value={item.liability ? liability : '--'} />
            ) : (
              t(myBetsTableHeader.RISK)
            )}
          </div>
        )}
        {!isAsianView && totalWinnings && (
          <>
            <div className={classNames(styles.col__xs, styles.col__right)}>
              {item ? (
                <MyBetsStandardCell entity="liability" value={item.liability ? liability : '--'} />
              ) : (
                t(myBetsTableHeader.LIABILITY)
              )}
            </div>
            <div className={classNames(styles.col__sm, styles.col__right)}>
              {item ? (
                <MyBetsStandardCell entity="winnings" value={item.totalWinnings ? winnings : ''} />
              ) : (
                t(myBetsTableHeader.TOTAL_WINNINGS)
              )}
            </div>
          </>
        )}
        <div className={classNames(styles.col__md, styles.col__right)}>
          {item ? (
            <MyBetsStandardCell entity="profit" value={profitValue ? profit : ''} coloredValue={!isCurrentPeriod} />
          ) : isCurrentPeriod ? (
            t(myBetsTableHeader.POTENTIAL_PROFIT)
          ) : (
            t(myBetsTableHeader.PROFIT_LOSS)
          )}
        </div>
        {isWideScreenView && (
          <div className={classNames(styles.col__lg, styles.col__center)}>
            {item ? (
              <MyBetsStatusCell
                offerState={item.offerState}
                isRowHovered={isRowHovered && !isCancelBetLoading}
                isPNC={item.commissionType === CommissionTypes.PRICES}
                onCancelBet={onCancelBet}
              />
            ) : (
              t(myBetsTableHeader.STATUS)
            )}
          </div>
        )}
      </div>
      {!isHeader && isUnmatched && isCancelBetError && (
        <div className={classNames(styles.error, componentsBranding.NOTIFICATION, componentsBranding.ERROR)}>
          <i
            className={classNames('biab_custom-icon-warning-circle', styles.error__icon, betslipBranding.WARNING_ICON)}
          />
          <p
            className={styles.error__msg}
            dangerouslySetInnerHTML={{ __html: unescape(t('error.code.EX005.message')) }}
          />
        </div>
      )}
      {!isHeader && isUnmatched && isCancelBetLoading && (
        <div className={styles.loader__wrapper}>
          <Loader circleColor={CircleColors.BLUE_2} className={styles.loader} />
        </div>
      )}
      {!isHeader && isUnmatched && isCancelBetSuccess && (
        <div className={classNames(styles.success, componentsBranding.NOTIFICATION)}>
          <i className={classNames('biab_custom-icon-info-circle', styles.success__icon, betslipBranding.INFO_ICON)} />
          <div>
            <p className={styles.success__text}>
              {t(`account.mybets.labels.${item?.side?.toLowerCase()}`)}:{' '}
              <span className={styles.success__text__bold}>{item?.selectionName}</span> –{' '}
              {item?.betType === GAME ? item?.sportName : item.marketNameWithParents || item?.marketName}
            </p>
            <p className={styles.success__text}>
              <span className={styles.success__text__bold}>
                {successCancelStake} @{item?.price}
              </span>{' '}
              – {t('account.mybets.labels.cancelled')}
            </p>
          </div>
        </div>
      )}
      {!isHeader && isGame && (
        <Modal
          clickableBackground
          open={openGameResultModal}
          onClose={() => setOpenGameResultModal(false)}
          title={`${item.sportName} ${t('games.gameId')}: ${item.marketId}`}
          customClassNames={{
            header: styles.modal__header,
            content: classNames(styles.modal__content, {
              [styles.modal__baccaratContent]: gameType === GameTypes.BACCARAT,
              [styles.modal__hiLoContent]: gameType === GameTypes.HILO,
              [styles.modal__blackJackContent]: gameType === GameTypes.BLACKJACK
            }),
            body: styles.modal__body
          }}
          isDisableScroll
        >
          <GameResultPopup channelId={item.eventTypeId} gameId={item.eventId} />
        </Modal>
      )}
    </div>
  );
};

export default TableRow;
