import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { SportIds } from 'constants/app';
import { fetchGetSportCompetition, setIsAllSoccer } from 'redux/modules/competitions';

import styles from '../../styles.module.scss';

const AllSoccer = () => {
  const dispatch = useDispatch();
  const { sportId } = useParams();
  const isTennis = sportId === SportIds.TENNIS;

  const handleAllSportsClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(fetchGetSportCompetition({ eventId: sportId!, showGroups: isTennis, allSoccer: true }));
  };

  useEffect(() => {
    dispatch(setIsAllSoccer(false));
  }, []);

  return (
    <ul>
      <li
        data-test-id={'all-soccer'}
        className={classNames('biab_navigation-competition-item-name', styles.sportsList__item, styles.allSoccer)}
      >
        <a
          className={classNames(
            'biab_href biab_item',
            'biab_navigation-competition-item-name',
            styles.sportsList__link
          )}
          onClick={handleAllSportsClick}
        >
          All Soccer
        </a>
      </li>
    </ul>
  );
};

export default AllSoccer;
