import {
  BettingDisplaySettingsEnabled,
  ResponsibleBettingDisplayedContents,
  ResponsibleBettingEndDates,
  ResponsibleBettingLimitsEnabled,
  ResponsibleBettingOperatorLimits
} from 'constants/responsibleBetting';
import { useResponsibleBettingDeactivationCounter } from 'hooks/useResponsibleBetting';

const ResponsibleBettingCountersInjection = () => {
  useResponsibleBettingDeactivationCounter({
    endDateKey: ResponsibleBettingEndDates.ExposureLimit,
    limitEnabledKey: ResponsibleBettingLimitsEnabled.ExposureLimit,
    operatorLimitKey: ResponsibleBettingOperatorLimits.ExposureLimit,
    displayEnabledKey: BettingDisplaySettingsEnabled.ExposureLimit,
    type: ResponsibleBettingDisplayedContents.ExposureLimit
  });
  useResponsibleBettingDeactivationCounter({
    endDateKey: ResponsibleBettingEndDates.LossLimit,
    limitEnabledKey: ResponsibleBettingLimitsEnabled.LossLimit,
    operatorLimitKey: ResponsibleBettingOperatorLimits.LossLimit,
    displayEnabledKey: BettingDisplaySettingsEnabled.LossLimit,
    type: ResponsibleBettingDisplayedContents.LossLimit
  });
  useResponsibleBettingDeactivationCounter({
    endDateKey: ResponsibleBettingEndDates.SelfExclusion,
    limitEnabledKey: ResponsibleBettingLimitsEnabled.SelfExclusion,
    operatorLimitKey: ResponsibleBettingOperatorLimits.SelfExcluded,
    displayEnabledKey: BettingDisplaySettingsEnabled.SelfExclusion,
    type: ResponsibleBettingDisplayedContents.SelfExclusion
  });

  return null;
};

export default ResponsibleBettingCountersInjection;
