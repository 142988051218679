import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AsianPlacedQuickBettingStatuses } from 'constants/asianView';
import {
  getGeneralWsEnabled,
  getIsCurrentBetsWebSocketEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { fetchAsianCurrentBets } from 'redux/modules/asianViewCurrentBets';
import { TAsianQuickPlacedBet, TAsianQuickUpdatedBet } from 'redux/modules/asianViewQuickBetting/type';

import PlacedOffer from '../PlacedOffer';

interface IPlacedQuickBetProps {
  quickBet: TAsianQuickPlacedBet & TAsianQuickUpdatedBet;
}
const PlacedQuickBet = ({ quickBet }: IPlacedQuickBetProps) => {
  const dispatch = useDispatch();

  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const isCurrentBetsWebSocketsEnabled = useSelector(getIsCurrentBetsWebSocketEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const isCreated = quickBet.status === AsianPlacedQuickBettingStatuses.CREATED;
  const isPlace = quickBet.status === AsianPlacedQuickBettingStatuses.PLACE;
  const isPlaced = quickBet.status === AsianPlacedQuickBettingStatuses.PLACED;
  const isError = quickBet.status === AsianPlacedQuickBettingStatuses.ERROR;

  useEffect(() => {
    if (arePropertiesLoaded && (!generalWsEnabled || !isCurrentBetsWebSocketsEnabled)) {
      dispatch(fetchAsianCurrentBets());
    }
  }, [quickBet.offerId, arePropertiesLoaded, isCurrentBetsWebSocketsEnabled, generalWsEnabled]);

  if (isCreated || isPlace || isPlaced || isError) {
    return <PlacedOffer quickBet={quickBet} />;
  }

  return null;
};

export default PlacedQuickBet;
