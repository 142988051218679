import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CashOutStatuses, SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';
import { TFetchMarketRulesResponse } from 'types/markets';
import { getContentCashOutPage } from 'utils/cashOut';

import {
  AutoCashOut,
  CashOutMarket,
  CashOutQuote,
  CreateAutoCashOutPayload,
  MarketCashOutResponse,
  ProfitPayload,
  ProfitSelection,
  TCashOutInitValue,
  TSetCashOut
} from './type';

const initialState: TCashOutInitValue = {
  loading: false,
  statusLoading: {},
  isFirstLoaded: false,
  error: null,
  marketCashOut: {
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 20,
    sort: null,
    totalElements: 0,
    totalPages: 0,
    content: []
  },
  stringifiedMarkets: '',
  quotes: {},
  stringifiedQuotes: '',
  whatIf: {},
  whatIfMarketId: null,
  possibleProfits: {},
  cashedValues: {},
  autoCashOuts: {},
  stringifiedAutoCashOuts: '',
  placedIds: {},
  successFullCreatedAutoCashOuts: {},
  successFullDeletedAutoCashOuts: {},
  pendingCashOuts: {},
  cashOutStatuses: {},
  paginationLoader: false,
  settingTabsStates: {},
  rules: null,
  rulesLoading: false,
  rulesError: null,
  fetchCashOutLoading: false,
  fetchAutoCashOutLoading: false,
  fetchCashOutMarketsLoading: false,
  autoCashOutErrors: {}
};

const slice = createSlice({
  name: SLICES_NAMES.CASH_OUT,
  initialState,
  reducers: {
    fetchCashOutQuotes: (state, { payload }: PayloadAction<{ ids: string[]; firstLoading?: boolean }>) => {
      state.loading = true;
      state.fetchCashOutLoading = true;

      if (payload.firstLoading) {
        state.isFirstLoaded = false;
      }
    },
    successFetchCashOutQuotes: (
      state,
      { payload }: PayloadAction<{ quotes: CashOutQuote[]; firstLoaded?: boolean; isWebSocketResponse: boolean }>
    ) => {
      state.loading = false;
      state.fetchCashOutLoading = false;

      if ((payload.firstLoaded || payload.isWebSocketResponse) && !state.isFirstLoaded) {
        state.isFirstLoaded = true;
      }

      const stringifiedPayload = payload.isWebSocketResponse ? '' : JSON.stringify(payload.quotes);

      if (payload.isWebSocketResponse || state.stringifiedQuotes !== stringifiedPayload) {
        if (!payload.isWebSocketResponse) {
          state.stringifiedQuotes = stringifiedPayload;
        }

        state.quotes = payload.quotes.reduce(
          (acc: Record<string, CashOutQuote>, item) => ({
            ...acc,
            [item.marketId]: item
          }),
          {}
        );
      }
    },
    failFetchCashOutQuotes: (
      state,
      { payload }: PayloadAction<{ error: TFailureActionPayload; isEvent?: boolean }>
    ) => {
      state.error = payload.error;
      state.loading = false;
      state.fetchCashOutLoading = false;
    },
    fetchCashOutMarkets: (
      state,
      {
        payload
      }: PayloadAction<{
        page: number;
        size: number;
        withLoader?: boolean;
        isUpdated?: boolean;
        resetPrev?: boolean;
        isPaginationEnabled?: boolean;
        changePage?: (page: number) => void; // This function required to avoid cases when we make request with a page that is bigger than the available page. Required if isPaginationEnabled = true
      }>
    ) => {
      state.loading = true;
      state.fetchCashOutMarketsLoading = true;

      if (payload.withLoader) {
        state.paginationLoader = true;
      }

      if (payload.resetPrev) {
        state.marketCashOut = initialState.marketCashOut;
        state.isFirstLoaded = false;
        state.stringifiedMarkets = '';
      }
    },
    successFetchCashOutMarkets: (state, { payload }: PayloadAction<MarketCashOutResponse & { isUpdated: boolean }>) => {
      const stringifiedPayload = JSON.stringify(payload.markets);
      const isMarketsNotUpdated = state.stringifiedMarkets === stringifiedPayload;

      if (isMarketsNotUpdated) {
        if (state.marketCashOut.totalElements === 0) {
          state.isFirstLoaded = true;
        }
      } else {
        const content = getContentCashOutPage(state.marketCashOut, payload);

        state.marketCashOut = {
          ...payload.markets,
          content
        };
        state.stringifiedMarkets = stringifiedPayload;

        if (payload.markets.totalElements === 0) {
          state.isFirstLoaded = true;
        }
      }

      state.paginationLoader = false;
      state.loading = false;
      state.fetchCashOutMarketsLoading = false;
    },
    failFetchCashOutMarkets: (
      state,
      { payload }: PayloadAction<{ error: TFailureActionPayload; withLoader?: boolean }>
    ) => {
      state.error = payload.error;
      state.loading = false;
      state.fetchCashOutMarketsLoading = false;
      state.paginationLoader = false;
    },
    hoverCashOutBtn: (state, { payload }: PayloadAction<{ marketId: string; selections: ProfitSelection[] }>) => {
      state.whatIfMarketId = payload.marketId;
      state.whatIf = payload.selections.reduce(
        (acc: Record<string, ProfitSelection>, item) => ({
          ...acc,
          [item.selectionId]: item
        }),
        {}
      );
    },
    leaveCashOutBtn: state => {
      state.whatIfMarketId = null;
      state.whatIf = {};
    },
    setPossibleProfit: (state, { payload }: PayloadAction<ProfitPayload>) => {
      state.possibleProfits[payload.marketId] = payload;
    },
    successCreateAutoCashOut: (state, { payload }: PayloadAction<AutoCashOut>) => {
      state.successFullCreatedAutoCashOuts[payload.marketId] = true;
      delete state.autoCashOutErrors[payload.marketId];
    },
    fetchAutoCashOutMarkets: (_, __: PayloadAction<string[]>) => {},
    successAutoCashOutMarkets: (
      state,
      {
        payload
      }: PayloadAction<{ autoCashOuts: AutoCashOut[]; stringifiedAutoCashOuts?: string; isWebSocketResponse: boolean }>
    ) => {
      state.autoCashOuts = payload.autoCashOuts.reduce(
        (acc, autoCashOut) => ({
          ...acc,
          [autoCashOut.marketId]: autoCashOut
        }),
        {}
      );

      if (!payload.isWebSocketResponse && payload.stringifiedAutoCashOuts) {
        state.stringifiedAutoCashOuts = payload.stringifiedAutoCashOuts;
      }
    },
    failAutoCashOutMarkets: (_, __: PayloadAction<string>) => {},
    successDeleteAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.possibleProfits[payload];
      state.successFullDeletedAutoCashOuts[payload] = true;
    },
    autoCashOutAutoDeleted: (state, { payload }: PayloadAction<string>) => {
      delete state.possibleProfits[payload];
    },
    removeSuccessFullDeletedAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.autoCashOuts[payload];
      delete state.successFullDeletedAutoCashOuts[payload];
    },
    removeSuccessFullCreatedAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.successFullCreatedAutoCashOuts[payload];

      if (state.autoCashOuts[payload]) {
        state.cashedValues[payload] = state.autoCashOuts[payload].profit;
      }
    },
    fetchSetCashOutMarket: (state, _: PayloadAction<TSetCashOut>) => {
      state.whatIf = {};
      state.whatIfMarketId = null;
      state.loading = true;
    },
    successFetchSetCashOutMarket: (state, { payload }: PayloadAction<CashOutMarket>) => {
      state.loading = false;
      state.placedIds[payload.marketId] = payload.offers[0];
      state.pendingCashOuts[payload.marketId] = payload.id;
      state.cashOutStatuses[payload.marketId] = CashOutStatuses.SUCCESS;
    },
    failFetchSetCashOutMarket: (state, { payload }: PayloadAction<{ status: string; marketId: string }>) => {
      state.cashOutStatuses[payload.marketId] = payload.status;
      delete state.pendingCashOuts[payload.marketId];
    },
    errorFetchSetCashOutMarket: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.loading = false;
    },
    cleanCashOutPlacedId: (state, { payload }: PayloadAction<{ marketId: string }>) => {
      delete state.placedIds[payload.marketId];
    },
    fetchCashOutStatus: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.loading = true;
      state.statusLoading[payload.id] = true;
    },
    cleanCashOutStatus: (state, { payload }: PayloadAction<{ marketId: string }>) => {
      delete state.cashOutStatuses[payload.marketId];
    },
    successFetchCashOutStatus: (
      state,
      {
        payload
      }: PayloadAction<{
        statusId: number;
        status: string;
        marketId: string;
      }>
    ) => {
      state.loading = false;
      state.statusLoading[payload.statusId] = false;

      if (payload.status !== CashOutStatuses.PENDING) {
        delete state.pendingCashOuts[payload.marketId];
        state.cashOutStatuses[payload.marketId] = payload.status;
      }
    },
    failFetchCashOutStatus: (state, { payload }: PayloadAction<{ error: string[]; statusId: number }>) => {
      state.loading = false;
      state.error = payload.error;
      state.statusLoading[payload.statusId] = false;
    },
    openSettingsTab: (state, { payload }: PayloadAction<string>) => {
      state.settingTabsStates[payload] = true;

      if (state.autoCashOuts[payload]) {
        state.cashedValues[payload] = state.autoCashOuts[payload].profit;
      }
    },
    closeSettingsTab: (state, { payload }: PayloadAction<string>) => {
      delete state.settingTabsStates[payload];
    },
    fetchCashOutMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchCashOutMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;

      if (!state.rules) {
        state.rules = {};
      }

      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchCashOutMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesError = payload;
      state.rulesLoading = false;
    },
    removeAutoCashOutMarket: (state, { payload }: PayloadAction<{ marketId: string }>) => {
      delete state.autoCashOuts[payload.marketId];
    },
    resetCashOut: () => initialState,
    createAutoCashOut: (_, __: PayloadAction<CreateAutoCashOutPayload>) => {},
    deleteAutoCashOut: (_, __: PayloadAction<{ marketId: string; id: number }>) => {},
    failCreateAutoCashOut: (state, action: PayloadAction<{ errorMessage: string; marketId: string }>) => {
      state.autoCashOutErrors = {
        ...state.autoCashOutErrors,
        [action.payload.marketId]: action.payload.errorMessage
      };
    },
    resetAutoCashOutError: (state, { payload }: PayloadAction<string>) => {
      delete state.autoCashOutErrors[payload];
    },
    failureDeleteAutoCashOut: (_, __: PayloadAction<string>) => {}
  }
});

export const {
  failureFetchCashOutMarketRules,
  successFetchCashOutMarketRules,
  errorFetchSetCashOutMarket,
  removeAutoCashOutMarket,
  autoCashOutAutoDeleted,
  cleanCashOutPlacedId,
  cleanCashOutStatus,
  failFetchSetCashOutMarket,
  failAutoCashOutMarkets,
  failFetchCashOutStatus,
  failFetchCashOutMarkets,
  resetCashOut,
  fetchAutoCashOutMarkets,
  fetchCashOutMarketRules,
  successDeleteAutoCashOut,
  successCreateAutoCashOut,
  removeSuccessFullCreatedAutoCashOut,
  successFetchCashOutStatus,
  failFetchCashOutQuotes,
  successFetchSetCashOutMarket,
  fetchCashOutMarkets,
  successAutoCashOutMarkets,
  fetchCashOutStatus,
  fetchCashOutQuotes,
  fetchSetCashOutMarket,
  leaveCashOutBtn,
  hoverCashOutBtn,
  successFetchCashOutQuotes,
  openSettingsTab,
  closeSettingsTab,
  setPossibleProfit,
  failCreateAutoCashOut,
  failureDeleteAutoCashOut,
  successFetchCashOutMarkets,
  deleteAutoCashOut,
  createAutoCashOut,
  removeSuccessFullDeletedAutoCashOut,
  resetAutoCashOutError
} = slice.actions;

export default slice.reducer;
