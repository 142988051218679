import { createSelector } from '@reduxjs/toolkit';
import { findLast, last, some, uniq } from 'lodash';

import { PageBlocks, PlacementPages } from 'constants/app';
import { BetsStatusesTypes } from 'constants/app';
import { BetErrors, BetslipTypes, PlaceBetsStates } from 'constants/betslip';
import { BetSides } from 'constants/myBets';
import { getBetsStatuses } from 'redux/modules/betsStatuses/selectors';
import { AppState } from 'redux/reducers';
import { BetSide } from 'types/myBets';
import { checkUniqSelectedBetKey, getIsSelectedBetActive } from 'utils/betslip';

import { PlacementNotification, PlacementNotificationsMap, SelectedBetState, TSelectedBet } from './type';

const getBetslip = (state: AppState) => state.betslip;

export const getBetslipLoading = ({ betslip }: AppState) => betslip.loading;
export const getConsolidatedBets = ({ betslip }: AppState) => betslip.consolidateBets;
export const getBetslipActiveTab = ({ betslip }: AppState) => betslip.activeTab;
export const getBetslipCollapse = ({ betslip }: AppState) => betslip.collapse;
export const getBetslipPlaceBetsState = ({ betslip }: AppState) => betslip.placeBetsState;
export const getIsFirstOpenedBetFocused = ({ betslip }: AppState) => betslip.isFirstOpenedBetFocused;
export const getIsGameBetSlip = ({ betslip }: AppState) => betslip.betslipType === BetslipTypes.GAME;
export const getPlacedBets = ({ betslip }: AppState) => betslip.placedBets;
export const getBetSlipSelectedBets = ({ betslip }: AppState) => betslip.selectedBets;
export const getPlacementNotifications = createSelector(
  getBetslip,
  getIsGameBetSlip,
  ({ placementNotifications }, isGameBetSlip) => {
    if (isGameBetSlip) {
      return Object.entries(placementNotifications).reduce<PlacementNotificationsMap>((acc, [betUuid, betInfo]) => {
        if (betInfo.bet.pageBlock === PageBlocks.GAME || betInfo.bet.page === PlacementPages.GAME) {
          return {
            ...acc,
            [betUuid]: betInfo
          };
        }

        return acc;
      }, {});
    }

    return Object.entries(placementNotifications).reduce<PlacementNotificationsMap>((acc, [betUuid, betInfo]) => {
      if (betInfo.bet.pageBlock === PageBlocks.GAME || betInfo.bet.page === PlacementPages.GAME) {
        return acc;
      }

      return {
        ...acc,
        [betUuid]: betInfo
      };
    }, {});
  }
);
export const getIsPendingNotifications = createSelector(
  getPlacementNotifications,
  getBetsStatuses,
  (notificationsMap, betStatuses) => {
    const notifications: PlacementNotification[] = Object.values(notificationsMap);
    return notifications.some(
      ({ offerId, error }) =>
        (!offerId && !error) ||
        (offerId && !betStatuses[offerId]) ||
        (offerId && betStatuses[offerId].status === BetsStatusesTypes.PENDING)
    );
  }
);
export const getHasPlacementNotifications = ({ betslip }: AppState) =>
  !!Object.keys(betslip.placementNotifications).length;

export const getSelectedBets = createSelector(getBetslip, getIsGameBetSlip, ({ selectedBets }, isGameBetSlip) => {
  let totalBets: TSelectedBet[] = [];

  Object.values(selectedBets).forEach(betsByMarket => {
    Object.values(betsByMarket.bets ?? {}).forEach(betsByType => {
      Object.values(betsByType).forEach(bet => {
        if (isGameBetSlip) {
          if (bet.pageBlock === PageBlocks.GAME) {
            totalBets = [...totalBets, bet];
          }
        } else if (bet.pageBlock !== PageBlocks.GAME) {
          totalBets = [
            ...totalBets,
            { ...bet, commission: betsByMarket.marketInfo?.commission, runners: betsByMarket.marketInfo?.runners ?? [] }
          ];
        }
      });
    });
  });

  return totalBets;
});

export const getSelectedBetsAmount = createSelector(getSelectedBets, bets => bets.length);

export const getActiveSelectedBets = createSelector(getSelectedBets, bets =>
  bets.filter(bet => getIsSelectedBetActive(bet))
);

export const getDisabledSelectedBets = createSelector(getSelectedBets, bets => bets.filter(bet => bet.isDisabled));

export const getBackLessLayError =
  (marketId: string) =>
  ({ betslip }: AppState) =>
    some(betslip.selectedBets[marketId]?.bets?.[BetSides.Back] ?? {}, bet => bet.error === BetErrors.EX015);

export const getFirstSelectedBet = createSelector(getBetslip, betslip => {
  const markets = Object.keys(betslip.selectedBets);
  const firstMarketId = last(markets);

  if (markets.length && firstMarketId) {
    return (
      findLast(betslip.selectedBets[firstMarketId]?.bets?.[BetSides.Back]) ||
      findLast(betslip.selectedBets[firstMarketId]?.bets?.[BetSides.Lay]) ||
      null
    );
  } else {
    return null;
  }
});

export const getPlacedBetsToUpdate = ({ betslip }: AppState) => betslip.placedBetsToUpdate;
export const getSelectedBetsForWhatIf = ({ betslip }: AppState) => betslip.selectedBets;
// Last added market - first in the list
export const getSelectedMarkets = createSelector(getBetslip, getIsGameBetSlip, ({ selectedBets }, isGameBetSlip) => {
  return Object.entries(selectedBets)
    .filter(([, { marketInfo }]) => {
      return isGameBetSlip ? !!marketInfo?.gameName : !marketInfo?.gameName;
    })
    .map(([marketId]) => marketId)
    .reverse();
});

export const getSelectedMarketsWithActiveBets = createSelector(
  getSelectedMarkets,
  getBetslip,
  (selectedMarkets, betslip) => {
    return selectedMarkets.reduce<string[]>((list, marketId) => {
      const betsData = betslip.selectedBets[marketId]?.bets;
      let hasActiveBet = false;

      Object.values(betsData ?? {}).forEach(betsByType => {
        hasActiveBet = hasActiveBet || Object.values(betsByType).some(bet => getIsSelectedBetActive(bet));
      });

      if (hasActiveBet) {
        list.push(marketId);
      }

      return list;
    }, []);
  }
);

export const getSelectedMarketsEvents = createSelector(getBetslip, betslip =>
  Object.entries(betslip.selectedBets).map(([marketId, { marketInfo }]) => {
    return { marketId: marketId, eventId: marketInfo?.eventId ?? marketInfo?.event?.id ?? '' };
  })
);

export const getSelectedBetsByMarketSideType = (marketId: string, betType: BetSide) =>
  createSelector(getBetslip, betslip => Object.values(betslip.selectedBets[marketId]?.bets?.[betType] ?? {}).reverse());

export const getSelectedBetsListByMarket = (curMarketId: string) =>
  createSelector(getBetslip, betslip => {
    let totalBets: TSelectedBet[] = [];

    Object.values(betslip.selectedBets[curMarketId]?.bets ?? {}).forEach(betsByType => {
      Object.values(betsByType).forEach(bet => {
        totalBets = [...totalBets, bet];
      });
    });

    return totalBets;
  });

export const getTotalMarketsLiability = ({ betslip }: AppState) => {
  return Object.keys(betslip.selectedBets).reduce((totalLiability, marketId) => {
    totalLiability += betslip.liabilityByMarket[marketId] ?? 0;
    return totalLiability;
  }, 0);
};

export const getIsMarketsLimitNotification = ({ betslip }: AppState) => betslip.selectedMarketsLimitNotification;

export const getSelectedMarketInfo =
  (marketId: string) =>
  ({ betslip }: AppState) =>
    betslip.selectedBets[marketId]?.marketInfo;
export const getSelectedMarketState =
  (marketId: string) =>
  ({ betslip }: AppState) =>
    betslip.selectedBets[marketId]?.state;
export const getBetSlipUnmatchedOfferErrorById =
  (offerId?: number) =>
  ({ betslip }: AppState) =>
    offerId ? betslip.unmatchedOffersWithErrors[offerId] : undefined;
export const getIsBetSlipUnmatchedOfferErrorById =
  (offerId?: number) =>
  ({ betslip }: AppState) =>
    offerId ? !!betslip.unmatchedOffersWithErrors[offerId] : false;

export const getIsSelectedBetsPlacementValid = createSelector(getSelectedBets, selectedBets => {
  return selectedBets.some(bet => getIsSelectedBetActive(bet));
});

export const getSelectedBetValidationError =
  ({ marketId, betType, betUuid }: { marketId: string; betType: BetSide; betUuid: string }) =>
  ({ betslip }: AppState) =>
    betslip.selectedBets[marketId]?.bets?.[betType]?.[betUuid]?.validationMessage;

export const getIsBetSelected =
  ({
    marketId,
    selectionId,
    handicap,
    type
  }: {
    marketId: string;
    selectionId: number;
    handicap?: number | string | null;
    type: BetSide;
  }) =>
  ({ betslip }: AppState) => {
    return !!Object.values(betslip.selectedBets[marketId]?.bets?.[type] ?? {}).find(
      bet =>
        bet.state === SelectedBetState.NONE &&
        checkUniqSelectedBetKey({ betKey: bet.betUuid ?? '', marketId, selectionId, handicap })
    );
  };

export const getSelectedMarketsAmount = createSelector(getSelectedMarkets, markets => markets.length);

export const getSelectedBetsAmountByMarket = (marketId: string) =>
  createSelector(getSelectedBetsListByMarket(marketId), bets => bets.length);

export const getSelectedBetsAmountToPlace = createSelector(
  getSelectedBets,
  bets =>
    bets.filter(
      ({ state }) =>
        state === SelectedBetState.NONE || state === SelectedBetState.PROGRESS || state === SelectedBetState.ERROR
    )?.length
);

export const getNewSelectedBetsAmount = createSelector(
  getSelectedBets,
  bets =>
    bets.filter(
      ({ isDisabled, state }) => (state === SelectedBetState.NONE || state === SelectedBetState.ERROR) && !isDisabled
    )?.length
);

export const getIsSelectedBets = createSelector(getBetslip, ({ selectedBets }) => !!Object.keys(selectedBets).length);

export const getSelectedBetsMarketIds = createSelector(getBetslip, ({ selectedBets }) => Object.keys(selectedBets));

export const getIsSelectedMarketInPlacementProcess = (curMarketId: string) =>
  createSelector(
    getSelectedBetsListByMarket(curMarketId),
    bets => bets.length && bets.every(bet => bet.state === SelectedBetState.PROGRESS)
  );

export const getIsAtLeastOneCancelledSelectedBet = createSelector(getSelectedBets, selectedBets => {
  return selectedBets.some(({ isCancelled }) => isCancelled);
});

export const getSelectedMarketRunnersNames = (marketId: string) =>
  createSelector(getSelectedMarketInfo(marketId), marketInfo =>
    uniq(
      marketInfo?.runners?.map(({ runnerName }) =>
        (runnerName ?? '').replace(/([\w\s\(\)]*(?!\s&))\s+([+-]*)([\d\.]*)\d*(\s*&?\s*[+-]?\d*(?:\.)?\d*)?/g, '$1')
      )
    )
  );

export const getSelectedBetIndex =
  (betUuid: string) =>
  ({ betslip }: AppState) =>
    betslip.selectedBetsUuids.indexOf(betUuid);

export const getBetslipFocusedButton = ({ betslip }: AppState) => betslip.focusedButton;

export const getIsCheckMarketsLoading = ({ betslip }: AppState) => betslip.checkMarketsLoading;

export const getUnmatchedBetProgress =
  (offerId: number | string) =>
  ({ betslip }: AppState) =>
    betslip.unmatchedBetsProgresses[offerId];

export const getIsOpenedBetSlipDutchingCalculator =
  ({ marketId, type }: { marketId: string; type: BetSide }) =>
  ({ betslip }: AppState) =>
    !!betslip.dutchingCalculatorsByMarketIdAndType[marketId]?.[type]?.isOpened;

export const getIsMinStakeMessageBetSlipDutchingCalculator =
  ({ marketId, type }: { marketId: string; type: BetSide }) =>
  ({ betslip }: AppState) =>
    !!betslip.dutchingCalculatorsByMarketIdAndType[marketId]?.[type]?.isMinStakeMessage;

export const getIsMaxStakeMessageBetSlipDutchingCalculator =
  ({ marketId, type }: { marketId: string; type: BetSide }) =>
  ({ betslip }: AppState) =>
    !!betslip.dutchingCalculatorsByMarketIdAndType[marketId]?.[type]?.isMaxStakeMessage;

export const getSelectedBetsForDutchingCalculator = ({ marketId, type }: { marketId: string; type: BetSide }) =>
  createSelector(getBetSlipSelectedBets, selectedBets => {
    if (selectedBets) {
      const betsByType = selectedBets[marketId]?.bets?.[type];

      if (betsByType) {
        return Object.values(betsByType).filter(({ price }) => !!Number(price));
      }

      return null;
    }

    return null;
  });

export const getIsAtLeastOneOpenedBetSlipDutchingCalculator = createSelector(
  getBetslip,
  ({ dutchingCalculatorsByMarketIdAndType }) =>
    Object.entries(dutchingCalculatorsByMarketIdAndType).some(([, byType]) =>
      Object.values(byType).some(({ isOpened }) => isOpened)
    )
);

export const getIsBetslipConfirmationStep = ({ betslip }: AppState) =>
  betslip.placeBetsState === PlaceBetsStates.CONFIRM;
