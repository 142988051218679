import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { SportIds } from 'constants/app';
import {
  ASIAN_VIEW_PAGE_PARAM,
  AsianMobileNavTypes,
  AsianViewMarketLinks,
  AsianViewTimeFilters
} from 'constants/asianView';
import { asianViewMiddleSection } from 'constants/branding';
import { ASIAN_BASE_URL } from 'constants/locations';
import { getIsMobileInfiniteScrollEnabled } from 'redux/modules/appConfigs/selectors';
import { resetContent } from 'redux/modules/asianView';
import { TAsianMobileNavigationItem } from 'types/asianView';
import { getIsValidMarketLink } from 'utils/asianView';

import styles from './styles.module.scss';

interface IViewByLinkProps {
  navLink: TAsianMobileNavigationItem;
  setIsOpened: (isOpened: boolean) => void;
}
const ViewByLink = ({ navLink, setIsOpened }: IViewByLinkProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { sportId = SportIds.SOCCER, marketLink, timeFilter: timeFilterParam, timeFrame: timeFrameParam } = useParams();

  const isInfiniteScrollEnabled = useSelector(getIsMobileInfiniteScrollEnabled);

  const { type, timeFrame, label, counterValue } = navLink;

  const isOutrightMarketLink = marketLink === AsianViewMarketLinks.OUTRIGHT;
  const isOutRights = type === AsianMobileNavTypes.OUTRIGHTS;
  const timeFrameNav = timeFrame ? `/timeFrame/${timeFrame}` : '';
  const timeFilterNav = isOutRights ? AsianViewTimeFilters.Today : type;
  const marketLinkNav = isOutRights
    ? AsianViewMarketLinks.OUTRIGHT
    : isOutrightMarketLink || !marketLink || !getIsValidMarketLink(marketLink)
    ? AsianViewMarketLinks.HDP_AND_OU
    : marketLink;

  const isActive = isOutrightMarketLink
    ? isOutRights
    : !timeFrameParam && !timeFrame
    ? type === timeFilterParam
    : timeFrameParam === timeFrame;

  const handleClickLink = () => {
    setIsOpened(false);

    if (marketLinkNav !== marketLink) {
      dispatch(resetContent());
    }
  };

  return (
    <Link
      to={{
        pathname: `${ASIAN_BASE_URL}/sport/${sportId}/timeFilter/${timeFilterNav}/marketLink/${marketLinkNav}${timeFrameNav}`,
        search: isInfiniteScrollEnabled ? undefined : `${ASIAN_VIEW_PAGE_PARAM}=0`
      }}
      className={classNames(styles.navItem, asianViewMiddleSection.VIEW_BY_ITEM, {
        biab_active: isActive,
        [styles.navItem__subItem]: !!timeFrame,
        [styles.navItem__active]: isActive
      })}
      onClick={handleClickLink}
    >
      <span>
        {label}
        {type === AsianMobileNavTypes.EARLY && !timeFrame && ` - ${t('asianView.labels.timeFilter.allDates')}`}
      </span>
      <span
        className={classNames(styles.navItem__counter, {
          [asianViewMiddleSection.VIEW_BY_ITEM_COUNTER]: type === AsianMobileNavTypes.LIVE,
          [styles.navItem__counter__inPlay]: type === AsianMobileNavTypes.LIVE
        })}
      >
        {counterValue}
      </span>
    </Link>
  );
};

export default ViewByLink;
