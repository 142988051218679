import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import BetPersistenceType from 'components/Betslip/components/OpenedBet/components/BetPersistenceType';
import { SportIds } from 'constants/app';
import { BettingTypes } from 'constants/markets';
import { useIsMobilePlacementDisabled } from 'hooks/useMobilePlacement';
import { getMobileSwapColorsFancyMarketsOnCricket } from 'redux/modules/appConfigs/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getMarketDataById } from 'redux/modules/placement/selectors';
import { PageBlock } from 'types';
import { PersistenceType } from 'types/bets';

import styles from './PersistenceRadioGroup.module.scss';

type PersistenceRadioGroupProps = {
  bet: TInlineSelectedBet;
  persistenceType: PersistenceType;
  setPersistenceType: Dispatch<SetStateAction<PersistenceType>>;
  pageBlock: PageBlock;
};

const PersistenceRadioGroup = ({ bet, setPersistenceType, persistenceType, pageBlock }: PersistenceRadioGroupProps) => {
  const market = useSelector(getMarketDataById(pageBlock, bet.marketId, bet.sportId));
  const swapColorsFancyMarketsOnCricket = useSelector(getMobileSwapColorsFancyMarketsOnCricket);

  const { isDisabled } = useIsMobilePlacementDisabled(bet);

  const isFancySwapColors =
    bet.sportId.toString() === SportIds.CRICKET &&
    swapColorsFancyMarketsOnCricket &&
    market?.description.bettingType === BettingTypes.LINE;

  return (
    <BetPersistenceType
      offerId={bet.currentOfferId ?? 0}
      persistenceType={persistenceType}
      changePersistenceType={setPersistenceType}
      side={bet.type}
      isDisabled={isDisabled}
      isFancySwapColors={isFancySwapColors}
      containerClassName={styles.container}
      textClassName={styles.text}
    />
  );
};

export default PersistenceRadioGroup;
