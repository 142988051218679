import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { rgToolsBranding } from 'constants/branding';
import {
  SELF_EXCLUSION_REMINDER_MINUTES,
  UpdatedResponsibleBettingDisplayedContents
} from 'constants/responsibleBetting';
import { UpdatedResponsibleBettingDisplayedContentKeys } from 'types/responsibleBetting';
import { calculateResponsibleBettingScaleWidth } from 'utils/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingScaleProps {
  availableTimeLimit?: number | null;
  currentLimit: number | null;
  spentLimit: number | null;
  availableLimitValue: string;
  spentLimitValue?: string;
  availableLabel: string;
  spentLabel?: string;
  shouldDisplayPeriod: boolean;
  limitPeriod: string;
  type: UpdatedResponsibleBettingDisplayedContentKeys;
}

const ResponsibleBettingScale = ({
  availableTimeLimit = null,
  currentLimit,
  spentLimit,
  availableLimitValue,
  spentLimitValue = '',
  availableLabel,
  spentLabel = '',
  shouldDisplayPeriod,
  limitPeriod,
  type
}: ResponsibleBettingScaleProps) => {
  const { t } = useTranslation();

  const isTimeLimitScale = type === UpdatedResponsibleBettingDisplayedContents.TimeLimit;
  const isLossLimitScale = type === UpdatedResponsibleBettingDisplayedContents.LossLimit;
  const isFinalCountdown = availableTimeLimit && availableTimeLimit <= SELF_EXCLUSION_REMINDER_MINUTES;

  const { spent, available } = useMemo(
    () => calculateResponsibleBettingScaleWidth(currentLimit, spentLimit),
    [currentLimit, spentLimit]
  );

  return (
    <div className={styles.container}>
      <div className={styles.limitContainer}>
        <div className={styles.limitItem}>
          <div
            className={classNames(styles.badge, styles.badge__available, {
              [styles.badge__disabled]: isTimeLimitScale && availableTimeLimit !== null && availableTimeLimit <= 0,
              [styles.badge__spent]: isTimeLimitScale && isFinalCountdown,
              [rgToolsBranding.LOSS_LIMIT]: isLossLimitScale,
              [rgToolsBranding.TIME_LIMIT]: isTimeLimitScale,
              [rgToolsBranding.LIMIT_SPENT]: isTimeLimitScale && availableTimeLimit !== null && availableTimeLimit <= 0,
              [rgToolsBranding.LIMIT_LESS_AVAILABLE]: isTimeLimitScale && isFinalCountdown
            })}
          />
          <span className={styles.limit}>{availableLimitValue}</span>
          <span className={styles.limitLabel}>{availableLabel}</span>
        </div>
        {!isTimeLimitScale && spentLimit && spentLimit > 0 ? (
          <div className={styles.limitItem}>
            <div
              className={classNames(
                styles.badge,
                styles.badge__spent,
                rgToolsBranding.LOSS_LIMIT,
                rgToolsBranding.LIMIT_SPENT
              )}
            />
            <span className={classNames(styles.limit, styles.limit__spent)}>{spentLimitValue}</span>
            <span className={styles.limitLabel}>{spentLabel}</span>
          </div>
        ) : null}
      </div>
      <div className={styles.scaleContainer}>
        <div
          className={classNames(styles.available, {
            [styles.available__countdown]: isTimeLimitScale && isFinalCountdown,
            [rgToolsBranding.LOSS_LIMIT]: isLossLimitScale,
            [rgToolsBranding.TIME_LIMIT]: isTimeLimitScale,
            [rgToolsBranding.LIMIT_LESS_AVAILABLE]: isTimeLimitScale && isFinalCountdown
          })}
          style={{ width: `${available}%` }}
        />
        <div
          className={classNames(styles.spent, rgToolsBranding.LIMIT_SPENT, {
            [styles.spent__disabled]: isTimeLimitScale,
            [rgToolsBranding.LOSS_LIMIT]: isLossLimitScale,
            [rgToolsBranding.TIME_LIMIT]: isTimeLimitScale
          })}
          style={{ width: `${spent}%` }}
        />
      </div>
      {shouldDisplayPeriod && (
        <div className={styles.periodContainer}>
          <span className={styles.period}>{t('responsibleBetting.labels.currentPeriod')}:</span>
          <span className={styles.period}>{limitPeriod}</span>
        </div>
      )}
    </div>
  );
};

export default ResponsibleBettingScale;
