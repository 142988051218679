import { useTranslation } from 'react-i18next';

import { OddsTypes } from 'constants/app';
import { OddsType } from 'types';
import { BetSide } from 'types/myBets';
import convertOdds from 'utils/oddsConverter';

import styles from './AVBetListMobilePriceAndOddsType.module.scss';

type AVBetListMobilePriceAndOddsTypeProps = {
  price: number;
  oddsType?: OddsType;
  side: BetSide;
};

const AVBetListMobilePriceAndOddsType = ({ price, oddsType, side }: AVBetListMobilePriceAndOddsTypeProps) => {
  const { t } = useTranslation();
  const priceValue = convertOdds(price || '', oddsType || OddsTypes.DEC);

  return (
    <div className={styles.container}>
      <p className={styles.container__side}>{t(`betslip.labels.type.${side.toLowerCase()}`)}</p>
      <p className={styles.container__priceAndOddsType}>
        @ {priceValue} ({t(`asianView.labels.betslip.oddsType.${oddsType || OddsTypes.DEC}`)})
      </p>
    </div>
  );
};

export default AVBetListMobilePriceAndOddsType;
