import { useSelector } from 'react-redux';

import CashOutButton from 'components/CashOutButton';
import ShowFullMarket from 'components/CashOutCard/partials/ShowFullMarket';
import ShowHideRunners from 'components/CashOutCard/partials/ShowHideRunners';
import SubHeader from 'components/CashOutCard/partials/SubHeader';
import { MarketStatuses } from 'constants/app';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getLanguage } from 'redux/modules/appConfigs/selectors';
import { getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { IMarket } from 'types/markets';

import HeaderCashOutMarket from './partials/HeaderCashOutMarket';

import styles from './styles.module.scss';

type CashOutCardProps = {
  market: IMarket;
  isMobile: boolean;
  openMarketRulesModal: (marketId: string) => void;
};

const CashOutCard = ({ market, isMobile, openMarketRulesModal }: CashOutCardProps) => {
  const language = useSelector(getLanguage);
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(market.marketId));

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market?.marketId,
    eventId: market?.event.id,
    observerOptions: { rootMargin: '200px' },
    section: MarketsPricesSocketParamsSections.SportsMiddleSection
  });

  const isMarketClosed = marketPricesStatus === MarketStatuses.CLOSED;

  return (
    <div
      ref={ref}
      className={styles.main}
      data-market-id={market?.marketId}
      data-event-id={market?.event.id}
      data-market-prices={true}
      data-card="cash-out"
    >
      <HeaderCashOutMarket market={market} />
      <SubHeader
        isMarketClosed={isMarketClosed}
        market={market}
        inPlay={market.inPlay}
        name={market.marketNameTranslations?.[language]}
        openMarketRulesModal={openMarketRulesModal}
      />
      {!isMarketClosed && (
        <CashOutButton marketId={market.marketId} partialCashOutEnabled={market.partialCashOutEnabled} />
      )}
      {!isMobile && <ShowHideRunners market={market} />}
      {!isMobile && <ShowFullMarket market={market} />}
    </div>
  );
};

export default CashOutCard;
