import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SportIds } from 'constants/app';
import { getAsianViewCashOutSportsEnabled } from 'redux/modules/appConfigs/selectors';

const useAsianCashOut = () => {
  const { sportId = SportIds.SOCCER } = useParams();
  const asianViewCashOutSportsEnabled = useSelector(getAsianViewCashOutSportsEnabled);

  return asianViewCashOutSportsEnabled.includes(sportId);
};

export default useAsianCashOut;
