import { ChangeEvent, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { CookieNames } from 'constants/app';
import { getFancyMarketData } from 'redux/modules/fancyMarket/selectors';
import { getBooleanValue } from 'utils';

import FancyMarketRules from '../FancyMarketRules';

import styles from './styles.module.scss';

const FancyMarketMobileInfo = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.MOBILE_OPEN_BETS]);

  const { inningsFancy } = useSelector(getFancyMarketData);

  const [isFavourite, setIsFavourite] = useState<boolean>(false);

  const isOpenBets = getBooleanValue(cookies.BIAB_MOBILE_OPEN_BETS ?? '');
  const handleOpenBets = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setCookie(CookieNames.MOBILE_OPEN_BETS, checked, { path: '/' });
  };

  const handleFavourite = () => {
    //TODO most likely state for favourite should be removed in the future and favourite feature should be handled with api
    setIsFavourite(prevState => !prevState);
  };

  return (
    <div className={styles.fancyMarketMobileInfo}>
      {!inningsFancy.length && (
        <i
          className={classNames('fa fa-star-o biab_mobile-market-star-icon', styles.fancyMarketMobileInfo__icon, {
            biab_active: isFavourite
          })}
          onClick={handleFavourite}
        />
      )}
      {inningsFancy.length > 0 && <FancyMarketRules />}
      <span className={styles.fancyMarketMobileInfo__openBets__container}>
        <input
          type="checkbox"
          name="mobile-open-bets-checkbox"
          id="fancy-mobile-open-bets"
          className={styles.fancyMarketMobileInfo__openBets__checkbox}
          onChange={handleOpenBets}
          checked={isOpenBets}
        />
        <label htmlFor="fancy-mobile-open-bets" className={styles.fancyMarketMobileInfo__openBets__label}>
          {t('market.mobileOpenBets')}
        </label>
      </span>
      <button className={classNames('biab_market-refresh', styles.fancyMarketMobileInfo__refresh)}>
        {t('market.refresh')}
      </button>
    </div>
  );
};

export default FancyMarketMobileInfo;
