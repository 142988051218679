import { memo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import { CookieNames } from 'constants/app';
import { componentsBranding } from 'constants/branding';
import { ASIAN_VIEW_BETTING_DAY } from 'constants/tooltip';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import useDevice from 'hooks/useDevice';
import useTooltip from 'hooks/useTooltip';
import dropdownStyles from 'pages/MyBetsPages/dropdown.module.scss';
import { getIsCancelActionsInProgress } from 'redux/modules/cancelActions/selectors';
import { DropdownItem, TimeFormats } from 'types';
import { convertTimeFormatToDropdown } from 'utils';

import styles from './styles.module.scss';

type TimeFormatDropdownProps = {
  onChange: (value: string) => void;
};

const TimeFormatDropdown = ({ onChange }: TimeFormatDropdownProps) => {
  const { t } = useTranslation();
  const { translationKey } = useTooltip(ASIAN_VIEW_BETTING_DAY);
  const convertedData = convertTimeFormatToDropdown(t, translationKey);
  const [cookies, setCookie] = useCookies([CookieNames.TIME_FORMAT]);
  const { isMobile, isAsianViewPage } = useDevice();
  const [openModal, setOpenModal] = useState(false);

  const { isMyBetsPage } = useAccountUrlParams();
  const isCancellingBets = useSelector(getIsCancelActionsInProgress);

  const currentTimeFormat = cookies[CookieNames.TIME_FORMAT] || TimeFormats.LOCAL_TIME;
  const selectedItem = convertedData.find(item => item.value === `${currentTimeFormat}`);

  const onSelectTimeFormat = (timeFormat: DropdownItem) => {
    onChange(timeFormat.value);
    setCookie(CookieNames.TIME_FORMAT, timeFormat.value, {
      path: '/'
    });
  };

  const dropdownBranding = {
    dropdownItems: componentsBranding.DROPDOWN_LIST,
    dropdownItem: componentsBranding.DROPDOWN_ITEM,
    selected: componentsBranding.ACTIVE
  };

  return (
    <div
      className={classNames('biab_time-display-format-dropdown', styles.timeDisplayFormat, {
        [styles.timeDisplayFormat__desktop]: !isMobile,
        [styles.timeDisplayFormat__mobile]: isMobile
      })}
    >
      {isMobile && selectedItem?.value == TimeFormats.BETTING_DAY && (
        <>
          <i
            onClick={() => setOpenModal(true)}
            className={classNames('fa2 fa2-info-circle tooltip-icon', styles.tooltipIcon, styles.tooltipIcon__mobile)}
          >
            <span className="path1" />
            <span className="path2" />
          </i>
          <Modal
            customClassNames={{
              header: styles.modalHeader,
              closeIcon: !isAsianViewPage ? 'biab_close' : '',
              dialog: styles.modalDialog,
              body: styles.modalBody
            }}
            clickableBackground
            open={openModal}
            onClose={() => setOpenModal(false)}
            title={selectedItem.label}
          >
            <div dangerouslySetInnerHTML={{ __html: selectedItem.tooltip || '' }} />
          </Modal>
        </>
      )}
      <Dropdown
        classes={dropdownStyles}
        data={convertedData}
        tooltipElement="item"
        tooltipPosition="right"
        defaultValue={selectedItem}
        tooltipsEnabled={!isMobile}
        onSelectOption={onSelectTimeFormat}
        disabled={isMyBetsPage && isCancellingBets}
        brandingClasses={dropdownBranding}
      />
    </div>
  );
};

export default memo(TimeFormatDropdown);
