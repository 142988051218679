import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { APPLICATION_TYPE_WEB, CookieNames } from 'constants/app';
import { BetTypeValues } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useDeviceSettings from 'hooks/useDeviceSettings';
import {
  getDesktopSettingsNetOfCommissionBetslip,
  getIsAsianViewEnabled,
  getMobileSettingsNetOfCommission
} from 'redux/modules/appConfigs/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { getBooleanValue } from 'utils';

import useAsianLayBetting from './useAsianLayBetting';

export const usePlacementParams = (
  {
    eachWayDivisor,
    numberOfWinners
  }: {
    eachWayDivisor?: number | null;
    numberOfWinners?: number | null;
  } = { eachWayDivisor: null, numberOfWinners: null }
) => {
  const accountSettings = useSelector(getAccountSettings);
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const netOfCommissionBetslip = useSelector(getDesktopSettingsNetOfCommissionBetslip);
  const netOfCommission = useSelector(getMobileSettingsNetOfCommission);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const isAsianView = useSelector(getIsAsianViewEnabled);
  const { isMobile, isAsianViewPage } = useDevice();

  const [cookies] = useCookies([CookieNames.MOBILE_OPEN_BETS]);

  const { quickstakeBetslip, confirmBetsBeforePlace } = useDeviceSettings();

  const showLayOddsEnabled = useAsianLayBetting();
  const isEachWay = !!eachWayDivisor;
  const mobileParams = isMobile ? { openBetsEnabled: getBooleanValue(cookies.BIAB_MOBILE_OPEN_BETS) } : {};

  return {
    betType: isAsianViewPage ? BetTypeValues.Sports : isGameBetslip ? BetTypeValues.Games : BetTypeValues.Sports,
    netPLBetslipEnabled: netOfCommissionBetslip && !!accountSettings?.netOfCommissionBetslipEnabledState,
    netPLMarketPageEnabled: netOfCommission && !!accountSettings?.netOfCommissionEnabledState,
    quickStakesEnabled: quickstakeBetslip,
    confirmBetsEnabled: confirmBetsBeforePlace && !!accountSettings?.confirmBetsBeforePlacement,
    applicationType: APPLICATION_TYPE_WEB,
    showLayOddsEnabled,
    mobile: isAsianView ? isMobileAsianView : isMobile,
    isEachWay,
    eachWayData: isEachWay
      ? {
          eachWayDivisor: eachWayDivisor,
          places: numberOfWinners
        }
      : {},
    ...mobileParams
  };
};
