import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { OPERATOR, PostMessageTypes } from 'constants/app';
import { componentsBranding, mobileComponents } from 'constants/branding';
import {
  MIN_BACK_TO_TOP_ITEMS,
  MIN_ITEMS_PAGINATION,
  MY_BETS_PAGINATION_LANDSCAPE_WIDTH,
  pageSizes
} from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import dropdownStyles from 'pages/MyBetsPages/dropdown.module.scss';
import { getIsIframeEnabled, getOperator, getWindowWidth } from 'redux/modules/appConfigs/selectors';
import { TMyBetsResponse, TPLResponse, TStatementResponse } from 'redux/modules/myBets/type';
import { DropdownItem } from 'types';
import { sendIframeMessages } from 'utils/iframe';

import PageSizeOptions from './components/PageSizeOptions';

import styles from './styles.module.scss';

interface PaginationProps {
  source: TStatementResponse | TMyBetsResponse | TPLResponse;
  pageSize: number;
  pageNumber: number;
  setPageNumber: (number: number) => void;
  setPageSize: (size: number) => void;
  setPageSizeAndNumber: (number: number, size: number) => void;
}

const Pagination = ({ pageSize, pageNumber, setPageNumber, setPageSizeAndNumber, source }: PaginationProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();

  const width = useSelector(getWindowWidth);

  const isLandscape = width >= MY_BETS_PAGINATION_LANDSCAPE_WIDTH;

  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const operator = useSelector(getOperator);

  const hasScrollToTop = !isIframeEnabled || (isIframeEnabled && operator === OPERATOR.A2F);

  const pageSizesToShow = pageSizes
    .filter((size, index) => size <= source.totalElements || pageSizes[index - 1] <= source.totalElements)
    .map(item => {
      return { id: item, value: item.toString(), label: item.toString() };
    });

  const selectedSize = pageSizesToShow.find(({ id }) => id === source.size);

  const isPageSizeOptionsEnabled = pageSizesToShow.length > 1;

  const isPaginationEnabled = source.totalElements > MIN_ITEMS_PAGINATION;
  const isBackToTopButtonEnabled = source.numberOfElements > MIN_BACK_TO_TOP_ITEMS;

  const scrollTop = () => {
    document.getElementById('my-bets-pages-content')?.scrollTo({ top: 0 });

    if (isMobile) {
      window.scrollTo({ top: 0 });
    }
  };

  const handleChangeSelectPageSize = (perPageOption: DropdownItem) => {
    setPageSizeAndNumber(0, +perPageOption.value);
    scrollTop();
  };

  const handleClickPage = (page: number) => {
    setPageNumber(page);
    scrollTop();

    if (isIframeEnabled) {
      sendIframeMessages({ type: PostMessageTypes.ACCOUNT_BETTING_PAGE_SCROLL_TO_TOP });
    }
  };

  const handleClickTop = () => {
    scrollTop();

    if (isIframeEnabled) {
      sendIframeMessages({ type: PostMessageTypes.ACCOUNT_BETTING_PAGE_SCROLL_TO_TOP });
    }
  };

  const isFirstPage = source.first;
  const isLastPage = source.last;

  const recordsOnPage = (pageNumber + 1) * pageSize;
  const recordsLabel = t(isMobile ? 'account.labels.numberOfRecordsShort' : 'account.labels.numberOfRecords', {
    current: `${pageNumber * pageSize + 1}-${
      recordsOnPage <= source.totalElements ? recordsOnPage : source.totalElements
    }`,
    all: source.totalElements
  });

  const buttonClasses = [
    styles.btn,
    ...(isMobile ? [styles.btn__mobile] : []),
    componentsBranding.SECONDARY_BTN,
    mobileComponents.BUTTON,
    mobileComponents.SECONDARY
  ];

  const fontIcons = {
    BACK_TO_TOP: isMobile ? 'biab_custom-icon-back-to-top-24' : 'biab_custom-icon-back-to-top',
    SKIP_LEFT: isMobile ? 'biab_custom-icon-skip-left-24' : 'biab_custom-icon-skip-left',
    LEFT: isMobile ? 'biab_custom-icon-chevron-left-24' : 'biab_custom-icon-chevron-left',
    RIGHT: isMobile ? 'biab_custom-icon-chevron-right-24' : 'biab_custom-icon-chevron-right',
    SKIP_RIGHT: isMobile ? 'biab_custom-icon-skip-right-24' : 'biab_custom-icon-skip-right'
  };

  const iconsClasses = [styles.icon, ...(isMobile ? [styles.icon__mobile] : [])];

  const dropdownBranding = {
    dropdownItems: componentsBranding.DROPDOWN_LIST,
    dropdownItem: componentsBranding.DROPDOWN_ITEM,
    selected: componentsBranding.ACTIVE
  };

  return (
    <section className={classNames(styles.pagination, { [styles.pagination__mobile]: isMobile })}>
      <div className={styles.pagination__wrap}>
        <div className={styles.pagination__left}>
          {isBackToTopButtonEnabled && hasScrollToTop && (
            <button type="button" className={classNames(...buttonClasses)} onClick={handleClickTop}>
              <i className={classNames(...iconsClasses, fontIcons.BACK_TO_TOP)} />
            </button>
          )}
          {isPaginationEnabled && !isMobile && isPageSizeOptionsEnabled && (
            <>
              <Dropdown
                data={pageSizesToShow}
                defaultValue={selectedSize}
                onSelectOption={handleChangeSelectPageSize}
                classes={{ ...dropdownStyles, placeholder: `${dropdownStyles.placeholder} ${styles.placeholder}` }}
                brandingClasses={dropdownBranding}
              />
              <p className={styles.label}>{t('account.labels.resultsPerPage')}</p>
            </>
          )}
          {isPaginationEnabled && isMobile && isLandscape && isPageSizeOptionsEnabled && (
            <PageSizeOptions
              options={pageSizesToShow}
              selectedSize={selectedSize}
              onChange={handleChangeSelectPageSize}
            />
          )}
        </div>

        <div className={styles.pagination__right}>
          <span className={classNames({ [styles.label__mobile]: isMobile })}>{recordsLabel}</span>
          {isPaginationEnabled && source.totalElements > source.size && (
            <div className={styles.btnList}>
              <button
                type="button"
                className={classNames(...buttonClasses, {
                  [componentsBranding.DISABLED]: isFirstPage
                })}
                onClick={() => handleClickPage(0)}
                disabled={isFirstPage}
              >
                <i className={classNames(...iconsClasses, fontIcons.SKIP_LEFT)} />
              </button>
              <button
                type="button"
                className={classNames(...buttonClasses, {
                  [componentsBranding.DISABLED]: isFirstPage
                })}
                onClick={() => handleClickPage(pageNumber - 1)}
                disabled={isFirstPage}
              >
                <i className={classNames(...iconsClasses, fontIcons.LEFT)} />
              </button>
              <button
                type="button"
                className={classNames(...buttonClasses, {
                  [componentsBranding.DISABLED]: isLastPage
                })}
                onClick={() => handleClickPage(pageNumber + 1)}
                disabled={isLastPage}
              >
                <i className={classNames(...iconsClasses, fontIcons.RIGHT)} />
              </button>
              <button
                type="button"
                className={classNames(...buttonClasses, {
                  [componentsBranding.DISABLED]: isLastPage
                })}
                onClick={() => handleClickPage(source.totalPages - 1)}
                disabled={isLastPage}
              >
                <i className={classNames(...iconsClasses, fontIcons.SKIP_RIGHT)} />
              </button>
            </div>
          )}
        </div>
      </div>
      {isPaginationEnabled && isMobile && !isLandscape && isPageSizeOptionsEnabled && (
        <PageSizeOptions options={pageSizesToShow} selectedSize={selectedSize} onChange={handleChangeSelectPageSize} />
      )}
    </section>
  );
};

export default Pagination;
