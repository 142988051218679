import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { BetsStatusesTypes } from 'constants/app';
import { BettingTypes } from 'constants/markets';
import { BetSides } from 'constants/myBets';
import { HIDE_MOBILE_PLACEMENT_TIMEOUT } from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { asianBetSlipCashOutCleanPlacedId } from 'redux/modules/asianViewBetSlipCashOut';
import { getAsianBetSlipCashOutPlaceIdByMarketId } from 'redux/modules/asianViewBetSlipCashOut/selectors';
import { asianViewCashOutCleanPlacedId } from 'redux/modules/asianViewCashOut';
import { getAsianViewCashOutPlaceIdByMarketId } from 'redux/modules/asianViewCashOut/selectors';
import { cleanCashOutPlacedId } from 'redux/modules/cashOut';
import { getCashOutPlacedId } from 'redux/modules/cashOut/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { eventCashOutCleanPlacedId } from 'redux/modules/eventCashOut';
import { getEventCashOutPlaceIdByMarketId } from 'redux/modules/eventCashOut/selectors';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from '../styles.module.scss';

interface IPlacementMessage {
  bet: TCurrentBet;
  onClose?: () => void;
  isAsianBetSlip?: boolean;
  isEvent?: boolean;
  isAsianView?: boolean;
}

const status = 'Placed';

const PlacementMessage = ({ bet, isAsianBetSlip, isEvent = false, isAsianView = false }: IPlacementMessage) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const cashOutPlacedIdSelector = isAsianBetSlip
    ? getAsianBetSlipCashOutPlaceIdByMarketId
    : isEvent
    ? getEventCashOutPlaceIdByMarketId
    : isAsianView
    ? getAsianViewCashOutPlaceIdByMarketId
    : getCashOutPlacedId;

  const cashOutPlacedId = useSelector(cashOutPlacedIdSelector(bet.marketId));
  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const labelPrefix = isPNCEnabled ? 'pnc.' : '';
  let betStateDetails = '';
  let betStatus = '';
  let moreInfo = 'marketBetslip.messages.matchedMoreInformation';
  let odds;
  let className = bet.type === BetSides.Back ? 'biab_type-bet' : '';

  const sizeMatched = +(bet.sizeMatched || 0);
  const sizeRemaining = +(bet.sizeRemaining || 0);
  const sizeCancelled = +(bet.sizeCancelled || 0);
  const sizePlaced = bet && +(bet.sizePlaced || 0);
  const currency = (bet && bet.currency) || '';
  const isLineMarket = bet && bet.bettingType === BettingTypes.LINE;
  const side = (bet.side || bet.type || '').toLowerCase();

  switch (true) {
    case sizeMatched === 0 && sizeRemaining > 0:
      betStateDetails = `marketBetslip.messages.unmatched${status}Bet`;
      moreInfo = 'marketBetslip.messages.unmatchedMoreInformation';
      betStatus = BetsStatusesTypes.UNMATCHED;
      odds = bet.price;
      break;
    case sizeMatched > 0 && sizeRemaining > 0:
      betStateDetails = `${labelPrefix}marketBetslip.messages.matchedPartially${status}Bet`;
      odds = bet.averagePriceRounded;
      betStatus = BetsStatusesTypes.MATCHED;
      break;
    case sizeMatched > 0 && sizeRemaining === 0 && sizeCancelled === 0:
      betStateDetails = `${labelPrefix}marketBetslip.messages.matched${status}Bet`;
      betStatus = BetsStatusesTypes.MATCHED;
      odds = bet.averagePriceRounded;
      break;
    case sizeRemaining === 0 && sizeCancelled > 0:
      betStateDetails = 'marketBetslip.messages.cancelledPlacedBet';
      moreInfo = 'marketBetslip.messages.cancelledMoreInformation';
      betStatus = BetsStatusesTypes.UNMATCHED;
      odds = bet.price;
      break;
    case sizeMatched === 0 &&
      sizeRemaining === 0 &&
      sizeCancelled === 0 &&
      bet.offerState === BetsStatusesTypes.MATCHED:
      betStateDetails = `${labelPrefix}marketBetslip.messages.matched${status}Bet`;
      betStatus = BetsStatusesTypes.MATCHED;
      odds = bet.averagePriceRounded;
      break;
    case sizeMatched === 0 && sizeRemaining === 0 && sizeCancelled === 0 && bet.offerState === BetsStatusesTypes.PLACED:
      betStateDetails = `marketBetslip.messages.unmatched${status}Bet`;
      moreInfo = 'marketBetslip.messages.unmatchedMoreInformation';
      betStatus = BetsStatusesTypes.UNMATCHED;
      odds = bet.price;
      break;
  }

  const detailedText =
    (bet.triggeredByCashOut ? '<span class="biab_cashout-icon"><i></i></span> ' : '') +
    t(betStateDetails, {
      sizePlaced: useFormatCurrency(sizePlaced, currency, { noCommas: true, isCheckIndian: true, noRounding: true })
        .noFormattedAmount,
      sizeMatched: useFormatCurrency(sizeMatched, currency, { noCommas: true, isCheckIndian: true, noRounding: true })
        .noFormattedAmount,
      odds: isLineMarket ? '2.00' : odds
    }) +
    '<br/>' +
    t(moreInfo, {
      rootPath: getEnvironmentRootPath()
    });

  className += ' biab_bet-status-' + betStatus.toLowerCase() + ' biab_type-bet-' + side;

  const hideMessage = () => {
    if (bet.triggeredByCashOut && bet.offerId === cashOutPlacedId) {
      if (isAsianBetSlip) {
        dispatch(asianBetSlipCashOutCleanPlacedId(bet.marketId));
      } else if (isEvent) {
        dispatch(eventCashOutCleanPlacedId(bet.marketId));
      } else if (isAsianView) {
        dispatch(asianViewCashOutCleanPlacedId(bet.marketId));
      } else {
        dispatch(cleanCashOutPlacedId({ marketId: bet.marketId }));
      }
    }
  };

  useEffect(() => {
    let hideTimeout: ReturnType<typeof setTimeout> | null = null;
    hideTimeout = setTimeout(() => hideMessage(), HIDE_MOBILE_PLACEMENT_TIMEOUT);

    return () => {
      if (hideTimeout) {
        hideMessage();
        clearTimeout(hideTimeout);
      }
    };
  }, [bet]);

  return createPortal(
    <div className="biab_bet-matched biab_fixed">
      <div className={classNames('biab_matching-bet-msg biab_visible-xs biab_fixed js-matching-bet-msg', className)}>
        <span className="biab_matched-label js-matched-label" />
        <p
          className={classNames(styles.messageContainer, 'biab_matched-details js-matched-details')}
          dangerouslySetInnerHTML={{ __html: unescape(detailedText) }}
        />
      </div>
    </div>,
    document.getElementById('biab_body')!
  );
};

export default PlacementMessage;
