import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { BIAB_CUSTOM_POPUP, Devices } from 'constants/app';
import { POPULAR_LINK_NONE_ICON } from 'constants/popularLinks';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { TPopularLinks } from 'redux/modules/popular/type';
import { setShowingCustomPopularLink } from 'redux/modules/popup';

import PopularLinkContent from '../PopularLinkContent';

import styles from '../../styles.module.scss';

interface CustomModalPopularLinkProps {
  value: TPopularLinks;
}

const ModalPopularLink = ({ value }: CustomModalPopularLinkProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const device = useSelector(getAppDevice);

  const isMobile = device === Devices.MOBILE;
  const popupPrefix = isMobile ? 'mobile.' : '';

  const handlerOpenPopup = (e: SyntheticEvent) => {
    e.preventDefault();
    localStorage.setItem(BIAB_CUSTOM_POPUP, 'true');
    dispatch(setShowingCustomPopularLink(true));
  };

  return (
    <li className={classnames('biab_sport-item', styles.popularLink)}>
      <Link to={location} onClick={handlerOpenPopup}>
        <PopularLinkContent
          type={value.type}
          icon={t('custom.pop.up.icon') !== POPULAR_LINK_NONE_ICON ? t(`${popupPrefix}custom.pop.up.icon`) : ''}
          name={t(`${popupPrefix}custom.pop.up.link`)}
        />
      </Link>
    </li>
  );
};

export default ModalPopularLink;
