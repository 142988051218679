import {
  AsianViewSizeValidationType,
  TAsianPlaceBet,
  TAsianViewPlaceBetErrorMessageId
} from 'redux/modules/asianViewBetslip/type';
import { BetsStatusesType } from 'redux/modules/betsStatuses/type';
import { OddsType } from 'types';
import {
  AsianBettingAction,
  AsianPlacedQuickBettingStatus,
  AsianViewMarketLink,
  AsianViewSelectedBetState
} from 'types/asianView';
import { THandicap, TPrice, TProfit, TSize } from 'types/bets';
import { BetSide } from 'types/myBets';

import { TCurrentBet } from '../currentBets/type';

export type TAsianViewQuickBettingState = {
  quickBets: Record<string, TAsianQuickBet>;
  placedBets: Record<string, TAsianQuickPlacedBet>;
  activePlacedBetIndex: number;
  isPlacedBetsLoading: boolean;
};

export type TAsianQuickBet = {
  identifier: string;
  sportId?: string;
  competitionId: string;
  eventId: string;
  marketId: string;
  selectionId: number;
  handicap?: THandicap | null;
  marketType?: string;
  betType: BetSide;
  price: TPrice | undefined;
  size?: TSize;
  profit?: TProfit;
  numberOfWinners?: number;
  competitionName?: string;
  eventName?: string;
  marketName?: string;
  selectionName?: string;
  currency?: string;
  order?: number;
  validationMessage?: string;
  sizeValidationType?: AsianViewSizeValidationType;
  validationMessageId?: null | TAsianViewPlaceBetErrorMessageId;
  validSizeValue?: TSize;
  score?: string;
  oddsType?: OddsType;
  marketLink: AsianViewMarketLink;
  action?: AsianBettingAction;
  offerId?: number | null;
  placementStatus?: TAsianPlacementStatus;
  placementError?: string | null;
  betUuid?: string;
  isInPlay?: boolean;
  adjHandicap?: THandicap | null;
  runnerIndex?: number;
  initPrice?: TPrice;
  isLowerPrice?: boolean;
  isValid?: boolean;
  isExpandedCoupon?: boolean;
  state: AsianViewSelectedBetState;
  dateTime: number;
  fullIdentifier: string;
};

export const AsianPlacementStatus = {
  OK: 'OK',
  FAIL: 'FAIL'
} as const;

export type TAsianPlacementStatus = typeof AsianPlacementStatus[keyof typeof AsianPlacementStatus];
export type TAsianPlaceQuickBetPayload = {
  identifier: string;
  data: TAsianPlaceQuickBetPayloadData;
};

export type TAsianPlaceQuickBetPayloadData = {
  [marketId: string]: TAsianPlaceBet[];
};

export type TAsianQuickPlacedBet = TAsianQuickBet & {
  identifier: string;
  offerId?: number | null;
  price?: TPrice;
  placedPrice?: TPrice;
  status?: AsianPlacedQuickBettingStatus;
  placementError?: string | null;
  competitionName?: string;
  eventName?: string;
  marketName?: string;
  selectionName?: string;
  betType: BetSide;
  oddsType?: OddsType;
  score?: string;
  order?: number;
  updateTime?: number;
  isLoading?: boolean;
  offerStatus?: BetsStatusesType;
  isInPlay?: boolean;
  isHidden?: boolean;
  isClosed?: boolean;
  noAnimation?: boolean;
  offer?: TCurrentBet;
};

export type TPlacedQuickBetsByMarket = {
  [marketId: string]: TPlacedQuickBetInfo;
};

export type TPlacedQuickBetInfo = {
  identifier?: string;
  offerIds?: Record<string, number>;
  status: TAsianPlacementStatus;
  error: string | null;
  exception?: {
    id: TAsianViewPlaceBetErrorMessageId;
    code: string;
    title: null | string;
    message: string;
  };
};

export type TAsianFailurePlaceQuickBetPayload = {
  [marketId: string]: { betUuid?: string }[];
};

export type TAsianQuickUpdatedBet = {
  price?: TPrice;
  prevPrice?: TPrice;
  size?: TSize;
  profit?: TProfit;
  isValid?: boolean;
  validationMessage?: string;
  sizeValidationType?: AsianViewSizeValidationType;
  validationMessageId?: null | TAsianViewPlaceBetErrorMessageId;
  validSizeValue?: TSize;
  action?: AsianBettingAction;
};
