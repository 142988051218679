import {
  ResponsibleBettingReportInitialState,
  ResponsibleBettingSelfExclusionTimeUnitKeys
} from 'types/responsibleBetting';

import { MINUTES_IN_A_DAY, MINUTES_IN_A_MONTH, MINUTES_IN_A_WEEK, MINUTES_IN_A_YEAR } from './date';

export const TODAY_BUTTON_DETECTION_CLASS_NAME = 'today-button';

export const CHECK_LIMIT_INTERVAL = 60000;

export const ResponsibleBettingTimeUnits = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
} as const;

export const RESPONSIBLE_BETTING_TIME_UNITS = {
  [ResponsibleBettingTimeUnits.DAY]: 'responsibleBetting.labels.perDay',
  [ResponsibleBettingTimeUnits.WEEK]: 'responsibleBetting.labels.perWeek',
  [ResponsibleBettingTimeUnits.MONTH]: 'responsibleBetting.labels.perMonth'
};

export const RESPONSIBLE_BETTING_TIME_UNIT_DAYS_AMOUNT = {
  [ResponsibleBettingTimeUnits.DAY]: 1,
  [ResponsibleBettingTimeUnits.WEEK]: 7,
  [ResponsibleBettingTimeUnits.MONTH]: 30
};

export const RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH = 100;
export const RESPONSIBLE_BETTING_DEFAULT_SCALE_GAP = 1;

export const updatedResponsibleBettingTimeUnits = [
  { value: ResponsibleBettingTimeUnits.DAY, label: 'responsibleBetting.labels.perDay' },
  { value: ResponsibleBettingTimeUnits.WEEK, label: 'responsibleBetting.labels.perWeek' },
  { value: ResponsibleBettingTimeUnits.MONTH, label: 'responsibleBetting.labels.perMonth' }
];

export const initialReportState: ResponsibleBettingReportInitialState = {
  loading: false,
  error: null,
  disabled: false
};

export const REPORTS_EXPORT_DELAY = 5000;
export const RESPONSIBLE_BETTING_SETTINGS_REQUEST_INTERVAL = 30000;

export const ResponsibleBettingSelfExclusionTimeUnits = {
  Day: 'DAY',
  Week: 'WEEK',
  Month: 'MONTH',
  HalfYear: 'HALF_YEAR',
  Year: 'YEAR',
  Years3: 'YEARS_3',
  Years5: 'YEARS_5'
} as const;

export const ResponsibleBettingSelfExclusionPeriods = {
  Hour: 'HOUR',
  Day: 'DAY',
  Custom: 'CUSTOM'
} as const;

export const SELF_EXCLUSION_TIME_UNITS_DURATION: Record<ResponsibleBettingSelfExclusionTimeUnitKeys, number> = {
  [ResponsibleBettingSelfExclusionTimeUnits.Day]: MINUTES_IN_A_DAY,
  [ResponsibleBettingSelfExclusionTimeUnits.Week]: MINUTES_IN_A_WEEK,
  [ResponsibleBettingSelfExclusionTimeUnits.Month]: MINUTES_IN_A_MONTH,
  [ResponsibleBettingSelfExclusionTimeUnits.HalfYear]: MINUTES_IN_A_MONTH * 6,
  [ResponsibleBettingSelfExclusionTimeUnits.Year]: MINUTES_IN_A_YEAR,
  [ResponsibleBettingSelfExclusionTimeUnits.Years3]: MINUTES_IN_A_YEAR * 3,
  [ResponsibleBettingSelfExclusionTimeUnits.Years5]: MINUTES_IN_A_YEAR * 5
};

export const ResponsibleBettingReportTypes = {
  CurrentBetsReport: 'current-bets',
  PastBetsReport: 'past-bets',
  ProfitAndLossReport: 'profit-and-loss',
  AccountStatementReport: 'account-statement'
} as const;

export const SELF_EXCLUSION_REPORTS_NAMES = {
  [ResponsibleBettingReportTypes.CurrentBetsReport]: 'responsibleBetting.reports.names.myCurrentBets',
  [ResponsibleBettingReportTypes.PastBetsReport]: 'responsibleBetting.reports.names.myPastBets',
  [ResponsibleBettingReportTypes.ProfitAndLossReport]: 'responsibleBetting.reports.names.bettingProfitAndLoss',
  [ResponsibleBettingReportTypes.AccountStatementReport]: 'responsibleBetting.reports.names.accountStatement'
};

export const TIME_LIMIT_MAX_HOURS = {
  [ResponsibleBettingTimeUnits.DAY]: 24,
  [ResponsibleBettingTimeUnits.WEEK]: 24 * 7,
  [ResponsibleBettingTimeUnits.MONTH]: 24 * 30
};

export const SELF_EXCLUSION_MIN_HOURS = 0.5;
export const SELF_EXCLUSION_REMINDER_MINUTES = 10;
export const REMINDER_MIN_PERIOD = 1;
export const REMINDER_MAX_PERIOD = 600;

export const ResponsibleBettingCalendarsContainerTypes = {
  ExposureLimit: 'exposure-limit',
  LossLimit: 'loss-limit',
  SelfExclusion: 'self-exclusion'
} as const;

export const ResponsibleBettingLimitInputIds = {
  ExposureLimit: 'exposure-limit-input',
  LossLimit: 'loss-limit-input',
  Reminder: 'reminder-input',
  DayLimit: 'day-limit-input'
} as const;

export const ResponsibleBettingOperatorLimits = {
  ExposureLimit: 'operatorsExposureLimit',
  LossLimit: 'operatorsLossLimit',
  SelfExcluded: 'selfExcludedThroughApi'
} as const;

export const ResponsibleBettingStartDates = {
  ExposureLimit: 'exposureStartDate',
  LossLimit: 'lossLimitStartDate',
  SelfExclusion: 'selfExclusionStartDate'
} as const;

export const ResponsibleBettingEndDates = {
  ExposureLimit: 'exposureEndDate',
  LossLimit: 'lossLimitEndDate',
  SelfExclusion: 'selfExclusionEndDate'
} as const;

export const ResponsibleBettingLimits = {
  ExposureLimit: 'exposureLimit',
  LossLimit: 'lossLimit',
  Reminder: 'reminderInterval',
  DayLimit: 'dayLimit'
} as const;

export const ResponsibleBettingLimitsEnabled = {
  ExposureLimit: 'exposureLimitEnabled',
  LossLimit: 'lossLimitEnabled',
  SelfExclusion: 'selfExclusionEnabled',
  Reminder: 'reminderEnabled',
  DayLimit: 'dayLimitEnabled'
} as const;

export const ResponsibleBettingDisplayedContents = {
  ExposureLimit: 'EXPOSURE_LIMIT',
  LossLimit: 'LOSS_LIMIT',
  SelfExclusion: 'SELF_EXCLUSION',
  Reminder: 'REMINDER',
  DayLimit: 'DAY_LIMIT'
} as const;

export const ResponsibleBettingLossLimitTimeUnits = {
  Day: 'DAY',
  Custom: 'CUSTOM'
} as const;

export const ResponsibleBettingReminderTimeUnits = {
  Minute: 'MINUTE',
  Hour: 'HOUR'
} as const;

export const BettingDisplaySettingsEnabled = {
  ExposureLimit: 'exposureLimitEnabled',
  LossLimit: 'lossLimitEnabled',
  SelfExclusion: 'selfExclusionEnabled',
  Reminder: 'reminderEnabled',
  DayLimit: 'dayLimitEnabled'
} as const;

export const ResponsibleBettingAlertTypes = {
  Error: 'Error',
  Info: 'Info',
  Outline: 'Outline'
} as const;

export const ResponsibleBettingMobileDropdownTypes = {
  Vertical: 'Vertical',
  Horizontal: 'Horizontal'
} as const;

export const UpdatedResponsibleBettingDisplayedContents = {
  LossLimit: 'LOSS_LIMIT',
  SelfExclusion: 'SELF_EXCLUSION',
  Reminder: 'REMINDER',
  TimeLimit: 'TIME_LIMIT'
};

export const ResponsibleBettingButtonTypes = {
  Primary: 'Primary',
  Secondary: 'Secondary',
  Applied: 'Applied',
  Outlined: 'Outlined'
} as const;
