import classNames from 'classnames';

import SignRow from 'components/GameDerbyRacingThirdSection/partials/SignRow';
import SuitPositionRow from 'components/GameDerbyRacingThirdSection/partials/SuitPositionRow';
import { GameDataTypes } from 'constants/games';
import { TGameData } from 'redux/modules/games/type';
import { arrayRange } from 'utils/games';

import styles from './styles.module.scss';

interface IGameDerbyRacingThirdSection {
  gameData: TGameData[];
}

const GameDerbyRacingThirdSection = ({ gameData }: IGameDerbyRacingThirdSection) => {
  const [cards, spades, hearts, clubs, diamonds] = gameData;
  const spadesSegment = spades.properties.find(p => p.name === GameDataTypes.SEGMENT);
  const spadesPlace = spades.properties.find(p => p.name === GameDataTypes.PLACE);
  const heartsSegment = hearts.properties.find(p => p.name === GameDataTypes.SEGMENT);
  const heartsPlace = hearts.properties.find(p => p.name === GameDataTypes.PLACE);
  const clubsSegment = clubs.properties.find(p => p.name === GameDataTypes.SEGMENT);
  const clubsPlace = clubs.properties.find(p => p.name === GameDataTypes.PLACE);
  const diamondsSegment = diamonds.properties.find(p => p.name === GameDataTypes.SEGMENT);
  const diamondsPlace = diamonds.properties.find(p => p.name === GameDataTypes.PLACE);

  const rows = [
    {
      type: 'spades',
      values: [...arrayRange(40, 51, 1), 39],
      segmentValue: spadesSegment?.value ? parseInt(spadesSegment?.value) : 0,
      placeValue: spadesPlace?.value || 1
    },
    {
      type: 'hearts',
      values: [...arrayRange(27, 38, 1), 26],
      segmentValue: heartsSegment?.value ? parseInt(heartsSegment?.value) : 0,
      placeValue: heartsPlace?.value || 1
    },
    {
      type: 'clubs',
      values: [...arrayRange(1, 12, 1), 0],
      segmentValue: clubsSegment?.value ? parseInt(clubsSegment?.value) : 0,
      placeValue: clubsPlace?.value || 1
    },
    {
      type: 'diamonds',
      values: [...arrayRange(14, 25, 1), 13],
      segmentValue: diamondsSegment?.value ? parseInt(diamondsSegment?.value) : 0,
      placeValue: diamondsPlace?.value || 1
    }
  ];

  return (
    <div className={classNames('biab_games-players', styles.main)}>
      <div className="biab_games-player">
        <SuitPositionRow />
        {rows.map(({ type, values, segmentValue, placeValue }) => (
          <SignRow
            key={type}
            type={type}
            currentSet={cards}
            values={values}
            segmentValue={segmentValue}
            placeValue={placeValue}
          />
        ))}
      </div>
    </div>
  );
};

export default GameDerbyRacingThirdSection;
