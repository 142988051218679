import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Collapse from 'components/Collapse';
import { ElementNames } from 'constants/app';
import useElementSize from 'hooks/useElementSize';
import { useOverLayFadeOutTimer } from 'hooks/useOverlayFadeOutTimer';
import useWebTours from 'hooks/useWebTours';
import { getDesktopSettingsHowTo, getPropertiesTours } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { HOW_TO_COLLAPSE, LEFT_PANEL_COLLAPSE_GROUP } from 'redux/modules/collapse/constants';

import TourListItem from './components/TourListItem';
import TourModalContainer from './components/TourModalContainer';

import styles from './styles.module.scss';

const HowToSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tours = useSelector(getPropertiesTours);
  const howTo = useSelector(getDesktopSettingsHowTo);

  const {
    selectedTour,
    openTourModal,
    setOpenTourModal,
    changeSelectedTour,
    handleStartTour,
    setSelectedTour,
    tourLoading
  } = useWebTours();
  const { showOverlay: showOverlayBlock, setShowOverLay: setShowOverlayBlock } = useOverLayFadeOutTimer(1400);

  const handleClose = () => {
    setSelectedTour(undefined);
    setShowOverlayBlock(true);
  };

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.HOW_TO_MENU_TITLE_HEIGHT, height }));
    }
  });

  const [ref] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.HOW_TO_SECTION_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.HOW_TO_MENU_TITLE_HEIGHT, height: 0 }));
      dispatch(setElementHeight({ name: ElementNames.HOW_TO_SECTION_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <Collapse
      defaultState={howTo}
      testId="a-howToSection"
      collapse={HOW_TO_COLLAPSE}
      group={LEFT_PANEL_COLLAPSE_GROUP}
      title={t('navigation.tours.title.label')}
      childHeightKey={ElementNames.HOW_TO_SECTION_HEIGHT}
      componentRef={componentRef}
    >
      <div
        ref={ref}
        className={classNames('biab_how-to-content', 'biab_navigation-section', styles.howToSectionWrapper)}
      >
        <ul className={classNames('biab_navigation-list', styles.navList)}>
          {tours.map(({ tourName, id }) => (
            <TourListItem startTour={handleStartTour} key={id} tourName={tourName} id={id} />
          ))}
        </ul>
      </div>
      {openTourModal && (
        <TourModalContainer
          tourLoading={tourLoading}
          onClose={handleClose}
          tourHeader={selectedTour?.tourHeader as string}
          tourId={selectedTour?.id as number}
          openTourModal={openTourModal}
          setOpenTourModal={setOpenTourModal}
          changeSelectedTour={changeSelectedTour}
        />
      )}
      {showOverlayBlock && <div className={styles.backdrop} />}
    </Collapse>
  );
};

export default HowToSection;
