import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES, SportIds } from 'constants/app';
import { NavigationTypes } from 'constants/competitions';
import api from 'redux/api/methods';
import { TFailureActionPayload, TimeFilter } from 'types';

import {
  ICompetitionDetails,
  ICountryCompetitionBySportPayload,
  IGetEventPayload,
  IGetTCMarketAndRedirectPayload,
  TCompetition,
  TCompetitionData,
  TCompetitionPayload,
  TCountryCompetitions,
  TFetchSportsPayload,
  TFetchTimeFiltersPayload,
  TMarketResponse,
  TMultiMarketResponse,
  TNavigationResponse
} from './type';

const initialState: TCompetition = {
  competitionData: [],
  competitions: [],
  eventData: [],
  sportData: [],
  sportTopCompetitions: [],
  sportTopCountries: [],
  countryData: [],
  groupData: [],
  sportNameTranslations: {},
  eventNameTranslations: {},
  groupNameTranslations: {},
  competitionNameTranslations: {},
  countryNameTranslations: {},
  sportMore: [],
  groupName: '',
  nameTranslations: {},
  market: {
    cashOutEnabled: false,
    commission: 0,
    competition: { id: '' },
    description: {
      marketType: ''
    },
    event: { id: '', name: '', countryCode: '', timezone: '', venue: null, openDate: 0 },
    eventType: { id: '', name: '' },
    fancyView: false,
    liveScoreEnabled: false,
    marketId: '',
    marketName: '',
    marketStartTime: 0,
    numberOfWinners: 0,
    outright: false,
    parents: [],
    partialCashOutEnabled: false,
    runners: [],
    totalMatched: 0
  },
  loading: false,
  error: null,
  countryParents: [],
  competitionParents: [],
  eventParents: [],
  marketParents: [],
  groupParents: [],
  sportParents: [],
  race: null,
  sportLoading: false,
  eventLoading: false,
  competitionLoading: false,
  marketLoading: false,
  groupLoading: false,
  raceLoading: false,
  countryLoading: false,
  isAllSoccer: false,
  sportNameHighlighted: false,
  competitionNameHighlighted: false,
  eventNameHighlighted: false,
  groupNameHighlighted: false,
  countryNameHighlighted: false,
  sportAvailableFilters: [],
  todayCardPageLoading: false,
  eventDetails: { id: '', type: '' },
  competitionDetails: { id: '', type: '' }
};

const slice = createSlice({
  name: SLICES_NAMES.COMPETITIONS,
  initialState,
  reducers: {
    getCompetitionAndCountry: (state, _: PayloadAction<string | number>) => {
      state.sportLoading = true;
      state.sportNameTranslations = initialState.nameTranslations;
      state.sportParents = [];
      state.sportData = [];
      state.sportMore = [];
      state.sportTopCompetitions = [];
      state.sportTopCountries = [];
      state.sportNameHighlighted = false;
    },
    successGetCompetitionAndCountry: (state, { payload }: PayloadAction<TCompetitionData>) => {
      const { more, topCompetitions, topCountries, parents, translations, highlighted, availableTimeFilters } = payload;
      const sportTopCompetitions = topCompetitions.map(i => ({
        ...i,
        type: NavigationTypes.COMPETITION
      }));
      const sportTopCountries = topCountries.map(i => ({
        ...i,
        type: NavigationTypes.COUNTRY
      }));

      if (translations) {
        state.sportNameTranslations = translations;
      }

      state.sportData = [...sportTopCompetitions, ...sportTopCountries] as TNavigationResponse[];
      state.sportTopCountries = sportTopCountries;
      state.sportTopCompetitions = sportTopCompetitions;
      state.sportParents = parents;
      state.sportMore = more.map((i: TNavigationResponse) => ({ ...i, type: NavigationTypes.COUNTRY }));
      state.sportLoading = false;
      state.sportNameHighlighted = highlighted ?? false;
      state.sportAvailableFilters = availableTimeFilters;
    },
    failureGetCompetitionAndCountry: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.sportLoading = false;
    },
    fetchCompetitionDetails: (state, _: PayloadAction<IGetEventPayload>) => {
      state.competitionLoading = true;
      state.competitionNameTranslations = initialState.competitionNameTranslations;
      state.competitionDetails = initialState.competitionDetails;
      state.competitionParents = [];
      state.competitionData = [];
      state.competitions = [];
      state.competitionNameHighlighted = false;
    },
    successCompetitionDetails: (state, { payload }: PayloadAction<ICompetitionDetails>) => {
      state.competitionData = payload.children as any[];
      state.competitionParents = payload.parents;
      state.competitionNameTranslations = payload.translations;
      state.competitionDetails.id = payload.id;
      state.competitionDetails.type = payload.type;
      state.competitionLoading = false;
      state.competitionNameHighlighted = payload.highlighted;
      state.competitions = payload.competitions;
    },
    failureCompetitionDetails: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.competitionLoading = false;
    },
    fetchCompetitionByCountryAndSport: (state, _: PayloadAction<ICountryCompetitionBySportPayload>) => {
      state.countryLoading = true;
      state.countryParents = [];
      state.countryData = [];
      state.countryNameTranslations = initialState.nameTranslations;
      state.countryNameHighlighted = false;
    },
    successCompetitionByCountryAndSport: (state, { payload }: PayloadAction<TCountryCompetitions>) => {
      state.countryData = payload.countryCompetitions.map((e: TNavigationResponse) => ({ ...e, type: 'COMPETITION' }));
      state.countryNameTranslations = payload.translations;
      state.countryParents = payload.parents;
      state.countryLoading = false;
      state.countryNameHighlighted = payload.highlighted;
    },
    failureCompetitionByCountryAndSport: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.countryLoading = false;
    },
    fetchGetEvents: (state, _: PayloadAction<IGetEventPayload>) => {
      state.eventLoading = true;
      state.eventNameTranslations = initialState.nameTranslations;
      state.eventDetails = initialState.eventDetails;
      state.eventData = [];
      state.eventParents = [];
      state.eventNameHighlighted = false;
      state.loading = true;
    },
    successGetEvents: (state, { payload }: PayloadAction<TNavigationResponse>) => {
      state.eventData = payload.children;
      state.eventParents = payload.parents;
      state.eventNameTranslations = payload.translations;
      state.eventDetails.id = payload.id;
      state.eventDetails.type = payload.type;
      state.eventLoading = false;
      state.eventNameHighlighted = payload.highlighted;
      state.loading = false;
    },
    failureGetEvents: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
      state.eventLoading = false;
    },
    fetchGetMarket: (state, _: PayloadAction<string | number>) => {
      state.marketLoading = true;
      state.marketParents = [];
      state.market = initialState.market;
    },
    successGetMarket: (state, { payload }: PayloadAction<TMarketResponse>) => {
      state.market = payload;
      state.marketParents = payload.parents;
      state.marketLoading = false;
    },
    failureGetMarket: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.marketLoading = false;
    },
    fetchGetSportCompetition: (state, { payload }: PayloadAction<IGetEventPayload>) => {
      state.sportLoading = true;
      state.sportNameTranslations = '';
      state.sportMore = [];
      state.sportData = [];
      state.sportParents = [];
      state.sportTopCompetitions = [];
      state.sportTopCountries = [];
      state.isAllSoccer = payload.allSoccer ?? false;
      state.sportNameHighlighted = false;
    },
    successGetSportCompetition: (state, { payload }: PayloadAction<TCompetitionPayload>) => {
      state.sportData = [
        ...payload.response.topCompetitions,
        ...payload.response.topCountries
      ] as TNavigationResponse[];
      state.sportMore = payload.response.moreCompetitions;
      state.sportTopCompetitions = payload.response.topCompetitions;
      state.sportTopCountries = payload.response.topCountries;
      state.sportNameTranslations = payload.id === SportIds.SOCCER ? 'All Soccer' : '';
      state.sportParents = payload.response.parents;
      state.sportLoading = false;
      state.isAllSoccer = payload.allSoccer;
      state.sportNameHighlighted = payload.response.highlighted ?? false;
      state.sportAvailableFilters = payload.response.availableTimeFilters;
    },
    failureGetSportCompetition: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.sportLoading = false;
    },
    fetchGetGroup: (state, _: PayloadAction<IGetEventPayload>) => {
      state.groupLoading = true;
      state.groupNameTranslations = initialState.nameTranslations;
      state.groupData = [];
      state.groupParents = [];
      state.groupNameHighlighted = false;
      state.groupName = '';
    },
    successGetGroup: (state, { payload }: PayloadAction<TNavigationResponse>) => {
      state.groupData = payload.children;
      state.groupNameTranslations = payload.translations;
      state.groupParents = payload.parents;
      state.groupLoading = false;
      state.groupNameHighlighted = payload.highlighted;
      state.groupName = payload.name;
    },
    failureGetGroup: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.groupLoading = false;
    },
    fetchGetSports: (state, { payload }: PayloadAction<TFetchSportsPayload>) => {
      if (payload.noReset) {
        state.sportLoading = true;
        state.sportNameHighlighted = false;
      } else {
        state.sportLoading = true;
        state.sportNameTranslations = '';
        state.sportData = [];
        state.sportParents = [];
        state.sportNameHighlighted = false;
      }
    },
    successGetSports: (state, { payload }: PayloadAction<TNavigationResponse[]>) => {
      state.sportData = payload;
      state.sportParents = payload[payload.length - 1].parents;
      state.sportLoading = false;
    },
    failureGetSports: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.sportLoading = false;
    },
    setRace: (state, { payload }: PayloadAction<TNavigationResponse | null>) => {
      if (payload) {
        const isTodayCart = String(payload.id).startsWith('tc');
        state.race = payload;
        state.nameTranslations = !isTodayCart ? payload?.translations : state.nameTranslations;
        state.raceLoading = false;
      } else {
        state.race = null;
        state.raceLoading = false;
      }
    },
    fetchTimeFilters: (state, _: PayloadAction<TFetchTimeFiltersPayload>) => {
      state.sportAvailableFilters = [];
      state.sportLoading = true;
    },
    successFetchTimeFilters: (state, { payload }: PayloadAction<TimeFilter[]>) => {
      state.sportAvailableFilters = payload;
      state.sportLoading = false;
    },
    failureFetchTimeFilters: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.sportLoading = false;
    },
    resetSportData: () => initialState,
    getSportDataForTCAndRedirect: (state, _: PayloadAction<IGetTCMarketAndRedirectPayload>) => {
      state.todayCardPageLoading = true;
    },
    setTodayCardLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.todayCardPageLoading = payload;
    },
    setIsAllSoccer: (state, { payload }: PayloadAction<boolean>) => {
      state.isAllSoccer = payload;
    }
  }
});

export const fetchRace = createAsyncThunk<TNavigationResponse, string, { rejectValue: unknown }>(
  '@competition/FETCH_RACE',
  async (raceId: string, { rejectWithValue }) => {
    try {
      return await api.competitions.getRace(raceId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchMultiMarket = createAsyncThunk<TMultiMarketResponse, string, { rejectValue: unknown }>(
  '@competition/FETCH_MULTI_MARKET',
  async (eventId: string, { rejectWithValue }) => {
    try {
      return await api.competitions.fetchMultiMarket(eventId);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const {
  failureCompetitionDetails,
  fetchCompetitionDetails,
  successCompetitionDetails,
  failureGetCompetitionAndCountry,
  getCompetitionAndCountry,
  successGetCompetitionAndCountry,
  successGetEvents,
  successGetGroup,
  successGetMarket,
  successGetSportCompetition,
  successGetSports,
  successCompetitionByCountryAndSport,
  fetchCompetitionByCountryAndSport,
  failureCompetitionByCountryAndSport,
  failureGetSportCompetition,
  failureGetSports,
  failureGetEvents,
  failureGetGroup,
  failureGetMarket,
  fetchGetEvents,
  fetchGetGroup,
  fetchGetMarket,
  fetchGetSportCompetition,
  fetchGetSports,
  fetchTimeFilters,
  failureFetchTimeFilters,
  successFetchTimeFilters,
  resetSportData,
  setIsAllSoccer,
  setRace,
  setTodayCardLoading,
  getSportDataForTCAndRedirect
} = slice.actions;

export default slice.reducer;
