import { useSelector } from 'react-redux';

import { BetDatabaseNames } from 'constants/bets';
import { BetSides } from 'constants/myBets';
import { useCurrency } from 'hooks/useCurrency';
import { getMinAmountToShowOdds } from 'redux/modules/appConfigs/selectors';
import { getBetAmount, getBetOdds, getMarketPricesCurrency } from 'redux/modules/marketsPrices/selectors';
import { THandicap } from 'types/bets';
import { BetSide } from 'types/myBets';

const useOddsDisplayFormat = ({
  marketId,
  selectionId,
  handicap,
  betType,
  depthValue = 0
}: {
  marketId: string;
  selectionId: number;
  handicap?: THandicap | null;
  betType: BetSide;
  depthValue?: 0 | 1 | 2;
}) => {
  const currencyCode = useSelector(getMarketPricesCurrency(marketId));
  const currency = useCurrency(currencyCode);
  const minAmountToShowOdds = useSelector(getMinAmountToShowOdds);
  const isDefaultFormat = currency?.displayFormat === 1;
  const handicapInt = +(handicap || 0);
  const isBack = betType === BetSides.Back;
  const betName = isBack ? BetDatabaseNames.BACK : BetDatabaseNames.LAY;
  const amount = useSelector(getBetAmount(marketId, selectionId, handicapInt, betName, depthValue));
  let odds = useSelector(getBetOdds(marketId, selectionId, handicapInt, betName, depthValue));
  const roundedAmount = isDefaultFormat ? Math.floor(amount ?? 0) : Math.ceil(amount ?? 0);

  if (roundedAmount <= minAmountToShowOdds) {
    odds = 0;
  }

  return odds;
};

export default useOddsDisplayFormat;
