import { ChangeEvent, KeyboardEvent, RefObject, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { InputFieldTypes, KEY_CODES } from 'constants/betslip';
import { componentsBranding } from 'constants/branding';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { removeBetSlipUnmatchedOfferError } from 'redux/modules/betslip';
import { getIsBetSlipUnmatchedOfferErrorById, getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { TPrice, TSize } from 'types/bets';
import { InputFieldType } from 'types/betslip';

import styles from './styles.module.scss';

type TInputFieldProps = {
  betUuid?: string;
  value: TPrice | TSize;
  inputPattern: RegExp;
  isError?: boolean;
  fieldTabIndex?: number;
  isFocus?: boolean;
  fieldType?: InputFieldType;
  onChange: (value: TPrice | TSize) => void;
  onBlur: () => void;
  onEnterClick: () => void;
  onTabNavigate?: (fieldType: InputFieldType) => void | null;
  isUnmatchedOpenedBetTab: boolean;
  isHighlighted?: boolean;
  offerId?: number;
  label?: string;
  customClassName?: string;
  isReadOnly?: boolean;
  fieldRef?: RefObject<HTMLInputElement>;
};

const InputField = ({
  betUuid,
  value = '',
  inputPattern,
  isError,
  onChange,
  onBlur,
  onEnterClick,
  onTabNavigate,
  fieldType = InputFieldTypes.NONE,
  fieldTabIndex = 0,
  isFocus,
  isUnmatchedOpenedBetTab,
  isHighlighted = false,
  offerId,
  label,
  customClassName = '',
  isReadOnly,
  fieldRef
}: TInputFieldProps) => {
  const dispatch = useDispatch();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const isUnmatchedBetError = useSelector(
    getIsBetSlipUnmatchedOfferErrorById(isUnmatchedOpenedBetTab ? offerId : undefined)
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const isMountedRef = useRef<boolean>(true);

  const isDisabled = isPNCEnabled && !isGameBetslip && fieldType === InputFieldTypes.PRICE;

  const inputId = `${betUuid}_${fieldType}`;
  const currentRef = fieldRef?.current || inputRef.current;

  useEffect(() => {
    if (
      currentRef !== null &&
      isFocus &&
      ((isPNCEnabled && !isGameBetslip && fieldType === InputFieldTypes.SIZE) ||
        ((!isPNCEnabled || isGameBetslip) && fieldType === InputFieldTypes.PRICE))
    ) {
      currentRef.select();
    }
  }, [fieldType, isGameBetslip, isPNCEnabled, isFocus]);

  useEffect(() => {
    if (isFocus && currentRef !== null) {
      currentRef.focus();
    }
  }, [isFocus, currentRef]);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value
      .trim()
      .replace(/[^0-9.]/g, '')
      .replace(/\.\./g, '.');

    if (changedValue === '' || inputPattern.test(changedValue)) {
      onChange(changedValue);
    }

    if (isUnmatchedOpenedBetTab && offerId && isUnmatchedBetError) {
      dispatch(removeBetSlipUnmatchedOfferError(offerId));
    }
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === KEY_CODES.ENTER) {
      e.preventDefault();
      onEnterClick();
    } else if (e.key === KEY_CODES.TAB && onTabNavigate) {
      e.preventDefault();
      onTabNavigate(fieldType);
    }
  };

  return (
    <>
      <input
        className={classNames(
          styles.input,
          componentsBranding.FORM_INPUT,
          styles[`input__${fieldType?.toLowerCase()}`],
          customClassName,
          {
            [componentsBranding.ERROR]: isUnmatchedBetError || isError,
            [styles.input__error]: isUnmatchedBetError || isError,
            [styles.input__disabled]: isDisabled,
            [styles.input__highlighted]: isHighlighted
          }
        )}
        id={inputId}
        type="text"
        value={value}
        placeholder=""
        ref={fieldRef || inputRef}
        tabIndex={fieldTabIndex}
        onChange={changeHandler}
        onBlur={onBlur}
        onKeyDown={keyDownHandler}
        disabled={isDisabled}
        data-field-type={fieldType}
        readOnly={isReadOnly}
      />
      {!!label && (
        <label className={styles.input__label} htmlFor={inputId}>
          {label}
        </label>
      )}
    </>
  );
};

export default InputField;
