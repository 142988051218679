import classnames from 'classnames';

import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getProfitStatus } from 'utils/general';

import styles from './styles.module.scss';

interface CurrentPLProps {
  value: string;
  currencyCode: string;
  wightNormal?: boolean;
}

const CurrentPL = ({ value, currencyCode, wightNormal }: CurrentPLProps) => {
  let amount = value;
  const status = getProfitStatus(value);

  amount = useFormatCurrency(amount, currencyCode, {
    noRounding: true,
    isPositiveSign: true,
    noZeroSign: true
  }).formattedAmount;

  return (
    <div
      className={classnames(styles.count, styles[`count__${status}`], {
        [styles.count__normal]: wightNormal
      })}
    >
      {amount}
    </div>
  );
};

export default CurrentPL;
