import { MouseEvent, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Button from 'components/Button';
import { setIsScrollingToTop } from 'redux/modules/appSettings';

import styles from './styles.module.scss';

interface LinkButtonProps {
  to: string;
  children: ReactNode;
  className?: string;
  sameUrlPush?: boolean;
}

const LinkButton = ({ to, children, className, sameUrlPush = false }: LinkButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleLinkClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (sameUrlPush) {
      event.preventDefault();
      if (location.pathname === to) {
        dispatch(setIsScrollingToTop(true));
      } else {
        navigate(to);
      }
    }
  };

  return (
    <Link onClick={handleLinkClick} to={to} className={classNames(styles.link, className)}>
      <Button>{children}</Button>
    </Link>
  );
};

export default LinkButton;
