import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Skeleton from 'components/Skeleton';
import { EDUCATIONAL_PORTAL_GUIDES_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import { IArticle } from 'types/educationalPortal';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

interface ArticleCardProps {
  data: IArticle;
  size?: 'small' | 'medium' | 'large';
  isHorizontalScroll?: boolean;
}

const ArticleCard = ({ data, size = 'medium', isHorizontalScroll }: ArticleCardProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imageSrc = `${
    window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL
  }${getEnvironmentRootPath()}/api/images/thumbnail/${data.thumbnail}`;

  const { isMobile } = useDevice();

  return (
    <Link to={`${EDUCATIONAL_PORTAL_GUIDES_URL}/${data.localeCode}/${data.permalink}`} className={styles.link}>
      <figure className={classNames(styles.card, styles[`card__${size}`], { [styles.card__mobile]: isMobile })}>
        {!isLoaded && (
          <Skeleton
            className={classNames(styles.image, styles[`image__${size}`], { [styles.image__mobile]: isMobile })}
          />
        )}
        <img
          src={imageSrc}
          alt={data.name}
          onLoad={() => {
            setIsLoaded(true);
          }}
          className={classNames(styles.image, styles[`image__${size}`], {
            [styles.image__mobile]: isMobile,
            [styles.image__hide]: !isLoaded,
            [styles.image__scroll]: isHorizontalScroll
          })}
        />
        <figcaption className={classNames(styles.title, { [styles.title__mobile]: isMobile })}>{data.name}</figcaption>
      </figure>
    </Link>
  );
};

export default ArticleCard;
