import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { mobileIcons } from 'constants/branding';
import { TEXT_COPIED_TIMEOUT } from 'constants/myBets';
import { AVG_PRICE } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';

import styles from './styles.module.scss';

interface MobileDetailsFieldProps {
  value?: string | number | null | boolean;
  name?: string;
  children?: ReactNode;
  capitalise?: boolean;
  classname?: string;
  isWithTooltip?: boolean;
  tooltipValue?: number | string | null;
  tooltipHTMLValue?: string;
  isSingleRow?: boolean;
  borderTop?: boolean;
  copyToClipboard?: boolean;
  valueClassName?: string;
}

const MobileDetailsField = ({
  name,
  value,
  isWithTooltip,
  tooltipValue,
  tooltipHTMLValue,
  capitalise,
  borderTop,
  copyToClipboard,
  valueClassName
}: MobileDetailsFieldProps) => {
  const { t } = useTranslation();
  const { translationKey } = useTooltip(AVG_PRICE);

  const [isTextCopiedTooltipHidden, setIsTextCopiedTooltipHidden] = useState(true);

  useEffect(() => {
    if (!isTextCopiedTooltipHidden) {
      setTimeout(() => {
        setIsTextCopiedTooltipHidden(true);
      }, TEXT_COPIED_TIMEOUT);
    }
  }, [isTextCopiedTooltipHidden]);

  return (
    <div className={classNames(styles.field, { [styles.field__borderTop]: borderTop })}>
      <span className={styles.fieldName}>{name}</span>
      <span className={classNames(styles.fieldValue, valueClassName, { [styles.capitalise]: capitalise })}>
        {value || '--'}
        {isWithTooltip && tooltipValue && tooltipValue !== '--' && (
          <i
            onClick={e => e.preventDefault()}
            data-tooltip-id="tooltip"
            data-tooltip-html={tooltipHTMLValue || unescape(t(translationKey, { N: tooltipValue }))}
            data-tooltip-class-name={styles.tooltip}
            className={classNames('biab_custom-icon-info-24', styles.icon, styles.icon__info, mobileIcons.TOOLTIP)}
          >
            <span className={classNames('path1', styles.iconBg, mobileIcons.TOOLTIP_BG)} />
            <span className={classNames('path2', mobileIcons.TOOLTIP_INNER)} />
            <span className={classNames('path3', mobileIcons.TOOLTIP_INNER)} />
          </i>
        )}
        {copyToClipboard && value && (
          <i
            data-tooltip-id="tooltip"
            data-tooltip-html={t('account.tooltip.textCopied')}
            data-tooltip-hidden={isTextCopiedTooltipHidden}
            data-tooltip-class-name={styles.details__tooltipTextCopied}
            className={classNames('biab_custom-icon-copy-24', styles.icon, styles.icon__copy)}
            onClick={() => {
              try {
                void navigator.clipboard.writeText(value?.toString());
                setIsTextCopiedTooltipHidden(false);
              } catch (err) {
                console.error(err);
              }
            }}
          />
        )}
      </span>
    </div>
  );
};

export default MobileDetailsField;
