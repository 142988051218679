import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { CookieNames } from 'constants/app';
import { AsianLineViewTypes } from 'constants/asianView';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { getUserAsianViewViewType } from 'redux/modules/user/selectors';
import { AsianLineViewType } from 'types/asianView';

const useAsianViewLineType = () => {
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const asianViewViewType = useSelector(getUserAsianViewViewType);
  const [cookies] = useCookies([CookieNames.ASIAN_VIEW_LINE_VIEW_FILTER]);

  if (isMobileAsianView) {
    return AsianLineViewTypes.DOUBLE;
  }

  return (
    (asianViewViewType as AsianLineViewType) ||
    (cookies[CookieNames.ASIAN_VIEW_LINE_VIEW_FILTER] as AsianLineViewType) ||
    AsianLineViewTypes.DOUBLE
  );
};

export default useAsianViewLineType;
