import classNames from 'classnames';

import { betslipBranding } from 'constants/branding';
import { TPrice } from 'types/bets';
import { BetSide } from 'types/myBets';

import styles from './styles.module.scss';

const BetIndicator = ({
  price,
  bestPrice,
  betType,
  onChange = () => {}
}: {
  price: TPrice;
  bestPrice?: TPrice;
  betType: BetSide;
  onChange?: () => void;
}) => {
  const isPriceUp = !!price && !!bestPrice && bestPrice > 0 && bestPrice > price;
  const isPriceDown = !!price && !!bestPrice && bestPrice > 0 && bestPrice < price;

  if (!isPriceDown && !isPriceUp) {
    return null;
  }

  return (
    <button
      className={classNames(styles.betIndicator, betslipBranding.PRICE_INDICATOR, betslipBranding[betType], {
        [styles.betIndicator__up]: isPriceUp,
        [styles.betIndicator__down]: isPriceDown,
        [styles.betIndicator__action]: !!onChange,
        [betslipBranding.UP]: isPriceUp,
        [betslipBranding.DOWN]: isPriceDown
      })}
      onClick={onChange}
    >
      <i
        className={classNames('fa2', {
          'fa2-sort-up': isPriceUp,
          'fa2-sort-down': isPriceDown
        })}
      />
    </button>
  );
};

export default BetIndicator;
