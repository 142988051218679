import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import OpenBetsEvent from 'components/Betslip/components/OpenBetsEvent/OpenBetsEvent';
import { CookieNames } from 'constants/app';
import { useEnabledView } from 'hooks/useEnabledView';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { getCurrentBetsGroupedByEvent } from 'redux/modules/currentBets/selectors';

import styles from './OpenBetsEvents.module.scss';

const OpenBetsEvents = () => {
  const { isMultiMarketView } = useEnabledView();
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const [cookies] = useCookies([CookieNames.BETSLIP_FILTER_MARKET, CookieNames.BETSLIP_FILTER_EVENT]);

  const eventsData = useSelector(
    getCurrentBetsGroupedByEvent({
      isGameType: isGameBetSlip,
      filterMarketId: isMultiMarketView ? undefined : cookies.FILTER_BETS_BY_MARKET_ID,
      filterEventId: cookies.FILTER_BETS_BY_EVENT_ID
    })
  );

  return (
    <div className={styles.events}>
      {eventsData.map(eventData => {
        return <OpenBetsEvent key={`${eventData.eventId}-${eventData.marketStartDate || ''}`} eventData={eventData} />;
      })}
    </div>
  );
};

export default OpenBetsEvents;
