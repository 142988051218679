import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import MatchStatistics from 'components/MatchStatistics';
import styles from 'components/SportsSection/styles.module.scss';
import VideoStream from 'components/VideoStream';
import { Devices, EventViewTypes, FAVORITES_TYPES, GAME_BETTING, SportIds } from 'constants/app';
import { TYPE_TO_URL } from 'constants/competitions';
import { SPORT_BASE_URL } from 'constants/locations';
import { useEnabledView } from 'hooks/useEnabledView';
import { useAppThunkDispatch } from 'redux/configureStore';
import { getAppDevice, getOperator, getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { fetchMultiMarket, fetchRace } from 'redux/modules/competitions';
import { getSportDataForTCAndRedirect, setRace } from 'redux/modules/competitions';
import { getRace } from 'redux/modules/competitions/selectors';
import { TNavigationResponse, TTopCountryItem } from 'redux/modules/competitions/type';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { TNavItemParams } from 'redux/modules/sports/type';
import { applyTimezone, parseDateToTime } from 'utils/date';
import { getIconNameForMobileNavigation } from 'utils/navigation';

const ListItem = ({
  item,
  domainLanguage,
  eventId,
  textLeft = false,
  collapseName = '',
  isGamesBettingChild = false
}: TNavItemParams) => {
  const dispatch = useDispatch();
  const appDispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const { sportId, marketId, fancyId, eventId: eventIdParam, groupId, competitionId } = useParams();

  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);
  const operator = useSelector(getOperator);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const race = useSelector(getRace);
  const isFavorite = useSelector(getFavoriteById(item.id));

  const { isMultiMarketView } = useEnabledView();

  const isHeader = String(item.id).startsWith('#');
  const isDateItem = String(item.id).startsWith('#date');
  const isMore = String(item.id).startsWith('#date_More');
  const isFancyHeader = String(item.id).startsWith('#date_Fancy Markets');
  const isAllHeader = String(item.id).startsWith('#date_All');
  const isRaceItem = TYPE_TO_URL[item.type] === TYPE_TO_URL.RACE;
  const isMarketItem = TYPE_TO_URL[item.type] === TYPE_TO_URL.MARKET;
  const isFancyMarket = TYPE_TO_URL[item.type] === TYPE_TO_URL.FANCY_MARKET;
  const isSportItem = TYPE_TO_URL[item.type] === TYPE_TO_URL.EVENT_TYPE;
  const isGroupItem = TYPE_TO_URL[item.type] === TYPE_TO_URL.GROUP;
  const isTennis = sportId === SportIds.TENNIS;
  const isGamesBettingItem = isGroupItem && isTennis && item.name === GAME_BETTING;
  const isEventItem = TYPE_TO_URL[item.type] === TYPE_TO_URL.EVENT;
  const isCompetition =
    TYPE_TO_URL[item.type] === TYPE_TO_URL.COMPETITION ||
    item.id === '#date_Top Competitions' ||
    item.id === '#date_Competitions';
  const isCountry =
    TYPE_TO_URL[item.type] === TYPE_TO_URL.COUNTRY || item.id === '#date_TopCountries' || item.id === '#date_Countries';
  const isTodayCard = marketId?.startsWith('tc') || sportId?.startsWith('tc');
  const isRacingSport = sportId === SportIds.GRAY_HOUND_RACING || sportId === SportIds.HORSE_RACING;
  const isRacing = sportId === SportIds.HORSE_RACING || sportId === SportIds.GRAY_HOUND_RACING;

  const isDesktop = device === Devices.DESKTOP;
  const isMobile = device === Devices.MOBILE;
  const currentDate = item.startTime ? applyTimezone(new Date(item.startTime), timezone, timezoneCookieEnabled) : null;
  const isShowFavorites =
    item.type !== FAVORITES_TYPES.market &&
    FAVORITES_TYPES[TYPE_TO_URL[item.type]] &&
    !isRaceItem &&
    !isGamesBettingItem &&
    !isGamesBettingChild;
  const showEventWidgets = (isCompetition || isEventItem) && isMobile;

  const showRightArrow =
    device === Devices.MOBILE &&
    (isGroupItem || isMarketItem || isEventItem || isRaceItem || isFancyMarket) &&
    !isShowFavorites;

  const favoriteData = useMemo(() => {
    return {
      entryId: item.id,
      sportId: item.type === FAVORITES_TYPES.sport ? item.id : `${item.eventTypeId}`,
      starred: !isFavorite,
      entryName: item.name,
      entryType: item.type
    };
  }, [isFavorite, item]);

  const handleItemClick = async (val: TNavigationResponse | TTopCountryItem) => {
    if (isHeader) {
      return;
    }

    if (!sportId) {
      if (val.id?.startsWith('tc')) {
        if (isMobile) {
          window.scrollTo({ top: 0 });
        }
        dispatch(getSportDataForTCAndRedirect({ id: val.id, navigate }));
        return;
      }
      navigate(`${SPORT_BASE_URL}/${val.id}`);
    } else {
      if (TYPE_TO_URL[val.type] === TYPE_TO_URL.EVENT && isMultiMarketView) {
        const multiMarketData = await appDispatch(fetchMultiMarket(val.id)).unwrap();
        if (multiMarketData.viewType === EventViewTypes.MULTI_MARKET_VIEW) {
          navigate(`${SPORT_BASE_URL}/${sportId}/${TYPE_TO_URL.MARKET}/${multiMarketData.topMarketId}`);
          return;
        }
      }
      if (TYPE_TO_URL[val.type] === TYPE_TO_URL.FANCY_MARKET) {
        navigate(`${SPORT_BASE_URL}/${sportId}/${TYPE_TO_URL.FANCY_MARKET}/${eventId || eventIdParam}/${val.id}`);
        return;
      }
      if (TYPE_TO_URL[val.type] !== TYPE_TO_URL.RACE) {
        navigate(`${SPORT_BASE_URL}/${sportId}/${TYPE_TO_URL[val.type]}/${val.id}`);
      } else {
        const response = await appDispatch(fetchRace(val.id)).unwrap();
        dispatch(setRace(response));
        navigate(`${SPORT_BASE_URL}/${sportId}/${TYPE_TO_URL.MARKET}/${response.children[0].id}`);
      }
    }
  };

  return (
    <>
      <li
        data-test-collapse={`${collapseName}_ITEM`}
        datatype={TYPE_TO_URL[item.type]}
        data-navigation-type={item.type}
        data-navigation-id={item.id}
        className={classNames(
          styles.sportsList__item,
          `biab_${TYPE_TO_URL[item.type]}-item`,
          [styles[`${TYPE_TO_URL[item.type]}-item`]],
          {
            'biab_sub-navigation-item': sportId === SportIds.TENNIS && isMobile && competitionId,
            [`biab_c-highlighted ${styles.sportsList__item_active} ${styles.highlighted}`]: item.highlighted,
            [`biab_date-item ${(styles.sportsList__dateItem, styles.dateItem)}`]:
              isHeader && isDateItem && !isFancyHeader && !isAllHeader,
            [styles.sportsList__today]: isTodayCard,
            [styles.sportsList__selectedItem]: item.id === marketId || item.id === fancyId,
            [styles.sportsList__dateHeader]: isDateItem || isMore,
            [`sportsList__raceItemParent ${styles.sportsList__raceItemParent}`]: isRaceItem,
            'biab_market-item': isMarketItem || isFancyMarket,
            'biab_race-item': isRaceItem,
            'biab_sport-item': isSportItem,
            [`biab_group-item ${styles.groupItem}`]: isGroupItem && sportId !== SportIds.TENNIS,
            [`biab_event-item ${styles.eventItem}`]: isEventItem && sportId !== SportIds.TENNIS,
            [`${styles.categoryName}`]: (isCompetition || isCountry) && isHeader && isDesktop,
            [styles.competitionItem]: isHeader && !isDateItem,
            'biab_navigation-competition-category-name': isHeader && !isMore,
            biab_active:
              ((isTodayCard || isRacingSport) && !!race && race.id === item.id && isDateItem) ||
              item.id === marketId ||
              item.id === fancyId,
            [`biab_navigation-items-more-title`]: isMore,
            [styles.moreMobileItem]: isMore && device === Devices.MOBILE,
            [styles.textLeft]: textLeft && isHeader && device === Devices.DESKTOP,
            [styles.textCenter]:
              groupId &&
              device === Devices.MOBILE &&
              isHeader &&
              !isMore &&
              isDateItem &&
              !isFancyHeader &&
              !isAllHeader,
            'biab_navigation-competition-item-name':
              !isEventItem && (isCompetition || isCountry || isEventItem) && !marketId && device === Devices.MOBILE
          }
        )}
      >
        {isHeader ? (
          <span className={styles.header}>
            {currentDate && isRacing ? `${parseDateToTime(currentDate)} ` : ''}{' '}
            {item?.translations?.[domainLanguage] || item.name}
          </span>
        ) : (
          <>
            <a
              data-sport-id={item.id}
              onClick={() => handleItemClick(item)}
              className={classNames('biab_href', {
                [`biab_c-highlighted ${styles.highlighted}`]: item.highlighted,
                [styles.sportsList__link]: !item.highlighted && (!isMarketItem || isDesktop),
                'biab_market-item': isMarketItem || isFancyMarket,
                'biab_sport-item': isSportItem,
                'biab_race-item': isRaceItem,
                [styles.showArrows]: device === Devices.DESKTOP,
                'biab_group-item': isGroupItem,
                'biab_event-item': isEventItem,
                'biab_navigation-competition-item-name':
                  !isEventItem && (isCompetition || isCountry || isEventItem) && !marketId,
                [styles.sportsList__todayIcon]: isTodayCard,
                [styles.marketItem]: isMarketItem || isFancyMarket,
                [styles.sportsList__raceItem]: isRaceItem,
                [styles.sportsList__linkMobile]: !isDesktop
              })}
            >
              <div className={styles.sportsList__leftContainer}>
                {!sportId && device === Devices.MOBILE && (
                  <i
                    className={classNames(
                      'biab_br-sport-icon',
                      styles.sportIcon,
                      getIconNameForMobileNavigation(item.id, operator)
                    )}
                  />
                )}
                {currentDate && isRacing ? `${parseDateToTime(currentDate)} ` : ' '}
                <div className={classNames({ [styles.sportsList__itemName]: isMobile })}>
                  {item?.translations?.[domainLanguage] || item?.name}
                </div>
                {showEventWidgets && (
                  <>
                    {'videoStreamingEnabled' in item && item.videoStreamingEnabled && (
                      <VideoStream visible={item.videoStreamingEnabled} eventId={item.id} isLeftSpace />
                    )}
                    {'matchStatEnabled' in item && item.matchStatEnabled && (
                      <MatchStatistics
                        isSoccer={item.eventTypeId === SportIds.SOCCER}
                        visible={item.matchStatEnabled}
                        isLeftSpace
                        eventId={item.id}
                      />
                    )}
                  </>
                )}
                {isMarketItem && !isRacing && device === Devices.DESKTOP && (
                  <div
                    className={classNames('biab_event-inplay-icon', styles.sportsList__iconWrap, {
                      biab_active: item.inPlay
                    })}
                  >
                    <i className={classNames('fa2 fa2-check', styles.sportsList__icon)} />
                  </div>
                )}
                {showRightArrow && (
                  <div className={styles.sportsList__arrowIcon}>
                    <i className="fa2 fa2-arrow-right" />
                  </div>
                )}
              </div>
              {isShowFavorites && (
                <div className={styles.sportsList__favoriteItem}>
                  <FavoriteStar
                    className={classNames(styles.collapsibleRow__starIcon, {
                      [styles.sportsList__favoriteHidden]: !isFavorite && isDesktop
                    })}
                    entryData={favoriteData}
                    isNavigation={true}
                  />
                </div>
              )}
            </a>
          </>
        )}
      </li>
      {(isTodayCard || isRacingSport) && !!race && race.id === item.id && isDesktop && (
        <div>
          <div>
            <ul>
              {race.children.map(r => (
                <li
                  key={r.id}
                  className={classNames(
                    'biab_market-item biab_has-arrow biab_race-market-item',
                    styles.sportsList__item,
                    {
                      [styles.sportsList__selectedItem]: r.id === marketId,
                      biab_active: r.id === marketId
                    }
                  )}
                >
                  <a
                    className={classNames('biab_href biab_market-item ', {
                      [styles.sportsList__link]: !isMarketItem || isDesktop
                    })}
                    data-race-id={r.id}
                    onClick={() => handleItemClick(r)}
                  >
                    {r?.translations?.[domainLanguage]}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default ListItem;
