import i18n from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { SportIds } from 'constants/app';
import { AsianViewMarketLinks, AsianViewTimeFilters } from 'constants/asianView';
import { ASIAN_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import api from 'redux/api/methods';

import {
  failureFetchCurrencies,
  failureFetchCurrency,
  failureGetData,
  fetchAppConfigs,
  fetchCashOutPopUp,
  fetchContentPages,
  fetchCurrencies,
  fetchCurrency,
  successFetchCashOutPopUp,
  successFetchCurrencies,
  successGetAppConfigs,
  successGetContentPages,
  successGetCurrency
} from './index';
import { CashOutPopUp, TFetchPropertiesResponse, TSuccessFetchCurrency, TTranslation } from './type';

export function* getAppConfigsWorker(action: ReturnType<typeof fetchAppConfigs>) {
  try {
    let asianTranslation: TTranslation = {};
    const response: TFetchPropertiesResponse = yield call(api.app.getAppConfigs);
    const translation: TTranslation = yield call(api.app.getTranslations, response.language);
    if (response.asianViewEnabled) {
      asianTranslation = yield call(api.app.getTranslations, response.language, response.asianViewEnabled);
    }

    i18n.addResourceBundle(response.language, 'translation', { ...translation, ...asianTranslation }, true, true);
    i18n.changeLanguage(response.language).catch(error => console.error(error));
    yield put(successGetAppConfigs(response));

    if (action.payload?.disabledProductError && action.payload.navigate) {
      const { exchangeGamesEnabled, exchangeSportsEnabled, asianViewEnabled } = response;

      if (asianViewEnabled) {
        action.payload.navigate(
          `${ASIAN_BASE_URL}/sport/${SportIds.SOCCER}/timeFilter/${AsianViewTimeFilters.Today}/marketLink/${AsianViewMarketLinks.HDP_AND_OU}`,
          { replace: true }
        );
      } else if (exchangeSportsEnabled) {
        action.payload.navigate(HOME_BASE_URL, { replace: true });
      } else if (exchangeGamesEnabled) {
        action.payload.navigate(GAMES_BASE_URL, { replace: true });
      }
    }
  } catch (error) {
    yield put(failureGetData(error));
  }
}

function* getPagesWorker() {
  try {
    const response: object = yield call(api.app.getContentPages);
    yield put(successGetContentPages(response));
  } catch (error) {
    yield put(failureGetData(error));
  }
}

function* getCurrencyWorker() {
  try {
    const response: TSuccessFetchCurrency = yield call(api.app.getCurrency);
    yield put(successGetCurrency(response));
  } catch (error) {
    yield put(failureFetchCurrency(error));
  }
}

function* getCurrenciesWorker() {
  try {
    const response: TSuccessFetchCurrency[] = yield call(api.app.getCurrencies);
    yield put(successFetchCurrencies(response));
  } catch (error) {
    yield put(failureFetchCurrencies(error));
  }
}

function* getCashOutPopUpWorker(action: ReturnType<typeof fetchCashOutPopUp>) {
  try {
    const response: CashOutPopUp = yield call(api.app.getCashOutPopUp, action.payload);
    yield put(successFetchCashOutPopUp(response));
  } catch (error) {
    yield put(failureGetData(error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchAppConfigs.type, getAppConfigsWorker),
    takeLatest(fetchContentPages.type, getPagesWorker),
    takeLatest(fetchCurrency.type, getCurrencyWorker),
    takeLatest(fetchCurrencies.type, getCurrenciesWorker),
    takeLatest(fetchCashOutPopUp.type, getCashOutPopUpWorker)
  ]);
}
