import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AsianViewBetslip from 'components/AsianViewBetslip';
import Collapse from 'components/Collapse';
import { ElementNames } from 'constants/app';
import useElementSize from 'hooks/useElementSize';
import { setElementHeight } from 'redux/modules/appSettings';
import { ASIAN_VIEW_BETSLIP_COLLAPSE, LEFT_PANEL_COLLAPSE_GROUP_ASIAN } from 'redux/modules/collapse/constants';

import styles from './styles.module.scss';

const AsianBetslip = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.ASIAN_BET_SLIP_COLLAPSE, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.ASIAN_BET_SLIP_COLLAPSE, height: 0 }));
    };
  }, []);

  return (
    <Collapse
      group={LEFT_PANEL_COLLAPSE_GROUP_ASIAN}
      collapse={ASIAN_VIEW_BETSLIP_COLLAPSE}
      title={t('asianView.labels.betslip.title')}
      withoutHidden
      withoutMargin
      withoutCookie
      customClass={styles.collapse}
      componentRef={componentRef}
    >
      <AsianViewBetslip />
    </Collapse>
  );
};

export default AsianBetslip;
