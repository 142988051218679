import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { COLLAPSE_NAVIGATION_COOKIE_KEY } from 'components/LeftNavigation/HideMenuButton';
import { ElementNames } from 'constants/app';
import { POKER_STAR } from 'constants/icons';
import { SPORT_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import {
  getDesktopSettingsActiveMarkets,
  getLanguage,
  getMinNavSportIds,
  getOperator,
  getTimezone,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setCollapseNewState } from 'redux/modules/collapse';
import {
  ACTIVE_MARKETS_COLLAPSE,
  FAVORITES_COLLAPSE,
  LEFT_PANEL_COLLAPSE_GROUP
} from 'redux/modules/collapse/constants';
import { getCurrentBetsCount } from 'redux/modules/currentBets/selectors';
import { getFavoritesLength } from 'redux/modules/favorites/selectors';
import { fetchSports } from 'redux/modules/sports';
import { getSportsList } from 'redux/modules/sports/selectors';
import { getIconNameForMobileNavigation } from 'utils/navigation';

import styles from '../styles.module.scss';

const CollapsedLeftPanel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, setCookie] = useCookies([COLLAPSE_NAVIGATION_COOKIE_KEY]);

  const sportList = useSelector(getSportsList);
  const timezone = useSelector(getTimezone);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const favoritesCount = useSelector(getFavoritesLength);
  const currentBetsCount = useSelector(getCurrentBetsCount);
  const domainLanguage = useSelector(getTranslation);
  const language = useSelector(getLanguage);
  const operator = useSelector(getOperator);
  const minNavSportIds = useSelector(getMinNavSportIds);
  const activeMarkets = useSelector(getDesktopSettingsActiveMarkets);

  const minNavSportList = minNavSportIds.map(id => sportList.find(s => s.id === id)).filter(item => item !== undefined);

  const handleShowMenuClick = () => {
    setCookie(COLLAPSE_NAVIGATION_COOKIE_KEY, false);
  };

  const handleFavoritesClick = () => {
    handleShowMenuClick();
    dispatch(
      setCollapseNewState({ group: LEFT_PANEL_COLLAPSE_GROUP, collapse: FAVORITES_COLLAPSE, openedState: true })
    );
  };

  const handleActiveMarketsClick = () => {
    handleShowMenuClick();
    dispatch(
      setCollapseNewState({
        group: LEFT_PANEL_COLLAPSE_GROUP,
        collapse: ACTIVE_MARKETS_COLLAPSE,
        openedState: true
      })
    );
  };

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.MIN_NAV_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.MIN_NAV_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    dispatch(fetchSports());
  }, [timezone, isLoggedIn, language]);

  return (
    <aside ref={componentRef} className={classNames('biab_min-nav-wrapper', styles.collapsedMenuMain)}>
      <button onClick={handleShowMenuClick} className="biab_nav-expand-btn biab_min-nav-item">
        <i className={classNames('io ion-ios-arrow-right biab_expand', styles.expandIcon)} />
      </button>
      {isLoggedIn && (
        <button
          onClick={handleFavoritesClick}
          className={classNames('biab_min-nav-item', {
            biab_active: !!favoritesCount
          })}
          title={t('navigation.favouritesTitle')}
        >
          <i
            className={classNames('biab_fav-icon', {
              'biab_fav-icons-inactive': !favoritesCount,
              'biab_fav-icons-active': !!favoritesCount
            })}
          />
        </button>
      )}
      {activeMarkets && (
        <button
          onClick={handleActiveMarketsClick}
          className={classNames('biab_min-nav-item', {
            biab_active: !!currentBetsCount
          })}
          title={t('navigation.activeMarkets')}
        >
          <i className="fas fa-stream" />
        </button>
      )}
      {minNavSportList.map(sport => (
        <Link
          to={`${SPORT_BASE_URL}/${sport?.id}`}
          key={sport?.id}
          title={sport?.translations[domainLanguage]}
          className="biab_sport-nav-link biab_sport-item"
        >
          <i
            className={classNames(
              'biab_icon biab_sport-icon biab_sport-icon ',
              sport?.id && getIconNameForMobileNavigation(sport?.id, operator),
              {
                fi: operator !== POKER_STAR
              }
            )}
          />
        </Link>
      ))}
    </aside>
  );
};

export default CollapsedLeftPanel;
