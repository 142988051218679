import { Link } from 'react-router-dom';
import classNames from 'classnames';

import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLinks,
  AsianViewTimeFilters
} from 'constants/asianView';
import { mobileComponents } from 'constants/branding';
import { ASIAN_BASE_URL, GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { BettingTypes } from 'constants/markets';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';
import { OfferStates } from 'types/myBets';

import styles from './styles.module.scss';

type TMobileEventInfoProps = {
  bet: TMyBetsContentItem;
};

const MobileEventInfo = ({ bet }: TMobileEventInfoProps) => {
  const isGame = bet.betType === BettingTypes.GAME;
  const sportEventUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}/event/${bet.eventId}`;
  const sportOutrightUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}/competition/${bet.competitionId}`;
  const sportUrl = bet.outright ? sportOutrightUrl : sportEventUrl;
  const gameUrl = `${GAMES_BASE_URL}/${bet.eventTypeId}`;
  const asianViewEventUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilters.Today
  }/marketLink/${AsianViewMarketLinks.HDP_AND_OU}?${ASIAN_SINGLE_VIEW_EVENT_ID}=${
    bet!.eventId
  }&${ASIAN_SINGLE_VIEW_SPORT_ID}=${bet.eventTypeId}`;
  const asianViewOutrightUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilters.Today
  }/marketLink/${AsianViewMarketLinks.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${bet!.competitionId}&page=0`;

  const navUrl = isGame ? gameUrl : sportUrl;
  const asianUrl = bet?.outright ? asianViewOutrightUrl : asianViewEventUrl;

  const isCurrent = bet.offerState === OfferStates.MATCHED || bet.offerState === OfferStates.PLACED;

  const eventDetail = (
    <>
      <div className={styles.info__left}>
        <p className={styles.eventName}>{bet.eventName}</p>
        {bet.competitionName && !bet.outright && <p className={styles.competitionName}>{bet.competitionName}</p>}
      </div>
      {isCurrent && (
        <i
          className={classNames(
            'biab_custom-icon-arrow-circle-24',
            styles.icon,
            mobileComponents.BUTTON,
            mobileComponents.TEXT
          )}
        />
      )}
    </>
  );

  return (
    <>
      {isCurrent ? (
        <Link to={bet.asian ? asianUrl : navUrl} className={styles.info}>
          {eventDetail}
        </Link>
      ) : (
        <div className={styles.info}>{eventDetail}</div>
      )}
    </>
  );
};

export default MobileEventInfo;
