import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import LinkButton from 'components/LinkButton/Index';
import { EDUCATIONAL_PORTAL_GUIDES_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import useOnMountEffect from 'hooks/useOnMountEffect';
import ArticleCard from 'pages/EducationalPortal/components/ArticleCard/ArticleCard';
import { fetchArticles } from 'redux/modules/educationalPortal';
import { getEnabledArticles } from 'redux/modules/educationalPortal/selectors';
import { ArticleType, IArticle } from 'types/educationalPortal';

import styles from './styles.module.scss';

interface ArticlesListProps {
  cardSize?: 'small' | 'medium' | 'large';
  displayAmount?: number;
  title?: string;
  hideViewAllButton?: boolean;
  horizontalScroll?: boolean;
  type?: ArticleType;
}

const ArticlesList = ({
  title,
  displayAmount,
  hideViewAllButton,
  horizontalScroll,
  cardSize = 'medium',
  type
}: ArticlesListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const articles = useSelector(getEnabledArticles);
  const { permalink } = useParams();
  const { isMobile } = useDevice();

  useOnMountEffect(() => {
    dispatch(fetchArticles(type));
  });

  const nextArticles = useMemo(() => {
    const currentIndex = articles?.findIndex(article => article.permalink === permalink) || 0;
    const result: IArticle[] = [];

    if (!displayAmount || !articles) {
      return articles;
    } else {
      for (let i = 1; i <= displayAmount; i++) {
        const nextArticle = articles[(currentIndex + i) % articles.length];
        if (nextArticle) {
          result.push(nextArticle);
        }
      }

      return result;
    }
  }, [articles, permalink]);

  if (!nextArticles?.length) return null;

  return (
    <section>
      {title && (
        <div className={styles.header}>
          <h2 className={classNames(styles.title, styles[`title__${cardSize}`])}>{title}</h2>
          {!hideViewAllButton && (
            <LinkButton to={EDUCATIONAL_PORTAL_GUIDES_URL} className={styles.viewAllBtn}>
              {t('education.labels.viewAll')}
            </LinkButton>
          )}
        </div>
      )}
      <div
        className={classNames(styles.list, styles[`list__${cardSize}`], {
          [styles.list__horizontal]: !!horizontalScroll,
          [styles.list__mobile]: isMobile && !horizontalScroll
        })}
      >
        {nextArticles.map(article => (
          <ArticleCard key={article.permalink} data={article} size={cardSize} isHorizontalScroll={!!horizontalScroll} />
        ))}
        {!!horizontalScroll && <div />}
      </div>
    </section>
  );
};

export default ArticlesList;
