import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Devices, HomePageTypes } from 'constants/app';
import { ASIAN_BASE_URL, IN_PLAY_BASE_URL } from 'constants/locations';
import { getAppDevice, getHomePageType } from 'redux/modules/appConfigs/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { getDefaultLandingPage } from 'redux/modules/user/selectors';

export const useMobileNavigation = () => {
  const location = useLocation();

  const device = useSelector(getAppDevice);
  const homePageType = useSelector(getHomePageType);
  const defaultLandingPage = useSelector(getDefaultLandingPage);
  const isMobileAsianView = useSelector(getIsMobileAsianView);

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const pathName = location.pathname.replace(/\/\/*/g, '/').replace(/\/+$/, '');
  const isMobile = isAsianViewPage ? isMobileAsianView : device === Devices.MOBILE;

  const isDefaultInPlayLandingPath =
    isMobile &&
    homePageType === HomePageTypes.LINK &&
    defaultLandingPage?.includes(IN_PLAY_BASE_URL) &&
    pathName.includes(IN_PLAY_BASE_URL);

  return { isDefaultInPlayLandingPath };
};
