import { toNumber } from 'lodash';

import { BetsStatusesTypes, CurrentBetActions } from 'constants/app';
import { BetSides } from 'constants/myBets';
import { TCurrentBet } from 'redux/modules/currentBets/type';

// Check if offer is MATCHED
export const isMatchedOffer = (bet: TCurrentBet, isFullyMatchedOnly = false) =>
  /** if precision of '1' is set for virtual currency */
  (toNumber(bet.sizeMatched) === 0 &&
    toNumber(bet.sizeRemaining) === 0 &&
    toNumber(bet.sizeCancelled) === 0 &&
    bet.offerState === BetsStatusesTypes.MATCHED) ||
  /** if it is fully matched */
  (toNumber(bet.sizeMatched) > 0 && toNumber(bet.sizeRemaining) === 0) ||
  /** if it is partially matched */
  (!isFullyMatchedOnly && toNumber(bet.sizeMatched) > 0 && toNumber(bet.sizeRemaining) > 0);

// Check if offer is UNMATCHED or PARTIALLY MATCHED
export const isUnmatchedOffer = ({
  bet,
  anyCancelled,
  showLapsed,
  unmatchedOffersIdsToShowLapsed,
  showLapsedAlways
}: {
  bet: TCurrentBet;
  anyCancelled?: boolean;
  showLapsed?: boolean;
  unmatchedOffersIdsToShowLapsed?: Record<string, boolean>;
  showLapsedAlways?: boolean;
}) => {
  const notHidden = bet.action !== CurrentBetActions.HIDDEN;
  const isPlaced = bet.offerState === BetsStatusesTypes.PLACED;
  const isFullyMatched = bet.action === CurrentBetActions.FULLY_MATCHED;
  const displayLapsed =
    bet.offerState === BetsStatusesTypes.LAPSED &&
    (showLapsedAlways ||
      (showLapsed &&
        unmatchedOffersIdsToShowLapsed &&
        Object.keys(unmatchedOffersIdsToShowLapsed).includes(String(bet.offerId))));
  const displayCancelled = anyCancelled && bet.offerState === BetsStatusesTypes.CANCELLED;
  const cancelledAndCancelling =
    bet.offerState === BetsStatusesTypes.CANCELLED && bet.action === CurrentBetActions.CANCELLING;
  const cancelledAndEditing =
    bet.offerState === BetsStatusesTypes.CANCELLED && bet.action === CurrentBetActions.EDITING;
  const isMatchedWithRemaining = bet.offerState === BetsStatusesTypes.MATCHED && +(bet.sizeRemaining || 0) > 0;
  const isMatched = bet.offerState === BetsStatusesTypes.MATCHED;
  const isCancellingOrCancellingAllAction =
    bet.action === CurrentBetActions.CANCELLING || bet.action === CurrentBetActions.CANCELLING_ALL;
  const isPartiallyMatchedWasFullyMatched =
    bet.action === CurrentBetActions.PLACED_PARTIALLY_MATCHED &&
    bet.offerState === BetsStatusesTypes.MATCHED &&
    Number(bet.sizeRemaining) === 0;

  if (bet.disabledLayOdds && bet.side == BetSides.Lay) {
    return false;
  }

  return (
    notHidden &&
    (isPlaced ||
      isFullyMatched ||
      displayLapsed ||
      displayCancelled ||
      cancelledAndCancelling ||
      cancelledAndEditing ||
      isMatchedWithRemaining ||
      isPartiallyMatchedWasFullyMatched ||
      (isMatched && isCancellingOrCancellingAllAction))
  );
};

const roundPriceLineBet = (bet: TCurrentBet, priceField: 'price' | 'averagePriceRounded') => bet[priceField] ?? 0;

export const mapBet = (bet: TCurrentBet) => {
  return {
    ...bet,
    averagePriceRounded: roundPriceLineBet(bet, 'averagePriceRounded'),
    price: roundPriceLineBet(bet, 'price')
  };
};
