import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ElementNames } from 'constants/app';
import useContextualHelp from 'hooks/useContextualHelp';
import useElementSize from 'hooks/useElementSize';
import { setElementHeight } from 'redux/modules/appSettings';
import { getContextualHelp } from 'redux/modules/tooltip/selectors';

import ToggleSwitch from './components/ToggleSwitch';

import styles from './styles.module.scss';

const ContextualHelpSection = () => {
  const dispatch = useDispatch();
  const contextualHelpValue = useSelector(getContextualHelp);
  const { handleToggleSwitch } = useContextualHelp();
  const { t } = useTranslation();

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.CONTEXTUAL_HELP, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.CONTEXTUAL_HELP, height: 0 }));
    };
  }, []);

  return (
    <div
      ref={componentRef}
      className={classNames('biab_navigation-section', 'biab_tooltips-nav', styles.contextualHelp)}
    >
      {t('navigation.contextualHelp')}
      <ToggleSwitch checked={contextualHelpValue} onChange={handleToggleSwitch} />
    </div>
  );
};

export default ContextualHelpSection;
