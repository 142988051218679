import { memo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { MarketStatuses } from 'constants/app';
import { asianViewMiddleSection as branding } from 'constants/branding';
import { MARKET_TYPES } from 'constants/marketTypes';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { TAsianViewMarket } from 'redux/modules/asianView/type';
import {
  getIsMarketInPlayByMarketPricesId,
  getIsMarketPricesKeyLineDefinition,
  getMarketPricesAwayScore,
  getMarketPricesFirstKeyLineAdjustedHandicap,
  getMarketPricesFirstKeyLineHandicap,
  getMarketPricesFirstKeyLineId,
  getMarketPricesHomeScore,
  getMarketPricesId,
  getMarketPricesRunners,
  getMarketPricesSecondKeyLineAdjustedHandicap,
  getMarketPricesSecondKeyLineHandicap,
  getMarketPricesSecondKeyLineId,
  getMarketPricesStatusById
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';

import AVMarketSelectionsByIndex from './AVMarketSelectionsByIndex/AVMarketSelectionsByIndex';
import AsianViewBackLayLabels from './AsianViewBackLayLabels';

import styles from './styles.module.scss';

interface MarketCellProps {
  competitionId: string;
  eventId: string;
  market: TAsianViewMarket;
  isFirstEvent: boolean;
  isExpanded?: boolean;
  marketIndex?: number;
  isSingleLine?: boolean;
}

const MarketCell = ({
  competitionId,
  eventId,
  market,
  isFirstEvent,
  isExpanded,
  marketIndex = 0,
  isSingleLine = false
}: MarketCellProps) => {
  const marketPricesId = useSelector(getMarketPricesId(market.id));
  const runnerStatuses = useSelector(getMarketPricesRunners(market.id));
  const homeScore = useSelector(getMarketPricesHomeScore(market.id));
  const awayScore = useSelector(getMarketPricesAwayScore(market.id));
  const status = useSelector(getMarketPricesStatusById(market.id));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(market.id));
  const firstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(market.id));
  const firstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(market.id));
  const firstKeyLineAdjHandicap = useSelector(getMarketPricesFirstKeyLineAdjustedHandicap(market.id));
  const secondKeyLineId = useSelector(getMarketPricesSecondKeyLineId(market.id));
  const secondKeyLineHandicap = useSelector(getMarketPricesSecondKeyLineHandicap(market.id));
  const secondKeyLineAdjHandicap = useSelector(getMarketPricesSecondKeyLineAdjustedHandicap(market.id));
  const isKeyLine = useSelector(getIsMarketPricesKeyLineDefinition(market.id));

  const isLayBetting = useAsianLayBetting();
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market.id,
    eventId,
    observerOptions: { rootMargin: '200px' },
    section: MarketsPricesSocketParamsSections.AsianViewMiddleSection
  });

  const isGoalLines = market.marketType === MARKET_TYPES.altTotalGoals;
  const isAsianHandicap = market.marketType === MARKET_TYPES.asianHandicap;
  const hasSelectionName = market.marketType.includes(MARKET_TYPES.firstHalfGoals) || isGoalLines || isAsianHandicap;
  const isClosed = status === MarketStatuses.CLOSED;
  const hasBackLayBtns = isFirstEvent && isLayBetting && !isClosed;
  const hasScore = isInPlay && homeScore !== undefined && awayScore !== undefined;
  const isVisible = !(!market || (market && isAsianHandicap && isInPlay && !hasScore));

  return (
    <div
      data-market-id={market.id}
      data-event-id={eventId}
      data-market-prices="true"
      ref={ref}
      className={classNames('biab_asian-view-market-cell', styles.marketCell)}
    >
      {marketIndex === 0 && hasBackLayBtns && isSingleLine && (
        <div className={classNames(styles.marketCell__market__backLay__wrapper)}>
          <AsianViewBackLayLabels containerClassName={styles.marketCell__backLay} isSmall={isSingleLine} />
          {isSingleLine && (
            <AsianViewBackLayLabels containerClassName={styles.marketCell__backLay} isSmall={isSingleLine} />
          )}
        </div>
      )}
      {isVisible && (
        <>
          <AVMarketSelectionsByIndex
            hasBackLayBtns={hasBackLayBtns}
            isSingleLine={isSingleLine}
            index={marketIndex}
            isFirstEvent={isFirstEvent}
            market={market}
            eventId={eventId}
            competitionId={competitionId}
            hasSelectionName={hasSelectionName}
            marketPricesId={marketPricesId}
            secondKeyLineId={secondKeyLineId}
            isKeyLine={isKeyLine}
            firstKeyLineAdjHandicap={firstKeyLineAdjHandicap}
            secondKeyLineAdjHandicap={secondKeyLineAdjHandicap}
            firstKeyLineHandicap={firstKeyLineHandicap}
            firstKeyLineId={firstKeyLineId}
            secondKeyLineHandicap={secondKeyLineHandicap}
            isGoalLines={isGoalLines}
            isAsianHandicap={isAsianHandicap}
            isClosed={isClosed}
            runnerStatuses={runnerStatuses}
          />
          {isExpanded &&
            !isClosed &&
            (isAsianHandicap || isGoalLines ? (
              <>
                <AVMarketSelectionsByIndex
                  hasBackLayBtns={hasBackLayBtns}
                  isSingleLine={isSingleLine}
                  index={1}
                  isFirstEvent={isFirstEvent}
                  market={market}
                  eventId={eventId}
                  competitionId={competitionId}
                  hasSelectionName={hasSelectionName}
                  marketPricesId={marketPricesId}
                  secondKeyLineId={secondKeyLineId}
                  isKeyLine={isKeyLine}
                  firstKeyLineAdjHandicap={firstKeyLineAdjHandicap}
                  secondKeyLineAdjHandicap={secondKeyLineAdjHandicap}
                  firstKeyLineHandicap={firstKeyLineHandicap}
                  firstKeyLineId={firstKeyLineId}
                  secondKeyLineHandicap={secondKeyLineHandicap}
                  isGoalLines={isGoalLines}
                  isAsianHandicap={isAsianHandicap}
                  isClosed={isClosed}
                  runnerStatuses={runnerStatuses}
                />
                <AVMarketSelectionsByIndex
                  hasBackLayBtns={hasBackLayBtns}
                  isSingleLine={isSingleLine}
                  index={2}
                  isFirstEvent={isFirstEvent}
                  market={market}
                  eventId={eventId}
                  competitionId={competitionId}
                  hasSelectionName={hasSelectionName}
                  marketPricesId={marketPricesId}
                  secondKeyLineId={secondKeyLineId}
                  isKeyLine={isKeyLine}
                  firstKeyLineAdjHandicap={firstKeyLineAdjHandicap}
                  secondKeyLineAdjHandicap={secondKeyLineAdjHandicap}
                  firstKeyLineHandicap={firstKeyLineHandicap}
                  firstKeyLineId={firstKeyLineId}
                  secondKeyLineHandicap={secondKeyLineHandicap}
                  isGoalLines={isGoalLines}
                  isAsianHandicap={isAsianHandicap}
                  isClosed={isClosed}
                  runnerStatuses={runnerStatuses}
                />
              </>
            ) : (
              <>
                <div
                  className={classNames(
                    'biab_asian-view-market-empty',
                    styles.marketCell__market__empty,
                    branding.MAIN_CONTAINER_BORDER
                  )}
                />
                <div
                  className={classNames(
                    'biab_asian-view-market-empty',
                    styles.marketCell__market__empty,
                    branding.MAIN_CONTAINER_BORDER
                  )}
                />
              </>
            ))}
        </>
      )}
      {!isVisible && !hasScore && isAsianHandicap && (
        <>
          {(isSingleLine ? [...Array(3)] : [...Array(1)]).map((value, index) => (
            <AVMarketSelectionsByIndex
              key={`empty_market_${index}`}
              hasBackLayBtns={hasBackLayBtns}
              isSingleLine={isSingleLine}
              index={index}
              isFirstEvent={isFirstEvent}
              market={market}
              eventId={eventId}
              hideRunners
              competitionId={competitionId}
              hasSelectionName={hasSelectionName}
              marketPricesId={marketPricesId}
              secondKeyLineId={secondKeyLineId}
              isKeyLine={isKeyLine}
              firstKeyLineAdjHandicap={firstKeyLineAdjHandicap}
              secondKeyLineAdjHandicap={secondKeyLineAdjHandicap}
              firstKeyLineHandicap={firstKeyLineHandicap}
              firstKeyLineId={firstKeyLineId}
              secondKeyLineHandicap={secondKeyLineHandicap}
              isGoalLines={isGoalLines}
              isAsianHandicap={isAsianHandicap}
              isClosed={isClosed}
              runnerStatuses={runnerStatuses}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default memo(MarketCell);
