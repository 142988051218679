import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewSingleEventOutrightLink from 'components/AsianViewSingleEventOutrightLink';
import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import { OddsTypes, SportIds } from 'constants/app';
import {
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  ASIAN_VIEW_PAGE_PARAM,
  ASIAN_VIEW_PL_TABLE_AVAILABLE_MARKETS,
  AsianViewMarketLinks,
  AsianViewTimeFilters
} from 'constants/asianView';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import { ASIAN_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetSides } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getIsDesktopInfiniteScrollEnabled } from 'redux/modules/appConfigs/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getIsValidMarketLink, getIsValidTimeFilter } from 'utils/asianView';
import convertOdds from 'utils/oddsConverter';

import styles from './styles.module.scss';

type OpenBetProps = {
  bet: TCurrentBet;
};

const OpenBet = ({ bet }: OpenBetProps) => {
  const { t } = useTranslation();
  const { sportId, timeFilter, marketLink } = useParams();

  const isInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);

  const isAsianHandicap = bet.marketType == MARKET_TYPES.asianHandicap;
  const score = bet?.score || '';
  const scoreLabel = isAsianHandicap && score ? `(${score})` : '';
  const payout =
    +(bet.potentialProfit || 0) + (bet.side === BetSides.Back ? +(bet.sizeMatched || 0) : +(bet.liability || 0));
  const sportIdParam = `&${ASIAN_SINGLE_VIEW_SPORT_ID}=${bet.eventTypeId}`;
  const pageParam = isInfiniteScrollEnabled ? '' : `&${ASIAN_VIEW_PAGE_PARAM}=0`;
  const oddsValue = convertOdds(bet?.averagePrice || '', bet.oddsType || OddsTypes.DEC);

  const formattedDate = useLongDateFormat({
    isTimeFirst: true,
    isShortYear: false,
    time: bet.placedDate ?? 0,
    hasYear: true,
    divider: ' ',
    dateDivider: '-',
    isMonthNumeric: true,
    isAsian: true
  });

  const [formattedTime, formattedDay] = formattedDate.split(' ');

  const { noFormattedAmount: stake } = useFormatCurrency(bet.sizeMatched || 0, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  const { noFormattedAmount: liability } = useFormatCurrency(bet.liability || 0, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  const { noFormattedAmount: profit } = useFormatCurrency(bet.potentialProfit || 0, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  const { noFormattedAmount: payoutFormatted } = useFormatCurrency(payout, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  return (
    <div
      className={classNames('biab_open-bet-wrapper', styles.openBet, branding.MATCHED_BET, {
        [styles.openBet__triggeredByCashOut]: bet.triggeredByCashOut,
        [branding.TRIGGERED_BY_CASH_OUT]: bet.triggeredByCashOut
      })}
    >
      <div className={classNames('biab_open-bet-event-wrapper', styles.openBet__eventWrapper, branding.BET_SEPARATOR)}>
        {bet.eventInPlay && (
          <span className={classNames('biab_open-bet-live', styles.openBet__live)}>
            {t('asianView.labels.timeFilter.live')}
          </span>
        )}
        {bet.triggeredByCashOut && (
          <>
            <span
              className={classNames(
                'biab_open-bet-cash-out',
                styles.openBet__triggeredByCashOutLabel,
                branding.TRIGGERED_BY_CASH_OUT_LABEL
              )}
            >
              {t('asianView.labels.triggeredByCashOut')}
            </span>
            <span className={styles.openBet__break} />
          </>
        )}
        <div className={styles.openBet__header}>
          <AsianViewSingleEventOutrightLink
            isOutrightLink={!!bet.isOutright}
            sportId={bet.eventTypeId.toString()}
            competitionId={(bet.competitionId || '')?.toString()}
            marketId={bet.marketId}
            eventId={bet.eventId}
            competitionName={bet.competitionName}
            eventName={bet.eventName}
            marketName={bet.marketName}
            className={classNames('biab_open-bet-event', styles.openBet__event, styles.openBet__link)}
          />
          {ASIAN_VIEW_PL_TABLE_AVAILABLE_MARKETS.includes(bet.marketType) && (
            <ProfitLossTablePopUpButton isActive eventId={bet.eventId} marketId={bet.marketId} />
          )}
        </div>
        {!bet.isOutright && bet.competitionName && (
          <>
            <span className={styles.openBet__break} />
            <Link
              className={classNames('biab_open-bet-competition', styles.openBet__competition, styles.openBet__link)}
              to={{
                pathname: `${ASIAN_BASE_URL}/sport/${sportId ?? SportIds.SOCCER}/timeFilter/${
                  !timeFilter || !getIsValidTimeFilter(timeFilter) ? AsianViewTimeFilters.Today : timeFilter
                }/marketLink/${
                  !marketLink || !getIsValidMarketLink(marketLink) ? AsianViewMarketLinks.HDP_AND_OU : marketLink
                }`,
                search: `${ASIAN_SINGLE_VIEW_COMPETITION_ID}=${bet.competitionId}${sportIdParam}${pageParam}`
              }}
            >
              {bet.competitionName}
            </Link>
          </>
        )}
      </div>
      <div className={classNames('biab_open-bet-market', styles.openBet__market)}>
        {bet.isOutright
          ? `${bet.selectionName} ${scoreLabel}`
          : `${bet.marketName} - ${bet.selectionName} ${scoreLabel}`}
      </div>
      <div className={classNames('biab_open-bet-row', styles.openBet__row)}>
        <div>
          <span className={classNames('biab_open-bet-odds', styles.openBet__odds)}>
            <i className={styles.openBet__odds__sign}>@</i>
            {oddsValue}{' '}
          </span>
          <span className={classNames('biab_open-bet-odds-type', styles.openBet__odds__type)}>
            ({t(`asianView.labels.betslip.oddsType.${bet.oddsType || OddsTypes.DEC}`)})
          </span>
        </div>
        <p className="biab_open-bet-col-name">
          {bet.side === BetSides.Back
            ? t(`betslip.labels.type.${BetSides.Back.toLowerCase()}`)
            : t(`betslip.labels.type.${BetSides.Lay.toLowerCase()}`)}
        </p>
      </div>
      <hr className={branding.BET_SEPARATOR} />
      <div className={classNames('biab_open-bet-row', styles.openBet__row)}>
        <p className="biab_open-bet-label">
          {bet.side === BetSides.Back ? t('asianView.labels.betslip.stake') : t('asianView.labels.betslip.risk')}:
        </p>
        <p className={classNames('biab_open-bet-stake', styles.openBet__value)}>
          {bet.side === BetSides.Back ? stake : liability}
        </p>
      </div>
      <div className={classNames('biab_open-bet-row', styles.openBet__row)}>
        <p className="biab_open-bet-label">{t('asianView.labels.betslip.profit')}:</p>
        <p className={classNames('biab_open-bet-profit', styles.openBet__value)}>
          {bet.side === BetSides.Back ? profit : stake}
        </p>
      </div>
      <div className={classNames('biab_open-bet-row', styles.openBet__row)}>
        <p className="biab_open-bet-label">{t('asianView.labels.betslip.payout')}:</p>
        <p className={classNames('biab_open-bet-payout', styles.openBet__value)}>{payoutFormatted}</p>
      </div>
      <div className={classNames('biab_open-bet-row', styles.openBet__row, styles.openBet__offerInfo)}>
        <p className={classNames('biab_open-bet-offer', styles.openBet__offer)}>
          {t('asianView.labels.betslip.offerId')}:{bet.offerId}
        </p>
        <div className={classNames('biab_open-bet-date', styles.openBet__date)}>
          <span className={styles.openBet__date__time}>{formattedTime}</span>
          <span>{formattedDay}</span>
        </div>
      </div>
    </div>
  );
};

export default OpenBet;
