import { useSelector } from 'react-redux';

import BetsIndicator from 'components/SearchContent/components/BetsIndicator';
import { MatchTypes } from 'constants/bets';
import { getCurrentEventBetsAmountByType } from 'redux/modules/currentBets/selectors';

interface BetsIndicatorMarketPageProps {
  eventTypeId: string;
  marketId: string;
  eventId: string;
}

const BetsIndicatorMarketPage = ({ eventTypeId, marketId, eventId }: BetsIndicatorMarketPageProps) => {
  const matchedBets = useSelector(
    getCurrentEventBetsAmountByType({ eventId, type: MatchTypes.MATCHED, ignoreCancelled: true })
  );

  const unmatchedBets = useSelector(
    getCurrentEventBetsAmountByType({
      eventId,
      type: MatchTypes.UNMATCHED,
      ignoreCancelled: true,
      ignoreFullyMatchedAction: true
    })
  );

  return (
    <BetsIndicator
      matchedBets={matchedBets}
      unmatchedBets={unmatchedBets}
      eventTypeId={eventTypeId}
      marketId={marketId}
      inMarketsTableRow
    />
  );
};

export default BetsIndicatorMarketPage;
