import { TGameChannels, TGameOrder, TGamesTitles } from 'types';

export const CHANNELS: TGameChannels = {
  1444082: { order: 1, isTurbo: true }, // Turbo BlackJack
  1444077: { order: 2, isTurbo: false }, // BlackJack
  1444080: { order: 5, isTurbo: true }, // Turbo Texas Hold'Em
  1444074: { order: 6, isTurbo: false }, // Texas Hold'em
  1444100: { order: 7, isTurbo: false }, // Omaha Hi
  1444086: { order: 9, isTurbo: false }, // Baccarat
  1444093: { order: 13, isTurbo: false }, // Hi Lo
  1444089: { order: 8, isTurbo: true }, // Turbo Baccarat
  1444096: { order: 12, isTurbo: true }, // Turbo Hi Lo
  1444116: { order: 4, isTurbo: false }, // Card Derby Racing
  1444126: { order: 3, isTurbo: true }, // Turbo Card Derby Racing
  1444170: { order: 11, isTurbo: false }, // Bullseye Roulette
  1444151: { order: 10, isTurbo: true } // Bullseye Roulette Turbo
};

export const GROUPED_SORT_ORDER: TGameOrder = {
  BLACKJACK: 1,
  HILO: 3,
  POKER: 4,
  BACCARAT: 5,
  OMAHA_POKER: 7,
  CARD_RACER: 2,
  BULLS_EYE_ROULETTE: 6
};

export const GAMES_TITLES: TGamesTitles = {
  BLACKJACK: 'games.titles.BLACKJACK',
  HILO: 'games.titles.HILO',
  POKER: 'games.titles.POKER',
  BACCARAT: 'games.titles.BACCARAT',
  OMAHA_POKER: 'games.titles.OMAHA_POKER',
  CARD_RACER: 'games.titles.CARD_RACER',
  BULLS_EYE_ROULETTE: 'games.titles.BULLS_EYE_ROULETTE'
};

export const GAME_TYPES = {
  HILO: 'HILO',
  BACCARAT: 'BACCARAT'
};

export const DEFAULT_GAMES_RESULT_PAGE = 0;
export const BLACKJACK_GAMES_ROUNDS = [1, 2, 3, 4, 5, 6, 7];
export const BACCARAT_GAMES_ROUNDS = [1, 2, 3, 4, 5, 6];
export const POKER_GAMES_ROUNDS = ['deal', 'preflop', 'flop', 'turn', 'river'];
export const GAMES_STATUSES = ['WINNER', 'LOSER', 'TIED'];
export const VALUES_MAP_TO_CARDS = [
  { id: '40', value: '2', order: 1, isCard: true },
  { id: '41', value: '3', order: 2, isCard: true },
  { id: '42', value: '4', order: 3, isCard: true },
  { id: '43', value: '5', order: 4, isCard: true },
  { id: '44', value: '6', order: 5, isCard: true },
  { id: '45', value: '7', order: 6, isCard: true },
  { id: '46', value: '8', order: 7, isCard: true },
  { id: '47', value: '9', order: 8, isCard: true },
  { id: '48', value: '10', order: 9, isCard: true },
  { id: '49', value: 'J', order: 10, isCard: true },
  { id: '50', value: 'Q', order: 11, isCard: true },
  { id: '51', value: 'K', order: 12, isCard: true },
  { id: '39', value: 'A', order: 13, isCard: true }
];

export const NAMES_BACCARAT_SHOE = ['A', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K'];

export const MARKET_TABS_MAIN = [
  { translationKey: 'games.marketTypes.CorrectPredictions' },
  { translationKey: 'games.marketTypes.SideBets' }
];

export const MARKET_TABS_BACCARAT = [
  { translationKey: 'games.marketTypes.MainBets' },
  { translationKey: 'games.marketTypes.SideBets' }
];

export const GameFilterValues = {
  All: 'All',
  BetPlaced: 'Bet Placed'
} as const;

export const GameTypes = {
  POKER: 'POKER',
  BLACKJACK: 'BLACKJACK',
  OMAHA_POKER: 'OMAHA_POKER',
  BACCARAT: 'BACCARAT',
  HILO: 'HILO',
  BULLS_EYE_ROULETTE: 'BULLS_EYE_ROULETTE',
  CARD_RACER: 'CARD_RACER'
} as const;

export const GameChannelStatuses = {
  RUNNING: 'RUNNING'
} as const;

export const GameDataTypes = {
  SEGMENT: 'Segment',
  PLACE: 'Place'
} as const;
export const CardExceptionValues = {
  NOT_AVAILABLE: 'NOT AVAILABLE'
} as const;

export const GameActionsTypes = {
  NEXT: 'NEXT',
  PREV: 'PREV',
  LAST: 'LAST',
  FIRST: 'FIRST'
} as const;

export const GameMarketTypes = {
  WIN_ONLY: 'WIN_ONLY',
  ANY_NUMBER_OF_WINNERS: 'ANY_NUMBER_OF_WINNERS',
  SINGLE_WINNER_OR_TIE: 'SINGLE_WINNER_OR_TIE',
  VARIABLE_HANDICAP: 'VARIABLE_HANDICAP'
} as const;

export const GameMarketStatuses = {
  ACTIVE: 'ACTIVE',
  SUSPENDED_GAME_PLAYING: 'SUSPENDED_GAME_PLAYING',
  SUSPENDED_GAME_SETTLING: 'SUSPENDED_GAME_SETTLING',
  SUSPENDED_GAME_ROUND_OVER: 'SUSPENDED_GAME_ROUND_OVER'
} as const;

export const GamePages = {
  RESULTS: 'RESULTS',
  GAME: 'GAME'
} as const;

export const GameSelectionStatuses = {
  IN_PLAY: 'IN_PLAY',
  WINNER: 'WINNER',
  CAN_NOT_LOSE: 'CAN_NOT_LOSE',
  CAN_NOT_WIN: 'CAN_NOT_WIN',
  LOSER: 'LOSER',
  REFUND: 'REFUND',
  TIED_DEAD_HEAT: 'TIED_DEAD_HEAT'
} as const;
