export const tabulation = {
  // event tab keyCode
  TAB_KEY_CODE: 9,
  PRICE_ORDER: 1,
  SIZE_ORDER: 2,
  PROFIT_ORDER: 3,
  /* Tabulate through ODDS (if not PNC), SIZE, PROFIT on each bet */
  BET_TABS: 3,
  FULL_BET_TABS: 4,
  OPENED_BET_TABS: 6,
  // Place bets buttons
  CANCEL_ALL_BTN_ORDER: 2,
  EDIT_BTN_ORDER: 4,
  PLACE_BTN_ORDER: 1,
  CONFIRM_BTN_ORDER: 3,
  AUTO_REFRESH_CHECKBOX_ORDER: 5,
  CONFIRM_CHECKBOX_ORDER: 6,
  BETTING_PL_CHECKBOX_ORDER: 7,
  INLINE_BETTING_CHECKBOX_ORDER: 8,
  // open bets buttons
  CANCEL_OPENED_BET_ORDER: 4,
  TAKE_OPENED_BET_ORDER: 5,
  UPDATE_OPENED_BET_ORDER: 6
};

export const KEY_CODES = {
  TAB: 'Tab',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown'
};

export const cancelParam = 'cancel';

export const ASIAN_VIEW_PLACE_BET_ERRORS_IDS = {
  EX001: 'EX001',
  EX002: 'EX002',
  EX003: 'EX003',
  EX004: 'EX004',
  EX007: 'EX007',
  EX009: 'EX009',
  EX010: 'EX010',
  EX011: 'EX011',
  EX013: 'EX013',
  EX014: 'EX014',
  EX016: 'EX016',
  EX020: 'EX020',
  EX026: 'EX026'
} as const;

export type ErrorIdType = typeof ASIAN_VIEW_PLACE_BET_ERRORS_IDS[keyof typeof ASIAN_VIEW_PLACE_BET_ERRORS_IDS];

export const ASIAN_VIEW_PLACE_BET_ERRORS_WITH_INTERVAL: ErrorIdType[] = [
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX001,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX002,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX003,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX004,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX007,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX009,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX010,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX011,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX020,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX026
];

export const ASIAN_VIEW_BETSLIP_VALIDATION_MESSAGE_TIMEOUT = 10000;
export const BETSLIP_SELECTED_BETS_STORAGE_NAME = 'BETSLIP_SELECTED_BETS';
export const BETSLIP_NOTIFICATIONS_STORAGE_NAME = 'BETSLIP_NOTIFICATIONS';
export const BETSLIP_SELECTED_MARKETS_LIMIT = 10;
export const BET_SLIP_UNMATCHED_BET_PROCESS_MESSAGE_TIMEOUT = 10000;
export const BET_SLIP_UNMATCHED_BET_WAS_NOT_PLACED_MESSAGE_TIMEOUT = 300000;

export const BETSLIP_UNIQ_SELECTED_BET_SEPARATOR = '__';
export const BET_SLIP_PNC_ODDS_INDICATOR_TIMEOUT = 3000;

export const BETSLIP_LINE_INT_ODDS = 2;

export const HIDDEN_PARTIAL_MATCHED_NOTIFICATIONS_LOCAL_STORAGE_NAME =
  'HIDDEN_PARTIAL_MATCHED_NOTIFICATIONS_LOCAL_STORAGE_NAME';

export const BetslipFilters = {
  MARKET: 'MARKET',
  SORTING: 'SORTING',
  EVENT: 'EVENT'
} as const;

export const BetslipTabs = {
  PLACE_BETS: 'PLACE_BETS',
  OPEN_BETS: 'OPEN_BETS'
} as const;

export const PlaceBetsStates = {
  SELECT: 'SELECT',
  CONFIRM: 'CONFIRM',
  READONLY: 'READONLY',
  CONFIRM_REMOVING: 'CONFIRM_REMOVING',
  PROGRESS: 'PROGRESS',
  TAKE_OFFER: 'TAKE_OFFER',
  PLACE: 'PLACE',
  PLACE_ENTER: 'PLACE_ENTER',
  DISABLED: 'DISABLED'
} as const;

export const BetErrors = {
  EX015: 'EX015'
} as const;

export const BetFocusFields = {
  PRICE: 'PRICE',
  SIZE: 'SIZE',
  LIABILITY: 'LIABILITY'
} as const;

export const BetslipTypes = {
  EXCHANGE: 'EXCHANGE',
  GAME: 'GAME'
} as const;

export const InputFieldTypes = {
  PRICE: 'PRICE',
  SIZE: 'SIZE',
  PROFIT: 'PROFIT',
  NONE: 'NONE'
} as const;

export const ActionTypes = {
  ADD: 'ADD',
  SUBSTR: 'SUBSTR'
} as const;

export const PlacementTypes = {
  PLACE: 'PLACE',
  UPDATE: 'UPDATE',
  CANCEL: 'CANCEL'
} as const;

export const PlacementStatuses = {
  INIT: 'INIT',
  GET_STATUSES: 'GET_STATUSES',
  GET_PLACED_BET: 'GET_PLACED_BET',
  CANCEL: 'CANCEL'
} as const;

export const FormActions = {
  NONE: 'NONE',
  VALIDATE: 'VALIDATE',
  PROFIT_EDIT: 'PROFIT_EDIT'
} as const;

export const LineSides = {
  UNDER: 'UNDER',
  OVER: 'OVER'
} as const;

export const AHVirtualOutcomes = {
  WIN: 'WIN',
  HALF_WIN: 'HALF_WIN',
  STAKE_REFUND: 'STAKE_REFUND',
  HALF_LOSS: 'HALF_LOSS',
  LOSS: 'LOSS'
} as const;

export const CancelAllBtnStates = {
  ACTIVE: 'ACTIVE',
  CONFIRM: 'CONFIRM',
  CANCELLING: 'CANCELLING',
  HIDDEN: 'HIDDEN'
} as const;

export const BetTooltips = {
  ODDS: 'ODDS',
  SIZE: 'SIZE',
  PROFIT: 'PROFIT'
} as const;

export const BetActions = {
  NONE: 'NONE',
  PROGRESS: 'PROGRESS',
  UPDATED: 'UPDATED',
  CANCELLED: 'CANCELLED'
} as const;
