import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ElementNames, OddsTypes, SLICES_NAMES } from 'constants/app';
import { TAppSettingsResponse, TScrollUpdate, TSetElementHeightPayload } from 'redux/modules/appSettings/type';
import { OddsType } from 'types';

import { AppSettings } from './type';

const initialState: AppSettings = {
  settings: [],
  loading: false,
  parentDomain: '',
  accountPageReferer: '',
  isShowingSearch: false,
  isAsianViewMenuOpen: false,
  oddsType: OddsTypes.DEC,
  scrollContentHeight: 0,
  elementsHeights: {
    [ElementNames.HEADER_HEIGHT]: 0,
    [ElementNames.FOOTER_HEIGHT]: 0,
    [ElementNames.COLLAPSE_HEIGHT]: 20,
    [ElementNames.HOW_TO_MENU_TITLE_HEIGHT]: 0,
    [ElementNames.HOW_TO_SECTION_HEIGHT]: 0,
    [ElementNames.ACTIVE_MENU_TITLE_HEIGHT]: 0,
    [ElementNames.ACTIVE_MENU_HEIGHT]: 0,
    [ElementNames.FAVORITES_MENU_TITLE_HEIGHT]: 0,
    [ElementNames.FAVORITES_MENU_HEIGHT]: 0,
    [ElementNames.NAVIGATION_PARENT_SECTION]: 0,
    [ElementNames.NAVIGATION_PARENT_SPORT_NAME]: 0,
    [ElementNames.NAVIGATION_ALL_SPORT_ITEM]: 0,
    [ElementNames.NAVIGATION_ALL_SPORTS_CONTENT_HEIGHT]: 0,
    [ElementNames.NAVIGATION_HIDE_NAVBAR_BUTTON]: 0,
    [ElementNames.CONTEXTUAL_HELP]: 0,
    [ElementNames.BANNER]: 0,
    [ElementNames.WEBTOURS_IMAGE]: 0,
    [ElementNames.FAVORITES_CONTENT_HEIGHT]: 0,
    [ElementNames.ACTIVE_MARKETS_CONTENT_HEIGHT]: 0,
    [ElementNames.SIDEBAR_HEIGHT]: 0,
    [ElementNames.CONTENT_HEIGHT]: 0,
    [ElementNames.NAVIGATION_HEIGHT]: 0,
    [ElementNames.SEARCH_INPUT_HEIGHT]: 0,
    [ElementNames.CASHOUT_HEADER_HEIGHT]: 0,
    [ElementNames.MIN_NAV_HEIGHT]: 0,
    [ElementNames.MOBILE_FOOTER_NAV_HEIGHT]: 0,
    [ElementNames.MOBILE_PAGE_HEIGHT]: 0,
    [ElementNames.MOBILE_CONTENT_HEIGHT]: 0,
    [ElementNames.MOBILE_HEADER_HEIGHT]: 0,
    [ElementNames.MOBILE_SWIPE_MENU_HEIGHT]: 0,
    [ElementNames.MOBILE_ASIAN_SUB_HEADER]: 0,
    [ElementNames.POPULAR_MARKETS_HEIGHT]: 0,
    [ElementNames.ASIAN_NAVIGATION_SPORTS_HEIGHT]: 0,
    [ElementNames.ASIAN_TIMEZONE]: 0,
    [ElementNames.ASIAN_BALANCE]: 0,
    [ElementNames.ASIAN_BET_SLIP_COLLAPSE]: 0,
    [ElementNames.ASIAN_BET_SLIP_TABS]: 0,
    [ElementNames.ASIAN_BET_SLIP_SETTINGS_AND_TOTAL_LIABILITY]: 0,
    [ElementNames.ASIAN_FAVOURITES_NAVIGATION]: 0,
    [ElementNames.ASIAN_MIDDLE_SECTION]: 0
  },
  isUserScrolling: false,
  isScrollingToTop: false,
  scrollUpdate: { top: 0, offset: 0, trigger: false }
};

const slice = createSlice({
  name: SLICES_NAMES.APP_SETTINGS,
  initialState,
  reducers: {
    fetchAppSettings: (state, _: PayloadAction<string>) => {
      state.loading = true;
    },
    successAppSettings: (state, { payload }: PayloadAction<TAppSettingsResponse[]>) => {
      state.settings = payload;
      state.loading = false;
    },
    failureGetAppSettings: state => {
      state.loading = false;
    },
    handlingShowingSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowingSearch = payload;
    },
    setScrollContentHeight: (state, { payload }: PayloadAction<number>) => {
      state.scrollContentHeight = payload;
    },
    setElementHeight: (state, { payload }: PayloadAction<TSetElementHeightPayload>) => {
      const { name, height } = payload;
      state.elementsHeights[name] = height;
    },
    setIsScrollingToTop: (state, { payload }: PayloadAction<boolean>) => {
      state.isScrollingToTop = payload;
    },
    setUSerIsScrolling: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserScrolling = payload;
    },
    setScrollUpdate: (state, { payload }: PayloadAction<TScrollUpdate>) => {
      state.scrollUpdate = payload;
    },
    setParentDomain: (state, { payload }: PayloadAction<string>) => {
      state.parentDomain = payload;
    },
    setAccountPageReferer: (state, { payload }: PayloadAction<string>) => {
      state.accountPageReferer = payload;
    },
    setIsAsianViewMenuOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isAsianViewMenuOpen = payload;
    },
    setOddsType: (state, { payload }: PayloadAction<OddsType>) => {
      state.oddsType = payload;
    }
  }
});

export const {
  fetchAppSettings,
  successAppSettings,
  failureGetAppSettings,
  handlingShowingSearch,
  setScrollContentHeight,
  setElementHeight,
  setUSerIsScrolling,
  setIsScrollingToTop,
  setScrollUpdate,
  setParentDomain,
  setAccountPageReferer,
  setIsAsianViewMenuOpen,
  setOddsType
} = slice.actions;

export default slice.reducer;
