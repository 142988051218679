import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useDebounceValue } from 'usehooks-ts';

import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import { GeneralWebSocketSubscriptionTypes } from 'constants/app';
import { MarketDepthViews } from 'constants/markets';
import { SEARCH_CONTENT_SIZE, SEARCH_DEBOUNCE_DELAY } from 'constants/search';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useMarketDepth from 'hooks/useMarketDepth';
import {
  getEventUpdatesWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getMinSearchQueryLength
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { removeEventsUpdatedDataByEventIds } from 'redux/modules/marketsPrices';
import { fetchSearch } from 'redux/modules/search';
import {
  getCollapsedSports,
  getSearchContentByHeaders,
  getSearchContentByHeadersKeys,
  getSearchContentList,
  getSearchIntersectingEventIds,
  getSearchLoading,
  getSearchResultBySportsKeys,
  getSearchResultsBySport,
  getSearchTotalElements
} from 'redux/modules/search/selectors';
import { TCollapsedSports } from 'redux/modules/search/type';
import { getIsConnectedGeneral, getSubscribeToGeneralMessages } from 'redux/modules/webSocket/selectors';

import SearchListItem from './components/SearchListItem';
import SportHeader from './components/SportHeader';

import styles from './styles.module.scss';

function SearchContent() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const loggedIn = useSelector(getLoggedInStatusState);
  const content = useSelector(getSearchContentList);
  const isSearchLoading = useSelector(getSearchLoading);
  const contentByHeaders = useSelector(getSearchContentByHeaders);
  const collapsedSports = useSelector(getCollapsedSports);
  const contentSports = useSelector(getSearchContentByHeadersKeys);
  const resultSports = useSelector(getSearchResultBySportsKeys);
  const minSearchQueryLength = useSelector(getMinSearchQueryLength);
  const resultsBySport = useSelector(getSearchResultsBySport);
  const totalElements = useSelector(getSearchTotalElements);
  const eventUpdatesWsEnabled = useSelector(getEventUpdatesWsEnabled);

  const query = searchParams.get(PARAMS_SEARCH_KEY) || '';

  const searchContentRef = useRef<HTMLDivElement>(null);

  const eventIds = useMemo(() => content.map(({ id }) => id), [content]);

  const debouncedSearch = useRef(
    debounce((value: string) => {
      dispatch(fetchSearch({ query: value, offset: 0, newQuery: true }));
    }, SEARCH_DEBOUNCE_DELAY)
  ).current;
  const eventIdsRef = useRef(eventIds);

  eventIdsRef.current = eventIds;

  const { isDepthEnabled } = useMarketDepth(MarketDepthViews.SEARCH);

  const infiniteScrollCallback = useCallback(() => {
    if (totalElements !== content.length) {
      const loadedSports = Object.entries(contentByHeaders).map(([sportId, value]) => ({
        count: value.length,
        sportId: sportId.trim()
      }));
      const targetSport = loadedSports.find(
        ({ sportId, count }) => resultsBySport[sportId] !== count && !collapsedSports.sportIds.includes(sportId)
      );

      if (targetSport) {
        const index = contentSports.findIndex(sportId => sportId.trim() === targetSport.sportId);
        const prevSports = contentSports.slice(0, index);
        const prevSportsOffset = prevSports.reduce((sum, sportId) => {
          return sum + resultsBySport[sportId.trim()];
        }, 0);
        const offset = prevSportsOffset + targetSport.count;
        const isTargetElementNotLastDisplayed = loadedSports.at(-1)?.sportId !== targetSport.sportId;
        const targetSportResultsCount = resultsBySport[targetSport.sportId.trim()];
        const isNextSizeNotValid = targetSport.count + SEARCH_CONTENT_SIZE > targetSportResultsCount;
        const size =
          isTargetElementNotLastDisplayed && isNextSizeNotValid
            ? targetSportResultsCount - targetSport.count
            : undefined;

        if (offset < totalElements) {
          dispatch(fetchSearch({ query, offset, size }));
        }
      } else {
        const offset = Object.entries(resultsBySport)
          .filter(([sportId]) => contentSports.includes(' ' + sportId))
          .reduce((sum, [, count]) => sum + count, 0);

        if (offset < totalElements) {
          dispatch(fetchSearch({ query, offset }));
        }
      }
    }
  }, [totalElements, content.length, collapsedSports.sportIds]);

  const { lastElementRef } = useInfiniteScroll<HTMLDivElement>({ callback: infiniteScrollCallback });

  useEffect(() => {
    if (query && minSearchQueryLength && query.length >= minSearchQueryLength && !content.length) {
      debouncedSearch(query);

      searchParams.set(PARAMS_SEARCH_KEY, query);
      return setSearchParams(searchParams);
    }
  }, [query, loggedIn]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      dispatch(removeEventsUpdatedDataByEventIds(eventIdsRef.current));
    };
  }, []);

  return (
    <div className="biab_scrollable biab_search-container skeleton_page_wrapper">
      {eventUpdatesWsEnabled && <SearchContentEventUpdatesInjection />}
      {isSearchLoading && <MiddleSectionPageSkeleton itemsCount={12} withDelay={150} />}
      {!!content.length && (
        <div className="biab_scrollable-content">
          <div className="biab_search-results-container">
            <div className={classNames('biab_search-results searchResult', styles.searchResult)} ref={searchContentRef}>
              {content.map((item, index) => {
                const isCollapsed = getIsCollapsedSearchSport(collapsedSports, item.eventTypeId, location.pathname);
                const displayHeader = index === 0 || (index > 0 && item.eventTypeId !== content[index - 1].eventTypeId);

                const isLastSportHeader = displayHeader && item.eventTypeId === contentSports.at(-1)?.trim();
                const isSingleResultSportCollapse = resultSports.length === 1 && !!collapsedSports.sportIds.length;
                const isNextResultsBelongToLastDisplayedSport = contentSports.length === resultSports.length;
                const isSetLastHeaderRef =
                  isLastSportHeader &&
                  !isSingleResultSportCollapse &&
                  !isNextResultsBelongToLastDisplayedSport &&
                  isCollapsed;

                let isLast = false;
                const sportIdKey = ' ' + item.eventTypeId;
                const isNotFullyLoaded = contentByHeaders[sportIdKey].length !== resultsBySport[item.eventTypeId];

                if (isNotFullyLoaded) {
                  const resultIndex = contentSports.findIndex(sportId => sportId.trim() === item.eventTypeId);
                  const prevSports = contentSports.slice(0, resultIndex);
                  const offset = prevSports.reduce((sum, sportId) => {
                    return sum + contentByHeaders[sportId].length;
                  }, 0);

                  isLast = index === offset + contentByHeaders[sportIdKey].length - 1;
                }

                return (
                  <div
                    className={classNames('biab_search-result-wrapper', { biab_collapsed: isCollapsed })}
                    key={`${index}-${item.id}`}
                  >
                    <div className={classNames('biab_search-item', styles.searchResult__item)}>
                      {displayHeader && (
                        <SportHeader
                          isCollapsed={isCollapsed}
                          eventTypeId={item.eventTypeId}
                          sportName={item.sportName}
                          lastItemRef={
                            isNextResultsBelongToLastDisplayedSport && isCollapsed && isLastSportHeader
                              ? undefined
                              : isSetLastHeaderRef
                              ? lastElementRef
                              : undefined
                          }
                        />
                      )}
                      {!isCollapsed && (
                        <div ref={content.length - 1 === index || isLast ? lastElementRef : undefined}>
                          <SearchListItem item={item} isDepthEnabled={isDepthEnabled} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(SearchContent);

function SearchContentEventUpdatesInjection() {
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const eventUpdatesWsEnabled = useSelector(getEventUpdatesWsEnabled);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const intersectingEventIds = useSelector(getSearchIntersectingEventIds);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const [debouncedEventIds, setDebouncedEventIds] = useDebounceValue(intersectingEventIds, 300);

  const generalWsDataRef = useRef<{
    subscribeGeneralWebSocketMessages: (<F>(params: F) => void) | null;
    isEventUpdatesSubscriptionAvailable: boolean;
  }>({
    subscribeGeneralWebSocketMessages,
    isEventUpdatesSubscriptionAvailable: false
  });

  const isEventUpdatesSubscriptionAvailable =
    eventUpdatesWsEnabled &&
    generalWsEnabled &&
    arePropertiesLoaded &&
    !!debouncedEventIds.length &&
    isConnectedGeneralWebSocket &&
    !!subscribeGeneralWebSocketMessages;

  generalWsDataRef.current = {
    subscribeGeneralWebSocketMessages,
    isEventUpdatesSubscriptionAvailable
  };

  useEffect(() => {
    setDebouncedEventIds(intersectingEventIds);
  }, [intersectingEventIds]);

  useEffect(() => {
    if (isEventUpdatesSubscriptionAvailable) {
      subscribeGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.eventUpdates]: {
          subscribe: true,
          eventIds: debouncedEventIds
        }
      });
    }
  }, [isEventUpdatesSubscriptionAvailable, debouncedEventIds]);

  useEffect(() => {
    return () => {
      const {
        subscribeGeneralWebSocketMessages: subscribeFunc,
        isEventUpdatesSubscriptionAvailable: isEventUpdatesAvailableWS
      } = generalWsDataRef.current;

      if (subscribeFunc && isEventUpdatesAvailableWS) {
        subscribeFunc({
          [GeneralWebSocketSubscriptionTypes.eventUpdates]: { subscribe: false }
        });
      }
    };
  }, []);

  return null;
}

function getIsCollapsedSearchSport(collapsedSports: TCollapsedSports, eventTypeId: string, pathname: string) {
  return (
    collapsedSports.pathname === pathname &&
    collapsedSports.sportIds.indexOf(eventTypeId) !== -1 &&
    collapsedSports.sportIds.length !== 0
  );
}
