import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices } from 'constants/app';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { getPLTableCurrentPL, getPLTableWorstCasePL } from 'redux/modules/plTable/selectors';

import CurrentPL from '../CurrentPL';

import { getValueForTable } from './helpers';

import styles from './styles.module.scss';

interface GoalLineTableProps {
  currencyCode: string;
  units: string;
}

const GoalLineTable = ({ units, currencyCode }: GoalLineTableProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const currentPL = useSelector(getPLTableCurrentPL);
  const worstCasePL = useSelector(getPLTableWorstCasePL);

  const isMobile = device === Devices.MOBILE;
  const plValue = Object.values(currentPL).length ? currentPL : worstCasePL;
  const values = getValueForTable(plValue);

  if (isMobile) {
    return (
      <div className={classNames('biab_pl-table-wrapper', styles.mobileContainer)}>
        <table className={classNames('biab_pl-table', styles.mobileTable)}>
          <thead>
            <tr>
              {Object.values(values).map((item, index) => (
                <td key={`${index}-${item}`} className={classNames(styles.table__coll, styles.table__topCol)}>
                  {item}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.keys(values).map((item, index) => (
                <td key={`${index}-${item}`} className={classNames(styles.table__coll, styles.table__botCol)}>
                  <CurrentPL value={item} currencyCode={currencyCode} />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className={classNames('biab_pl-table-wrapper', styles.contentContainer)}>
      <table className={classNames('biab_pl-table', styles.table)}>
        <thead>
          <tr>
            <th className={styles.table__title}>{`${t('PLTable.numberOf')} ${t(units)}`}</th>
            {Object.values(values).map((item, index) => (
              <td key={`${index}-${item}`} className={classNames(styles.table__coll, styles.table__topCol)}>
                {item}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className={styles.table__title}>{t('PLTable.PL')}</th>
            {Object.keys(values).map((item, index) => (
              <td key={`${index}-${item}`} className={classNames(styles.table__coll, styles.table__botCol)}>
                <CurrentPL value={item} currencyCode={currencyCode} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GoalLineTable;
