import { useState } from 'react';
import classNames from 'classnames';

import MatchStatistics from 'components/MatchStatistics';
import VideoStream from 'components/VideoStream';
import { SportIds } from 'constants/app';
import { EventWidgetTab } from 'types';

import MatchStatisticsWidgetContent from './components/MatchStatisticsWidgetContent';
import VideoStreamingWidgetContent from './components/VideoStreamingWidgetContent';

import styles from './styles.module.scss';

interface MobileEventWidgetsProps {
  videoStreamingEnabled: boolean;
  matchStatEnabled: boolean;
  eventId: string;
  sportId: string;
}

const MobileEventWidgets = ({ videoStreamingEnabled, eventId, matchStatEnabled, sportId }: MobileEventWidgetsProps) => {
  const [openedWidgetTab, setOpenedWidgetTab] = useState<EventWidgetTab>(null);

  return (
    <div
      className={classNames('biab_video-streaming-wrapper', styles.eventWidgets__container, {
        [styles.eventWidgets__container__selectedWidget]: openedWidgetTab !== null
      })}
    >
      <div className={styles.eventWidgets}>
        {videoStreamingEnabled && (
          <div
            className={classNames('biab_event-widgets-tab', styles.eventWidgets__widget, {
              biab_selected: openedWidgetTab === 'video-stream',
              [styles.eventWidgets__widget__selected]: openedWidgetTab === 'video-stream'
            })}
          >
            <VideoStream
              visible={videoStreamingEnabled}
              isInHeader
              eventId={eventId}
              openedWidgetTab={openedWidgetTab}
              setOpenedWidgetTab={setOpenedWidgetTab}
            />
          </div>
        )}
        {matchStatEnabled && (
          <div
            className={classNames('biab_event-widgets-tab', styles.eventWidgets__widget, {
              biab_selected: openedWidgetTab === 'match-statistics',
              [styles.eventWidgets__widget__selected]: openedWidgetTab === 'match-statistics'
            })}
          >
            <MatchStatistics
              isSoccer={sportId === SportIds.SOCCER}
              visible={matchStatEnabled}
              isInHeader
              openedWidgetTab={openedWidgetTab}
              setOpenedWidgetTab={setOpenedWidgetTab}
              eventId={eventId}
            />
          </div>
        )}
      </div>
      {openedWidgetTab === 'video-stream' && <VideoStreamingWidgetContent />}
      {openedWidgetTab === 'match-statistics' && <MatchStatisticsWidgetContent eventId={eventId} />}
    </div>
  );
};

export default MobileEventWidgets;
