import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import CancelAllButton from 'components/Betslip/components/CancelAllButton';
import CashOut from 'components/CashOut';
import FavoriteStar from 'components/FavoriteStar';
import NetCommission from 'components/NetCommission';
import OpenBetsSetting from 'components/OpenBetsSetting';
import { CurrencySettingsPlacements, FAVORITES_TYPES } from 'constants/app';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { getMarketPricesCurrency, getMatchedAmountByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MobileMarketInfoRowViewOptions } from 'types/markets';
import { getCleanMarketId } from 'utils';

import styles from './styles.module.scss';

interface MobileInfoRowProps {
  /**
   * Sport id
   * @return string
   */
  sportId: string;
  /**
   * Market name
   * @return string
   */
  marketName: string;
  /**
   * Selected market ID
   * @return string
   */
  marketId: string;
  /**
   * Is cashOut enabled
   * @return boolean
   */
  isCashOutEnabled: boolean;
  /**
   * Will be invoked when user click to Rules button
   * @return function
   */
  onOpenRules: () => void;
  /**
   * Will be invoked when user click to Refresh button
   * @return function
   */
  onRefreshMarket: () => void;
  /**
   * View options
   */
  viewOptions?: MobileMarketInfoRowViewOptions;
  marketType?: string;
}

const MobileInfoRow = ({
  marketId,
  isCashOutEnabled,
  onOpenRules,
  onRefreshMarket,
  sportId,
  marketName,
  marketType,
  viewOptions = {
    noFavourite: false,
    refreshAfterRules: false,
    noBottom: false,
    noCashOut: false,
    noCancelAllBetsBtn: false
  }
}: MobileInfoRowProps) => {
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const totalMatched = useSelector(getMatchedAmountByMarketPricesId(marketId));
  const currency = useSelector(getMarketPricesCurrency(marketId));
  const isFavorite = useSelector(getFavoriteById(getCleanMarketId(marketId)));

  const { formattedAmount: matched } = useFormatCurrency(totalMatched || 0, currency, {
    isCheckIndian: true,
    placement: CurrencySettingsPlacements.MATCHED_AMOUNT
  });

  const isMobileCashOutEnabled = isLoggedIn && isCashOutEnabled && !viewOptions?.noCashOut;
  const favoriteData = {
    entryId: marketId,
    sportId: sportId,
    starred: !isFavorite,
    entryName: marketName,
    entryType: FAVORITES_TYPES.market
  };

  const refreshButton = (
    <button className={classNames('biab_market-refresh', styles.marketDetailInfo__refresh)} onClick={onRefreshMarket}>
      {t('market.refresh')}
    </button>
  );

  return (
    <div className={styles.marketDetailInfo__top}>
      {!viewOptions.noFavourite && (
        <div className={styles.eventHeader__favouriteIcon}>
          {marketType && <FavoriteStar entryData={favoriteData} />}
        </div>
      )}
      <button className={styles.marketDetailInfo__rules} onClick={onOpenRules}>
        <i className="fa2 fa2-info-circle tooltip-icon">
          <span className="path1 biab_promoted-tooltip-bg biab_mobile-tooltip-bg biab_tooltip-bg" />
          <span className="path2" />
        </i>
      </button>
      {viewOptions?.refreshAfterRules && refreshButton}
      <OpenBetsSetting />
      {isMobileCashOutEnabled && <CashOut />}
      {!viewOptions?.noCancelAllBetsBtn && <CancelAllButton marketId={marketId} />}
      <div className={styles.marketDetailInfo__rightCol}>
        {viewOptions?.noBottom ? (
          <NetCommission />
        ) : (
          <>
            {!viewOptions?.refreshAfterRules && refreshButton}
            <NetCommission />

            <div className={classNames('biab_market-tv', styles.marketDetailInfo__matched)}>
              {t(`${isPNCEnabled ? 'pnc.' : ''}market.matched`)}: <span>{matched}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MobileInfoRow;
