import { useCookies } from 'react-cookie';

import { CookieNames, SortingCookieValues } from 'constants/app';

interface Data {
  isSortedByEvents: boolean;
}

const useBetslipOpenBetsSorting = (): Data => {
  const [cookies] = useCookies([CookieNames.BETSLIP_FILTER_SORTING]);

  return {
    isSortedByEvents: cookies.SORTED_BETS === SortingCookieValues.BY_EVENT
  };
};

export default useBetslipOpenBetsSorting;
