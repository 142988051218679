import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { add, subtract } from 'lodash';

import StepperInput from 'components/StepperInput';
import { BetFocusFields } from 'constants/betslip';
import { mobileComponents } from 'constants/branding';
import { useStakeRegexPattern } from 'hooks/usePrecision';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import {
  getCurrency,
  getCurrencySteps,
  getMobileSettingsVirtualKeyboardBetslip
} from 'redux/modules/appConfigs/selectors';
import { BetFocusField } from 'redux/modules/betslip/type';
import { valueToNumber } from 'utils/betslip';
import { formatFloatNumber } from 'utils/betValidation';

import inputStyles from '../inputs.module.scss';

interface SizeInputProps {
  sizeValue: string | number;
  setSizeValue: (sizeValue: string | number) => void;
  setFocused: () => void;
  currentFocus: BetFocusField | null;
  isInvalid: boolean;
  validateSizeInput: () => void;
}

function SizeInput({
  sizeValue,
  setSizeValue,
  setFocused,
  currentFocus,
  isInvalid,
  validateSizeInput
}: SizeInputProps) {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const { t } = useTranslation();

  const virtualKeyboardBetslip = useSelector(getMobileSettingsVirtualKeyboardBetslip);
  const currencySteps = useSelector(getCurrencySteps);
  const defaultCurrency = useSelector(getCurrency);

  const stakeRegexPattern = useStakeRegexPattern();

  const isPlusBtnEnabled = +(sizeValue || 0) < defaultCurrency.maxBetSize;
  const isMinusBtnEnabled = +(sizeValue || 0) > defaultCurrency.minBetSize;
  const isFocused = currentFocus === BetFocusFields.SIZE;

  useUpdateEffect(() => {
    if (!isTouched) {
      setIsTouched(true);
    }
  }, [sizeValue]);

  const { minusStep, plusStep } = useMemo(() => {
    let minusSizeStep = 0,
      plusSizeStep = 0;

    currencySteps.forEach(curStep => {
      if (+sizeValue >= curStep.min && (curStep.max == null || +sizeValue < curStep.max)) {
        plusSizeStep = defaultCurrency.step > curStep.step ? defaultCurrency.step : curStep.step;
      }
      if (+sizeValue > curStep.min && (curStep.max == null || +sizeValue <= curStep.max)) {
        minusSizeStep = defaultCurrency.step > curStep.step ? defaultCurrency.step : curStep.step;
      }
    });

    return { minusStep: minusSizeStep, plusStep: plusSizeStep };
  }, [currencySteps, sizeValue, defaultCurrency.step]);

  const onBlur = () => {
    if (isTouched && sizeValue !== '') {
      validateSizeInput();
    }
  };

  const onSizeChange = (value: string) => {
    const changedValue = valueToNumber(value);

    if (changedValue === '' || stakeRegexPattern.test(changedValue)) {
      setSizeValue(changedValue);
    }
  };

  const onFocus = () => {
    if (!isFocused) {
      setFocused();
    }
  };

  const onAdd = () => {
    if (isPlusBtnEnabled && plusStep) {
      if (!sizeValue) {
        setSizeValue(defaultCurrency.minBetSize);
      } else {
        const newSizeValue = formatFloatNumber(add(parseFloat(sizeValue.toString()), plusStep));

        setSizeValue(newSizeValue);
      }
    }
  };

  const onSubtract = () => {
    if (isMinusBtnEnabled && minusStep) {
      const newSizeValue = formatFloatNumber(subtract(parseFloat(sizeValue.toString()), minusStep));

      setSizeValue(newSizeValue);
    }
  };

  return (
    <StepperInput
      value={sizeValue}
      label={t('betslip.labels.size.back')}
      id="mobile-input-size"
      name="size"
      onFocus={onFocus}
      onDecrease={onSubtract}
      onIncrease={onAdd}
      onInputChange={onSizeChange}
      setFocused={setFocused}
      onBlur={onBlur}
      className={inputStyles.container}
      commonClassName={classNames(mobileComponents.INPUT, {
        [mobileComponents.FOCUS]: isFocused,
        [mobileComponents.ERROR]: isInvalid
      })}
      isInputReadOnly={virtualKeyboardBetslip}
      isDecreaseBtnDisabled={!isMinusBtnEnabled}
      isIncreaseBtnDisabled={!isPlusBtnEnabled}
      isInvalid={isInvalid}
      isFocused={isFocused}
      autoFocus
    />
  );
}

export default SizeInput;
