import { TOddsTypes } from 'types';

import { OddsTypes } from './app';

export const oddsTypeSettingsName = 'asianViewOddsType';

export const oddsTypes: TOddsTypes = {
  [OddsTypes.DEC]: 'DEC (Decimal)',
  [OddsTypes.MY]: 'MY (Malay)',
  [OddsTypes.HK]: 'HK (Hong Kong)',
  [OddsTypes.IND]: 'IND (Indonesian)'
};
