import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { KEY_CODES } from 'constants/betslip';
import { educationBranding } from 'constants/branding';
import { SEARCH_INPUT_MAX_LENGTH } from 'constants/search';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import useDevice from 'hooks/useDevice';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getMinSearchQueryLength } from 'redux/modules/appConfigs/selectors';
import { resetSearchArticlesResults, setIsSearchActive, setSearchLoading } from 'redux/modules/educationalPortal';
import { getIsSearchActive } from 'redux/modules/educationalPortal/selectors';

import SearchResults from '../SearchResults/SearchResults';

import styles from './styles.module.scss';

const SearchBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { isDesktop, isMobile } = useDevice();

  const isSearchActive = useSelector(getIsSearchActive);
  const minSearchQueryLength = useSelector(getMinSearchQueryLength);

  const [desktopSearch, setDesktopSearch] = useState('');

  const wrapperRef = useRef<HTMLDivElement>(null);
  const firstRender = useRef(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const query = searchParams.get(PARAMS_SEARCH_KEY) || '';
  const placeholder = t('education.labels.search.placeholder');

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      handleClose(false);
    }

    return () => {
      dispatch(setIsSearchActive(false));
    };
  }, [location.pathname]);

  useEffect(() => {
    if (query) {
      dispatch(setIsSearchActive(true));
    } else if (isMobile) {
      inputRef.current?.focus();
      handleOpen();
    }

    return () => {
      dispatch(setIsSearchActive(false));
    };
  }, []);

  const handleClose = (resetSearchParams = true) => {
    dispatch(setIsSearchActive(false));
    const searchValue = searchParams.get(PARAMS_SEARCH_KEY);
    if (resetSearchParams && isMobile && searchValue !== null) {
      searchParams.delete(PARAMS_SEARCH_KEY);
      setSearchParams(searchParams);
    }
    dispatch(resetSearchArticlesResults());

    if (desktopSearch) {
      setDesktopSearch('');
    }
  };

  const handleOpen = () => {
    if (!isSearchActive && inputRef.current) {
      dispatch(setIsSearchActive(true));
      inputRef.current.focus();
    }
  };

  const handleChange = (value: string) => {
    if (isDesktop) {
      setDesktopSearch(value);
      dispatch(resetSearchArticlesResults());
    } else {
      if (value === '') {
        searchParams.delete(PARAMS_SEARCH_KEY);
      } else {
        searchParams.set(PARAMS_SEARCH_KEY, value);
      }

      if (window.scrollY !== 0) {
        scrollTo({ top: 0 });
      }

      setSearchParams(searchParams);
    }

    if (value.length >= minSearchQueryLength) {
      dispatch(setSearchLoading(true));
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === KEY_CODES.ESCAPE) {
      handleClose();
      inputRef?.current?.blur();
    }
  };

  useOnClickOutside(wrapperRef, () => {
    if (isDesktop) {
      handleClose();
    }
  });

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles.search__wrapper, { [styles.search__wrapper__mobile]: isMobile })}
    >
      <div
        onClick={handleOpen}
        className={classNames(styles.search, educationBranding.SEARCH_BOX, {
          [styles.search__mobile]: isMobile
        })}
      >
        <i
          className={classNames('fa2 fa2-search', styles.searchIcon, {
            [styles.searchIcon__mobile]: isMobile
          })}
        />
        <input
          value={isDesktop ? desktopSearch : query}
          onChange={e => handleChange(e.target.value)}
          ref={inputRef}
          type="text"
          autoComplete="off"
          maxLength={SEARCH_INPUT_MAX_LENGTH}
          placeholder={placeholder}
          className={classNames(educationBranding.SEARCH_BOX_INPUT, styles.search__input, {
            [styles.search__input__mobile]: isMobile
          })}
          onKeyDown={handleKeyDown}
        />
        {(isSearchActive || isMobile) && (
          <button
            className={classNames(styles.closeBtn, { [styles.closeBtn__mobile]: isMobile })}
            onClick={() => handleClose()}
          >
            <i
              className={classNames('biab_tour-icon-close', styles.closeBtn__icon, {
                [styles.closeBtn__icon__mobile]: isMobile
              })}
            />
          </button>
        )}
      </div>
      {isDesktop && isSearchActive && (
        <SearchResults searchValue={desktopSearch} onSelectItem={() => setDesktopSearch('')} />
      )}
    </div>
  );
};

export default SearchBar;
