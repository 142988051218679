import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { InlinePlacementActions, InlinePlacementButtonActionTypes } from 'constants/inlinePlacement';
import useConfirmBets from 'hooks/useConfirmBets';
import { useInlineSelectedBetView, useIsDisabledInlineSelectedBet } from 'hooks/useInlinePlacement';
import { updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getBetIdentityData, getInlinePlacedOfferStatuses } from 'utils/inlinePlacement';

import InlineBetButton from '../InlineBetButton';

import styles from './styles.module.scss';

type TInlineBetActionsProps = {
  bet: TInlineSelectedBet;
};
const InlineBetActions = ({ bet }: TInlineBetActionsProps) => {
  const dispatch = useDispatch();

  const { isConfirmBetsBeforePlacement } = useConfirmBets();
  const { isShortView } = useInlineSelectedBetView({ sportId: bet.sportId, pageBlock: bet.pageBlock });
  const isDisabled = useIsDisabledInlineSelectedBet(bet);

  const isSelectState = bet.action === InlinePlacementActions.SELECT;
  const isConfirmState = bet.action === InlinePlacementActions.CONFIRM;
  const isPlacedState = bet.action === InlinePlacementActions.PLACED;
  const isEditState = bet.action === InlinePlacementActions.EDIT;

  const { isUnmatched } = getInlinePlacedOfferStatuses(bet.placedBet);

  const isPlacedUnmatched = isPlacedState && isUnmatched;

  const betIdData = getBetIdentityData(bet);

  const onPlaceBtnClick = () => {
    dispatch(
      updateInlineSelectedBet({
        ...betIdData,
        action: isConfirmBetsBeforePlacement
          ? InlinePlacementActions.CONFIRM
          : isPlacedUnmatched
          ? InlinePlacementActions.UPDATE
          : InlinePlacementActions.PLACE
      })
    );
  };

  const onConfirmBtnClick = () => {
    dispatch(
      updateInlineSelectedBet({
        ...betIdData,
        action: isUnmatched ? InlinePlacementActions.UPDATE : InlinePlacementActions.PLACE
      })
    );
  };

  const onEditBtnClick = () => {
    dispatch(
      updateInlineSelectedBet({
        ...betIdData,
        action: isUnmatched ? InlinePlacementActions.EDIT : InlinePlacementActions.SELECT
      })
    );
  };

  const onTakeOfferBtnClick = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, action: InlinePlacementActions.TAKE }));
  };

  const onEditUnmatchedBtnClick = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, action: InlinePlacementActions.EDIT }));
  };

  const onCancelUnmatchedBtnClick = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, action: InlinePlacementActions.CANCEL }));
  };

  const onUpdateUnmatchedBtnClick = () => {
    dispatch(
      updateInlineSelectedBet({
        ...betIdData,
        action: isConfirmBetsBeforePlacement ? InlinePlacementActions.CONFIRM : InlinePlacementActions.UPDATE
      })
    );
  };

  return (
    <div className={classNames(styles.wrap, { [styles.wrap__shortView]: isShortView })}>
      {isSelectState && (
        <InlineBetButton
          bet={bet}
          buttonType={InlinePlacementButtonActionTypes.PLACE}
          onClickCallback={onPlaceBtnClick}
        />
      )}
      {isConfirmState && (
        <>
          <InlineBetButton
            bet={bet}
            buttonType={InlinePlacementButtonActionTypes.CONFIRM}
            onClickCallback={onConfirmBtnClick}
          />
          {!isDisabled && !isShortView && (
            <InlineBetButton
              bet={bet}
              buttonType={InlinePlacementButtonActionTypes.EDIT}
              onClickCallback={onEditBtnClick}
            />
          )}
        </>
      )}
      {isEditState && (
        <InlineBetButton
          bet={bet}
          buttonType={InlinePlacementButtonActionTypes.UPDATE}
          onClickCallback={onUpdateUnmatchedBtnClick}
        />
      )}
      {isPlacedUnmatched && (
        <>
          <InlineBetButton
            bet={bet}
            buttonType={InlinePlacementButtonActionTypes.CANCEL_UNMATCHED}
            onClickCallback={onCancelUnmatchedBtnClick}
          />
          <InlineBetButton
            bet={bet}
            buttonType={InlinePlacementButtonActionTypes.TAKE_OFFER}
            onClickCallback={onTakeOfferBtnClick}
          />
          <InlineBetButton
            bet={bet}
            buttonType={InlinePlacementButtonActionTypes.EDIT_UNMATCHED}
            onClickCallback={onEditUnmatchedBtnClick}
          />
        </>
      )}
    </div>
  );
};

export default InlineBetActions;
