import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { CookieNames, OddsTypes } from 'constants/app';
import { asianViewDropdown } from 'constants/branding';
import { oddsTypeSettingsName } from 'constants/oddsTypes';
import { setOddsType } from 'redux/modules/appSettings';
import { getOddsType } from 'redux/modules/appSettings/selectors';
import { setSettings } from 'redux/modules/user';
import { getAccountSettings, getIsUserAccountSettings } from 'redux/modules/user/selectors';
import { DropdownItem, OddsType } from 'types';
import { convertOddsTypeToDropdown } from 'utils';

import styles from './styles.module.scss';

const OddsTypeDropdown = () => {
  const { t } = useTranslation();
  const convertedData = convertOddsTypeToDropdown(t);
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([CookieNames.ODDS_TYPE]);

  const currentOddsType = useSelector(getOddsType);
  const accountSettings = useSelector(getAccountSettings);
  const isAccountSettings = useSelector(getIsUserAccountSettings);

  let selectedItem = convertedData.find(item => item.value === `${currentOddsType}`);
  const currentItem = selectedItem || convertedData[0];
  selectedItem = { ...currentItem, label: t(`asianView.labels.oddsType.short.${currentItem.value}`) };

  useEffect(() => {
    dispatch(setOddsType(accountSettings?.[oddsTypeSettingsName] || cookies[CookieNames.ODDS_TYPE] || OddsTypes.DEC));
  }, [accountSettings]);

  const onSelectOddsType = (oddsType: DropdownItem) => {
    dispatch(setOddsType(oddsType.value as OddsType));
    if (isAccountSettings) {
      dispatch(
        setSettings({
          settings: {
            [oddsTypeSettingsName]: oddsType.value
          }
        })
      );
    } else {
      setCookie(CookieNames.ODDS_TYPE, oddsType.value, {
        path: '/'
      });
    }
  };

  const classes = {
    dropdown: styles.dropdown,
    placeholder: styles.placeholder,
    activePlaceholder: styles.activePlaceholder,
    option: styles.option,
    selectedOption: styles.selectedOption
  };

  return (
    <div className={classNames('biab_odds-type-dropdown', styles.oddsType)}>
      <Dropdown
        classes={classes}
        brandingClasses={asianViewDropdown}
        data={convertedData}
        defaultValue={selectedItem}
        onSelectOption={onSelectOddsType}
      />
    </div>
  );
};

export default OddsTypeDropdown;
