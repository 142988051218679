import { createSelector } from '@reduxjs/toolkit';

import { AsianPopularLinkEntryTypes } from 'constants/asianView';
import { AppState } from 'redux/reducers';

export const getAsianViewPopularLinks = (state: AppState) => state.asianViewPopularLinks.asianViewPopularLinks;
export const getIsOpenedAsianViewCustomPopUpLink = (state: AppState) =>
  state.asianViewPopularLinks.isOpenedCustomPopUpLink;
export const getAsianViewCustomPopUpPopularLink = createSelector(getAsianViewPopularLinks, links =>
  links.find(({ entryType }) => entryType === AsianPopularLinkEntryTypes.CUSTOM_POP_UP)
);
export const getIsAsianViewPopularLinksLoaded = (state: AppState) =>
  state.asianViewPopularLinks.asianViewPopularLinksLoaded;
