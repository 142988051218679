import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import { ResponsibleBettingReportInitialState, ResponsibleBettingReportTypeKeys } from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingExportDataFieldProps {
  label: string;
  period: string;
  type: ResponsibleBettingReportTypeKeys;
  isMobile: boolean;
  exportFieldState: ResponsibleBettingReportInitialState;
  onExport: () => void;
  onClose: () => void;
}

const ResponsibleBettingExportDataField = ({
  label,
  period,
  isMobile,
  exportFieldState,
  onExport,
  onClose
}: ResponsibleBettingExportDataFieldProps) => {
  const { t } = useTranslation();
  const { loading, disabled, error } = exportFieldState;

  return (
    <div
      className={classNames(styles.container, {
        [styles.container__with_error]: error,
        [styles.container__active]: !loading && !disabled
      })}
    >
      <div
        className={styles.field}
        onClick={() => {
          if (!disabled) {
            onExport();
          }
        }}
      >
        <div
          className={classNames(styles.fieldInfo, {
            [styles.fieldInfo__mobile]: isMobile
          })}
        >
          <span className={styles.fieldInfoLabel}>{t(label)}</span>
          <span className={styles.fieldInfoDescription}>{t(period)}</span>
        </div>
        {loading ? (
          <div className={styles.loaderWrapper}>
            <i className={classNames(styles.loaderIcon, 'fa2 fa2-loading')} />
          </div>
        ) : (
          <button
            className={classNames(styles.iconWrapper, styles.downloadIconBtn, {
              [styles.iconWrapper__active]: !loading && !disabled
            })}
            disabled={disabled}
          >
            <i className={classNames(styles.downloadIcon, 'biab_custom-icon-download')} />
          </button>
        )}
      </div>
      {error && (
        <>
          <div className={classNames(commonStyles.divider, styles.divider)} />
          <div className={styles.field}>
            <div
              className={classNames(styles.fieldInfo, {
                [styles.fieldInfo__mobile]: isMobile
              })}
            >
              <div className={styles.warningWrapper}>
                <i className={classNames(styles.warningIcon, 'fa2 fa2-warning_icon biab_fav-manage-icon')} />
                <span className={styles.warningLabel}>{t('responsibleBetting.messages.fileExportFailed')}</span>
              </div>
            </div>
            <button className={styles.iconWrapper} onClick={onClose}>
              <i className={classNames(styles.icon, 'biab_custom-icon-cross')} />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ResponsibleBettingExportDataField;
