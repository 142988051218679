import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { CashOutTabs } from 'constants/app';
import useDevice from 'hooks/useDevice';
import { getAutoCashOutEnabled } from 'redux/modules/appConfigs/selectors';
import { CashOutQuote } from 'redux/modules/cashOut/type';
import { CashOutTab } from 'types';

import CashOutState from '../CashOutState';
import PartialTab from '../PartialTab';

import styles from './styles.module.scss';

type CashOutSettingsContentProps = {
  isAsianBetSlip: boolean;
  partialCashOutEnabled: boolean;
  marketId: string;
  isEvent: boolean;
  partial?: number;
  isDisabled: boolean;
  onSetPartial: (updatedPartial?: number) => void;
  currentCashOut: CashOutQuote;
  onHover: () => void;
  onLeave: () => void;
  isAVMobileCashOutPage?: boolean;
  isAsianView?: boolean;
  tabsContainerClassName?: string;
  isAVMobileBetList?: boolean;
  ignoreFlexibleDisplayFormat?: boolean;
};

const CashOutSettingsContent = ({
  isAsianBetSlip,
  partialCashOutEnabled,
  isEvent,
  marketId,
  onSetPartial,
  partial,
  currentCashOut,
  isDisabled,
  onHover,
  onLeave,
  isAVMobileCashOutPage = false,
  isAsianView = false,
  tabsContainerClassName = '',
  isAVMobileBetList = false,
  ignoreFlexibleDisplayFormat = false
}: CashOutSettingsContentProps) => {
  const { t } = useTranslation();

  const autoCashOutEnabled = useSelector(getAutoCashOutEnabled);

  const { isMobile } = useDevice();

  const [selectedTab, setSelectedTab] = useState<CashOutTab>(CashOutTabs.auto);

  return (
    <>
      <div
        className={classNames(
          'biab_cash-out-button-settings-content',
          styles.tabsContainer,
          {
            [styles.cashOutBetslip__tabs]: isAsianBetSlip,
            [styles.coMobPage__container]: isAVMobileCashOutPage,
            [styles.tabsContainer__mobile]: isMobile
          },
          tabsContainerClassName
        )}
      >
        <div
          className={classNames('biab_cashout-tabs-container', {
            [styles.tabsContainer__single]: !partialCashOutEnabled,
            [styles.cashOutBetslip__tabsWrapper]: isAsianBetSlip
          })}
        >
          {autoCashOutEnabled && (
            <button
              data-tab="auto"
              onClick={() => setSelectedTab(CashOutTabs.auto)}
              className={classNames('biab_partial-cashout-tab', styles.tabsContainer__tab, {
                biab_selected: CashOutTabs.auto === selectedTab || !partialCashOutEnabled,
                [styles.tabsContainer__tab__active]: CashOutTabs.auto === selectedTab,
                [styles.cashOutBetslip__tabsContainer__tab]: isAsianBetSlip,
                [styles.coMobPage__tab]: isAVMobileCashOutPage || isAVMobileBetList,
                [styles.coMobPage__tab__active]:
                  (isAVMobileCashOutPage || isAVMobileBetList) && CashOutTabs.auto === selectedTab,
                [styles.tabsContainer__tab__mobile]: isMobile && !isAsianView,
                [styles.tabsContainer__tab__active__avMobile]:
                  isAsianView && isMobile && CashOutTabs.auto === selectedTab
              })}
            >
              {t('cashout.page.labels.autoCashOut')}
            </button>
          )}
          {partialCashOutEnabled && (
            <button
              data-tab="partial"
              onClick={() => setSelectedTab(CashOutTabs.partial)}
              className={classNames('biab_partial-cashout-tab', styles.tabsContainer__tab, {
                biab_selected: CashOutTabs.partial === selectedTab,
                [styles.tabsContainer__tab__active]: CashOutTabs.partial === selectedTab,
                [styles.cashOutBetslip__tabsContainer__tab]: isAsianBetSlip,
                [styles.coMobPage__tab]: isAVMobileCashOutPage || isAVMobileBetList,
                [styles.coMobPage__tab__active]:
                  (isAVMobileCashOutPage || isAVMobileBetList) && CashOutTabs.partial === selectedTab,
                [styles.tabsContainer__tab__mobile]: isMobile && !isAsianView,
                [styles.tabsContainer__tab__active__avMobile]:
                  isAsianView && isMobile && CashOutTabs.partial === selectedTab
              })}
            >
              {t('cashout.page.labels.partialCashOut')}
            </button>
          )}
        </div>
      </div>

      <div
        className={classNames('biab_cash-out-tab-content', styles.tabContent, {
          [styles.betslip]: isAsianBetSlip
        })}
      >
        {CashOutTabs.auto === selectedTab && autoCashOutEnabled && (
          <CashOutState
            marketId={marketId}
            isAsianBetSlip={isAsianBetSlip}
            isEvent={isEvent}
            isAVMobileCashOutPage={isAVMobileCashOutPage}
            isAsianView={isAsianView}
            isAVMobileBetList={isAVMobileBetList}
            ignoreFlexibleDisplayFormat={ignoreFlexibleDisplayFormat}
          />
        )}
        {(CashOutTabs.partial === selectedTab || !autoCashOutEnabled) && (
          <PartialTab
            partial={partial}
            isDisabled={isDisabled}
            setPartial={onSetPartial}
            minPercentage={currentCashOut.minPartialPercentage}
            maxPercentage={currentCashOut.maxPartialPercentage}
            onHover={onHover}
            onLeave={onLeave}
            isAsianBetSlip={isAsianBetSlip}
            isAVMobileCashOutPage={isAVMobileCashOutPage}
            isAVMobileBetList={isAVMobileBetList}
            isAsianView={isAsianView}
            isEvent={isEvent}
            isMobile={isMobile}
          />
        )}
      </div>
    </>
  );
};

export default CashOutSettingsContent;
