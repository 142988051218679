import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import MarketsTable from 'components/MarketsTable';
import SEO from 'components/SEO';
import { Devices, MetaPages, PageBlocks, PlacementPages, ViewsBy } from 'constants/app';
import { MarketsTableColumns, MarketsTableHeaderTypes } from 'constants/markets';
import { timeSections } from 'constants/sportPage';
import withCollapse from 'hoc/withCollapse';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { fetchInPlayMarketRules } from 'redux/modules/inPlay';
import { getFirstInPlayMarketId, getInPlayMarketsRules, getRulesLoading } from 'redux/modules/inPlay/selectors';
import { ViewBy } from 'types';
import { IMarket, TMarketSportInfo } from 'types/markets';

import styles from './styles.module.scss';

interface InPlaySportSectionProps {
  /**
   * List of markets
   */
  markets: IMarket[];

  /**
   * The currently selected sort
   */
  viewBy: ViewBy;
}

const competitionViewMarkets = (title: string, markets: IMarket[], viewBy: ViewBy, isMobile: boolean) => {
  if (viewBy === ViewsBy.COMPETITION || isMobile) {
    return markets.filter(({ competition }) => competition?.name === title);
  }

  return [];
};

const InPlaySportSection = ({ markets, viewBy }: InPlaySportSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rulesLoading = useSelector(getRulesLoading);
  const marketsRules = useSelector(getInPlayMarketsRules);
  const device = useSelector(getAppDevice);
  const firstMarketId = useSelector(getFirstInPlayMarketId);

  const stringifiedMarkets = JSON.stringify(markets);
  const tablesContainerRef = useRef<HTMLDivElement>(null);

  const tables = useMemo(() => {
    return viewBy === ViewsBy.COMPETITION || device === Devices.MOBILE
      ? Array.from(new Set(markets.map(({ competition }) => competition?.name)))
      : [t('inplay.labels.inPlay'), t('inplay.labels.comingUp')];
  }, [stringifiedMarkets, viewBy, device]);

  const handleFetchMarketRules = (marketId: string) => {
    dispatch(fetchInPlayMarketRules(marketId));
  };

  const mobileHiddenHeaderColumns =
    device === Devices.MOBILE
      ? [MarketsTableColumns.MATCHED, MarketsTableColumns.BET_CONTENT, MarketsTableColumns.RULES]
      : [];

  const betGroupsCount = useMemo(() => {
    return markets.some(({ runners }) => runners.length === 3) ? 3 : 2;
  }, [stringifiedMarkets]);

  const marketsTableData: IMarket[][] = useMemo(() => {
    const inPlayMarkets: IMarket[] = [];
    const comingUpMarkets: IMarket[] = [];
    markets.forEach(m => {
      if (m.inPlay) {
        inPlayMarkets.push(m);
      } else {
        comingUpMarkets.push(m);
      }
    });
    return [inPlayMarkets, comingUpMarkets];
  }, [stringifiedMarkets]);

  return (
    <>
      <SEO page={MetaPages.IN_PLAY} />
      <div ref={tablesContainerRef}>
        {tables.map((title, index) => {
          const tableMarkets: IMarket[] =
            viewBy === ViewsBy.COMPETITION || device === Devices.MOBILE
              ? competitionViewMarkets(title, markets, viewBy, device === Devices.MOBILE)
              : marketsTableData[index];

          const sportInfo: TMarketSportInfo = {
            id: tableMarkets?.[0]?.eventType?.id ?? '',
            name: tableMarkets?.[0]?.eventType?.name ?? ''
          };

          return (
            <MarketsTable
              key={`${title}${index}`}
              headerTitle={title}
              timeSection={timeSections[index]}
              markets={tableMarkets}
              onFetchMarketRules={handleFetchMarketRules}
              marketsRules={marketsRules}
              betGroupsCount={betGroupsCount}
              rulesLoading={rulesLoading}
              sportInfo={sportInfo}
              pageBlock={PageBlocks.IN_PLAY}
              hiddenColumns={[MarketsTableColumns.MOBILE_SPORT_ICON, MarketsTableColumns.DATE_AND_MATCHED]}
              hiddenHeaderColumns={mobileHiddenHeaderColumns}
              showHeaderForMobile
              classes={{
                header: classNames({
                  [styles.tableHeader]: device === Devices.MOBILE,
                  'biab_group-markets-league-title': device === Devices.MOBILE
                })
              }}
              containerRef={tablesContainerRef}
              headerType={
                viewBy === ViewsBy.COMPETITION ? MarketsTableHeaderTypes.COMPETITION : MarketsTableHeaderTypes.DEFAULT
              }
              page={PlacementPages.IN_PLAY}
              firstMarketId={firstMarketId}
            />
          );
        })}
      </div>
    </>
  );
};

export default withCollapse(InPlaySportSection);
