import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import SearchBar from 'components/Edu/SearchBar';
import HeaderAccountNavigation from 'components/HeaderAccountNavigation';
import SubHeaderNavigationItem from 'components/HeaderNavigation/components/SubHeaderNavigationItem';
import Icon from 'components/Icon';
import { ASIAN_BASE_URL, EDUCATIONAL_PORTAL_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import { educationalPortalSubHeaderNavigationItems } from 'constants/navigation';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';

import styles from './styles.module.scss';

const EducationalPortalSubheader = () => {
  const { pathname } = useLocation();
  const isSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);

  const isGamesPage = pathname.includes(GAMES_BASE_URL) || (!isSportsEnabled && !isAsianViewEnabled);
  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL) || (!isSportsEnabled && !isGamesEnabled);
  const homeUrl = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || HOME_BASE_URL;

  return (
    <nav className={classNames(styles.container, 'biab_header-submenu', 'biab_navigation-links')}>
      <div className={styles.navigationContainer}>
        <Link to={homeUrl} className={classNames(styles.iconContainer, styles.iconLink, 'biab_navigation-link')}>
          <Icon fontFamily="fa2" iconClass="fa2-home-icon biab_fav-manage-icon" className={styles.icon} />
        </Link>
        {educationalPortalSubHeaderNavigationItems.map(item => (
          <SubHeaderNavigationItem
            item={item}
            key={item.title}
            getIsActive={() =>
              item.link === EDUCATIONAL_PORTAL_BASE_URL
                ? location.pathname.endsWith(EDUCATIONAL_PORTAL_BASE_URL)
                : location.pathname.includes(item.link)
            }
          />
        ))}
      </div>
      <SearchBar />
      <HeaderAccountNavigation hideSettings />
    </nav>
  );
};

export default EducationalPortalSubheader;
