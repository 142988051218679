export const TYPE_TO_URL: { [key: string]: string } = {
  EVENT_TYPE: 'sport',
  COUNTRY: 'country',
  COMPETITION: 'competition',
  EVENT: 'event',
  GROUP: 'group',
  MARKET: 'market',
  RACE: 'race',
  FANCY_MARKET: 'fancyEvent'
};

export const TimeFilters = {
  ALL: 'ALL',
  FUTURE: 'FUTURE',
  IN_PLAY: 'IN_PLAY',
  TOMORROW: 'TOMORROW',
  TODAY: 'TODAY'
} as const;

export const NavigationTypes = {
  COMPETITION: 'COMPETITION',
  EVENT: 'EVENT',
  COUNTRY: 'COUNTRY',
  RACE: 'RACE'
} as const;
