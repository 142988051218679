import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { uniqueId } from 'lodash';

import { PLBetTypes, plDefaultSportTypeValues, PLExchangeTypes, SEARCH_CURRENCY_KEY } from 'constants/myBets';
import useActivePLProduct from 'hooks/useActivePLProduct';
import MobileSelect from 'pages/MyBetsPages/components/MobileAccountFilters/components/MobileSelect';
import { getCurrency } from 'redux/modules/appConfigs/selectors';
import { fetchProfitGames, fetchProfitSports, setMobileFilters } from 'redux/modules/myBets';
import { getBetsTypeList, getEventTypeFilter, getMobileFiltersChanged } from 'redux/modules/myBets/selectors';
import { Game, Sport } from 'redux/modules/myBets/type';
import { DropdownItem } from 'types';
import { PLBetType, TMobileSelectOption } from 'types/myBets';

const MobileFiltersEventTypes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activePLTab = useActivePLProduct();
  const { currency, eventTypeId, product } = useSelector(getMobileFiltersChanged);
  const currentProduct = (product as PLBetType) || activePLTab;
  const betsTypeList = useSelector(getBetsTypeList(currentProduct));
  const eventType = useSelector(getEventTypeFilter);
  const currentCurrency = useSelector(getCurrency);
  const [searchParams] = useSearchParams();

  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);
  const currencyCode = currency || searchCurrency || currentCurrency.currencyCode;

  const isAsianView = currentProduct === PLExchangeTypes[PLBetTypes.AsianView];
  const isSports = currentProduct === PLExchangeTypes[PLBetTypes.Sports];
  const allItem = isSports ? plDefaultSportTypeValues.sports : plDefaultSportTypeValues.games;

  useEffect(() => {
    if (!isAsianView) {
      if (isSports) {
        dispatch(fetchProfitSports(currencyCode));
      } else if (currentProduct === PLExchangeTypes[PLBetTypes.Games]) {
        dispatch(fetchProfitGames(currencyCode));
      }
    }
  }, [currentProduct]);

  const items = (betsTypeList.map(betType => {
    return {
      label: betType.name,
      id: isSports ? (betType as Sport).id : (betType as Game).channelId,
      value: isSports ? (betType as Sport).id : (betType as Game).channelId
    };
  }) || []) as DropdownItem[];

  const currentEventTypeId = (eventType?.id || '').toString();
  const title = t(`account.profitandloss.labels.${isSports ? 'sports' : 'games'}`);

  const eventTypesOptions: TMobileSelectOption[] = [
    { id: '', value: '', label: t(allItem.translationKey), isLabelTranslatable: true } as DropdownItem
  ]
    .concat(items)
    .map(option => {
      const { id, label, value } = option;
      return {
        id: id.toString(),
        label: label ?? '',
        isSelected: value.toString() === (eventTypeId ?? currentEventTypeId),
        defaultIsSelected: value.toString() == currentEventTypeId
      };
    });

  const changeHandler = (eventTypesChanged: TMobileSelectOption[], isEventTypeIdChanged: boolean) => {
    dispatch(
      setMobileFilters({
        eventTypeId: eventTypesChanged.find(({ isSelected }) => isSelected)?.id ?? '',
        isEventTypeIdChanged
      })
    );
  };

  return isAsianView ? null : (
    <MobileSelect key={uniqueId()} title={title} defaultOptions={eventTypesOptions} onChangeCallback={changeHandler} />
  );
};

export default MobileFiltersEventTypes;
