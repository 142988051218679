import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { EventViewTypes, SportIds, TODAY_CARD_PREFIX } from 'constants/app';
import { NavigationIds } from 'constants/navigation';
import { getEventViewSettings, getIsCompetitionViewEnabled } from 'redux/modules/appConfigs/selectors';
import { getEventData } from 'redux/modules/competitions/selectors';

import useDeviceSettings from './useDeviceSettings';

export const useEnabledView = (isSportPage = false) => {
  const params = useParams();

  const { listViewSports, competitionViewSports, fancyMarketsOnCricket } = useDeviceSettings();

  const eventData = useSelector(getEventData);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const eventViewSettings = useSelector(getEventViewSettings);

  const sportId = params.sportId && params.sportId.toString().replace(TODAY_CARD_PREFIX, '');
  const eventSettings = eventViewSettings.find(event => event.eventTypeId === sportId);
  const isDefault = eventSettings?.viewType === EventViewTypes.DEFAULT;
  const isCompetitionView = sportId && isCompetitionViewEnabled && competitionViewSports.includes(sportId);
  const isHorseRacingView = sportId === SportIds.HORSE_RACING || sportId === SportIds.GRAY_HOUND_RACING;
  const isListView = sportId && listViewSports.includes(sportId);
  const isFancyView = fancyMarketsOnCricket && eventData[0]?.id === NavigationIds.FANCY;
  const isTop5View = eventSettings?.viewType === EventViewTypes.TOP_5_VIEW;
  const isMultiMarketView = eventSettings?.viewType === EventViewTypes.MULTI_MARKET_VIEW;

  return {
    isListViewEnabled: (isDefault || isSportPage) && (isListView || (!isCompetitionView && !isHorseRacingView)),
    isDefaultView: isDefault,
    isCompetitionView,
    isFancyView,
    isTop5View,
    eventSettings,
    isMultiMarketView
  };
};
