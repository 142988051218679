import { useRef } from 'react';
import { useSelector } from 'react-redux';

import { Devices } from 'constants/app';
import { MarketDepthViews } from 'constants/markets';
import { DEFAULT_CELL_WIDTH } from 'constants/marketsTable';
import useMarketDepth from 'hooks/useMarketDepth';
import {
  getAppDevice,
  getDesktopExtendedMarketCellWidth,
  getMobileCellWidth,
  getMobileMarketCellWidth
} from 'redux/modules/appConfigs/selectors';

interface UseCellConfigParams {
  /**
   * If this is home page isDepthEnabled should include homePageColumnsNumber in the definition
   */
  isHomePage?: boolean;

  /**
   * Count of bet groups (1 group = 1 back + 1 lay)
   */
  cellGroupsCount: number;

  /**
   * For market view in the mobile the cells are slightly wider (~54px)
   */
  isMarketView?: boolean;

  /**
   * Never show depth, even if enabled
   */
  noDepth?: boolean;
  /**
   * Always show market depth even if disabled
   */
  showDepth?: boolean;
  /**
   * Double cell container width for desktop
   */
  doubleWidth?: boolean;
  /**
   * Add extra space for mobile, when added space between groups
   */
  addMobileExtraSpace?: boolean;
}

const useCellConfigs = ({
  isHomePage = false,
  cellGroupsCount,
  isMarketView = false,
  noDepth = false,
  showDepth = false,
  doubleWidth = false,
  addMobileExtraSpace = false
}: UseCellConfigParams) => {
  const device = useSelector(getAppDevice);
  const desktopExtendedMarketCellWidth = useSelector(getDesktopExtendedMarketCellWidth);
  const mobileMarketCellWidth = useSelector(getMobileMarketCellWidth);
  const mobileCellWidth = useSelector(getMobileCellWidth);

  let desktopCellsContainerWidthNumber = DEFAULT_CELL_WIDTH * cellGroupsCount * 2;
  let mobileCellsContainerWidthNumber = mobileCellWidth * cellGroupsCount * 2;
  let cellWidthMobileNumber = mobileCellWidth;

  const prevCellGroupsCount = useRef<number>(0);

  const { isDepthEnabled, isLandscape } = useMarketDepth(isHomePage ? MarketDepthViews.FEATURED_MARKETS : undefined);

  const isDesktop = device === Devices.DESKTOP;
  const groups = isDepthEnabled || noDepth ? cellGroupsCount : 1;

  if (isDesktop) {
    let width = (desktopExtendedMarketCellWidth || DEFAULT_CELL_WIDTH) * groups * 2;
    width = showDepth && !isDepthEnabled ? width * 3 : width;
    width = doubleWidth ? width * 2 : width;

    desktopCellsContainerWidthNumber = width;
  } else {
    const mobileExtraSpace = addMobileExtraSpace ? (cellGroupsCount === 3 ? 6 : 4) : 0;

    if (isMarketView) {
      mobileCellsContainerWidthNumber = mobileMarketCellWidth * groups * 2 + mobileExtraSpace;
      cellWidthMobileNumber = mobileMarketCellWidth;
    } else {
      mobileCellsContainerWidthNumber = groups * mobileCellWidth * 2 + mobileExtraSpace;
    }
    prevCellGroupsCount.current = groups;
  }

  return {
    isDepthEnabled,
    isLandscape,
    desktopCellsContainerWidthNumber,
    cellWidthMobileNumber,
    mobileCellsContainerWidthNumber,
    desktopCellsContainerWidth: `${desktopCellsContainerWidthNumber}%`,
    cellWidthMobile: `${cellWidthMobileNumber}px`,
    mobileCellsContainerWidth: `${mobileCellsContainerWidthNumber}px`
  };
};

export default useCellConfigs;
