export const MarketsTableColumns = {
  IN_PLAY: 'IN_PLAY',
  PARTICIPANTS_NAMES: 'PARTICIPANTS_NAMES',
  PLACEMENTS: 'PLACEMENTS',
  MATCHED: 'MATCHED',
  RULES: 'RULES',
  DATE_AND_MATCHED: 'DATE_AND_MATCHED',
  MOBILE_SPORT_ICON: 'MOBILE_SPORT_ICON',
  BET_CONTENT: 'BET_CONTENT'
} as const;

export const MarketsTableHeaderTypes = {
  DEFAULT: 'DEFAULT',
  COMPETITION: 'COMPETITION'
} as const;

export const BetContentGroupTypes = {
  DEFAULT: 'DEFAULT', // Best back and best lay
  DEEP_BACK: 'DEEP_BACK', // 2 not the best (remaining) prices for back
  DEEP_LAY: 'DEEP_LAY' // 2 not the best (remaining) prices for lay
} as const;

export const MarketOddsBannerLocationTypes = {
  ALL: 'ALL',
  SPORT: 'SPORT',
  HOME: 'HOME'
} as const;

export const BettingTypes = {
  LINE: 'LINE',
  GAME: 'GAME',
  ASIAN_HANDICAP_SINGLE_LINE: 'ASIAN_HANDICAP_SINGLE_LINE',
  ASIAN_HANDICAP_DOUBLE_LINE: 'ASIAN_HANDICAP_DOUBLE_LINE'
} as const;

export const MarketTypeEndings = {
  A: 'A',
  B: 'B'
} as const;

export const RunnersStatuses = {
  WINNER: 'WINNER',
  LOSER: 'LOSER',
  PLACED: 'PLACED',
  ACTIVE: 'ACTIVE',
  REMOVED: 'REMOVED'
} as const;

export const RunnerStatuses = {
  ACTIVE: 'ACTIVE',
  IN_PLAY: 'IN_PLAY'
} as const;

export const DesktopTopMarketViewTypes = {
  TOP_5: 'TOP_5',
  MULTI_MARKET: 'MULTI_MARKET'
} as const;

export const PriceLadderDescriptions = {
  CLASSIC: 'CLASSIC',
  FINEST: 'FINEST',
  LINE_RANGE: 'LINE_RANGE'
} as const;
export const MarketDepthViews = {
  FEATURED_MARKETS: 'FEATURED_MARKETS',
  SINGLE_MARKET: 'SINGLE_MARKET',
  FANCY_MARKET: 'FANCY_MARKET',
  SEARCH: 'SEARCH',
  TOP_5: 'TOP_5',
  MULTI_MARKET: 'MULTI_MARKET'
} as const;

export const ViewTypes = {
  // Display all columns without truncate
  DEFAULT: 'default',
  // Display all columns without truncate, placements cell maxWidth = 40px
  LARGE: 'large',
  // Display all columns except Matched cell without truncate, placements cell maxWidth = 40px
  MEDIUM: 'medium',
  // Display all columns except Matched and Placement cells without truncate
  SMALL: 'small',
  // Display all columns except Matched and Placement cells with truncate
  EXTRA_SMALL: 'extra-small',
  // Display all columns available for mobile version with truncate
  MOBILE: 'MOBILE'
} as const;
