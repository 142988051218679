import { HTMLAttributes } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined, unescape } from 'lodash';

import ConsolidateBetsButton from 'components/Betslip/components/ConsolidateBetsButton';
import Button from 'components/Button';
import DropdownContainer from 'components/DropdownContainer';
import Icon from 'components/Icon';
import { CookieNames, SortingCookieValues } from 'constants/app';
import { BetslipFilters } from 'constants/betslip';
import { componentsBranding } from 'constants/branding';
import { getBetslipFilterTooltip } from 'constants/tooltip';
import { useEnabledView } from 'hooks/useEnabledView';
import { getConsolidateBets } from 'redux/modules/appConfigs/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { getMultiMarketEventId } from 'redux/modules/multiMarket/selectors';
import { SortingCookieValue } from 'types';

import styles from './OpenBetsActions.module.scss';

interface OpenBetsActionsProps {
  eventId: string;
  marketId: string;
}

const OpenBetsActions = ({ eventId, marketId }: OpenBetsActionsProps) => {
  const isConsolidateBetsEnabled = useSelector(getConsolidateBets);
  const isGameBetSlip = useSelector(getIsGameBetSlip);

  const displayConsolidateBets = !isGameBetSlip && isConsolidateBetsEnabled;

  return (
    <div className={styles.actionsContainer}>
      <div className={styles.actionsContainer__actions}>
        <FilterButton marketId={marketId} eventId={eventId} />
        <SortButton />
      </div>
      {displayConsolidateBets && <ConsolidateBetsButton />}
    </div>
  );
};

export default OpenBetsActions;

const SortButton = () => {
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([
    CookieNames.BETSLIP_FILTER_MARKET,
    CookieNames.BETSLIP_FILTER_SORTING,
    CookieNames.BETSLIP_FILTER_EVENT,
    CookieNames.BETSLIP_SORTED_EVENTS_COLLAPSED
  ]);

  const cookieValue = cookies[CookieNames[`BETSLIP_FILTER_${BetslipFilters.SORTING}`]];
  const isDefaultSorting = cookieValue !== SortingCookieValues.BY_EVENT;

  const handleSortingClick = (value: SortingCookieValue) => {
    if (value !== SortingCookieValues.BY_EVENT) {
      removeCookie(CookieNames.BETSLIP_SORTED_EVENTS_COLLAPSED, { path: '/' });
    }

    setCookie(CookieNames.BETSLIP_FILTER_SORTING, value, { path: '/' });
  };

  const items = (
    <>
      <DropdownItem
        label={t('betslip.labels.openBets.sort.placementTime')}
        isActive={isDefaultSorting}
        onClick={() => handleSortingClick(SortingCookieValues.BY_PLACEMENT)}
        data-tooltip-id="tooltip"
        data-tooltip-place="left"
        data-tooltip-html={unescape(t(getBetslipFilterTooltip(BetslipFilters.SORTING, isDefaultSorting)))}
      />
      <DropdownItem
        label={t('betslip.labels.openBets.sort.startTime')}
        isActive={!isDefaultSorting}
        onClick={() => handleSortingClick(SortingCookieValues.BY_EVENT)}
        data-tooltip-id="tooltip"
        data-tooltip-place="left"
        data-tooltip-html={unescape(t(getBetslipFilterTooltip(BetslipFilters.SORTING, !isDefaultSorting)))}
      />
    </>
  );

  return (
    <DropdownContainer items={items} className={classNames(styles.action)}>
      {isOpen => (
        <Button
          variant="tertiary"
          className={classNames(styles.action__button, {
            [componentsBranding.ACTIVE]: !isDefaultSorting
          })}
          isSelected={!isDefaultSorting}
        >
          <Icon
            fontFamily={isOpen ? 'fa2' : 'av-icon'}
            iconClass={classNames(
              isOpen ? `fa2-chevron-down-icon ${styles.action__icon__chevron}` : 'av-icon-normal-sorting',
              {
                [styles.action__icon__chevron__active]: isOpen && !isDefaultSorting,
                [styles.action__icon__sorting]: !isOpen
              }
            )}
            className={classNames(styles.action__icon)}
          />
          <span>{t('betslip.labels.openBets.sort')}</span>
        </Button>
      )}
    </DropdownContainer>
  );
};

const FilterButton = ({ marketId, eventId }: { marketId: string; eventId: string }) => {
  const multiMarketEventId = useSelector(getMultiMarketEventId);
  const { t } = useTranslation();
  const { isMultiMarketView } = useEnabledView();
  const [cookies, setCookie, removeCookie] = useCookies([
    CookieNames.BETSLIP_FILTER_MARKET,
    CookieNames.BETSLIP_FILTER_EVENT
  ]);

  const isMarket = !isMultiMarketView && marketId;
  const filterType = isMarket ? BetslipFilters.MARKET : BetslipFilters.EVENT;
  const isAllBetsFilter = isUndefined(cookies[CookieNames[`BETSLIP_FILTER_${filterType}`]]);

  const items = (
    <>
      <DropdownItem
        label={t('betslip.openBets.filter.allBets')}
        isActive={isAllBetsFilter}
        onClick={() => {
          removeCookie(CookieNames.BETSLIP_FILTER_MARKET, { path: '/' });
          removeCookie(CookieNames.BETSLIP_FILTER_EVENT, { path: '/' });
        }}
        data-tooltip-id="tooltip"
        data-tooltip-place="left"
        data-tooltip-html={unescape(t(getBetslipFilterTooltip(filterType, true)))}
      />
      <DropdownItem
        label={isMarket ? t('betslip.openBets.filter.currentMarket') : t('betslip.openBets.filter.currentEvent')}
        isActive={!isAllBetsFilter}
        onClick={() => {
          if (isMarket) {
            setCookie(CookieNames.BETSLIP_FILTER_MARKET, marketId, { path: '/' });
          } else {
            setCookie(CookieNames.BETSLIP_FILTER_EVENT, isMultiMarketView ? multiMarketEventId : eventId, {
              path: '/'
            });
          }
        }}
        data-tooltip-id="tooltip"
        data-tooltip-place="left"
        data-tooltip-html={unescape(t(getBetslipFilterTooltip(filterType, false)))}
      />
    </>
  );

  return (
    <DropdownContainer items={items} className={classNames(styles.action)}>
      {isOpen => (
        <Button
          variant="tertiary"
          isSelected={!isAllBetsFilter}
          className={classNames(styles.action__button, { [componentsBranding.ACTIVE]: !isAllBetsFilter })}
        >
          <Icon
            fontFamily="fa2"
            iconClass={classNames(isOpen ? `fa2-chevron-down-icon ${styles.action__icon__chevron}` : 'fa2-filter', {
              [styles.action__icon__chevron__active]: isOpen && !isAllBetsFilter
            })}
            className={classNames(styles.action__icon)}
          />
          <span>{t('betslip.labels.openBets.filter')}</span>
        </Button>
      )}
    </DropdownContainer>
  );
};

interface DropdownItemProps extends HTMLAttributes<HTMLLIElement> {
  isActive: boolean;
  label: string;
  onClick: () => void;
}

const DropdownItem = ({ isActive, label, onClick, ...props }: DropdownItemProps) => {
  return (
    <li onClick={onClick} {...props} className={classNames(componentsBranding.DROPDOWN_ITEM)}>
      <span
        className={classNames({
          [styles.dropdownItem__active]: isActive
        })}
      >
        {label}
      </span>
      {isActive && <Icon fontFamily="fa2" iconClass="fa2-check" color="black" />}
    </li>
  );
};
