import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices } from 'constants/app';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';

import styles from '../../styles.module.scss';

interface SidebarItemProps {
  changeTour: (id: number) => void;
  id: number;
  name: string;
  tourId: number;
  showHowToSectionOnly?: boolean;
}

const SidebarItem = ({ changeTour, id, name, tourId, showHowToSectionOnly }: SidebarItemProps) => {
  const device = useSelector(getAppDevice);

  const isMobile = device === Devices.MOBILE;

  return (
    <div
      onClick={() => changeTour(id)}
      className={classNames('biab_left-menu-item', {
        ['biab_active']: id === tourId && !showHowToSectionOnly,
        [styles.active]: id === tourId && !showHowToSectionOnly,
        [styles.mobileMenu__item]: isMobile,
        [styles.rightMenu__item]: !isMobile
      })}
    >
      <div className={classNames({ [styles.rightMenu__item__name]: !isMobile })}>{name}</div>
      <div
        className={classNames({
          [styles.mobileMenu__item__arrow]: isMobile,
          [styles.rightMenu__item__arrow]: !isMobile
        })}
      >
        <i className={'biab_tour-icon-right'} />
      </div>
    </div>
  );
};

export default SidebarItem;
