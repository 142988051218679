import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Fraction from 'fraction.js';

import { MAX_PRICE, MIN_PRICE } from 'constants/app';
import { KEY_CODES } from 'constants/betslip';
import { componentsBranding, educationBranding, mobileComponents } from 'constants/branding';
import useDevice from 'hooks/useDevice';
import { PRECISION } from 'utils/betValidation';

import styles from './styles.module.scss';

interface FractionalOddsCalculatorProps {
  isDirect?: boolean;
}

const FractionalOddsCalculator = ({ isDirect = false }: FractionalOddsCalculatorProps) => {
  const { t } = useTranslation();
  const { isMobile } = useDevice();
  const isMobileView = isMobile && !isDirect;

  const [numerator, setNumerator] = useState('5');
  const [denominator, setDenominator] = useState('2');
  const [odds, setOdds] = useState('3.5');
  const numeratorRef = useRef<HTMLInputElement>(null);

  const validateNumber = (value: string, min?: number, max?: number, precision?: number): string => {
    const numValue = parseFloat(value);
    if (isNaN(numValue)) return ''; // Empty for invalid input
    if (min !== undefined && numValue < min) return min.toFixed(2);
    if (max !== undefined && numValue > max) return max.toFixed(2);
    if (precision) {
      return numValue.toFixed(precision);
    } else {
      return numValue.toString();
    }
  };

  const handleOddsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[1-9]+\d*\.?\d{0,2}$/;

    if (regex.test(value) || value === '') {
      const validValue = value || validateNumber(value);
      setOdds(validValue);
      if (validValue !== '') {
        const fraction = new Fraction(validValue).sub(1).toFraction().split('/');
        setNumerator(fraction?.[0]);
        setDenominator(fraction?.[1] || '1');
      } else {
        setNumerator('');
        setDenominator('');
      }
    }
  };

  const handleOddsBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[1-9]+\d*\.?\d{0,2}$/;

    if (regex.test(value) || value === '') {
      const validValue = validateNumber(value, MIN_PRICE, MAX_PRICE);
      setOdds(validValue);
      if (validValue !== '') {
        const fraction = new Fraction(validValue).sub(1).toFraction().split('/');
        setNumerator(fraction?.[0]);
        setDenominator(fraction?.[1] || '1');
      } else {
        setNumerator('');
        setDenominator('');
      }
    }
  };

  const handleNumeratorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[1-9]+\d*$/;

    if (regex.test(value) || value === '') {
      const validValue = value || validateNumber(value);
      const newOdds = parseFloat(validValue) / parseFloat(denominator || '1') + 1;
      setNumerator(validValue);
      setOdds(validValue && denominator ? validateNumber(newOdds.toString(), MIN_PRICE, MAX_PRICE, PRECISION) : '');
    }
  };

  const handleDenominatorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[1-9]+\d*$/;

    if (regex.test(value) || value === '') {
      const validValue = value || validateNumber(value);
      const newOdds = parseFloat(numerator) / parseFloat(validValue || '1') + 1;
      setDenominator(validValue);
      setOdds(validValue && numerator ? validateNumber(newOdds.toString(), MIN_PRICE, MAX_PRICE, PRECISION) : '');
    }
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === KEY_CODES.TAB) {
      e.preventDefault();
      numeratorRef.current?.focus();
    }
  };

  return (
    <>
      {isDirect && (
        <Helmet>
          <title data-rh="true">{t('education.labels.oddsMadeEasy')}</title>
        </Helmet>
      )}
      <div
        className={classNames(styles.wrapper, educationBranding.ODDS_CALCULATOR_BOTTOM, {
          [styles.wrapper__noBorder]: isDirect,
          [styles.embeded]: !isDirect
        })}
      >
        <div className={classNames(styles.border, { [styles.border__noBorder]: isDirect })}>
          <div className={styles.form}>
            <div className={styles.inputColumns}>
              <div className={styles.inputWrapper}>
                <div className={styles.inputLabel}>{t('education.labels.fractionalOdds')}</div>
                <div className={styles.fractionalWrapper}>
                  <input
                    className={classNames(styles.input, {
                      [componentsBranding.FORM_INPUT]: !isMobileView,
                      [mobileComponents.INPUT]: isMobileView
                    })}
                    lang="en-US"
                    inputMode="decimal"
                    autoComplete="off"
                    type="text"
                    value={numerator}
                    ref={numeratorRef}
                    onChange={handleNumeratorChange}
                  />
                  <span className={styles.symbol}>/</span>
                  <input
                    lang="en-US"
                    inputMode="decimal"
                    autoComplete="off"
                    className={classNames(styles.input, {
                      [componentsBranding.FORM_INPUT]: !isMobileView,
                      [mobileComponents.INPUT]: isMobileView
                    })}
                    type="text"
                    value={denominator}
                    onChange={handleDenominatorChange}
                  />
                </div>
              </div>
              <span className={classNames(styles.symbol, styles.symbol__equal)}>=</span>
              <div className={styles.inputWrapper}>
                <div className={styles.inputLabel}>{t('education.labels.decimalOdds')}</div>
                <input
                  lang="en-US"
                  inputMode="decimal"
                  autoComplete="off"
                  className={classNames(styles.input, styles.input__odds, {
                    [componentsBranding.FORM_INPUT]: !isMobileView,
                    [mobileComponents.INPUT]: isMobileView
                  })}
                  type="text"
                  value={odds}
                  onChange={handleOddsChange}
                  onBlur={handleOddsBlur}
                  onKeyDown={keyDownHandler}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FractionalOddsCalculator;
