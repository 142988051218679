import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import FavoriteStar from 'components/FavoriteStar';
import styles from 'components/SportsSection/styles.module.scss';
import { Devices, ElementNames, FAVORITES_TYPES, GAME_BETTING, SportIds } from 'constants/app';
import { TYPE_TO_URL } from 'constants/competitions';
import { POKER_STAR } from 'constants/icons';
import { SPORT_BASE_URL } from 'constants/locations';
import { getAppDevice, getOperator, getTranslation } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getCompetitionAndCountry } from 'redux/modules/competitions';
import { getIsAllSoccer } from 'redux/modules/competitions/selectors';
import { TNestedItem } from 'redux/modules/competitions/type';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { getArrowLeftByOperator } from 'utils/operator';

interface IParentItem {
  item: TNestedItem;
  isLast?: boolean;
}

const ParentItem = ({ item, isLast }: IParentItem) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportId } = useParams();

  const domainLanguage = useSelector(getTranslation);
  const operator = useSelector(getOperator);
  const device = useSelector(getAppDevice);
  const isAllSoccer = useSelector(getIsAllSoccer);
  const isFavorite = useSelector(getFavoriteById(item.id));

  const isTennis = sportId === SportIds.TENNIS;
  const isEventItem = TYPE_TO_URL[item.type] === TYPE_TO_URL.EVENT;
  const isGroupItem = TYPE_TO_URL[item.type] === TYPE_TO_URL.GROUP;
  const isCompetitionItem = TYPE_TO_URL[item.type] === TYPE_TO_URL.COMPETITION;
  const parentIcon = getArrowLeftByOperator(operator);
  const isMobile = device === Devices.MOBILE;
  const isDesktop = device === Devices.DESKTOP;
  const isShowArrow = device === Devices.MOBILE && (!isLast || isAllSoccer);
  const currentSportRef = useRef<HTMLLIElement>(null);
  const isGamesBettingGroup = isGroupItem && isTennis && item.name === GAME_BETTING;
  const isShowFavorites =
    item.type !== FAVORITES_TYPES.market && FAVORITES_TYPES[TYPE_TO_URL[item.type]] && !isGamesBettingGroup;

  const favoriteData = useMemo(() => {
    return {
      entryId: item.id,
      sportId: `${item.eventTypeId}`,
      starred: !isFavorite,
      entryName: item.name,
      entryType: item.type
    };
  }, [isFavorite, item]);

  useEffect(() => {
    if (TYPE_TO_URL[item.type] === TYPE_TO_URL.EVENT_TYPE) {
      dispatch(
        setElementHeight({
          name: ElementNames.NAVIGATION_PARENT_SPORT_NAME,
          height: currentSportRef?.current?.clientHeight || 0
        })
      );
    }
  }, [item]);

  const handleClick = () => {
    if (isLast && !isAllSoccer) {
      return;
    }
    if (!item.type && sportId === SportIds.SOCCER) {
      navigate(`${SPORT_BASE_URL}/${item.id}`);
      return;
    }
    if (TYPE_TO_URL[item.type] === TYPE_TO_URL.EVENT_TYPE && sportId === SportIds.SOCCER) {
      dispatch(getCompetitionAndCountry(sportId));
    }

    navigate(
      TYPE_TO_URL[item.type] === TYPE_TO_URL.EVENT_TYPE
        ? `${SPORT_BASE_URL}/${item.id}`
        : `${SPORT_BASE_URL}/${sportId}/${TYPE_TO_URL[item.type]}/${item.id}`
    );
  };

  return (
    <li
      ref={currentSportRef}
      data-test-id={TYPE_TO_URL[item.type] === TYPE_TO_URL.EVENT_TYPE ? 'current-sport' : ''}
      datatype={TYPE_TO_URL[item.type]}
      data-navigation-type={item.type}
      data-navigation-id={item.id}
      key={item.id}
      className={classNames(`${styles.sportsList__item}`, {
        [`biab_c-highlighted ${styles.sportsList__item_active} ${styles.highlighted}`]: item.highlighted && isDesktop,
        [styles.parentItem]: isMobile,
        [styles.eventItem]: isEventItem && isDesktop,
        biab_item: isEventItem && isDesktop,
        [`biab_${TYPE_TO_URL[item.type]}-item`]: isDesktop,
        'biab_sport-item': (!isCompetitionItem && isDesktop) || isMobile,
        'biab_navigation-competition-item-name': isCompetitionItem && isDesktop,
        [styles.parentItem__competition]: isCompetitionItem && isDesktop,
        'biab_group-item': isGroupItem && isDesktop,
        [`biab_active ${styles.activeParent}`]: isLast && !isAllSoccer,
        'biab_all-sports': isMobile && (!isLast || isAllSoccer)
      })}
    >
      <a
        className={classNames('biab_href', {
          [`biab_c-highlighted ${styles.highlighted}`]: item.highlighted && isDesktop,
          'biab_all-sports': isMobile && (!isLast || isAllSoccer)
        })}
        onClick={handleClick}
      >
        <div>
          {isShowArrow && (
            <i
              className={classNames('biab_back-arrow-icon', styles.arrowContainer, parentIcon, {
                [styles.rotate]: operator === POKER_STAR,
                [styles.arrowContainer__default]: operator !== POKER_STAR
              })}
            />
          )}
          {item?.translations[domainLanguage]}
        </div>
        {isShowFavorites && (
          <div
            className={classNames(styles.sportsList__favoriteItem, styles.sportsList__favoriteItemParent, {
              [styles.sportsList__favoriteHidden]: !isFavorite && isDesktop
            })}
          >
            <FavoriteStar className={styles.collapsibleRow__starIcon} entryData={favoriteData} isNavigation={true} />
          </div>
        )}
      </a>
    </li>
  );
};

export default ParentItem;
