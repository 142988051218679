import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { GameSelectionStatus } from 'redux/modules/games/type';

import styles from '../../styles.module.scss';

interface IStatusRow {
  status: GameSelectionStatus;
}

const StatusRow = ({ status }: IStatusRow) => {
  const { t } = useTranslation();
  const statusInLowerCase = status.toLowerCase();

  return (
    <div
      className={classNames(
        styles.statusWrapper,
        `biab_game-overlay-background biab_overlay-status-${statusInLowerCase}`
      )}
    >
      <div
        className={classNames(styles.status, `biab_game-overlay-status biab_overlay-text-status-${statusInLowerCase}`)}
      >
        {t(`market.status.game.${statusInLowerCase}`)}
      </div>
    </div>
  );
};

export default StatusRow;
