import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { toNumber } from 'lodash';

import { AsianPlacedQuickBettingStatuses } from 'constants/asianView';
import { LOADING_TIMEOUT_MESSAGE } from 'constants/placement';
import {
  getBetslipSpinnerTime,
  getBetsStatusesRequestInterval,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { fetchAsianCurrentBets } from 'redux/modules/asianViewCurrentBets';
import { fetchQuickBetStatuses, updatePlacedQuickBet } from 'redux/modules/asianViewQuickBetting';
import { TAsianQuickPlacedBet } from 'redux/modules/asianViewQuickBetting/type';

interface IProgressQuickBet {
  quickBet: TAsianQuickPlacedBet;
}
const ProgressQuickBet = ({ quickBet }: IProgressQuickBet) => {
  const dispatch = useDispatch();

  const betsStatusesRequestInterval = useSelector(getBetsStatusesRequestInterval);
  const placementTime = useSelector(getBetslipSpinnerTime);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);

  const isCreated = quickBet.status === AsianPlacedQuickBettingStatuses.CREATED;
  const isPlaced = quickBet.status === AsianPlacedQuickBettingStatuses.PLACED;
  const isError = quickBet.status === AsianPlacedQuickBettingStatuses.ERROR;

  const fetchPlacedBetStatusInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const placementTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const loading = useRef(quickBet.isLoading);
  loading.current = quickBet.isLoading;

  const clearStatusInterval = () => {
    if (fetchPlacedBetStatusInterval.current) {
      clearInterval(fetchPlacedBetStatusInterval.current);
    }
  };

  const clearPlacementTimeout = () => {
    if (placementTimeout.current) {
      clearTimeout(placementTimeout.current);
    }
  };

  useEffect(() => {
    if (arePropertiesLoaded) {
      if (isCreated && quickBet.offerId && !fetchPlacedBetStatusInterval.current) {
        fetchPlacedBetStatusInterval.current = setInterval(() => {
          if (!loading.current) {
            dispatch(fetchQuickBetStatuses({ offerIds: [toNumber(quickBet.offerId)] }));
          }
        }, parseInt(betsStatusesRequestInterval));

        placementTimeout.current = setTimeout(() => {
          dispatch(
            updatePlacedQuickBet({
              ...quickBet,
              status: AsianPlacedQuickBettingStatuses.ERROR,
              placementError: t(LOADING_TIMEOUT_MESSAGE)
            })
          );

          clearStatusInterval();
          clearPlacementTimeout();
        }, +placementTime * 1000);
      } else if (isPlaced || isError) {
        clearStatusInterval();

        dispatch(fetchAsianCurrentBets());
      }
    }
  }, [quickBet.offerId, isCreated, isPlaced, betsStatusesRequestInterval, arePropertiesLoaded]);

  useEffect(() => {
    return () => {
      clearStatusInterval();
      clearPlacementTimeout();
    };
  }, []);

  return null;
};

export default ProgressQuickBet;
