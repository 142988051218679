import { CookieSetOptions } from 'universal-cookie';

import { CookieNames, Devices, IFrameMessageTypes } from 'constants/app';
import { HOME_BASE_URL, MOBILE_LOGIN_BASE_URL } from 'constants/locations';
import { Dispatch } from 'redux';
import { fetchAppConfigs } from 'redux/modules/appConfigs';
import { setParentDomain } from 'redux/modules/appSettings';
import { logoutUser } from 'redux/modules/auth';
import { fetchUserInfo, setOperatorBalance } from 'redux/modules/user';
import { Device } from 'types';
import { iosBundleRedirect } from 'utils';
import { parseDomainFromUrl } from 'utils/url';

const inFrame = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const subscribeOnIframeMessages = (
  setCookie: (name: string, value: any, options?: CookieSetOptions) => void,
  removeCookie: (name: string, options?: CookieSetOptions) => void,
  dispatch: Dispatch,
  properties: { fallbackLang: string },
  device: Device
) => {
  function getMessage(event: MessageEvent) {
    if (event.data.type === IFrameMessageTypes.USER_BALANCE) {
      if (event.data?.value) {
        dispatch(setOperatorBalance(event.data.value));
      }
    }

    if (event.data.type === IFrameMessageTypes.CHECK_ORIGIN) {
      if (event?.origin) {
        dispatch(setParentDomain(event?.origin.split('://')[1] || ''));
      }
    }

    if (event.data.type === IFrameMessageTypes.AUTHENTICATION) {
      setCookie(CookieNames.TOKEN as string, event.data.token, {
        path: '/'
      });

      const domain = parseDomainFromUrl(
        window.location.hostname,
        window.environmentConfig?.langCookieDomainPattern ||
          (process.env.REACT_APP_LANG_COOKIE_DOMAIN_PATTERN as string)
      );

      if (domain && domain.trim().length > 0) {
        setCookie(CookieNames.DOMAIN, domain);
      }

      dispatch(fetchAppConfigs());
      dispatch(fetchUserInfo());
    }

    if (event.data.type === IFrameMessageTypes.LOGGED_IN) {
      const lang = window.environmentConfig?.langCookieName || (process.env.REACT_APP_LANG_COOKIE_NAME as string);
      const locale =
        event.data.locale == 'en_US' || event.data.locale == 'en_en'
          ? 'en'
          : event.data.locale || properties.fallbackLang;
      const timezoneName = window.environmentConfig?.tzCookieName || (process.env.REACT_APP_TZ_COOKIE_NAME as string);

      if (locale) {
        setCookie(lang, locale, { path: '/' });
        setCookie(CookieNames.I18_NEXT, locale, { path: '/' });
      }

      if (event.data.timezone) {
        setCookie(timezoneName, event.data.timezone, { path: '/' });
      }

      if (event.data.token) {
        setCookie(CookieNames.TOKEN as string, event.data.token, {
          path: '/'
        });
      }

      if (window.environmentConfig?.useHeadersInsteadOfCookies || iosBundleRedirect) {
        setCookie(CookieNames.IS_LOGGING_IN, true, { path: '/' });
      }

      dispatch(fetchAppConfigs());
      dispatch(fetchUserInfo());
    }

    if (event.data.type === IFrameMessageTypes.LOGGED_OUT) {
      setCookie(CookieNames.BIAB_LOGIN_POP_UP_SHOWN, null, { path: '/' });
      setCookie(CookieNames.LOGIN_POP_UP_SHOWN, null, { path: '/' });

      if (window.environmentConfig?.useHeadersInsteadOfCookies || iosBundleRedirect) {
        removeCookie(CookieNames.TOKEN as string, {
          path: '/'
        });

        dispatch(fetchAppConfigs());
        dispatch(fetchUserInfo());
      } else {
        const redirectUrl = device === Devices.MOBILE ? MOBILE_LOGIN_BASE_URL : HOME_BASE_URL;
        dispatch(logoutUser(redirectUrl));
      }
    }
  }
  if (inFrame()) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    eventer(messageEvent, getMessage, false);
  }
};

export const sendIframeMessages = (params: Record<string, string | number>) => {
  if (window && window.parent) {
    window.parent.postMessage(params, '*');
  }
};

export const dispatchEvent = (params: Record<string, string | number | any>) => {
  if (iosBundleRedirect) {
    const e: Event | any = document.createEvent('Event');
    e.initEvent(params.type, !0, !0);
    e.data = params;
    document.dispatchEvent(e);
    const customEvent = new CustomEvent(params.type, params);
    document.dispatchEvent(customEvent);
  }
};
