import { SportIds } from 'constants/app';
import { BettingTypes } from 'constants/markets';
import useDeviceSettings from 'hooks/useDeviceSettings';

export const useFancySwapColors = ({ sportId, bettingType = '' }: { sportId: string; bettingType?: string }) => {
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const isCricket = sportId === SportIds.CRICKET;
  const isLineMarket = bettingType === BettingTypes.LINE;

  return isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;
};
