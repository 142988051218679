import { MouseEvent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import InfoCircle from 'components/InfoCircle';
import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import { Devices } from 'constants/app';
import { asianViewIcons } from 'constants/branding';
import { ASIAN_VIEW_CASH_OUT, CASH_OUT } from 'constants/tooltip';
import useAsianView from 'hooks/useAsianView';
import useTooltip from 'hooks/useTooltip';
import { fetchCashOutPopUp } from 'redux/modules/appConfigs';
import { getAppDevice, getCashOutPopUp, getCashOutPopUpLoading, getLanguage } from 'redux/modules/appConfigs/selectors';
import { ModalClasses } from 'types';

import styles from './styles.module.scss';

type CashOutProps = {
  /**
   * Show info icon or not
   */
  showInfoIcon?: boolean;
  /**
   * Show label or not
   */
  showLabel?: boolean;
  /**
   * Should make request or not
   */
  clickable?: boolean;
  disabled?: boolean;
  className?: {
    container?: string;
    icon?: string;
  };
  isAvPLTableAvailable?: boolean;
  modalClassNames?: ModalClasses;
};

const CashOut = ({
  showLabel = true,
  clickable = true,
  showInfoIcon = true,
  className = {},
  disabled,
  isAvPLTableAvailable = false,
  modalClassNames
}: CashOutProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAsianViewPage } = useAsianView();

  const cashOutPopUp = useSelector(getCashOutPopUp);
  const cashOutPopUpLoading = useSelector(getCashOutPopUpLoading);
  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);

  const { translationKey, isEnabled, isShowIcon } = useTooltip(isAsianViewPage ? ASIAN_VIEW_CASH_OUT : CASH_OUT);

  const [openModal, setOpenModal] = useState(false);

  const isMobile = device === Devices.MOBILE;

  const handleClickCashOut = (e: MouseEvent) => {
    e.stopPropagation();
    if (clickable) {
      setOpenModal(true);

      if (!cashOutPopUp.content) {
        dispatch(fetchCashOutPopUp(language));
      }
    }
  };

  const handleCloseCashout = (e?: SyntheticEvent) => {
    e?.stopPropagation();
    setOpenModal(false);
  };

  return (
    <div className={classNames(styles.cashOut__container, className.container ?? '')}>
      <div className={styles.cashOut}>
        <div className={styles.cashOut__icon__wrapper}>
          <>
            <span
              data-tooltip-id={'tooltip'}
              data-tooltip-html={isEnabled && !isMobile ? unescape(t(translationKey)) : ''}
              className={classNames(
                styles.cashOut__title,
                {
                  'cursor-help': isEnabled,
                  [styles.cashOut__desktop]: !isMobile
                },
                className.icon ?? ''
              )}
            >
              <span
                className={classNames(
                  'biab_cashout-icon',
                  styles.cashOut__icon__container,
                  asianViewIcons.CASH_OUT_ICON,
                  {
                    [styles.cashOut__disabled]: disabled,
                    [asianViewIcons.ENABLED]: !disabled
                  }
                )}
                title={t('market.cashOut')}
                onClick={handleClickCashOut}
              >
                <i className={styles.cashOut__icon} />
              </span>
            </span>
            {showLabel && (
              <>
                <span
                  data-tooltip-id="tooltip"
                  data-tooltip-html={isEnabled && !isMobile ? unescape(t(translationKey)) : ''}
                  className={classNames(
                    styles.cashOut__title,
                    {
                      'cursor-help': isEnabled
                    },
                    className.icon ?? ''
                  )}
                >
                  {t('market.cashOut')}
                </span>
                {isEnabled && isShowIcon && showInfoIcon && (
                  <span data-tooltip-id="tooltip" data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}>
                    <InfoCircle />
                  </span>
                )}
              </>
            )}
          </>
        </div>
      </div>
      <Modal
        customClassNames={{
          ...modalClassNames,
          header: classNames(styles.modalHeader, modalClassNames?.header ?? ''),
          closeIcon: classNames(modalClassNames?.closeIcon ?? '', {
            biab_close: !isAsianViewPage
          }),
          dialog: classNames(
            styles.modalDialog,
            {
              [styles.modalDialog__aVplTable]: isAvPLTableAvailable
            },
            modalClassNames?.dialog ?? ''
          ),
          body: classNames(styles.modalBody, modalClassNames?.body ?? '')
        }}
        clickableBackground
        open={openModal}
        onClose={handleCloseCashout}
        title={cashOutPopUpLoading ? t('marketRules.loading') : cashOutPopUp.name}
      >
        {cashOutPopUpLoading ? (
          <Loader circleColor={CircleColors.BLACK} />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: cashOutPopUp.content }} />
        )}
      </Modal>
    </div>
  );
};

export default CashOut;
