import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import BetGroupEmptyCells from 'components/BetGroupEmptyCells';
import InlinePlacement from 'components/InlinePlacement';
import BetContentGroup from 'components/MarketsTable/components/MarketsTableRow/components/BetContentGroup';
import StatusOverlay from 'components/MarketsTable/components/MarketsTableRow/components/StatusOverlay';
import MobileOpenBets from 'components/MobileOpenBets';
import MobilePlacement from 'components/MobilePlacement';
import { Devices, PageBlocks, PlacementPages } from 'constants/app';
import { BetContentGroupTypes } from 'constants/markets';
import useCellConfigs from 'hooks/useCellConfigs';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  getInlineSelectedBetBySelection,
  getInlineSelectedBetsBySelection
} from 'redux/modules/inlinePlacement/selectors';
import {
  getMarketPricesCurrency,
  getMarketPricesIsBettingEnabled,
  getMatchedAmountByMarketPricesId,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { PageBlock } from 'types';
import { IMarket } from 'types/markets';
import { isLineBettingType } from 'utils/betslip';
import { getCellGroupsCount } from 'utils/fancy';
import { getBetGroups } from 'utils/market';

import styles from './styles.module.scss';

interface FancyMarketItemProps {
  /**
   * Is multi market view enabled or not
   */
  isMultiMarketView?: boolean;

  /**
   * Market name
   */
  name: string;

  /**
   * Deep prices should be displayed if available or not?
   */
  hasMarketDepth?: boolean;

  /**
   * Data for the market (marketId, runners, event data, startTime, etc.)
   */
  market: IMarket;

  /**
   * Is item last in displayed list
   */
  isLastItem: boolean;

  /**
   * Is item first in displayed list
   */
  isFirstItem: boolean;

  /**
   * Occupies all available width of middle section or half
   */
  isTopItem?: boolean;

  /**
   * Should header be rendered or not
   */
  noHeader?: boolean;

  /**
   * Full width for section if on multi market page
   */
  sectionFullWidth?: boolean;

  /**
   * Is twoColumnView on multi market page is turned on?
   */
  twoColumnView?: boolean;
  /**
   * Whether lay column is disabled
   */
  isLayColumnDisabled: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
  /**
   * Show tooltip in the first top cell
   */
  showCellTooltip?: boolean;
  /**
   * Page block for betting
   */
  pageBlock: PageBlock;
}

const FancyMarketItem = ({
  isMultiMarketView,
  name,
  hasMarketDepth = false,
  market,
  isLastItem,
  isTopItem = false,
  isFirstItem,
  noHeader,
  sectionFullWidth = false,
  twoColumnView = false,
  isLayColumnDisabled,
  isDepthEnabled,
  showCellTooltip,
  pageBlock
}: FancyMarketItemProps) => {
  const { t } = useTranslation();

  const { lineMarketsSwitchBackLayOnCricket } = useDeviceSettings();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const device = useSelector(getAppDevice);
  const mobilePageBlock = isMultiMarketView ? PageBlocks.MULTI_MARKET_VIEW : pageBlock;
  const inlineSelectedBet = useSelector(
    getInlineSelectedBetBySelection(
      mobilePageBlock,
      market.marketId,
      market.runners[0]?.selectionId,
      market.runners[0]?.handicap
    )
  );
  const inlineSelectedBets = useSelector(
    getInlineSelectedBetsBySelection(
      mobilePageBlock,
      market.marketId,
      market.runners[0]?.selectionId,
      market.runners[0]?.handicap
    )
  );
  const bettingEnabled = useSelector(getMarketPricesIsBettingEnabled(market.marketId));
  const marketPricesStatus = useSelector(getStatusByMarketPricesId(market.marketId));
  const marketPricesCurrency = useSelector(getMarketPricesCurrency(market.marketId));
  const totalMatched = useSelector(getMatchedAmountByMarketPricesId(market.marketId));

  const [isBetslipClosing, setIsBetslipClosing] = useState(false);

  const { formattedAmount: matchedAmount } = useFormatCurrency(totalMatched || 0, marketPricesCurrency);
  const { showStatus, status, showLockIcon, displayStatus } = useMarketStatusAndLockIcon(
    bettingEnabled,
    marketPricesStatus
  );
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market?.marketId,
    eventId: market?.event?.id,
    observerOptions: { rootMargin: '200px' },
    section: MarketsPricesSocketParamsSections.SportsMiddleSection
  });

  const isMobile = device === Devices.MOBILE;
  const isDesktop = device === Devices.DESKTOP;
  const isMobileOpenBetsVisible = isMobile && isLoggedIn && (isBetslipClosing || !inlineSelectedBet);
  const showDeepPrice = (hasMarketDepth || sectionFullWidth) && isDepthEnabled && !twoColumnView;
  const isLineMarket = isLineBettingType(market.description.bettingType);
  const betGroups = getBetGroups(showDeepPrice, !isLayColumnDisabled, isLineMarket);
  let headerItems = lineMarketsSwitchBackLayOnCricket ? ['no', 'yes'] : ['back', 'lay'];
  headerItems = isLayColumnDisabled && !isLineMarket ? [headerItems[0]] : headerItems;
  const showEmptyColumns = isDesktop && !isDepthEnabled && (isTopItem || sectionFullWidth) && !twoColumnView;
  const alwaysThreeGroups =
    showEmptyColumns || (isLayColumnDisabled && !isLineMarket && (isTopItem || sectionFullWidth));
  const placementPage = isMultiMarketView ? PlacementPages.MULTI_MARKET : PlacementPages.FANCY_PAGE;

  const { desktopCellsContainerWidth, mobileCellsContainerWidth } = useCellConfigs({
    cellGroupsCount: alwaysThreeGroups ? 3 : betGroups.length,
    isMarketView: true,
    doubleWidth: (!isTopItem && !sectionFullWidth) || twoColumnView,
    showDepth: showEmptyColumns,
    addMobileExtraSpace: true
  });

  const cellGroupsCount = getCellGroupsCount(
    alwaysThreeGroups,
    isTopItem,
    sectionFullWidth,
    twoColumnView,
    betGroups.length
  );
  const cellsStyle = { width: isDesktop ? desktopCellsContainerWidth : mobileCellsContainerWidth };

  return (
    <div ref={ref} data-market-id={market?.marketId} data-event-id={market?.event.id} data-market-prices={true}>
      {isFirstItem && !noHeader && (
        <div className={styles.header}>
          <div
            className={classNames(styles.header__content, {
              [styles.header__content__withoutLay]: isLayColumnDisabled && !isLineMarket
            })}
            style={cellsStyle}
          >
            <div className={styles.header__content__container} style={{ width: `${100 / cellGroupsCount}%` }}>
              {headerItems.map(item => (
                <span key={item}>{t(`market.${lineMarketsSwitchBackLayOnCricket ? 'column.' : ''}${item}`)}</span>
              ))}
            </div>
          </div>
        </div>
      )}
      <div
        className={classNames('biab_market-table', styles.fancyMarketItem, {
          [styles.fancyMarketItem__last]: isLastItem
        })}
      >
        <span className={classNames(styles.fancyMarketItem__name, styles.fancyMarketItem__cell)}>{name}</span>
        <span className={classNames(styles.fancyMarketItem__matchedAmount, styles.fancyMarketItem__cell)}>
          {matchedAmount}
        </span>
        <div className={styles.fancyMarketItem__betContent} style={cellsStyle}>
          {showEmptyColumns && <BetGroupEmptyCells />}
          {betGroups.map((type, index) => (
            <BetContentGroup
              marketStatusSettings={{ showStatus, status, showLockIcon, displayStatus }}
              key={type}
              betGroupsCount={showEmptyColumns ? 3 : betGroups.length}
              runner={market.runners[0]}
              market={market}
              type={type}
              pageBlock={pageBlock}
              mobileBettingMode={!isDesktop}
              allGroupsTypes={betGroups}
              cellClasses={
                !isDesktop
                  ? {
                      cell: classNames(styles.mobileFancyCell, {
                        [styles.mobileFancyCell__withoutLay]: isLayColumnDisabled && !isLineMarket
                      }),
                      container: styles.mobileFancyCell__container
                    }
                  : {}
              }
              showCellTooltip={
                ((!isMobile && type === BetContentGroupTypes.DEFAULT) || (isMobile && index === 0)) && showCellTooltip
              }
              page={placementPage}
            />
          ))}
          {showEmptyColumns && (!isLayColumnDisabled || isLineMarket) && <BetGroupEmptyCells />}
        </div>
        {showStatus && <StatusOverlay status={status} />}
      </div>
      {!!inlineSelectedBets.length && isDesktop && <InlinePlacement bets={inlineSelectedBets} page={placementPage} />}
      {inlineSelectedBet && !isDesktop && (
        <MobilePlacement
          bet={inlineSelectedBet}
          pageBlock={mobilePageBlock}
          onClosingBetslip={setIsBetslipClosing}
          page={placementPage}
        />
      )}
      {isMobileOpenBetsVisible && (
        <MobileOpenBets
          marketId={market.marketId}
          pageBlock={pageBlock}
          page={placementPage}
          sportId={market.eventType.id}
        />
      )}
    </div>
  );
};

export default FancyMarketItem;
