import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import NavSkeleton from 'components/Skeleton/NavSkeleton';
import CloseListItem from 'components/SportsSection/partials/CloseListItem';
import ListItem from 'components/SportsSection/partials/ListItem';
import NavItemName from 'components/SportsSection/partials/NavItemName';
import ParentItem from 'components/SportsSection/partials/ParentItem';
import {
  Devices,
  ElementNames,
  FAVORITES_TYPES,
  GAME_BETTING,
  RACING_SPORT,
  RACING_SPORT_WITH_TODAY,
  SportIds
} from 'constants/app';
import { NavigationTypes, TYPE_TO_URL } from 'constants/competitions';
import { useAppThunkDispatch } from 'redux/configureStore';
import {
  getAppDevice,
  getDesktopSettingsCompetitionViewSports,
  getIsCompetitionViewEnabled,
  getLanguage,
  getPropertiesTours,
  getTimezone,
  getTranslation,
  getWindowHeight,
  getWindowWidth
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchRace } from 'redux/modules/competitions';
import {
  fetchCompetitionDetails,
  fetchGetEvents,
  fetchGetMarket,
  fetchGetSports,
  resetSportData,
  setRace
} from 'redux/modules/competitions';
import {
  getCompetitionData,
  getCompetitionDetails,
  getCompetitionLoading,
  getCompetitionMarket,
  getCompetitionMarketParents,
  getCompetitionNameHighlighted,
  getCompetitionParents,
  getCompetitionsNameTranslations,
  getEventData,
  getEventDataLoading,
  getEventDetails,
  getEventNameHighlighted,
  getEventNameTranslations,
  getEventParents,
  getMarketLoading,
  getRaceLoading,
  getSportData,
  getSportLoading,
  getSportNameHighlighted,
  getSportNameTranslations,
  getSportParents
} from 'redux/modules/competitions/selectors';
import { ITranslation, TNavigationResponse, TNestedItem } from 'redux/modules/competitions/type';
import { getStatusByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { filterCompetitionViewParents } from 'utils/navigation';

interface IRenderMarket {
  parents: TNestedItem[];
  name: ITranslation;
  data: TNavigationResponse[];
  highlighted: boolean;
  details?: { id: string; type: string };
}

const MarketView = () => {
  const { sportId = '', marketId = '' } = useParams();
  const dispatch = useDispatch();
  const appDispatch = useAppThunkDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);
  const domainLanguage = useSelector(getTranslation);
  const language = useSelector(getLanguage);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const competitionViewSports = useSelector(getDesktopSettingsCompetitionViewSports);
  const tours = useSelector(getPropertiesTours);
  const headerHeight = useSelector(getElementHeightByName(ElementNames.HEADER_HEIGHT));
  const howToSectionHeight = useSelector(getElementHeightByName(ElementNames.HOW_TO_SECTION_HEIGHT));
  const collapseHeight = useSelector(getElementHeightByName(ElementNames.COLLAPSE_HEIGHT));
  const activeMenuHeight = useSelector(getElementHeightByName(ElementNames.ACTIVE_MENU_HEIGHT));
  const navigationHideNavbarButton = useSelector(getElementHeightByName(ElementNames.NAVIGATION_HIDE_NAVBAR_BUTTON));
  const favoritesMenuHeight = useSelector(getElementHeightByName(ElementNames.FAVORITES_MENU_HEIGHT));
  const navigationParentSection = useSelector(getElementHeightByName(ElementNames.NAVIGATION_PARENT_SECTION));
  const navigationParentSportName = useSelector(getElementHeightByName(ElementNames.NAVIGATION_PARENT_SPORT_NAME));
  const navigationAllSportItem = useSelector(getElementHeightByName(ElementNames.NAVIGATION_ALL_SPORT_ITEM));
  const contextualHelp = useSelector(getElementHeightByName(ElementNames.CONTEXTUAL_HELP));
  const status = useSelector(getStatusByMarketPricesId(marketId));
  const market = useSelector(getCompetitionMarket);
  const marketParents = useSelector(getCompetitionMarketParents);
  const eventNameTranslations = useSelector(getEventNameTranslations);
  const eventParents = useSelector(getEventParents);
  const eventData = useSelector(getEventData);
  const sportData = useSelector(getSportData);
  const sportParents = useSelector(getSportParents);
  const sportNameTranslations = useSelector(getSportNameTranslations);
  const marketLoading = useSelector(getMarketLoading);
  const eventLoading = useSelector(getEventDataLoading);
  const sportLoading = useSelector(getSportLoading);
  const raceLoading = useSelector(getRaceLoading);
  const competitionData = useSelector(getCompetitionData);
  const competitionParents = useSelector(getCompetitionParents);
  const competitionLoading = useSelector(getCompetitionLoading);
  const competitionNameTranslations = useSelector(getCompetitionsNameTranslations);
  const sportNameHighlighted = useSelector(getSportNameHighlighted);
  const eventNameHighlighted = useSelector(getEventNameHighlighted);
  const competitionNameHighlighted = useSelector(getCompetitionNameHighlighted);
  const eventDetails = useSelector(getEventDetails);
  const competitionDetails = useSelector(getCompetitionDetails);
  const windowWidth = useSelector(getWindowWidth);
  const windowHeight = useSelector(getWindowHeight);

  const parentsRef = useRef<HTMLDivElement>(null);

  const isRacingSport = RACING_SPORT[sportId];
  const isTennis = sportId === SportIds.TENNIS;
  const isTodayCart = sportId?.startsWith('tc');
  const filteredParents = filterCompetitionViewParents(
    marketParents,
    competitionViewSports,
    sportId,
    isCompetitionViewEnabled
  );
  const isClosedMarket = market.closedDate;
  const lastMarketParentItem = filteredParents[filteredParents.length - 1];
  let contentHeight: string | number = '100%';

  if (device === Devices.DESKTOP && isTodayCart) {
    let collapsesCount = 4;

    if (!isLoggedIn) collapsesCount -= 1;
    if (!tours.length) collapsesCount -= 1;

    const sumToMinus =
      headerHeight +
      (tours.length ? howToSectionHeight : 0) +
      collapseHeight * collapsesCount +
      activeMenuHeight +
      navigationParentSection +
      navigationParentSportName +
      navigationAllSportItem +
      (isLoggedIn ? favoritesMenuHeight : 0) +
      contextualHelp +
      navigationHideNavbarButton;

    contentHeight = windowHeight - sumToMinus;
  }

  const eventLastParent = eventParents.at(-1);
  const showFavStarForNavItem = !(
    eventLastParent?.type === FAVORITES_TYPES.group &&
    isTennis &&
    eventLastParent.name === GAME_BETTING
  );

  useEffect(() => {
    if (marketId && sportId) {
      dispatch(fetchGetMarket(marketId));
      if (isTodayCart) {
        dispatch(fetchGetSports({ sportId }));
      }
    }
  }, [marketId, timezone, isLoggedIn, sportId, isTodayCart, language]);

  useEffect(() => {
    if (lastMarketParentItem?.type) {
      if (
        lastMarketParentItem.type === NavigationTypes.COMPETITION &&
        (market?.competition?.id || market?.event?.id) &&
        !market.closedDate
      ) {
        dispatch(
          fetchCompetitionDetails({
            eventId: market?.competition?.id || market?.event?.id,
            showGroups: isTennis
          })
        );
        return;
      }
      if (market?.event?.id && !isClosedMarket) {
        dispatch(fetchGetEvents({ eventId: market.event.id, showGroups: isTennis }));
      }
    }
  }, [
    market?.competition?.id,
    market?.event?.id,
    timezone,
    isLoggedIn,
    lastMarketParentItem?.type,
    isTodayCart,
    isRacingSport,
    marketParents,
    language
  ]);

  useEffect(() => {
    if (
      RACING_SPORT_WITH_TODAY[market?.eventType?.id] &&
      lastMarketParentItem?.type === NavigationTypes.RACE &&
      !market.closedDate
    ) {
      appDispatch(fetchRace(lastMarketParentItem.id))
        .unwrap()
        .then(race => {
          dispatch(setRace(race));
        });
    }
  }, [lastMarketParentItem, isTodayCart, isRacingSport, language, status, market?.closedDate]);

  useEffect(() => {
    return () => {
      dispatch(setRace(null));
      dispatch(resetSportData());
    };
  }, []);

  useEffect(() => {
    dispatch(
      setElementHeight({
        name: ElementNames.NAVIGATION_PARENT_SECTION,
        height: parentsRef?.current?.clientHeight || 0
      })
    );
  }, [windowWidth, windowHeight, parentsRef]);

  const renderMarketView = ({ parents, name, data, highlighted, details }: IRenderMarket) => {
    const renderParent = isClosedMarket ? [...(parents[0] ? [parents[0]] : [])] : parents;
    if (!renderParent.length && isClosedMarket) {
      renderParent.push(marketParents[0]);
    }

    return (
      <>
        {(marketLoading || eventLoading || sportLoading || raceLoading || competitionLoading) && (
          <NavSkeleton withDelay={150} itemsCount={40} />
        )}
        <div
          className={classNames({
            hidden: marketLoading || eventLoading || sportLoading || raceLoading || competitionLoading
          })}
        >
          <div ref={parentsRef}>
            {renderParent.map(parent => (
              <ParentItem key={parent.id} item={parent} />
            ))}
            {!isClosedMarket && !isEmpty(name) && (
              <NavItemName
                details={details}
                type={TYPE_TO_URL.COMPETITION}
                highlighted={highlighted}
                nameTranslations={name}
                hasFavoritesIcon={showFavStarForNavItem}
              />
            )}
          </div>
          <div
            style={
              device === Devices.DESKTOP && isTodayCart
                ? {
                    height: contentHeight,
                    overflowY: 'auto'
                  }
                : {}
            }
          >
            {isClosedMarket ? (
              <CloseListItem marketName={market.marketName} />
            ) : (
              data.map(d => <ListItem eventId={market.event?.id} item={d} domainLanguage={domainLanguage} key={d.id} />)
            )}
          </div>
        </div>
      </>
    );
  };

  if (isTodayCart) {
    return renderMarketView({
      parents: filterCompetitionViewParents(sportParents, competitionViewSports, sportId, isCompetitionViewEnabled),
      data: sportData,
      name: sportNameTranslations,
      highlighted: sportNameHighlighted
    });
  }
  if (lastMarketParentItem?.type === NavigationTypes.COMPETITION) {
    return renderMarketView({
      parents: filterCompetitionViewParents(
        competitionParents,
        competitionViewSports,
        sportId,
        isCompetitionViewEnabled
      ),
      data: competitionData,
      name: competitionNameTranslations,
      highlighted: competitionNameHighlighted,
      details: competitionDetails
    });
  }
  return renderMarketView({
    parents: filterCompetitionViewParents(eventParents, competitionViewSports, sportId, isCompetitionViewEnabled),
    data: eventData,
    name: eventNameTranslations,
    highlighted: eventNameHighlighted,
    details: eventDetails
  });
};

export default MarketView;
