import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { groupBy, isString } from 'lodash';

import { PageBlocks, SLICES_NAMES } from 'constants/app';
import {
  BetErrors,
  BetFocusFields,
  BETSLIP_NOTIFICATIONS_STORAGE_NAME,
  BETSLIP_SELECTED_BETS_STORAGE_NAME,
  BETSLIP_SELECTED_MARKETS_LIMIT,
  BetslipTabs,
  BetslipTypes,
  PlaceBetsStates,
  PlacementTypes
} from 'constants/betslip';
import { BetSides } from 'constants/myBets';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { TPlaceBetsDataPayload, TPlacedBetsByMarket, TPlacementError } from 'redux/modules/placement/type';
import { TFailureActionPayload } from 'types';
import { BetslipType, PlacementType } from 'types/betslip';
import { BetSide } from 'types/myBets';
import { checkUniqSelectedBetKey, getSelectedBetKey, getUniqSelectedBetKey } from 'utils/betslip';
import { getMarketInfoFromBet } from 'utils/inlinePlacement';

import {
  BetError,
  BetslipTab,
  DutchingCalculator,
  PlaceBetsState,
  PlacementNotificationsMap,
  SelectedBetState,
  TBetslipState,
  TSelectedBet,
  TSelectedBetsByMarket,
  TSelectedBetsBySelection,
  TSelectedBetsByTypes,
  TSelectedBetState,
  TSelectedBetUpdateData,
  TSelectedMarketInfo,
  TSelectedMarketStates
} from './type';

const initialState: TBetslipState = {
  collapse: false,
  activeTab: BetslipTabs.PLACE_BETS,
  selectedBets: {},
  placedBets: {},
  placementNotifications: {},
  placedBetsToUpdate: {},
  placeBetsState: PlaceBetsStates.SELECT,
  loading: false,
  consolidateBets: false,
  betslipType: BetslipTypes.EXCHANGE,
  isFirstOpenedBetFocused: false,
  rgErrorMessage: null,
  liabilityByMarket: {},
  selectedMarketsLimitNotification: false,
  unmatchedOffersWithErrors: {},
  selectedBetsUuids: [],
  focusedButton: null,
  checkMarketsError: null,
  checkMarketsLoading: true,
  unmatchedBetsProgresses: {},
  dutchingCalculatorsByMarketIdAndType: {}
};

const slice = createSlice({
  name: SLICES_NAMES.BET_SLIP,
  initialState,
  reducers: {
    setCollapseState: (state, { payload }: PayloadAction<boolean>) => {
      state.collapse = payload;
    },
    setActiveTab: (state, { payload }: PayloadAction<BetslipTab>) => {
      state.activeTab = payload;
      state.loading = false;

      if (payload === BetslipTabs.PLACE_BETS) {
        state.placedBets = {};
      }
    },
    setSelectedBets: (
      state,
      { payload }: PayloadAction<{ selectedBets: TSelectedBet[]; marketInfo?: TSelectedMarketInfo }>
    ) => {
      const groupByMarketId = groupBy(payload.selectedBets, 'marketId');

      state.placeBetsState = PlaceBetsStates.SELECT;
      state.collapse = false;

      const newSelectedBets: TSelectedBetsByMarket = { ...state.selectedBets };

      Object.entries(groupByMarketId).forEach(([marketId, bets]) => {
        const isBetAllSelected = bets.every(({ isBetAll }) => isBetAll);
        const selectedTypesByMarket: TSelectedBetsByMarket = newSelectedBets[marketId]?.bets ?? {};

        const isAllBetsSelectedInStorage =
          isBetAllSelected &&
          bets.every(betItem =>
            Object.keys(selectedTypesByMarket[betItem.type] ?? {}).some(selectedBetKey =>
              selectedBetKey.includes(getSelectedBetKey(betItem))
            )
          );

        bets.forEach((bet, index) => {
          if (!newSelectedBets[marketId] && payload.marketInfo) {
            newSelectedBets[marketId] = { marketInfo: payload.marketInfo };
          }

          // Moved from Inline Placement
          if (!newSelectedBets[marketId] && !payload.marketInfo) {
            newSelectedBets[marketId] = { marketInfo: getMarketInfoFromBet(bet) };
          }

          /**
           * Map selected bets by selection id and handicap values.
           * This combination is uniq for handicap double line markets.
           */
          const betKey = bet.betUuid || getUniqSelectedBetKey(bet);
          const selectedBetsByMarket: TSelectedBetsByTypes = newSelectedBets[marketId]?.bets ?? {};
          const selectedBetsByType: TSelectedBetsBySelection = selectedBetsByMarket[bet.type as BetSide] ?? {};
          let selectedBet: TSelectedBet | undefined;

          Object.entries(selectedBetsByType).forEach(([key, item]) => {
            if (checkUniqSelectedBetKey({ betKey, ...item })) {
              selectedBet = item;
              delete selectedBetsByType[key];
            }
          });

          if (
            !selectedBet ||
            (selectedBet &&
              (!bet.isBetAll || !isAllBetsSelectedInStorage) &&
              // Compare bets with initial prices before min valid value is set for LINE market
              (selectedBet.hasOwnProperty('initPrice')
                ? selectedBet.initPrice !== bet.price
                : selectedBet.price !== bet.price)) ||
            (bet.isBetAll && !isAllBetsSelectedInStorage)
          ) {
            newSelectedBets[marketId].bets = {
              ...selectedBetsByMarket,
              [bet.type]: {
                ...(selectedBetsByMarket[bet.type as BetSide] ?? {}),
                [betKey]: {
                  ...((selectedBetsByMarket[bet.type as BetSide] ?? {})[betKey] ?? {}),
                  ...bet,
                  betUuid: betKey,
                  isBetAll: false,
                  isPriceValid: bet.price !== '',
                  isSizeValid: !!Number(bet.size),
                  isValid: false,
                  state: bet.state || SelectedBetState.NONE,
                  focusedField: index === bets.length - 1 ? BetFocusFields.SIZE : null,
                  error: bet.error || null
                }
              }
            };
          }
        });
      });

      Object.entries(newSelectedBets).forEach(([curMarketId, { bets }]) => {
        if (!Object.keys(bets?.[BetSides.Back] ?? {}).length && !Object.keys(bets?.[BetSides.Lay] ?? {}).length) {
          delete newSelectedBets[curMarketId];
        }
      });

      if (Object.keys(newSelectedBets).length <= BETSLIP_SELECTED_MARKETS_LIMIT) {
        state.selectedMarketsLimitNotification = false;
        state.selectedBets = newSelectedBets;
        if (state.betslipType === BetslipTypes.EXCHANGE) {
          localStorage.setItem(BETSLIP_SELECTED_BETS_STORAGE_NAME, JSON.stringify(newSelectedBets));
        }
      } else {
        state.selectedMarketsLimitNotification = true;
      }

      let selectedBetsUuids: string[] = [];

      Object.entries(newSelectedBets).forEach(([, { bets }]) => {
        selectedBetsUuids = [
          ...selectedBetsUuids,
          ...Object.entries(bets?.[BetSides.Lay] ?? {}).map(([betUuid]) => betUuid),
          ...Object.entries(bets?.[BetSides.Back] ?? {}).map(([betUuid]) => betUuid)
        ];
      });

      state.selectedBetsUuids = selectedBetsUuids.reverse();
    },
    updateSelectedBet: (state, { payload }: PayloadAction<TSelectedBetUpdateData>) => {
      const bet = payload.betUuid
        ? state.selectedBets[payload.marketId]?.bets?.[payload.type]?.[payload.betUuid]
        : undefined;
      const betKey = payload.betUuid || (bet && getUniqSelectedBetKey(bet)) || '';
      const selectedBetsStorageString = localStorage.getItem(BETSLIP_SELECTED_BETS_STORAGE_NAME);
      const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};
      const selectedBetsByMarket = state.selectedBets[payload.marketId]?.bets ?? {};
      const oppositeType = payload.type === BetSides.Back ? BetSides.Lay : BetSides.Back;
      const oppositeBets: TSelectedBetsBySelection = state.selectedBets[payload.marketId]?.bets?.[oppositeType] ?? {};
      const selectedBetKey = bet ? getSelectedBetKey(bet) : undefined;
      const oppositeBet = Object.values(oppositeBets).find(
        ({ betUuid }: TSelectedBet) => betUuid && selectedBetKey && betUuid.includes(selectedBetKey)
      );

      /**
       * Error occurs when back and a lay for the same selection at overlapping prices.
       */
      const isBackLessLayError = () => {
        if (
          bet?.price &&
          oppositeBet &&
          oppositeBet.price &&
          bet?.state !== SelectedBetState.PROGRESS &&
          oppositeBet.state !== SelectedBetState.PROGRESS
        ) {
          if (payload.type === BetSides.Back) {
            return bet.price <= oppositeBet.price;
          } else {
            return oppositeBet.price <= bet.price;
          }
        } else {
          return false;
        }
      };

      if (bet && betKey) {
        const selectedBets = {
          ...current(state.selectedBets[bet.marketId]),
          bets: {
            ...selectedBetsByMarket,
            [bet.type]: {
              ...(selectedBetsByMarket[bet.type as BetSide] ?? {}),
              [betKey]: {
                ...((selectedBetsByMarket[bet.type as BetSide] ?? {})[betKey] ?? {}),
                ...payload,
                ...(isBackLessLayError() ? { error: BetErrors.EX015 } : { error: null })
              }
            },
            ...(oppositeBet
              ? {
                  [oppositeType]: {
                    ...(selectedBetsByMarket[oppositeType] ?? {}),
                    [oppositeBet.betUuid ?? betKey]: {
                      ...oppositeBet,
                      ...(isBackLessLayError() ? { error: BetErrors.EX015 } : { error: null })
                    }
                  }
                }
              : {})
          }
        };

        state.selectedBets[payload.marketId] = selectedBets;

        if (state.betslipType === BetslipTypes.EXCHANGE) {
          selectedBetsFromStorage[payload.marketId] = selectedBets;
          localStorage.setItem(BETSLIP_SELECTED_BETS_STORAGE_NAME, JSON.stringify(selectedBetsFromStorage));
        }
      }
    },
    updateSelectedBetsByMarket: (
      state,
      { payload }: PayloadAction<{ marketId: string; data: { error?: BetError | null } }>
    ) => {
      const { marketId, data } = payload;

      Object.entries(state.selectedBets[marketId]?.bets ?? {}).forEach(([betType, betsByType]) => {
        Object.entries(betsByType || {}).forEach(([key]) => {
          Object.assign(state.selectedBets[marketId]?.bets?.[betType as BetSide]?.[key] ?? {}, data);
        });
      });
    },
    updateSelectedBetsWithData: (
      state,
      { payload }: PayloadAction<{ selectedBets: TSelectedBet[]; data: { state: TSelectedBetState | null } }>
    ) => {
      payload.selectedBets.forEach(({ marketId, betUuid, type }) => {
        const selectedBet = betUuid ? state.selectedBets[marketId]?.bets?.[type]?.[betUuid] : undefined;
        if (selectedBet) {
          Object.assign(selectedBet, payload.data);
        }
      });

      if (state.betslipType === BetslipTypes.EXCHANGE) {
        localStorage.setItem(BETSLIP_SELECTED_BETS_STORAGE_NAME, JSON.stringify({ ...state.selectedBets }));
      }
    },
    removeSelectedBet: (state, { payload }: PayloadAction<TSelectedBet>) => {
      const betKey = payload.betUuid || getUniqSelectedBetKey(payload);
      const selectedBetsStorageString = localStorage.getItem(BETSLIP_SELECTED_BETS_STORAGE_NAME);
      const selectedBetsFromStorage = selectedBetsStorageString ? JSON.parse(selectedBetsStorageString) : {};
      const selectedBetsByMarket = state.selectedBets[payload.marketId]?.bets ?? {};
      const selectedBetsByType = selectedBetsByMarket[payload.type as BetSide] ?? {};
      const oppositeType = payload.type === BetSides.Back ? BetSides.Lay : BetSides.Back;
      const oppositeBets: TSelectedBetsBySelection = state.selectedBets[payload.marketId]?.bets?.[oppositeType] ?? {};
      const oppositeBet = Object.values(oppositeBets).find(
        ({ betUuid }: TSelectedBet) => betUuid && betUuid.includes(getSelectedBetKey(payload))
      );
      const { [betKey]: removedSelectedBet, ...restSelectedBets } = selectedBetsByType;

      const selectedBets = {
        ...state.selectedBets[payload.marketId],
        bets: {
          ...selectedBetsByMarket,
          [payload.type]: {
            ...restSelectedBets
          },
          ...(oppositeBet?.betUuid
            ? {
                [oppositeType]: {
                  ...(selectedBetsByMarket[oppositeType] ?? {}),
                  [oppositeBet.betUuid]: {
                    ...oppositeBet,
                    ...{ error: null }
                  }
                }
              }
            : {})
        }
      };

      if (
        Object.keys(selectedBets.bets?.[BetSides.Back] ?? {}).length > 0 ||
        Object.keys(selectedBets.bets?.[BetSides.Lay] ?? {}).length > 0
      ) {
        state.selectedBets[payload.marketId] = selectedBets;
        selectedBetsFromStorage[payload.marketId] = selectedBets;
      } else {
        delete state.selectedBets[payload.marketId];
        delete selectedBetsFromStorage[payload.marketId];
      }

      if (state.betslipType === BetslipTypes.EXCHANGE) {
        localStorage.setItem(BETSLIP_SELECTED_BETS_STORAGE_NAME, JSON.stringify(selectedBetsFromStorage));
      }
    },
    removeAllSelectedBets: state => {
      state.selectedBets = {};
      localStorage.removeItem(BETSLIP_SELECTED_BETS_STORAGE_NAME);
    },
    removeSelectedBetsByMarket: (state, { payload }: PayloadAction<{ marketId: string }>) => {
      if (state.selectedBets[payload.marketId]) {
        state.selectedBets[payload.marketId].bets = {};
      }
    },
    removeSelectedMarket: (state, { payload }: PayloadAction<{ marketId: string }>) => {
      delete state.selectedBets[payload.marketId];
    },
    removeEmptySelectedMarkets: state => {
      Object.keys(state.selectedBets).forEach(marketId => {
        if (
          !Object.keys(state.selectedBets[marketId]?.bets?.[BetSides.Back] ?? {}).length &&
          !Object.keys(state.selectedBets[marketId]?.bets?.[BetSides.Lay] ?? {}).length
        ) {
          delete state.selectedBets[marketId];
        }
      });
    },
    setPlaceBetsState: (state, { payload }: PayloadAction<PlaceBetsState>) => {
      state.placeBetsState = payload;
    },
    setPlacementNotifications: (state, { payload }: PayloadAction<TSelectedBet[]>) => {
      payload.forEach(bet => {
        if (bet.betUuid) {
          state.placementNotifications[bet.betUuid] = {
            bet,
            betUuid: bet.betUuid,
            error: null,
            marketId: bet.marketId
          };
        }
      });

      localStorage.setItem(BETSLIP_NOTIFICATIONS_STORAGE_NAME, JSON.stringify({ ...state.placementNotifications }));
    },
    updatePlacementNotifications: (state, { payload }: PayloadAction<TPlacedBetsByMarket>) => {
      Object.entries(payload).forEach(([, { betUuids = [], status, exception, offerIds = {} }]) => {
        betUuids.forEach(betUuid => {
          if (status === 'FAIL' && exception && state.placementNotifications[betUuid]) {
            Object.assign(state.placementNotifications[betUuid], {
              error: exception
            });
          } else if (offerIds[betUuid] && state.placementNotifications[betUuid]) {
            Object.assign(state.placementNotifications[betUuid], {
              offerId: offerIds[betUuid]
            });
          }
        });
      });

      localStorage.setItem(BETSLIP_NOTIFICATIONS_STORAGE_NAME, JSON.stringify({ ...state.placementNotifications }));
    },
    addOffersForPlacementNotifications: (
      state,
      { payload }: PayloadAction<{ betUuid: string; offer: TCurrentBet }[]>
    ) => {
      payload.forEach(({ offer, betUuid }) => {
        state.placementNotifications[betUuid].offer = offer;
      });
    },
    addErrorForPlacementNotifications: (state, { payload }: PayloadAction<{ betUuid: string; error: string }[]>) => {
      payload.forEach(({ error, betUuid }) => {
        state.placementNotifications[betUuid].error = error;
      });
    },
    setPlacementNotificationsError: (
      state,
      { payload }: PayloadAction<{ error: TPlacementError; bets?: TPlaceBetsDataPayload; offerIds?: number[] }>
    ) => {
      const placementNotifications = {} as PlacementNotificationsMap;

      if (payload.bets) {
        const bets = payload.bets;
        Object.keys(bets).forEach(marketId => {
          bets[marketId].forEach(bet => {
            const betUuid = bet.betUuid;
            const hasBackBet = !!state.selectedBets[marketId]?.bets?.[BetSides.Back]?.[betUuid];
            const placeBet = {
              ...state.selectedBets[marketId]?.bets?.[hasBackBet ? BetSides.Back : BetSides.Lay]?.[betUuid]
            } as TSelectedBet;
            placementNotifications[betUuid] = {
              offerId: null,
              marketId,
              error: payload.error.toString(),
              bet: placeBet,
              betUuid
            };
          });
        });
      }

      if (payload.offerIds) {
        const betUuids = Object.keys(state.placementNotifications);
        betUuids.forEach(betUuid => {
          const placeBet = state.placementNotifications[betUuid];
          const offerId = placeBet?.offerId;
          if (payload.offerIds?.includes(+(offerId || 0))) {
            placementNotifications[betUuid] = {
              offerId: placeBet.offerId,
              marketId: placeBet.marketId,
              error: payload.error.toString(),
              bet: placeBet.bet,
              betUuid
            };
          }
        });
      }

      Object.assign(state.placementNotifications, placementNotifications);
      localStorage.setItem(BETSLIP_NOTIFICATIONS_STORAGE_NAME, JSON.stringify({ ...state.placementNotifications }));
    },
    removeAllPlacementNotifications: state => {
      state.placementNotifications = {};
      localStorage.removeItem(BETSLIP_NOTIFICATIONS_STORAGE_NAME);
    },
    setBetslipLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setPlacedBets: (state, { payload }: PayloadAction<number[]>) => {
      payload.forEach(offerId => {
        state.placedBets[offerId] = PlacementTypes.PLACE;
      });
    },
    setUpdatedBets: (state, { payload }: PayloadAction<number[]>) => {
      payload.forEach(offerId => {
        state.placedBets[offerId] = PlacementTypes.UPDATE;
      });
    },
    removePlacedBet: (state, { payload }: PayloadAction<number>) => {
      delete state.placedBets[payload];
    },
    setConsolidateBets: (state, { payload }: PayloadAction<boolean>) => {
      state.consolidateBets = payload;
    },
    setPlacedBetsToUpdate: (state, { payload }: PayloadAction<{ offerId: number; placementType: PlacementType }>) => {
      state.placedBetsToUpdate[payload.offerId] = payload.placementType;
    },
    removePlacedBetsToUpdate: (state, { payload }: PayloadAction<number>) => {
      delete state.placedBetsToUpdate[payload];
    },
    setBetslipType: (state, { payload }: PayloadAction<BetslipType>) => {
      state.betslipType = payload;
    },
    setFirstOpenedBetFocused: (state, { payload }: PayloadAction<boolean>) => {
      state.isFirstOpenedBetFocused = payload;
    },
    setRGErrorMessage: (state, { payload }: PayloadAction<TPlacementError | null | string>) => {
      state.rgErrorMessage = payload;
    },
    setLiabilityByMarket: (state, { payload }: PayloadAction<{ marketId: string; liability: number }>) => {
      state.liabilityByMarket[payload.marketId] = payload.liability;
    },
    setSelectedMarketsLimitNotification: (state, { payload }: PayloadAction<boolean>) => {
      state.selectedMarketsLimitNotification = payload;
    },
    setSelectedMarketState: (
      state,
      { payload }: PayloadAction<{ marketId: string; state: TSelectedMarketStates | null }>
    ) => {
      const { marketId, state: marketState } = payload;

      if (state.selectedBets[marketId]) {
        state.selectedBets[marketId].state = marketState;
      }
    },
    setSelectedBetsState: (
      state,
      { payload }: PayloadAction<{ betUuid: string; marketId: string; type: BetSide; state: TSelectedBetState }[]>
    ) => {
      payload.forEach(({ marketId, type, betUuid, state: betState }) => {
        if (state.selectedBets[marketId]?.bets?.[type]?.[betUuid]) {
          state.selectedBets[marketId].bets![type]![betUuid].state = betState;
        }
      });
    },
    setBetSlipUnmatchedOfferError: (
      state,
      { payload }: PayloadAction<{ offerId: number; error: string | TPlacementError }>
    ) => {
      state.unmatchedOffersWithErrors[payload.offerId] = isString(payload.error)
        ? payload.error
        : payload.error?.response?.data?.message ?? '';
    },
    setErrorForUnmatchedOffers: (
      state,
      { payload }: PayloadAction<{ offerIds: number[]; error: string | TPlacementError }>
    ) => {
      payload.offerIds.forEach(offerId => {
        state.unmatchedOffersWithErrors[offerId] = isString(payload.error)
          ? payload.error
          : payload.error?.response?.data?.message ?? '';
      });
    },
    removeBetSlipUnmatchedOfferError: (state, { payload }: PayloadAction<number>) => {
      delete state.unmatchedOffersWithErrors[payload];
    },
    removeAllSportsSelectedBets: state => {
      Object.entries(state.selectedBets).forEach(([marketId, betsByMarket]) => {
        Object.entries(betsByMarket.bets ?? {}).forEach(([betType, betsByType]) => {
          Object.entries(betsByType).forEach(([betUuid, bet]) => {
            if (bet.pageBlock !== PageBlocks.GAME) {
              if (state.selectedBets[marketId].bets?.[betType as BetSide]?.[betUuid]) {
                delete state.selectedBets[marketId].bets![betType as BetSide]![betUuid];
              }
            }
          });
        });
      });

      Object.entries(state.selectedBets).forEach(([marketId, betsByMarket]) => {
        const backBets = betsByMarket.bets?.[BetSides.Back];
        const layBets = betsByMarket.bets?.[BetSides.Lay];

        const backBetsCount = backBets ? Object.keys(backBets).length : 0;
        const layBetsCount = layBets ? Object.keys(layBets).length : 0;

        if (backBetsCount === 0 && layBetsCount === 0) {
          delete state.selectedBets[marketId];
        }
      });

      localStorage.removeItem(BETSLIP_SELECTED_BETS_STORAGE_NAME);
    },
    removeAllGamesSelectedBets: state => {
      Object.entries(state.selectedBets).forEach(([marketId, betsByMarket]) => {
        Object.entries(betsByMarket.bets ?? {}).forEach(([betType, betsByType]) => {
          Object.entries(betsByType).forEach(([betUuid, bet]) => {
            if (bet.pageBlock === PageBlocks.GAME) {
              if (state.selectedBets[marketId].bets?.[betType as BetSide]?.[betUuid]) {
                delete state.selectedBets[marketId].bets![betType as BetSide]![betUuid];
              }
            }
          });
        });
      });

      Object.entries(state.selectedBets).forEach(([marketId, betsByMarket]) => {
        const backBets = betsByMarket.bets?.[BetSides.Back];
        const layBets = betsByMarket.bets?.[BetSides.Lay];

        const backBetsCount = backBets ? Object.keys(backBets).length : 0;
        const layBetsCount = layBets ? Object.keys(layBets).length : 0;

        if (backBetsCount === 0 && layBetsCount === 0) {
          delete state.selectedBets[marketId];
        }
      });
    },
    removeIsCancelledFromAllSelectedBets: state => {
      Object.entries(state.selectedBets).forEach(([marketId, betsByMarket]) => {
        Object.entries(betsByMarket.bets ?? {}).forEach(([betType, betsByType]) => {
          Object.entries(betsByType).forEach(([betUuid, bet]) => {
            if (bet.isCancelled) {
              if (state.selectedBets[marketId].bets?.[betType as BetSide]?.[betUuid]) {
                state.selectedBets[marketId].bets![betType as BetSide]![betUuid]!.isCancelled = false;
              }
            }
          });
        });
      });
    },
    setBetslipFocusedButton: (state, { payload }: PayloadAction<'place' | 'confirm' | null>) => {
      state.focusedButton = payload;
    },
    checkSportsMarkets: (
      _,
      __: PayloadAction<{ selectedBets: TSelectedBetsByMarket; placementNotifications: PlacementNotificationsMap }>
    ) => {},
    setSportsSelectedBetsAndNotificationsFromStorage: (
      state,
      {
        payload
      }: PayloadAction<{ selectedBets: TSelectedBetsByMarket; placementNotifications: PlacementNotificationsMap }>
    ) => {
      state.selectedBets = payload.selectedBets;
      state.placementNotifications = payload.placementNotifications;
      state.checkMarketsLoading = false;
    },
    setCheckMarketsError: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.checkMarketsError = payload;
      state.checkMarketsLoading = false;
    },
    setCheckMarketsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.checkMarketsLoading = payload;
    },
    setUnmatchedBetProgress: (state, { payload }: PayloadAction<{ offerId: number; progress: number }>) => {
      state.unmatchedBetsProgresses[payload.offerId] = payload.progress;
    },
    setDutchingCalculatorData: (
      state,
      { payload }: PayloadAction<{ marketId: string; type: BetSide; data: Partial<DutchingCalculator> }>
    ) => {
      if (!state.dutchingCalculatorsByMarketIdAndType[payload.marketId]) {
        state.dutchingCalculatorsByMarketIdAndType[payload.marketId] = {};
      }

      if (!state.dutchingCalculatorsByMarketIdAndType[payload.marketId][payload.type]) {
        state.dutchingCalculatorsByMarketIdAndType[payload.marketId][payload.type] = {} as DutchingCalculator;
      }

      Object.assign(state.dutchingCalculatorsByMarketIdAndType[payload.marketId][payload.type], payload.data);
    },
    closeDutchingCalculators: state => {
      Object.entries(state.dutchingCalculatorsByMarketIdAndType).forEach(([marketId, byType]) =>
        Object.entries(byType).forEach(([type, calculator]) => {
          if (calculator.isOpened) {
            state.dutchingCalculatorsByMarketIdAndType[marketId][type].isOpened = false;
          }
        })
      );
    }
  }
});

export const {
  setFirstOpenedBetFocused,
  setPlaceBetsState,
  setPlacedBets,
  setPlacedBetsToUpdate,
  removePlacedBetsToUpdate,
  removeSelectedBet,
  removeAllSelectedBets,
  removeSelectedBetsByMarket,
  removeSelectedMarket,
  removeEmptySelectedMarkets,
  removePlacedBet,
  updateSelectedBet,
  setConsolidateBets,
  setSelectedBets,
  setUpdatedBets,
  setBetslipType,
  setCollapseState,
  setRGErrorMessage,
  setBetslipLoading,
  setPlacementNotificationsError,
  removeAllPlacementNotifications,
  updatePlacementNotifications,
  setActiveTab,
  setLiabilityByMarket,
  setSelectedMarketsLimitNotification,
  updateSelectedBetsByMarket,
  updateSelectedBetsWithData,
  setSelectedMarketState,
  setBetSlipUnmatchedOfferError,
  setErrorForUnmatchedOffers,
  removeBetSlipUnmatchedOfferError,
  removeAllGamesSelectedBets,
  removeAllSportsSelectedBets,
  removeIsCancelledFromAllSelectedBets,
  setBetslipFocusedButton,
  setPlacementNotifications,
  addOffersForPlacementNotifications,
  setSelectedBetsState,
  addErrorForPlacementNotifications,
  checkSportsMarkets,
  setSportsSelectedBetsAndNotificationsFromStorage,
  setCheckMarketsError,
  setCheckMarketsLoading,
  setUnmatchedBetProgress,
  setDutchingCalculatorData,
  closeDutchingCalculators
} = slice.actions;

export default slice.reducer;
