import useBetLabels from 'hooks/useBetLabels';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { THandicap, TPrice, TSize } from 'types/bets';
import { LineSide } from 'types/betslip';
import { TMarketLineRangeInfo } from 'types/markets';
import { BetSide } from 'types/myBets';
import { getCalculatedParams, getSpreadsAndTotalsProfitLoss } from 'utils/betLabels';

import BetLabelsTpl from '../BetLabelsTpl';

type SpreadsAndTotalsLabelProps = {
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetSide;
  eventTypeId?: string;
  handicapType?: LineSide | null;
  lineRangeInfo: TMarketLineRangeInfo | null;
  currency?: string;
};

const SpreadsAndTotalsLabel = ({
  price,
  size,
  handicap,
  betType,
  handicapType,
  lineRangeInfo,
  currency
}: SpreadsAndTotalsLabelProps) => {
  const { NPointsOrLess, NPointsOrMore, ExactlyNPoints } = useBetLabels();

  const { handicapValue } = getCalculatedParams({ price, size, handicap, betType });

  const isIntHandicap = handicapValue % 1 === 0;
  const refundValue = isIntHandicap ? handicapValue : 0;

  const marketUnitTranslated = useMarketUnits(lineRangeInfo?.marketUnit ?? 'points', refundValue);

  const winValue = isIntHandicap ? handicapValue - 1 : Math.floor(handicapValue);
  const loseValue = isIntHandicap ? handicapValue + 1 : Math.ceil(handicapValue);

  const profitLossS: number | null = price && size && isIntHandicap ? 0 : null;

  const labelW = NPointsOrLess(winValue, marketUnitTranslated.toLowerCase());
  const labelL = NPointsOrMore(loseValue, marketUnitTranslated.toLowerCase());
  const labelS = isIntHandicap ? ExactlyNPoints(refundValue, marketUnitTranslated.toLowerCase()) : '';

  const { profitLossW, profitLossL } = getSpreadsAndTotalsProfitLoss({ price, size, betType, handicapType });

  return (
    <BetLabelsTpl
      betType={betType}
      labelW={labelW}
      labelS={labelS}
      labelL={labelL}
      profitLossW={profitLossW}
      profitLossS={profitLossS}
      profitLossL={profitLossL}
      currency={currency}
    />
  );
};

export default SpreadsAndTotalsLabel;
