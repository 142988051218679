import { RefObject, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Collapse from 'components/Collapse';
import { ElementNames } from 'constants/app';
import useElementSize from 'hooks/useElementSize';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { ACTIVE_MARKETS_COLLAPSE, LEFT_PANEL_COLLAPSE_GROUP } from 'redux/modules/collapse/constants';

import Content from './components/Content';

interface ActiveMarketsSectionProps {
  activeMarketsWrapperRef: RefObject<HTMLDivElement>;
}

const ActiveMarketsSection = ({ activeMarketsWrapperRef }: ActiveMarketsSectionProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const activeMarketsContentHeight = useSelector(getElementHeightByName(ElementNames.ACTIVE_MARKETS_CONTENT_HEIGHT));

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.ACTIVE_MENU_TITLE_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.ACTIVE_MENU_TITLE_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div className="biab_active-markets-nav" ref={activeMarketsWrapperRef}>
      <Collapse
        group={LEFT_PANEL_COLLAPSE_GROUP}
        collapse={ACTIVE_MARKETS_COLLAPSE}
        childHeightKey={ElementNames.ACTIVE_MENU_HEIGHT}
        title={t('navigation.activeMarkets')}
        componentRef={componentRef}
      >
        <div style={{ overflow: 'auto', maxHeight: activeMarketsContentHeight }}>
          <Content />
        </div>
      </Collapse>
    </div>
  );
};

export default ActiveMarketsSection;
