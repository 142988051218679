import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ElementNames } from 'constants/app';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { generateArray } from 'utils/general';

import DelayedItem from '../DelayedItem';
import Skeleton from '../index';

import styles from './styles.module.scss';

interface IMiddleSectionPageSkeleton {
  itemsCount?: number;
  withDelay?: number;
  isAbsolute?: boolean;
}

const Content = () => {
  return (
    <div className={styles.middleSectionSkeletonItemWrapper}>
      <div className={styles.middleSectionLeftSideWrapper}>
        <Skeleton className={styles.middleSectionSkeletonItem} />
        <Skeleton className={styles.middleSectionSkeletonItem} />
      </div>
      <div className={styles.middleSectionRightSideWrapper}>
        <Skeleton className={styles.middleSectionSkeletonItem} />
      </div>
    </div>
  );
};

const MiddleSectionPageSkeleton = ({
  itemsCount = 10,
  withDelay = 0,
  isAbsolute = true
}: IMiddleSectionPageSkeleton) => {
  const headerHeight = useSelector(getElementHeightByName(ElementNames.HEADER_HEIGHT));
  const searchInputHeight = useSelector(getElementHeightByName(ElementNames.SEARCH_INPUT_HEIGHT));

  const items = generateArray(itemsCount);

  return (
    <div
      className={classNames({
        [styles.skeletonContainerAbsolute]: isAbsolute
      })}
      style={{ height: `calc(100vh - ${headerHeight + searchInputHeight})` }}
    >
      {items.map((i, index) => {
        return withDelay ? (
          <DelayedItem delay={index * withDelay} key={index} item={<Content />} />
        ) : (
          <Content key={index} />
        );
      })}
    </div>
  );
};

export default MiddleSectionPageSkeleton;
