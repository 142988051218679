import { To } from 'react-router-dom';

import {
  BetContentGroupTypes,
  BettingTypes,
  DesktopTopMarketViewTypes,
  MarketDepthViews,
  MarketOddsBannerLocationTypes,
  MarketsTableColumns,
  MarketsTableHeaderTypes,
  PriceLadderDescriptions,
  RunnerStatuses,
  ViewTypes
} from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import { TContentParent } from 'redux/modules/search/type';

import { LineSide } from './betslip';
import { FancyMarketType, MarketStatus, TimeFilter } from './index';

export type TMarketSportInfo = {
  id: string;
  name: string;
  eventTypeId?: string | null;
  availableTimeFilters?: TimeFilter[];
};

export type TMarketRunner = {
  selectionId: number;
  runnerName: string;
  runnerNameTranslations?: Record<string, string>;
  handicap: number;
  lineSide: LineSide | null;
  metadata: {
    runnerId: string;
    CLOTH_NUMBER?: string;
    JOCKEY_NAME?: string;
    COLOURS_FILENAME?: string;
    CLOTH_NUMBER_ALPHA?: string;
    STALL_DRAW?: string;
    AGE?: string;
    DAYS_SINCE_LAST_RUN?: string;
    FORM?: string;
    WEARING?: string;
    TRAINER_NAME?: string;
    WEIGHT_VALUE?: string;
    JOCKEY_CLAIM?: string;
    COLOURS_DESCRIPTION?: string;
  };
  runnerStatus?: string | null;
  seeAllMarkets?: boolean;
  keyLine?: boolean;
  status?: RunnerStatus;
};

export interface IMarketDescription {
  persistenceEnabled: boolean;
  bspMarket: boolean;
  marketTime: number;
  suspendTime: number;
  settleTime: null | number;
  bettingType: BettingType;
  turnInPlayEnabled: boolean;
  marketType: string;
  regulator: string;
  marketBaseRate: number;
  discountAllowed: boolean;
  wallet: string;
  rules: string;
  rulesHasDate: boolean;
  clarifications: null;
  eachWayDivisor: number | null;
  lineRangeInfo: TMarketLineRangeInfo;
  priceLadderDescription: TPriceLadderDescription;
  gameType?: string;
}

interface IRaceDetailsGoing {
  name: string;
  shortName: string;
}

export interface IRaceDetails {
  distance: string;
  going: IRaceDetailsGoing;
  raceId: string;
  raceType: string;
}

export interface IStatisticsHistory {
  bsp: number;
  course: string;
  date: string;
  distance: string;
  going: IRaceDetailsGoing;
  ipHiLo: string;
  jockey: string;
  or: number;
  pos: string;
  runnerName: string;
  selectionId: number;
  type: string;
}

export interface IRunnersStatistics {
  age: number;
  daysSinceLastRace: number;
  form: string;
  gear: string;
  history: IStatisticsHistory[];
  jockeyClaim: string;
  officialRating: number;
  owner: string;
  pedigree: string;
  runnerComments: string;
  runnerName: string;
  selectionId: number;
  timeformRating: number;
  trainer: string;
  verdictDescription: string;
  weight: string;
}

export type IRunnersStatisticsMap = Omit<IRunnersStatistics, 'history'>;

export interface ITimeForm123 {
  runnerName: string;
  selectionId: number;
  silkDescription: string;
  silkFileName: string;
  stars: number;
}

export interface ITimeFormComments {
  comment: string;
  number: number;
  runnerName: string;
  selectionId: number;
}

interface ITimeFormVerdict {
  timeform123: ITimeForm123[];
  timeformCashOurHints: string;
  timeformComments: ITimeFormComments[];
  timeformView: string;
}

export interface ITimeFormStatistics {
  raceDetails: IRaceDetails;
  runnersStatistics: IRunnersStatistics[];
  verdict: ITimeFormVerdict;
}

export interface IMarket {
  marketId: string;
  marketName: string;
  marketNameTranslations?: Record<string, string>;
  marketStartTime: number;
  /**
   *   This property doesn't exist in the backend response, can be added for extra manipulations with markets
   *   (f.e. find unique dates for collapses)
   */
  parsedMarketStartTime?: string;
  description: IMarketDescription;
  totalMatched: number;
  runners: TMarketRunner[];
  eventType: {
    id: string;
    name: string;
  };
  competition: {
    id: string;
    name: string;
    regionName: null | string;
    totalMatched?: number;
    highlighted: boolean;
    competitionNameTranslations?: Record<string, string>;
    regionNameTranslations?: Record<string, string>;
  };
  event: {
    id: string;
    name: string;
    nameTranslations?: Record<string, string>;
    homeTeam?: string;
    homeTeamTranslations?: Record<string, string>;
    awayTeam?: string;
    awayTeamTranslations?: Record<string, string>;
    countryCode: string;
    timezone?: string;
    venue?: null;
    openDate: number;
    totalMatched?: null | number;
    videoStreamingEnabled: boolean;
    matchStatEnabled: boolean;
  };
  numberOfWinners: number;
  /** true if market is located under competition skipping event in hierarchy */
  outright: boolean;
  region: null;
  regionTranslations?: Record<string, string>;
  enabledByGroup?: null;
  cashOutEnabled: boolean;
  competitionViewEnabled?: boolean;
  partialCashOutEnabled: boolean;
  closedDate: null | number;
  inPlay: boolean;
  commission: number;
  parents?: TContentParent[];
  fancyView: boolean;
  timeformStatistics?: ITimeFormStatistics;
  round?: number;
  outrightMarket?: boolean;
}

export interface TMarketLineRangeInfo {
  marketUnit: string;
  minUnitValue?: number;
  maxUnitValue?: number;
  interval?: number;
}

export type TMarketPricesRunner = {
  sortPriority: number;
  removalDate: number;
  id: number;
  hc: null;
  adjustmentFactor: number;
  bsp: null;
  status: string;
  selectionId: number;
  handicap: null;
};

export type TMarketPrice = {
  index: number;
  odds: number;
  amount: number;
};

export type TMarketPricesRC = {
  id: number;
  hc: null;
  tv: number;
  locked?: boolean; // Fraud protection
  bdatb: TMarketPrice[];
  bdatl: TMarketPrice[];
};

type TKeyLine = {
  id: number;
  hc: number;
  ahc?: number | null;
};

export type TKeyLineDefinition = {
  kl: TKeyLine[];
};

export type TMarketDefinition = {
  marketType: string;
  numberOfWinners: number;
  countryCode: string;
  inPlay: boolean;
  bettingType: string;
  numberOfActiveRunners: number;
  eventId: string;
  crossMatching: boolean;
  runnersVoidable: boolean;
  turnInPlayEnabled: boolean;
  suspendTime: number;
  persistenceEnabled: boolean;
  runners: TMarketPricesRunner[];
  eventTypeId: string;
  complete: boolean;
  openDate: number;
  marketTime: number;
  status: MarketStatus;
  keyLineDefinition: TKeyLineDefinition;
};

export interface IMarketPrices {
  id: string;
  marketDefinition: TMarketDefinition;
  rc: TMarketPricesRC[];
  img: boolean;
  tv: string;
  con: boolean;
  overround?: number;
  underround?: number;
  apiPt: number;
  ewlPt: number;
  bettingEnabled: boolean;
  enabled: boolean; // market restriction
  currency: string;
  commission?: number;
  matched?: string;
  ms?: string;
  hs?: string; // homeScore
  as?: string; // awayScore
  ftsl?: FTSLKeys;
  disabledLayOdds: boolean;
  mainEventId?: string;
  mainEventName?: string;
  marketNameWithParents?: string;
}

export type AsianViewMarketPrices = IMarketPrices & {
  han: string; // homeAdjustedName
  aan: string; // awayAdjustedName
  hh: string; // homeHandicap
  ah: string; // awayHandicap
  hs: string; // homeScore
  as: string; // awayScore
  te: number; // timeElapsed
  ert: number; // elapsedRegularTime
  eat: number; // elapsedAddedTime
  ms: string; // inPlayMatchStatus
  ftsl?: FTSLKeys; // TODO add description what ftsl abbreviation does mean
  aert: number; // asianViewElapsedRegularTime - elapsed time for specific half-time
};

export const FTSL = {
  HOME: 'HOME',
  AWAY: 'AWAY'
} as const;

export type FTSLKeys = typeof FTSL[keyof typeof FTSL];

export interface IMarketRules {
  marketIds: string[] | null | string;
  marketName: string;
  marketStartTime: number;
  rules: string;
}

export interface MarketsTableClasses {
  header?: string;
  headerCell?: string;
}

export type MarketsTableRowClasses = {
  firstRow?: string;
  lastRow?: string;
};

export type EventMarketClasses = {
  market?: string;
  viewMarket?: string;
  collapseHeader?: string;
  marketTableHeader?: string;
  runnersContainer?: string;
};

export type MarketSelectionsClasses = {
  header?: string;
};

export type ViewAllMarketClasses = {
  container?: string;
};

export type MarketCollapseRowClasses = {
  header?: string;
};

export type RowMarketClasses = {
  firstMarket?: string;
  market?: string;
  runnerRow?: string;
};

export type MobileMarketInfoRowViewOptions = {
  noFavourite?: boolean;
  refreshAfterRules?: boolean;
  noBottom?: boolean;
  noCashOut?: boolean;
  noRulesModal?: boolean;
  noCancelAllBetsBtn?: boolean;
};

export type TFetchEventRulesResponse = {
  marketId: string;
  rules: IMarketRules[];
};

export type TFetchMarketRulesResponse = {
  marketId: string;
  rules: IMarketRules;
};

export type TFancyMultiMarketData = {
  eventInfo: TMarketSportInfo;
  inningsFancy: IMarket[];
  inningsFours: IMarket[];
  inningsSixes: IMarket[];
  totalInningsRuns: IMarket[];
  batsmen: IMarket[];
  otherLineMarkets: IMarket[];
  fallOfWicket: IMarket[];
  type: FancyMarketType;
  id: number;
};

export type TPriceLadderDescription = {
  type: PriceLadderDescription;
};

export type FancyAndMultiMarketMobileLink = {
  to: To;
  titleKey: string;
  id: 1 | 2;
};

export type MarketActionsRowClasses = {
  actions?: string;
};

export interface ITimeFormHistoryColumns {
  key: keyof IStatisticsHistory;
  label: string;
  isShort: boolean;
}

export type MarketsTableEventWidgetsOptions = {
  eventId: string;
  videoStreamingEnabled: boolean;
  sportId: string;
  matchStatEnabled: boolean;
};

export type IMarketStatusSettings = {
  showLockIcon: boolean;
  displayStatus: boolean;
  showStatus: boolean;
  status: string | undefined;
};

export type MarketType = typeof MARKET_TYPES[keyof typeof MARKET_TYPES];
export type RunnerStatus = typeof RunnerStatuses[keyof typeof RunnerStatuses];
export type BettingType = typeof BettingTypes[keyof typeof BettingTypes];
export type PriceLadderDescription = typeof PriceLadderDescriptions[keyof typeof PriceLadderDescriptions];
export type ViewType = typeof ViewTypes[keyof typeof ViewTypes];
export type MarketOddsBannerLocationType =
  typeof MarketOddsBannerLocationTypes[keyof typeof MarketOddsBannerLocationTypes];
export type MarketDepthView = typeof MarketDepthViews[keyof typeof MarketDepthViews];
export type DesktopTopMarketViewType = typeof DesktopTopMarketViewTypes[keyof typeof DesktopTopMarketViewTypes];
export type MarketsTableColumn = typeof MarketsTableColumns[keyof typeof MarketsTableColumns];
export type BetContentGroupType = typeof BetContentGroupTypes[keyof typeof BetContentGroupTypes];
export type MarketsTableHeaderType = typeof MarketsTableHeaderTypes[keyof typeof MarketsTableHeaderTypes];
