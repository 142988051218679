import { BetErrors, BetFocusFields, BetslipFilters, BetslipTabs, PlaceBetsStates } from 'constants/betslip';
import { TPlacementErrorIdType } from 'constants/placement';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { TPlacementError } from 'redux/modules/placement/type';
import { PageBlock, PlacementPage, TFailureActionPayload } from 'types';
import { PersistenceType, THandicap, TPrice, TProfit, TSize } from 'types/bets';
import { BetslipType, FormAction, LineSide, PlacementType, TBetslipMarketRunner } from 'types/betslip';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';
import { BetSide } from 'types/myBets';

export type TValidationMessage = {
  text: string;
  field: string;
  code?: TPlacementErrorIdType | null;
};

export type TSelectedBet = {
  betUuid?: string;
  currency: string;
  marketId: string;
  sportId: string;
  eventId?: string;
  eventTypeId?: string;
  selectionId: number;
  handicap?: THandicap | null;
  marketType: string;
  bettingType?: string;
  type: BetSide;
  price: TPrice | undefined;
  size?: TSize;
  profit?: TProfit;
  pageBlock?: PageBlock;
  isValid?: boolean;
  numberOfWinners?: number;
  error?: BetError | null;
  focusedField?: BetFocusField | null;
  side?: string;
  /** Is selected via Bet All button */
  isBetAll?: boolean;
  /** Default price from market prices, before min valid price value is set up */
  initPrice?: TPrice;
  page?: PlacementPage;
  persistenceType?: PersistenceType;
  redirectedTo?: PageBlock;
  isPriceDirty?: boolean;
  eventName?: string;
  raceName?: string;
  gameName?: string;
  gameType?: string;
  marketName?: string;
  selectionName?: string;
  marketUnit?: string;
  lineSide?: LineSide | null;
  marketStartTime?: number | null;
  fancyView?: boolean;
  eachWayDivisor?: number | null;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  priceLadderDescription?: TPriceLadderDescription | null;
  runners?: TBetslipMarketRunner[];
  validationMessage?: TValidationMessage | null;
  formAction?: FormAction;
  state?: TSelectedBetState;
  isPriceValid?: boolean;
  isSizeValid?: boolean;
  isDisabled?: boolean;
  isLowerPrice?: boolean;
  actualPrice?: TPrice;
  isCancelled?: boolean;
  offerId?: number;
  isPopularMarket?: boolean;
  notificationError?: string | null;
  commission?: number;
};

export const SelectedMarketState = {
  CLOSED: 'CLOSED'
} as const;

export type TSelectedMarketStates = typeof SelectedMarketState[keyof typeof SelectedMarketState];

export type TSelectedBetsBySelection = {
  [key: string]: TSelectedBet;
};

export type TSelectedBetsByTypes = {
  [betType in BetSide]?: TSelectedBetsBySelection;
};

export type TSelectedMarket = {
  bets?: TSelectedBetsByTypes;
  marketInfo?: TSelectedMarketInfo;
  state?: TSelectedMarketStates | null;
};

export type TSelectedMarketInfo = {
  sportId: string;
  eventId?: string;
  marketType?: string;
  bettingType?: string;
  marketName?: string;
  eventName?: string;
  event?: { id: string; name: string };
  gameName?: string;
  raceName?: string;
  marketStartTime?: number | null;
  eachWayDivisor?: number | null;
  marketUnit?: string | null;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  priceLadderDescription?: TPriceLadderDescription | null;
  runners?: TBetslipMarketRunner[];
  parents?: TSelectedMarketParent[];
  fancyView?: boolean;
  numberOfWinners?: number;
  commission?: number;
  marketId: string;
};

export type TSelectedMarketParent = {
  type: string;
  name: string;
};

export type TSelectedBetsByMarket = {
  [marketId: string]: TSelectedMarket;
};

export type TPlacedBets = {
  [offerId: number]: PlacementType;
};

export type TPlacedBetsToUpdate = {
  [offerId: number]: PlacementType;
};

export type PlacementNotification = {
  offerId?: number | null;
  bet: TSelectedBet;
  marketId: string;
  error: string | null;
  isCurrentBetExist?: boolean;
  betUuid: string;
  pageBlock?: PageBlock; // used in mobile placement
  offer?: TCurrentBet;
};

export type DutchingCalculator = {
  isOpened: boolean;
  isMinStakeMessage?: boolean;
  isMaxStakeMessage?: boolean;
};

export type PlacementNotificationsMap = {
  [betUuid: string]: PlacementNotification;
};

export type TBetslipState = {
  /**
   * Is Betslip collapse
   */
  collapse: boolean;
  /**
   * Place bets or Opened bets active tab
   */
  activeTab: BetslipTab;
  /**
   * List of selected bets
   * */
  selectedBets: TSelectedBetsByMarket;
  /**
   * list of bets that were placed on Place bets tab
   * */
  placedBets: TPlacedBets;
  /**
   * List of bets with their statuses about the placement
   */
  placementNotifications: PlacementNotificationsMap;
  /**
   * List of bets that have to be updated
   */
  placedBetsToUpdate: TPlacedBetsToUpdate;
  /**
   * Are bets in the edit or confirm mode on Place bets tab
   */
  placeBetsState: PlaceBetsState;
  /**
   * Show loading spinner
   */
  loading: boolean;
  /**
   * Consolidate bets checkbox value
   */
  consolidateBets: boolean;
  /**
   * Betslip for Exchange or Games
   */
  betslipType: BetslipType;
  /**
   * Indicator that first unmatched opened bet must be focused
   */
  isFirstOpenedBetFocused?: boolean;
  /**
   * Responsible error message
   */
  rgErrorMessage: TPlacementError | null | string;
  /**
   * Liability by selected market
   */
  liabilityByMarket: Record<string, number>;
  /**
   * Selected markets limit notification - max 10 markets
   */
  selectedMarketsLimitNotification: boolean;
  unmatchedOffersWithErrors: Record<string, string>;
  selectedBetsUuids: string[];
  focusedButton?: 'place' | 'confirm' | null;
  checkMarketsError: TFailureActionPayload;
  checkMarketsLoading: boolean;
  unmatchedBetsProgresses: {
    [offerId: string]: number;
  };
  dutchingCalculatorsByMarketIdAndType: {
    [marketId: string]: {
      [type: string]: DutchingCalculator;
    };
  };
};

export type TBetslipFormProps = {
  price?: TPrice;
  size?: TSize;
  profit?: TProfit;
  isPriceDirty?: boolean;
  isPriceValid?: boolean;
  isSizeValid?: boolean;
  priceErrorMessage?: string | null;
  sizeErrorMessage?: string | null;
  focusedField?: BetFocusField | null;
};

export type TBetslipPriceChangeProps = {
  changedPrice?: TPrice;
  isValid?: boolean;
  errorMessage?: string;
  focusedField?: BetFocusField | null;
};

export type TBetslipSizeChangeProps = {
  changedSize?: TSize;
  isValid?: boolean;
  errorMessage?: string;
  focusedField?: BetFocusField | null;
};

export type TBetslipProfitChangeProps = {
  profit?: TProfit;
  size?: TSize;
  isValid?: boolean;
  errorMessage?: string;
  focusedField?: BetFocusField | null;
};

export type TPriceErrorMessage = {
  text: string;
  params: { units?: string; min?: TPrice; max?: TPrice; step?: number };
};

export type TPriceValidation = {
  validValue: TPrice;
  errorMessage: TPriceErrorMessage | null;
  isValid: boolean;
};

export type TSizeErrorMessage = {
  text: string;
  params: { currency_ISO_code?: string; min?: TSize; max?: TSize; number?: number };
};

export type TSizeValidation = {
  validValue?: TSize;
  errorMessage: TSizeErrorMessage | null;
  isValid: boolean;
};

export const SelectedBetState = {
  NONE: 'NONE',
  PROGRESS: 'PROGRESS',
  ERROR: 'ERROR'
} as const;

export type TSelectedBetState = typeof SelectedBetState[keyof typeof SelectedBetState];
export type BetslipFilter = typeof BetslipFilters[keyof typeof BetslipFilters];
export type BetslipTab = typeof BetslipTabs[keyof typeof BetslipTabs];
export type PlaceBetsState = typeof PlaceBetsStates[keyof typeof PlaceBetsStates];
export type BetError = typeof BetErrors[keyof typeof BetErrors];
export type BetFocusField = typeof BetFocusFields[keyof typeof BetFocusFields];

export type TSelectedBetUpdateData = {
  betUuid?: string;
  marketId: string;
  type: BetSide;
  price?: TPrice;
  size?: TSize;
  profit?: TProfit;
  isValid?: boolean;
  error?: BetError | null;
  focusedField?: BetFocusField | null;
  isBetAll?: boolean;
  initPrice?: TPrice;
  prevPrice?: TPrice;
  persistenceType?: PersistenceType;
  redirectedTo?: PageBlock;
  isPriceDirty?: boolean;
  validationMessage?: TValidationMessage | null;
  formAction?: FormAction;
  state?: TSelectedBetState;
  isPriceValid?: boolean;
  isSizeValid?: boolean;
  isDisabled?: boolean;
  isLowerPrice?: boolean;
  actualPrice?: TPrice;
  isCancelled?: boolean;
};
