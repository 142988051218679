import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import GameDerbyRacingCartItem from 'components/GameDerbyRacingCartItem';
import { Devices } from 'constants/app';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { TGameData } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface ISignRow {
  type: string;
  currentSet: TGameData;
  values: number[];
  placeValue: number | string;
  segmentValue: number;
}

const SignRow = ({ type, currentSet, values, placeValue, segmentValue }: ISignRow) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);

  const handicapCards = currentSet.properties.find(i => i.name === 'Handicap')?.value?.split(',') || [];
  const currentCards = currentSet.properties.find(i => i.name === 'Current')?.value?.split(',') || [];
  const usedCards = currentSet.properties.find(i => i.name === 'Used')?.value?.split(',') || [];
  const allOpenedCarts = [...handicapCards, ...currentCards, ...usedCards];
  const cardsPlaces = new Array(13).fill(null);
  const isDesktop = device === Devices.DESKTOP;

  return (
    <div className={classNames(`biab_suit-${type}`, styles.main)}>
      <div className={styles.signSide}>
        <div className={classNames(`biab_suit-icon biab_suit-${type}-icon`, styles.icon)}>
          <img src={require(`assets/images/games/signs/${type}.svg`)} alt={type} />
        </div>
        <div className={classNames(styles.signName)}>{t(`games.suits.${type}`)}</div>
        {isDesktop && (
          <div className={classNames(styles.suitInfo)}>
            {t('games.places.' + placeValue) + ', ' + (32 - segmentValue) + t('games.labels.toGo')}
          </div>
        )}
      </div>
      <div className={classNames(styles.carts)}>
        {cardsPlaces.map((v, index) => (
          <GameDerbyRacingCartItem
            withOpacity={handicapCards.includes(values[index].toString())}
            key={values[index]}
            value={allOpenedCarts.includes(values[index].toString()) ? values[index] : null}
          />
        ))}
      </div>
    </div>
  );
};

export default SignRow;
