import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { MarketStatuses, tooltipStatus } from 'constants/app';
import useTooltip from 'hooks/useTooltip';

import styles from './styles.module.scss';

interface StatusOverlayProps {
  /**
   * Status text
   */
  status?: string;
  /**
   * Overlay class
   */
  className?: string;
  /**
   * Whether the status should be placed in the middle of the parent and take up half the width of the parent
   */
  inMiddle?: boolean;
}
const StatusOverlay = ({ status = '', className = '', inMiddle = false }: StatusOverlayProps) => {
  const { t } = useTranslation();

  const { isEnabled } = useTooltip(tooltipStatus[status] || '');

  if (MarketStatuses.OPEN === status) {
    return null;
  }

  const statusContent = (
    <div
      data-tooltip-id="tooltip"
      data-tooltip-html={isEnabled ? unescape(t(tooltipStatus[status])) : ''}
      className={classNames('biab_game-overlay-background biab_overlay-status', styles.statusOverlay, {
        'biab_overlay-background': status,
        'biab_opening-soon-market-overlay': !status,
        [className ?? '']: className,
        'cursor-help': isEnabled
      })}
    >
      {status ? t(`market.status.${status.toLowerCase()}`) : t('bettingRestriction.labels.openingSoon')}
    </div>
  );

  if (inMiddle) {
    return (
      <div className={styles.statusOverlay__wrapper}>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-html={isEnabled ? unescape(t(tooltipStatus[status])) : ''}
          className={classNames(styles.statusOverlay__container__full, {
            'cursor-help': isEnabled
          })}
        >
          {statusContent}
        </div>
      </div>
    );
  }

  return statusContent;
};

export default StatusOverlay;
