import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ResponsibleBettingAlert from 'components/ResponsibleBetting/components/ResponsibleBettingAlert';
import ResponsibleBettingAppliedField from 'components/ResponsibleBetting/components/ResponsibleBettingAppliedField';
import ResponsibleBettingBtn from 'components/ResponsibleBetting/components/ResponsibleBettingBtn';
import ResponsibleBettingExportDataField from 'components/ResponsibleBetting/components/ResponsibleBettingExportDataField';
import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import { Methods } from 'constants/app';
import {
  initialReportState,
  REPORTS_EXPORT_DELAY,
  ResponsibleBettingAlertTypes,
  ResponsibleBettingButtonTypes,
  ResponsibleBettingReportTypes,
  SELF_EXCLUSION_REPORTS_NAMES
} from 'constants/responsibleBetting';
import { fetchRequest } from 'redux/api/request';
import { urls } from 'redux/api/urls';
import { fetchResponsibleBettingReportsAvailability } from 'redux/modules/responsibleBetting';
import { getIsReportsAvailableToExport, getReportsAvailability } from 'redux/modules/responsibleBetting/selectors';
import {
  ResponsibleBettingReportInitialState,
  ResponsibleBettingReportTypeKeys,
  ResponsibleBettingSelfExclusionPeriod
} from 'types/responsibleBetting';
import { getSelfExclusionReportsExportOptions } from 'utils/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingApplySelfExclusionFormProps {
  isMobile: boolean;
  selectedTimeUnit: ResponsibleBettingSelfExclusionPeriod | null;
  onBack: () => void;
  onApply: () => void;
}

const ResponsibleBettingApplySelfExclusionForm = ({
  isMobile,
  selectedTimeUnit,
  onBack,
  onApply
}: ResponsibleBettingApplySelfExclusionFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reportsAvailability = useSelector(getReportsAvailability);
  const isReportsAvailableForExport = useSelector(getIsReportsAvailableToExport);

  const [loadingReportsByType, setLoadingReportsByType] = useState({
    [ResponsibleBettingReportTypes.CurrentBetsReport]: initialReportState,
    [ResponsibleBettingReportTypes.PastBetsReport]: initialReportState,
    [ResponsibleBettingReportTypes.ProfitAndLossReport]: initialReportState,
    [ResponsibleBettingReportTypes.AccountStatementReport]: initialReportState
  });

  const reportsExportOptions = getSelfExclusionReportsExportOptions(reportsAvailability);

  const updateReportState = (
    reportType: ResponsibleBettingReportTypeKeys,
    updates: ResponsibleBettingReportInitialState
  ) => {
    setLoadingReportsByType(prevState => ({
      ...prevState,
      [reportType]: { ...prevState[reportType], ...updates }
    }));
  };

  const fetchReportByTypeHandler = async (type: ResponsibleBettingReportTypeKeys) => {
    updateReportState(type, { loading: true, error: null, disabled: true });

    try {
      const file = await fetchRequest(
        Methods.GET,
        urls.responsibleBetting.fetchReports(type),
        {},
        {},
        true,
        'blob' as ResponseType
      );

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = `${t(
        SELF_EXCLUSION_REPORTS_NAMES[type as ResponsibleBettingReportTypeKeys]
      )}_${new Date().getTime()}.xlsx`;
      link.click();

      updateReportState(type, { loading: false });

      setTimeout(() => updateReportState(type, { disabled: false }), REPORTS_EXPORT_DELAY);
    } catch (error: any) {
      updateReportState(type, { loading: false, error: error, disabled: false });
    }
  };

  useEffect(() => {
    dispatch(fetchResponsibleBettingReportsAvailability());
  }, []);

  return (
    <div
      className={classNames(commonStyles.content, {
        [commonStyles.content__mobile]: isMobile
      })}
    >
      <div
        className={classNames(styles.form, {
          [styles.form__mobile]: isMobile
        })}
      >
        <span
          className={classNames(styles.formLabel, {
            [styles.formLabel__mobile]: isMobile
          })}
        >
          {t('responsibleBetting.labels.excludeMe')}
        </span>
        <ResponsibleBettingAppliedField
          isMobile={isMobile}
          value={t(`responsibleBetting.labels.${selectedTimeUnit}`)}
          centered={false}
        />
      </div>

      {isReportsAvailableForExport && (
        <div>
          <div className={styles.alertWrapper}>
            <ResponsibleBettingAlert
              type={ResponsibleBettingAlertTypes.Info}
              description={t('responsibleBetting.message.SELF_EXCLUSION_CONFIRMATION')}
            />
          </div>
          <p
            className={classNames(styles.subtitle, {
              [styles.subtitle__mobile]: isMobile
            })}
          >
            {t('responsibleBetting.labels.exportYourAccountData')}:
          </p>
          <div className={styles.exportDataControllers}>
            {reportsExportOptions.map(
              ({ label, period, isVisible, type }) =>
                isVisible && (
                  <ResponsibleBettingExportDataField
                    key={`${label}${type}`}
                    isMobile={isMobile}
                    label={label}
                    period={period}
                    type={type}
                    exportFieldState={loadingReportsByType[type]}
                    onExport={() => fetchReportByTypeHandler(type)}
                    onClose={() => updateReportState(type, { error: null })}
                  />
                )
            )}
          </div>
        </div>
      )}

      <div className={commonStyles.divider} />
      <div
        className={classNames(commonStyles.sectionContainer, {
          [commonStyles.sectionContainer__mobile_reverse]: isMobile,
          [commonStyles.sectionContainer__no_margin]: !isMobile
        })}
      >
        <span
          className={classNames(commonStyles.sectionLabel, {
            [commonStyles.sectionLabel__mobile]: isMobile
          })}
        >
          {t('responsibleBetting.messages.confirmDecision')}
        </span>
        <div
          className={classNames(commonStyles.sectionControllers, {
            [commonStyles.sectionControllers__mobile]: isMobile,
            [commonStyles.sectionControllers__applied]: true
          })}
        >
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.back')}
            onClick={onBack}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Secondary}
          />
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.apply')}
            onClick={onApply}
            isMobile={isMobile}
            disabled={!selectedTimeUnit}
            type={ResponsibleBettingButtonTypes.Applied}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponsibleBettingApplySelfExclusionForm;
