import { each, flatten, groupBy, keys, map, max, min, reduce, toNumber, union, uniq } from 'lodash';

import { AHVirtualOutcomes, LineSides } from 'constants/betslip';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetSides } from 'constants/myBets';
import {
  AH_DOUBLE_LINE,
  AH_SINGLE_LINE,
  ALT_TOTAL_GOALS,
  ALT_TOTAL_GOALS_NUMBER_OF_WINNERS_BY_LINE,
  COMBINED_NUMBER_OF_WINNERS_BY_LINE,
  COMBINED_TOTAL,
  VARIABLE_HANDICAP
} from 'constants/placement';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { TNewWhatValue } from 'redux/modules/whatIf/type';
import {
  AHVirtualOutcome,
  TBetLiability,
  TBetslipMarket,
  TBetslipMarketDefinition,
  TBetslipMarketRunner
} from 'types/betslip';
import { BetSide } from 'types/myBets';

export class AHVectorOutcomePL {
  handicap: number | string;

  side: BetSide | null;

  selectionId: number | null;

  outcomePLList: AHOutcomePL[];

  constructor(
    handicap: number | string,
    side: BetSide | null,
    selectionId: number | null,
    outcomePLList?: AHOutcomePL[]
  ) {
    this.handicap = handicap;
    this.side = side;
    this.selectionId = selectionId;
    this.outcomePLList = outcomePLList || [];
  }

  addOutcomePL(outcomePL: AHOutcomePL) {
    this.outcomePLList.push(outcomePL);
  }

  getOutcomePLList() {
    return this.outcomePLList;
  }

  toOppositeTeam() {
    const outcomePLList: AHOutcomePL[] = [];

    this.outcomePLList.forEach((item: AHOutcomePL) => {
      outcomePLList.unshift(item.toOppositeTeam());
    });

    return new AHVectorOutcomePL(this.handicap, this.side, this.selectionId, outcomePLList);
  }
}

class AHOutcomePL {
  virtualPoints: number;

  virtualOutcome: AHVirtualOutcome;

  profitOrLoss: number;

  constructor(virtualPoints: number, virtualOutcome: AHVirtualOutcome, profitOrLoss: number) {
    this.virtualPoints = virtualPoints;
    this.virtualOutcome = virtualOutcome;
    this.profitOrLoss = profitOrLoss;
  }

  getVirtualPoints() {
    return this.virtualPoints;
  }

  getVirtualOutcome() {
    return this.virtualOutcome;
  }

  toOppositeTeam() {
    return new AHOutcomePL(this.virtualPoints * -1, this.virtualOutcome, this.profitOrLoss);
  }
}

class SelectionPL {
  profitIfWin: number;

  profitIfLose: number;

  constructor(profitIfWin: number, profitIfLose: number) {
    this.profitIfWin = profitIfWin;
    this.profitIfLose = profitIfLose;
  }

  getProfitIfWin() {
    return this.profitIfWin;
  }

  getProfitIfLose() {
    return this.profitIfLose;
  }

  getNetProfit() {
    return this.profitIfWin - this.profitIfLose;
  }
}

export const getLiabilityByAsianHandicapMarket = (offers: TBetLiability[] = [], marketInfo?: TBetslipMarket) => {
  if (!offers.length) {
    return 0;
  }

  const totalOutcomePl = calculateTotalProfitLossByAsianHandicapMarket(offers, marketInfo);

  const liability = min(totalOutcomePl) || 0;

  return getPositiveLiability(liability);
};

export const getProfitLossByAsianHandicapMarket = (offers: TBetLiability[] = [], marketInfo?: TBetslipMarket) => {
  // calculate p&l for matched bets
  const isMatchedCalculated = true;

  if (!offers || !offers.length) {
    return {};
  }

  return calculateProfitLossByAsianHandicapMarket(offers, marketInfo, isMatchedCalculated);
};

export const getLiabilityByATGMarket = (
  offers: TBetLiability[] = [],
  marketInfo?: TBetslipMarket,
  marketDefinition?: TBetslipMarketDefinition | null
) => {
  const liability = calculateTotalProfitLossByATGandSnTMarket(
    offers,
    ALT_TOTAL_GOALS_NUMBER_OF_WINNERS_BY_LINE,
    marketInfo,
    marketDefinition
  );

  return getPositiveLiability(liability);
};

export const getLiabilityByCombinedMarket = (offers: TBetLiability[] = [], marketInfo?: TBetslipMarket) => {
  const liability = calculateTotalProfitLossByATGandSnTMarket(offers, COMBINED_NUMBER_OF_WINNERS_BY_LINE, marketInfo);

  return getPositiveLiability(liability);
};

export const calculateTotalProfitLossByAsianHandicapMarket = (
  offers: TBetLiability[] = [],
  marketInfo?: TBetslipMarket
) => {
  const groupedProfitLossByHandicap = calculateProfitLossByAsianHandicapMarket(offers, marketInfo);

  return map(groupedProfitLossByHandicap, item => {
    return reduce(
      item,
      (total, value) => {
        return total + value.profitOrLoss;
      },
      0
    );
  });
};

export const calculateProfitLossByAsianHandicapMarket = (
  offers: TBetLiability[] = [],
  marketInfo?: TBetslipMarket,
  isMatchedCalculated?: boolean
) => {
  const outcomePLGrid: AHVectorOutcomePL[] = [];

  offers.forEach(offer => {
    if (getMarketBettingType(marketInfo) === AH_DOUBLE_LINE) {
      outcomePLGrid.push(calculateOutcomePLDoubleLine(offer, marketInfo, isMatchedCalculated));
    } else if (getMarketBettingType(marketInfo) === AH_SINGLE_LINE) {
      outcomePLGrid.push(calculateOutcomePLSingleLine(offer, marketInfo, isMatchedCalculated));
    } else if (getMarketType(marketInfo) === VARIABLE_HANDICAP) {
      const market = { ...marketInfo } as TBetslipMarket;
      if (market?.runners) {
        market.runners = market.runners.map(runner => {
          return {
            ...runner,
            handicap: +(runner.runnerName?.replace(/\D+/g, '') || 0)
          };
        });
      }
      outcomePLGrid.push(calculateOutcomePLSingleLine(offer, market, isMatchedCalculated));
    }
  });

  return groupBy(flatten(outcomePLGrid.map(item => item.outcomePLList)), 'virtualPoints');
};

export const calculateOutcomePLSingleLine = (
  offer: TBetLiability,
  marketInfo?: TBetslipMarket,
  isMatchedCalculated?: boolean
) => {
  const outcomePL = new AHVectorOutcomePL(toNumber(offer.handicap || 0), offer.type, offer.selectionId);

  if (marketInfo?.runners) {
    const fromOutcome = toNumber(min(marketInfo.runners.map(item => toNumber(item.handicap || 0))) || 1) - 1;

    const toOutcome = toNumber(max(marketInfo.runners.map(item => toNumber(item.handicap || 0))) || -1) + 1;

    let virtualPointsList: number[] = marketInfo.runners
      .map(item => toNumber(item.handicap || 0))
      .sort((a, b) => a - b);

    virtualPointsList.unshift(fromOutcome);
    virtualPointsList.push(toOutcome);
    virtualPointsList = uniq(virtualPointsList);

    virtualPointsList.forEach(virtualPoints => {
      outcomePL.addOutcomePL(getAHOutcomePLSingleLine(virtualPoints, offer, isMatchedCalculated));
    });
  }

  return outcomePL;
};

export const getAHOutcomePLSingleLine = (
  virtualPoints: number,
  offer: TBetLiability,
  isMatchedCalculated?: boolean
) => {
  let virtualOutcome;
  let profitOrLoss;

  const handicap = toNumber(offer.handicap || 0);
  const handicapAbs = Math.abs(handicap);
  const virtualPointsAbs = Math.abs(virtualPoints);
  const offerSide = offer.type || '';
  const isBack = offerSide === BetSides.Back;
  const isLay = !isBack;

  if ((isBack && virtualPointsAbs >= handicapAbs) || (isLay && virtualPointsAbs < handicapAbs)) {
    virtualOutcome = AHVirtualOutcomes.WIN;
    // As for matched calculate: this.getMatchedProfitOrZero(offer);
    profitOrLoss = isMatchedCalculated ? getMatchedProfitOrZero(offer) : 0;
  } else if ((isBack && virtualPointsAbs < handicapAbs) || (isLay && virtualPointsAbs >= handicapAbs)) {
    virtualOutcome = AHVirtualOutcomes.LOSS;
    profitOrLoss = getLoss(offer);
  } else {
    virtualOutcome = AHVirtualOutcomes.STAKE_REFUND;
    profitOrLoss = 0;
  }

  return new AHOutcomePL(virtualPoints, virtualOutcome, profitOrLoss);
};

export const calculateOutcomePLDoubleLine = (
  offer: TBetLiability,
  marketInfo?: TBetslipMarket,
  isMatchedCalculated?: boolean
) => {
  if (marketInfo?.runners?.length) {
    let outcomePL = new AHVectorOutcomePL(toNumber(offer.handicap || 0), offer.type, offer.selectionId);
    const isGoalLines = getMarketType(marketInfo) === COMBINED_TOTAL || getMarketType(marketInfo) === ALT_TOTAL_GOALS;

    let homeRunner: TBetslipMarketRunner | undefined;
    let virtualSupremacyWithOutcome;

    if (isGoalLines) {
      homeRunner = marketInfo.runners.find(runner => {
        return runner.lineSide === LineSides.UNDER && runner.selectionId;
      });
    }

    if (!isGoalLines || !homeRunner) {
      homeRunner = marketInfo.runners[0];

      if (!homeRunner || (homeRunner && !homeRunner.selectionId)) {
        homeRunner = marketInfo.runners.find(runner => runner.selectionId);
      }
    }

    const isHomeTeam = homeRunner?.selectionId.toString() === offer.selectionId.toString();

    const fromOutcome = toNumber(min(marketInfo.runners.map(item => toNumber(item.handicap || 0))) || 1) - 1;

    const toOutcome = toNumber(max(marketInfo.runners.map(item => toNumber(item.handicap || 0))) || -1) + 1;

    for (let virtualSupremacyPoints = fromOutcome; virtualSupremacyPoints <= toOutcome; virtualSupremacyPoints++) {
      if (isGoalLines) {
        virtualSupremacyWithOutcome = isHomeTeam
          ? toNumber(offer.handicap || 0) - virtualSupremacyPoints + 0.5
          : virtualSupremacyPoints - toNumber(offer.handicap || 0) - 0.5;
      } else {
        virtualSupremacyWithOutcome = virtualSupremacyPoints + toNumber(offer.handicap || 0);
      }

      outcomePL.addOutcomePL(
        getAHOutcomePLDoubleLineOnHomeTeam(
          virtualSupremacyPoints,
          virtualSupremacyWithOutcome,
          offer,
          isMatchedCalculated
        )
      );
    }

    if (!isGoalLines && !isHomeTeam) {
      outcomePL = outcomePL.toOppositeTeam();
    }

    return outcomePL;
  } else {
    return new AHVectorOutcomePL(0, null, null);
  }
};

const roundToTwoDecimals = (value: number): number => {
  return (Math.sign(value) * Math.round(Math.abs(value) * 100)) / 100;
};

export const getAHOutcomePLDoubleLineOnHomeTeam = (
  virtualSupremacyPoints: number,
  virtualSupremacyWithOutcome: number,
  offer: TBetLiability,
  isMatchedCalculated?: boolean
) => {
  let virtualOutcome;
  let profitOrLoss;
  const offerSide = offer.type;
  const isBack = offerSide === BetSides.Back;
  const isLay = !isBack;

  const QUARTER = 0.25;
  const MINUS_QUARTER = -0.25;
  const TWO = 2;

  if ((isBack && virtualSupremacyWithOutcome > QUARTER) || (isLay && virtualSupremacyWithOutcome < MINUS_QUARTER)) {
    virtualOutcome = AHVirtualOutcomes.WIN;
    // As for matched calculate: this.getMatchedProfitOrZero(offer);
    profitOrLoss = isMatchedCalculated ? getMatchedProfitOrZero(offer) : 0;
  } else if (
    (isBack && virtualSupremacyWithOutcome === QUARTER) ||
    (isLay && virtualSupremacyWithOutcome === MINUS_QUARTER)
  ) {
    virtualOutcome = AHVirtualOutcomes.HALF_WIN;
    // As for matched calculate: (Math.round(this.getMatchedProfitOrZero(offer) * 100) / 100) / TWO;
    profitOrLoss = isMatchedCalculated ? Math.round((getMatchedProfitOrZero(offer) / TWO) * 100) / 100 : 0;
  } else if (
    (isBack && virtualSupremacyWithOutcome < MINUS_QUARTER) ||
    (isLay && virtualSupremacyWithOutcome > QUARTER)
  ) {
    virtualOutcome = AHVirtualOutcomes.LOSS;
    profitOrLoss = getLoss(offer);
  } else if (
    (isBack && virtualSupremacyWithOutcome === MINUS_QUARTER) ||
    (isLay && virtualSupremacyWithOutcome === QUARTER)
  ) {
    virtualOutcome = AHVirtualOutcomes.HALF_LOSS;
    profitOrLoss = Math.round(getLoss(offer) * 100) / 100 / TWO;
  } else {
    virtualOutcome = AHVirtualOutcomes.STAKE_REFUND;
    profitOrLoss = 0;
  }

  return new AHOutcomePL(virtualSupremacyPoints, virtualOutcome, profitOrLoss);
};

export const calculateTotalProfitLossByATGandSnTMarket = (
  offers: TBetLiability[],
  numberOfWinners: number,
  marketInfo?: TBetslipMarket,
  marketDefinition?: TBetslipMarketDefinition | null
) => {
  let exposure = 0;

  if (!offers || !offers.length) {
    return exposure;
  }

  if (marketInfo?.runners) {
    const { runners } = marketInfo;

    const selectionsByLine = groupBy(runners, selection => selection.handicap);

    const offersByLine = groupBy(offers, offer => offer.handicap);

    for (const handicap in offersByLine) {
      if (selectionsByLine.hasOwnProperty(handicap)) {
        const lineSelections = map(selectionsByLine[handicap], selection => selection.selectionId);

        const lineExposure = getExposureByOddsMarket(
          offersByLine[handicap],
          lineSelections,
          numberOfWinners,
          marketDefinition?.complete
        );

        exposure = exposure + lineExposure;
      }
    }
  }

  return exposure;
};

export const getExposureByOddsMarket = (
  offers: TBetLiability[],
  selectionIds?: number[],
  numberOfWinners?: number,
  complete?: boolean
) => {
  if (!selectionIds || !selectionIds.length) {
    return 0;
  }

  const offersBySelection = groupBy(offers, offer => offer.selectionId);

  const profitsPerSelection: Record<number, SelectionPL> = {};

  selectionIds.forEach((selectionId: number) => {
    const selectionOffers = offersBySelection[selectionId] || [];

    profitsPerSelection[selectionId] = calcPLForSelection(selectionOffers);
  });

  const profitsList = map(profitsPerSelection, selectionPL => selectionPL);

  const selectionProfits = profitsList.sort((item1, item2) => {
    return item1.getNetProfit() - item2.getNetProfit();
  });

  return getExposure(selectionProfits, numberOfWinners, complete);
};

export const calcPLForSelection = (selectionOffers: TBetLiability[]) => {
  let profitIfWin = 0;
  let profitLose = 0;

  selectionOffers.forEach(offer => {
    profitIfWin = profitIfWin + getProfitIfWin(offer);
    profitLose = profitLose + getProfitIfLose(offer);
  });

  return new SelectionPL(profitIfWin, profitLose);
};

export const getExposure = (profits: SelectionPL[], numOfWinners = 0, marketComplete?: boolean) => {
  let exposure;

  if (numOfWinners === 0) {
    const profitsList = map(profits, profit => profit);

    exposure = reduce(
      profitsList,
      (totalProfit, selectionPL: SelectionPL) => {
        return totalProfit + toNumber(min([selectionPL.getProfitIfWin(), selectionPL.getProfitIfLose()]) || 0);
      },
      0
    );
  } else {
    exposure = 0;

    for (let i = 0; i < profits.length; i++) {
      const profit = profits[i];

      if (i < numOfWinners) {
        const profitToAdd = marketComplete
          ? profit.getProfitIfWin()
          : min([profit.getProfitIfWin(), profit.getProfitIfLose()]);

        exposure = exposure + toNumber(profitToAdd || 0);
      } else {
        exposure = exposure + profit.getProfitIfLose();
      }
    }
  }

  return exposure;
};

export const getProfitIfWin = (offer: TBetLiability) => {
  if (offer.type === BetSides.Lay) {
    return -1 * calculateLiability(offer);
  } else {
    return 0;
  }
};

export const getProfitIfLose = (offer: TBetLiability) => {
  if (offer.type === BetSides.Back) {
    return -1 * calculateLiability(offer);
  } else {
    return 0;
  }
};

export const calculateLiability = (offer: TBetLiability) => {
  return offer.type === BetSides.Back ? backLiability(offer) : layLiability(offer);
};

export const backLiability = (offer: TBetLiability) => {
  return toNumber(offer.size || 0);
};

export const layLiability = (offer: TBetLiability) => {
  return (toNumber(offer.price || 0) - 1) * toNumber(offer.size || 0);
};

export const getMatchedProfitOrZero = (offer: TBetLiability) => {
  const profitLoss =
    offer.type === BetSides.Back
      ? (toNumber(offer.price || 0) - 1) * toNumber(offer.size || 0)
      : toNumber(offer.size || 0);

  return Math.round(profitLoss * 100) / 100;
};

export const getLoss = (offer: TBetLiability) => {
  const profitLoss =
    -1 *
    (offer.type === BetSides.Lay
      ? (toNumber(offer.price || 0) - 1) * toNumber(offer.size || 0)
      : toNumber(offer.size || 0));

  return Math.round(profitLoss * 100) / 100;
};

export const getPositiveLiability = (liability: number) => {
  liability = liability < 0 ? -1 * liability : 0;

  return Math.round(liability * 100) / 100;
};

export const getMarketBettingType = (marketInfo?: TBetslipMarket) => {
  return marketInfo?.description?.bettingType ?? '';
};

export const getMarketType = (marketInfo?: TBetslipMarket) => {
  return marketInfo?.description?.marketType ?? '';
};

export const mapAHDoubleLineNetProfit = (outcomePl: { [key: string]: any }, commission?: number) => {
  const isCommission = true,
    commissionValue = commission || 0;

  outcomePl = map(outcomePl, (item, key) => {
    const pl = {} as { [key: string]: any };

    pl[key] = reduce(
      item,
      function (total, value) {
        return total + value.profitOrLoss;
      },
      0
    );

    return pl;
  });

  outcomePl = outcomePl.reduce((result: any, item: any) => {
    const key = parseFloat(keys(item)[0]),
      keyAbs = Math.abs(key),
      resultKey = key > 0 ? 'win' : key === 0 ? 'draw' : 'loss';
    let value = parseFloat(item[key]);

    if (value > 0 && isCommission) {
      value = value * ((100 - commissionValue) / 100);
    }

    value = roundToTwoDecimals(value || 0);

    if (!result[keyAbs]) {
      result[keyAbs] = { title: keyAbs };
    }

    result[keyAbs][resultKey] = value;
    result[keyAbs][resultKey + '_style'] = value >= 0 ? 'positive' : 'negative';
    result[keyAbs][resultKey + '_value'] = value;

    return result;
  }, {});

  const keyValues: any = keys(outcomePl);

  if (keyValues.length) {
    const line = outcomePl[keyValues[keyValues.length - 2]].title;
    outcomePl[keyValues[keyValues.length - 1]].title = (line == parseInt(line) ? line : parseInt(line) + 1) + '+';
  }

  return outcomePl;
};

export const groupCombinedBets = (combinedBets: { [key: string]: any }) => {
  let win: any = null;
  let loss: any = null;
  let prevLine: any = null;
  let prevRecord: any = null;
  const data = {} as { [key: string]: any };
  let maxCombinedLine: any = null;
  let maxLine: any = null;
  each(combinedBets, function (combinedBet: any, line: any) {
    if (win != combinedBet.pl.win || loss != combinedBet.whatIf.win) {
      data[line] = combinedBet;
      win = combinedBet.pl.win;
      loss = combinedBet.whatIf.win;
      maxCombinedLine = line;
    }
    maxLine = line;
  });

  each(data, function (record, line) {
    if (!prevRecord) {
      prevRecord = record;
      prevLine = line;
    }
    if (+line - prevLine > 1) {
      data[prevLine].title = parseInt(prevLine) + '+';
    } else {
      data[prevLine].title = parseInt(prevLine);
    }
    if (line == maxCombinedLine) {
      if (maxCombinedLine != maxLine) {
        data[line].title = (parseInt(line) == 0 ? parseInt(line) : parseInt(line)) + '+';
      }
    }
    prevRecord = record;
    prevLine = line;
  });

  return data;
};

export const mapCurrentBets = (currentBets: TCurrentBet[]) => {
  const bets: TBetLiability[] = currentBets.map(bet => {
    return {
      marketId: bet.marketId,
      selectionId: toNumber(bet.selectionId),
      handicap: bet.handicap || 0,
      type: (bet.type || bet.side) as BetSide,
      price: bet.averagePrice || bet.price,
      size: bet.size
    };
  });
  return bets;
};

export const mapNewBets = (newBets: TNewWhatValue[]) => {
  const bets: TBetLiability[] = newBets.map(bet => {
    return {
      marketId: bet.marketId,
      selectionId: toNumber(bet.selectionId),
      handicap: bet.handicap || 0,
      type: (bet.type || bet.side) as BetSide,
      price: bet.price,
      size: bet.size
    };
  });
  return bets;
};

export const getAHDoubleLineNetProfitTable = (
  market: TBetslipMarket,
  newBets: TNewWhatValue[],
  currentBets: TCurrentBet[]
) => {
  const currentBetsLiabilities = mapCurrentBets(currentBets);
  const newBetsLiabilities = mapNewBets(newBets);
  const isGoalLine = market.description.marketType === MARKET_TYPES.altTotalGoals,
    selectedBets = getProfitLossByAsianHandicapMarket(newBetsLiabilities.concat(currentBetsLiabilities), market),
    openedBets = getProfitLossByAsianHandicapMarket(currentBetsLiabilities, market),
    selectedBetsMapped = mapAHDoubleLineNetProfit(selectedBets, market.commission),
    openedBetsMapped = mapAHDoubleLineNetProfit(openedBets, market.commission),
    keyValues: any = union(keys(selectedBetsMapped), keys(openedBetsMapped)),
    combinedBets = {} as { [key: string]: any };

  each(keyValues, function (line) {
    const lineData = selectedBetsMapped[line] || openedBetsMapped[line] || {};

    combinedBets[line] = {
      title: lineData.title || '',
      pl: openedBetsMapped[line] || {},
      whatIf: selectedBetsMapped[line] || {}
    };
  });
  return isGoalLine ? groupCombinedBets(combinedBets) : combinedBets;
};
