import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { componentsBranding, mobileComponents } from 'constants/branding';
import { ACCOUNT_RESET_TO_DEFAULT_STATE } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';

import styles from './AccountSettingsActions.module.scss';

type DesktopSettingsActionsProps = {
  isEditMode: boolean;
  onCancel: () => void;
  onReset?: () => void;
  onSave: () => void;
  onEdit: () => void;
  isSaveBtnDisabled: boolean;
  isSaveLoading: boolean;
  isMobile?: boolean;
  containerOrEditClassName?: string;
};

const AccountSettingsActions = ({
  isEditMode,
  onSave,
  onReset,
  onCancel,
  onEdit,
  isSaveBtnDisabled,
  isSaveLoading,
  isMobile,
  containerOrEditClassName = ''
}: DesktopSettingsActionsProps) => {
  const { t } = useTranslation();
  const { translationKey, isEnabled } = useTooltip(ACCOUNT_RESET_TO_DEFAULT_STATE);

  if (isEditMode) {
    return (
      <div className={classNames(styles.actions, containerOrEditClassName)}>
        <button
          onClick={onCancel}
          className={classNames(styles.actions__action, styles.actions__action__secondary, {
            [styles.actions__action__mobile]: isMobile,
            [componentsBranding.TERTIARY_BTN]: !isMobile,
            [mobileComponents.BUTTON]: isMobile,
            [mobileComponents.TERTIARY]: isMobile
          })}
        >
          {t('account.settings.quickBets.buttons.cancel')}
        </button>
        {onReset && (
          <button
            data-tooltip-id="tooltip"
            data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
            onClick={onReset}
            className={classNames(styles.actions__action, styles.actions__action__secondary, {
              [styles.actions__action__mobile]: isMobile,
              [componentsBranding.TERTIARY_BTN]: !isMobile,
              [mobileComponents.BUTTON]: isMobile,
              [mobileComponents.TERTIARY]: isMobile
            })}
          >
            {t('account.settings.quickBets.buttons.reset')}
          </button>
        )}
        <button
          onClick={onSave}
          className={classNames(styles.actions__action, styles.actions__save, {
            [styles.actions__action__mobile]: isMobile,
            [componentsBranding.DISABLED]: isSaveBtnDisabled || (isSaveLoading && !isMobile),
            [componentsBranding.PRIMARY_BTN]: !isMobile,
            [mobileComponents.BUTTON]: isMobile,
            [mobileComponents.PRIMARY]: isMobile
          })}
          disabled={isSaveBtnDisabled || isSaveLoading}
        >
          {isSaveLoading ? (
            <i
              className={classNames('fa fa-spinner fa-pulse fa-fw', styles.actions__save__loading, {
                [styles.actions__save__loading__mobile]: isMobile
              })}
            />
          ) : (
            t('account.settings.quickBets.buttons.save')
          )}
        </button>
      </div>
    );
  }

  return (
    <button
      onClick={onEdit}
      className={classNames(
        styles.actions__action,
        styles.actions__action__secondary,
        styles.actions__edit,
        {
          [styles.actions__action__mobile]: isMobile,
          [mobileComponents.BUTTON]: isMobile,
          [mobileComponents.TERTIARY]: isMobile,
          [componentsBranding.TERTIARY_BTN]: !isMobile
        },
        containerOrEditClassName
      )}
    >
      {t('account.settings.quickBets.buttons.edit')}
    </button>
  );
};

export default AccountSettingsActions;
