import { RunnersStatuses } from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import { TAsianSelectedBet } from 'redux/modules/asianViewBetslip/type';
import { TMarketPricesRunner } from 'types/markets';

export const getIsHandicapLineDisappeared = ({
  bet,
  isKeyLine,
  firstKeyLineId,
  firstKeyLineHandicap,
  isAsianHandicap,
  runnerStatuses
}: {
  bet: TAsianSelectedBet;
  isKeyLine: boolean;
  firstKeyLineHandicap?: number;
  firstKeyLineId?: number;
  isAsianHandicap: boolean;
  runnerStatuses: TMarketPricesRunner[];
}) => {
  if (
    isKeyLine &&
    bet.handicap &&
    (bet.marketType === MARKET_TYPES.asianHandicap || bet.marketType === MARKET_TYPES.altTotalGoals)
  ) {
    const homeHandicap = firstKeyLineHandicap || 0;
    const hasPrevLine =
      !runnerStatuses ||
      runnerStatuses.find(
        runnerStatus =>
          runnerStatus.status === RunnersStatuses.ACTIVE &&
          runnerStatus.id === firstKeyLineId &&
          runnerStatus.handicap === homeHandicap - 0.25
      );
    const hasNextLine =
      !runnerStatuses ||
      runnerStatuses.find(
        runnerStatus =>
          runnerStatus.status === RunnersStatuses.ACTIVE &&
          runnerStatus.id === firstKeyLineId &&
          runnerStatus.handicap === homeHandicap + 0.25
      );
    const nextLineHandicap = homeHandicap + (hasNextLine ? 0.25 : -0.25);
    const lastLineHandicap = homeHandicap + (!hasPrevLine && hasNextLine ? 0.5 : hasPrevLine ? -0.25 : -0.5);
    const possibleHomeHandicap = [homeHandicap, nextLineHandicap, lastLineHandicap];
    const possibleAwayHandicap = isAsianHandicap ? possibleHomeHandicap.map(hc => hc * -1) : possibleHomeHandicap;
    const isHome = firstKeyLineId === bet.selectionId;

    return isHome
      ? !possibleHomeHandicap.includes(Number(bet.handicap))
      : !possibleAwayHandicap.includes(Number(bet.handicap));
  }

  return false;
};

export const getIsScoreChanged = ({ bet, currentScore }: { bet: TAsianSelectedBet; currentScore: string }) => {
  if (
    (bet.marketType === MARKET_TYPES.asianHandicap || bet.marketType === MARKET_TYPES.altTotalGoals) &&
    currentScore
  ) {
    return currentScore !== bet.score;
  }

  return false;
};
