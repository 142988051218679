export const PersistenceTypes = {
  LAPSE: 'LAPSE',
  PERSIST: 'PERSIST'
} as const;

export const BetDatabaseNames = {
  BACK: 'bdatb',
  LAY: 'bdatl'
} as const;

export const MatchTypes = {
  MATCHED: 'MATCHED',
  UNMATCHED: 'UNMATCHED'
} as const;
