import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { setBackendContent } from 'redux/modules/appConfigs';
import { getLoggedInLoading, getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { PageFromBackend, PagesFromBackend } from 'redux/modules/pages/types';

type PrivateRouteProps = {
  page?: PageFromBackend;
};

const PrivateRoute = ({ page }: PrivateRouteProps) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const loading = useSelector(getLoggedInLoading);

  useEffect(() => {
    if (!isLoggedIn && !loading) {
      dispatch(setBackendContent(page || PagesFromBackend.FORBIDDEN));
    }
  }, [isLoggedIn, loading]);

  return <Outlet />;
};

export default PrivateRoute;
