import { KeyboardEvent, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import RGModalMessage from 'components/Betslip/components/RGModalMessage';
import { BetFocusFields, BetslipTabs, KEY_CODES, PlaceBetsStates, tabulation } from 'constants/betslip';
import { betslipBranding as branding, componentsBranding } from 'constants/branding';
import { BettingTypes } from 'constants/markets';
import useConfirmBets from 'hooks/useConfirmBets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { usePlacementData } from 'hooks/usePlacement';
import usePostMessage from 'hooks/usePostMessage';
import {
  getBalanceWsEnabled,
  getBetslipSpinnerTime,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  removeAllGamesSelectedBets,
  removeAllPlacementNotifications,
  removeAllSelectedBets,
  removeAllSportsSelectedBets,
  removeIsCancelledFromAllSelectedBets,
  removeSelectedBet,
  setActiveTab,
  setBetslipFocusedButton,
  setBetslipLoading,
  setPlaceBetsState,
  setPlacementNotifications,
  updateSelectedBet,
  updateSelectedBetsWithData
} from 'redux/modules/betslip';
import {
  getActiveSelectedBets,
  getBetslipFocusedButton,
  getBetslipPlaceBetsState,
  getDisabledSelectedBets,
  getFirstSelectedBet,
  getIsAtLeastOneCancelledSelectedBet,
  getIsGameBetSlip,
  getIsPendingNotifications,
  getIsSelectedBetsPlacementValid,
  getNewSelectedBetsAmount,
  getSelectedBets
} from 'redux/modules/betslip/selectors';
import { PlaceBetsState, SelectedBetState } from 'redux/modules/betslip/type';
import { getCurrentGameRound } from 'redux/modules/games/selectors';
import { removeAllPlacedSelectedBets } from 'redux/modules/placement';
import { TPlacementBet } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import { getAccountSettings, getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { TBetslipMarket } from 'types/betslip';
import { isLineBettingType } from 'utils/betslip';

import TotalLiability from '../TotalLiability';

import styles from './styles.module.scss';

const PlaceBetsActions = ({ market }: { market?: TBetslipMarket }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const betslipSpinnerTime = useSelector(getBetslipSpinnerTime);
  const gameDataRound = useSelector(getCurrentGameRound);
  const placeBetsState = useSelector(getBetslipPlaceBetsState);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const firstSelectedBet = useSelector(getFirstSelectedBet);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isGameBetslip = useSelector(getIsGameBetSlip);
  const selectedBetsList = useSelector(getSelectedBets);
  const selectedBetsAmount = useSelector(getNewSelectedBetsAmount);
  const activeSelectedBets = useSelector(getActiveSelectedBets);
  const disabledSelectedBets = useSelector(getDisabledSelectedBets);
  const accountSettings = useSelector(getAccountSettings);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);
  const isPlacementValid = useSelector(getIsSelectedBetsPlacementValid);
  const autoRefreshIsEnabled = useSelector(getUserAsianViewAutoRefresh);
  const isAtLeastOneCancelledSelectedBet = useSelector(getIsAtLeastOneCancelledSelectedBet);
  const focusedButton = useSelector(getBetslipFocusedButton);
  const isPendingNotifications = useSelector(getIsPendingNotifications);

  const confirmBtnRef = useRef<HTMLButtonElement>(null);
  const spinnerTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { isConfirmBetsBeforePlacement, isConfirmBetsEnabled } = useConfirmBets();
  const { placeBetWithEnterKey } = useDeviceSettings();
  const { placeBetLogin } = usePostMessage();
  const marketUnitTranslated = useMarketUnits(market?.description?.lineRangeInfo?.marketUnit ?? 'points');

  const isPlacementEnabled = isPlacementValid;
  const isPlaceBetsWithEnterKeyEnabled = placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;
  const isTakeOffer = isPNCEnabled && placeBetsState === PlaceBetsStates.TAKE_OFFER;
  const isLineMarket = isLineBettingType(market?.description?.bettingType ?? '');
  const showAcceptPriceChangeLabel = useMemo(() => {
    if (isPNCEnabled && autoRefreshIsEnabled && !isGameBetslip) {
      return activeSelectedBets.some(
        ({ isLowerPrice, bettingType }) => !!isLowerPrice && bettingType !== BettingTypes.LINE
      );
    }

    return false;
  }, [isPNCEnabled, autoRefreshIsEnabled, activeSelectedBets, isGameBetslip]);

  const onSuccessPlacement = () => {
    dispatch(setBetslipLoading(false));
    dispatch(setPlaceBetsState(PlaceBetsStates.SELECT));

    if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
      dispatch(fetchBalance());
    }
  };

  const onErrorPlacement = () => {
    dispatch(setBetslipLoading(false));
    dispatch(setPlaceBetsState(PlaceBetsStates.SELECT));
    dispatch(updateSelectedBetsWithData({ selectedBets: activeSelectedBets, data: { state: SelectedBetState.ERROR } }));
  };

  const onCancelledPlacement = () => {
    dispatch(setBetslipLoading(false));
    dispatch(setPlaceBetsState(PlaceBetsStates.SELECT));
    dispatch(setActiveTab(BetslipTabs.PLACE_BETS));
  };

  const placement = usePlacementData({
    successPlacement: onSuccessPlacement,
    errorPlacement: onErrorPlacement,
    onCancelledPlacement,
    onSuccessPlaceBets: () => {
      dispatch(setBetslipLoading(false));
    }
  });

  useEffect(() => {
    if (([PlaceBetsStates.PLACE, PlaceBetsStates.PLACE_ENTER] as PlaceBetsState[]).includes(placeBetsState)) {
      placeAllSelectedBets({ placedUsingEnterKey: placeBetsState === PlaceBetsStates.PLACE_ENTER });
    } else if (placeBetsState === PlaceBetsStates.CONFIRM && confirmBtnRef.current !== null) {
      confirmBtnRef.current.focus();

      dispatch(setBetslipFocusedButton(null));
    }
  }, [placeBetsState, focusedButton]);

  useEffect(() => {
    if (placeBetsState === PlaceBetsStates.PROGRESS) {
      spinnerTimeout.current = setTimeout(() => {
        dispatch(setBetslipLoading(false));
        dispatch(setPlaceBetsState(PlaceBetsStates.SELECT));
      }, +betslipSpinnerTime * 1000);
    } else if (spinnerTimeout.current) {
      clearTimeout(spinnerTimeout.current);
    }
  }, [placeBetsState]);

  useEffect(() => {
    return () => {
      if (spinnerTimeout.current) {
        clearTimeout(spinnerTimeout.current);
        dispatch(setBetslipLoading(false));
        dispatch(setPlaceBetsState(PlaceBetsStates.SELECT));
      }
    };
  }, []);

  useEffect(() => {
    if (isGameBetslip) {
      dispatch(setBetslipLoading(false));
    }
  }, [isGameBetslip, gameDataRound]);

  const getConfirmButtonLabel = () => {
    if (isTakeOffer) {
      return isLineMarket
        ? t('betslip.actions.placeBetAtAvailableUnits', { unit: marketUnitTranslated })
        : t('betslip.actions.placeBetAtAvailableOdds');
    } else {
      return t('betslip.actions.confirmNBets', { N: activeSelectedBets.length || null });
    }
  };

  const cancelAllSelections = () => {
    if (isGameBetslip) {
      dispatch(removeAllGamesSelectedBets());
    } else {
      dispatch(removeAllSportsSelectedBets());
    }

    dispatch(removeAllPlacedSelectedBets());
  };

  const removeAllBets = () => {
    if (isConfirmBetsBeforePlacement) {
      dispatch(setPlaceBetsState(PlaceBetsStates.CONFIRM_REMOVING));
    } else {
      cancelAllSelections();
    }
  };

  const onPlaceBetsClick = () => {
    if (isPlacementValid) {
      if (!isLoggedIn) {
        placeBetLogin();
      } else if (isPlacementEnabled) {
        if (isConfirmBetsBeforePlacement) {
          dispatch(setPlaceBetsState(PlaceBetsStates.CONFIRM));
        } else {
          placeAllSelectedBets();
        }
      }
    }
  };

  const onConfirmBetsClick = () => {
    placeAllSelectedBets();
  };

  const placeAllSelectedBets = (
    { placedUsingEnterKey = false }: { placedUsingEnterKey?: boolean } = { placedUsingEnterKey: false }
  ) => {
    if (!isPendingNotifications) {
      dispatch(removeAllPlacementNotifications());
    }

    dispatch(setPlaceBetsState(PlaceBetsStates.PROGRESS));
    dispatch(setBetslipLoading(true));
    dispatch(
      updateSelectedBetsWithData({ selectedBets: activeSelectedBets, data: { state: SelectedBetState.PROGRESS } })
    );

    const selectedBets: TPlacementBet[] = activeSelectedBets.map(bet => {
      return {
        ...bet,
        side: bet.type,
        price: isPNCEnabled && autoRefreshIsEnabled && !isGameBetslip ? bet.actualPrice : bet.price
      };
    });

    dispatch(setPlacementNotifications(activeSelectedBets));

    if (activeSelectedBets.length === selectedBetsAmount) {
      dispatch(removeAllSelectedBets());
    } else {
      [...activeSelectedBets, ...disabledSelectedBets].forEach(bet => {
        dispatch(removeSelectedBet(bet));
      });
    }

    dispatch(setPlaceBetsState(PlaceBetsStates.SELECT));
    placement.placeBetsHandler({
      bets: selectedBets,
      options: {
        isTakeOffer: isPNCEnabled && placeBetsState === PlaceBetsStates.TAKE_OFFER,
        placedUsingEnterKey
      }
    });
  };

  const onPressButton = (event: KeyboardEvent) => {
    event.preventDefault();

    if (event.key === KEY_CODES.ENTER && isPlaceBetsWithEnterKeyEnabled && isPlacementEnabled) {
      if (placeBetsState === PlaceBetsStates.SELECT) {
        if (isConfirmBetsBeforePlacement) {
          dispatch(setPlaceBetsState(PlaceBetsStates.CONFIRM));
        } else {
          placeAllSelectedBets({ placedUsingEnterKey: true });
        }
      } else if (placeBetsState === PlaceBetsStates.CONFIRM) {
        placeAllSelectedBets({ placedUsingEnterKey: true });
      }
    }
  };

  const onKeyDownButton = (event: KeyboardEvent, isRestartTabNavigation: boolean) => {
    if (event.key === KEY_CODES.TAB && isRestartTabNavigation && firstSelectedBet) {
      event.preventDefault();

      const { marketId, betUuid, type } = firstSelectedBet;

      dispatch(
        updateSelectedBet({
          marketId,
          betUuid,
          type,
          focusedField: !isPNCEnabled || isGameBetslip ? BetFocusFields.PRICE : BetFocusFields.SIZE
        })
      );
    }
  };

  const getButtonTabIndex = (index: number) => {
    return !isPlacementValid && index === tabulation.PLACE_BTN_ORDER
      ? -1
      : ((placeBetsState === PlaceBetsStates.CONFIRM ? activeSelectedBets.length : selectedBetsList.length) || 0) *
          (index === tabulation.PLACE_BTN_ORDER ? tabulation.BET_TABS : tabulation.FULL_BET_TABS) +
          index;
  };

  const handleEdit = () => {
    dispatch(setPlaceBetsState(PlaceBetsStates.SELECT));

    if (isAtLeastOneCancelledSelectedBet) {
      dispatch(removeIsCancelledFromAllSelectedBets());
    }
  };

  const renderActions = () => {
    if (placeBetsState === PlaceBetsStates.SELECT) {
      return (
        <>
          <button
            className={classNames(
              styles.button,
              styles.secondaryBtn,
              branding.BET_BTN,
              branding.CANCEL_ALL_BTN,
              componentsBranding.SECONDARY_BTN
            )}
            onKeyDown={(e: KeyboardEvent) => onKeyDownButton(e, !isConfirmBetsEnabled)}
            onClick={removeAllBets}
            tabIndex={getButtonTabIndex(tabulation.CANCEL_ALL_BTN_ORDER)}
          >
            {t('betslip.actions.cancelSelections')}
          </button>
          <button
            className={classNames(
              styles.button,
              styles.primaryBtn,
              branding.BET_BTN,
              branding.PLACE_BETS_BTN,
              componentsBranding.PRIMARY_BTN,
              {
                [styles.primaryBtn__disabled]: !isPlacementEnabled,
                [branding.DISABLED]: !isPlacementValid,
                [styles.primaryBtn__highlighted]: showAcceptPriceChangeLabel
              }
            )}
            disabled={!isPlacementEnabled}
            onClick={onPlaceBetsClick}
            onKeyPress={onPressButton}
            onKeyDown={(e: KeyboardEvent) => onKeyDownButton(e, !isPlacementEnabled)}
            tabIndex={getButtonTabIndex(tabulation.PLACE_BTN_ORDER)}
          >
            {t(showAcceptPriceChangeLabel ? 'placement.labels.acceptOdds' : 'betslip.actions.placeNBets', {
              N: activeSelectedBets.length || null
            })}
          </button>
        </>
      );
    }

    if (placeBetsState === PlaceBetsStates.CONFIRM_REMOVING) {
      return (
        <>
          <button
            className={classNames(
              styles.button,
              styles.secondaryBtn,
              styles.confirmRemovalBtn,
              componentsBranding.SECONDARY_BTN,
              componentsBranding.CONFIRM_BTN
            )}
            onKeyDown={(e: KeyboardEvent) => onKeyDownButton(e, !isConfirmBetsEnabled)}
            onClick={cancelAllSelections}
            tabIndex={getButtonTabIndex(tabulation.CANCEL_ALL_BTN_ORDER)}
          >
            {t('betslip.labels.confirmRemoval')}
          </button>
          <button
            className={classNames(styles.button, styles.primaryBtn, componentsBranding.PRIMARY_BTN)}
            onClick={() => dispatch(setPlaceBetsState(PlaceBetsStates.SELECT))}
            tabIndex={getButtonTabIndex(tabulation.PLACE_BTN_ORDER)}
          >
            {t('betslip.labels.cancel')}
          </button>
        </>
      );
    }

    return (
      <>
        <button
          className={classNames(styles.button, styles.secondaryBtn, componentsBranding.SECONDARY_BTN)}
          onClick={handleEdit}
          tabIndex={getButtonTabIndex(tabulation.EDIT_BTN_ORDER)}
        >
          {t('betslip.actions.edit')}
        </button>
        <button
          className={classNames(styles.button, styles.primaryBtn, componentsBranding.PRIMARY_BTN, {
            [styles.primaryBtn__highlighted]: activeSelectedBets.length,
            [componentsBranding.CONFIRM_BTN]: activeSelectedBets.length,
            [styles.primaryBtn__disabled]: !activeSelectedBets.length,
            [branding.DISABLED]: !activeSelectedBets.length
          })}
          disabled={!activeSelectedBets.length}
          onClick={onConfirmBetsClick}
          onKeyPress={onPressButton}
          onKeyDown={(e: KeyboardEvent) => onKeyDownButton(e, !isPlacementEnabled)}
          tabIndex={getButtonTabIndex(tabulation.CONFIRM_BTN_ORDER)}
          ref={confirmBtnRef}
        >
          {getConfirmButtonLabel()}
        </button>
      </>
    );
  };

  return (
    <div className={styles.placeBetsActions}>
      <TotalLiability />
      <hr className={styles.dashLine} />
      <div className={styles.buttonsWrap}>{renderActions()}</div>
      <RGModalMessage />
    </div>
  );
};

export default PlaceBetsActions;
