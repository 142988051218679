import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { PageBlocks } from 'constants/app';
import { betslipBranding, inlinePlacementBranding as branding, inlinePlacementBranding } from 'constants/branding';
import { InlinePlacementActions } from 'constants/inlinePlacement';
import { BettingTypes } from 'constants/markets';
import { BetSides } from 'constants/myBets';
import { VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET } from 'constants/placement';
import { useFancySwapColors } from 'hooks/useFancySwap';
import { useInlineSelectedBetView, useIsDisabledInlineSelectedBet } from 'hooks/useInlinePlacement';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { getCurrentBetByOfferId } from 'redux/modules/currentBets/selectors';
import { updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getMarketPricesCurrency } from 'redux/modules/marketsPrices/selectors';
import { removePlacedSelectedBet, removePlacedSelectedMarket } from 'redux/modules/placement';
import { getBetIdentityData, getInlinePlacedOfferStatuses } from 'utils/inlinePlacement';

import InlineBetActions from '../InlineBetActions';
import InlineBetEdit from '../InlineBetEdit';
import InlineBetError from '../InlineBetError';
import InlineBetForm from '../InlineBetForm';
import InlineBetInfo from '../InlineBetInfo';
import InlineBetNotification from '../InlineBetNotification';
import InlineBetRemove from '../InlineBetRemove';
import InlinePlacedBetIcon from '../InlinePlacedBetIcon';

import styles from './styles.module.scss';

type TInlineSelectedBetProps = { bet: TInlineSelectedBet };

const InlineSelectedBet = ({ bet }: TInlineSelectedBetProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentBet = useSelector(getCurrentBetByOfferId(bet?.placedBet?.offerId || 0));
  const marketCurrency = useSelector(getMarketPricesCurrency(bet.marketId));

  const { isMultiCurrencySupported, isMultiCurrencyChanged } = useMultiCurrencySupporting();
  const { isShortView } = useInlineSelectedBetView({ sportId: bet.sportId, pageBlock: bet.pageBlock });

  const { isUnmatched, isPartiallyMatched } = getInlinePlacedOfferStatuses(currentBet ?? bet.placedBet);
  const isBack = bet.type === BetSides.Back;
  const validationError = bet.validationMessage?.text;
  const isPlacedBet = bet.placedBet;

  const isDisabled = useIsDisabledInlineSelectedBet(bet);

  const isCurrencyChanged = isMultiCurrencySupported && isMultiCurrencyChanged(marketCurrency);

  const isFancySwapColors = useFancySwapColors(bet);
  const isBackColor = isFancySwapColors ? !isBack : isBack;
  const isLayColor = isFancySwapColors ? isBack : !isBack;

  const betIdData = getBetIdentityData(bet);

  const isConfirmState = bet.action === InlinePlacementActions.CONFIRM;

  const onCloseValidationErrorHandler = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, validationMessage: null }));
  };

  const onClosePlacementErrorHandler = () => {
    dispatch(updateInlineSelectedBet({ ...betIdData, placementError: '' }));
    dispatch(removePlacedSelectedBet({ betUuid: bet.betUuid ?? '' }));
    dispatch(removePlacedSelectedMarket({ marketId: bet.marketId }));
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      ref.current &&
      (bet.pageBlock === PageBlocks.MULTI_MARKET_VIEW_TOP_MARKET || bet.pageBlock === PageBlocks.MARKET_ODDS) &&
      bet.bettingType === BettingTypes.ASIAN_HANDICAP_DOUBLE_LINE
    ) {
      ref.current.scrollIntoView({ block: 'nearest' });
    }
  }, []);

  return (
    <div
      className={classNames(styles.inlineSelectedBetWrap, {
        [inlinePlacementBranding.PLACED_BET_WRAPPER]: isUnmatched || isPartiallyMatched
      })}
      ref={ref}
    >
      <div
        className={classNames(styles.inlineSelectedBet, branding.WRAPPER, betslipBranding.BET_CONTENT, {
          [styles.inlineSelectedBet__back]: isBackColor,
          [styles.inlineSelectedBet__lay]: isLayColor,
          [styles.inlineSelectedBet__placed]: isPlacedBet,
          [styles.inlineSelectedBet__shortView]: isShortView,
          [styles.inlineSelectedBet__disabled]: isDisabled,
          [betslipBranding.BACK]: isBackColor,
          [betslipBranding.LAY]: isLayColor,
          [betslipBranding.DISABLED]: isDisabled,
          [inlinePlacementBranding.PLACED_BET_BORDER]: isUnmatched || isPartiallyMatched
        })}
      >
        {isShortView && (
          <div className={styles.inlineSelectedBet__topButton}>
            {!isConfirmState ? <InlineBetRemove bet={bet} /> : <InlineBetEdit bet={bet} />}
          </div>
        )}
        <div
          className={classNames(styles.inlineSelectedBet__inner, {
            [styles.inlineSelectedBet__inner__shortView]: isShortView
          })}
        >
          <div className={styles.inlineSelectedBet__info}>
            <InlinePlacedBetIcon bet={bet} />
            <InlineBetInfo bet={bet} />
          </div>
          {!isCurrencyChanged && <InlineBetForm bet={bet} />}
          {isCurrencyChanged && (
            <InlineBetNotification
              message={t(VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET, { currency_ISO_code: marketCurrency })}
            />
          )}
          {validationError && isShortView && (
            <InlineBetError message={validationError} onClose={onCloseValidationErrorHandler} />
          )}
          {bet.placementError && isShortView && (
            <InlineBetError message={bet.placementError} onClose={onClosePlacementErrorHandler} />
          )}
          {!isCurrencyChanged && (
            <div
              className={classNames(styles.inlineSelectedBet__actions, {
                [styles.inlineSelectedBet__actions__shortView]: isShortView
              })}
            >
              <InlineBetActions bet={bet} />
            </div>
          )}
          {!isShortView && <InlineBetRemove bet={bet} />}
        </div>
      </div>
      {validationError && !isShortView && (
        <InlineBetError message={validationError} onClose={onCloseValidationErrorHandler} />
      )}
      {bet.placementError && !isShortView && (
        <InlineBetError message={bet.placementError} onClose={onClosePlacementErrorHandler} />
      )}
    </div>
  );
};

export default InlineSelectedBet;
