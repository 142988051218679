import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import Rules from 'components/Rules';
import { Devices } from 'constants/app';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { fetchFancyMarketRules } from 'redux/modules/fancyMarket';
import { getFancyMarketData } from 'redux/modules/fancyMarket/selectors';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

const FancyMarketRules = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const { rules, rulesLoading, inningsFancy, startDate } = useSelector(getFancyMarketData);

  const [openRulesModal, setOpenRulesModal] = useState<boolean>(false);

  const lastInningsFancyMarket = inningsFancy[inningsFancy.length - 1];

  const marketTime = useLongDateFormat({
    time: startDate,
    hasYear: true,
    divider: ', '
  });

  const handleOpenRulesModal = () => {
    if (!rules?.[lastInningsFancyMarket.marketId]?.rules) {
      dispatch(fetchFancyMarketRules(lastInningsFancyMarket.marketId));
    }
    setOpenRulesModal(true);
  };

  return (
    <>
      {device === Devices.MOBILE ? (
        <i
          className={classNames(
            'biab_mobile-market-info-icon fa2 fa2-info-circle',
            styles.fancyMarketRules__mobileIcon
          )}
          onClick={handleOpenRulesModal}
        >
          <span className="path1" />
          <span className="path2" />
        </i>
      ) : (
        <div onClick={handleOpenRulesModal}>
          <Rules />
        </div>
      )}
      <Modal
        clickableBackground
        open={openRulesModal}
        onClose={() => setOpenRulesModal(false)}
        title={
          rulesLoading
            ? t('marketRules.loading')
            : `${rules?.[lastInningsFancyMarket.marketId]?.marketName || ''} - ${t('rules.title')}`
        }
      >
        {rulesLoading ? (
          <Loader circleColor={CircleColors.BLACK} />
        ) : (
          <>
            {t('marketRules.eventStartTime')}:
            <br />
            {marketTime}
            <br />
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html:
                  rules?.[lastInningsFancyMarket.marketId]?.rules.replace(
                    /CONTEXT_ROOT_PATH/g,
                    getEnvironmentRootPath()
                  ) ?? ''
              }}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default FancyMarketRules;
