import classNames from 'classnames';

import styles from './styles.module.scss';

export const CircleColors = {
  WHITE: 'circle-white',
  BLACK: 'circle-black',
  DARK_GRAY: 'circle-dark-gray',
  BLUE: 'circle-blue',
  BLUE_2: 'circle-blue-2',
  RED: 'circle-red'
} as const;

type CircleColor = typeof CircleColors[keyof typeof CircleColors];

interface ILoader {
  circleColor?: CircleColor;
  className?: string;
  hideBiabClassName?: boolean;
}

const Loader = ({ circleColor = CircleColors.WHITE, className = '', hideBiabClassName }: ILoader) => {
  return (
    <div
      className={classNames(styles.loader, styles[circleColor], className, {
        biab_loader: !hideBiabClassName
      })}
    >
      <div className={classNames(styles.circle, styles.circle1)} />
      <div className={classNames(styles.circle, styles.circle2)} />
      <div className={classNames(styles.circle, styles.circle3)} />
      <div className={classNames(styles.circle, styles.circle4)} />
      <div className={classNames(styles.circle, styles.circle5)} />
      <div className={classNames(styles.circle, styles.circle6)} />
      <div className={classNames(styles.circle, styles.circle7)} />
      <div className={classNames(styles.circle, styles.circle8)} />
    </div>
  );
};

export default Loader;
