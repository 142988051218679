import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import PricesRow from 'components/GameMarketTable/partials/PricesRow';
import StatusRow from 'components/GameMarketTable/partials/StatusRow';
import MobileOpenBets from 'components/MobileOpenBets';
import MobilePlacement from 'components/MobilePlacement';
import WhatIfGames from 'components/WhatIfGames';
import { Devices, PageBlocks, PlacementPages } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { GameSelectionStatuses } from 'constants/games';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsCurrentBetsBySelection } from 'redux/modules/currentBets/selectors';
import { getCurrentGameMarket } from 'redux/modules/games/selectors';
import { TSelection } from 'redux/modules/games/type';
import { getInlineSelectedBetBySelection } from 'redux/modules/inlinePlacement/selectors';

import styles from '../../styles.module.scss';

interface IGameMarketRow {
  isFirstSelection: boolean;
  selection: TSelection;
  hide?: boolean;
}

const GameMarketRow = ({
  selection: { id, name, bestAvailableToBackPrices, bestAvailableToLayPrices, status },
  isFirstSelection,
  hide = false
}: IGameMarketRow) => {
  const gameMarket = useSelector(getCurrentGameMarket);
  const device = useSelector(getAppDevice);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const inlineSelectedBet = useSelector(
    getInlineSelectedBetBySelection(PageBlocks.GAME, String(gameMarket?.id), id, null)
  );
  const isMatchedBetsBySelectionId = useSelector(
    getIsCurrentBetsBySelection({
      type: MatchTypes.MATCHED,
      marketId: gameMarket?.id.toString() || '',
      selectionId: id,
      handicap: 0
    })
  );
  const isUnmatchedBetsBySelectionId = useSelector(
    getIsCurrentBetsBySelection({
      type: MatchTypes.UNMATCHED,
      marketId: gameMarket?.id.toString() || '',
      selectionId: id,
      handicap: 0
    })
  );

  const [isBetslipClosing, setIsBetslipClosing] = useState<boolean>(false);

  const isMobile = device === Devices.MOBILE;
  const isMobileOpenBetsVisible =
    isMobile &&
    !!gameMarket?.id &&
    isLoggedIn &&
    (isBetslipClosing || !inlineSelectedBet) &&
    (isMatchedBetsBySelectionId || isUnmatchedBetsBySelectionId);
  const dontShowWhatIf = GameSelectionStatuses.LOSER === status || GameSelectionStatuses.WINNER === status;
  const tieSelectionId =
    gameMarket?.marketType === 'SINGLE_WINNER_OR_TIE' ? gameMarket?.selections.at(-1)?.id : undefined;

  return (
    <div className={classNames({ hidden: hide })}>
      <div className={styles.marketRow}>
        <div className={styles.name}>
          <div>{name}</div>
          {!dontShowWhatIf && gameMarket && (
            <WhatIfGames
              selectionId={id}
              marketId={gameMarket.id.toString()}
              commissionRate={gameMarket.commissionRate}
              currency={gameMarket.currency}
              marketType={gameMarket?.description?.marketType}
              tieSelectionId={tieSelectionId}
            />
          )}
        </div>
        <div className={styles.rowData}>
          {status === GameSelectionStatuses.IN_PLAY ? (
            <PricesRow
              selectionId={id}
              name={name}
              isFirstSelection={isFirstSelection}
              bestAvailableToBackPrices={bestAvailableToBackPrices}
              bestAvailableToLayPrices={bestAvailableToLayPrices}
            />
          ) : (
            <StatusRow status={status} />
          )}
        </div>
      </div>
      {inlineSelectedBet && isMobile && (
        <MobilePlacement
          bet={inlineSelectedBet}
          pageBlock={PageBlocks.GAME}
          onClosingBetslip={setIsBetslipClosing}
          page={PlacementPages.GAME}
          containerClassName={styles.mobilePlacement}
        />
      )}
      {isMobileOpenBetsVisible && (
        <MobileOpenBets
          marketId={gameMarket?.id.toString()}
          selectionId={id}
          handicap={0}
          pageBlock={PageBlocks.GAME}
          page={PlacementPages.GAME}
          containerClassName={styles.mobilePlacement}
        />
      )}
    </div>
  );
};

export default GameMarketRow;
