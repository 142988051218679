import { CookieSetOptions } from 'universal-cookie';

import { CookieNames } from 'constants/app';
import { iosBundleRedirect } from 'utils';
import { parseURLSearch } from 'utils/url';

export const setIOSBundleInitialCookies = (
  setCookie: (name: string, value: any, options?: CookieSetOptions) => void,
  search: string
) => {
  const apiURL = window.environmentConfig?.apiUrl || process.env.REACT_APP_API_URL;
  const useHeadersInsteadOfCookies = window.environmentConfig?.useHeadersInsteadOfCookies;

  if ((!apiURL && iosBundleRedirect) || useHeadersInsteadOfCookies) {
    const options = { path: '/' };
    const parsedSearch = parseURLSearch(search);
    const tokenParamName =
      window.environmentConfig?.tokenParamName || (process.env.REACT_APP_TOKEN_PARAM_NAME as string);
    const localeParamName =
      window.environmentConfig?.localeParamName || (process.env.REACT_APP_LOCALE_PARAM_NAME as string);
    const proxyParamName =
      window.environmentConfig?.proxyParamName || (process.env.REACT_APP_PROXY_PARAM_NAME as string);
    const timezoneParamName =
      window.environmentConfig?.timezoneParamName || (process.env.REACT_APP_TIMEZONE_PARAM_NAME as string);
    const licenseParamName =
      window.environmentConfig?.licenseParamName || (process.env.REACT_APP_LICENSE_PARAM_NAME as string);

    if (parsedSearch[tokenParamName]) {
      setCookie(CookieNames.TOKEN, parsedSearch[tokenParamName], options);
    }

    if (parsedSearch[localeParamName]) {
      setCookie(CookieNames.LANGUAGE, parsedSearch[localeParamName], options);
    }

    if (parsedSearch[proxyParamName]) {
      setCookie(CookieNames.API_URL, parsedSearch[proxyParamName], options);
    }

    if (parsedSearch[timezoneParamName]) {
      setCookie(CookieNames.TIMEZONE, parsedSearch[timezoneParamName], options);
    }

    if (parsedSearch[licenseParamName]) {
      setCookie(CookieNames.LICENSE, parsedSearch[licenseParamName], options);
    }
  }
};

export const getLanguageCookieName = (): string => {
  return window.environmentConfig?.langCookieName || (process.env.REACT_APP_LANG_COOKIE_NAME as string);
};

export const getCollapsedCookie = (marketId: string, value: boolean, cookies?: string) => {
  let currentCookie = cookies || '';

  if (currentCookie && currentCookie.indexOf(marketId) !== -1) {
    const oldCookie = currentCookie.split(',').find((cookie: string) => cookie.split('=')[0] === marketId);
    const newCookie = `${marketId}=${value}`;

    currentCookie = currentCookie.replace(oldCookie ?? '', newCookie);
  } else {
    currentCookie += `${currentCookie ? ',' : ''}${marketId}=${value}`;
  }

  return currentCookie;
};
