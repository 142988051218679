import { useRef, useState } from 'react';
import BodyClassName from 'react-body-classname';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import { SportIds } from 'constants/app';
import { MOBILE_VIEW_BY_DROPDOWN_MARGIN, MOBILE_VIEW_BY_DROPDOWN_WIDTH } from 'constants/asianView';
import { asianViewMiddleSection } from 'constants/branding';
import { getTimezone, getTimezoneCookieEnabled, getWindowHeight } from 'redux/modules/appConfigs/selectors';
import { getNavigationEventsCountersBySportId } from 'redux/modules/asianSportsNavigation/selectors';
import { getAsianSectionSize, getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { getAsianMobileNavigation } from 'utils/asianView';
import { applyTimezone } from 'utils/date';

import ViewByLink from './components/ViewByLink';
import ViewByPlaceholder from './components/ViewByPlaceholder';

import styles from './styles.module.scss';

const ViewByDropdown = () => {
  const { t } = useTranslation();
  const { sportId = SportIds.SOCCER } = useParams();

  const eventsCounter = useSelector(getNavigationEventsCountersBySportId(sportId));
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);
  const sectionSize = useSelector(getAsianSectionSize);
  const windowHeight = useSelector(getWindowHeight);

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownPopUpRef = useRef<HTMLDivElement>(null);

  const sectionWidth = sectionSize?.width ?? 0;
  const sectionTop = sectionSize?.top ?? 0;

  const date = applyTimezone(new Date(), timezone, timezoneCookieEnabled);
  const navItems = getAsianMobileNavigation({ eventsCounter, date, t });
  const curWidth = sectionWidth - MOBILE_VIEW_BY_DROPDOWN_MARGIN;
  const width = curWidth > MOBILE_VIEW_BY_DROPDOWN_WIDTH ? MOBILE_VIEW_BY_DROPDOWN_WIDTH : curWidth;
  const left = sectionWidth * 0.5 - (dropdownRef.current?.offsetLeft ?? 0) - width / 2;
  const LANDSCAPE_PADDING_BOTTOM = 10;
  const maxHeight = windowHeight - sectionTop - LANDSCAPE_PADDING_BOTTOM;

  return (
    <>
      <div className={styles.viewDropdownWrap} ref={dropdownRef}>
        <ViewByPlaceholder isOpened={isOpened} setIsOpened={setIsOpened} />
        {isOpened && !!navItems.length && (
          <div
            ref={dropdownPopUpRef}
            className={classNames(styles.viewDropdown, { [styles.viewDropdown__landscape]: isLandscapeView })}
            style={!isLandscapeView ? { width, left } : { width, maxHeight }}
          >
            <div className={classNames(styles.viewDropdown__content, asianViewMiddleSection.VIEW_BY_DROPDOWN)}>
              {navItems.map(navItem => (
                <div key={navItem.type}>
                  <ViewByLink navLink={navItem} setIsOpened={setIsOpened} />
                  {!!navItem.subNavItems?.length &&
                    navItem.subNavItems.map(dayItem => (
                      <ViewByLink navLink={dayItem} setIsOpened={setIsOpened} key={dayItem.timeFrame} />
                    ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {isOpened && <div className={styles.backdrop} onClick={() => setIsOpened(false)} />}
      <BodyClassName className={classNames({ disableScroll: isOpened })} />
    </>
  );
};

export default ViewByDropdown;
