import { useSelector } from 'react-redux';

import FavoriteStar from 'components/FavoriteStar';
import styles from 'components/SportsSection/styles.module.scss';
import { PopularEventTypes } from 'constants/app';
import { TCompetitionListItem } from 'redux/modules/competitions/type';
import { getFavoriteById } from 'redux/modules/favorites/selectors';

interface FavouriteInDropdownProps {
  item: TCompetitionListItem;
  sportId: string;
}

const FavouriteInDropdown = ({ item, sportId }: FavouriteInDropdownProps) => {
  const isFavorite = useSelector(getFavoriteById(item.id));

  const favoriteData = {
    entryId: item.id,
    sportId: sportId,
    starred: !isFavorite,
    entryName: item.name,
    entryType: PopularEventTypes.COMPETITION
  };

  return (
    <div className={styles.sportsList__favoriteItem}>
      <FavoriteStar className={styles.collapsibleRow__starIcon} entryData={favoriteData} isNavigation={true} />
    </div>
  );
};

export default FavouriteInDropdown;
