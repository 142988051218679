import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import Rules from 'components/Rules';
import { Devices } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import { CASH_OUT } from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { IMarket } from 'types/markets';

import styles from './styles.module.scss';

interface ISubHeader {
  name?: string;
  inPlay: boolean;
  market: IMarket;
  isMarketClosed: boolean;
  openMarketRulesModal: (marketId: string) => void;
}

const SubHeader = ({ name = '', inPlay = false, market, isMarketClosed = false, openMarketRulesModal }: ISubHeader) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const device = useSelector(getAppDevice);

  const { translationKey, isEnabled } = useTooltip(CASH_OUT);

  const onNavigateToMarket = () => {
    navigate(`${SPORT_BASE_URL}/${market.eventType.id}/market/${market.marketId}`);
  };

  const onOpenMarketRulesModal = () => {
    openMarketRulesModal(market.marketId);
  };

  return (
    <div className={classNames(styles.sybHeader, 'biab_cash-out-page-market-name')}>
      <a className={styles.name}>
        {device === Devices.DESKTOP ? (
          <strong>{name}</strong>
        ) : (
          <strong className={styles.name} onClick={onNavigateToMarket}>
            {name}
          </strong>
        )}
      </a>
      <div className={styles.rightSide}>
        {isMarketClosed ? (
          <span className={'biab_closed-market-status'}>{t('market.status.closed')}</span>
        ) : (
          <>
            {device === Devices.DESKTOP ? (
              <>
                <div
                  className={classNames('biab_cash-out-inplay-icon', styles.inPlay, {
                    biab_inplay: inPlay
                  })}
                >
                  <i className="fa2 fa2-inplay" />
                </div>
                <div className={styles.cashOut}>
                  <span
                    data-tooltip-id="tooltip"
                    data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
                    className={classNames('biab_cashout-icon', styles.cashOutIcon, {
                      'cursor-help': isEnabled
                    })}
                    title={t('market.cashOut')}
                  >
                    <i />
                  </span>
                </div>
                <div className={styles.rules}>
                  <div onClick={() => onOpenMarketRulesModal()}>
                    <Rules />
                  </div>
                </div>
              </>
            ) : (
              <a>
                <i onClick={onNavigateToMarket} className={classNames(styles.arrow, 'fa2 fa2-arrow-right')} />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SubHeader;
