import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { accountPagesBranding } from 'constants/branding';
import { BettingTypes } from 'constants/markets';
import { MY_BETS_CONTENT_SM_WIDTH_DESKTOP, myBetsTableHeader } from 'constants/myBets';
import {
  getDesktopSettingsLineMarketsSwitchBackLayOnCricket,
  getDesktopSettingsReplaceBackLayWithUnderOver,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';
import { OfferStates } from 'types/myBets';
import { betsSideValue, getStatusTranslationKey } from 'utils/myBetsValues';

import styles from './styles.module.scss';

type TBetInfoRowProps = {
  bet: TMyBetsContentItem;
  isRowHovered?: boolean;
  onCancelBet?: () => void;
  windowWidth: number;
};

const BetInfoRow = ({ bet, isRowHovered, onCancelBet, windowWidth }: TBetInfoRowProps) => {
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const lineMarketsSwitchBackLayOnCricket = useSelector(getDesktopSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getDesktopSettingsReplaceBackLayWithUnderOver);

  const isSmallScreenView = windowWidth <= MY_BETS_CONTENT_SM_WIDTH_DESKTOP;
  const { betType, groupName, marketId, triggeredByCashOut, cancelledByOperator } = bet;
  const placementLabel = t(`account.mybets.labels.${bet.inPlay ? 'live' : 'prePlay'}Placement`);
  const isCancelBetAvailable = bet.offerState === OfferStates.PLACED && !!isRowHovered && !!onCancelBet;

  const labels = [];

  if (isSmallScreenView) {
    labels.push(t(betsSideValue({ item: bet, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver })));
  }

  if (bet.asian) {
    labels.push(placementLabel);
  }

  if (groupName) {
    labels.push(groupName);
  }

  if (betType === BettingTypes.GAME) {
    labels.push(`${t('games.gameId')}: ${marketId}`);
  }

  if (isSmallScreenView) {
    labels.push(`${t(myBetsTableHeader.REF_ID)}: ${bet.offerId}`);
  }

  if (isSmallScreenView && !cancelledByOperator && !isCancelBetAvailable) {
    labels.push(t(getStatusTranslationKey(bet.offerState, isPNCEnabled)));
  } else if (cancelledByOperator) {
    labels.push(t('account.mybets.labels.cancelledByOperator'));
  }

  return (
    <div className={styles.wrap}>
      {triggeredByCashOut && (
        <span className={classNames(styles.cashedOut, accountPagesBranding.CASH_OUT_LABEL)}>
          {t('account.myBets.labels.cashOut')}
        </span>
      )}
      <span>
        {labels.join(' | ')}
        {isCancelBetAvailable && isSmallScreenView && ' | '}
      </span>
      {isCancelBetAvailable && isSmallScreenView && (
        <button className={styles.cancelBet} onClick={onCancelBet}>
          {t('account.mybets.btns.cancelBet')}
        </button>
      )}
    </div>
  );
};

export default BetInfoRow;
