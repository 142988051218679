import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewMobileMenuButton from 'components/AsianViewMobileHeader/partials/AsianViewMobileMenuButton';
import AsianViewPopularLinks from 'components/AsianViewPopularLinks';
import BannerContent from 'components/BannersContainer/components/BannerContent';
import AsianToolbarWrapper from 'components/MobileToolbar/components/AsianToolbarWrapper';
import { BannerNames, ElementNames, SportIds } from 'constants/app';
import { AsianViewMarketLinks, AsianViewTimeFilters } from 'constants/asianView';
import { asianViewMiddleSection } from 'constants/branding';
import { ASIAN_BASE_URL, GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import {
  getAsianViewMobileNavigationMenu,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsIframeEnabled,
  getIsPropertiesLoaded,
  getPositionOfAsianViewMobileNavigationMenu
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import {
  getAsianViewPopularLinks,
  getIsAsianViewPopularLinksLoaded
} from 'redux/modules/asianViewPopularLinks/selectors';
import { getBannerByName } from 'redux/modules/banners/selectors';

import styles from './styles.module.scss';

const AsianViewMobileMorePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const popularLinks = useSelector(getAsianViewPopularLinks);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const banner = useSelector(getBannerByName(BannerNames.homePage));
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const isPopularLinksLoaded = useSelector(getIsAsianViewPopularLinksLoaded);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const headerHeight = useSelector(getElementHeightByName(ElementNames.MOBILE_HEADER_HEIGHT));
  const positionOfAsianViewMobileNavigationMenu = useSelector(getPositionOfAsianViewMobileNavigationMenu);
  const asianViewMobileNavigationMenu = useSelector(getAsianViewMobileNavigationMenu);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const hasMenu = isLandscapeView || (positionOfAsianViewMobileNavigationMenu && asianViewMobileNavigationMenu);
  const isBannerSection = !!banner?.bannerImages?.length || !!banner?.bannerHtmlContent?.content;
  const isSwitchToSection = isExchangeGamesEnabled || isExchangeSportsEnabled;

  const [componentRef, { height }] = useElementSize<HTMLDivElement>();

  useEffect(() => {
    dispatch(setElementHeight({ name: ElementNames.MOBILE_ASIAN_SUB_HEADER, height }));
  }, [height]);

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.MOBILE_ASIAN_SUB_HEADER, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (isPropertiesLoaded && isPopularLinksLoaded && !popularLinks.length && !isSwitchToSection && !isBannerSection) {
      navigate(
        `${ASIAN_BASE_URL}/sport/${SportIds.SOCCER}/timeFilter/${AsianViewTimeFilters.Today}/marketLink/${AsianViewMarketLinks.HDP_AND_OU}`
      );
    }
  }, [popularLinks.length, isSwitchToSection, isBannerSection, isPropertiesLoaded]);

  return (
    <>
      {hasMenu && (
        <AsianToolbarWrapper>
          {!isLandscapeView && (
            <div ref={componentRef} className={classNames(styles.header, asianViewMiddleSection.MOBILE_SUB_HEADER)}>
              <AsianViewMobileMenuButton />
              <div className={styles.header__left}>
                <i className="ion-ios-more" data-class-nav-icon="ion-ios-more" />
                <h3 className={styles.header__title}>{t('asianView.labels.navigation.more')}</h3>
              </div>
            </div>
          )}
        </AsianToolbarWrapper>
      )}
      <div
        className={classNames(styles.morePage, asianViewMiddleSection.MORE_PAGE)}
        style={{ minHeight: `calc(100dvh - ${headerHeight + height}px)` }}
      >
        {isBannerSection && (
          <section className={styles.banner}>
            <BannerContent
              bannerName={BannerNames.homePage}
              isIframeEnabled={isIframeEnabled}
              containerClassName={styles.banner__container}
            />
          </section>
        )}
        {isSwitchToSection && (
          <>
            <p
              className={classNames(styles.title, {
                [styles.title__noTopMargin]: !isBannerSection
              })}
            >
              {t('asianView.labels.morePage.switchTo')}
            </p>
            <section className={styles.switchTo}>
              {isExchangeSportsEnabled && (
                <Link
                  to={HOME_BASE_URL}
                  className={classNames(styles.switchTo__item, asianViewMiddleSection.SWITCH_TO)}
                >
                  <i className="biab_sport-icon fa2 fa2-football" />
                  <span>{t('asianView.labels.morePage.switchTo.sports')}</span>
                </Link>
              )}
              {isExchangeGamesEnabled && (
                <Link
                  to={GAMES_BASE_URL}
                  className={classNames(styles.switchTo__item, asianViewMiddleSection.SWITCH_TO)}
                >
                  <i className="av-icon av-icon-games" />
                  <span>{t('asianView.labels.morePage.switchTo.games')}</span>
                </Link>
              )}
            </section>
          </>
        )}
        {!!popularLinks.length && (
          <>
            <p
              className={classNames(styles.title, {
                [styles.title__noTopMargin]: !isSwitchToSection && !isBannerSection
              })}
            >
              {t('navigation.popularLinks')}
            </p>
            <section className={classNames(styles.popularLinks, asianViewMiddleSection.POPULAR_LINKS)}>
              <AsianViewPopularLinks
                linkClassName={styles.popularLinks__item}
                hideHeader
                arrowClassName={styles.arrow}
                noTruncate
              />
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default AsianViewMobileMorePage;
