import { useSelector } from 'react-redux';

import { getIsSportLayOddsDisabled } from 'redux/modules/appConfigs/selectors';
import { getIsMarketLayOdsDisabled } from 'redux/modules/marketsPrices/selectors';
import { isLineBettingType } from 'utils/betslip';

const useLayColumn = ({
  sportId,
  bettingType,
  marketId
}: {
  sportId?: string;
  bettingType?: string;
  marketId?: string;
}) => {
  const isDisabledLayOdds = useSelector(getIsSportLayOddsDisabled(sportId));

  const isLineMarket = !!bettingType && isLineBettingType(bettingType);

  const isMarketLayOddsDisabled = useSelector(getIsMarketLayOdsDisabled(marketId));

  return {
    isLayColumnEnabled: (!isMarketLayOddsDisabled && !isDisabledLayOdds) || isLineMarket,
    isLineMarket
  };
};

export default useLayColumn;
