import { createSelector } from '@reduxjs/toolkit';
import { isEmpty, sortBy } from 'lodash';

import { AsianPlacedQuickBettingStatuses } from 'constants/asianView';
import { AppState } from 'redux/reducers';

export const getAsianQuickBettingPlacedBets = (state: AppState) => state.asianViewQuickBetting.placedBets;

export const getAsianQuickBettingItem = (identifier: string) => (state: AppState) =>
  state.asianViewQuickBetting.quickBets[identifier];

export const getAsianQuickPlacedBetsList = createSelector(getAsianQuickBettingPlacedBets, placedBets =>
  sortBy(Object.values(placedBets), 'order')
    .filter(
      bet =>
        (bet.status === AsianPlacedQuickBettingStatuses.CREATED ||
          bet.status === AsianPlacedQuickBettingStatuses.PLACE ||
          bet.status === AsianPlacedQuickBettingStatuses.PLACED ||
          bet.status === AsianPlacedQuickBettingStatuses.ERROR) &&
        !bet.isClosed
    )
    .reverse()
);

export const getAsianQuickPlacedBetsNotifications = createSelector(getAsianQuickBettingPlacedBets, placedBets =>
  sortBy(Object.values(placedBets), 'updateTime')
    .filter(
      bet =>
        (bet.status === AsianPlacedQuickBettingStatuses.CREATED ||
          bet.status === AsianPlacedQuickBettingStatuses.PLACE ||
          bet.status === AsianPlacedQuickBettingStatuses.PLACED ||
          bet.status === AsianPlacedQuickBettingStatuses.ERROR) &&
        !bet.isHidden
    )
    .reverse()
);

export const getAsianQuickPlacedBetsProgressList = createSelector(getAsianQuickBettingPlacedBets, placedBets =>
  sortBy(
    Object.values(placedBets).filter(
      bet =>
        bet.status !== AsianPlacedQuickBettingStatuses.PLACED && bet.status !== AsianPlacedQuickBettingStatuses.ERROR
    ),
    'order'
  ).reverse()
);

export const getAsianQuickPlacedBetsPendingList = createSelector(getAsianQuickBettingPlacedBets, placedBets =>
  sortBy(
    Object.values(placedBets).filter(
      bet =>
        (bet.status !== AsianPlacedQuickBettingStatuses.PLACED &&
          bet.status !== AsianPlacedQuickBettingStatuses.ERROR) ||
        (bet.status === AsianPlacedQuickBettingStatuses.PLACED && !bet.offer)
    ),
    'order'
  ).reverse()
);

export const getAsianQuickPlacedBetsWithoutOffer = createSelector(getAsianQuickBettingPlacedBets, placedBets =>
  sortBy(
    Object.values(placedBets).filter(bet => bet.status === AsianPlacedQuickBettingStatuses.PLACED && !bet.offer),
    'order'
  ).reverse()
);

export const getAsianActiveQuickPlacedBetIndex = (state: AppState) => state.asianViewQuickBetting.activePlacedBetIndex;

export const getAsianActiveQuickPlacedBet = createSelector(
  getAsianQuickPlacedBetsList,
  getAsianActiveQuickPlacedBetIndex,
  (bets, activePlacedBetIndex) => bets[activePlacedBetIndex]
);

export const getAsianQuickPlacedBetsAmount = createSelector(getAsianQuickPlacedBetsList, bets => bets.length);
export const getIsAsianQuickPlacedBets = createSelector(getAsianQuickPlacedBetsList, bets => !!bets.length);
export const getIsAsianQuickProgressBets = createSelector(getAsianQuickPlacedBetsPendingList, bets => !!bets.length);

export const getAsianIsPlacedBetsLoading = (state: AppState) => state.asianViewQuickBetting.isPlacedBetsLoading;

export const getIsAsianQuickPlacedBetsEmpty = (state: AppState) => isEmpty(state.asianViewQuickBetting.placedBets);
