import { TFailureActionPayload } from 'types';

export interface PageContentType {
  content: string;
  name: string;
  information?: string;
}

export type TPage = {
  loading: boolean;
  error: TFailureActionPayload;
  data: null | PageContentType;
};

export const PagesFromBackend = {
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  FORBIDDEN: 'FORBIDDEN',
  UNAUTHORIZED: 'UNAUTHORIZED',
  AUTO_CASH_OUT: 'AUTO_CASH_OUT',
  PARTIAL_CASH_OUT_RULES: 'PARTIAL_CASH_OUT_RULES',
  CASH_OUT_RULES: 'CASH_OUT_RULES',
  LAY_BETTING_RULES_PAGE: 'LAY_BETTING_RULES_PAGE',
  RG_TOOLS_SELF_EXCLUSION_END: 'RG_TOOLS_SELF_EXCLUSION_END',
  RG_TOOLS_SELF_EXCLUSION_START: 'RG_TOOLS_SELF_EXCLUSION_START',
  RG_TOOLS_TIME_LIMIT_END: 'RG_TOOLS_TIME_LIMIT_END'
} as const;

export type PageFromBackend = typeof PagesFromBackend[keyof typeof PagesFromBackend];

export interface Pages {
  [PagesFromBackend.PAGE_NOT_FOUND]: string;
  [PagesFromBackend.FORBIDDEN]: string;
  [PagesFromBackend.UNAUTHORIZED]: string;
  [PagesFromBackend.AUTO_CASH_OUT]: string;
  [PagesFromBackend.LAY_BETTING_RULES_PAGE]: string;
  GENERAL_ERROR?: string;
  COMMISSION?: string;
  GAME_RULES?: string;
  [PagesFromBackend.CASH_OUT_RULES]: string;
  CASH_OUT_POP_UP?: string;
  [PagesFromBackend.PARTIAL_CASH_OUT_RULES]: string;
  RULES?: string;
  [PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START]: string;
  [PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_END]: string;
  [PagesFromBackend.RG_TOOLS_TIME_LIMIT_END]: string;
}
