import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { MatchStatuses } from 'constants/app';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataElapsedAddedTime,
  getEventsUpdatedDataElapsedRegularTime,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataPeriod,
  getHomeScoreByEventId
} from 'redux/modules/marketsPrices/selectors';
import { EventScorePeriod, TEventScorePeriod } from 'types';

import styles from './styles.module.scss';

type SoccerMarketScoreProps = {
  isCashOut?: boolean;
  eventId: string;
};

export const getSoccerLabel = ({
  isFullText = false,
  t,
  elapsedAddedTime,
  elapsedRegularTime,
  inPlayMatchStatus,
  period
}: {
  elapsedRegularTime?: number | null;
  elapsedAddedTime?: number | null;
  inPlayMatchStatus?: string | null;
  t: TFunction;
  isFullText?: boolean;
  period: TEventScorePeriod;
}) => {
  const isHalfTime = inPlayMatchStatus === MatchStatuses.HALF_TIME;
  const isFinished = inPlayMatchStatus === MatchStatuses.END;
  const addedTime = elapsedAddedTime ? `+${elapsedAddedTime}` : '';

  if (isHalfTime) {
    return t(isFullText ? 'runningBall.halfTime' : 'runningBall.list.ht');
  } else if (isFinished) {
    return t(isFullText ? 'runningBall.finished' : 'runningBall.list.end');
  } else if (typeof elapsedRegularTime === 'number') {
    return `${elapsedRegularTime}'${period !== EventScorePeriod.EXTRA ? addedTime : ''}`;
  }

  return '';
};

const SoccerMarketScore = ({ eventId, isCashOut }: SoccerMarketScoreProps) => {
  const { t } = useTranslation();

  const homeScore = useSelector(getHomeScoreByEventId(eventId));
  const awayScore = useSelector(getAwayScoreByEventId(eventId));
  const elapsedRegularTime = useSelector(getEventsUpdatedDataElapsedRegularTime(eventId));
  const elapsedAddedTime = useSelector(getEventsUpdatedDataElapsedAddedTime(eventId));
  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(eventId));
  const period = useSelector(getEventsUpdatedDataPeriod(eventId));

  return isCashOut ? (
    <table className={classNames('biab_soccer', styles.soccerCashOut)}>
      <tbody>
        <tr>
          <td rowSpan={2} className={styles.soccerCashOut__time}>
            {getSoccerLabel({ t, elapsedAddedTime, elapsedRegularTime, inPlayMatchStatus, period })}
          </td>
          <td className={classNames('biab_score', styles.soccerCashOut__score)}>{homeScore}</td>
        </tr>
        <tr>
          <td className={classNames('biab_score', styles.soccerCashOut__score)}>{awayScore}</td>
        </tr>
      </tbody>
    </table>
  ) : (
    <div className={classNames('biab_soccer', styles.soccer)}>
      <span className={styles.soccer__time}>
        {getSoccerLabel({ t, elapsedAddedTime, elapsedRegularTime, inPlayMatchStatus, period })}
      </span>
      <div className={classNames('biab_score', styles.soccer__ScoreBlock)}>
        <span className={styles.soccer__score}>{homeScore}</span>
        <span className={styles.soccer__score}>{awayScore}</span>
      </div>
    </div>
  );
};

export default SoccerMarketScore;
