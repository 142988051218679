import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { difference, entries, isEqual, isUndefined } from 'lodash';

import { CashOutStatuses, SLICES_NAMES } from 'constants/app';
import {
  FetchCashOutMarketsError,
  FetchCashOutMarketsResponse,
  FetchCashOutQuotesPayload,
  FetchCashOutQuotesResponse,
  FetchCashOutStatusResponse
} from 'redux/modules/asianViewBetSlipCashOut/type';
import {
  AutoCashOut,
  CashOutMarket,
  CashOutQuote,
  ProfitPayload,
  ProfitSelection,
  TSetCashOut
} from 'redux/modules/cashOut/type';
import { CashOutStatus, TFailureActionPayload } from 'types';
import { TFetchMarketRulesResponse } from 'types/markets';
import { getContentCashOutPage } from 'utils/cashOut';

import { EventCashOutState, FetchEventCashOutMarketsPayload } from './type';

const initialState: EventCashOutState = {
  quotesLoading: false,
  statusLoading: {},
  isFirstQuotesLoaded: false,
  isFirstMarketsLoaded: false,
  quotesError: null,
  marketsError: null,
  marketsData: {
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: null,
    totalElements: 0,
    totalPages: 0,
    content: []
  },
  quotes: {},
  stringifiedQuotes: '',
  whatIf: {},
  whatIfMarketId: null,
  possibleProfits: {},
  cashedValues: {},
  autoCashOutMarkets: {},
  stringifiedAutoCashOutMarkets: '',
  placedIds: {},
  successFullCreatedAutoCashOuts: {},
  successFullDeletedAutoCashOuts: {},
  pendingCashOuts: {},
  cashOutStatuses: {},
  paginationLoading: false,
  settingTabsStates: {},
  rules: {},
  rulesLoading: false,
  rulesError: null,
  cashOutLoading: false,
  autoCashOutLoading: false,
  marketsLoading: false,
  autoCashOutMarketsError: null,
  cashOutMarketLoading: false,
  cashOutMarketError: null,
  cashOutStatusLoading: false,
  cashOutStatusError: null,
  createAutoCashOutError: null,
  deleteAutoCashOutError: null
};

const slice = createSlice({
  name: SLICES_NAMES.EVENT_CASH_OUT,
  initialState,
  reducers: {
    fetchEventCashOutQuotes: (state, { payload }: PayloadAction<FetchCashOutQuotesPayload>) => {
      state.quotesLoading = true;

      if (payload.firstLoading) {
        state.isFirstQuotesLoaded = false;
      }
    },
    successFetchEventCashOutQuotes: (state, { payload }: PayloadAction<FetchCashOutQuotesResponse>) => {
      state.quotesLoading = false;

      const stringifiedPayloadQuotes = payload.isWebSocketResponse ? '' : JSON.stringify(payload.quotes);

      if (
        payload.isWebSocketResponse ||
        !state.stringifiedQuotes ||
        state.stringifiedQuotes !== stringifiedPayloadQuotes
      ) {
        if (!payload.isWebSocketResponse) {
          state.stringifiedQuotes = stringifiedPayloadQuotes;
        }

        const statusMarketIds = Object.keys(state.cashOutStatuses);
        const newQuotes = payload.quotes.reduce<Record<string, CashOutQuote>>(
          (acc, item) => ({
            ...acc,
            [item.marketId]: item
          }),
          {}
        );
        const oldQuoteMarketIds = difference(statusMarketIds, Object.keys(newQuotes));
        const oldQuotes = oldQuoteMarketIds.reduce<Record<string, CashOutQuote>>(
          (acc: Record<string, CashOutQuote>, marketId: string) =>
            state.quotes[marketId]
              ? {
                  ...acc,
                  [marketId]: state.quotes[marketId]
                }
              : acc,
          {}
        );

        state.quotes = { ...oldQuotes, ...newQuotes };
      }

      if (payload.firstLoaded) {
        state.isFirstQuotesLoaded = true;
      }
    },
    failureFetchEventCashOutQuotes: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.quotesLoading = false;
      state.quotesError = payload;
    },
    fetchEventCashOutMarkets: (state, { payload }: PayloadAction<FetchEventCashOutMarketsPayload>) => {
      if (payload.resetPrev) {
        state.marketsData = initialState.marketsData;
        state.isFirstMarketsLoaded = false;
      }

      if (payload.withLoader) {
        state.paginationLoading = true;
      }

      if (payload.resetSettingsTabs) {
        state.settingTabsStates = initialState.settingTabsStates;
      }

      state.marketsLoading = true;
    },
    successFetchEventCashOutMarkets: (state, { payload }: PayloadAction<FetchCashOutMarketsResponse>) => {
      const isMarketsNotUpdated = isEqual(state.marketsData, payload.markets);

      state.marketsLoading = false;

      if (!state.isFirstMarketsLoaded) {
        state.isFirstMarketsLoaded = true;
      }

      if (payload.withLoader) {
        state.paginationLoading = false;
      }

      if (!isMarketsNotUpdated) {
        const content = getContentCashOutPage(state.marketsData, payload);

        state.marketsData = {
          ...payload.markets,
          content
        };
      }
    },
    failureFetchEventCashOutMarkets: (state, { payload }: PayloadAction<FetchCashOutMarketsError>) => {
      state.marketsError = payload.error;
      state.marketsLoading = false;

      if (payload.withLoader) {
        state.paginationLoading = false;
      }
    },
    hoverEventCashOutBtn: (state, { payload }: PayloadAction<{ marketId: string; selections: ProfitSelection[] }>) => {
      state.whatIf = payload.selections.reduce(
        (acc: Record<string, ProfitSelection>, item) => ({
          ...acc,
          [item.selectionId]: item
        }),
        {}
      );
      state.whatIfMarketId = payload.marketId;
    },
    leaveEventCashOutBtn: state => {
      state.whatIf = {};
      state.whatIfMarketId = null;
    },
    setEventCashOutPossibleProfit: (state, { payload }: PayloadAction<ProfitPayload>) => {
      if (!state.possibleProfits[payload.marketId]) {
        state.possibleProfits[payload.marketId] = {} as ProfitPayload;
      }

      state.possibleProfits[payload.marketId].marketId = payload.marketId;

      if (!isUndefined(payload.value)) {
        state.possibleProfits[payload.marketId].value = payload.value;
      }

      if (!isUndefined(payload.error)) {
        state.possibleProfits[payload.marketId].error = payload.error;
      }

      if (!isUndefined(payload.hideError)) {
        state.possibleProfits[payload.marketId].hideError = payload.hideError;
      }
    },
    eventCreateAutoCashOut: (_, __: PayloadAction<{ marketId: string; profit: number }>) => {},
    successEventCreateAutoCashOut: (state, { payload }: PayloadAction<AutoCashOut>) => {
      state.successFullCreatedAutoCashOuts[payload.marketId] = true;
    },
    failureEventCreateAutoCashOut: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.createAutoCashOutError = payload;
    },
    fetchEventAutoCashOutMarkets: (_, __: PayloadAction<string[]>) => {},
    successFetchEventAutoCashOutMarkets: (
      state,
      {
        payload
      }: PayloadAction<{ autoCashOuts: AutoCashOut[]; isWebSocketResponse: boolean; stringifiedAutoCashOuts?: string }>
    ) => {
      state.autoCashOutMarkets = payload.autoCashOuts.reduce(
        (acc, autoCashOut) => ({
          ...acc,
          [autoCashOut.marketId]: autoCashOut
        }),
        {}
      );

      if (!payload.isWebSocketResponse && payload.stringifiedAutoCashOuts) {
        state.stringifiedAutoCashOutMarkets = payload.stringifiedAutoCashOuts;
      }
    },
    failureFetchEventAutoCashOutMarkets: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.autoCashOutMarketsError = payload;
    },
    deleteEventAutoCashOut: (_, __: PayloadAction<{ marketId: string; id: number }>) => {},
    successDeleteEventAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.possibleProfits[payload];
      state.successFullDeletedAutoCashOuts[payload] = true;
    },
    failureDeleteEventAutoCashOut: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.deleteAutoCashOutError = payload;
    },
    eventAutoCashOutAutoDelete: (state, { payload }: PayloadAction<string>) => {
      delete state.possibleProfits[payload];
    },
    eventRemoveSuccessFullDeletedAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.successFullDeletedAutoCashOuts[payload];
      delete state.autoCashOutMarkets[payload];
    },
    eventRemoveSuccessFullCreatedAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.successFullCreatedAutoCashOuts[payload];

      entries(state.autoCashOutMarkets).forEach(([marketId, value]) => {
        if (marketId === payload && value) {
          state.cashedValues[marketId] = value.profit;
        } else {
          delete state.cashedValues[marketId];
        }
      });
    },
    fetchEventCashOutMarket: (state, _: PayloadAction<TSetCashOut>) => {
      state.whatIf = {};
      state.whatIfMarketId = null;
      state.cashOutMarketLoading = true;
    },
    successFetchEventCashOutMarket: (state, { payload }: PayloadAction<CashOutMarket>) => {
      state.cashOutMarketLoading = false;
      state.placedIds[payload.marketId] = payload.offers[0];
      state.pendingCashOuts[payload.marketId] = payload.id;
      state.cashOutStatuses[payload.marketId] = CashOutStatuses.SUCCESS;
    },
    eventCashOutRemovePendingCashOut: (
      state,
      {
        payload
      }: PayloadAction<{
        status: string;
        marketId: string;
      }>
    ) => {
      delete state.pendingCashOuts[payload.marketId];
      state.cashOutStatuses[payload.marketId] = payload.status;
    },
    failureFetchEventCashOutMarket: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.cashOutMarketLoading = false;
      state.cashOutMarketError = payload;
    },
    eventCashOutCleanPlacedId: (state, { payload }: PayloadAction<string>) => {
      delete state.placedIds[payload];
    },
    fetchEventCashOutStatus: (
      state,
      { payload }: PayloadAction<{ statusId: number; onSuccessStatus?: () => void }>
    ) => {
      state.cashOutStatusLoading = true;
      state.statusLoading[payload.statusId] = true;
    },
    eventCashOutCleanStatus: (state, { payload }: PayloadAction<string>) => {
      delete state.cashOutStatuses[payload];
    },
    successFetchEventCashOutStatus: (state, { payload }: PayloadAction<FetchCashOutStatusResponse>) => {
      state.cashOutStatusLoading = false;
      state.statusLoading[payload.statusId] = false;

      if (payload.status !== CashOutStatuses.PENDING) {
        state.cashOutStatuses[payload.marketId] = payload.status;
        delete state.pendingCashOuts[payload.marketId];
      }
    },
    setEventCashOutStatus: (state, { payload }: PayloadAction<{ marketId: string; status: CashOutStatus }>) => {
      state.cashOutStatuses[payload.marketId] = payload.status;
    },
    failureFetchEventCashOutStatus: (
      state,
      { payload }: PayloadAction<{ error: TFailureActionPayload; statusId: number }>
    ) => {
      state.statusLoading[payload.statusId] = false;
      state.cashOutStatusLoading = false;
      state.cashOutStatusError = payload.error;
    },
    eventCashOutOpenSettingsTab: (state, { payload }: PayloadAction<string>) => {
      state.settingTabsStates[payload] = true;
      entries(state.autoCashOutMarkets).forEach(([marketId, value]) => {
        if (marketId === payload && value) {
          state.cashedValues[marketId] = value.profit;
        } else {
          delete state.cashedValues[marketId];
        }
      });
    },
    eventCashOutCloseSettingsTab: (state, { payload }: PayloadAction<string>) => {
      delete state.settingTabsStates[payload];
    },
    fetchEventCashOutMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchEventCashOutMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;
      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchEventCashOutMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    },
    removeEventAutoCashOutMarket: (state, { payload }: PayloadAction<string>) => {
      delete state.autoCashOutMarkets[payload];
    },
    resetEventCashOut: () => initialState
  }
});

export const {
  fetchEventCashOutQuotes,
  eventCashOutCleanPlacedId,
  eventCashOutCloseSettingsTab,
  eventAutoCashOutAutoDelete,
  eventCashOutRemovePendingCashOut,
  eventCashOutOpenSettingsTab,
  removeEventAutoCashOutMarket,
  eventRemoveSuccessFullDeletedAutoCashOut,
  eventRemoveSuccessFullCreatedAutoCashOut,
  failureFetchEventAutoCashOutMarkets,
  failureFetchEventCashOutMarket,
  deleteEventAutoCashOut,
  failureFetchEventCashOutMarketRules,
  eventCashOutCleanStatus,
  failureFetchEventCashOutStatus,
  fetchEventAutoCashOutMarkets,
  fetchEventCashOutMarket,
  fetchEventCashOutMarkets,
  fetchEventCashOutMarketRules,
  hoverEventCashOutBtn,
  setEventCashOutPossibleProfit,
  fetchEventCashOutStatus,
  leaveEventCashOutBtn,
  resetEventCashOut,
  successEventCreateAutoCashOut,
  successDeleteEventAutoCashOut,
  successFetchEventAutoCashOutMarkets,
  successFetchEventCashOutMarket,
  successFetchEventCashOutMarketRules,
  successFetchEventCashOutMarkets,
  successFetchEventCashOutQuotes,
  successFetchEventCashOutStatus,
  failureFetchEventCashOutQuotes,
  failureFetchEventCashOutMarkets,
  eventCreateAutoCashOut,
  failureDeleteEventAutoCashOut,
  failureEventCreateAutoCashOut,
  setEventCashOutStatus
} = slice.actions;

export default slice.reducer;
