import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CashOutStatuses } from 'constants/app';

import styles from './styles.module.scss';

type CashOutStatusMessageProps = {
  value?: string | null;
  onClose: () => void;
};

const CashOutStatusMessage = ({ value = '', onClose }: CashOutStatusMessageProps) => {
  const { t } = useTranslation();

  if (!value) {
    return null;
  }

  return (
    <div className={classNames('biab_cash-out-button', styles.container)}>
      <div
        className={classNames(styles.statusMessage, 'biab_cash-out-message', {
          biab_success: value === CashOutStatuses.SUCCESS || value === CashOutStatuses.SUCCESS_WITH_BETTER_VALUE,
          biab_error: value !== CashOutStatuses.SUCCESS && value !== CashOutStatuses.SUCCESS_WITH_BETTER_VALUE
        })}
      >
        <div dangerouslySetInnerHTML={{ __html: t(`cashout.statuses.${value}`) }} />
        <i onClick={onClose} className={classNames('fa fa-times biab_close-icon', styles.close)} />
      </div>
    </div>
  );
};

export default CashOutStatusMessage;
