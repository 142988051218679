import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { SportIds } from 'constants/app';
import { InlinePlacementActions } from 'constants/inlinePlacement';
import { BettingTypes } from 'constants/markets';
import { BetSides } from 'constants/myBets';
import { LINE_MARKET_ODDS } from 'constants/placement';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useInlineSelectedBetView } from 'hooks/useInlinePlacement';
import { getLineMarketsSwitchBackLayOnCricket } from 'redux/modules/appConfigs/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';

import styles from './styles.module.scss';

type TInlineBetInfo = { bet: TInlineSelectedBet };

const InlineBetInfo = ({ bet }: TInlineBetInfo) => {
  const { t } = useTranslation();

  const lineBackLayToNoYes = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const { replaceBackLayWithUnderOver } = useDeviceSettings();

  const { isShortView } = useInlineSelectedBetView({ sportId: bet.sportId, pageBlock: bet.pageBlock });

  const isCricket = bet.sportId === SportIds.CRICKET;
  const isLineMarket = bet.bettingType === BettingTypes.LINE;

  let label = t('betslip.labels.type.' + bet.type?.toLocaleLowerCase());

  if (isCricket && isLineMarket && lineBackLayToNoYes) {
    label = t('betslip.labels.type.' + (bet.type === BetSides.Back ? 'no' : 'yes'));
  }

  if (!isCricket && isLineMarket && replaceBackLayWithUnderOver) {
    label = t('betslip.labels.type.' + (bet.type === BetSides.Back ? 'under' : 'over'));
  }

  const isPlacedState = bet.action === InlinePlacementActions.PLACED;
  const isCancelledState = bet.action === InlinePlacementActions.CANCELLED;
  const isRemovedState = bet.action === InlinePlacementActions.REMOVED;
  const isProgressState =
    bet.action === InlinePlacementActions.PROGRESS || bet.action === InlinePlacementActions.LONG_PROGRESS;

  const isLineView = isShortView || isPlacedState || isCancelledState || isProgressState || isRemovedState;

  return (
    <div className={classNames(styles.inlineBetInfo, { [styles.inlineBetInfo__line]: isLineView })}>
      {!isRemovedState && <span>{`${label}${isLineView ? ':' : ''}`}</span>}
      <span>
        <span className={styles.selectionName}>
          {bet.selectionName}
          {isLineMarket ? ` @${LINE_MARKET_ODDS}` : ''}
        </span>{' '}
        &ndash; {bet.marketName}
      </span>
    </div>
  );
};

export default InlineBetInfo;
