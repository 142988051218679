import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { BannerNames, ElementNames } from 'constants/app';
import { BannerSections } from 'constants/banners';
import useElementSize from 'hooks/useElementSize';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';

import BannerContent from './components/BannerContent';

import styles from './styles.module.scss';

interface BannersContainerProps {
  bannerType: 'HOME_PAGE_BANNER' | 'BADGE_BANNER' | 'SUB_PAGE_CONTENT_BANNER' | 'BADGE_BANNER_SKYSCRAPER';
}

const BANNER_CONTENTS = {
  [BannerSections.HOME_PAGE_BANNER]: (isIframeEnabled: boolean) => (
    <BannerContent bannerName={BannerNames.homePage} isIframeEnabled={isIframeEnabled} />
  ),
  [BannerSections.SUB_PAGE_CONTENT_BANNER]: (isIframeEnabled: boolean) => (
    <BannerContent bannerName={BannerNames.subPageContent} isIframeEnabled={isIframeEnabled} />
  ),
  [BannerSections.BADGE_BANNER]: (isIframeEnabled: boolean) => (
    <BannerContent bannerName={BannerNames.badge} isIframeEnabled={isIframeEnabled} />
  ),
  [BannerSections.BADGE_BANNER_SKYSCRAPER]: (isIframeEnabled: boolean) => (
    <BannerContent bannerName={BannerNames.badgeSkyscraper} isIframeEnabled={isIframeEnabled} />
  )
};

const BannersContainer = ({ bannerType }: BannersContainerProps) => {
  const dispatch = useDispatch();

  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const [bannerRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      if (bannerType === BannerSections.SUB_PAGE_CONTENT_BANNER || bannerType === BannerSections.HOME_PAGE_BANNER) {
        dispatch(setElementHeight({ name: ElementNames.BANNER, height }));
      }
    }
  });

  useEffect(() => {
    return () => {
      if (bannerType === BannerSections.SUB_PAGE_CONTENT_BANNER || bannerType === BannerSections.HOME_PAGE_BANNER) {
        dispatch(setElementHeight({ name: ElementNames.BANNER, height: 0 }));
      }
    };
  }, []);

  return (
    <div ref={bannerRef} className={classNames('biab_banner-wrapper', styles.bannerWrapper)}>
      {BANNER_CONTENTS[bannerType]?.(isIframeEnabled)}
    </div>
  );
};

export default BannersContainer;
