import { createSelector } from 'reselect';

import { AppState } from 'redux/reducers';

export const getAllArticles = ({ education }: AppState) => education.articles;

export const getIsSearchActive = ({ education }: AppState) => education.isSearchActive;
export const getIsSearchArticlesLoading = ({ education }: AppState) => education.isSearchLoading;
export const getSearchArticles = ({ education }: AppState) => education.searchResults;

export const getEnabledArticles = createSelector(getAllArticles, articles =>
  articles?.filter(article => article.enabled)
);
