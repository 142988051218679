import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { BETTING_TYPES, GAME } from 'constants/app';
import { accountPagesBranding } from 'constants/branding';
import { BetSides, lineAvgPriceFlatValue, StatementDataStatuses, StatementOriginNoteTypes } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import {
  getDesktopSettingsLineMarketsSwitchBackLayOnCricket,
  getDesktopSettingsReplaceBackLayWithUnderOver,
  getDesktopSwapColorsFancyMarketsOnCricket,
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { TStatementContentItem } from 'redux/modules/myBets/type';
import { AccountStatementTableColumn } from 'types/myBets';
import { applyTimezone, parseMillisecondsToTime } from 'utils/date';
import {
  betsSideValue,
  getAccountStatementIndicators,
  getAccountStatementStatusLabel,
  getOriginLabel
} from 'utils/myBetsValues';
import convertOdds from 'utils/oddsConverter';

import styles from './AccountStatementTableRow.module.scss';

type AccountStatementTableRowProps = {
  columns: AccountStatementTableColumn[];
  data: TStatementContentItem;
  isMultiCurrencySupported: boolean;
  isWidthLessThan1200: boolean;
};

const AccountStatementTableRow = ({
  data,
  columns,
  isMultiCurrencySupported,
  isWidthLessThan1200
}: AccountStatementTableRowProps) => {
  const { t } = useTranslation();

  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const lineMarketsSwitchBackLayOnCricket = useSelector(getDesktopSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getDesktopSettingsReplaceBackLayWithUnderOver);
  const swapColorsFancyMarketsOnCricket = useSelector(getDesktopSwapColorsFancyMarketsOnCricket);

  const currencySettings = {
    noRounding: true,
    isCheckIndian: true,
    noSymbol: !isMultiCurrencySupported
  };

  const { noFormattedAmount: balance } = useFormatCurrency(data.balance || 0, data.currency, currencySettings);
  const { noFormattedAmount: stake } = useFormatCurrency(data.size || 0, data.currency, currencySettings);
  const { noFormattedAmount: debit } = useFormatCurrency(data.debit || 0, data.currency, currencySettings);
  const { noFormattedAmount: credit } = useFormatCurrency(data.credit || 0, data.currency, currencySettings);
  const { noFormattedAmount: liability } = useFormatCurrency(data.liability || 0, data.currency, currencySettings);

  const { isBackIndicator, isLayIndicator } = getAccountStatementIndicators({ data, swapColorsFancyMarketsOnCricket });

  const getSelection = () => {
    const originLabel = getOriginLabel({ data, t });
    const selectionName = `${data.selectionName}${
      data.bettingType === BETTING_TYPES.line ? ` ${Number(data.avgPriceMatched)}` : ''
    }`;

    if (originLabel) {
      return (
        <div className={styles.row__cell__selection__wrapper}>
          <p className={styles.row__cell__selection}>{selectionName}</p>
          <p className={styles.row__cell__selection__label}>{originLabel}</p>
        </div>
      );
    }

    return <p className={styles.row__cell__selection}>{selectionName}</p>;
  };

  const getNames = () => {
    if (data.betType === GAME) {
      return <p className={styles.row__cell__names}>{data.sportName}</p>;
    }

    if (data.outright) {
      return (
        <p className={styles.row__cell__names}>
          {data.competitionName} - {data.marketName}
        </p>
      );
    }

    return (
      <p className={styles.row__cell__names}>
        {data.mainEventName || data.eventName}
        {data.raceName
          ? ` - ${parseMillisecondsToTime(data.marketStartTime, timezone, timezoneCookieEnabled)} ${data.raceName} `
          : ' '}
        - {data.marketNameWithParents || data.marketName}
      </p>
    );
  };

  const getDescriptionBottom = () => {
    const hiddenColumns = isWidthLessThan1200
      ? ` | ${t('account.statement.labels.id')}: ${data.refId} | ${getAccountStatementStatusLabel({
          status: data.status,
          t
        })}`
      : '';
    const hiddenTypeColumn = isWidthLessThan1200
      ? t(betsSideValue({ item: data, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver }))
      : '';

    if (data.gameIdLabel) {
      return (
        <p className={styles.row__cell__description__bottom}>
          {hiddenTypeColumn}
          {hiddenTypeColumn ? ' | ' : ''}
          {data.gameIdLabel}
          {hiddenColumns}
        </p>
      );
    }

    const cashOutLabel = data.triggeredByCashOut ? (
      <span className={classNames(styles.row__cell__cashOut, accountPagesBranding.CASH_OUT_LABEL)}>
        {t('market.cashOut')}
      </span>
    ) : (
      ''
    );

    if (data.asian) {
      const placementLabel = data.inPlay
        ? t('account.mybets.labels.livePlacement')
        : t('account.statement.labels.prePlayPlacement');

      return (
        <p
          className={classNames(styles.row__cell__description__bottom, {
            [styles.row__cell__description__bottom__less1200]: isWidthLessThan1200
          })}
        >
          {cashOutLabel}
          {placementLabel} | {data.competitionName}
          {hiddenColumns}
        </p>
      );
    }

    const competitionOrDivisor =
      data.eachWayDivisor && typeof data.numberOfWinners === 'number'
        ? `${t('market.each.way.termsNoPref', { odds: data.eachWayDivisor, places: data.numberOfWinners })}`
        : data.competitionName;

    if (cashOutLabel || hiddenTypeColumn || competitionOrDivisor || hiddenColumns) {
      return (
        <p
          className={classNames(styles.row__cell__description__bottom, {
            [styles.row__cell__description__bottom__less1200]: isWidthLessThan1200
          })}
        >
          {cashOutLabel}
          {hiddenTypeColumn}
          {hiddenTypeColumn && competitionOrDivisor ? ' | ' : ''}
          {competitionOrDivisor}
          {hiddenColumns}
        </p>
      );
    }

    return null;
  };

  const getTransactionDescriptionTopContent = () => {
    if (data.asian || data.depositWithdrawal) {
      let content: string;

      if (data.depositWithdrawal) {
        content = data.description;
      } else {
        content = t(Number(data.credit) > 0 ? 'accountStatement.deposit' : 'accountStatement.withdrawal');
      }

      return <p className={styles.row__cell__selection}>{content}</p>;
    }

    const commissionLabel = <p className={styles.row__cell__selection}>{t('accountStatement.commission')}</p>;

    if (
      data.originNoteType === StatementOriginNoteTypes.CORRECTION ||
      data.originNoteType === StatementOriginNoteTypes.RESETTLEMENT
    ) {
      return (
        <div className={styles.row__cell__selection__wrapper}>
          {commissionLabel}
          <p className={styles.row__cell__selection__label}>
            {data.originNoteTypeLabel}
            {data.deadHeated ? ` - ${t('account.statement.labels.deadheated')}` : ''}
          </p>
        </div>
      );
    }

    return commissionLabel;
  };

  const getBottomTransactionDescription = () => {
    if (data.betType === GAME) {
      if (!data.depositWithdrawal || isWidthLessThan1200) {
        return (
          <p
            className={classNames(styles.row__cell__description__bottom, {
              [styles.row__cell__description__bottom__less1200]: isWidthLessThan1200
            })}
          >
            {data.depositWithdrawal ? '' : data.gameIdLabel}
            {isWidthLessThan1200 ? ` | ${t('account.statement.labels.id')}: ${data.refId} | ` : ''}
            {isWidthLessThan1200 ? getAccountStatementStatusLabel({ status: data.status, t }) : ''}
          </p>
        );
      }

      return null;
    }

    if (data.eachWayDivisor && typeof data.numberOfWinners === 'number') {
      return (
        <p
          className={classNames(styles.row__cell__description__bottom, {
            [styles.row__cell__description__bottom__less1200]: isWidthLessThan1200
          })}
        >
          {data.depositWithdrawal
            ? ''
            : t('market.each.way.termsNoPref', { odds: data.eachWayDivisor, places: data.numberOfWinners })}
          {isWidthLessThan1200 ? ` | ${t('account.statement.labels.id')}: ${data.refId} | ` : ''}
          {isWidthLessThan1200 ? getAccountStatementStatusLabel({ status: data.status, t }) : ''}
        </p>
      );
    }

    if ((!data.depositWithdrawal && data.competitionName) || isWidthLessThan1200) {
      return (
        <p
          className={classNames(styles.row__cell__description__bottom, {
            [styles.row__cell__description__bottom__less1200]: isWidthLessThan1200
          })}
        >
          {data.depositWithdrawal
            ? ''
            : `${data.competitionName}${isWidthLessThan1200 && data.competitionName ? ' | ' : ''}`}
          {isWidthLessThan1200 ? `${t('account.statement.labels.id')}: ${data.refId} | ` : ''}
          {isWidthLessThan1200 ? getAccountStatementStatusLabel({ status: data.status, t }) : ''}
        </p>
      );
    }
  };

  const getOddsTooltipValue = (type?: string) => {
    if (type === 'oddsTooltip' || (type === 'avgLayOdds' && data.side === BetSides.Lay) || type === 'avgBackOdds') {
      if (data.asian) {
        return `${data.avgPrice} ${t('asianView.labels.oddsType.short.decimal')}`;
      }

      return `${t('account.statement.tooltip.fullPriceIs')} ${
        type === 'avgBackOdds' && data.side === BetSides.Lay ? data.alternativeBackOdds : data.avgPrice
      }`;
    }

    return undefined;
  };

  return (
    <div
      className={classNames(styles.row, accountPagesBranding.BET_INDICATOR, {
        [styles.row__back]: isBackIndicator,
        [styles.row__less1200]: isWidthLessThan1200,
        [styles.row__lay]: isLayIndicator,
        [accountPagesBranding.BET_SIDE_BACK]: isBackIndicator,
        [accountPagesBranding.BET_SIDE_LAY]: isLayIndicator
      })}
    >
      {columns.map(({ isVisible, className, id, right, type }) => {
        if (isVisible) {
          if (id === 'description') {
            if (data.status === StatementDataStatuses.TRANSACTION) {
              return (
                <div key={id} className={classNames(styles.row__cell__description, className)}>
                  {getTransactionDescriptionTopContent()}
                  {!data.depositWithdrawal && (
                    <p className={styles.row__cell__names}>
                      {data.mainEventName || data.eventName}
                      {data.raceName
                        ? ` - ${parseMillisecondsToTime(data.marketStartTime, timezone, timezoneCookieEnabled)} ${
                            data.raceName
                          }`
                        : ''}
                      {data.betType === GAME ? '' : ` - ${data.marketNameWithParents || data.marketName}`}
                    </p>
                  )}
                  {getBottomTransactionDescription()}
                </div>
              );
            }

            return (
              <div key={id} className={classNames(styles.row__cell__description, className)}>
                {getSelection()}
                {getNames()}
                {getDescriptionBottom()}
              </div>
            );
          }

          let content = data[id];

          if (
            data.status === StatementDataStatuses.TRANSACTION &&
            (id === 'placedDate' ||
              id === 'side' ||
              id === 'liability' ||
              id === 'oddsType' ||
              id === 'avgPriceRounded' ||
              id === 'size' ||
              id === 'alternativeBackOddsRounded')
          ) {
            content = '--';
          } else {
            if (id === 'credit') {
              if (Number(data.debit) > 0) {
                content = debit;
              } else {
                content = credit;
              }
            } else if (type === 'date') {
              const date = applyTimezone(new Date(data[id] as number), timezone, timezoneCookieEnabled);
              const hours = date.getHours();
              const minutes = date.getMinutes();
              const hoursToDisplay = hours > 9 ? hours : `0${hours}`;
              const minutesToDisplay = minutes > 9 ? minutes : `0${minutes}`;

              if (americanDateFormatEnabled) {
                content = `${t(
                  `dates.shortMonth.${date.getMonth()}`
                )} ${date.getDate()}, ${date.getFullYear()}\n${hoursToDisplay}:${minutesToDisplay}`;
              } else {
                content = `${date.getDate()} ${t(
                  `dates.shortMonth.${date.getMonth()}`
                )} ${date.getFullYear()}\n${hoursToDisplay}:${minutesToDisplay}`;
              }
            } else if (id === 'size') {
              content = stake;
            } else if (id === 'liability') {
              content = liability;
            } else if (id === 'balance') {
              content = balance;
            } else if (id === 'status') {
              content = getAccountStatementStatusLabel({ status: data.status, t });
            } else if (id === 'side') {
              content = t(
                betsSideValue({ item: data, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver })
              );
            } else if (id === 'oddsType') {
              content = t(`asianView.labels.oddsType.short.${data.oddsType}`);
            } else if (id === 'avgPriceRounded' || id === 'alternativeBackOddsRounded') {
              if (type === 'avgBackOdds') {
                if (data.bettingType === BETTING_TYPES.line) {
                  content = lineAvgPriceFlatValue;
                } else {
                  content = data.side === BetSides.Back ? data.avgPriceRounded : data.alternativeBackOddsRounded;
                }
              } else if (type === 'avgLayOdds') {
                if (data.side === BetSides.Back) {
                  content = '--';
                } else {
                  content = data.bettingType === BETTING_TYPES.line ? lineAvgPriceFlatValue : data.avgPriceRounded;
                }
              } else {
                if (data.asian) {
                  const convertedOdds = data.oddsType ? convertOdds(data.avgPrice, data.oddsType) : '';
                  content =
                    typeof convertedOdds === 'number' && convertedOdds < 0
                      ? `${Math.abs(Number(convertedOdds))}`
                      : convertedOdds;
                } else if (data.bettingType === BETTING_TYPES.line) {
                  content = lineAvgPriceFlatValue;
                }
              }
            }
          }

          const isWon =
            id === 'status' &&
            (data.status === StatementDataStatuses.WON || data.status === StatementDataStatuses.HALF_WON);

          const isLost =
            id === 'status' &&
            (data.status === StatementDataStatuses.LOST || data.status === StatementDataStatuses.HALF_LOST);

          const isGreyStatus =
            id === 'status' &&
            (data.status === StatementDataStatuses.TRANSACTION ||
              data.status === StatementDataStatuses.VOIDED ||
              data.status === null);
          const tooltipId =
            type === 'oddsTooltip' || (type === 'avgLayOdds' && data.side === BetSides.Lay) || type === 'avgBackOdds'
              ? 'tooltip'
              : undefined;

          return (
            <p
              key={id}
              data-tooltip-id={tooltipId}
              data-tooltip-html={getOddsTooltipValue(type)}
              className={classNames(styles.row__cell, className, accountPagesBranding.BET_STATUS, {
                [styles.row__cell__right]: right,
                [styles.row__cell__date]: type === 'date',
                [styles.row__cell__credit]: id === 'credit' && Number(data.credit) > 0,
                [styles.row__cell__debit]: id === 'credit' && Number(data.debit) > 0,
                [styles.row__cell__status]: id === 'status',
                [styles.row__cell__status__won]: isWon,
                [styles.row__cell__status__lost]: isLost,
                [styles.row__cell__status__grey]: isGreyStatus,
                [styles.row__cell__longRefId]: id === 'refId' && data.refId.length >= 11,
                [accountPagesBranding.POSITIVE]: isWon,
                [accountPagesBranding.NEGATIVE]: isLost,
                [accountPagesBranding.NEUTRAL]: isGreyStatus
              })}
            >
              {id === 'status' && (
                <span
                  className={classNames(
                    'biab_custom-icon-dot',
                    styles.row__cell__status__dot,
                    accountPagesBranding.BET_STATUS_DOT
                  )}
                />
              )}
              {Number(content) > 0 &&
                (id === 'credit' && Number(data.credit) > 0
                  ? '+'
                  : id === 'credit' && Number(data.debit) > 0
                  ? '-'
                  : '')}
              {content}
            </p>
          );
        }

        return null;
      })}
    </div>
  );
};

export default AccountStatementTableRow;
