import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { CookieNames } from 'constants/app';
import { ASIAN_VIEW_PAGE_PARAM, AsianSortingTypes } from 'constants/asianView';
import { asianViewDropdown } from 'constants/branding';
import { getIsDesktopInfiniteScrollEnabled } from 'redux/modules/appConfigs/selectors';
import { DropdownItem } from 'types';
import { AsianSortingType } from 'types/asianView';
import { convertSortingFilterToDropdown } from 'utils/asianView';

import styles from '../filter.module.scss';

const SortingFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies([CookieNames.ASIAN_VIEW_SORTING_FILTER]);

  const isInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);

  const currentSortingFilter =
    (cookies[CookieNames.ASIAN_VIEW_SORTING_FILTER] as AsianSortingType) || AsianSortingTypes.NORMAL;
  const convertedData = convertSortingFilterToDropdown(t);

  const selectedItem = convertedData.find(item => item.value === currentSortingFilter);

  const onSelectOddsType = (dropdownItem: DropdownItem) => {
    if (!isInfiniteScrollEnabled) {
      searchParams.set(ASIAN_VIEW_PAGE_PARAM, '0');
      setSearchParams(searchParams);
    }

    setCookie(CookieNames.ASIAN_VIEW_SORTING_FILTER, dropdownItem.value, {
      path: '/'
    });
  };

  return (
    <Dropdown
      classes={{
        ...styles,
        placeholder: classNames(styles.placeholder, 'biab_sorting-filter'),
        container: 'biab_sorting-filter-wrapper'
      }}
      brandingClasses={asianViewDropdown}
      data={convertedData}
      defaultValue={selectedItem}
      onSelectOption={onSelectOddsType}
      title={false}
    />
  );
};

export default SortingFilter;
