import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';

import MobileSubheader from 'components/MobileSubheader';
import { EDUCATIONAL_PORTAL_BASE_URL, EDUCATIONAL_PORTAL_GUIDES_URL } from 'constants/locations';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import useDevice from 'hooks/useDevice';
import { resetSearchArticlesResults, setIsSearchActive } from 'redux/modules/educationalPortal';

const EducationMobileSubheader = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isMobile } = useDevice();

  const searchQuery = searchParams.get(PARAMS_SEARCH_KEY);
  const isGuidesPage = location.pathname.endsWith(EDUCATIONAL_PORTAL_GUIDES_URL);
  const isArticlePage = location.pathname.includes(EDUCATIONAL_PORTAL_GUIDES_URL) && !isGuidesPage;
  const isHomePage = location.pathname.endsWith(EDUCATIONAL_PORTAL_BASE_URL);
  const titleLabel = isGuidesPage
    ? 'education.labels.guides'
    : isArticlePage
    ? 'education.labels.article'
    : 'education.labels.educationalPortal';
  const isMobileSearchQueryAvailable = isArticlePage && isMobile && searchQuery;

  const handleClickBack = () => {
    if (isMobileSearchQueryAvailable) {
      dispatch(setIsSearchActive(true));
      dispatch(resetSearchArticlesResults());
    }
  };

  return (
    <MobileSubheader
      backUrl={{
        pathname: isArticlePage ? EDUCATIONAL_PORTAL_GUIDES_URL : EDUCATIONAL_PORTAL_BASE_URL,
        search: isMobileSearchQueryAvailable ? `${PARAMS_SEARCH_KEY}=${searchQuery}` : undefined
      }}
      title={t(titleLabel)}
      withHomeBtn={isHomePage}
      hasSearch
      onClickBackUrl={handleClickBack}
    />
  );
};

export default EducationMobileSubheader;
