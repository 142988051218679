import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices } from 'constants/app';
import {
  getAppDevice,
  getIsMarketSelectionsScroll,
  getNumberOfMarketSelectionsScroll
} from 'redux/modules/appConfigs/selectors';
import { TMarketLines, TMarketResponse } from 'redux/modules/market/type';
import { IMarket, MarketActionsRowClasses } from 'types/markets';
import { getMarketTypes } from 'utils/market';

import styles from './styles.module.scss';

interface MarketActionsRowProps {
  /**
   * Market
   */
  market: IMarket | TMarketResponse;
  /**
   * Show all runners?
   */
  showAll?: boolean;
  /**
   * Lines text
   */
  linesText: string;
  /**
   * Lines
   */
  lines: TMarketLines;
  /**
   * Callback fired to show all runners
   */
  onShowAllRunners: () => void;
  /**
   * Callback fired to minimize
   */
  onMinimize: () => void;
  /**
   * Callback fired to reset view
   */
  onResetView: () => void;
  /**
   * Classes for component customization
   */
  classes?: MarketActionsRowClasses;
  isShowPlTable: boolean;
  handlerPLTable: () => void;
}

const HANDICAP_DOUBLE_LINE_NUMBER_OF_VISIBLE_LINES = 3;

const MarketActionsRow = ({
  market,
  showAll,
  linesText,
  lines,
  onShowAllRunners,
  onResetView,
  onMinimize,
  handlerPLTable,
  isShowPlTable,
  classes
}: MarketActionsRowProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const numberOfMarketSelectionsScroll = useSelector(getNumberOfMarketSelectionsScroll);
  const isMarketSelectionsScroll = useSelector(getIsMarketSelectionsScroll);

  const { totalLines, currentLine, defaultLine } = lines;
  const { description, runners } = market;
  const { isHandicapDoubleLine, isHandicapMarket, isEachWay, isLineMarket, isCombinedTotal, isTotalGoals } =
    getMarketTypes(description.marketType, description.bettingType);
  const isShowTotalRecordsButton =
    totalLines > numberOfMarketSelectionsScroll ||
    (isHandicapDoubleLine &&
      (isHandicapMarket || isCombinedTotal) &&
      totalLines > HANDICAP_DOUBLE_LINE_NUMBER_OF_VISIBLE_LINES);

  const eachWayBlock = isEachWay ? (
    <span className={styles.marketSelections__divisor}>
      {t('market.each.way.terms', {
        odds: market?.description?.eachWayDivisor,
        places: market?.numberOfWinners
      })}
    </span>
  ) : null;

  const getSelectionsLabel = () => {
    if (isHandicapDoubleLine) {
      return (
        <span data-test-id="total-lines">
          {t('market.lines')}: {showAll ? totalLines : linesText}
        </span>
      );
    } else if (!isLineMarket) {
      return `${runners.length} ${t('market.selections')}`;
    }
  };

  const isScrollable = () => {
    return (
      (isMarketSelectionsScroll && !isHandicapDoubleLine && !isTotalGoals && !isCombinedTotal && runners.length) ||
      0 >= numberOfMarketSelectionsScroll
    );
  };

  return (
    <>
      {device === Devices.MOBILE ? (
        <div className={classNames('biab_market-pl-table-action', styles.marketActions__pl)}>
          {(isShowTotalRecordsButton || isShowPlTable) && (
            <div
              className={classNames('biab_market-ah-lines-wrapper biab_handicap-market', styles.marketActions, {
                [classes?.actions ?? '']: classes?.actions
              })}
            >
              {isShowTotalRecordsButton && !isCombinedTotal && (
                <>
                  <span className={styles.marketActions__view}>{`${t('market.lines.view')}:`}</span>
                  <button
                    type="button"
                    className={classNames('biab_ah-minimize-button', styles.marketActions__minimize, {
                      biab_active: showAll
                    })}
                    onClick={onMinimize}
                  >
                    {t('market.lines.minimized')}
                  </button>
                  <button
                    type="button"
                    className={classNames('biab_ah-show-all-button', styles.marketActions__full, {
                      biab_active: !showAll
                    })}
                    onClick={onShowAllRunners}
                  >
                    {t('market.lines.full')}
                  </button>
                </>
              )}

              {isShowTotalRecordsButton && (isCombinedTotal || isScrollable()) && (
                <button
                  type="button"
                  className={classNames('biab_market-pl-table', styles.marketActions__reset, {
                    [styles.marketActions__reset__disabled]: showAll || currentLine === defaultLine
                  })}
                  onClick={onResetView}
                >
                  {t('market.lines.resetView')}
                </button>
              )}

              {isShowPlTable && (
                <button
                  type="button"
                  title={t('market.PLTable')}
                  className={classNames('biab_market-pl-table', styles.marketActions__plTable, {
                    [styles.marketActions__plTable__combinedView]: isCombinedTotal,
                    [styles.marketActions__plTable__padding]:
                      isShowTotalRecordsButton && (isCombinedTotal || isScrollable())
                  })}
                  onClick={handlerPLTable}
                >
                  {t('market.PLTable')}
                </button>
              )}
            </div>
          )}
          {eachWayBlock}
        </div>
      ) : (
        <div className={styles.marketSelections__title}>
          {getSelectionsLabel()}

          {eachWayBlock}

          <span className="biab_ah-show-all-button biab_active" />

          {isShowTotalRecordsButton && (
            <div className={styles.marketActions__recordsButtons}>
              {!isCombinedTotal && (
                <>
                  {!showAll ? (
                    <span
                      className={classNames('biab_ah-show-all-button', styles.marketSelections__showAll)}
                      onClick={onShowAllRunners}
                    >
                      {t('market.lines.showAll')}
                    </span>
                  ) : (
                    <span
                      className={classNames('biab_ah-minimize-button', styles.marketSelections__minimize)}
                      onClick={onMinimize}
                    >
                      {t('market.lines.minimize')}
                    </span>
                  )}
                </>
              )}

              <span
                className={classNames('biab_ah-reset-view-button', styles.marketSelections__reset, {
                  [`${styles.marketSelections__reset_disabled} biab_disabled`]: showAll || currentLine === defaultLine
                })}
                onClick={onResetView}
              >
                {t('market.lines.resetView')}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default memo(MarketActionsRow);
