import { AppState } from 'redux/reducers';
import { ElementName } from 'types';

export const getAppSettings = ({ appSettings }: AppState) => appSettings.settings;
export const getParentDomain = ({ appSettings }: AppState) => appSettings.parentDomain;
export const getAccountPageReferer = ({ appSettings }: AppState) => appSettings.accountPageReferer;
export const getOddsType = ({ appSettings }: AppState) => appSettings.oddsType;
export const getElementHeightByName =
  (elementName: ElementName) =>
  ({ appSettings }: AppState) =>
    appSettings.elementsHeights[elementName];
export const isShowSearch = ({ appSettings }: AppState) => appSettings.isShowingSearch;
export const getIsAsianViewMenuOpen = ({ appSettings }: AppState) => appSettings.isAsianViewMenuOpen;
export const getScrollContentHeight = ({ appSettings }: AppState) => appSettings.scrollContentHeight;
export const getIsUserScrolling = ({ appSettings }: AppState) => appSettings.isUserScrolling;
export const getIsScrollingToTop = ({ appSettings }: AppState) => appSettings.isScrollingToTop;
export const getScrollUpdate = ({ appSettings }: AppState) => appSettings.scrollUpdate;
