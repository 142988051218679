import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import BetForm from 'components/Betslip/components/BetForm';
import QuickBets from 'components/Betslip/components/QuickBets';
import { BetDatabaseNames } from 'constants/bets';
import { BetFocusFields, InputFieldTypes, PlaceBetsStates, tabulation } from 'constants/betslip';
import { InlinePlacementActions, InlinePlacementButtonActionTypes } from 'constants/inlinePlacement';
import { BetSides } from 'constants/myBets';
import useConfirmBets from 'hooks/useConfirmBets';
import { useCurrency } from 'hooks/useCurrency';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useInlineSelectedBetView, useIsDisabledInlineSelectedBet } from 'hooks/useInlinePlacement';
import { useBetslipLabels } from 'hooks/useLabels';
import { getCurrency, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { BetFocusField, TBetslipFormProps, TSelectedBetUpdateData, TSizeValidation } from 'redux/modules/betslip/type';
import { updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getBetOdds, getMarketPricesCurrency } from 'redux/modules/marketsPrices/selectors';
import { getAccountSettings, getIsQuickStakesEnabled, getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { InputFieldType, TLabels } from 'types/betslip';
import { InlinePlacementButtonActionType } from 'types/inlinePlacement';
import { formatBestPrice } from 'utils/betslip';
import { getBetIdentityData } from 'utils/inlinePlacement';
import { calculateLiability, calculateSize } from 'utils/liability';
import { precisionFormat, validateSize } from 'utils/size';

import styles from './styles.module.scss';

type TInlineBetFormProps = { bet: TInlineSelectedBet };

const InlineBetForm = ({ bet }: TInlineBetFormProps) => {
  const dispatch = useDispatch();

  const { marketId, betUuid, type, marketType, bettingType, marketUnit, sportId } = bet;
  const handicap = +(bet.handicap || 0);
  const isBack = bet.type === BetSides.Back;
  const betName = isBack ? BetDatabaseNames.BACK : BetDatabaseNames.LAY;

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const autoRefreshIsEnabled = useSelector(getUserAsianViewAutoRefresh);
  const { placeBetWithEnterKey } = useDeviceSettings();
  const accountSettings = useSelector(getAccountSettings);
  const isPlaceBetsWithEnterKeyEnabled = placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;
  const marketCurrency = useSelector(getMarketPricesCurrency(bet.marketId));
  const defaultCurrency = useSelector(getCurrency);
  const currency = useCurrency(marketCurrency);
  const bestPrice = useSelector(getBetOdds(bet.marketId, bet.selectionId, handicap, betName, 0));
  const isQuickBetsEnabled = useSelector(getIsQuickStakesEnabled);

  const { isConfirmBetsBeforePlacement } = useConfirmBets();
  const isDisabled = useIsDisabledInlineSelectedBet(bet);
  const { isShortView } = useInlineSelectedBetView({ sportId: bet.sportId, pageBlock: bet.pageBlock });
  const labels: TLabels = useBetslipLabels({
    eventTypeId: sportId,
    bettingType,
    marketType,
    marketUnit,
    isInlinePlacement: true
  });

  const isSelected = bet.action === InlinePlacementActions.SELECT;
  const isConfirm = bet.action === InlinePlacementActions.CONFIRM;
  const isPlacement = bet.action === InlinePlacementActions.PLACE;
  const isEdit = bet.action === InlinePlacementActions.EDIT;
  const isPlaced = bet.action === InlinePlacementActions.PLACED;

  const bestPriceFormatted = formatBestPrice(bestPrice, marketType, bettingType ?? '');

  const isOddsRefreshEnabled = isPNCEnabled && autoRefreshIsEnabled && !isDisabled && isSelected;
  const showOddsChangedIndicators =
    isOddsRefreshEnabled && !!bet.prevPrice && !!bestPrice && bet.prevPrice !== bestPrice;

  const mode = isDisabled || isConfirm ? PlaceBetsStates.READONLY : PlaceBetsStates.SELECT;

  const isPlacementInvalid =
    (!isUndefined(bet.isPriceValid) && !bet.isPriceValid) || (!isUndefined(bet.isSizeValid) && !bet.isSizeValid);

  const betIdData = getBetIdentityData(bet);

  const sizeValidationParams = {
    betType: bet.type,
    currency,
    defaultCurrency,
    currencyCode: bet.currency,
    isOperatorBettingLimitsEnabled: false
  };

  const onFormChanged = ({
    price,
    size,
    profit,
    isPriceValid,
    isSizeValid,
    priceErrorMessage,
    sizeErrorMessage,
    focusedField
  }: TBetslipFormProps) => {
    const updatedData: TSelectedBetUpdateData = {
      marketId,
      betUuid,
      type,
      ...(!isUndefined(price) ? { price } : {}),
      ...(!isUndefined(size) ? { size } : {}),
      ...(!isUndefined(profit) ? { profit } : {}),
      ...(!isUndefined(isPriceValid) ? { isPriceValid } : {}),
      ...(!isUndefined(isSizeValid) ? { isSizeValid } : {}),
      ...(priceErrorMessage ? { validationMessage: { text: priceErrorMessage, field: 'price' } } : {}),
      ...(sizeErrorMessage ? { validationMessage: { text: sizeErrorMessage, field: 'size' } } : {}),
      ...(bet.price !== price ? { isPriceDirty: true } : {}),
      ...(!isUndefined(focusedField) ? { focusedField } : {})
    };

    if (isUndefined(profit) && !isUndefined(price) && !isUndefined(bet.size) && bet.size !== '') {
      updatedData.profit = calculateLiability(price, bet.size, bet);
    } else if (isUndefined(profit) && !isUndefined(size) && bet.price !== '') {
      updatedData.profit = calculateLiability(bet.price, size, bet);
    }

    if (!isUndefined(profit) && isUndefined(size)) {
      updatedData.size = calculateSize(bet.price, profit, bet);

      const validation: TSizeValidation = validateSize({
        size: updatedData.size,
        ...sizeValidationParams
      });

      updatedData.isSizeValid = validation.isValid;
    }

    if (
      bet.validationMessage?.text &&
      ((!isUndefined(price) && price !== bet.price) ||
        (!isUndefined(size) && size !== bet.size) ||
        (!isUndefined(profit) && profit !== bet.profit))
    ) {
      updatedData.validationMessage = null;
    }

    if (updatedData.size === '') {
      updatedData.isSizeValid = false;
    }

    updatedData.prevPrice = bet.price;

    dispatch(updateInlineSelectedBet({ ...betIdData, ...updatedData }));
  };

  const onEnterClick = () => {
    if (isPlaceBetsWithEnterKeyEnabled && !isPlacementInvalid && !isDisabled && isLoggedIn) {
      dispatch(
        updateInlineSelectedBet({
          ...betIdData,
          action:
            isSelected && isConfirmBetsBeforePlacement
              ? InlinePlacementActions.CONFIRM
              : isPlaced
              ? InlinePlacementActions.UPDATE
              : InlinePlacementActions.PLACE
        })
      );
    }
  };

  const onTabNavigate = (fieldType: InputFieldType) => {
    let focusedField: BetFocusField | null = null;
    let focusedButton: InlinePlacementButtonActionType | null = null;

    if (fieldType === InputFieldTypes.PRICE) {
      focusedField = BetFocusFields.SIZE;
    } else if (fieldType === InputFieldTypes.SIZE) {
      focusedField = BetFocusFields.LIABILITY;
    } else if (fieldType === InputFieldTypes.PROFIT) {
      if (isPlacementInvalid) {
        focusedField = BetFocusFields.PRICE;
      } else {
        focusedButton = isEdit ? InlinePlacementButtonActionTypes.UPDATE : InlinePlacementButtonActionTypes.PLACE;
      }
    }

    dispatch(updateInlineSelectedBet({ ...betIdData, focusedField, focusedButton }));
  };

  const [quickBetFocus, setQuickBetFocus] = useState(false);
  const disabledQuickBets = bet.action === InlinePlacementActions.CONFIRM || isDisabled;

  const handlerQuickBets = (value: string | number) => {
    const size = precisionFormat(+(bet.size || 0) + +value);
    const profit = calculateLiability(bet.price, size, bet);

    const validation: TSizeValidation = validateSize({
      size,
      ...sizeValidationParams
    });

    dispatch(
      updateInlineSelectedBet({
        ...betIdData,
        size,
        profit,
        isSizeValid: validation.isValid,
        focusedField: BetFocusFields.SIZE
      })
    );
  };

  useEffect(() => {
    dispatch(updateInlineSelectedBet({ ...betIdData, validationMessage: null }));
  }, [isConfirm, isPlacement]);

  useEffect(() => {
    if (isEdit) {
      dispatch(updateInlineSelectedBet({ ...betIdData, focusedField: BetFocusFields.PRICE }));
    }
  }, [isEdit]);

  useEffect(() => {
    if (bet.price && bet.size && !bet.profit) {
      dispatch(updateInlineSelectedBet({ ...betIdData, profit: calculateLiability(bet.price, bet.size, bet) }));
    }
  }, [bet.price, bet.size]);

  return (
    <div className={classNames(styles.inlineBetForm, { [styles.inlineBetForm__fullWidth]: isShortView })}>
      <BetForm
        betUuid={bet.betUuid}
        betPrice={bet.price}
        betPrevPrice={bet.prevPrice}
        betSize={bet.size}
        betProfit={bet.profit}
        betType={bet.type}
        betError={bet.error}
        bestPrice={bestPriceFormatted}
        bettingType={bet.bettingType ?? ''}
        marketId={bet.marketId}
        marketType={bet.marketType ?? ''}
        lineRangeInfo={bet.lineRangeInfo ?? null}
        eachWayDivisor={bet.eachWayDivisor ?? null}
        priceLadderDescription={bet.priceLadderDescription ?? null}
        mode={mode}
        onFormChanged={onFormChanged}
        currency={marketCurrency}
        betIndex={0}
        totalTabFields={tabulation.BET_TABS}
        focusedField={bet.focusedField}
        onEnterClick={onEnterClick}
        onTabNavigate={onTabNavigate}
        isHighlighted={quickBetFocus}
        showOddsChangedIndicators={showOddsChangedIndicators}
        isPriceDirty={bet.isPriceDirty}
        isTakeOfferState={bet.isTakeOffer}
        isOddsRefreshEnabled={isOddsRefreshEnabled}
        labels={labels?.[bet.type]}
        validationMessage={bet.validationMessage}
        customClassNames={{
          form: styles.form,
          price: styles.price,
          size: styles.size,
          profit: styles.profit,
          priceWrap: styles.priceWrap,
          betPriceWrap: styles.betPriceWrap,
          priceIndicator: styles.priceIndicator,
          pncIndicator: styles.pncIndicator
        }}
        isInlineBetslip
      />
      {isQuickBetsEnabled && (
        <QuickBets
          handler={handlerQuickBets}
          isDisabled={disabledQuickBets}
          onFocusChange={setQuickBetFocus}
          hasTooltip={quickBetFocus}
          customClassName={styles.quickBets}
        />
      )}
    </div>
  );
};

export default InlineBetForm;
