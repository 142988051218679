import { useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useMeasure from 'react-use-measure';
import { animated, useSpring } from '@react-spring/web';
import classNames from 'classnames';

import OpenedBetsMatchedBySide from 'components/Betslip/components/OpenedBetsMatchedBySide/OpenedBetsMatchedBySide';
import Icon from 'components/Icon';
import ProfitLossTablePopUpButton from 'components/ProfitLossTablePopUp/components/ProfitLossTablePopUpButton/ProfitLossTablePopUpButton';
import { CookieNames, SportIds } from 'constants/app';
import { betslipBranding } from 'constants/branding';
import { SPORT_BASE_URL } from 'constants/locations';
import { BettingTypes } from 'constants/markets';
import { BetSides } from 'constants/myBets';
import { useEnabledView } from 'hooks/useEnabledView';
import { getDesktopSwapColorsFancyMarketsOnCricket } from 'redux/modules/appConfigs/selectors';
import { TCurrentBet, TGroupedEvent } from 'redux/modules/currentBets/type';
import { getEventTime, getIsRaceType } from 'utils/openedBets';

import styles from './OpenBetsEvent.module.scss';

interface OpenBetsEventProps {
  eventData: TGroupedEvent;
}

const OpenBetsEvent = ({ eventData }: OpenBetsEventProps) => {
  const betData = (eventData.betsByType[BetSides.Back] || eventData.betsByType[BetSides.Lay])[0];

  const swapColorsOnCricketEnabled = useSelector(getDesktopSwapColorsFancyMarketsOnCricket);
  const { t } = useTranslation();
  const { isMultiMarketView } = useEnabledView();
  const [cookies, setCookie] = useCookies([
    CookieNames.BETSLIP_FILTER_MARKET,
    CookieNames.BETSLIP_FILTER_SORTING,
    CookieNames.BETSLIP_FILTER_EVENT,
    CookieNames.BETSLIP_SORTED_EVENTS_COLLAPSED
  ]);
  const collapsedList: string[] = cookies.BETSLIP_SORTED_EVENTS_COLLAPSED?.split(',') ?? [];
  const isCollapsedCookieValue = collapsedList.includes(eventData.eventId);

  const isToggled = useRef(false);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(isCollapsedCookieValue);
  const [contentRef, { height: contentHeight }] = useMeasure();
  const contentStyles = useSpring({
    from: { height: 0 },
    to: { height: isCollapsed ? 0 : contentHeight || 'auto' },
    config: { duration: 200 },
    immediate: !isToggled.current
  });

  const eventName = betData.mainEventName || betData.eventName;
  const eventId = eventData.mainEventId || eventData.eventId;
  const eventLink = betData.isOutright
    ? `${SPORT_BASE_URL}/${betData.eventTypeId}/competition/${eventData.competitionId}`
    : `${SPORT_BASE_URL}/${betData.eventTypeId}/event/${eventId}`;

  const { backBets, layBets, swappedBackBets, swappedLayBets } = useMemo(() => {
    const backBetsList = eventData.betsByType[BetSides.Back] || [];
    const layBetsList = eventData.betsByType[BetSides.Lay] || [];

    if (swapColorsOnCricketEnabled) {
      const isCricketBet = (bet: TCurrentBet) =>
        String(bet.eventTypeId) === SportIds.CRICKET && bet.bettingType === BettingTypes.LINE;

      const backList = backBetsList.filter(bet => !isCricketBet(bet));
      const cricketBackBets = backBetsList.filter(bet => isCricketBet(bet));
      const layList = layBetsList.filter(bet => !isCricketBet(bet));
      const cricketLayBets = layBetsList.filter(bet => isCricketBet(bet));

      return {
        backBets: backList,
        layBets: layList,
        swappedBackBets: cricketBackBets,
        swappedLayBets: cricketLayBets
      };
    }

    return {
      backBets: backBetsList,
      layBets: layBetsList
    };
  }, [eventData, swapColorsOnCricketEnabled]);

  const toggleCollapse = () => {
    let newList: string[];

    if (!isCollapsedCookieValue) {
      newList = [...collapsedList, eventData.eventId];
    } else {
      newList = collapsedList.filter((item: string) => item !== eventData.eventId);
    }

    isToggled.current = true;

    setCookie(CookieNames.BETSLIP_SORTED_EVENTS_COLLAPSED, newList.join(), { path: '/' });
    setIsCollapsed(currIsCollapsed => !currIsCollapsed);
  };

  return (
    <div className={styles.event}>
      <div className={styles.event__header}>
        <div className={styles.event__header__info}>
          <button className={styles.event__header__collapseBtn} onClick={toggleCollapse}>
            <Icon
              fontFamily="fa2"
              iconClass="fa2-chevron-down-icon"
              className={classNames(styles.event__header__icon, {
                [styles.event__header__icon__collapsed]: !isCollapsed
              })}
            />
          </button>
          {betData.eventInPlay && (
            <span className={classNames(styles.event__header__liveLabel, betslipBranding.LIVE)}>
              {t('asianView.labels.betslip.live')}
            </span>
          )}
          <Link className={styles.event__link} to={eventLink}>
            {eventName}{' '}
            {getIsRaceType(betData.eventTypeId) && betData.marketStartDate && getEventTime(betData.marketStartDate)}
          </Link>
        </div>
        {String(betData.eventTypeId) === SportIds.SOCCER && !betData.isOutright && (
          <ProfitLossTablePopUpButton
            isActive={eventData.isPNLAvailable}
            eventId={eventData.eventId}
            marketId={betData.marketId}
            isAsianView={false}
          />
        )}
      </div>
      <animated.div style={{ overflow: 'hidden', ...contentStyles }}>
        <div ref={contentRef}>
          <OpenedBetsMatchedBySide bets={backBets} side={BetSides.Back} isMultiMarketView={isMultiMarketView} />
          {swappedLayBets && (
            <OpenedBetsMatchedBySide bets={swappedLayBets} side={BetSides.Lay} isMultiMarketView={isMultiMarketView} />
          )}
          {swappedBackBets && (
            <OpenedBetsMatchedBySide
              bets={swappedBackBets}
              side={BetSides.Back}
              isMultiMarketView={isMultiMarketView}
            />
          )}
          <OpenedBetsMatchedBySide bets={layBets} side={BetSides.Lay} isMultiMarketView={isMultiMarketView} />
        </div>
      </animated.div>
    </div>
  );
};

export default OpenBetsEvent;
