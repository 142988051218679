import classNames from 'classnames';

import { betslipBranding, componentsBranding, mobileComponents } from 'constants/branding';

import styles from './AccountSettingsError.module.scss';

const wrapNumberWithSpan = (text: string) => {
  return !text.includes('span') ? text.replace(/(\d+(\.\d+)?)/, '<span>$1</span>') : text;
};

type DesktopSettingsErrorProps = {
  error: string;
  onClose?: () => void;
  containerClassName?: string;
  isMobile?: boolean;
};

const AccountSettingsError = ({ error, onClose, containerClassName = '', isMobile }: DesktopSettingsErrorProps) => {
  const formattedError = wrapNumberWithSpan(error);

  return (
    <div
      className={classNames(
        styles.error,
        {
          [styles.error__mobile]: isMobile,
          [componentsBranding.NOTIFICATION]: !isMobile,
          [componentsBranding.ERROR]: !isMobile,
          [mobileComponents.NOTIFICATION]: isMobile,
          [mobileComponents.ERROR]: isMobile
        },
        containerClassName
      )}
    >
      <i className={classNames('biab_custom-icon-warning-circle', styles.error__icon, betslipBranding.WARNING_ICON)} />
      {formattedError.includes('<span>') || formattedError.includes('<br') ? (
        <p
          className={classNames(styles.error__label, {
            [styles.error__label__mobile]: isMobile
          })}
          dangerouslySetInnerHTML={{ __html: formattedError }}
        />
      ) : (
        <p
          className={classNames(styles.error__label, {
            [styles.error__label__mobile]: isMobile
          })}
        >
          {formattedError}
        </p>
      )}
      {onClose && (
        <button onClick={onClose} className={styles.error__closeBtn}>
          <i className="biab_custom-icon-close" />
        </button>
      )}
    </div>
  );
};

export default AccountSettingsError;
