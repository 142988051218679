import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MultiselectDropdown from 'components/MultiselectDropdown';
import { CookieNames } from 'constants/app';
import {
  DESKTOP_ACCOUNT_STATEMENT_SELECTED_TRANSACTIONS_TYPES_NAME,
  ExchangeTypes,
  SEPARATE_WALLET,
  StatementTransactionTypes
} from 'constants/myBets';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import {
  getIsExchangeGamesEnabled,
  getPNCEnabledSetting,
  getWalletIntegrationType
} from 'redux/modules/appConfigs/selectors';
import { setAccountStatementTransactionsTypes } from 'redux/modules/myBets';
import { getAccountProductsByBetsType, getAccountStatementTransactionsTypes } from 'redux/modules/myBets/selectors';
import { BetsType, StatementTransactionType } from 'redux/modules/myBets/type';

const TransactionsDropdown = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW]);

  const accountProducts = useSelector(getAccountProductsByBetsType(BetsType.ALL_ACCOUNT_STATEMENT));
  const accountStatementTransactions = useSelector(getAccountStatementTransactionsTypes);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const walletIntegrationType = useSelector(getWalletIntegrationType);

  const isAsianViewTable = !!accountProducts?.asianView && cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView;

  const { refreshData } = useMyBetsPagesNavigation();

  const transactionsOptions = [
    {
      id: StatementTransactionTypes.BETTING,
      label: t('account.statement.labels.sportsBetting'),
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.BETTING),
      isVisible: true
    },
    {
      id: StatementTransactionTypes.COMMISSION,
      label: t('accountStatement.commission'),
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.COMMISSION),
      isVisible: isAsianViewTable ? false : !isPNCEnabled || exchangeGamesEnabled
    },
    {
      id: StatementTransactionTypes.RESETTLEMENT_CORRECTION,
      label: t('account.statement.labels.resettlementsCorrections'),
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.RESETTLEMENT_CORRECTION),
      isVisible: true
    },
    {
      id: StatementTransactionTypes.DEPOSIT_WITHDRAWAL,
      label: t('account.statement.labels.depositAndWithdrawal'),
      isSelected: accountStatementTransactions.includes(StatementTransactionTypes.DEPOSIT_WITHDRAWAL),
      isVisible: walletIntegrationType === SEPARATE_WALLET
    }
  ].filter(({ isVisible }) => isVisible);

  const handleOnSelect = (selected: string[]) => {
    if (selected.length === 0) {
      if (accountStatementTransactions.length !== transactionsOptions.length) {
        const allSelected = transactionsOptions.map(({ id }) => id);

        dispatch(setAccountStatementTransactionsTypes(allSelected));
        refreshData({ statementTransactions: allSelected });

        localStorage.setItem(DESKTOP_ACCOUNT_STATEMENT_SELECTED_TRANSACTIONS_TYPES_NAME, JSON.stringify(allSelected));
      }
    } else if (
      selected.length !== accountStatementTransactions.length ||
      accountStatementTransactions.some(transaction => !selected.includes(transaction))
    ) {
      dispatch(setAccountStatementTransactionsTypes(selected as StatementTransactionType[]));
      localStorage.setItem(DESKTOP_ACCOUNT_STATEMENT_SELECTED_TRANSACTIONS_TYPES_NAME, JSON.stringify(selected));
      refreshData({ statementTransactions: selected as StatementTransactionType[] });
    }
  };

  const placeholder =
    accountStatementTransactions.length === 1
      ? transactionsOptions.find(({ id }) => id === accountStatementTransactions[0])?.label
      : accountStatementTransactions.length < transactionsOptions.length
      ? `${accountStatementTransactions.length} ${t('account.statement.labels.transactionTypes')}`
      : t('account.statement.labels.allTransactionTypes');

  return (
    <MultiselectDropdown options={transactionsOptions} onSelect={handleOnSelect}>
      {placeholder}
    </MultiselectDropdown>
  );
};

export default TransactionsDropdown;
