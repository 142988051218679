import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES, SportIds } from 'constants/app';

import {
  ACTIVE_MARKETS_COLLAPSE,
  ASIAN_VIEW_BETSLIP_COLLAPSE,
  ASIAN_VIEW_FAVORITES_COLLAPSE,
  FAVORITES_COLLAPSE,
  HOW_TO_COLLAPSE,
  LEFT_PANEL_COLLAPSE_GROUP,
  LEFT_PANEL_COLLAPSE_GROUP_ASIAN,
  SPORT_COLLAPSE,
  SPORT_INNER_COLLAPSE,
  SPORT_INNER_COLLAPSE_MORE,
  SPORT_INNER_COLLAPSE_TOP_COMPETITIONS,
  SPORT_INNER_COLLAPSE_TOP_COUNTRY
} from './constants';
import { TCollapse, TCollapseItem, TCollapsePayload } from './type';

const initialState: TCollapse = {
  [LEFT_PANEL_COLLAPSE_GROUP]: {
    [SPORT_COLLAPSE]: {
      closeOnChange: true,
      openedState: true
    },
    [HOW_TO_COLLAPSE]: {
      closeOnChange: false,
      openedState: false,
      depends: [SPORT_COLLAPSE, ACTIVE_MARKETS_COLLAPSE, FAVORITES_COLLAPSE]
    },
    [FAVORITES_COLLAPSE]: {
      closeOnChange: true,
      openedState: false,
      withoutCookie: true,
      depends: [ACTIVE_MARKETS_COLLAPSE]
    },
    [ACTIVE_MARKETS_COLLAPSE]: {
      closeOnChange: true,
      openedState: false,
      withoutCookie: true,
      depends: [FAVORITES_COLLAPSE]
    }
  },
  [SPORT_INNER_COLLAPSE]: {
    [SPORT_INNER_COLLAPSE_MORE]: {
      closeOnChange: false,
      openedState: true
    },
    [SPORT_INNER_COLLAPSE_TOP_COMPETITIONS]: {
      closeOnChange: false,
      openedState: true
    },
    [SPORT_INNER_COLLAPSE_TOP_COUNTRY]: {
      closeOnChange: false,
      openedState: true
    }
  },
  [LEFT_PANEL_COLLAPSE_GROUP_ASIAN]: {
    [SportIds.SOCCER]: {
      closeOnChange: true,
      openedState: true
    },
    [ASIAN_VIEW_FAVORITES_COLLAPSE]: {
      closeOnChange: true,
      openedState: false,
      withoutCookie: true,
      depends: []
    },
    [ASIAN_VIEW_BETSLIP_COLLAPSE]: {
      closeOnChange: true,
      openedState: false
    }
  }
};

const slice = createSlice({
  name: SLICES_NAMES.COLLAPSE,
  initialState,
  reducers: {
    setCollapseState: (state, action: PayloadAction<TCollapsePayload>) => {
      const { group, collapse, openedState } = action.payload;

      if (!state[group][collapse]) {
        state[group][collapse] = { openedState, closeOnChange: true };
      } else {
        state[group][collapse].openedState = openedState;
      }
    },
    setCollapseNewState: (state, action: PayloadAction<TCollapsePayload>) => {
      const { group, collapse, openedState } = action.payload;
      const currentCollapse = state[group][collapse];
      const newGroupState: TCollapseItem = {};

      Object.entries(state[group]).forEach(([col, val]) => {
        newGroupState[col] = {
          ...val,
          openedState:
            col === collapse
              ? openedState
              : currentCollapse?.closeOnChange && !val.depends?.includes(collapse)
              ? false
              : val.openedState
        };
      });

      state[group] = { ...state[group], ...newGroupState };
    }
  }
});

export const { setCollapseState, setCollapseNewState } = slice.actions;

export default slice.reducer;
