import { ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewNotFoundView from 'components/AsianViewPageModule/components/AsianViewNotFoundView';
import AsianFooter from 'components/Footer/AsianFooter';
import AsianLeftNavigation from 'components/LeftNavigation/AsianLeftNavigation';
import AsianRightNavigation from 'components/RightNavigation/AsianRightNavigation';
import { ElementNames } from 'constants/app';
import {
  ASIAN_VIEW_MIDDLE_SECTION_CLASS,
  DESKTOP_NAVIGATION_HEIGHT,
  MOBILE_LEFT_NAVIGATION_HEIGHT
} from 'constants/asianView';
import useElementSize from 'hooks/useElementSize';
import {
  getAsianViewMobileNavigationMenu,
  getPositionOfAsianViewMobileNavigationMenu
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight, setScrollUpdate } from 'redux/modules/appSettings';
import { getElementHeightByName, getScrollContentHeight, getScrollUpdate } from 'redux/modules/appSettings/selectors';
import { setIsCollapsedRightSide } from 'redux/modules/asianView';
import {
  getAsianViewIsCollapsedRightSide,
  getIsAsianViewNotFoundView,
  getIsLandscapeAsianView,
  getIsMobileAsianView
} from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

type AsianViewContentProps = {
  children: ReactNode;
  withoutNavigation: boolean;
  isCashOutPage?: boolean;
  withoutFooter?: boolean;
};

const AsianViewContent = ({
  withoutNavigation,
  children,
  isCashOutPage = false,
  withoutFooter = false
}: AsianViewContentProps) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const scrollUpdate = useSelector(getScrollUpdate);
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const height = useSelector(getScrollContentHeight);
  const isCollapsed = useSelector(getAsianViewIsCollapsedRightSide);
  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const positionOfAsianViewMobileNavigationMenu = useSelector(getPositionOfAsianViewMobileNavigationMenu);
  const asianViewMobileNavigationMenu = useSelector(getAsianViewMobileNavigationMenu);
  const mobileAsianSubHeader = useSelector(getElementHeightByName(ElementNames.MOBILE_ASIAN_SUB_HEADER));
  const isAsianViewNotFoundView = useSelector(getIsAsianViewNotFoundView);

  const [containerRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ notRoundedHeight = 0 }) => {
      if (notRoundedHeight > 0) {
        dispatch(setElementHeight({ name: ElementNames.ASIAN_MIDDLE_SECTION, height: notRoundedHeight }));
      }
    }
  });

  const hasMenu = isMobileAsianView && positionOfAsianViewMobileNavigationMenu && asianViewMobileNavigationMenu;
  const hasMenuLandscape = isLandscapeAsianView && asianViewMobileNavigationMenu;

  const heightWithoutSubHeader = height - (!isCashOutPage ? mobileAsianSubHeader : 0);
  const subHeaderHeight = hasMenu && !isCashOutPage ? mobileAsianSubHeader : 0;

  const mobileHeight =
    height - (!isLandscapeAsianView ? subHeaderHeight : 0) < MOBILE_LEFT_NAVIGATION_HEIGHT
      ? MOBILE_LEFT_NAVIGATION_HEIGHT - (isLandscapeAsianView ? subHeaderHeight : 0)
      : heightWithoutSubHeader;

  const handleScroll = () => {
    if (!isCollapsed && !isMobileAsianView) {
      dispatch(setIsCollapsedRightSide(true));
    }
  };

  useEffect(() => {
    if (scrollUpdate.trigger) {
      if (ref.current) {
        const top = ref.current.scrollTop - scrollUpdate.offset;
        ref.current.scrollTo({ top: top > 0 ? top : 0 });
      }
      dispatch(setScrollUpdate({ top: 0, offset: 0, trigger: false }));
    }
  }, [scrollUpdate.trigger]);

  return (
    <div ref={containerRef} className={classNames(styles.content, { [styles.content__mobile]: isMobileAsianView })}>
      {!withoutNavigation && !isMobileAsianView && (
        <AsianLeftNavigation onScroll={handleScroll} isCashOutPage={isCashOutPage} />
      )}
      <div
        ref={ref}
        className={classNames(ASIAN_VIEW_MIDDLE_SECTION_CLASS, styles.middleSectionWrapper, {
          [styles.middleSectionWrapper__desktop]: !isMobileAsianView,
          [styles.middleSectionWrapper__hasMenu]: hasMenuLandscape,
          [styles.middleSectionWrapper__mobile]: isMobileAsianView
        })}
        style={!isMobileAsianView ? { height: height - DESKTOP_NAVIGATION_HEIGHT } : { minHeight: mobileHeight }}
        onScroll={handleScroll}
      >
        {isAsianViewNotFoundView ? <AsianViewNotFoundView /> : children}
        {!withoutFooter && <AsianFooter />}
      </div>
      {!withoutNavigation && !isMobileAsianView && <AsianRightNavigation />}
    </div>
  );
};

export default AsianViewContent;
