import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import GamesBaccaratResultsContent from 'components/GamesBaccaratResultsContent';
import GamesBlackJackResultsContent from 'components/GamesBlackJackResultsContent';
import GamesDerbyRacingResultsContent from 'components/GamesDerbyRacingResultsContent';
import GamesHiLoResultsContent from 'components/GamesHiLoResultsContent';
import Loader, { CircleColors } from 'components/Loader';
import PokerAndOmaha from 'components/ResultsScreen/components/PokerAndOmaha';
import { DEFAULT_GAMES_RESULT_PAGE, GameFilterValues, GameTypes } from 'constants/games';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchResultsHistory } from 'redux/modules/games';
import { getGameType, getHistory } from 'redux/modules/games/selectors';
import { GameFilterValue } from 'redux/modules/games/type';

import FiltersBlock from './components/FiltersBlock';

import styles from './styles.module.scss';

const ResultsScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { gameId = '' } = useParams();

  const resultsHistory = useSelector(getHistory);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const gameType = useSelector(getGameType);

  const [filterValue, setFilterValue] = useState<GameFilterValue>(GameFilterValues.All);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFilterValue(value as GameFilterValue);
  };

  useEffect(() => {
    if (gameId) {
      dispatch(
        fetchResultsHistory({
          channelId: gameId,
          page: DEFAULT_GAMES_RESULT_PAGE,
          betPlacedOnly: filterValue === GameFilterValues.BetPlaced
        })
      );
    }
  }, [gameId, filterValue]);

  const getContentByGame = useCallback(() => {
    if (resultsHistory && !gameType) {
      return <div className={styles.noData}>{t('account.mybets.messages.noData')}</div>;
    }
    if (resultsHistory && gameType) {
      switch (gameType) {
        case GameTypes.BLACKJACK:
          return <GamesBlackJackResultsContent resultsHistory={resultsHistory} />;
        case GameTypes.POKER:
          return <PokerAndOmaha resultsHistory={resultsHistory} />;
        case GameTypes.OMAHA_POKER:
          return <PokerAndOmaha resultsHistory={resultsHistory} />;
        case GameTypes.BACCARAT:
          return <GamesBaccaratResultsContent resultsHistory={resultsHistory} />;
        case GameTypes.HILO:
          return <GamesHiLoResultsContent resultsHistory={resultsHistory} />;
        case GameTypes.CARD_RACER:
          return <GamesDerbyRacingResultsContent resultsHistory={resultsHistory} />;
        default:
          return null;
      }
    }
    return <Loader circleColor={CircleColors.BLACK} />;
  }, [resultsHistory, gameType]);

  return (
    <div className={styles.resultsMain}>
      {isLoggedIn && <FiltersBlock handleChangeFilter={handleChangeFilter} />}
      {getContentByGame()}
    </div>
  );
};

export default ResultsScreen;
