import { TFunction } from 'react-i18next';

import { SportIds } from 'constants/app';
import { DEFAULT_MARKET_UNITS } from 'constants/placement';
import { TCurrentPL } from 'redux/modules/plTable/type';

interface ITotalGoal {
  [key: string]: {
    min: number;
    max: number;
    current: string;
    worst: string;
  };
}

const PL_TABLE_KEYS = {
  CURRENT: 'current',
  WORST: 'worst'
};

export const mapperPlToTable = (currentPL: TCurrentPL[], worstCasePL: TCurrentPL[]): ITotalGoal => {
  const combinedArray = currentPL.map((currentItem, index) => ({
    virtualPoints: currentItem.virtualPoints,
    current: currentItem.profitOrLoss,
    worst: worstCasePL[index].profitOrLoss
  }));

  const counts = combinedArray.reduce(
    (acc, { current, worst }) => {
      acc.current[current] = (acc.current[current] || 0) + 1;
      acc.worst[worst] = (acc.worst[worst] || 0) + 1;
      return acc;
    },
    { current: {} as { [key: string]: number }, worst: {} as { [key: string]: number } }
  );

  const keyToUse =
    Object.keys(counts.current).length > Object.keys(counts.worst).length ? PL_TABLE_KEYS.CURRENT : PL_TABLE_KEYS.WORST;

  return combinedArray.reduce((acc: ITotalGoal, { virtualPoints, current, worst }, index, src) => {
    const key = keyToUse === PL_TABLE_KEYS.CURRENT ? current : worst;

    if (!acc[key]) {
      acc[key] = {
        min: virtualPoints,
        max: virtualPoints,
        current,
        worst
      };
    } else {
      acc[key].max = virtualPoints;
    }

    if (src[index + 1] && (src[index + 1].current !== current || src[index + 1].worst !== worst)) {
      acc[key].max = src[index + 1].virtualPoints - 1;
    }

    return acc;
  }, {});
};

interface IGetTitle {
  index: number;
  min: number;
  max: number;
  length: number;
  t: TFunction<'translation', undefined>;
  sportId: string;
}

export const getTitle = ({ index, min, max, length, t, sportId }: IGetTitle) => {
  const point =
    sportId === SportIds.SOCCER ? t('PLTable.goals') : sportId === SportIds.CRICKET ? '' : DEFAULT_MARKET_UNITS;

  if (index === 0) {
    const points = max + 1 === 1 ? point.slice(0, -1) : point;
    return t('PLTable.ifLessThan', { points: `${max + 1} ${points}` });
  }
  if (index === length - 1) {
    const points = min === 1 ? point.slice(0, -1) : point;
    return t('PLTable.ifMoreThan', { points: `${min} ${points}` });
  }
  return `${t('PLTable.if', { start: min, end: max })} ${point}`;
};
