import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import EducationMobileSubheader from 'components/Header/components/EducationMobileSubheader';
import HeaderNavigation from 'components/HeaderNavigation';
import AccountPagesSubheader from 'components/HeaderNavigation/components/AccountPagesSubheader';
import EducationalPortalSubheader from 'components/HeaderNavigation/components/EducationalPortalSubheader';
import SessionTime from 'components/SessionTime';
import { ACCOUNT_BASE_URL, EDUCATIONAL_PORTAL_BASE_URL, RESPONSIBLE_BETTING_BASE_URL } from 'constants/locations';
import { MyBetsLocations } from 'constants/myBets';
import MobileAccountFiltersHeader from 'pages/MyBetsPages/components/MobileAccountFiltersHeader';
import {
  getDesktopHeaderHidden,
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsHeadsUpOverHeader,
  getIsIframeEnabled,
  getIsPassiveSessionEnabled
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getIsMobileFiltersOpen } from 'redux/modules/myBets/selectors';
import { getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';
import { getUserSessionInfo } from 'redux/modules/user/selectors';

import MobileAccountHeader from '../MobileAccountHeader';

import HeaderButtons from './components/HeaderButtons';
import HeaderResize from './components/HeaderResize';
import HeaderTop from './components/HeaderTop';

import styles from './styles.module.scss';

interface HeaderProps {
  isAsianView?: boolean;
  isMobile: boolean;
  isSelfExcluded?: boolean;
}

const Header = ({ isAsianView = false, isSelfExcluded = false, isMobile }: HeaderProps) => {
  const location = useLocation();
  const { t } = useTranslation();

  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const desktopHeaderHidden = useSelector(getDesktopHeaderHidden);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const passiveSessionEnabled = useSelector(getIsPassiveSessionEnabled);
  const headsUpOverHeader = useSelector(getIsHeadsUpOverHeader);
  const sessionInfo = useSelector(getUserSessionInfo);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isMobileFiltersOpen = useSelector(getIsMobileFiltersOpen);

  const headerWarnMessage = t('responsibleBetting.message.HEAD_UP_OVER_HEADER');

  const isSessionCounterEnabled = passiveSessionEnabled && sessionInfo?.startSessionDate;

  const isShowHeaderBottom = !isMobile && (exchangeGamesEnabled || asianViewEnabled || exchangeSportsEnabled);

  const isAccountPage = location.pathname.includes(ACCOUNT_BASE_URL);
  const isEduPortalPage = location.pathname.includes(EDUCATIONAL_PORTAL_BASE_URL);
  const isResponsibleBettingPage = location.pathname.includes(RESPONSIBLE_BETTING_BASE_URL);

  const isMyAccountPages =
    isResponsibleBettingPage ||
    location.pathname.includes(MyBetsLocations.MyBets) ||
    location.pathname.includes(MyBetsLocations.Statement) ||
    location.pathname.includes(MyBetsLocations.ProfitAndLoss);

  const renderDesktopHeader = () => {
    if (
      !isMobile &&
      isLoggedIn &&
      !isAsianView &&
      !isSelfExcluded &&
      (isTimeOutEnabled || isAccountPage || isResponsibleBettingPage)
    ) {
      return <AccountPagesSubheader />;
    }

    if (!isMobile && !isAsianView && isEduPortalPage) {
      return <EducationalPortalSubheader />;
    }

    if (!isMobile && !isAsianView && !isSelfExcluded) {
      return <HeaderNavigation isMobile={isMobile} />;
    }

    return null;
  };

  return (
    <>
      <HeaderResize isMobile={isMobile}>
        {!(iframeEnabled && desktopHeaderHidden) && (
          <header
            id="biab_header"
            className={classNames(styles.header, {
              [styles.header__self_excluded]: isSelfExcluded
            })}
          >
            {headsUpOverHeader && (
              <div
                id="biab_headsUpOver"
                className={styles.header__headsUpOver}
                dangerouslySetInnerHTML={{ __html: headerWarnMessage }}
              />
            )}
            <HeaderTop isMobile={isMobile} />
            {isShowHeaderBottom && !isSelfExcluded && (
              <HeaderButtons
                isGames={exchangeGamesEnabled}
                isAsianView={asianViewEnabled}
                sportEnabled={exchangeSportsEnabled}
              />
            )}
            {isMobile && isSessionCounterEnabled && <SessionTime isMobile={isMobile} />}
          </header>
        )}
        {renderDesktopHeader()}
        {isMobile && isEduPortalPage && <EducationMobileSubheader />}
        {isMobile && isMyAccountPages && !isMobileFiltersOpen && <MobileAccountHeader />}
        {isMobile && isMyAccountPages && isMobileFiltersOpen && <MobileAccountFiltersHeader />}
      </HeaderResize>
      {isMobile && !isAsianView && !isSelfExcluded && <HeaderNavigation isMobile={isMobile} />}
    </>
  );
};

export default Header;
