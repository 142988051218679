import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import EventMarket from 'components/EventMarket';
import { CookieNames, Devices, PageBlocks, PlacementPages } from 'constants/app';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { setExtraMultiMarketTab } from 'redux/modules/multiMarket';
import {
  getMultiMarketRulesLoading,
  getMultiMarketsRules,
  getMultiMarketTop
} from 'redux/modules/multiMarket/selectors';
import { IMarket } from 'types/markets';
import { getCollapsedCookie } from 'utils/cookie';
import { serializeCollapsedCookies } from 'utils/market';

import styles from '../../styles.module.scss';

interface MultiMarketSimpleMarketsProps {
  markets: IMarket[];
  onFetchMarketRules: (marketId: string) => void;
  displayFancyMultiMarkets: boolean;
  isDepthEnabled: boolean;
  isLandscape: boolean;
}

const MultiMarketSimpleMarkets = ({
  markets,
  onFetchMarketRules,
  displayFancyMultiMarkets,
  isDepthEnabled,
  isLandscape
}: MultiMarketSimpleMarketsProps) => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    CookieNames.MULTI_MARKETS_COLLAPSED,
    CookieNames.MULTI_MARKETS_OTHER_IS_OPENED,
    CookieNames.PL_TABLE_VISIBLE
  ]);
  const [searchParams] = useSearchParams();

  const device = useSelector(getAppDevice);
  const topMultiMarket = useSelector(getMultiMarketTop);
  const rulesLoading = useSelector(getMultiMarketRulesLoading);
  const rules = useSelector(getMultiMarketsRules);

  const { inlineBetting } = useDeviceSettings();

  const viewMarketSearchParam = searchParams.get('viewMarket');
  const isDesktop = device === Devices.DESKTOP;
  const collapsedMarkets = cookies.MULTI_MARKETS_COLLAPSED
    ? serializeCollapsedCookies(cookies.MULTI_MARKETS_COLLAPSED.split(','))
    : {};
  let showDeepPrices = false;

  if (isDesktop) {
    showDeepPrices = isDepthEnabled && !topMultiMarket?.twoColumnView;
  } else {
    if (isLandscape) {
      showDeepPrices = isDepthEnabled;
    } else {
      showDeepPrices = false;
    }
  }

  const setMarketCollapse = (marketId: string, value: boolean) => {
    setCookie(
      CookieNames.MULTI_MARKETS_COLLAPSED,
      getCollapsedCookie(marketId, value, cookies.MULTI_MARKETS_COLLAPSED)
    );
  };

  const handleClickBetCell = useCallback(
    (market: IMarket) => {
      if (!isDesktop && !inlineBetting) {
        dispatch(setExtraMultiMarketTab(market));
      }
    },
    [isDesktop, inlineBetting, dispatch]
  );

  return (
    <div
      className={classNames('biab_multi-markets-section', styles.multiMarket__otherMarkets__sections, {
        [styles.multiMarket__otherMarkets__sections__2columns]: topMultiMarket?.twoColumnView && isDesktop,
        [styles.multiMarket__otherMarkets__sections__contents]:
          displayFancyMultiMarkets && topMultiMarket?.twoColumnView && isDesktop
      })}
    >
      {markets.map((market, index, arr) => (
        <div
          key={market.marketId}
          className={classNames('biab_multi-market', styles.multiMarket__otherMarkets__sections__item, {
            [styles.multiMarket__otherMarkets__sections__item__2columns]: topMultiMarket?.twoColumnView && isDesktop
          })}
        >
          <EventMarket
            market={market}
            rulesLoading={rulesLoading}
            onFetchMarketRules={onFetchMarketRules}
            marketIndex={index}
            marketsRules={rules}
            pageBlock={PageBlocks.MULTI_MARKET_VIEW}
            collapsedMarkets={collapsedMarkets}
            setMarketCollapse={setMarketCollapse}
            collapseTitleClassName="biab_multi-market-title"
            isRightArrow
            showDeepPrices={showDeepPrices}
            collapseInPlayClassName="biab_multi-market-inplay"
            redirectToSingleMarketPage={isDesktop || !viewMarketSearchParam}
            collapseInPlayStatusFirst
            showMarketTableTooltip={!isDesktop}
            onRedirectToSingleMarketPage={handleClickBetCell}
            redirectToSingleMarketSearchParams={isDesktop ? '' : 'viewMarket=true'}
            doubleCellsWidth={topMultiMarket?.twoColumnView}
            showEmptyColumns={!isDepthEnabled && !topMultiMarket?.twoColumnView}
            isOtherSection
            page={PlacementPages.MULTI_MARKET}
            classes={{
              market: classNames(styles.multiMarket__otherMarkets__market, {
                [styles.multiMarket__otherMarkets__market__last]: index === arr.length - 1
              }),
              viewMarket: styles.multiMarket__otherMarkets__viewMarket,
              collapseHeader: styles.multiMarket__otherMarkets__collapseHeader,
              runnersContainer: styles.multiMarket__paddingRight,
              marketTableHeader: styles.multiMarket__paddingRight
            }}
            disableRemoveSocketParams={!isDesktop}
          />
        </div>
      ))}
    </div>
  );
};

export default MultiMarketSimpleMarkets;
