import { createSelector } from '@reduxjs/toolkit';

import { AsianViewSections } from 'constants/asianView';
import { AppState } from 'redux/reducers';

export const getAsianViewFavouritesList = ({ asianViewFavourites }: AppState) => asianViewFavourites.favourites;
export const getIsFavouriteById =
  (id: string) =>
  ({ asianViewFavourites }: AppState) =>
    asianViewFavourites.favouritesIds[id] ?? false;

export const getAsianViewIsFavouritesLoaded = ({ asianViewFavourites }: AppState) =>
  asianViewFavourites.isFavouritesLoaded;
export const getIsAsianViewFavourites = ({ asianViewFavourites }: AppState) =>
  asianViewFavourites.favourites.length > 0;
export const getAsianViewFavouritesLoading = ({ asianViewFavourites }: AppState) => asianViewFavourites.loading;
export const getAsianViewFavouritesMobileEventsListContent = ({ asianViewFavourites }: AppState) =>
  asianViewFavourites.favouritesMobileEventsList.content;
export const getAsianViewFavouritesMobileEventsLoading = ({ asianViewFavourites }: AppState) =>
  asianViewFavourites.favouritesMobileEventsLoading;
export const getAsianViewFavouritesMobileEventsTotalPages = ({ asianViewFavourites }: AppState) =>
  asianViewFavourites.favouritesMobileEventsList.totalPages;
export const getAsianViewFavouritesMobileEventsLast = ({ asianViewFavourites }: AppState) =>
  asianViewFavourites.favouritesMobileEventsList.last;
export const getAsianViewFavouritesMobileEventsTotalElements = ({ asianViewFavourites }: AppState) =>
  asianViewFavourites.favouritesMobileEventsList.totalElements;
export const getAsianViewFavouritesMobileEventsCompetitionKeys = ({ asianViewFavourites }: AppState) =>
  Object.keys(asianViewFavourites.favouritesMobileEventsList.content);
export const getAsianViewFavouritesMobileEvents =
  (competitionKey: string) =>
  ({ asianViewFavourites }: AppState) =>
    asianViewFavourites.favouritesMobileEventsList.content[competitionKey];

export const getIsAsianViewFavouritesMobileCompetitionClosed = (
  competitionKey: string,
  closedMarketsIds: string[] = []
) =>
  createSelector(getAsianViewFavouritesMobileEvents(competitionKey), events =>
    events.every(({ sections }) => sections[AsianViewSections.TOP]?.every(({ id }) => closedMarketsIds.includes(id)))
  );

export const getAsianViewFavouritesMobileEventsIds = createSelector(
  getAsianViewFavouritesMobileEventsListContent,
  content => Object.values(content).flatMap(events => events.map(event => event.id))
);
