import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import GameScreen from 'components/GameScreen';
import ResultsScreen from 'components/ResultsScreen';
import RotateDevice from 'components/RotateDevice';
import SingleGamePageHeader from 'components/SingleGamePageHeader';
import { Devices, ElementNames } from 'constants/app';
import { GamePages } from 'constants/games';
import useAddHeightForFooter from 'hooks/useAddHeightForFooter';
import { getAppDevice, getIsLandscape, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { removeAllGamesSelectedBets } from 'redux/modules/betslip';
import { fetchCurrentGameData, resetGameData } from 'redux/modules/games';
import { getSelectedGamePage } from 'redux/modules/games/selectors';

import GamePageRequestIntervalInjection from './components/GamePageRequestIntervalInjection/GamePageRequestIntervalInjection';

const GamePage = () => {
  const dispatch = useDispatch();
  const { gameId = '' } = useParams();

  const selectedGamePage = useSelector(getSelectedGamePage);
  const language = useSelector(getLanguage);
  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);
  const isLandscape = useSelector(getIsLandscape);

  const { target, targetHeight, addedHeight } = useAddHeightForFooter();

  const isDesktop = device === Devices.DESKTOP;

  useEffect(() => {
    return () => {
      dispatch(removeAllGamesSelectedBets());
      dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (gameId && (isLandscape || isDesktop)) {
      dispatch(fetchCurrentGameData(gameId));

      return () => {
        dispatch(resetGameData());
      };
    }
  }, [gameId, language, timezone, isDesktop, isLandscape]);

  useEffect(() => {
    dispatch(setElementHeight({ name: ElementNames.CONTENT_HEIGHT, height: targetHeight }));
  }, [targetHeight]);

  return (
    <>
      <GamePageRequestIntervalInjection isDesktop={isDesktop} isLandscape={isLandscape} />
      <div ref={target}>
        {!isLandscape && !isDesktop && <RotateDevice />}
        {(isLandscape || isDesktop) && (
          <>
            <SingleGamePageHeader />
            {selectedGamePage === GamePages.GAME ? <GameScreen /> : <ResultsScreen />}
          </>
        )}
      </div>
      {addedHeight > 0 && <div style={{ height: addedHeight }} />}
    </>
  );
};

export default GamePage;
