import {
  CancelActionStatuses,
  CashOutStatuses,
  CashOutTabs,
  CurrencySettingsPlacements,
  CurrentBetActions,
  Devices,
  ElementNames,
  EventStatuses,
  EventViewTypes,
  HomePageTypes,
  LiveWidgetHeaderTypes,
  MarketStatuses,
  Methods,
  NavigationItemTypes,
  OddsTypes,
  PageBlocks,
  PlacementPages,
  Platforms,
  PrecisionTypes,
  SortingCookieValues,
  TypeFields,
  ViewsBy
} from 'constants/app';
import { NavigationTypes, TimeFilters } from 'constants/competitions';
import { FancyMarketTypes } from 'constants/fancyMultiMarket';
import { AB, CLASSIC_NAV_CLASSES, DEFAULT as ICON_DEFAULT, POKER_STAR } from 'constants/icons';

// types/interfaces that used in the whole app

export interface Generator<T = unknown, TReturn = any, TNext = unknown> extends Iterator<T, TReturn, TNext> {
  next(...args: [] | [TNext]): IteratorResult<T, TReturn>;

  return(value: TReturn): IteratorResult<T, TReturn>;

  throw(e: any): IteratorResult<T, TReturn>;

  [Symbol.iterator](): Generator<T, TReturn, TNext>;
}

export interface RequestParams<T = null> {
  method: Method;
  url: string;
  data?: T;
  params?: any;
  withCredentials: boolean;
}

export type TFailureActionPayload = Error | null | undefined;
export type FailureActionPayload = Error | unknown;
export type Handler = (event: MouseEvent | TouchEvent) => void;

export interface DropdownItem {
  title?: string;
  label?: string;
  image?: string;
  /** icon className */
  icon?: string;
  tooltip?: string;
  hasDivider?: boolean;
  /**
   * This property is used when selected label should be translatable, because if pass to component already translated
   * labels and then change locale you will see that label of selected item doesn't change. This happens because
   * label of selected item was translated only one time when it was passed, but after locale changing we need translate
   * this label again. So if you want to use labels with translations just pass translations keys as labels and true for
   * this property, f.e.: look at viewByOptions in pages/InPlay component
   */
  isLabelTranslatable?: boolean;
  id: number | string;
  value: string;
}

export interface DropdownClasses {
  container?: string;
  placeholder?: string;
  activePlaceholder?: string;
  dropdown?: string;
  option?: string;
  selectedOption?: string;
  icon?: string;
  selectedIcon?: string;
  tooltipIcon?: string;
  disabledDropdown?: string;
}

export interface BrandingDropdownClasses {
  dropdownBtn?: string;
  dropdownItem?: string;
  dropdownItems?: string;
  active?: string;
  selected?: string;
}

export interface SubheaderIconsType {
  [key: string]: string;
}

export interface GetIconName {
  iconName: string;
  sportId: string | number;
  modifiable: boolean;
  url: string;
  page: string;
}

export interface IconsConfigProps {
  SPORT_ICONS: { [key: string]: string };
  TOOLBAR_ICONS: {
    [AB]: Record<string, string>;
    [ICON_DEFAULT]: Record<string, string>;
  };
  SUBHEADER_ICONS: {
    [POKER_STAR]: Record<string, string>;
    [ICON_DEFAULT]: Record<string, string>;
    [CLASSIC_NAV_CLASSES]: Record<string, string>;
  };
  NAVIGATION_ICONS: {
    [POKER_STAR]: Record<string, string>;
    DEFAULT_ICON: string;
  };
}

export type TSocketMarketParams = {
  marketId: string;
  eventId: string;
  authToken?: string;
  applicationType?: string;
};

export type Inning = {
  overs: string;
  runs: string;
  wickets: string;
};

export type TEventsUpdatedDataTeam = {
  aces: null;
  currentBreak: null;
  doubleFaults: null;
  fullTimeScore: string;
  gameSequence: any;
  halfTimeScore: string;
  games: string;
  highlight: boolean;
  inning1: Inning | null;
  inning2: Inning | null;
  localizedNames: Record<string, string>;
  name: string | null;
  playerSeed: null;
  quarterByQuarter: number[];
  score: string;
  serviceBreaks: null;
  sets: string;
};

export const EventScorePeriod = {
  REGULAR: 'REGULAR',
  EXTRA: 'EXTRA'
} as const;

export type TEventScorePeriod = typeof EventScorePeriod[keyof typeof EventScorePeriod];

export const PenaltyResult = {
  SCORED: 'scored',
  MISSED: 'missed',
  CANCELLED: 'cancelled',
  FIRST_TEAM_TO_SHOOT: 'first_team_to_shoot'
} as const;

export type TPenaltyResult = typeof PenaltyResult[keyof typeof PenaltyResult];

export type TEventExtraData = {
  eventExtraData: {
    currentSet: number;
    currentDay: string;
    fullTimeElapsed: {
      hour: number;
      min: number;
      sec: number;
    };
    timeElapsedSeconds: number;
  };
  team: string;
  updateType: string;
  matchTime: number;
  footballWidth: number | string;
  elapsedRegularTime: number;
  period: TEventScorePeriod;
  penaltyResult: TPenaltyResult;
  inPlayMatchStatus: string | null;
};

export type TEventUpdatedData = {
  eventId: string;
  eventTypeId: number;
  score: {
    home: TEventsUpdatedDataTeam;
    away: TEventsUpdatedDataTeam;
  };
  inPlayMatchStatus: string | null;
  timeElapsed: number;
  elapsedRegularTime: number | null;
  elapsedAddedTime: number | null;
  marketId: null;
  status: EventStatus | null;
  asianViewElapsedRegularTime: number | null;
  period: TEventScorePeriod;
  updateDetails?: TEventExtraData[];
};

export type TTimezone = Record<string, number>;
export type TOddsTypes = Record<OddsType, string>;

export type TGamesTitles = Record<string, string>;

export type TGameChannels = {
  [key: number]: {
    order: number;
    isTurbo: boolean;
  };
};

export type TGameOrder = Record<string, number>;

export interface BetContentGroupStyles {
  betContent?: Record<string, string | number>;
}

export interface BetContentGroupClasses {
  betContent?: string;
}

export interface BetContentCellClasses {
  cell?: string;
  container?: string;
  content?: string;
  odds?: string;
}

export interface BetContentCellStyles {
  cell?: Record<string, string | number>;
}

export type TMobileAccountDropdownValue = {
  name: string;
  labelTranslationKey: string;
  url: string;
  isVisible?: boolean;
  isSubMenu?: boolean;
};

export type ModalClasses = {
  container?: string;
  dialog?: string;
  content?: string;
  header?: string;
  headerContent?: string;
  closeIcon?: string;
  body?: string;
  backdrop?: string;
  title?: string;
};

export type EventWidgetTab = null | 'video-stream' | 'match-statistics';

export type TListViewTabs = {
  id: string;
  name: string;
};

export const TimeFormats = {
  LOCAL_TIME: 'localTime',
  BETTING_DAY: 'bettingDay'
} as const;

export const Periods = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'thisWeek',
  LAST_WEEK: 'lastWeek',
  LAST_30_DAYS: 'last30Days',
  LAST_3_MONTHS: 'last3Months',
  CUSTOM: 'custom'
} as const;

export type TPeriods = typeof Periods[keyof typeof Periods];
export type Method = typeof Methods[keyof typeof Methods];
export type EventStatus = typeof EventStatuses[keyof typeof EventStatuses];
export type OddsType = typeof OddsTypes[keyof typeof OddsTypes];
export type Device = typeof Devices[keyof typeof Devices];
export type HomePageType = typeof HomePageTypes[keyof typeof HomePageTypes];
export type EventViewType = typeof EventViewTypes[keyof typeof EventViewTypes];
export type ViewBy = typeof ViewsBy[keyof typeof ViewsBy];
export type PrecisionType = typeof PrecisionTypes[keyof typeof PrecisionTypes];
export type MarketStatus = typeof MarketStatuses[keyof typeof MarketStatuses];
export type PlacementPage = typeof PlacementPages[keyof typeof PlacementPages];
export type PageBlock = typeof PageBlocks[keyof typeof PageBlocks];
export type NavigationItemType = typeof NavigationItemTypes[keyof typeof NavigationItemTypes];
export type Platform = typeof Platforms[keyof typeof Platforms];
export type CurrencySettingsPlacement = typeof CurrencySettingsPlacements[keyof typeof CurrencySettingsPlacements];
export type LiveWidgetHeaderType = typeof LiveWidgetHeaderTypes[keyof typeof LiveWidgetHeaderTypes];
export type CashOutTab = typeof CashOutTabs[keyof typeof CashOutTabs];
export type SortingCookieValue = typeof SortingCookieValues[keyof typeof SortingCookieValues];
export type ElementName = typeof ElementNames[keyof typeof ElementNames];
export type CurrentBetAction = typeof CurrentBetActions[keyof typeof CurrentBetActions];
export type TimeFilter = typeof TimeFilters[keyof typeof TimeFilters];
export type NavigationType = typeof NavigationTypes[keyof typeof NavigationTypes];
export type CashOutStatus = typeof CashOutStatuses[keyof typeof CashOutStatuses];
export type FancyMarketType = typeof FancyMarketTypes[keyof typeof FancyMarketTypes];
export type CancelActionStatus = typeof CancelActionStatuses[keyof typeof CancelActionStatuses];
export type TypeField = typeof TypeFields[keyof typeof TypeFields];

export type FieldSetting = {
  [key in TypeField]: boolean;
};

export type WebSocketProperties = {
  properties: {
    marketOddsBackOnly: boolean;
  };
};

export type TMultiselectDropdownItem = {
  id: string;
  label: string;
  isSelected?: boolean;
  onSelect?: (option: TMultiselectDropdownItem) => void;
};
