import { useCallback, useEffect, useRef } from 'react';
import BodyClassName from 'react-body-classname';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import LandingPageRedirect from 'components/LandingPageRedirect';
import { COLLAPSE_NAVIGATION_COOKIE_KEY } from 'components/LeftNavigation/HideMenuButton';
import LoadingWithOverlay from 'components/LoadingWithOverlay';
import MobilePlacementNotifications from 'components/MobilePlacement/MobilePlacementNotifications/MobilePlacementNotifications';
import ResponsibleBettingModals from 'components/ResponsibleBettingModals';
import BrowserRoutes from 'components/Routes';
import Tooltip from 'components/Tooltip';
import {
  BannerNames,
  BroadCastChannels,
  BroadCastHandledTypes,
  COLLAPSIBLE_SIDEBAR_WINDOW_WIDTH,
  CookieNames,
  Devices,
  LANDSCAPE_MIN_WIDTH,
  MOBILE_MAX_DISPLAY_SIZE,
  ONLY_MOBILE_URLS,
  PostMessageTypes,
  RESPONSIBLE_BETTING_SELF_EXCLUSION_PAGE_URL,
  SportIds
} from 'constants/app';
import {
  ASIAN_VIEW_AUTO_COLLAPSED_RIGHT_SIDE_WIDTH,
  ASIAN_VIEW_MOBILE_MIN_WIDTH,
  ASIAN_VIEW_MOBILE_WIDTH,
  AsianViewMarketLinks,
  AsianViewTimeFilters
} from 'constants/asianView';
import { BannerSections } from 'constants/banners';
import {
  ACCOUNT_BASE_URL,
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  CALC_BASE_URL,
  GAMES_BASE_URL,
  HOME_BASE_URL,
  MOBILE_LOGIN_BASE_URL,
  MY_BETS_BASE_URL,
  SEARCH_BASE_URL
} from 'constants/locations';
import {
  BetsTypesByPeriods,
  MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME,
  MyBetsLocations,
  MyBetsPeriods
} from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useReminder from 'hooks/useReminder';
import { useTimeLimit } from 'hooks/useTimeLimit';
import AsianLeagueFilterInjection from 'injections/AsianLeagueFilterInjection';
import AsianViewBetSlipBetsStatusesInjection from 'injections/AsianViewBetSlipBetsStatusesInjection';
import BalanceInjection from 'injections/BalanceInjection';
import CashOutStatusesIntervalsInjection from 'injections/CashOutStatusesIntervalsInjection';
import CssInjection from 'injections/CssInjection';
import CssOperatorInjection from 'injections/CssOperatorInjection';
import CurrencyAndResponsibleBettingSettingsInjection from 'injections/CurrencyAndResponsibleBettingSettingsInjection';
import CurrentBetsInjection from 'injections/CurrentBetsInjection';
import FontsInjection from 'injections/FontsInjection';
import IframeUserActivityInjection from 'injections/IframeUserActivityInjection';
import PageHeightInjection from 'injections/PageHeightInjection';
import ResponsibleBettingCountersInjection from 'injections/ResponsibleBettingCountersInjection';
import WebSocketsSubscriptionsInjection from 'injections/WebSocketsSubscriptionsInjection';
import { AxiosInterceptorsInjection } from 'redux/api/request';
import {
  fetchAppConfigs,
  fetchContentPages,
  setBackendContent,
  setIsCollapsibleRightSide,
  setIsLandscape,
  setTimezone,
  setUserDevice,
  setWindowSizes
} from 'redux/modules/appConfigs';
import {
  getAppDevice,
  getBackendPages,
  getBrandingCSSLoaded,
  getDesktopSettingsCollapseSportsBar,
  getEduPortalEnabled,
  getFallbackLang,
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsIframeEnabled,
  getIsPropertiesLoaded,
  getLanguage
} from 'redux/modules/appConfigs/selectors';
import { fetchAppSettings, setAccountPageReferer } from 'redux/modules/appSettings';
import { getParentDomain } from 'redux/modules/appSettings/selectors';
import { setIsAutoCollapsedRightSide, setIsLandscapeAsianView, setIsMobileAsianView } from 'redux/modules/asianView';
import { proceedLogout, setAfterLogOutRedirect } from 'redux/modules/auth';
import { getLoggedInStatusState, getLogoutRedirectUrl } from 'redux/modules/auth/selectors';
import { fetchBanner } from 'redux/modules/banners';
import { removeAllSelectedBets } from 'redux/modules/betslip';
import { getIsCancelActionMessage } from 'redux/modules/cancelActions/selectors';
import { cleanInlineSelectedBets, removeAllMobilePlacementNotifications } from 'redux/modules/inlinePlacement';
import { getAreMobilePlacementNotifications } from 'redux/modules/inlinePlacement/selectors';
import { resetPageData } from 'redux/modules/myBets';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { getIsShowingCustomLinkPopup } from 'redux/modules/popup/selectors';
import { setCustomerSelfExclusionStartDate } from 'redux/modules/responsibleBetting';
import {
  getIsResponsibleBettingLoaded,
  getIsSelfExclusionEnabled,
  getIsTimeOutEnabled,
  getResponsibleBettingSettings
} from 'redux/modules/responsibleBetting/selectors';
import { fetchTooltips } from 'redux/modules/tooltip';
import { fetchUserInfo } from 'redux/modules/user';
import { getIsUserInfoLoaded, getOperatorCurrency } from 'redux/modules/user/selectors';
import {
  clearAllTimers,
  convertTimezoneToDropdown,
  getOperatorDomain,
  getOperatorLanguage,
  iosBundleRedirect,
  redirectToOperator
} from 'utils';
import { setIOSBundleInitialCookies } from 'utils/cookie';
import { getDefaultTimezoneOffset, getTimezoneCookieName } from 'utils/date';
import { dispatchEvent, sendIframeMessages, subscribeOnIframeMessages } from 'utils/iframe';
import { getEnvironmentRootPath } from 'utils/navigation';
import { getSelfExclusionPageType } from 'utils/responsibleBetting';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { sportId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const isBrandingCSSLoaded = useSelector(getBrandingCSSLoaded);
  const isUserInfoLoaded = useSelector(getIsUserInfoLoaded);
  const isResponsibleBettingLoaded = useSelector(getIsResponsibleBettingLoaded);
  const logoutRedirectUrl = useSelector(getLogoutRedirectUrl);
  const language = useSelector(getLanguage);
  const fallbackLang = useSelector(getFallbackLang);
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const collapseSportsBar = useSelector(getDesktopSettingsCollapseSportsBar);
  const isShowingCustomLinkPopup = useSelector(getIsShowingCustomLinkPopup);
  const operatorCurrency = useSelector(getOperatorCurrency);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const parentDomain = useSelector(getParentDomain);
  const appDevice = useSelector(getAppDevice);
  const areMobileNotificationsMessages = useSelector(
    getAreMobilePlacementNotifications(location.pathname.includes(GAMES_BASE_URL))
  );
  const isCancelActionMessage = useSelector(getIsCancelActionMessage);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const pages = useSelector(getBackendPages);
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const isSelfExclusionEnabled = useSelector(getIsSelfExclusionEnabled);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);
  const eduPortalEnabled = useSelector(getEduPortalEnabled);

  const selfExclusionEnabled = rbSettings?.rgTools?.selfExclusionEnabled ?? false;
  const selfExclusionEndDate = rbSettings?.rgTools?.selfExclusionEndDate ?? 0;
  const selfExcludedThroughApi = rbSettings?.selfExcludedThroughApi ?? false;
  const selfExcludedThroughApiUntil = rbSettings?.selfExcludedThroughApiUntil ?? 0;
  const timeLimitAvailable = rbSettings?.rgTools?.timeLimitAvailable ?? 0;

  const iframeDomainsConfig = window?.environmentConfig?.iframeDomain || '';
  const iframeDomains = iframeDomainsConfig.split(',');
  const isAppReady = iframeDomainsConfig === '' || (iframeDomainsConfig !== '' && iframeDomains.includes(parentDomain));
  const isRoutesReady =
    isAppReady && isPropertiesLoaded && isUserInfoLoaded && (!isLoggedIn || (isLoggedIn && isResponsibleBettingLoaded));

  const divRef = useRef<HTMLDivElement>(null);
  const searchScrollPosition = useRef<number>(0);

  const timezoneCookieName = getTimezoneCookieName();

  const selfExclusionPageUrl = pages[PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START];

  const [cookies, setCookie, removeCookie] = useCookies([
    CookieNames.PL_NET_COMMISSION,
    CookieNames.ACCOUNT_DATETO,
    CookieNames.ACCOUNT_DATEFROM,
    COLLAPSE_NAVIGATION_COOKIE_KEY,
    timezoneCookieName,
    CookieNames.LANGUAGE,
    CookieNames.MY_BETS_TYPES
  ]);

  const convertedData = convertTimezoneToDropdown(t);
  const timezoneCookie = cookies[timezoneCookieName] || getDefaultTimezoneOffset();
  const selectedOffset = convertedData.find(item => item.value === `${timezoneCookie}`);
  const isClosedNavbar = cookies[COLLAPSE_NAVIGATION_COOKIE_KEY] === 'true' && collapseSportsBar;

  const { currentDevice, isMobile, isAsianViewPage, isAccountPage, isEducationPage } = useDevice();

  const isLoginPage = isMobile && location.pathname === MOBILE_LOGIN_BASE_URL;
  const isMobileLoginPage = isMobile && location.pathname.includes(MOBILE_LOGIN_BASE_URL);
  const isCalculatorPage = location.pathname.includes(CALC_BASE_URL);

  const handleResize = useCallback(() => {
    const maxDisplaySize = isAsianViewPage ? ASIAN_VIEW_MOBILE_WIDTH : MOBILE_MAX_DISPLAY_SIZE;
    const deviceToCheck = window.innerWidth > maxDisplaySize ? Devices.DESKTOP : Devices.MOBILE;

    if (deviceToCheck === Devices.DESKTOP && ONLY_MOBILE_URLS.includes(location.pathname)) {
      if (location.pathname === ASIAN_MOBILE_BET_LIST_URL) {
        navigate(`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${MyBetsPeriods.Open.toLowerCase()}/1`);
      } else {
        navigate(isAsianViewPage ? ASIAN_BASE_URL : HOME_BASE_URL);
      }
    }

    const device = window.innerWidth > MOBILE_MAX_DISPLAY_SIZE ? Devices.DESKTOP : Devices.MOBILE;

    dispatch(setWindowSizes({ width: window.innerWidth, height: window.innerHeight }));
    dispatch(
      setIsLandscape(
        iframeEnabled
          ? window.innerWidth > LANDSCAPE_MIN_WIDTH
          : window.innerHeight < window.innerWidth && window.innerWidth > LANDSCAPE_MIN_WIDTH
      )
    );
    dispatch(setUserDevice(device));
    dispatch(setIsMobileAsianView(window.innerWidth <= ASIAN_VIEW_MOBILE_WIDTH));
    dispatch(
      setIsLandscapeAsianView(
        window.innerWidth >= ASIAN_VIEW_MOBILE_MIN_WIDTH && window.innerWidth <= ASIAN_VIEW_MOBILE_WIDTH
      )
    );
    dispatch(setIsAutoCollapsedRightSide(window.innerWidth < ASIAN_VIEW_AUTO_COLLAPSED_RIGHT_SIDE_WIDTH));
    dispatch(setIsCollapsibleRightSide(window.innerWidth < COLLAPSIBLE_SIDEBAR_WINDOW_WIDTH));

    if (window.innerWidth >= COLLAPSIBLE_SIDEBAR_WINDOW_WIDTH) {
      setCookie(CookieNames.COLLAPSE_SIDEBAR, false, { path: '/' });
    }
  }, [iframeEnabled, isAsianViewPage, location.pathname]);

  useTimeLimit();
  useReminder();

  useEffect(() => {
    if (
      window.top == window.self &&
      iframeEnabled &&
      !iosBundleRedirect &&
      !location.pathname.includes(CALC_BASE_URL)
    ) {
      const operatorLanguage = getOperatorLanguage(cookies.BIAB_LANGUAGE);
      const operatorDomain = getOperatorDomain(operatorLanguage);

      redirectToOperator(operatorDomain);
    }

    handleResize();
  }, [iframeEnabled]);

  useEffect(() => {
    const setSearchScrollPosition = () => {
      searchScrollPosition.current = window.scrollY;
    };

    if (location.pathname === SEARCH_BASE_URL) {
      window.scrollTo({ top: searchScrollPosition.current });

      window.addEventListener('scroll', setSearchScrollPosition);
    } else {
      window.scrollTo(0, 0);
      if (divRef.current) divRef.current.scrollTo({ top: 0 });
    }

    window.addEventListener('resize', handleResize);
    window.history.scrollRestoration = 'manual';

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', setSearchScrollPosition);
    };
  }, [location.pathname, iframeEnabled]);

  useEffect(() => {
    if (isAppReady && isPropertiesLoaded) {
      dispatch(fetchUserInfo(true));
    }
  }, [dispatch, operatorCurrency, isAppReady, isPropertiesLoaded]);

  useEffect(() => {
    if (isAppReady) {
      dispatch(fetchContentPages());
      dispatch(fetchAppConfigs());
    }
  }, [currentDevice, dispatch, isAppReady]);

  useEffect(() => {
    if (language && isAppReady) {
      dispatch(fetchAppSettings(language));
      dispatch(
        fetchBanner({
          name: BannerNames.logo,
          locale: language,
          section: BannerSections.LOGO,
          platform: currentDevice
        })
      );
    }
  }, [currentDevice, language, dispatch, isAppReady]);

  useEffect(() => {
    if (isLoggedIn && isSelfExclusionEnabled) {
      const pageType = getSelfExclusionPageType(
        selfExclusionEndDate,
        selfExclusionEnabled,
        selfExcludedThroughApi,
        selfExcludedThroughApiUntil
      );

      dispatch(setBackendContent(pageType));
    }

    if (!selfExclusionPageUrl && location.pathname.includes(RESPONSIBLE_BETTING_SELF_EXCLUSION_PAGE_URL)) {
      if (!isLoggedIn && !logoutRedirectUrl?.includes(RESPONSIBLE_BETTING_SELF_EXCLUSION_PAGE_URL)) {
        navigate(isAsianViewPage ? ASIAN_BASE_URL : HOME_BASE_URL);
        dispatch(setCustomerSelfExclusionStartDate(null));
      }
    }
  }, [rbSettings, isLoggedIn, isSelfExclusionEnabled, location.pathname]);

  useEffect(() => {
    if (isLoggedIn && !location.pathname.includes(ACCOUNT_BASE_URL) && isTimeOutEnabled && !isSelfExclusionEnabled) {
      dispatch(setBackendContent(PagesFromBackend.RG_TOOLS_TIME_LIMIT_END));
    }
  }, [timeLimitAvailable, location.pathname, isLoggedIn]);

  useEffect(() => {
    if (location.pathname === HOME_BASE_URL) {
      if (asianViewEnabled && !isExchangeSportsEnabled) {
        navigate(
          `${ASIAN_BASE_URL}/sport/${SportIds.SOCCER}/timeFilter/${AsianViewTimeFilters.Today}/marketLink/${AsianViewMarketLinks.HDP_AND_OU}`,
          { replace: true }
        );
      } else if (isExchangeGamesEnabled && !isExchangeSportsEnabled) {
        navigate(GAMES_BASE_URL, { replace: true });
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isEducationPage && !eduPortalEnabled && isPropertiesLoaded) {
      navigate(HOME_BASE_URL, { replace: true });
    }
  }, [location.pathname, isPropertiesLoaded]);

  useEffect(() => {
    if (logoutRedirectUrl) {
      navigate(logoutRedirectUrl);
      dispatch(setAfterLogOutRedirect(''));
    }
  }, [logoutRedirectUrl]);

  useEffect(() => {
    if (language) {
      dispatch(fetchTooltips({ locale: language, platform: currentDevice, asianViewEnabled }));
    }
  }, [language, currentDevice, dispatch, asianViewEnabled]);

  useEffect(() => {
    if (selectedOffset && selectedOffset.value) {
      if (!cookies[timezoneCookieName]) {
        setCookie(timezoneCookieName, selectedOffset.value, { path: '/' });
      }

      dispatch(setTimezone(selectedOffset.value));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isUserInfoLoaded && !isLoggedIn) {
      removeCookie(CookieNames.ACCOUNT_DATEFROM, { path: '/' });
      removeCookie(CookieNames.ACCOUNT_DATETO, { path: '/' });
      removeCookie(CookieNames.PL_NET_COMMISSION, { path: '/' });
      dispatch(resetPageData());
    }
  }, [isLoggedIn, isUserInfoLoaded]);

  useEffect(() => {
    setIOSBundleInitialCookies(setCookie, location.search);
    subscribeOnIframeMessages(setCookie, removeCookie, dispatch, { fallbackLang }, currentDevice);

    const channel = new BroadcastChannel(BroadCastChannels.USER_ACTIONS);
    channel.onmessage = event => {
      if (event.data.type === BroadCastHandledTypes.LOGOUT) {
        clearAllTimers();
        dispatch(proceedLogout(event.data.payload));
      }
    };
  }, []);

  useEffect(() => {
    if (!sportId?.startsWith('tc')) {
      const currentUrl = `${window.location.origin}${getEnvironmentRootPath()}${location.pathname}`;

      if (iframeEnabled) {
        sendIframeMessages({
          type: PostMessageTypes.ROUTE_CHANGE,
          href: isAsianViewPage && location.search ? `${currentUrl}${location.search}` : currentUrl
        });
      } else {
        dispatchEvent({ type: PostMessageTypes.ROUTE_CHANGE, href: currentUrl });
      }
    }
  }, [location.pathname, location.search, iframeEnabled, currentDevice, sportId, isAsianViewPage]);

  useEffect(() => {
    if ((iframeEnabled && iframeDomainsConfig === '') || iframeDomainsConfig !== '') {
      sendIframeMessages({ type: PostMessageTypes.APP_LOADED });
    }
  }, [iframeEnabled, iframeDomainsConfig]);

  useEffect(() => {
    const isEditAction = searchParams.get('action') === 'edit';
    const offerId = searchParams.get('offerId');

    if (!isMobile || (!isEditAction && !offerId)) {
      dispatch(cleanInlineSelectedBets());
    }
    if (!location.pathname.includes(ACCOUNT_BASE_URL) && !location.pathname.includes(MOBILE_LOGIN_BASE_URL)) {
      dispatch(setAccountPageReferer(location.pathname));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (appDevice === Devices.MOBILE) {
      dispatch(removeAllSelectedBets());
    }
    dispatch(cleanInlineSelectedBets());

    if (areMobileNotificationsMessages && appDevice === Devices.DESKTOP) {
      dispatch(removeAllMobilePlacementNotifications());
    }
  }, [appDevice]);

  useEffect(() => {
    if (!isAccountPage || (!isLoggedIn && isUserInfoLoaded)) {
      localStorage.removeItem(MY_BETS_IS_SETTLED_PERIOD_TYPE_LOCAL_STORAGE_NAME);
      setCookie(CookieNames.MY_BETS_TYPES, BetsTypesByPeriods[MyBetsPeriods.Open], { path: '/' });
    }
  }, [isAccountPage, isLoggedIn]);

  return (
    <BodyClassName
      className={classNames('app_container', {
        biab_mobile: isMobile,
        disableScroll: isShowingCustomLinkPopup
      })}
    >
      <div
        ref={divRef}
        id="biab_body"
        className={classNames('biab_body biab_fluid biab_no-bottom-menu', {
          biab_mobileView: isMobile && !isCalculatorPage,
          'biab_minimized-nav': isClosedNavbar,
          biab_game:
            location.pathname.startsWith(`${GAMES_BASE_URL}`) || location.pathname.startsWith(MyBetsLocations.MyBets),
          biab_asianView: isAsianViewPage,
          biab_accountPage: isAccountPage || isEducationPage || isMobileLoginPage,
          biab_loginPage: isLoginPage
        })}
      >
        {isPropertiesLoaded && <LandingPageRedirect />}
        <CssInjection />
        <CssOperatorInjection />
        <WebSocketsSubscriptionsInjection />
        <BalanceInjection />
        {!iosBundleRedirect && <FontsInjection />}
        <AxiosInterceptorsInjection />
        <CurrentBetsInjection />
        <CurrencyAndResponsibleBettingSettingsInjection />
        <ResponsibleBettingCountersInjection />
        <PageHeightInjection />
        <CashOutStatusesIntervalsInjection />
        {asianViewEnabled && <AsianViewBetSlipBetsStatusesInjection />}
        <AsianLeagueFilterInjection />
        {iframeEnabled && <IframeUserActivityInjection />}
        {isRoutesReady && <BrowserRoutes />}
        {isBrandingCSSLoaded && !isRoutesReady && <LoadingWithOverlay />}
        <Tooltip />
        <ResponsibleBettingModals />
        {isMobile && !isAsianViewPage && (areMobileNotificationsMessages || isCancelActionMessage) && (
          <MobilePlacementNotifications />
        )}
      </div>
    </BodyClassName>
  );
}

export default App;
