import { Link, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_OUTRIGHT_MARKET_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE,
  ASIAN_VIEW_PAGE_PARAM,
  AsianViewMarketLinks,
  AsianViewTimeFilters
} from 'constants/asianView';
import { ASIAN_BASE_URL, ROOT_URL } from 'constants/locations';

import styles from './styles.module.scss';

type AsianViewSingleEventOutrightLinkProps = {
  isOutrightLink: boolean;
  sportId: string;
  competitionId: string;
  marketId: string;
  eventId: string;
  competitionName?: string;
  marketName?: string;
  eventName?: string;
  className?: string;
};

const AsianViewSingleEventOutrightLink = ({
  isOutrightLink,
  sportId,
  competitionId,
  marketId,
  eventId,
  competitionName,
  marketName,
  eventName,
  className = ''
}: AsianViewSingleEventOutrightLinkProps) => {
  const { timeFilter, marketLink } = useParams();
  const [searchParams] = useSearchParams();

  const sportIdParam = `&${ASIAN_SINGLE_VIEW_SPORT_ID}=${sportId}`;
  const pageSearchParam = searchParams.get(ASIAN_VIEW_PAGE_PARAM);
  const pageParam = pageSearchParam ? `&${ASIAN_VIEW_PAGE_PARAM}=0` : '';
  const betSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);
  const betSlipCashOutPageParam = betSlipCashOutPage
    ? `&${ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE}=${betSlipCashOutPage}`
    : '';

  if (isOutrightLink) {
    const marketLinkParam = marketLink || AsianViewMarketLinks.OUTRIGHT;
    const outrightLinkPathname =
      timeFilter && marketLink
        ? location.pathname.split(ROOT_URL)[1]
        : `${ASIAN_BASE_URL}/sport/${sportId}/timeFilter/${timeFilter || AsianViewTimeFilters.Today}/marketLink/${
            marketLinkParam || AsianViewMarketLinks.OUTRIGHT
          }`;

    return (
      <Link
        to={{
          pathname: outrightLinkPathname,
          search: `${ASIAN_OUTRIGHT_COMPETITION_ID}=${competitionId}&${ASIAN_OUTRIGHT_MARKET_ID}=${marketId}${betSlipCashOutPageParam}`
        }}
        className={classNames(styles.link, className)}
      >
        {competitionName && `${competitionName} - `}
        {marketName}
      </Link>
    );
  }

  return (
    <Link
      to={{
        pathname: `${ASIAN_BASE_URL}/sport/${sportId}/timeFilter/${
          timeFilter || AsianViewTimeFilters.Today
        }/marketLink/${AsianViewMarketLinks.HDP_AND_OU}`,
        search: `${ASIAN_SINGLE_VIEW_EVENT_ID}=${eventId}${sportIdParam}${pageParam}${betSlipCashOutPageParam}`
      }}
      className={classNames(styles.link, className)}
    >
      {eventName}
    </Link>
  );
};

export default AsianViewSingleEventOutrightLink;
