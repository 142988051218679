import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { asianViewBetslipBranding as branding } from 'constants/branding';
import { setSelectedTab } from 'redux/modules/asianViewBetslip';
import { setCollapseNewState } from 'redux/modules/collapse';
import { ASIAN_VIEW_BETSLIP_COLLAPSE, LEFT_PANEL_COLLAPSE_GROUP_ASIAN } from 'redux/modules/collapse/constants';
import { AsianBetslipTab } from 'types/asianView';

import styles from './styles.module.scss';

interface ITab {
  title: string;
  selected: boolean;
  tabKey: AsianBetslipTab;
  counter?: number;
  opened?: boolean;
}
const Tab = ({ title, selected, tabKey, counter, opened }: ITab) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <button
      onClick={() => {
        dispatch(setSelectedTab(tabKey));
        dispatch(
          setCollapseNewState({
            group: LEFT_PANEL_COLLAPSE_GROUP_ASIAN,
            collapse: ASIAN_VIEW_BETSLIP_COLLAPSE,
            openedState: true
          })
        );
      }}
      className={classNames(styles.tab, branding.BETSLIP_TAB, {
        [styles.selected]: selected,
        [styles.opened]: opened,
        [branding.SELECTED]: selected
      })}
    >
      {t(title)}
      {!!counter && counter > 0 && (
        <span className={classNames(styles.tab__counter, branding.BETSLIP_TAB_COUNTERS)}>{counter}</span>
      )}
    </button>
  );
};

export default Tab;
