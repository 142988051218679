import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import PromotableTooltip from 'components/PromotableTooltip';
import { SportIds } from 'constants/app';
import { betslipBranding as branding } from 'constants/branding';
import { BettingTypes } from 'constants/markets';
import { BetSides } from 'constants/myBets';
import { PRICE_MARKET_UNITS } from 'constants/placement';
import {
  BACK_ODDS,
  BACK_PROFIT,
  BACK_SELECTED_UNMATCHED,
  BACK_SIZE,
  LAY_ODDS,
  LAY_PROFIT,
  LAY_SELECTED_UNMATCHED,
  LAY_SIZE
} from 'constants/tooltip';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useBetslipLabels } from 'hooks/useLabels';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { setDutchingCalculatorData } from 'redux/modules/betslip';
import { getIsBetslipConfirmationStep, getIsOpenedBetSlipDutchingCalculator } from 'redux/modules/betslip/selectors';
import { getIsGameBetSlip } from 'redux/modules/betslip/selectors';
import { TSelectedMarketInfo } from 'redux/modules/betslip/type';
import { TLabels } from 'types/betslip';
import { BetSide } from 'types/myBets';

import DutchingCalculator from '../DutchingCalculator/DutchingCalculator';

import styles from './styles.module.scss';

type TSelectedMarketHeaderProps = {
  marketInfo: TSelectedMarketInfo;
  type: BetSide;
};
const SelectedBetsSubHeader = ({ marketInfo, type }: TSelectedMarketHeaderProps) => {
  const { sportId, bettingType, marketType, marketUnit, lineRangeInfo } = marketInfo;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const marketUnits = useMarketUnits(lineRangeInfo?.marketUnit || PRICE_MARKET_UNITS);
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isGameBetSlip = useSelector(getIsGameBetSlip);
  const isOpenedDutchingCalculator = useSelector(
    getIsOpenedBetSlipDutchingCalculator({ marketId: marketInfo.marketId, type })
  );
  const isConfirmationStep = useSelector(getIsBetslipConfirmationStep);

  const labels: TLabels = useBetslipLabels({ eventTypeId: sportId, bettingType, marketType, marketUnit });

  const isCricket = marketInfo?.sportId === SportIds.CRICKET;
  const isLineMarket = bettingType === BettingTypes.LINE;
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;

  const getTRKeyForOdds = () => {
    if (isLineMarket) {
      return type === BetSides.Lay ? LAY_SELECTED_UNMATCHED : BACK_SELECTED_UNMATCHED;
    } else {
      return type === BetSides.Lay ? LAY_ODDS : BACK_ODDS;
    }
  };

  const getSizeElement = () => {
    if (type === BetSides.Lay) {
      if (labels[type]?.stake) {
        return (
          <PromotableTooltip tooltipKey={LAY_SIZE} className={styles.selectedBetsSubHeader__size}>
            <span dangerouslySetInnerHTML={{ __html: labels[type]?.stake as string }} />
          </PromotableTooltip>
        );
      }

      return null;
    }

    if (
      (!isPNCEnabled || isGameBetSlip) &&
      marketInfo.bettingType === 'ODDS' &&
      marketInfo.marketType !== 'EACH_WAY' &&
      !isConfirmationStep
    ) {
      return (
        <div className={styles.dutching__wrapper}>
          <PromotableTooltip tooltipKey={BACK_SIZE} className={styles.selectedBetsSubHeader__size}>
            <button
              className={classNames(styles.dutching__btn, {
                [styles.dutching__btn__underline]: !isOpenedDutchingCalculator
              })}
              onClick={() =>
                dispatch(setDutchingCalculatorData({ marketId: marketInfo.marketId, type, data: { isOpened: true } }))
              }
            >
              {t('betslip.labels.size.back')}
            </button>
          </PromotableTooltip>
          {isOpenedDutchingCalculator && <DutchingCalculator type={type} marketId={marketInfo.marketId} />}
        </div>
      );
    }

    return (
      <PromotableTooltip tooltipKey={BACK_SIZE} className={styles.selectedBetsSubHeader__size}>
        <span>{labels[type]?.stake}</span>
      </PromotableTooltip>
    );
  };

  return (
    <div
      className={classNames(
        styles.selectedBetsSubHeader,
        styles[`selectedBetsSubHeader__${type.toLowerCase()}`],
        branding.BET_HEADER,
        branding[type],
        { [branding.FANCY_SWAP]: isFancySwapColors }
      )}
    >
      <div>{labels[type]?.bet}</div>
      <div>
        <PromotableTooltip
          tooltipKey={getTRKeyForOdds()}
          translationOptions={{ UNITS: marketUnits }}
          className={styles.selectedBetsSubHeader__odds}
        >
          {type === BetSides.Lay ? (
            <>{labels[type]?.odds && <span dangerouslySetInnerHTML={{ __html: labels[type]?.odds as string }} />}</>
          ) : (
            <span>{labels[type]?.odds}</span>
          )}
        </PromotableTooltip>
        {getSizeElement()}
        {type === BetSides.Lay &&
        (!isPNCEnabled || isGameBetSlip) &&
        marketInfo.bettingType === 'ODDS' &&
        marketInfo.marketType !== 'EACH_WAY' &&
        !isConfirmationStep ? (
          <div className={styles.dutching__wrapper}>
            <PromotableTooltip tooltipKey={LAY_PROFIT} className={styles.selectedBetsSubHeader__profit}>
              <button
                className={classNames(styles.dutching__btn, {
                  [styles.dutching__btn__underline]: !isOpenedDutchingCalculator
                })}
                onClick={() =>
                  dispatch(setDutchingCalculatorData({ marketId: marketInfo.marketId, type, data: { isOpened: true } }))
                }
              >
                {t('betslip.labels.profit.lay')}
              </button>
            </PromotableTooltip>
            {isOpenedDutchingCalculator && <DutchingCalculator type={type} marketId={marketInfo.marketId} />}
          </div>
        ) : (
          <PromotableTooltip
            tooltipKey={type === BetSides.Lay ? LAY_PROFIT : BACK_PROFIT}
            className={styles.selectedBetsSubHeader__profit}
          >
            <span>{labels[type]?.profit}</span>
          </PromotableTooltip>
        )}
      </div>
    </div>
  );
};

export default SelectedBetsSubHeader;
