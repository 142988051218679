import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_OUTRIGHT_MARKET_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLinks,
  AsianViewTimeFilters
} from 'constants/asianView';
import { ASIAN_BASE_URL, GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { BettingTypes } from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import { useMyBetsFilters } from 'hooks/useMyBetsFilters';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';
import { parseMillisecondsToTime } from 'utils/date';
import { roundedPriceLineBet } from 'utils/myBetsValues';

import BetInfoRow from '../BetInfoRow';

import styles from './styles.module.scss';

interface DescriptionCellProps {
  bet: TMyBetsContentItem;
  onClickEventName?: (value: boolean) => void;
  isRowHovered?: boolean;
  onCancelBet?: () => void;
  windowWidth: number;
  columnWidth?: number;
}

const DescriptionCell = ({
  bet,
  onClickEventName,
  isRowHovered,
  onCancelBet,
  windowWidth,
  columnWidth
}: DescriptionCellProps) => {
  const { t } = useTranslation();

  const { isCurrentPeriod: isCurrentStatus } = useMyBetsFilters();
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);

  const isGame = bet.betType === BettingTypes.GAME;
  const marketUrl = `/market/${bet!.marketId}`;
  const sportMarketUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}${marketUrl}`;
  const sportUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}/event/${bet.mainEventId || bet!.eventId}`;
  const competitionUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}/competition/${bet!.competitionId}`;
  const gameUrl = `${GAMES_BASE_URL}/${bet.eventTypeId}`;
  const asianViewEventUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilters.Today
  }/marketLink/${AsianViewMarketLinks.HDP_AND_OU}?${ASIAN_SINGLE_VIEW_EVENT_ID}=${
    bet.mainEventId || bet!.eventId
  }&${ASIAN_SINGLE_VIEW_SPORT_ID}=${bet.eventTypeId}`;
  const asianViewOutrightUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilters.Today
  }/marketLink/${AsianViewMarketLinks.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${bet!.competitionId}&page=0`;
  const asianViewOutrightMarketUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilters.Today
  }/marketLink/${AsianViewMarketLinks.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${
    bet!.competitionId
  }&${ASIAN_OUTRIGHT_MARKET_ID}=${bet!.marketId}&page=0`;
  const isUrlEnabled = isGame || !!bet.raceName || !!bet.mainEventId || !!bet.eventId;
  const isMarketUrlEnabled = !!bet.raceName || !!bet.mainEventId || !!bet.eventId;
  const navUrl = isGame ? gameUrl : bet.outright ? competitionUrl : sportUrl;
  const asianUrl = bet?.outright ? asianViewOutrightUrl : asianViewEventUrl;
  const navName = bet.betType === BettingTypes.GAME ? bet.sportName : bet.mainEventName || bet.eventName;
  const startTime = parseMillisecondsToTime(bet.marketStartDate || 0, timezone, timezoneCookieEnabled);
  const marketName = (
    <>
      {bet.raceName && (
        <>
          {startTime} {bet.raceName} &ndash;{' '}
        </>
      )}
      {bet.marketNameWithParents || bet.marketName}
    </>
  );
  const score = bet.marketType === MARKET_TYPES.asianHandicap && bet.score ? ` (${bet.score})` : '';

  const showGamePopup = (e: SyntheticEvent) => {
    e.preventDefault();

    if (onClickEventName) {
      onClickEventName(true);
    }
  };

  return (
    <div className={styles.wrap} data-type="description">
      <span className={styles.selectionName}>
        {bet.selectionName}
        {score}
        {bet.bettingType === BettingTypes.LINE && ` ${roundedPriceLineBet(bet)}`}
      </span>
      <span className={styles.eventName} style={{ maxWidth: columnWidth }}>
        {isCurrentStatus ? (
          <>
            {isUrlEnabled ? (
              <Link to={bet.asian ? asianUrl : navUrl} className={styles.link}>
                {navName}
              </Link>
            ) : (
              navName
            )}
            {((isMarketUrlEnabled && !bet.asian) || bet.outright) && !isGame ? (
              <>
                <> &ndash; </>
                <Link
                  to={bet.asian && bet.outright ? asianViewOutrightMarketUrl : sportMarketUrl}
                  className={styles.link}
                >
                  {marketName}
                </Link>
              </>
            ) : (
              <>{isGame ? '' : <> &ndash; {marketName}</>}</>
            )}
          </>
        ) : isGame ? (
          <a href="#" className={styles.gameLink} onClick={showGamePopup}>
            {bet.eventName}
          </a>
        ) : (
          <>
            {bet.mainEventName || bet.eventName}
            {isGame ? '' : <> &ndash; {marketName}</>}
          </>
        )}
      </span>
      {bet.marketType === MARKET_TYPES.eachWay && (
        <span className={styles.marketInfo}>
          {t('market.each.way.termsNoPref', { odds: bet.eachWayDivisor, places: bet.numberOfWinners })}
        </span>
      )}
      <BetInfoRow bet={bet} isRowHovered={isRowHovered} onCancelBet={onCancelBet} windowWidth={windowWidth} />
    </div>
  );
};

export default DescriptionCell;
