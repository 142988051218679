import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { CookieNames } from 'constants/app';
import { ExchangeTypes } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import { getAccountProductsByBetsType } from 'redux/modules/myBets/selectors';
import { BetsType, TStatementContentItem } from 'redux/modules/myBets/type';

import AccountStatementTableDesktopContent from './components/AccountStatementTableDesktopContent/AccountStatementTableDesktopContent';
import AccountStatementTableMobileContent from './components/AccountStatementTableMobileContent/AccountStatementTableMobileContent';

interface StatementTableProps {
  content: TStatementContentItem[];
}
const StatementTable = ({ content }: StatementTableProps) => {
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE_NEW]);

  const accountProducts = useSelector(getAccountProductsByBetsType(BetsType.ALL_ACCOUNT_STATEMENT));

  const { isDesktop } = useDevice();

  const isAsianViewTable = !!accountProducts?.asianView && cookies.EXCHANGE_TYPE_NEW === ExchangeTypes.AsianView;

  if (isDesktop) {
    return <AccountStatementTableDesktopContent isAsianViewTable={isAsianViewTable} content={content} />;
  }

  return <AccountStatementTableMobileContent isAsianViewTable={isAsianViewTable} content={content} />;
};

export default StatementTable;
