import { SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { BannerNames, BASKETBALL_NORMAL_MATCH, CookieNames } from 'constants/app';
import { BannersContentOptions, BannerSections } from 'constants/banners';
import { getIsIframeEnabled, getOperator } from 'redux/modules/appConfigs/selectors';
import { getBannerByName } from 'redux/modules/banners/selectors';
import { getBooleanValue } from 'utils';
import { getArrowDownByOperator } from 'utils/operator';

import BannerComponent from './BannerComponent';

import styles from './styles.module.scss';

const PromotionBanners = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.BIAB_MULTIPLE_BANNERS_SHOW_ALL]);

  const promotion = useSelector(getBannerByName(BannerNames.promotion));
  const operator = useSelector(getOperator);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const [showMore, isShowMore] = useState(false);

  useEffect(() => {
    if (cookies[CookieNames.BIAB_MULTIPLE_BANNERS_SHOW_ALL]) {
      isShowMore(getBooleanValue(cookies[CookieNames.BIAB_MULTIPLE_BANNERS_SHOW_ALL]));
    }
  }, []);

  const handleClickShowMore = (e: SyntheticEvent) => {
    e.preventDefault();
    isShowMore(!showMore);
    setCookie(CookieNames.BIAB_MULTIPLE_BANNERS_SHOW_ALL, !showMore);
  };
  const showPromotionBanners =
    (promotion?.activeContentOption === BannersContentOptions.HTML && promotion?.bannerHtmlContent.content) ||
    (promotion?.activeContentOption === BannersContentOptions.IMAGES && promotion?.bannerImages?.length);

  const displayShowMore =
    promotion?.activeContentOption === BannersContentOptions.IMAGES &&
    promotion?.bannerImages?.length &&
    promotion.bannerImages.length > BASKETBALL_NORMAL_MATCH;

  return (
    <>
      {showPromotionBanners ? (
        <div className={styles.promotionBannersWrapper}>
          <div className={classNames('biab_multiple-banners-title', styles.promotionBannersHeader)}>
            {t('multipleBanners.labels.title')}
          </div>
          <div>
            <ul className={classNames({ [styles.opened]: showMore })}>
              {promotion?.activeContentOption === BannersContentOptions.IMAGES &&
                promotion?.bannerImages?.length &&
                promotion?.bannerImages?.length > 0 &&
                promotion.bannerImages.map(banner => (
                  <BannerComponent
                    key={banner.id}
                    banner={banner}
                    isIframeEnabled={isIframeEnabled}
                    type={BannerSections.PROMOTION_BANNER}
                  />
                ))}
              {promotion?.activeContentOption === BannersContentOptions.HTML && (
                <div dangerouslySetInnerHTML={{ __html: unescape(promotion?.bannerHtmlContent.content) }} />
              )}
            </ul>
          </div>
          {displayShowMore && (
            <div className={styles.bannersShowMore}>
              <a
                onClick={handleClickShowMore}
                href="#"
                className={classNames('js-toggle biab_toggle', styles.showMoreBtn, {
                  'show-less': showMore,
                  'show-more': !showMore
                })}
              >
                {t(`multipleBanners.labels.${showMore ? 'showLess' : 'showMore'}`)}
                <i
                  className={classNames(
                    'fa biab_expand',
                    getArrowDownByOperator(operator),
                    `${showMore ? [styles.rotate] : ''}`
                  )}
                />
              </a>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default PromotionBanners;
