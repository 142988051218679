import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { GameActionsTypes, GamePages } from 'constants/games';
import { TFailureActionPayload } from 'types';

import { GamePage, TCurrentGameData, TFetchResultHistory, TGame, TGameRules, TGames, THistory } from './type';

const initialState: TGames = {
  gameList: [],
  currentGameData: null,
  selectedGamePage: GamePages.GAME,
  currentGameDataLoading: false,
  loading: false,
  gamesFetchFinished: false,
  error: null,
  gameRules: null,
  gameRulesLoading: false,
  resultsHistory: null,
  resultsHistoryLoading: false,
  selectedResultIndex: 0,
  selectedMarket: 0,
  stringifiedCurrentGameData: ''
};

const slice = createSlice({
  name: SLICES_NAMES.GAMES,
  initialState,
  reducers: {
    fetchGames: state => {
      state.loading = true;
      state.gamesFetchFinished = false;
    },
    successGetGames: (state, { payload }: PayloadAction<TGame[]>) => {
      state.loading = false;
      state.gamesFetchFinished = true;
      state.gameList = payload;
    },
    failureGetGames: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.gamesFetchFinished = true;
      state.error = payload;
    },
    fetchCurrentGameData: (state, _: PayloadAction<string>) => {
      state.currentGameDataLoading = true;
    },
    successFetchCurrentGameData: (state, { payload }: PayloadAction<TCurrentGameData>) => {
      state.currentGameDataLoading = false;

      const stingifiedCurrentGameFromResponse = JSON.stringify(payload);

      if (state.stringifiedCurrentGameData !== stingifiedCurrentGameFromResponse) {
        state.currentGameData = payload;
        state.stringifiedCurrentGameData = stingifiedCurrentGameFromResponse;
      }
    },
    failureFetchCurrentGameData: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.currentGameDataLoading = false;
      state.error = payload;
    },
    setSelectedGamePage: (state, { payload }: PayloadAction<GamePage>) => {
      state.selectedGamePage = payload;
    },
    resetGameData: state => {
      state.currentGameData = initialState.currentGameData;
      state.currentGameDataLoading = initialState.currentGameDataLoading;
      state.selectedGamePage = initialState.selectedGamePage;
      state.gameRules = initialState.gameRules;
      state.stringifiedCurrentGameData = initialState.stringifiedCurrentGameData;
    },
    fetchGameRules: (state, _: PayloadAction<string>) => {
      state.gameRulesLoading = true;
    },
    successFetchGameRules: (state, { payload }: PayloadAction<TGameRules>) => {
      state.gameRulesLoading = false;
      state.gameRules = payload;
    },
    failureFetchGameRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.gameRulesLoading = false;
      state.error = payload;
    },
    fetchResultsHistory: (state, _: PayloadAction<TFetchResultHistory>) => {
      state.resultsHistoryLoading = true;
    },
    successFetchResultHistory: (state, { payload }: PayloadAction<THistory>) => {
      const { numberOfElements, number, actionType } = payload;
      const currentResultHistory = state.resultsHistory;

      const getSelectedResultIndex = () => {
        switch (actionType) {
          case GameActionsTypes.FIRST:
            return numberOfElements - 1;
          case GameActionsTypes.LAST:
            return 0;
          default:
            if (currentResultHistory && number < currentResultHistory.number) {
              return numberOfElements - 1;
            }
            return 0;
        }
      };

      state.resultsHistoryLoading = false;
      state.selectedResultIndex = getSelectedResultIndex();
      state.resultsHistory = payload;
    },
    failureFetchResultHistory: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.resultsHistoryLoading = false;
      state.error = payload;
    },
    setSelectedResultIndex: (state, { payload }: PayloadAction<number>) => {
      state.selectedResultIndex = payload;
    },
    setSelectedMarket: (state, { payload }: PayloadAction<number>) => {
      state.selectedMarket = payload;
    }
  }
});

export const {
  failureFetchResultHistory,
  failureFetchGameRules,
  fetchResultsHistory,
  successFetchResultHistory,
  failureFetchCurrentGameData,
  fetchGameRules,
  fetchGames,
  successFetchCurrentGameData,
  successFetchGameRules,
  fetchCurrentGameData,
  resetGameData,
  setSelectedGamePage,
  setSelectedMarket,
  successGetGames,
  setSelectedResultIndex,
  failureGetGames
} = slice.actions;

export default slice.reducer;
