import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import MarketSport from 'components/MarketSport';
import PopularMarket from 'components/PopularMarket';
import SEO from 'components/SEO';
import MiddleSectionPageSkeleton from 'components/Skeleton/MiddleSectionPageSkeleton';
import { Devices, MetaPages, PageBlocks, PlacementPages } from 'constants/app';
import { InlinePlacementActions } from 'constants/inlinePlacement';
import { dontRaceRequest } from 'constants/marketTypes';
import { useEnabledView } from 'hooks/useEnabledView';
import CashOutQuotesAndAutoCashOutsIntervalsInjection from 'injections/CashOutQuotesAndAutoCashOutsIntervalsInjection';
import MobileTodayCardPage, { RACE_SEARCH_PARAM } from 'pages/mobile/MobileTodayCardPage';
import MultiMarketView from 'pages/MultiMarketView';
import { getAppDevice, getCurrencyCode, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getSelectedBetsAmountByMarket } from 'redux/modules/betslip/selectors';
import { resetCashOut } from 'redux/modules/cashOut';
import {
  getCompetitionsLoader,
  getEventDataLoading,
  getTodayCardPageLoading
} from 'redux/modules/competitions/selectors';
import { getCurrentBetByOfferId } from 'redux/modules/currentBets/selectors';
import { cleanEvent, fetchEventDetails } from 'redux/modules/event';
import { getEventMarkets } from 'redux/modules/event/selectors';
import { removeInlineSelectedBet, setInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { getInlineSelectedBetByMarket } from 'redux/modules/inlinePlacement/selectors';
import { resetListViewMarkets, successFetchListViewMarkets } from 'redux/modules/listViewTabs';
import { cleanMarket, fetchMarket, setMarketShowAll } from 'redux/modules/market';
import { getSingleMarketInfo, getSingleMarketLoading } from 'redux/modules/market/selectors';
import { resetMarketsPricesByMarket } from 'redux/modules/marketsPrices';
import { fetchMultiMarketTopDetails, setMultiMarketShowAll } from 'redux/modules/multiMarket';
import {
  getMultiMarketLoading,
  getMultiMarketRulesLoading,
  getMultiMarketTop
} from 'redux/modules/multiMarket/selectors';
import { getLoadingPopularMarkets } from 'redux/modules/popularMarket/selectors';
import { getTop5ViewLoader } from 'redux/modules/top5View/selectors';
import { getCleanMarketId } from 'utils';

const MarketPage = () => {
  const dispatch = useDispatch();
  const { marketId, sportId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const market = useSelector(getSingleMarketInfo);
  const marketCatalogues = useSelector(getEventMarkets);
  const timezone = useSelector(getTimezone);
  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const currency = useSelector(getCurrencyCode);
  const topMultiMarket = useSelector(getMultiMarketTop);
  const loggedIn = useSelector(getLoggedInStatusState);
  const marketInfo = useSelector(getSingleMarketInfo);
  const rulesLoading = useSelector(getMultiMarketRulesLoading);
  const loading = useSelector(getMultiMarketLoading);
  const popularMarketsIsLoading = useSelector(getLoadingPopularMarkets);
  const singleMarketLoading = useSelector(getSingleMarketLoading);
  const isTop5ViewLoader = useSelector(getTop5ViewLoader);
  const isEventDataLoader = useSelector(getEventDataLoading);
  const isCompetitionsLoader = useSelector(getCompetitionsLoader);
  const todayCardPageLoading = useSelector(getTodayCardPageLoading);
  const selectedBetsAmountByMarket = useSelector(getSelectedBetsAmountByMarket(marketId ?? ''));

  const [isLoadedRaces, setIsLoadedRaces] = useState(false);

  const { isMultiMarketView, isFancyView } = useEnabledView();

  const isMultiMarketLoading = !marketInfo && loading;
  const isDesktop = device === Devices.DESKTOP;
  const isMultiMarket = isMultiMarketView && topMultiMarket?.topMarketId;
  const hasMarketView = market && ((isMultiMarketView && isMultiMarket === null) || !isMultiMarketView);
  const isTodayCardMobileView = sportId?.startsWith('tc') && !isDesktop;
  const selectedRaceId = searchParams.get(RACE_SEARCH_PARAM);
  const isLoading =
    isMultiMarketLoading ||
    popularMarketsIsLoading ||
    singleMarketLoading ||
    rulesLoading ||
    isTop5ViewLoader ||
    isEventDataLoader ||
    isCompetitionsLoader ||
    todayCardPageLoading;
  const isShowSkeleton = isTodayCardMobileView ? !isLoadedRaces : isLoading;
  const isEditActionParam = searchParams.get('action') === 'edit';
  const offerIdFromParams = Number(searchParams.get('offerId'));

  const bet = useSelector(getCurrentBetByOfferId(Number(offerIdFromParams)));
  const inlineSelectedBet = useSelector(getInlineSelectedBetByMarket(PageBlocks.MARKET_ODDS, bet?.marketId ?? ''));

  const isCurrentBet = !!bet;

  useEffect(() => {
    if (isEditActionParam && offerIdFromParams && bet && offerIdFromParams === bet.offerId && !isMultiMarketLoading) {
      if (isDesktop) {
        if (inlineSelectedBet) {
          dispatch(removeInlineSelectedBet(inlineSelectedBet));
        }
      } else {
        dispatch(
          setInlineSelectedBet({
            selectedBet: {
              marketId: bet.marketId,
              eventId: bet.eventId,
              sportId: bet.eventTypeId.toString(),
              selectionId: bet.selectionId || 0,
              handicap: +(bet.handicap || 0),
              marketType: bet.marketType,
              bettingType: bet.bettingType,
              numberOfWinners: bet.numberOfWinners || 0,
              eventName: bet.eventName,
              marketName: bet.marketName,
              selectionName: bet.selectionName,
              marketUnit: bet.marketUnit ?? '',
              lineSide: bet.lineSide ?? null,
              marketStartTime: bet.marketStartDate,
              eachWayDivisor: bet.eachWayDivisor,
              priceLadderDescription: bet.priceLadderDescription,
              fancyView: bet.fancyView,
              raceName: bet.raceName ?? '',
              type: bet.side,
              size: bet.sizeRemaining,
              price: bet.price,
              pageBlock: isMultiMarketView ? PageBlocks.MULTI_MARKET_VIEW : PageBlocks.MARKET_ODDS,
              currentOfferId: bet.offerId,
              currency,
              offers: { [bet.offerId]: bet },
              page: PlacementPages.MARKET,
              action: InlinePlacementActions.EDIT,
              disabledLayOdds: bet.disabledLayOdds,
              notHighlightBetContentCell: true
            }
          })
        );
      }
    }
  }, [isEditActionParam, offerIdFromParams, isDesktop, isCurrentBet, isMultiMarketLoading, isMultiMarketView]);

  useEffect(() => {
    if (marketId) {
      dispatch(fetchMarket({ marketId: getCleanMarketId(marketId) }));
    }
  }, [marketId, timezone, currency, dispatch, language, loggedIn]);

  useEffect(() => {
    return () => {
      dispatch(cleanMarket());
      dispatch(cleanEvent());
      dispatch(resetListViewMarkets());

      if (!selectedBetsAmountByMarket && marketId) {
        dispatch(resetMarketsPricesByMarket(marketId));
      }
    };
  }, [marketId]);

  useEffect(() => {
    dispatch(setMarketShowAll(false));
    dispatch(setMultiMarketShowAll(false));
  }, [dispatch, marketId]);

  useEffect(() => {
    if (topMultiMarket === null && market?.event?.id && isMultiMarketView) {
      dispatch(fetchMultiMarketTopDetails(market.event.id));
    }
  }, [market, isMultiMarketView, topMultiMarket, dispatch]);

  useEffect(() => {
    if (topMultiMarket && market?.event?.id && isMultiMarketView) {
      dispatch(fetchMultiMarketTopDetails(market.event.id));
    }
  }, [market]);

  useEffect(() => {
    if (
      !market?.closedDate &&
      market?.event?.id &&
      !dontRaceRequest.includes(market?.description?.marketType as typeof dontRaceRequest[number]) &&
      !isTodayCardMobileView
    ) {
      dispatch(fetchEventDetails(market.event.id));
    }
  }, [dispatch, market?.event?.id, isTodayCardMobileView]);

  useEffect(() => {
    if (marketCatalogues.length) {
      dispatch(successFetchListViewMarkets(marketCatalogues));
    }
  }, [marketCatalogues]);

  useEffect(() => {
    if (isDesktop && selectedRaceId) {
      setSearchParams({}, { replace: true });
    }
  }, [isDesktop]);

  useEffect(() => {
    return () => {
      dispatch(resetCashOut());
    };
  }, [marketId]);

  return (
    <div className="skeleton_page_wrapper">
      <SEO page={MetaPages.MARKET} id={marketId} />
      {market?.marketId && (
        <CashOutQuotesAndAutoCashOutsIntervalsInjection
          marketId={market.marketId}
          cashOutEnabled={market.cashOutEnabled}
        />
      )}
      {isShowSkeleton && <MiddleSectionPageSkeleton itemsCount={12} withDelay={isTodayCardMobileView ? 0 : 150} />}
      {isDesktop && (
        <PopularMarket
          page={
            market && isMultiMarketView && isMultiMarket
              ? PlacementPages.MULTI_MARKET_ODDS_BANNER
              : PlacementPages.MARKET_ODDS_BANNER
          }
        />
      )}
      {market && isMultiMarketView && isMultiMarket && <MultiMarketView isFancyView={isFancyView} />}
      {isTodayCardMobileView && (
        <MobileTodayCardPage setIsLoadedRaces={setIsLoadedRaces} isLoadedRaces={isLoadedRaces} />
      )}
      {hasMarketView && !isTodayCardMobileView && <MarketSport market={market} />}
    </div>
  );
};

export default MarketPage;
