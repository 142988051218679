import classNames from 'classnames';

import { componentsBranding, mobileComponents } from 'constants/branding';
import { ResponsibleBettingButtonTypes } from 'constants/responsibleBetting';
import { ResponsibleBettingButtonTypeKeys } from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingBtnProps {
  label: string;
  onClick: () => void;
  type?: ResponsibleBettingButtonTypeKeys;
  isMobile: boolean;
  customClass?: string;
  disabled?: boolean;
}

const ResponsibleBettingBtn = ({
  label,
  onClick,
  type = ResponsibleBettingButtonTypes.Primary,
  isMobile,
  customClass = '',
  disabled = false
}: ResponsibleBettingBtnProps) => {
  const isPrimary = type === ResponsibleBettingButtonTypes.Primary || type === ResponsibleBettingButtonTypes.Applied;
  const isSecondary = type === ResponsibleBettingButtonTypes.Secondary;
  const isTertiary = type === ResponsibleBettingButtonTypes.Outlined;
  const isConfirmed = type === ResponsibleBettingButtonTypes.Applied || type === ResponsibleBettingButtonTypes.Outlined;

  return (
    <button
      className={classNames(styles.btn, customClass, {
        [componentsBranding.DISABLED]: disabled,
        [componentsBranding.PRIMARY_BTN]: !isMobile && isPrimary,
        [componentsBranding.SECONDARY_BTN]: !isMobile && isSecondary,
        [componentsBranding.TERTIARY_BTN]: !isMobile && isTertiary,
        [componentsBranding.CONFIRM_BTN]: !isMobile && isConfirmed,
        [mobileComponents.BUTTON]: isMobile,
        [mobileComponents.PRIMARY]: isMobile && isPrimary,
        [mobileComponents.SECONDARY]: isMobile && isSecondary,
        [mobileComponents.TERTIARY]: isMobile && isTertiary,
        [mobileComponents.FOCUS]: isMobile && isConfirmed,
        [styles.btn__primary]: type === ResponsibleBettingButtonTypes.Primary,
        [styles.btn__secondary]: type === ResponsibleBettingButtonTypes.Secondary,
        [styles.btn__applied]: type === ResponsibleBettingButtonTypes.Applied,
        [styles.btn__outlined]: type === ResponsibleBettingButtonTypes.Outlined,
        [styles.btn__mobile]: isMobile
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default ResponsibleBettingBtn;
