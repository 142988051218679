import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { difference, entries, isUndefined } from 'lodash';

import { CashOutStatuses, SLICES_NAMES } from 'constants/app';
import {
  AutoCashOut,
  CashOutMarket,
  CashOutQuote,
  ProfitPayload,
  ProfitSelection,
  TSetCashOut
} from 'redux/modules/cashOut/type';
import { CashOutStatus, TFailureActionPayload } from 'types';
import { TFetchMarketRulesResponse } from 'types/markets';
import { getContentCashOutPage } from 'utils/cashOut';

import {
  AsianViewCashOutState,
  FetchCashOutMarketsError,
  FetchCashOutMarketsPayload,
  FetchCashOutMarketsResponse,
  FetchCashOutQuotesPayload,
  FetchCashOutQuotesResponse,
  FetchCashOutStatusResponse
} from './type';

const initialState: AsianViewCashOutState = {
  quotesLoading: false,
  statusLoading: {},
  isFirstQuotesLoaded: false,
  isFirstMarketsLoaded: false,
  quotesError: null,
  marketsError: null,
  marketsData: {
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    sort: null,
    totalElements: 0,
    totalPages: 0,
    content: []
  },
  quotes: {},
  stringifiedQuotes: '',
  whatIf: {},
  whatIfMarketId: null,
  possibleProfits: {},
  cashedValues: {},
  autoCashOutMarkets: {},
  stringifiedAutoCashOutMarkets: '',
  placedIds: {},
  successFullCreatedAutoCashOuts: {},
  successFullDeletedAutoCashOuts: {},
  pendingCashOuts: {},
  cashOutStatuses: {},
  paginationLoading: false,
  settingTabsStates: {},
  rules: {},
  rulesLoading: false,
  rulesError: null,
  cashOutLoading: false,
  autoCashOutLoading: false,
  marketsLoading: false,
  autoCashOutMarketsError: null,
  cashOutMarketLoading: false,
  cashOutMarketError: null,
  cashOutStatusLoading: false,
  cashOutStatusError: null,
  createAutoCashOutError: null,
  deleteAutoCashOutError: null
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW_BET_SLIP_CASH_OUT,
  initialState,
  reducers: {
    fetchAsianViewCashOutQuotes: (state, { payload }: PayloadAction<FetchCashOutQuotesPayload>) => {
      state.quotesLoading = true;

      if (payload.firstLoading) {
        state.isFirstQuotesLoaded = false;
      }
    },
    successFetchAsianViewCashOutQuotes: (state, { payload }: PayloadAction<FetchCashOutQuotesResponse>) => {
      state.quotesLoading = false;

      if (
        !state.stringifiedQuotes ||
        payload.isWebSocketResponse ||
        state.stringifiedQuotes !== payload.stringifiedQuotes
      ) {
        if (!payload.isWebSocketResponse && payload.stringifiedQuotes) {
          state.stringifiedQuotes = payload.stringifiedQuotes;
        }

        const statusMarketIds = Object.keys(state.cashOutStatuses);
        const newQuotes = payload.quotes.reduce<Record<string, CashOutQuote>>(
          (acc, item) => ({
            ...acc,
            [item.marketId]: item
          }),
          {}
        );
        const oldQuoteMarketIds = difference(statusMarketIds, Object.keys(newQuotes));
        const oldQuotes = oldQuoteMarketIds.reduce<Record<string, CashOutQuote>>(
          (acc: Record<string, CashOutQuote>, marketId: string) =>
            state.quotes[marketId]
              ? {
                  ...acc,
                  [marketId]: state.quotes[marketId]
                }
              : acc,
          {}
        );

        state.quotes = { ...oldQuotes, ...newQuotes };
      }

      if (payload.firstLoaded || !state.isFirstQuotesLoaded) {
        state.isFirstQuotesLoaded = true;
      }
    },
    setQuotesLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.quotesLoading = payload;
    },
    failureFetchAsianViewCashOutQuotes: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.quotesLoading = false;
      state.quotesError = payload;

      if (!state.isFirstQuotesLoaded) {
        state.isFirstQuotesLoaded = true;
      }
    },
    fetchAsianViewCashOutMarkets: (state, { payload }: PayloadAction<FetchCashOutMarketsPayload>) => {
      if (payload.resetPrev) {
        state.marketsData = initialState.marketsData;
        state.isFirstMarketsLoaded = false;
      }

      if (payload.withLoader) {
        state.paginationLoading = true;
      }

      if (payload.resetSettingsTabs) {
        state.settingTabsStates = initialState.settingTabsStates;
      }

      state.marketsLoading = true;
    },
    successFetchAsianViewCashOutMarkets: (state, { payload }: PayloadAction<FetchCashOutMarketsResponse>) => {
      const isMarketsNotUpdated = JSON.stringify(current(state.marketsData)) === JSON.stringify(payload.markets);

      state.marketsLoading = false;

      if (!state.isFirstMarketsLoaded) {
        state.isFirstMarketsLoaded = true;
      }

      if (payload.withLoader) {
        state.paginationLoading = false;
      }

      if (!isMarketsNotUpdated) {
        const content = getContentCashOutPage(state.marketsData, payload);

        state.marketsData = {
          ...payload.markets,
          content
        };
      }
    },
    failureFetchAsianViewCashOutMarkets: (state, { payload }: PayloadAction<FetchCashOutMarketsError>) => {
      state.marketsError = payload.error;
      state.marketsLoading = false;

      if (payload.withLoader) {
        state.paginationLoading = false;
      }
    },
    hoverAsianViewCashOutBtn: (
      state,
      { payload }: PayloadAction<{ marketId: string; selections: ProfitSelection[] }>
    ) => {
      state.whatIf = payload.selections.reduce(
        (acc: Record<string, ProfitSelection>, item) => ({
          ...acc,
          [item.selectionId]: item
        }),
        {}
      );
      state.whatIfMarketId = payload.marketId;
    },
    leaveAsianViewCashOutBtn: state => {
      state.whatIf = {};
      state.whatIfMarketId = null;
    },
    setAsianViewCashOutPossibleProfit: (state, { payload }: PayloadAction<ProfitPayload>) => {
      if (!state.possibleProfits[payload.marketId]) {
        state.possibleProfits[payload.marketId] = {} as ProfitPayload;
      }

      state.possibleProfits[payload.marketId].marketId = payload.marketId;

      if (!isUndefined(payload.value)) {
        state.possibleProfits[payload.marketId].value = payload.value;
      }

      if (!isUndefined(payload.error)) {
        state.possibleProfits[payload.marketId].error = payload.error;
      }

      if (!isUndefined(payload.hideError)) {
        state.possibleProfits[payload.marketId].hideError = payload.hideError;
      }
    },
    asianViewCreateAutoCashOut: (_, __: PayloadAction<{ marketId: string; profit: number }>) => {},
    successAsianViewCreateAutoCashOut: (state, { payload }: PayloadAction<AutoCashOut>) => {
      state.successFullCreatedAutoCashOuts[payload.marketId] = true;
    },
    failureAsianViewCreateAutoCashOut: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.createAutoCashOutError = payload;
    },
    fetchAsianViewAutoCashOutMarkets: (_, __: PayloadAction<string[]>) => {},
    successFetchAsianViewAutoCashOutMarkets: (
      state,
      {
        payload
      }: PayloadAction<{ autoCashOuts: AutoCashOut[]; isWebSocketResponse: boolean; stringifiedAutoCashOuts?: string }>
    ) => {
      state.autoCashOutMarkets = payload.autoCashOuts.reduce(
        (acc, autoCashOut) => ({
          ...acc,
          [autoCashOut.marketId]: autoCashOut
        }),
        {}
      );

      if (!payload.isWebSocketResponse && payload.stringifiedAutoCashOuts) {
        state.stringifiedAutoCashOutMarkets = payload.stringifiedAutoCashOuts;
      }
    },
    failureFetchAsianViewAutoCashOutMarkets: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.autoCashOutMarketsError = payload;
    },
    deleteAsianViewAutoCashOut: (_, __: PayloadAction<{ marketId: string; id: number }>) => {},
    successDeleteAsianViewAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.possibleProfits[payload];
      state.successFullDeletedAutoCashOuts[payload] = true;
    },
    failureDeleteAsianViewAutoCashOut: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.deleteAutoCashOutError = payload;
    },
    asianViewAutoCashOutAutoDelete: (state, { payload }: PayloadAction<string>) => {
      delete state.possibleProfits[payload];
    },
    asianViewRemoveSuccessFullDeletedAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.successFullDeletedAutoCashOuts[payload];
      delete state.autoCashOutMarkets[payload];
    },
    asianViewRemoveSuccessFullCreatedAutoCashOut: (state, { payload }: PayloadAction<string>) => {
      delete state.successFullCreatedAutoCashOuts[payload];

      entries(state.autoCashOutMarkets).forEach(([marketId, value]) => {
        if (marketId === payload && value) {
          state.cashedValues[marketId] = value.profit;
        } else {
          delete state.cashedValues[marketId];
        }
      });
    },
    fetchAsianViewCashOutMarket: (state, _: PayloadAction<TSetCashOut>) => {
      state.whatIf = {};
      state.whatIfMarketId = null;
      state.cashOutMarketLoading = true;
    },
    successFetchAsianViewCashOutMarket: (state, { payload }: PayloadAction<CashOutMarket>) => {
      state.cashOutMarketLoading = false;
      state.placedIds[payload.marketId] = payload.offers[0];
      state.pendingCashOuts[payload.marketId] = payload.id;
      state.cashOutStatuses[payload.marketId] = CashOutStatuses.SUCCESS;
    },
    asianViewCashOutRemovePendingCashOut: (
      state,
      {
        payload
      }: PayloadAction<{
        status: string;
        marketId: string;
      }>
    ) => {
      delete state.pendingCashOuts[payload.marketId];
      state.cashOutStatuses[payload.marketId] = payload.status;
    },
    failureFetchAsianViewCashOutMarket: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.cashOutMarketLoading = false;
      state.cashOutMarketError = payload;
    },
    asianViewCashOutCleanPlacedId: (state, { payload }: PayloadAction<string>) => {
      delete state.placedIds[payload];
    },
    fetchAsianViewCashOutStatus: (
      state,
      { payload }: PayloadAction<{ statusId: number; onSuccessStatus?: () => void }>
    ) => {
      state.cashOutStatusLoading = true;
      state.statusLoading[payload.statusId] = true;
    },
    asianViewCashOutCleanStatus: (state, { payload }: PayloadAction<string>) => {
      delete state.cashOutStatuses[payload];
    },
    successFetchAsianViewCashOutStatus: (state, { payload }: PayloadAction<FetchCashOutStatusResponse>) => {
      state.cashOutStatusLoading = false;
      state.statusLoading[payload.statusId] = false;

      if (payload.status !== CashOutStatuses.PENDING) {
        state.cashOutStatuses[payload.marketId] = payload.status;
        delete state.pendingCashOuts[payload.marketId];
      }
    },
    setAsianViewCashOutStatus: (state, { payload }: PayloadAction<{ marketId: string; status: CashOutStatus }>) => {
      state.cashOutStatuses[payload.marketId] = payload.status;
    },
    failureFetchAsianViewCashOutStatus: (
      state,
      { payload }: PayloadAction<{ error: TFailureActionPayload; statusId: number }>
    ) => {
      state.statusLoading[payload.statusId] = false;
      state.cashOutStatusLoading = false;
      state.cashOutStatusError = payload.error;
    },
    asianViewCashOutOpenSettingsTab: (state, { payload }: PayloadAction<string>) => {
      state.settingTabsStates[payload] = true;
      entries(state.autoCashOutMarkets).forEach(([marketId, value]) => {
        if (marketId === payload && value) {
          state.cashedValues[marketId] = value.profit;
        } else {
          delete state.cashedValues[marketId];
        }
      });
    },
    asianViewCashOutCloseSettingsTab: (state, { payload }: PayloadAction<string>) => {
      delete state.settingTabsStates[payload];
    },
    fetchAsianViewCashOutMarketRules: (state, _: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchAsianViewCashOutMarketRules: (state, { payload }: PayloadAction<TFetchMarketRulesResponse>) => {
      state.rulesLoading = false;
      state.rules[payload.marketId] = payload.rules;
    },
    failureFetchAsianViewCashOutMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    },
    removeAsianViewAutoCashOutMarket: (state, { payload }: PayloadAction<string>) => {
      delete state.autoCashOutMarkets[payload];
    },
    resetAsianViewCashOut: () => initialState,
    removeAsianViewCashOutQuoteByMarketId: (state, { payload }: PayloadAction<string>) => {
      delete state.quotes[payload];
    }
  }
});

export const {
  fetchAsianViewCashOutQuotes,
  asianViewCashOutCleanPlacedId,
  asianViewCashOutCloseSettingsTab,
  asianViewAutoCashOutAutoDelete,
  asianViewCashOutRemovePendingCashOut,
  asianViewCashOutOpenSettingsTab,
  removeAsianViewAutoCashOutMarket,
  asianViewRemoveSuccessFullDeletedAutoCashOut,
  asianViewRemoveSuccessFullCreatedAutoCashOut,
  failureFetchAsianViewAutoCashOutMarkets,
  failureFetchAsianViewCashOutMarket,
  deleteAsianViewAutoCashOut,
  failureFetchAsianViewCashOutMarketRules,
  asianViewCashOutCleanStatus,
  failureFetchAsianViewCashOutStatus,
  fetchAsianViewAutoCashOutMarkets,
  fetchAsianViewCashOutMarket,
  fetchAsianViewCashOutMarkets,
  fetchAsianViewCashOutMarketRules,
  hoverAsianViewCashOutBtn,
  setAsianViewCashOutPossibleProfit,
  fetchAsianViewCashOutStatus,
  leaveAsianViewCashOutBtn,
  resetAsianViewCashOut,
  successAsianViewCreateAutoCashOut,
  successDeleteAsianViewAutoCashOut,
  successFetchAsianViewAutoCashOutMarkets,
  successFetchAsianViewCashOutMarket,
  successFetchAsianViewCashOutMarketRules,
  successFetchAsianViewCashOutMarkets,
  successFetchAsianViewCashOutQuotes,
  successFetchAsianViewCashOutStatus,
  failureFetchAsianViewCashOutQuotes,
  failureFetchAsianViewCashOutMarkets,
  asianViewCreateAutoCashOut,
  failureDeleteAsianViewAutoCashOut,
  failureAsianViewCreateAutoCashOut,
  setQuotesLoading,
  setAsianViewCashOutStatus,
  removeAsianViewCashOutQuoteByMarketId
} = slice.actions;

export default slice.reducer;
