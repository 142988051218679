import { SyntheticEvent } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Icon from 'components/Icon';
import { CookieNames } from 'constants/app';
import { betslipBranding as branding } from 'constants/branding';
import { getIsCollapsibleRightSide } from 'redux/modules/appConfigs/selectors';
import { setCollapseState } from 'redux/modules/betslip';
import { getBetslipCollapse } from 'redux/modules/betslip/selectors';
import { closeAllCurrentBetsCanBeRemoved, removeAllUnmatchedOffersIdsToShowLapsed } from 'redux/modules/currentBets';
import { getIsAtLeastOneCanBeRemovedCurrentBet } from 'redux/modules/currentBets/selectors';

import styles from './styles.module.scss';

const BetslipHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [, setCookie] = useCookies([CookieNames.COLLAPSE_SIDEBAR]);

  const collapse = useSelector(getBetslipCollapse);
  const hasCollapseSidebar = useSelector(getIsCollapsibleRightSide);
  const isAtLeastOneCanBeRemovedBet = useSelector(getIsAtLeastOneCanBeRemovedCurrentBet);

  const onToggleIconClick = (event: SyntheticEvent) => {
    event.preventDefault();

    dispatch(setCollapseState(!collapse));

    if (isAtLeastOneCanBeRemovedBet && !collapse) {
      dispatch(closeAllCurrentBetsCanBeRemoved());
      dispatch(removeAllUnmatchedOffersIdsToShowLapsed());
    }
  };

  const onToggleSidebar = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setCookie(CookieNames.COLLAPSE_SIDEBAR, true, { path: '/' });
  };

  return (
    <div className={classNames(styles.betslipHeader, branding.HEADER)} onClick={onToggleIconClick}>
      <button
        className={classNames(styles.betslipToggle, branding.BETSLIP_COLLAPSE, {
          [styles.betslipToggle__active]: !collapse,
          [branding.UP]: !collapse,
          [branding.DOWN]: collapse
        })}
      >
        <Icon fontFamily="fa2" iconClass="fa2-chevron-down-icon" className="biab_expand" color="inherit" />
      </button>
      <span className={styles.betslipTitle}>{t(`betslip.labels.betslip`)}</span>
      {hasCollapseSidebar && (
        <button className={styles.sidebarToggle} onClick={onToggleSidebar}>
          <Icon iconClass="av-icon-union-chevron-right" fontFamily="av-icon" size="sm" color="inherit" />
        </button>
      )}
    </div>
  );
};

export default BetslipHeader;
