import { useTranslation } from 'react-i18next';

import { BetSides } from 'constants/myBets';
import { TAsianMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip/type';
import { getMarketTypes } from 'utils/market';

import styles from './styles.module.scss';

const MobileTitle = ({ bet }: { bet: TAsianMobileSelectedBet }) => {
  const { t } = useTranslation();

  const { isAsianHandicap } = getMarketTypes(bet.marketType ?? '', bet.bettingType ?? '');
  const isBack = bet.betType === BetSides.Back;
  const betTypeLabel = t(isBack ? 'asianView.labels.betslip.backBetFor' : 'asianView.labels.betslip.layBetAgainst');

  return (
    <h4 className={styles.title}>
      <span>{betTypeLabel}:</span>
      <span className={styles.name}>{bet.selectionName}</span>
      {isAsianHandicap && bet.score && <span>({bet.score})</span>}
    </h4>
  );
};

export default MobileTitle;
