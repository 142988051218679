import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { MarketStatuses, PopularEventTypes, SportIds } from 'constants/app';
import { betslipBranding, betslipBranding as branding } from 'constants/branding';
import { BettingTypes } from 'constants/markets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { removeSelectedMarket } from 'redux/modules/betslip';
import { getSelectedMarketInfo, getSelectedMarketState } from 'redux/modules/betslip/selectors';
import { SelectedMarketState } from 'redux/modules/betslip/type';
import {
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesCurrency,
  getMarketPricesMainEventName,
  getMarketPricesMarketNameWithParents,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { isEachWayMarketType } from 'utils/betslip';
import { parseMillisecondsToTime } from 'utils/date';

import styles from './styles.module.scss';

type TSelectedMarketHeaderProps = {
  marketId: string;
};
const SelectedMarketHeader = ({ marketId }: TSelectedMarketHeaderProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const marketInfo = useSelector(getSelectedMarketInfo(marketId));

  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const marketStatus = useSelector(getStatusByMarketPricesId(marketId));
  const marketState = useSelector(getSelectedMarketState(marketId));
  const marketCurrency = useSelector(getMarketPricesCurrency(marketId));
  const mainEventName = useSelector(getMarketPricesMainEventName(marketId));
  const marketNameWithParents = useSelector(getMarketPricesMarketNameWithParents(marketId));
  const { isMultiCurrencySupported, isMultiCurrencyChanged } = useMultiCurrencySupporting();

  const isCurrencyChanged = isMultiCurrencyChanged(marketCurrency);
  const isMultiCurrencyMessage = isMultiCurrencySupported && isCurrencyChanged;

  const isCricket = marketInfo?.sportId === SportIds.CRICKET;
  const isLineMarket = marketInfo?.bettingType === BettingTypes.LINE;
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;
  const isClosed = marketStatus === MarketStatuses.CLOSED;
  const isClosedState = marketState === SelectedMarketState.CLOSED || isMultiCurrencyMessage;
  const isEachWay = isEachWayMarketType(marketInfo?.marketType ?? '');

  const raceName =
    marketInfo?.raceName ?? marketInfo?.parents?.find(({ type }) => type === PopularEventTypes.RACE)?.name ?? '';

  const startTime =
    marketInfo?.marketStartTime &&
    parseMillisecondsToTime(+marketInfo?.marketStartTime || 0, timezone, timezoneCookieEnabled);
  const raceNameFormatted = raceName ? startTime + ' ' + raceName : '';

  const title = marketInfo?.gameName ? (
    <>
      {marketInfo?.gameName} {marketInfo?.marketName ? <>&ndash; {marketInfo?.marketName}</> : ''}
    </>
  ) : (
    <>
      {mainEventName || marketInfo?.eventName || marketInfo?.event?.name}{' '}
      {raceNameFormatted ? `- ${raceNameFormatted} -` : <>&ndash;</>} {marketNameWithParents || marketInfo?.marketName}
    </>
  );

  const onCloseNotificationHandler = () => {
    dispatch(removeSelectedMarket({ marketId }));
  };

  return (
    <div
      className={classNames(styles.selectedMarketHeader, branding.BET_EVENT_NAME, {
        [styles.selectedMarketHeader__closed]: isClosedState,
        [branding.FANCY_SWAP]: isFancySwapColors,
        [styles.selectedMarketHeader__disabled]: isMultiCurrencyMessage
      })}
    >
      {isInPlay && !isClosed && (
        <span className={classNames(styles.liveLabel, betslipBranding.LIVE)}>{t('asianView.labels.betslip.live')}</span>
      )}
      <div className={styles.headerTitle}>
        <span>{title}</span>
        {isEachWay && marketInfo?.eachWayDivisor && (
          <span className={classNames(styles.eachWayLabel, branding.BET_EVENT_EW_NAME)}>
            {t('market.each.way.termsNoPref', {
              odds: marketInfo?.eachWayDivisor,
              places: marketInfo?.numberOfWinners
            })}
          </span>
        )}
      </div>
      {isClosedState && (
        <button type="button" className={styles.closeBtn} onClick={onCloseNotificationHandler}>
          <i className="biab_custom-icon-close" />
        </button>
      )}
    </div>
  );
};

export default SelectedMarketHeader;
