import { useSelector } from 'react-redux';
import classNames from 'classnames';

import GameCartsDerbyRacing from 'components/GameCartsDerbyRacing';
import GameCartsTableBlackjack from 'components/GameCartsTableBlackjack';
import GameCartsTableHiLo from 'components/GameCartsTableHiLo';
import { Devices } from 'constants/app';
import { GameTypes } from 'constants/games';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { getCurrentGameData } from 'redux/modules/games/selectors';

import Baccarat from './components/Baccarat';
import PokerAndOmahaGames from './components/PokerAndOmahaGames';

import styles from './styles.module.scss';

const GameSection = () => {
  const currentGameData = useSelector(getCurrentGameData);
  const device = useSelector(getAppDevice);

  const isMobile = device === Devices.MOBILE;

  const getGameSectionView = () => {
    const gameType = currentGameData?.gameType;

    if (gameType) {
      switch (gameType) {
        case GameTypes.BACCARAT:
          return <Baccarat gameData={currentGameData.gameData} />;
        case GameTypes.POKER:
          return <PokerAndOmahaGames game={currentGameData} />;
        case GameTypes.OMAHA_POKER:
          return <PokerAndOmahaGames game={currentGameData} />;
        case GameTypes.BLACKJACK:
          return <GameCartsTableBlackjack gameData={currentGameData.gameData} />;
        case GameTypes.HILO:
          return <GameCartsTableHiLo game={currentGameData.gameData[0]} />;
        case GameTypes.CARD_RACER:
          return <GameCartsDerbyRacing gameData={currentGameData.gameData} />;
        default:
          return null;
      }
    }
  };

  return (
    <div className={classNames('biab_games-wrapper', styles.gamesWrapper)}>
      <div
        className={classNames(styles.cardsSection, {
          [styles.autoWidth]: currentGameData?.gameType === GameTypes.CARD_RACER,
          [styles.derbyMobileWrapper]: currentGameData?.gameType === GameTypes.CARD_RACER && isMobile
        })}
      >
        {getGameSectionView()}
      </div>
    </div>
  );
};

export default GameSection;
