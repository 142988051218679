import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FancyMarket from 'components/FancyMarket';
import Loader, { CircleColors } from 'components/Loader';
import { PageBlocks } from 'constants/app';
import { FancyMarketTypes } from 'constants/fancyMultiMarket';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';
import { fetchFancyMultiMarketHeaderDetails } from 'redux/modules/multiMarket';
import { getFancyMultiMarketHeaderData, getFancyMultiMarketHeaderLoading } from 'redux/modules/multiMarket/selectors';

interface FancyMultiMarketHeaderProps {
  /**
   * Whether lay column is disabled
   */
  isLayColumnDisabled: boolean;
  /**
   * Whether market depth is enabled
   */
  isDepthEnabled: boolean;
}

const FancyMultiMarketHeader = ({ isLayColumnDisabled, isDepthEnabled }: FancyMultiMarketHeaderProps) => {
  const dispatch = useDispatch();

  const market = useSelector(getSingleMarketInfo);
  const headerData = useSelector(getFancyMultiMarketHeaderData);
  const loading = useSelector(getFancyMultiMarketHeaderLoading);

  useEffect(() => {
    if (market?.marketName) {
      const fancyMarketTypes = market.marketName.startsWith('1st')
        ? FancyMarketTypes.FIRST_INNINGS_FANCY
        : FancyMarketTypes.SECOND_INNINGS_FANCY;

      dispatch(fetchFancyMultiMarketHeaderDetails({ eventId: market.event.id, fancyMarketTypes }));
    }
  }, [market?.marketName]);

  if (loading) {
    return <Loader circleColor={CircleColors.BLACK} />;
  } else if (!headerData) {
    return null;
  }

  return (
    <FancyMarket
      marketId={market?.marketId ?? ''}
      eventInfo={headerData.eventInfo}
      inningsFancy={headerData.inningsFancy}
      inningsFours={headerData.inningsFours}
      inningsSixes={headerData.inningsSixes}
      totalInningsRuns={headerData.totalInningsRuns}
      batsmen={headerData.batsmen}
      otherLineMarkets={headerData.otherLineMarkets}
      fallOfWicket={headerData.fallOfWicket}
      isMultiMarketView
      isLayColumnDisabled={isLayColumnDisabled}
      isDepthEnabled={isDepthEnabled}
      pageBlock={PageBlocks.FANCY_MULTI_MARKET_VIEW}
    />
  );
};

export default FancyMultiMarketHeader;
