import { MouseEvent, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ElementNames, IS_SCROLLING_SECTION, ROUTER_BASE_URL } from 'constants/app';
import { ACCOUNT_BASE_URL, ASIAN_BASE_URL, CUSTOM_PAGES_BASE_URL, MOBILE_LOGIN_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import { useMobileNavigation } from 'hooks/useMobileNavigation';
import {
  getIsAsianViewEnabled,
  getMobileSettingsClassicNavigation,
  getMobileSettingsClassicNavigationLinks,
  getMobileSettingsSwipeMenuLinks
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getIsTimeOutEnabled } from 'redux/modules/responsibleBetting/selectors';

import MobileNavigationItem from '../MobileNavigationItem';

import styles from './styles.module.scss';

const MobileNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const ulRef = useRef<HTMLDivElement>(null);
  const sliderRef = useRef({ isDown: false, startX: 0, scrollLeft: 0 });

  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const classicNavigation = useSelector(getMobileSettingsClassicNavigation);
  const classicNavigationLinks = useSelector(getMobileSettingsClassicNavigationLinks);
  const swipeMenuLinks = useSelector(getMobileSettingsSwipeMenuLinks);
  const isTimeOutEnabled = useSelector(getIsTimeOutEnabled);

  const { isDefaultInPlayLandingPath } = useMobileNavigation();
  const isAccountPage = location.pathname.includes(ACCOUNT_BASE_URL);
  const isLoginPage = location.pathname.includes(MOBILE_LOGIN_BASE_URL);
  const isCustomPage = location.pathname.startsWith(CUSTOM_PAGES_BASE_URL);

  const navigation = useMemo(() => {
    // TODO looks like 'window.location.pathname' can be replaced with 'location.pathname' and ROUTER_BASE_URL with '/'
    return classicNavigation &&
      (window.location.pathname.replace(/\/\/*/g, '/').replace(/\/+$/, '') === ROUTER_BASE_URL ||
        isDefaultInPlayLandingPath ||
        isCustomPage)
      ? classicNavigationLinks
      : swipeMenuLinks.filter(item => (asianViewEnabled ? true : !item.url.includes(ASIAN_BASE_URL)));
  }, [
    asianViewEnabled,
    classicNavigation,
    classicNavigationLinks,
    isDefaultInPlayLandingPath,
    isCustomPage,
    swipeMenuLinks,
    location.pathname
  ]);

  const hasMenu = !isAccountPage && !isLoginPage && navigation?.length > 0;

  const scrollToCenter = ({
    offsetLeft,
    event
  }: {
    offsetLeft: number;
    event?: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>;
  }) => {
    if (event && ulRef?.current?.classList.value.includes(IS_SCROLLING_SECTION)) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      return;
    }
    if (ulRef?.current && offsetLeft) {
      ulRef?.current?.scrollTo({
        left: offsetLeft - (ulRef?.current.scrollWidth - offsetLeft) / 2,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (ulRef.current?.children[0].children) {
      const navigationItems = Array.from(Array.from(ulRef.current?.children[0].children));
      const index = navigationItems.findIndex(i => i.children[0].classList.contains('biab_active'));
      if (navigationItems[index]) {
        const { x } = navigationItems[index].getBoundingClientRect();
        scrollToCenter({ offsetLeft: x });
      }
    }
  }, []);

  const mouseDown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (ulRef.current && ulRef.current) {
      sliderRef.current.isDown = true;
      ulRef.current.classList.add('active');
      sliderRef.current.startX = e.pageX - ulRef.current.offsetLeft;
      sliderRef.current.scrollLeft = ulRef.current.scrollLeft;
    }
  };

  const mouseLeave = () => {
    if (ulRef.current) {
      sliderRef.current.isDown = false;
      ulRef.current.classList.remove('active');
      ulRef.current.classList.remove(IS_SCROLLING_SECTION);
    }
  };

  const mouseMove = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    if (ulRef.current && sliderRef.current) {
      if (!sliderRef.current.isDown) return;
      ulRef.current.classList.add(IS_SCROLLING_SECTION);
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      const walk = e.pageX - ulRef.current.offsetLeft - sliderRef.current.startX;
      ulRef.current.scrollLeft = sliderRef.current.scrollLeft - walk;
    }
  };

  const mouseUp = () => {
    if (ulRef.current) {
      sliderRef.current.isDown = false;
      setTimeout(
        ul => {
          ul.classList.remove(IS_SCROLLING_SECTION);
        },
        0,
        ulRef.current
      );
    }
  };

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.MOBILE_SWIPE_MENU_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.MOBILE_SWIPE_MENU_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <>
      {hasMenu && !isTimeOutEnabled ? (
        <div ref={componentRef}>
          <div
            id="biab_mobileTopNav"
            className={styles.headerNavigationMobileWrapper}
            ref={ulRef}
            onMouseDown={mouseDown}
            onMouseLeave={mouseLeave}
            onMouseUp={mouseUp}
            onMouseMove={mouseMove}
          >
            <ul className={styles.headerNavigationMobileLinks}>
              {navigation.map(navigationType => (
                <MobileNavigationItem
                  onClick={scrollToCenter}
                  key={navigationType.orderNumber}
                  navigationType={navigationType}
                />
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default MobileNavigation;
