import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import AccountSettingsActions from 'components/AccountSettingsActions/AccountSettingsActions';
import AccountSettingsError from 'components/AccountSettingsError/AccountSettingsError';
import {
  componentsBranding,
  header as branding,
  mobileAccountPagesBranding,
  mobileComponents
} from 'constants/branding';
import {
  getCurrency,
  getDisplayCurrencySymbol,
  getIndianNumberSystemEnabled,
  getIsCurrencySymbolAfterAmount
} from 'redux/modules/appConfigs/selectors';
import { removeUpdateSettingsError, setSettings } from 'redux/modules/user';
import {
  getIsUpdateSettingsError,
  getIsUserAccountSettings,
  getPrecisionType,
  getUserAccountSettingsDefaultStakes
} from 'redux/modules/user/selectors';
import { Stake } from 'redux/modules/user/type';
import { DefaultStakeStates } from 'types/navigation';
import { getDefaultStakeValidationValue, getStakeDefaultValue, getStakeValue } from 'utils/navigation';
import { getStakeAmountLabelInfo } from 'utils/settings';

import styles from './AccountSettingsDefaultStake.module.scss';

type AccountSettingsDefaultStakeProps = {
  isMobile?: boolean;
};

const AccountSettingsDefaultStake = ({ isMobile }: AccountSettingsDefaultStakeProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const currency = useSelector(getCurrency);
  const displayCurrencySymbol = useSelector(getDisplayCurrencySymbol);
  const currencySymbolAfterAmount = useSelector(getIsCurrencySymbolAfterAmount);
  const indianNumberSystemEnabled = useSelector(getIndianNumberSystemEnabled);
  const precision = useSelector(getPrecisionType);
  const defaultStakes = useSelector(getUserAccountSettingsDefaultStakes);
  const isUpdateSettingsError = useSelector(getIsUpdateSettingsError);

  const initialDefaultStakesStates: DefaultStakeStates = useMemo(
    () => ({
      first: {
        value: getStakeValue(defaultStakes?.[0]),
        defaultValue: getStakeDefaultValue(defaultStakes?.[0]),
        error: getDefaultStakeValidationValue({
          value: defaultStakes?.[0]?.value,
          currency,
          t,
          isDesktopSettings: true
        }),
        index: 0,
        key: 'first',
        isFocused: false
      },
      second: {
        value: getStakeValue(defaultStakes?.[1]),
        defaultValue: getStakeDefaultValue(defaultStakes?.[1]),
        error: getDefaultStakeValidationValue({
          value: defaultStakes?.[1]?.value,
          currency,
          t,
          isDesktopSettings: true
        }),
        index: 1,
        key: 'second',
        isFocused: false
      },
      third: {
        value: getStakeValue(defaultStakes?.[2]),
        defaultValue: getStakeDefaultValue(defaultStakes?.[2]),
        error: getDefaultStakeValidationValue({
          value: defaultStakes?.[2]?.value,
          currency,
          t,
          isDesktopSettings: true
        }),
        index: 2,
        key: 'third',
        isFocused: false
      }
    }),
    [currency, defaultStakes]
  );

  const [defaultStakesStates, setDefaultStakesStates] = useState<DefaultStakeStates>(initialDefaultStakesStates);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const { states, isSelectedStakeError, noValues, isError, stakesWithError } = useMemo(() => {
    const statesValues = Object.values(defaultStakesStates);
    const stakesWithErrorState = statesValues.filter(({ error }) => !!error);

    return {
      states: statesValues,
      isSelectedStakeError: statesValues.find(stake => stake.defaultValue)?.error || '',
      noValues: statesValues.every(({ value }) => !value),
      isError: stakesWithErrorState.length > 0,
      stakesWithError: stakesWithErrorState
    };
  }, [defaultStakesStates]);

  const isValuesChanged =
    initialDefaultStakesStates.first.value !== defaultStakesStates.first.value ||
    initialDefaultStakesStates.second.value !== defaultStakesStates.second.value ||
    initialDefaultStakesStates.third.value !== defaultStakesStates.third.value;

  const handleChangeDefaultStake = ({ key, value }: { key?: 'first' | 'second' | 'third'; value: string }) => {
    if (key) {
      if (value === '') {
        setDefaultStakesStates(prevState => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            value,
            defaultValue: false,
            error: ''
          }
        }));
      } else if (/^\d+(\.\d*)?$/.test(value)) {
        setDefaultStakesStates(prevState => ({
          ...prevState,
          [key]: {
            ...prevState[key],
            value,
            error: getDefaultStakeValidationValue({ value, currency, t, isDesktopSettings: true })
          }
        }));
      }
    }
  };

  const handleClickDefaultStake = (index: number, value = '') => {
    const newDefaultStakes = defaultStakes.map((stake, stakeIndex) => {
      if (stakeIndex === index) {
        return { ...stake, defaultValue: true } as Stake;
      } else if (stake?.defaultValue) {
        return { ...stake, defaultValue: false } as Stake;
      }

      return stake;
    });

    if (isAccountSettings && value) {
      dispatch(
        setSettings({
          settings: {
            defaultStakes: newDefaultStakes
          }
        })
      );
    }
  };

  const handleSaveStakes = () => {
    if (!isError && !noValues && isAccountSettings && isValuesChanged) {
      setIsSaveLoading(true);

      const noChangedStakes = defaultStakes.slice(3);
      const onlyOneValue = states.filter(({ value }) => !!value).length === 1;
      const prevDefStakes = defaultStakes.slice(0, 3);
      const noPrevValues = prevDefStakes.every(stake => stake === null);
      const stakesToSubmit = states
        .sort((a, b) => a.index - b.index)
        .map(({ value, index, defaultValue }) => {
          if (!value) {
            return null;
          }

          let defValue: boolean;

          if (noPrevValues) {
            defValue = index === 0;
          } else if (onlyOneValue) {
            defValue = true;
          } else {
            defValue = defaultValue;
          }

          return {
            value: Number(value),
            defaultValue: defValue
          } as Stake;
        });

      dispatch(
        setSettings({
          settings: {
            defaultStakes: stakesToSubmit.concat(noChangedStakes)
          },
          onFinish: () => {
            setIsSaveLoading(false);
            setIsEditMode(false);
          }
        })
      );
    }
  };

  const handleCancelEditMode = () => {
    setIsEditMode(false);
    setDefaultStakesStates(initialDefaultStakesStates);
  };

  const getStakeAmountLabel = (stakeAmount: number) => {
    const { label, translationKey } = getStakeAmountLabelInfo(stakeAmount, precision, indianNumberSystemEnabled);
    const formatSymbol = translationKey ? t(translationKey) : '';

    if (displayCurrencySymbol) {
      if (currencySymbolAfterAmount) {
        return `${label}${formatSymbol}${currency.symbol}`;
      }

      return `${currency.symbol}${label}${formatSymbol}`;
    }

    return `${label}${formatSymbol}`;
  };

  return (
    <>
      <div className={styles.defaultStake}>
        {defaultStakes?.slice(0, 3)?.map((defaultStake, index) => {
          const defaultStakeState = states.find(stake => stake.index === index);
          const isDisabled = defaultStakeState?.error || isSelectedStakeError;
          const isDefaultValue = !!defaultStake?.defaultValue;
          const defaultStakeValue = defaultStake?.value;

          if (isEditMode) {
            return (
              <input
                key={index}
                type="text"
                value={defaultStakeState?.value ?? ''}
                placeholder={isMobile ? `${t('account.settings.stake')} ${index + 1}` : ''}
                className={classNames(styles.defaultStake__input, {
                  [styles.defaultStake__input__error]: !!defaultStakeState?.error,
                  [styles.defaultStake__input__mobile]: isMobile,
                  [componentsBranding.ERROR]: !!defaultStakeState?.error && !isMobile,
                  [componentsBranding.FORM_INPUT]: !isMobile,
                  [mobileComponents.INPUT]: isMobile,
                  [mobileComponents.ERROR]: !!defaultStakeState?.error && isMobile
                })}
                onChange={e =>
                  handleChangeDefaultStake({ key: defaultStakeState?.key ?? 'first', value: e.target.value })
                }
              />
            );
          }

          return (
            <button
              key={index}
              className={classNames(styles.defaultStake__button, {
                [styles.defaultStake__button__active]: isDefaultValue,
                [styles.defaultStake__button__inactive]: !isDefaultValue,
                [branding.SELECTED]: isDefaultValue && !isMobile,
                [branding.DEFAULT_STAKE]: !isMobile,
                [styles.defaultStake__button__mobile]: isMobile,
                [styles.defaultStake__button__mobile__hint]: isMobile && !defaultStakeValue,
                [mobileAccountPagesBranding.SELECTED_STATE]: isMobile && isDefaultValue,
                [mobileComponents.BUTTON]: isMobile && !isDefaultValue,
                [mobileComponents.SECONDARY]: isMobile && !isDefaultValue
              })}
              onClick={!isDisabled ? () => handleClickDefaultStake(index, defaultStakeState?.value) : undefined}
            >
              {defaultStakeValue
                ? getStakeAmountLabel(defaultStakeValue)
                : isMobile
                ? `${t('account.settings.stake')} ${index + 1}`
                : null}
            </button>
          );
        })}
      </div>
      {stakesWithError.map(({ error, key }) => {
        return (
          <AccountSettingsError
            key={key}
            error={error}
            containerClassName={styles.defaultStake__error}
            isMobile={isMobile}
          />
        );
      })}
      <AccountSettingsActions
        isEditMode={isEditMode}
        onCancel={handleCancelEditMode}
        onSave={handleSaveStakes}
        onEdit={() => setIsEditMode(true)}
        isSaveBtnDisabled={noValues || isError || !isValuesChanged}
        isSaveLoading={isSaveLoading}
        isMobile={isMobile}
      />
      {isUpdateSettingsError && (
        <AccountSettingsError
          error={t('account.settings.changesNotSaved')}
          onClose={() => dispatch(removeUpdateSettingsError())}
          containerClassName={styles.defaultStake__notSavedError}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

export default AccountSettingsDefaultStake;
