import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import Image from 'components/Image/Image';
import { MobilePreferencesOption } from 'types/mobile';

import styles from './MobileLocaleTimezoneView.module.scss';

type LocaleTimezoneViewProps = {
  label: string;
  options: MobilePreferencesOption[];
  onChangeOption: (option: MobilePreferencesOption) => void;
  onGoBack: () => void;
  selectedOption: MobilePreferencesOption;
  type: 'locale' | 'timezone';
  headerResizeElement?: HTMLElement | null;
};

const MobileLocaleTimezoneView = ({
  label,
  options,
  onChangeOption,
  selectedOption,
  onGoBack,
  type,
  headerResizeElement
}: LocaleTimezoneViewProps) => {
  const [newSelectedOption, setNewSelectedOption] = useState(selectedOption);

  const handleSelectNewOption = (newOption: MobilePreferencesOption) => {
    if (newOption.id !== newSelectedOption.id) {
      setNewSelectedOption(newOption);
    }
  };

  const handleApplyNewOption = () => {
    if (newSelectedOption.id !== selectedOption.id) {
      onChangeOption(newSelectedOption);
    }
  };

  useEffect(() => {
    scrollTo({ top: 0 });
  }, []);

  const header = (
    <div className={styles.view__header}>
      <button className={styles.view__header__btn} onClick={onGoBack}>
        <i className={'biab_custom-icon-chevron-left-24'} />
      </button>
      <p className={styles.view__header__label}>{label}</p>
      <button
        disabled={newSelectedOption.id === selectedOption.id}
        onClick={handleApplyNewOption}
        className={classNames(styles.view__header__btn, {
          [styles.view__header__btn__active]: newSelectedOption.id !== selectedOption.id
        })}
      >
        <i
          className={classNames('av-icon av-icon-check', styles.view__icon, {
            [styles.view__icon__disabled]: newSelectedOption.id === selectedOption.id,
            [styles.view__icon__active]: newSelectedOption.id !== selectedOption.id
          })}
        />
      </button>
    </div>
  );

  return (
    <>
      {headerResizeElement ? createPortal(header, headerResizeElement) : header}
      <div className={styles.view__option__container}>
        {options.map(option => {
          const isActive = newSelectedOption?.id === option.id;

          return (
            <button
              key={option.id}
              disabled={isActive}
              onClick={() => handleSelectNewOption(option)}
              className={classNames(styles.view__option, {
                [styles.view__option__selected]: isActive,
                [styles.view__option__notSelected]: !isActive
              })}
            >
              <span className={styles.view__option__left}>
                {option.image && (
                  <Image className={styles.flag} name={option.value} width={31} height={18} path={option.image} />
                )}
                {type === 'locale' ? option.label?.toLowerCase() : option.label}
              </span>
              {isActive && <i className={classNames('av-icon av-icon-check', styles.view__icon)} />}
            </button>
          );
        })}
      </div>
    </>
  );
};

export default MobileLocaleTimezoneView;
