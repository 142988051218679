import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import NavSkeleton from 'components/Skeleton/NavSkeleton';
import AllSportsButton from 'components/SportsSection/partials/AllSportsButton';
import { Devices } from 'constants/app';
import { TYPE_TO_URL } from 'constants/competitions';
import {
  getAppDevice,
  getDesktopSettingsCompetitionViewSports,
  getIsCompetitionViewEnabled,
  getLanguage,
  getTimezone,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchCompetitionByCountryAndSport, resetSportData, setRace } from 'redux/modules/competitions';
import {
  getCountryData,
  getCountryLoading,
  getCountryNameHighlighted,
  getCountryNameTranslations,
  getCountryParents
} from 'redux/modules/competitions/selectors';
import { filterCompetitionViewParents } from 'utils/navigation';

import ListItem from '../partials/ListItem';
import NavItemName from '../partials/NavItemName';
import ParentItem from '../partials/ParentItem';

const CountryView = () => {
  const { sportId, countryId } = useParams();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const competitionViewSports = useSelector(getDesktopSettingsCompetitionViewSports);
  const isCompetitionViewEnabled = useSelector(getIsCompetitionViewEnabled);
  const timezone = useSelector(getTimezone);
  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const domainLanguage = useSelector(getTranslation);
  const countryParents = useSelector(getCountryParents);
  const countryData = useSelector(getCountryData);
  const countryNameTranslations = useSelector(getCountryNameTranslations);
  const countryLoading = useSelector(getCountryLoading);
  const countryNameHighlighted = useSelector(getCountryNameHighlighted);

  useEffect(() => {
    if (countryId && sportId) {
      dispatch(fetchCompetitionByCountryAndSport({ countryId, sportId }));
    }
  }, [countryId, sportId, timezone, isLoggedIn, language]);

  useEffect(() => {
    return () => {
      dispatch(setRace(null));
      dispatch(resetSportData());
    };
  }, []);

  return (
    <>
      {countryLoading && <NavSkeleton withDelay={150} itemsCount={40} />}
      <div className={classNames({ hidden: countryLoading })}>
        {device === Devices.MOBILE && !countryLoading && <AllSportsButton />}
        {filterCompetitionViewParents(countryParents, competitionViewSports, sportId, isCompetitionViewEnabled).map(
          parent => (
            <ParentItem key={parent.id} item={parent} />
          )
        )}
        {countryNameTranslations && (
          <NavItemName
            type={TYPE_TO_URL.COUNTRY}
            highlighted={countryNameHighlighted}
            nameTranslations={countryNameTranslations}
          />
        )}
        {countryData.map(d => (
          <ListItem key={d.id} item={d} domainLanguage={domainLanguage} />
        ))}
      </div>
    </>
  );
};

export default CountryView;
