import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import InfoCircle from 'components/InfoCircle';
import { Devices, PageBlocks, PlacementPages } from 'constants/app';
import { BetslipTabs } from 'constants/betslip';
import { GameTypes, MARKET_TABS_BACCARAT, MARKET_TABS_MAIN } from 'constants/games';
import { BetSides } from 'constants/myBets';
import { MarketContext } from 'contexts/gamesMarletContext';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getAppDevice, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { setActiveTab, setSelectedBets } from 'redux/modules/betslip';
import { getIsBetSelected } from 'redux/modules/betslip/selectors';
import {
  getCurrentGameMarket,
  getCurrentGameMarketCurrency,
  getCurrentGameMarketsAmount,
  getCurrentGameName,
  getCurrentGameType
} from 'redux/modules/games/selectors';
import { removeInlineSelectedBet, setInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { getInlineSelectedBet } from 'redux/modules/inlinePlacement/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { BetSide } from 'types/myBets';

import styles from '../../styles.module.scss';

interface ICellBox {
  selectionId: number;
  value: number;
  unmatched: number;
  index: number;
  type: BetSide;
  name: string;
  isFirstSelection?: boolean;
}

const CellBox = ({ selectionId, value, unmatched, index, type, name, isFirstSelection = false }: ICellBox) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currency = useSelector(getCurrentGameMarketCurrency);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const gameMarket = useSelector(getCurrentGameMarket);
  const gameName = useSelector(getCurrentGameName);
  const gameType = useSelector(getCurrentGameType);
  const marketsAmount = useSelector(getCurrentGameMarketsAmount);
  const device = useSelector(getAppDevice);

  const isFirst = index === 0;
  const isEmptyCell = !unmatched && !value;
  const { isBestOddTooltipEnabled } = useContext(MarketContext);
  const showBestBetCellTooltip = index === 1 && type === BetSides.Back && isBestOddTooltipEnabled && isFirstSelection;
  const isDesktop = device === Devices.DESKTOP;
  const betType = type === BetSides.Back ? BetSides.Back : BetSides.Lay;

  const { formattedAmount: unmatchedAmount, noFormattedAmount: tooltipUnmatchedAmount } = useFormatCurrency(
    unmatched,
    currency,
    {
      noCommas: true
    }
  );

  const inlineSelectedBet = useSelector(
    getInlineSelectedBet(PageBlocks.GAME, String(gameMarket?.id), selectionId ?? 0, 0, betType)
  );

  const isBetslipBetSelected = useSelector(
    getIsBetSelected({
      marketId: String(gameMarket?.id ?? ''),
      selectionId: selectionId,
      handicap: 0,
      type: betType
    })
  );

  const isSelected =
    (isDesktop && inlineSelectedBet) ||
    (!isDesktop && inlineSelectedBet && (!inlineSelectedBet.currentOfferId || inlineSelectedBet.action)) ||
    isBetslipBetSelected;

  const onBetCellClickHandler = () => {
    if (inlineSelectedBet) {
      dispatch(removeInlineSelectedBet(inlineSelectedBet));
    } else if (gameMarket) {
      const marketsNames =
        gameType === GameTypes.BACCARAT || gameType === GameTypes.OMAHA_POKER ? MARKET_TABS_BACCARAT : MARKET_TABS_MAIN;

      const marketInfo = {
        gameName,
        gameType,
        commission: gameMarket.commission,
        marketId: String(gameMarket.id),
        sportId: String(gameMarket.gameId),
        marketName:
          marketsAmount > 1 && gameMarket.selectionsType
            ? t(marketsNames[gameMarket.selectionsType === 'MainBets' ? 0 : 1].translationKey)
            : '',
        marketType: gameMarket.marketType,
        runners: gameMarket.selections.map(gameSelection => {
          return {
            selectionId: gameSelection.id,
            runnerName: gameSelection.name,
            handicap: null,
            status: gameSelection.status
          };
        }),
        numberOfWinners: 1
      };

      const bet: TInlineSelectedBet = {
        marketId: String(gameMarket.id),
        sportId: String(gameMarket.gameId),
        gameName,
        gameType,
        selectionName: name,
        runners: gameMarket.runners,
        selectionId: selectionId,
        handicap: 0,
        marketType: gameMarket.marketType,
        numberOfWinners: 1,
        type: betType,
        price: value,
        pageBlock: PageBlocks.GAME,
        currency: currency ?? '',
        page: PlacementPages.GAME
      };

      if (isDesktop) {
        dispatch(setSelectedBets({ selectedBets: [bet], marketInfo }));
        dispatch(setActiveTab(BetslipTabs.PLACE_BETS));
      } else {
        dispatch(setInlineSelectedBet({ selectedBet: bet }));
      }
    }
  };

  return (
    <div
      className={classNames(styles.box, {
        'biab_green-cell': isFirst && type === BetSides.Lay,
        'biab_blue-cell': isFirst && type === BetSides.Back,
        'biab_bet-back back-cell': type === BetSides.Back,
        'biab_bet-lay lay-cell': type === BetSides.Lay,
        'biab_empty-cell': isEmptyCell,
        'biab_grey-cell': !isFirst,
        biab_selected: isSelected
      })}
      data-cell-id={selectionId + '_0'}
      onClick={onBetCellClickHandler}
    >
      <div
        data-tooltip-id="tooltip"
        data-tooltip-html={unescape(
          t(`${isPNCEnabled ? 'pnc.' : ''}tooltip.${type === BetSides.Back ? 'back' : 'lay'}MarketCell`, {
            amount: tooltipUnmatchedAmount,
            selection: name,
            odds: value
          })
        )}
        className={classNames(styles.boxContent, 'biab_bet-content cursor-help')}
      >
        <span className={classNames('biab_odds', styles.value)}>{value}</span>
        <span className={classNames('biab_bet-amount', styles.unmatched)}>{unmatched ? unmatchedAmount : ''}</span>
      </div>
      {showBestBetCellTooltip && (
        <div
          data-tooltip-id="tooltip"
          data-tooltip-html={unescape(t('tooltip.all.pages.cells'))}
          className={classNames('cursor-help', styles.bestBetIcon)}
        >
          <InfoCircle />
        </div>
      )}
    </div>
  );
};

export default CellBox;
