import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ResponsibleBettingApplySelfExclusionForm from 'components/ResponsibleBetting/components/ResponsibleBettingApplySelfExclusionForm';
import ResponsibleBettingCreateSelfExclusionForm from 'components/ResponsibleBetting/components/ResponsibleBettingCreateSelfExclusionForm';
import { ResponsibleBettingSection } from 'components/ResponsibleBetting/components/ResponsibleBettingSection';
import { Methods } from 'constants/app';
import { ResponsibleBettingDisplayedContents } from 'constants/responsibleBetting';
import useDevice from 'hooks/useDevice';
import { fetchRequest } from 'redux/api/request';
import { urls } from 'redux/api/urls';
import { getBackendPages, getBettingDisplaySettings } from 'redux/modules/appConfigs/selectors';
import { logoutUser } from 'redux/modules/auth';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { setCustomerSelfExclusionStartDate, updateResponsibleBettingSettings } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import { DropdownItem } from 'types';
import { ResponsibleBettingSelfExclusionPeriod, ResponsibleBettingTab } from 'types/responsibleBetting';
import { convertSelfExclusionPeriodsToDropdown } from 'utils/responsibleBetting';

interface ResponsibleBettingSelfExclusionProps {
  tab: ResponsibleBettingTab;
}

const ResponsibleBettingSelfExclusion = ({ tab }: ResponsibleBettingSelfExclusionProps) => {
  const { isMobile } = useDevice();
  const pages = useSelector(getBackendPages);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rbSettings = useSelector(getResponsibleBettingSettings);
  const bettingDisplaySettings = useSelector(getBettingDisplaySettings);

  const [selectedTimeUnit, setSelectedTimeUnit] = useState<ResponsibleBettingSelfExclusionPeriod | null>(null);
  const [isOpened, setIsOpened] = useState(false);
  const [isAppliedMode, setIsAppliedMode] = useState(false);

  const selfExclusionPeriods = convertSelfExclusionPeriodsToDropdown(bettingDisplaySettings.selfExclusionPeriods, t);
  const isSingleSelfExclusionPeriod = selfExclusionPeriods.length === 1;

  const handleOnCancel = () => {
    setIsOpened(false);
  };

  const handleOnContinue = () => {
    setIsAppliedMode(true);
  };

  const handleOnBack = () => {
    if (isSingleSelfExclusionPeriod) {
      setIsOpened(false);
    } else {
      setIsAppliedMode(false);
    }
  };

  const handleOnApply = async () => {
    const redirectUrl = `/pages/${pages[PagesFromBackend.RG_TOOLS_SELF_EXCLUSION_START]}`;
    if (rbSettings) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            selfExclusionEnabled: true,
            selfExclusionTimeUnit: selectedTimeUnit,
            type: ResponsibleBettingDisplayedContents.SelfExclusion
          },
          type: ResponsibleBettingDisplayedContents.SelfExclusion
        })
      );
      const result = await fetchRequest(Methods.GET, urls.responsibleBetting.base);
      dispatch(setCustomerSelfExclusionStartDate(result?.rgTools?.selfExclusionEndDate));
      dispatch(logoutUser(redirectUrl));
    }
  };

  const handleOnSelectOption = (option: DropdownItem) => {
    if (selectedTimeUnit !== option.value) {
      setSelectedTimeUnit(option.value as ResponsibleBettingSelfExclusionPeriod);
    }
  };

  return (
    <ResponsibleBettingSection tab={tab} onClick={() => setIsOpened(true)} isOpened={isOpened}>
      {isAppliedMode || isSingleSelfExclusionPeriod ? (
        <ResponsibleBettingApplySelfExclusionForm
          isMobile={isMobile}
          selectedTimeUnit={
            isSingleSelfExclusionPeriod
              ? (selfExclusionPeriods[0].value as ResponsibleBettingSelfExclusionPeriod)
              : selectedTimeUnit
          }
          onBack={handleOnBack}
          onApply={handleOnApply}
        />
      ) : (
        <ResponsibleBettingCreateSelfExclusionForm
          selfExclusionPeriods={selfExclusionPeriods}
          onSelectOption={handleOnSelectOption}
          selectedTimeUnit={selectedTimeUnit}
          onCancel={handleOnCancel}
          onContinue={handleOnContinue}
        />
      )}
    </ResponsibleBettingSection>
  );
};

export default ResponsibleBettingSelfExclusion;
