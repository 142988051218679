import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useOnClickOutside } from 'usehooks-ts';

import Modal from 'components/Modal';
import { BetSides } from 'constants/myBets';
import { getCurrencyMaxBetSize, getCurrencyMinBetSize } from 'redux/modules/appConfigs/selectors';
import { setDutchingCalculatorData, updateSelectedBet } from 'redux/modules/betslip';
import {
  getIsMaxStakeMessageBetSlipDutchingCalculator,
  getIsMinStakeMessageBetSlipDutchingCalculator,
  getSelectedBetsForDutchingCalculator
} from 'redux/modules/betslip/selectors';
import { BetSide } from 'types/myBets';

import styles from './DutchingCalculator.module.scss';

type DutchingCalculatorProps = {
  type: BetSide;
  marketId: string;
};

const DutchingCalculator = ({ type, marketId }: DutchingCalculatorProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const bets = useSelector(getSelectedBetsForDutchingCalculator({ marketId, type }));
  const minBetSize = useSelector(getCurrencyMinBetSize);
  const maxBetSize = useSelector(getCurrencyMaxBetSize);
  const isMinStakeMessageFromState = useSelector(getIsMinStakeMessageBetSlipDutchingCalculator({ marketId, type }));
  const isMaxStakeMessageFromState = useSelector(getIsMaxStakeMessageBetSlipDutchingCalculator({ marketId, type }));

  const [isOpenedPopUp, setIsOpenedPopUp] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const calculatorRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleCloseCalculator = () => {
    dispatch(setDutchingCalculatorData({ marketId, type, data: { isOpened: false } }));
  };

  useOnClickOutside(isOpenedPopUp ? [calculatorRef, modalRef] : calculatorRef, handleCloseCalculator);

  const handleChangeStake = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (/^\d+(\.\d{0,2})?$/.test(value) || value === '') {
      setInputValue(value);
    }
  };

  const handleDistribute = () => {
    if (bets?.length) {
      const numericInputValue = Number(inputValue);
      const betsWithInversePrices = bets.map(({ price, ...rest }) => {
        const numericPrice = Number(price);

        return {
          ...rest,
          price: numericPrice,
          inversePrice: numericInputValue / numericPrice
        };
      });
      const totalInverseOdds =
        type === BetSides.Back ? betsWithInversePrices.reduce((sum, { inversePrice }) => sum + inversePrice, 0) : 0;
      let isMinStakeValidationMessage = false;
      let isMaxStakeValidationMessage = false;

      betsWithInversePrices.forEach(({ price, inversePrice, ...rest }) => {
        const stake =
          type === BetSides.Back
            ? (inversePrice / totalInverseOdds) * numericInputValue
            : numericInputValue / (price - 1);

        if (stake >= minBetSize && stake <= maxBetSize) {
          dispatch(
            updateSelectedBet({
              ...rest,
              size: Number(stake.toFixed(2)),
              profit: Number(((price - 1) * stake).toFixed(2)),
              isSizeValid: true
            })
          );
        } else {
          dispatch(updateSelectedBet({ ...rest, size: '', profit: '' }));

          if (stake < minBetSize) {
            isMinStakeValidationMessage = true;
          } else if (stake > maxBetSize) {
            isMaxStakeValidationMessage = true;
          }
        }
      });

      if (isMinStakeValidationMessage !== isMinStakeMessageFromState) {
        dispatch(
          setDutchingCalculatorData({ marketId, type, data: { isMinStakeMessage: isMinStakeValidationMessage } })
        );
      }

      if (isMaxStakeValidationMessage !== isMaxStakeMessageFromState) {
        dispatch(
          setDutchingCalculatorData({ marketId, type, data: { isMaxStakeMessage: isMaxStakeValidationMessage } })
        );
      }
    }

    handleCloseCalculator();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && inputValue) {
      handleDistribute();
    }
  };

  return (
    <>
      <div
        ref={calculatorRef}
        className={classNames(styles.calculator, {
          [styles.calculator__lay]: type === BetSides.Lay
        })}
      >
        <div className={styles.calculator__header}>
          <div className={styles.calculator__header__left}>
            <p className={styles.calculator__title}>{t('dutchingCalculator.title')}</p>
            <button className={styles.calculator__infoBtn} onClick={() => setIsOpenedPopUp(true)}>
              <i className="biab_custom-icon-info-circle" />
            </button>
          </div>
          <button className={styles.calculator__closeBtn} onClick={handleCloseCalculator}>
            <i className="biab_custom-icon-close" />
          </button>
        </div>
        <div className={styles.calculator__middle}>
          <p className={styles.calculator__stakeLabel}>
            {t(type === BetSides.Back ? 'dutchingCalculator.stake' : 'dutchingCalculator.liability')}
          </p>
          <input
            type="text"
            className={styles.calculator__input}
            value={inputValue}
            onChange={handleChangeStake}
            autoFocus
            onKeyDown={handleKeyDown}
          />
        </div>
        <button className={styles.calculator__distribute} disabled={!inputValue} onClick={handleDistribute}>
          {t('dutchingCalculator.distribute')}
        </button>
      </div>
      {isOpenedPopUp && (
        <Modal
          open={isOpenedPopUp}
          title={t('dutchingCalculator.title')}
          onClose={() => setIsOpenedPopUp(false)}
          hasTitle
          customClassNames={{ body: styles.calculator__modal__body, dialog: styles.calculator__modal__dialog }}
          containerRef={modalRef}
        >
          <p className={styles.calculator__modal__text}>
            {t(`dutchingCalculator.popUp.content.${type.toLowerCase()}`)}
          </p>
        </Modal>
      )}
    </>
  );
};

export default DutchingCalculator;
