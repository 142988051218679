import { toNumber, uniq } from 'lodash';

import { LineSides } from 'constants/betslip';
import { BetSides } from 'constants/myBets';
import { THandicap, TPrice, TSize } from 'types/bets';
import { LineSide, TBetslipMarketRunner } from 'types/betslip';
import { BetSide } from 'types/myBets';
import { getDecimal } from 'utils/betValidation';

/**
 * Calculate AH Double line profit & loss for current price, size, handicap, bet type values
 *
 * @param price
 * @param size
 * @param handicap
 * @param betType
 * @param caseNumber
 */
export const getAHDoubleLineProfitLoss = ({
  price,
  size,
  handicap,
  betType
}: {
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetSide;
}) => {
  const { handicapValue, odds, stake, coefficient } = getCalculatedParams({ price, size, handicap, betType });

  let profitLossW: number | null = null;
  let profitLossS: number | null = null;
  let profitLossL: number | null = null;

  if (price && size) {
    switch (true) {
      // ------------------ CASE 1 -------------------
      case handicapValue === 0:
      case handicapValue === -1:
      case handicapValue === 1:
      // -N, where N = 2, 3, ...
      case handicapValue <= -2 && handicapValue % 1 === 0:
      // N, where N = 2, 3, ...
      case handicapValue >= 2 && handicapValue % 1 === 0:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossS = 0;
        profitLossL = -stake * coefficient;
        break;

      // ------------------ CASE 2 -------------------
      case handicapValue === -0.5:
      case handicapValue === 0.5:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossS = -stake * coefficient;
        break;

      // ------------------ CASE 3 -------------------
      //  -N.5, where N = 1, 2, 3, ...
      case handicapValue <= -1.5 && getDecimal(handicapValue) === 0.5:
      //  N.5, where N = 1, 2, 3, ...
      case handicapValue >= 1.5 && getDecimal(handicapValue) === 0.5:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossL = -stake * coefficient;
        break;

      // ------------------ CASE 4 -------------------
      // 0 && -0.5
      case handicapValue === -0.25:
      // -0.5 && -1.0
      case handicapValue === -0.75:
      // -1.0 && -1.5
      case handicapValue === -1.25:
      // N.5 && (N + 1).0, where N = 1, 2, 3, ...
      case handicapValue >= 1.75 && getDecimal(handicapValue) === 0.75:
      // -N.0 && -N.5, where N = 2, 3, ...
      case handicapValue <= -2 && getDecimal(handicapValue) === 0.25:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossS = (((handicapValue === -0.75 ? odds - 1 : -1) * stake) / 2) * coefficient;
        profitLossL = -stake * coefficient;
        break;

      // ------------------ CASE 5 -------------------
      // 0 && 0.5
      case handicapValue === 0.25:
      // 0.5 && 1
      case handicapValue === 0.75:
      // 1 && 1.5
      case handicapValue === 1.25:
      // -N.5 && -(N + 1).0, where N = 1, 2, 3, ...
      case handicapValue <= -1.75 && getDecimal(handicapValue) === 0.75:
      // N && N.5, where N = 2, 3, ...
      case handicapValue >= 2 && getDecimal(handicapValue) === 0.25:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossS = (((handicapValue === 0.75 ? -1 : odds - 1) * stake) / 2) * coefficient;
        profitLossL = -stake * coefficient;
        break;
    }
  }

  return {
    profitLossW,
    profitLossS,
    profitLossL
  };
};

/**
 * Calculate Alt total goals bets profit & loss for current price, size, handicap, bet type, handicap type
 *
 * @param price
 * @param size
 * @param handicap
 * @param betType
 * @param handicapType
 */
export const getAltTotalGoalsProfitLoss = ({
  price,
  size,
  handicap,
  betType,
  handicapType
}: {
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetSide;
  handicapType?: LineSide | null;
}) => {
  const { handicapValue, odds, stake, coefficient } = getCalculatedParams({ price, size, handicap, betType });

  let profitLossW: number | null = null;
  let profitLossS: number | null = null;
  let profitLossL: number | null = null;

  if (price && size) {
    switch (true) {
      // Example: 1.5
      case getDecimal(handicapValue) === 0.5 && handicapType === LineSides.UNDER:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossL = -stake * coefficient;
        break;
      case getDecimal(handicapValue) === 0.5 && handicapType === LineSides.OVER:
        profitLossW = -stake * coefficient;
        profitLossL = (odds - 1) * stake * coefficient;
        break;
      // Example: 2.0
      case (handicapValue === 1 || handicapValue % 1 === 0) && handicapType === LineSides.UNDER:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossS = 0;
        profitLossL = -stake * coefficient;
        break;
      case (handicapValue === 1 || handicapValue % 1 === 0) && handicapType === LineSides.OVER:
        profitLossW = -stake * coefficient;
        profitLossS = 0;
        profitLossL = (odds - 1) * stake * coefficient;
        break;
      //  Example: 2.5 & 3
      case getDecimal(handicapValue) === 0.75 && handicapType === LineSides.UNDER:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossS = -0.5 * stake * coefficient;
        profitLossL = -stake * coefficient;
        break;
      case getDecimal(handicapValue) === 0.75 && handicapType === LineSides.OVER:
        profitLossW = -stake * coefficient;
        profitLossS = 0.5 * (odds - 1) * stake * coefficient;
        profitLossL = (odds - 1) * stake * coefficient;
        break;
      //  Example: 2.0 & 2.5
      case getDecimal(handicapValue) === 0.25 && handicapType === LineSides.UNDER:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossS = 0.5 * (odds - 1) * stake * coefficient;
        profitLossL = -stake * coefficient;
        break;
      case getDecimal(handicapValue) === 0.25 && handicapType === LineSides.OVER:
        profitLossW = -stake * coefficient;
        profitLossS = -0.5 * stake * coefficient;
        profitLossL = (odds - 1) * stake * coefficient;
        break;
    }
  }

  return {
    profitLossW,
    profitLossS,
    profitLossL
  };
};

export const getSpreadsAndTotalsProfitLoss = ({
  price,
  size,
  betType,
  handicapType
}: {
  price: TPrice;
  size: TSize;
  betType: BetSide;
  handicapType?: LineSide | null;
}) => {
  const { odds, stake, coefficient } = getCalculatedParams({ price, size, betType });

  let profitLossW: number | null = null;
  let profitLossL: number | null = null;

  if (price && size) {
    switch (handicapType) {
      case LineSides.UNDER:
        profitLossW = (odds - 1) * stake * coefficient;
        profitLossL = -stake * coefficient;
        break;
      case LineSides.OVER:
        profitLossW = -stake * coefficient;
        profitLossL = (odds - 1) * stake * coefficient;
        break;
    }
  }

  return { profitLossW, profitLossL };
};

/**
 * Get home and away team names from event name
 *
 * @param eventName
 */
export const getTeamsNamesFromEventName = (eventName = '') => {
  let runnersNames: string[] = [];

  if (eventName) {
    const runnersFromEvent = /(.*)\s(?:vs|v|@)\s(.*)/g.exec(eventName);

    if (runnersFromEvent && runnersFromEvent.length === 3) {
      runnersNames = [runnersFromEvent[1], runnersFromEvent[2]];
    }
  }

  return runnersNames;
};

/**
 * Get home and away team names from runners
 *
 * @param runners
 */
export const getTeamsNamesFromRunners = (runners: TBetslipMarketRunner[] = []) => {
  let runnersNames: string[] = [];

  if (runners.length) {
    runnersNames = uniq(
      runners.map(
        runner =>
          runner.runnerName?.replace(
            /([\w\s\(\)]*(?!\s&))\s+([+-]*)([\d\.]*)\d*(\s*&?\s*[+-]?\d*(?:\.)?\d*)?/g,
            '$1'
          ) ?? ''
      )
    );
  }

  return runnersNames;
};

export const getCalculatedParams = ({
  price,
  size,
  handicap,
  betType
}: {
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetSide;
}) => {
  return {
    handicapValue: toNumber(handicap || 0),
    odds: toNumber(price || 0),
    stake: toNumber(size || 0),
    coefficient: betType === BetSides.Lay ? -1 : 1
  };
};
