import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getActiveQuarterLabel } from 'components/EventHeaderBasketball/helper';
import { BASKETBALL_NORMAL_MATCH, BasketballPlayStatuses } from 'constants/app';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataBasketballActiveQuarter,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataTimeElapsed,
  getHomeScoreByEventId
} from 'redux/modules/marketsPrices/selectors';

import styles from './styles.module.scss';

type BasketballMarketScoreProps = {
  isCashOut?: boolean;
  eventId: string;
};

const getShortTimeLabel = (inPlayMatchStatus: string, timeElapsed: number, activeQuarter: number, t: TFunction) => {
  const isOvertime = activeQuarter > BASKETBALL_NORMAL_MATCH;
  switch (inPlayMatchStatus) {
    case BasketballPlayStatuses.FINISH:
      return t('runningBall.basketball.END');
    case BasketballPlayStatuses.FINISH_HALF_END:
      return (
        <>
          <span>{t('runningBall.basketball.H1')}</span>
          <span>{t('runningBall.basketball.HT')}</span>
        </>
      );
    case BasketballPlayStatuses.STOP_QUARTER:
      return t('runningBall.basketball.EOQ');
    default:
      return (
        <>
          <span>{`${isOvertime ? t('runningBall.basketball.OT') : getActiveQuarterLabel(activeQuarter, t)}`}</span>
          <span>{`${timeElapsed}'`}</span>
        </>
      );
  }
};

const BasketballMarketScore = ({ isCashOut, eventId }: BasketballMarketScoreProps) => {
  const { t } = useTranslation();

  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(eventId));
  const timeElapsed = useSelector(getEventsUpdatedDataTimeElapsed(eventId));
  const activeQuarter = useSelector(getEventsUpdatedDataBasketballActiveQuarter(eventId));
  const homeScore = useSelector(getHomeScoreByEventId(eventId));
  const awayScore = useSelector(getAwayScoreByEventId(eventId));

  const isFinished = inPlayMatchStatus === BasketballPlayStatuses.FINISH;
  const title = getShortTimeLabel(inPlayMatchStatus || '', timeElapsed, activeQuarter, t);

  return isCashOut ? (
    <div
      className={classNames('biab_inplay-cell biab_basketball', styles.basketballCashOut, {
        biab_end: isFinished
      })}
    >
      <div className={classNames('biab_time-label', styles.basketballCashOut__label)}>{title}</div>
      <div className="biab_score">
        <div className={classNames('biab_score', styles.basketball__games)}>
          <div className={styles.basketballCashOut__score}>{homeScore}</div>
          <div className={styles.basketballCashOut__score}>{awayScore}</div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={classNames('biab_inplay-cell biab_basketball', styles.basketball, {
        ['biab_end']: isFinished
      })}
    >
      <div className={classNames('biab_time-label', styles.basketball__label)}>{title}</div>
      <div className={classNames('biab_score', styles.basketball__games)}>
        <div className={styles.basketball__score}>{homeScore}</div>
        <div className={styles.basketball__score}>{awayScore}</div>
      </div>
    </div>
  );
};

export default BasketballMarketScore;
