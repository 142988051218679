import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import PopularLinks from 'components/PopularLinks';
import PopularMarket from 'components/PopularMarket';
import Search from 'components/Search';
import SearchInput from 'components/SearchInput';
import { ElementNames } from 'constants/app';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import useElementSize from 'hooks/useElementSize';
import { getMinSearchQueryLength } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';

import styles from './MobileSearchPage.module.scss';

const MobileSearchPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const minSearchQueryLength = useSelector(getMinSearchQueryLength);

  const query = searchParams.get(PARAMS_SEARCH_KEY);
  const showSearch = !!query && query.length >= minSearchQueryLength;

  const [componentRef, { height }] = useElementSize<HTMLDivElement>();
  const footerHeight = useSelector(getElementHeightByName(ElementNames.FOOTER_HEIGHT));
  const mobileHeaderHeight = useSelector(getElementHeightByName(ElementNames.MOBILE_HEADER_HEIGHT));

  useEffect(() => {
    dispatch(
      setElementHeight({
        name: ElementNames.MOBILE_PAGE_HEIGHT,
        height: height + footerHeight + mobileHeaderHeight
      })
    );
  }, [height, footerHeight]);

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.MOBILE_PAGE_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div ref={componentRef}>
      <SearchInput />
      {showSearch && <Search />}
      <PopularLinks listClassName={styles.border} />
      <PopularMarket className={styles.border} applyClassName />
    </div>
  );
};

export default MobileSearchPage;
