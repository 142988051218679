import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import GameLoader from 'components/GameLoader';
import GamesRoundsList from 'components/GamesRoundsList';
import PlayerCardsTable from 'components/PlayerCardsTable';
import { Devices } from 'constants/app';
import { BACCARAT_GAMES_ROUNDS, GamePages, GameTypes } from 'constants/games';
import { MyBetsLocations } from 'constants/myBets';
import {
  getAppDevice,
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { getCurrentGameRound, getCurrentGameRoundPercent, getSelectedGamePage } from 'redux/modules/games/selectors';
import { TGameData } from 'redux/modules/games/type';
import { timeStampToStartDate } from 'utils/date';

import CardCounter from './components/CardCounter';

import styles from './styles.module.scss';

interface BaccaratProps {
  gameData: TGameData[];
  startDate?: number;
}

const Baccarat = ({ gameData, startDate }: BaccaratProps) => {
  const location = useLocation();
  const { t } = useTranslation();

  const selectedGamePage = useSelector(getSelectedGamePage);
  const round = useSelector(getCurrentGameRound);
  const percent = useSelector(getCurrentGameRoundPercent);
  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const isMyBetsPage = location.pathname.includes(MyBetsLocations.MyBets);
  const isResultPage = selectedGamePage === GamePages.RESULTS;
  const gameResultPages = isResultPage || isMyBetsPage;
  const startedParsedDate = timeStampToStartDate(startDate, americanDateFormatEnabled, timezoneCookieEnabled, timezone);
  const isMobile = device === Devices.MOBILE;
  const [Player, Banker, Shoe] = gameData;
  const shoeId = Shoe.properties[0].value;

  const loaderWrapper = () => (
    <div className={styles.loaderWrapper}>
      <GameLoader loaderPercentage={percent || 0} />
    </div>
  );

  const cardsBlock = () => (
    <div className={classNames(styles.rowWrapper)}>
      <PlayerCardsTable gameType={GameTypes.BACCARAT} gameData={Player} currentRound={round} />
      <PlayerCardsTable gameType={GameTypes.BACCARAT} gameData={Banker} currentRound={round} isBanker />
    </div>
  );

  return (
    <div
      className={classNames(styles.baccaratWrapper, {
        'biab_games-wrapper': !gameResultPages,
        [styles.resultsBaccarat]: gameResultPages,
        [styles.myBetsBaccarat]: isMyBetsPage
      })}
    >
      {gameResultPages && (
        <>
          <div className={classNames('biab_games-start-date', styles.title)}>
            {t('games.results.shoeId')}: {shoeId}
          </div>
          <div className={classNames('biab_games-start-date', styles.title)}>
            {t('games.results.started')}: {isMyBetsPage ? <strong>{startedParsedDate}</strong> : startedParsedDate}
          </div>
        </>
      )}
      {isMobile ? (
        <>
          {!isResultPage && (
            <div className={styles.roundAndLoader}>
              <GamesRoundsList gamesRounds={BACCARAT_GAMES_ROUNDS} currentRound={round || 0} />
              {loaderWrapper()}
            </div>
          )}
          {cardsBlock()}
        </>
      ) : (
        <>
          {!gameResultPages && <GamesRoundsList gamesRounds={BACCARAT_GAMES_ROUNDS} currentRound={round || 0} />}
          {cardsBlock()}
          {!gameResultPages && loaderWrapper()}
        </>
      )}
      {!gameResultPages && <CardCounter gameData={Shoe} />}
    </div>
  );
};

export default Baccarat;
