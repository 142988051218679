import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import MyBetsStatusCell from 'components/MyBetsStatusCell';
import { CommissionTypes } from 'constants/app';
import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_OUTRIGHT_MARKET_ID,
  AsianViewMarketLinks,
  AsianViewTimeFilters
} from 'constants/asianView';
import { accountPagesBranding, betslipBranding, mobileComponents, mobileIcons } from 'constants/branding';
import { ASIAN_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { BettingTypes } from 'constants/markets';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetSides } from 'constants/myBets';
import { AVG_PRICE } from 'constants/tooltip';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useTooltip from 'hooks/useTooltip';
import {
  getMobileSettingsAlternativeLayOdds,
  getMobileSettingsLineMarketsSwitchBackLayOnCricket,
  getMobileSettingsReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { TMyBetsContentItem } from 'redux/modules/myBets/type';
import { OfferStates } from 'types/myBets';
import { isLineBettingType } from 'utils/betslip';
import { betsSideValue, getRaceStartTime, oddsValue, roundedPriceLineBet, shouldSwapColors } from 'utils/myBetsValues';
import convertOdds from 'utils/oddsConverter';

import styles from './styles.module.scss';

type TMobileBetDescriptionProps = {
  bet: TMyBetsContentItem;
  isCancelBetError: boolean;
  isCancelBetSuccess: boolean;
};

const MobileBetDescription = ({ bet, isCancelBetSuccess, isCancelBetError }: TMobileBetDescriptionProps) => {
  const { t } = useTranslation();

  const lineMarketsSwitchBackLayOnCricket = useSelector(getMobileSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getMobileSettingsReplaceBackLayWithUnderOver);
  const alternativeLayOdds = useSelector(getMobileSettingsAlternativeLayOdds);
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const isGame = bet.betType === BettingTypes.GAME;
  const isLineMarket = isLineBettingType(bet.bettingType);
  const isBack = bet.side === BetSides.Back;

  const marketUrl = `${SPORT_BASE_URL}/${bet.eventTypeId}/market/${bet.marketId}`;
  const asianViewOutrightMarketUrl = `${ASIAN_BASE_URL}/sport/${bet.eventTypeId}/timeFilter/${
    AsianViewTimeFilters.Today
  }/marketLink/${AsianViewMarketLinks.OUTRIGHT}?${ASIAN_OUTRIGHT_COMPETITION_ID}=${
    bet!.competitionId
  }&${ASIAN_OUTRIGHT_MARKET_ID}=${bet!.marketId}&page=0`;

  const score = bet.marketType === MARKET_TYPES.asianHandicap && bet.score ? ` (${bet.score})` : '';
  const selectionNameTpl = `${bet.selectionName} ${isLineMarket ? roundedPriceLineBet(bet) : ''}${score}`;

  const marketNameTpl = bet.raceName
    ? `${getRaceStartTime(bet.marketStartDate)} ${bet.raceName} - ${bet.marketName}`
    : bet.marketNameWithParents || bet.marketName;

  const isMatched = bet.offerState === OfferStates.MATCHED;
  const isUnmatched = bet.offerState === OfferStates.PLACED;
  const isCurrent = isMatched || isUnmatched;

  const { translationKey } = useTooltip(AVG_PRICE);
  const tooltipValue = oddsValue(bet, { isRounded: false });

  const backColors = shouldSwapColors(bet, swapColorsFancyMarketsOnCricket) ? !isBack : isBack;
  const layColors = shouldSwapColors(bet, swapColorsFancyMarketsOnCricket) ? isBack : !isBack;

  const renderOdds = () => {
    if (bet.asian) {
      return `@${convertOdds(oddsValue(bet) || '', bet.oddsType)} (${t(
        `asianView.labels.betslip.oddsType.${bet?.oddsType}`
      )})`;
    }

    return (
      <>
        @{oddsValue(bet, { isRounded: true })}
        {alternativeLayOdds && (
          <i
            onClick={e => e.preventDefault()}
            data-tooltip-id="tooltip"
            data-tooltip-html={unescape(t(translationKey, { N: tooltipValue }))}
            data-tooltip-class-name={styles.tooltip}
            className={classNames('biab_custom-icon-info-24', styles.icon, mobileIcons.TOOLTIP)}
          >
            <span className={classNames('path1', styles.iconBg, mobileIcons.TOOLTIP_BG)} />
            <span className={classNames('path2', mobileIcons.TOOLTIP_INNER)} />
            <span className={classNames('path3', mobileIcons.TOOLTIP_INNER)} />
          </i>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.descriptionField, accountPagesBranding.BET_INDICATOR, {
          [styles.descriptionField__back]: backColors,
          [styles.descriptionField__lay]: layColors,
          [accountPagesBranding.BET_SIDE_BACK]: backColors,
          [accountPagesBranding.BET_SIDE_LAY]: layColors
        })}
      >
        <div className={styles.infoWrap}>
          <span className={styles.selectionName}>{selectionNameTpl}</span>
          {isGame ? (
            <span className={styles.marketInfo}>
              {t('games.gameId')}: {bet.marketId}
            </span>
          ) : (
            <>
              {isCurrent && (!bet.asian || bet.outright) ? (
                <Link to={bet.asian ? asianViewOutrightMarketUrl : marketUrl}>
                  <span>{marketNameTpl}</span>
                </Link>
              ) : (
                <span className={styles.marketInfo}>{marketNameTpl}</span>
              )}
            </>
          )}
          {bet.marketType === MARKET_TYPES.eachWay && (
            <span className={styles.terms}>
              {t('market.each.way.termsNoPref', { odds: bet.eachWayDivisor, places: bet.numberOfWinners })}
            </span>
          )}
          <MyBetsStatusCell
            offerState={isCancelBetSuccess ? OfferStates.CANCELLED : bet.offerState}
            isAsian={bet.asian}
            isPNC={bet.commissionType === CommissionTypes.PRICES}
          />
          {bet.triggeredByCashOut && (
            <span className={classNames(styles.cashOutLabel, accountPagesBranding.CASH_OUT_LABEL)}>
              {t('account.myBets.labels.cashOut')}
            </span>
          )}
        </div>
        <div className={styles.oddsWrap}>
          <span className={styles.odds}>{renderOdds()}</span>
          <span className={styles.betSide}>
            {t(
              betsSideValue({
                item: bet!,
                lineMarketsSwitchBackLayOnCricket,
                replaceBackLayWithUnderOver,
                extendedLabel: true
              })
            )}
          </span>
        </div>
      </div>
      {isUnmatched && isCancelBetError && (
        <div className={classNames(styles.error, mobileComponents.NOTIFICATION, mobileComponents.ERROR)}>
          <i
            className={classNames('biab_custom-icon-warning-circle', styles.error__icon, betslipBranding.WARNING_ICON)}
          />
          <p
            className={styles.error__msg}
            dangerouslySetInnerHTML={{ __html: unescape(t('error.code.EX005.message')) }}
          />
        </div>
      )}
      {isUnmatched && isCancelBetSuccess && (
        <div className={classNames(styles.success, mobileComponents.NOTIFICATION, mobileComponents.INFO)}>
          <i className={classNames('biab_custom-icon-info-circle', styles.success__icon, betslipBranding.INFO_ICON)} />
          <p className={styles.success__text}>{t('betslip.messages.cancelledPlacedBet')}</p>
        </div>
      )}
    </div>
  );
};

export default MobileBetDescription;
