import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import MarketsTable from 'components/MarketsTable';
import SportSectionTitle from 'components/SportPageModule/components/SportPageSportSection/partials/SportSectionTitle';
import { CookieNames, Devices, GeneralWebSocketSubscriptionTypes, PageBlocks, ViewsBy } from 'constants/app';
import { TYPE_TO_URL } from 'constants/competitions';
import { TimeFilters } from 'constants/competitions';
import { MarketsTableColumns } from 'constants/markets';
import { timeSections } from 'constants/sportPage';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import EventsUpdatesInjection from 'injections/EventsUpdatesInjection';
import {
  getAppDevice,
  getDesktopCompetitionPageSize,
  getTimezone,
  getTimezoneCookieEnabled,
  getTranslation
} from 'redux/modules/appConfigs/selectors';
import { getCompetitionData } from 'redux/modules/competitions/selectors';
import { resetSuspendedOrClosedMarketPrices } from 'redux/modules/marketsPrices';
import { getClosedMarketPrices, getInPlayMarketsIds } from 'redux/modules/marketsPrices/selectors';
import { fetchSportPageDetails, fetchSportPageMarketRules, removeSportPageDetails } from 'redux/modules/sportPage';
import {
  getSportPageLoading,
  getSportPageMarketEventIds,
  getSportPageMarkets,
  getSportPageMarketsInfo,
  getSportPageMarketsRules,
  getSportPageRulesLoading,
  getSportPageSportInfo
} from 'redux/modules/sportPage/selectors';
import { SportPageContextFilter } from 'redux/modules/sportPage/type';
import { PlacementPage, TimeFilter, ViewBy } from 'types';
import { IMarket, MarketsTableColumn } from 'types/markets';
import { getCompetitionSections, getTableMarketsData, getTimeSections, parseMarketStartTime } from 'utils/sportPage';

import SportPageSportSection from '../SportPageSportSection';

import styles from './styles.module.scss';

interface SportPageSportsSectionsProps {
  /**
   * 'View by' sort value
   */
  viewBy: ViewBy;

  /**
   * Current time filter for data on sport page
   */
  timeFilter: TimeFilter;

  /**
   * Current page for infinite scroll
   */
  page: number;

  /**
   * Set state action for current page
   */
  setPage: Dispatch<SetStateAction<number>>;

  /**
   * Current page request data id
   */
  pageDetailsId: string;

  /**
   * Current page request data contextFilter
   */
  contextFilter: SportPageContextFilter;
  placementPage: PlacementPage;
}

const tables = ['inplay.labels.inPlay', 'inplay.labels.comingUp'];

const SportPageSportsSections = ({
  viewBy,
  timeFilter,
  page,
  setPage,
  pageDetailsId,
  contextFilter,
  placementPage
}: SportPageSportsSectionsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sportId } = useParams();
  const [cookies, setCookie] = useCookies([CookieNames.COMPETITION_COLLAPSED]);

  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);
  const domainLanguage = useSelector(getTranslation);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const desktopCompetitionPageSize = useSelector(getDesktopCompetitionPageSize);
  const loading = useSelector(getSportPageLoading);
  const rulesLoading = useSelector(getSportPageRulesLoading);
  const markets = useSelector(getSportPageMarkets);
  const marketsRules = useSelector(getSportPageMarketsRules);
  const sportInfo = useSelector(getSportPageSportInfo);
  const marketEventIds = useSelector(getSportPageMarketEventIds);
  const marketsInfo = useSelector(getSportPageMarketsInfo);
  const competitionData = useSelector(getCompetitionData);
  const tablesContainerRef = useRef<HTMLDivElement>(null);
  const inPlayMarketIds = useSelector(getInPlayMarketsIds);
  const closedMarketPrices = useSelector(getClosedMarketPrices);

  const collapsedList: string[] = cookies.BIAB_COMPETITION_COLLAPSED?.split(',') ?? [];
  const mobileHiddenHeaderColumns =
    device === Devices.MOBILE ? [MarketsTableColumns.MATCHED, MarketsTableColumns.RULES] : [];

  const betGroupsCount = useMemo(() => (markets?.some(({ runners }) => runners.length === 3) ? 3 : 2), [markets]);
  const marketsWithParsedDate: IMarket[] | null = useMemo(() => {
    if (markets) {
      return markets.map(market => ({
        ...market,
        parsedMarketStartTime: parseMarketStartTime(market.marketStartTime, timezone, timezoneCookieEnabled)
      }));
    }

    return null;
  }, [markets, timezone, timezoneCookieEnabled]);

  const infiniteScrollCallback = useCallback(() => {
    if (typeof marketsInfo?.last === 'boolean' && !marketsInfo.last) {
      setPage(prevPage => prevPage + 1);
    }
  }, [marketsInfo?.last, sportId]);

  const { lastElementRef } = useInfiniteScroll<HTMLDivElement>({ callback: infiniteScrollCallback });

  const handleCollapse = (isOpened: boolean, value: string) => {
    let newList: string[];

    if (isOpened) {
      newList = collapsedList.filter((item: string) => item !== value);
    } else {
      newList = [...collapsedList, value];
    }

    setCookie(CookieNames.COMPETITION_COLLAPSED, newList.join(), { path: '/' });
  };

  const handleFetchMarketRules = useCallback(
    (marketId: string) => {
      dispatch(fetchSportPageMarketRules(marketId));
    },
    [dispatch]
  );

  useEffect(() => {
    if (page !== 0) {
      dispatch(
        fetchSportPageDetails({
          page,
          size: device === Devices.DESKTOP ? desktopCompetitionPageSize : undefined,
          data: { viewBy, timeFilter, id: pageDetailsId, contextFilter },
          concatMarkets: true
        })
      );
    }
  }, [page, device, sportId]);

  useEffect(() => {
    if (closedMarketPrices && markets?.length) {
      const updatedMarkets = markets.filter(({ marketId }) => marketId !== closedMarketPrices.id);
      dispatch(resetSuspendedOrClosedMarketPrices());
      dispatch(removeSportPageDetails(updatedMarkets));
    }
  }, [closedMarketPrices, markets]);

  if (loading && page === 0) {
    return <Loader circleColor={CircleColors.BLACK} />;
  } else if (!markets?.length) {
    if (
      device === Devices.MOBILE &&
      (marketsInfo?.content.length || competitionData.some(i => TYPE_TO_URL[i.type] === TYPE_TO_URL.MARKET))
    ) {
      return null;
    }

    return (
      <>
        <p className={styles.noMarkets}>{t('competition.view.labels.noData')}</p>
        <hr className={styles.separator} />
      </>
    );
  }

  const getSections = () => {
    const defaultHiddenColumns: MarketsTableColumn[] = [
      MarketsTableColumns.MOBILE_SPORT_ICON,
      MarketsTableColumns.DATE_AND_MATCHED
    ];
    const hiddenMatched: MarketsTableColumn[] =
      viewBy !== ViewsBy.POPULARITY && device === Devices.MOBILE ? [MarketsTableColumns.MATCHED] : [];
    const hiddenColumns = defaultHiddenColumns.concat(hiddenMatched);

    const commonProps = {
      tables,
      onFetchMarketRules: handleFetchMarketRules,
      mobileHiddenHeaderColumns,
      className: 'biab_inplay-sport-item-title',
      collapseClasses: {
        container: styles.collapse__container,
        toggle: styles.collapse__toggle,
        arrow: classNames('biab_expand', styles.collapse__toggle__arrow)
      },
      hiddenColumns,
      betGroupsCount,
      page: placementPage
    };

    if (viewBy === ViewsBy.COMPETITION) {
      const sections = getCompetitionSections(markets);

      return sections.map(competitionName => {
        const competitionMarkets = markets.filter(({ competition }) => competition.name === competitionName);
        const title = competitionMarkets[0].competition.competitionNameTranslations?.[domainLanguage] ?? '';
        const tableCompetitionId = competitionMarkets[0].competition.id;
        const isNotCollapsed = !collapsedList.includes(tableCompetitionId);

        return (
          <SportPageSportSection
            key={competitionName}
            markets={competitionMarkets}
            title={title}
            defaultIsOpened={isNotCollapsed}
            onCollapse={(isOpened: boolean) => handleCollapse(isOpened, tableCompetitionId)}
            firstMarketId={markets[0]?.marketId}
            {...commonProps}
          />
        );
      });
    } else if (viewBy === ViewsBy.TIME && (timeFilter === TimeFilters.ALL || timeFilter === TimeFilters.FUTURE)) {
      const sections = getTimeSections(marketsWithParsedDate);
      return sections.map(date => {
        const [month, day, year] = date.split('-');
        const timeMarkets =
          marketsWithParsedDate?.filter(({ parsedMarketStartTime }) => parsedMarketStartTime === date) ?? [];
        const collapseValue = `${year}_${month}_${day}`;
        const isNotCollapsed = !collapsedList.includes(collapseValue);

        return (
          <SportPageSportSection
            key={date}
            markets={timeMarkets}
            title={<SportSectionTitle parsedStartTime={date} />}
            defaultIsOpened={isNotCollapsed}
            onCollapse={(isOpened: boolean) => handleCollapse(isOpened, collapseValue)}
            firstMarketId={markets[0]?.marketId}
            {...commonProps}
          />
        );
      });
    }

    return (
      <div ref={tablesContainerRef}>
        {tables.map((title, index) => {
          const tableMarkets = getTableMarketsData(markets, inPlayMarketIds, index);

          return (
            <MarketsTable
              timeSection={timeSections[index]}
              key={title}
              headerTitle={t(title)}
              markets={tableMarkets}
              onFetchMarketRules={handleFetchMarketRules}
              marketsRules={marketsRules}
              betGroupsCount={betGroupsCount}
              rulesLoading={rulesLoading}
              sportInfo={sportInfo}
              pageBlock={PageBlocks.SPORT}
              hiddenColumns={hiddenColumns}
              hiddenHeaderColumns={mobileHiddenHeaderColumns}
              showHeaderForMobile
              containerRef={tablesContainerRef}
              mobileBetting
              classes={{ header: classNames({ 'biab_group-markets-table-header': device === Devices.MOBILE }) }}
              page={placementPage}
              firstMarketId={markets[0]?.marketId}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      {!!marketEventIds?.length && (
        <EventsUpdatesInjection
          eventIds={marketEventIds}
          subscriptionType={GeneralWebSocketSubscriptionTypes.eventInfoUpdates}
        />
      )}
      <div className="biab_inplay-sports-container inPlaySportsContainer">
        <div
          className={classNames('biab_group-markets', {
            [styles.noSectionsContainer]:
              viewBy === ViewsBy.POPULARITY ||
              (viewBy === ViewsBy.TIME && timeFilter !== TimeFilters.ALL && timeFilter !== TimeFilters.FUTURE)
          })}
        >
          {getSections()}
          {!loading && <div ref={lastElementRef} />}
        </div>
      </div>
      {loading && page !== 0 && <Loader circleColor={CircleColors.BLACK} />}
    </>
  );
};

export default SportPageSportsSections;
