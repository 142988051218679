import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ResponsibleBettingCreateLimit from 'components/ResponsibleBetting/components/ResponsibleBettingCreateLimit';
import ResponsibleBettingRemoveLimit from 'components/ResponsibleBetting/components/ResponsibleBettingRemoveLimit';
import { ResponsibleBettingSection } from 'components/ResponsibleBetting/components/ResponsibleBettingSection';
import ResponsibleBettingTimeLimitView from 'components/ResponsibleBetting/components/ResponsibleBettingTimeLimitView';
import ResponsibleBettingUpdateLimit from 'components/ResponsibleBetting/components/ResponsibleBettingUpdateLimit';
import {
  SELF_EXCLUSION_MIN_HOURS,
  TIME_LIMIT_MAX_HOURS,
  UpdatedResponsibleBettingDisplayedContents
} from 'constants/responsibleBetting';
import useDevice from 'hooks/useDevice';
import {
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { updateResponsibleBettingSettings } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';
import { ResponsibleBettingTab, ResponsibleBettingTimeUnitKeys } from 'types/responsibleBetting';
import { addDay, getLongDateFormat } from 'utils/date';
import { convertTimeUnitsToDropdown, getIsLimitLessStrict, getTimeUnitKey } from 'utils/responsibleBetting';

interface ResponsibleBettingTimeLimitProps {
  tab: ResponsibleBettingTab;
}

const ResponsibleBettingTimeLimit = ({ tab }: ResponsibleBettingTimeLimitProps) => {
  const dispatch = useDispatch();

  const { isMobile } = useDevice();
  const { t } = useTranslation();

  const [isOpened, setIsOpened] = useState(false);
  const [isUpdatingMode, setIsUpdatingMode] = useState(false);
  const [isRemovalMode, setIsRemovalMode] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isApplyingMode, setIsApplyingMode] = useState(false);

  const rbSettings = useSelector(getResponsibleBettingSettings);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const { timeLimit = null, timeLimitTimeUnit = null, timeLimitEndDate = 0 } = rbSettings?.rgTools || {};

  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const [selectedLimit, setSelectedLimit] = useState<number | null>(timeLimit);
  const [selectedTimeUnit, setSelectedTimeUnit] = useState<CombinedResponsibleBettingTimeUnit>(timeLimitTimeUnit);

  const timeLimitUnits = convertTimeUnitsToDropdown(t);

  const validateLimitInput = (limit: number, timeUnit: CombinedResponsibleBettingTimeUnit) => {
    const maxHours = TIME_LIMIT_MAX_HOURS[timeUnit as ResponsibleBettingTimeUnitKeys];

    if (limit < SELF_EXCLUSION_MIN_HOURS) {
      setError(
        t('responsibleBetting.validation.minNumber', {
          N: `<strong>${SELF_EXCLUSION_MIN_HOURS}</strong>`,
          interpolation: { escapeValue: false }
        })
      );
      return;
    } else if (timeUnit && limit > maxHours) {
      setError(
        t('responsibleBetting.validation.maxNumber', {
          N: `<strong>${maxHours}</strong>`,
          interpolation: { escapeValue: false }
        })
      );
      return;
    }

    setError(null);
  };

  const initializeSelectedLimit = () => {
    setSelectedLimit(timeLimit);
    setSelectedTimeUnit(timeLimitTimeUnit);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const limit = +event.target.value;
    setSelectedLimit(limit);

    validateLimitInput(limit, selectedTimeUnit);
  };

  const resetInputs = () => {
    setSelectedLimit(null);
    setSelectedTimeUnit(null);
  };

  const handleOnCreateApply = () => {
    if (rbSettings && selectedTimeUnit && selectedLimit) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            timeLimitEnabled: true,
            timeLimit: selectedLimit,
            timeLimitTimeUnit: selectedTimeUnit,
            type: UpdatedResponsibleBettingDisplayedContents.TimeLimit
          },
          type: UpdatedResponsibleBettingDisplayedContents.TimeLimit
        })
      );
    }
  };

  const handleOnCreateCancel = () => {
    if (isUpdatingMode) {
      setIsUpdatingMode(false);
    } else {
      setIsOpened(false);
    }

    resetInputs();
    setError(null);
  };

  const handleOnUpdateApply = () => {
    if (!isApplyingMode) {
      setIsApplyingMode(true);
      return;
    }
    if (rbSettings) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            timeLimitEnabled: true,
            timeLimit: selectedLimit || timeLimit,
            timeLimitTimeUnit: selectedTimeUnit || timeLimitTimeUnit,
            type: UpdatedResponsibleBettingDisplayedContents.TimeLimit
          },
          type: UpdatedResponsibleBettingDisplayedContents.TimeLimit
        })
      );
    }
    initializeSelectedLimit();
    setIsApplyingMode(false);
    setIsUpdatingMode(false);
  };

  const handleOnUpdateCancel = () => {
    if (isApplyingMode) {
      setIsApplyingMode(false);
    } else {
      initializeSelectedLimit();
      setError(null);
      setIsUpdatingMode(false);
    }
  };

  const handleOnRemoveCancel = () => {
    setIsRemovalMode(false);
  };

  const handleOnRemoveApply = () => {
    if (rbSettings) {
      dispatch(
        updateResponsibleBettingSettings({
          rgTools: {
            ...rbSettings.rgTools,
            timeLimitEnabled: false,
            type: UpdatedResponsibleBettingDisplayedContents.TimeLimit
          },
          type: UpdatedResponsibleBettingDisplayedContents.TimeLimit
        })
      );
    }
    setIsRemovalMode(false);
  };

  const handleOnSelectOption = (option: DropdownItem) => {
    const newTimeUnit = selectedTimeUnit === option.value ? null : (option.value as CombinedResponsibleBettingTimeUnit);
    setSelectedTimeUnit(newTimeUnit);
    if (selectedLimit && newTimeUnit) {
      validateLimitInput(selectedLimit, newTimeUnit);
    }
  };

  const confirmationMessage = useMemo(() => {
    const isLessStrict = getIsLimitLessStrict(timeLimit, timeLimitTimeUnit, selectedLimit, selectedTimeUnit);
    const newTimeLimitDate = addDay(new Date(timeLimitEndDate ?? 0));

    const formattedDate = getLongDateFormat(
      newTimeLimitDate,
      t,
      timezone,
      timezoneCookieEnabled,
      americanDateFormatEnabled
    );

    if (isRemovalMode) {
      return `${t('responsibleBetting.message.TIME_LIMIT_REMOVAL', {
        estimated_date_of_the_limit_removal: formattedDate
      })}`;
    }

    return isLessStrict
      ? t('responsibleBetting.message.TIME_LIMIT_UPDATE', {
          ['estimated_date_of the_limit_change']: formattedDate
        })
      : undefined;
  }, [timeLimit, timeLimitTimeUnit, selectedLimit, selectedTimeUnit, isRemovalMode]);

  return (
    <ResponsibleBettingSection tab={tab} onClick={() => setIsOpened(true)} isOpened={isOpened || !!timeLimit}>
      <>
        {isOpened && !timeLimit && (
          <ResponsibleBettingCreateLimit
            isMobile={isMobile}
            error={error}
            selectedTimeUnit={selectedTimeUnit}
            selectedLimit={selectedLimit}
            inputLabel={t('responsibleBetting.labels.limitHours')}
            inputPlaceholder={t('responsibleBetting.placeholders.enterAmount')}
            dropdownLabel={t('responsibleBetting.labels.limitPeriod')}
            data={timeLimitUnits}
            onChange={handleOnChange}
            onCancel={handleOnCreateCancel}
            onApply={handleOnCreateApply}
            onSelectOption={handleOnSelectOption}
          />
        )}
        {timeLimit && isUpdatingMode && (
          <ResponsibleBettingUpdateLimit
            isMobile={isMobile}
            isApplyingMode={isApplyingMode}
            error={error}
            currentTimeUnit={timeLimitTimeUnit}
            currentLimit={timeLimit}
            newLimit={selectedLimit}
            newLimitTimeUnit={selectedTimeUnit}
            onChange={handleOnChange}
            onCancel={handleOnUpdateCancel}
            onApply={handleOnUpdateApply}
            onSelectOption={handleOnSelectOption}
            confirmationMessage={confirmationMessage}
            inputLabel={t('responsibleBetting.labels.limitHours')}
            newLimitAppliedFieldValue={`${selectedLimit}`}
            currentLimitComparedFieldLabel={`${timeLimit} ${t('responsibleBetting.labels.hours')} ${t(
              getTimeUnitKey(timeLimitTimeUnit)
            )}`}
            newLimitComparedFieldLabel={`${selectedLimit} ${t('responsibleBetting.labels.hours')} ${t(
              getTimeUnitKey(selectedTimeUnit)
            )}`}
            data={timeLimitUnits}
          />
        )}
        {timeLimit && !isUpdatingMode && !isRemovalMode && (
          <ResponsibleBettingTimeLimitView
            onEdit={() => {
              setIsUpdatingMode(true);
              initializeSelectedLimit();
            }}
            onRemove={() => setIsRemovalMode(true)}
          />
        )}
        {timeLimit && isRemovalMode && (
          <ResponsibleBettingRemoveLimit
            isMobile={isMobile}
            onCancel={handleOnRemoveCancel}
            onApply={handleOnRemoveApply}
            confirmationMessage={confirmationMessage}
            currentLimitValue={`${timeLimit} ${t('responsibleBetting.labels.hours')} ${t(
              getTimeUnitKey(timeLimitTimeUnit)
            )}`}
          />
        )}
      </>
    </ResponsibleBettingSection>
  );
};

export default ResponsibleBettingTimeLimit;
