import { Link, To } from 'react-router-dom';
import classNames from 'classnames';

import Icon from 'components/Icon';

import styles from './styles.module.scss';

interface BackButtonProps {
  url: To;
  icon: string;
  className?: string;
  onClick?: () => void;
}

const IconLinkButton = ({ url, icon, className, onClick }: BackButtonProps) => {
  return (
    <Link to={url} className={classNames(styles.button, className)} onClick={onClick}>
      <Icon iconClass={icon} className={styles.icon} />
    </Link>
  );
};

export default IconLinkButton;
