import RGModalMessage from 'components/Betslip/components/RGModalMessage';
import { PageBlocks } from 'constants/app';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { PageBlock, PlacementPage } from 'types';

import InlinePlacementItem from './components/InlinePlacementItem';

type TInlinePlacementProps = {
  bets: TInlineSelectedBet[];
  pageBlock?: PageBlock;
  page?: PlacementPage;
  isPopularMarketsOdds?: boolean;
};

const InlinePlacement = ({ bets, page, isPopularMarketsOdds, pageBlock = PageBlocks.HOME }: TInlinePlacementProps) => {
  return (
    <>
      {bets.map(bet => (
        <InlinePlacementItem
          bet={bet}
          page={page}
          pageBlock={pageBlock}
          isPopularMarketsOdds={isPopularMarketsOdds}
          key={bet.betUuid}
        />
      ))}
      <RGModalMessage />
    </>
  );
};

export default InlinePlacement;
