import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import SortingField from 'components/BetsTable/components/SortingField';
import Skeleton from 'components/Skeleton';
import { ElementNames } from 'constants/app';
import { AccountStatementSortByFields, MY_BETS_CONTENT_SM_WIDTH_DESKTOP, SEPARATE_WALLET } from 'constants/myBets';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import {
  getDesktopSettingsAlternativeLayOdds,
  getWalletIntegrationType,
  getWindowWidth
} from 'redux/modules/appConfigs/selectors';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { setAccountStatementSort } from 'redux/modules/myBets';
import {
  getAccountStatementSort,
  getAccountStatementSortByName,
  getStatementLoading
} from 'redux/modules/myBets/selectors';
import { TStatementContentItem } from 'redux/modules/myBets/type';
import { AccountStatementSortByField, AccountStatementTableColumn } from 'types/myBets';

import AccountStatementTableRow from '../AccountStatementTableRow/AccountStatementTableRow';

import styles from './AccountStatementTableDesktopContent.module.scss';

type AccountStatementTableDesktopContentProps = {
  isAsianViewTable: boolean;
  content: TStatementContentItem[];
};

const AccountStatementTableDesktopContent = ({
  isAsianViewTable,
  content
}: AccountStatementTableDesktopContentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const walletIntegrationType = useSelector(getWalletIntegrationType);
  const alternativeLayOdds = useSelector(getDesktopSettingsAlternativeLayOdds);
  const placedSortingValue = useSelector(getAccountStatementSortByName(AccountStatementSortByFields.PLACED_DATE));
  const settledSortingValue = useSelector(getAccountStatementSortByName(AccountStatementSortByFields.SETTLED_DATE));
  const sorting = useSelector(getAccountStatementSort);
  const isLoading = useSelector(getStatementLoading);
  const headerHeight = useSelector(getElementHeightByName(ElementNames.HEADER_HEIGHT));
  const width = useSelector(getWindowWidth);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const { refreshData } = useMyBetsPagesNavigation();

  const stickyRef = useRef<null | HTMLDivElement>(null);
  const isStickiedRef = useRef(false);
  const headerHeightRef = useRef(headerHeight);
  const [isHeaderStickied, setIsHeaderStickied] = useState(false);

  headerHeightRef.current = headerHeight;

  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        const newValue = stickyRef.current.getBoundingClientRect().top <= headerHeightRef.current;

        if (newValue !== isStickiedRef.current) {
          isStickiedRef.current = newValue;
          setIsHeaderStickied(newValue);
        }
      }
    };

    const scrollableContent = document.getElementById('my-bets-pages-content');

    if (scrollableContent) {
      scrollableContent.addEventListener('scroll', handleScroll);
      return () => {
        scrollableContent.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const desktopHeaderCells: AccountStatementTableColumn[] = [
    {
      id: 'refId',
      labelKey: 'account.statement.labels.id',
      isVisible: width >= MY_BETS_CONTENT_SM_WIDTH_DESKTOP,
      className: styles.tableHeader__item__60,
      right: false
    },
    {
      id: 'settledDate',
      labelKey: 'account.statement.labels.settled',
      isVisible: true,
      className: styles.tableHeader__item__80,
      right: false,
      sortName: AccountStatementSortByFields.SETTLED_DATE,
      sortingValue: sorting ? settledSortingValue : settledSortingValue ?? 'desc',
      type: 'date'
    },
    {
      id: 'placedDate',
      labelKey: 'account.statement.labels.placed',
      isVisible: true,
      className: styles.tableHeader__item__80,
      right: false,
      sortName: AccountStatementSortByFields.PLACED_DATE,
      sortingValue: placedSortingValue ?? null,
      type: 'date'
    },
    {
      id: 'description',
      labelKey: 'account.statement.labels.description',
      isVisible: true,
      className: styles.tableHeader__item__description,
      right: false
    },
    {
      id: 'side',
      labelKey: 'account.mybets.labels.type',
      isVisible: width >= MY_BETS_CONTENT_SM_WIDTH_DESKTOP,
      className: styles.tableHeader__item__40,
      right: false
    },
    {
      id: 'oddsType',
      labelKey: 'account.statement.labels.oddsType',
      isVisible: isAsianViewTable,
      className: styles.tableHeader__item__40,
      right: false
    },
    {
      id: 'avgPriceRounded',
      labelKey: 'account.statement.labels.odds',
      isVisible: isAsianViewTable || !alternativeLayOdds,
      className: styles.tableHeader__item__40,
      right: true,
      type: 'oddsTooltip'
    },
    {
      id: 'alternativeBackOddsRounded',
      labelKey: 'account.statement.labels.backOdds',
      isVisible: !isAsianViewTable && alternativeLayOdds,
      className: styles.tableHeader__item__60,
      right: true,
      type: 'avgBackOdds'
    },
    {
      id: 'avgPriceRounded',
      labelKey: 'account.statement.labels.layOdds',
      isVisible: !isAsianViewTable && alternativeLayOdds,
      className: styles.tableHeader__item__60,
      right: true,
      type: 'avgLayOdds'
    },
    {
      id: 'size',
      labelKey: 'account.statement.labels.stake',
      isVisible: true,
      className: styles.tableHeader__item__80,
      right: true
    },
    {
      id: 'liability',
      labelKey: 'account.statement.labels.risk',
      isVisible: isAsianViewTable,
      className: styles.tableHeader__item__80,
      right: true
    },
    {
      id: 'credit',
      labelKey: 'account.statement.labels.balanceChange',
      isVisible: true,
      className: styles.tableHeader__item__80,
      right: true
    },
    {
      id: 'balance',
      labelKey: 'account.statement.labels.balance',
      isVisible: walletIntegrationType === SEPARATE_WALLET,
      className: styles.tableHeader__item__80,
      right: true
    },
    {
      id: 'status',
      labelKey: 'account.statement.labels.status',
      isVisible: width >= MY_BETS_CONTENT_SM_WIDTH_DESKTOP,
      className: styles.tableHeader__item__status,
      right: false
    }
  ];

  const handleSort = ({ sortBy, value }: { sortBy: string; value: 'asc' | 'desc' }) => {
    dispatch(setAccountStatementSort({ key: sortBy as AccountStatementSortByField, value }));
    refreshData({
      sortBy: {
        [sortBy]: value
      }
    });
  };

  return (
    <>
      {!isAsianViewTable && alternativeLayOdds && (
        <p className={styles.alternativeLayOdds}>{t('account.mybets.messages.alternativeLayOdds')}</p>
      )}
      <div ref={stickyRef} className={styles.tableHeader__wrapper}>
        <div className={styles.tableHeader__whiteSpace} />
        <div
          className={classNames(styles.tableHeader, {
            [styles.tableHeader__stickied]: isHeaderStickied,
            [styles.tableHeader__less1200]: width < MY_BETS_CONTENT_SM_WIDTH_DESKTOP
          })}
          role="rowheader"
        >
          {desktopHeaderCells.map(({ isVisible, labelKey, className, right, sortName, sortingValue, id }) => {
            if (isVisible) {
              if (sortName) {
                return (
                  <SortingField
                    key={id}
                    sortBy={sortName}
                    sortingValue={sortingValue ?? null}
                    onSorting={handleSort}
                    isDisabled={isLoading || !content.length}
                    isInactive={
                      sortName === AccountStatementSortByFields.PLACED_DATE
                        ? !!settledSortingValue || !sorting
                        : !!placedSortingValue
                    }
                    className={styles.tableHeader__item__80}
                  >
                    {t(labelKey)}
                  </SortingField>
                );
              }

              return (
                <p
                  key={id}
                  className={classNames(styles.tableHeader__item, className, {
                    [styles.tableHeader__item__right]: right
                  })}
                >
                  {t(labelKey)}
                </p>
              );
            }

            return null;
          })}
        </div>
      </div>
      {isLoading ? (
        <StatementSkeleton />
      ) : content?.length ? (
        <>
          {content.map(item => {
            return (
              <AccountStatementTableRow
                key={item.refId}
                columns={desktopHeaderCells}
                data={item}
                isMultiCurrencySupported={isMultiCurrencySupported}
                isWidthLessThan1200={width < MY_BETS_CONTENT_SM_WIDTH_DESKTOP}
              />
            );
          })}
        </>
      ) : (
        <p className={styles.noData}>{t('account.mybets.messages.noData')}</p>
      )}
    </>
  );
};

export default AccountStatementTableDesktopContent;

const skeletons = [60, 80, 80, 200];

function StatementSkeleton() {
  return (
    <>
      {new Array(4).fill(0).map((_, index) => {
        return (
          <div key={index} className={styles.skeleton__container}>
            {skeletons.map((width, itemIndex) => {
              return (
                <Skeleton
                  key={itemIndex}
                  className={classNames(styles.skeleton__item, {
                    [styles.skeleton__item__60]: width === 60,
                    [styles.skeleton__item__80]: width === 80,
                    [styles.skeleton__item__200]: width === 200
                  })}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
}
