import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { getSoccerLabel } from 'components/MarketsTable/components/MarketsTableRow/components/InPlayCell/components/SoccerMarketScore';
import MatchStatistics from 'components/MatchStatistics';
import VideoStream from 'components/VideoStream';
import { SportIds } from 'constants/app';
import { IconsConfig } from 'constants/iconsConfig';
import { SPORT_BASE_URL } from 'constants/locations';
import { removeEventUpdatedDataByEventId } from 'redux/modules/marketsPrices';
import {
  getAwayScoreByEventId,
  getEventsUpdatedDataElapsedAddedTime,
  getEventsUpdatedDataElapsedRegularTime,
  getEventsUpdatedDataInPlayMatchStatus,
  getEventsUpdatedDataPeriod,
  getEventUpdatedDataLocalizedAwayName,
  getEventUpdatedDataLocalizedHomeName,
  getHomeScoreByEventId,
  getIsEventsUpdatedDataByEventId
} from 'redux/modules/marketsPrices/selectors';
import { IMarket } from 'types/markets';

import styles from './styles.module.scss';

interface TitleMarketProps {
  market: IMarket;
}

const TitleMarketMobile = ({ market }: TitleMarketProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isEventUpdatedData = useSelector(getIsEventsUpdatedDataByEventId(market?.event?.id));
  const elapsedRegularTime = useSelector(getEventsUpdatedDataElapsedRegularTime(market?.event?.id));
  const elapsedAddedTime = useSelector(getEventsUpdatedDataElapsedAddedTime(market?.event?.id));
  const inPlayMatchStatus = useSelector(getEventsUpdatedDataInPlayMatchStatus(market?.event?.id));
  const homeName = useSelector(getEventUpdatedDataLocalizedHomeName(market?.event?.id));
  const awayName = useSelector(getEventUpdatedDataLocalizedAwayName(market?.event?.id));
  const homeScore = useSelector(getHomeScoreByEventId(market?.event?.id));
  const awayScore = useSelector(getAwayScoreByEventId(market?.event?.id));
  const period = useSelector(getEventsUpdatedDataPeriod(market?.event?.id));

  const score = isEventUpdatedData
    ? getSoccerLabel({
        t,
        elapsedRegularTime,
        elapsedAddedTime,
        inPlayMatchStatus,
        period
      })
    : '';

  useEffect(() => {
    return () => {
      dispatch(removeEventUpdatedDataByEventId(market?.event?.id));
    };
  }, []);

  return (
    <div className={classNames('biab_featured-markets-content', styles.marketHeader)}>
      <div className={classNames('biab_featured-market-link-holder', styles.wrapper)}>
        <div className="biab_running-ball-list biab_running-ball-mobile">
          <NavLink
            className={classNames('biab_has-icon', styles.link)}
            to={`${SPORT_BASE_URL}/${market.eventType.id}/market/${market.marketId}`}
          >
            <div className={styles.marketHeaderName}>
              <i className={classNames(styles.marketHeaderName__icon, IconsConfig.SPORT_ICONS[market.eventType.id])} />
              {score && <div className={classNames('biab_running-ball-list-time', styles.time)}>{score}</div>}

              <div>
                {isEventUpdatedData && (
                  <span>
                    <span className={classNames('biab_running-ball-list-team biab_eventName', styles.name)}>
                      {homeName}
                    </span>
                    <span className={classNames('biab_running-ball-list-score', styles.score)}>
                      {`${homeScore}-${awayScore}`}
                    </span>
                    <span className={classNames('biab_running-ball-list-team biab_eventName', styles.name)}>
                      {awayName}
                    </span>
                  </span>
                )}
                {!isEventUpdatedData && (
                  <span className={classNames('biab_eventName', styles.name)}>{market.event.name}</span>
                )}
                &nbsp;-&nbsp;
                <span className={classNames('biab_marketName', styles.name)}>{market.marketName}</span>
                {market.event.videoStreamingEnabled && (
                  <VideoStream visible={market.event.videoStreamingEnabled} isLeftSpace eventId={market.event.id} />
                )}
                {market.event.matchStatEnabled && (
                  <MatchStatistics
                    isSoccer={market.eventType.id === SportIds.SOCCER}
                    visible={market.event.matchStatEnabled}
                    isLeftSpace
                    eventId={market.event.id}
                  />
                )}
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default TitleMarketMobile;
