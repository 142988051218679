import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Devices } from 'constants/app';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { getPLTableCurrentPL, getPLTableWorstCasePL } from 'redux/modules/plTable/selectors';

import CurrentPL from '../CurrentPL';

import { mapperPlToTable } from './helpers';

import styles from './styles.module.scss';

interface OverLineTableProps {
  currencyCode: string;
  units: string;
}

const OverLineTable = ({ currencyCode, units }: OverLineTableProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const currentPL = useSelector(getPLTableCurrentPL);
  const worstCasePL = useSelector(getPLTableWorstCasePL);

  const isMobile = device === Devices.MOBILE;
  const plValue = Object.values(currentPL).length ? currentPL : worstCasePL;
  const values = mapperPlToTable(plValue);

  if (isMobile) {
    return (
      <div className={classNames('biab_pl-table-wrapper', styles.mobileContainer)}>
        <table className={classNames('biab_pl-table', styles.mobileTable)}>
          <thead>
            <tr>
              {Object.values(values).map(item => (
                <td key={`${item.min} - ${item.max}`} className={classNames(styles.table__coll, styles.table__topCol)}>
                  {item.min === item.max ? item.min : `${item.min} - ${item.max}`}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.values(values).map(item => (
                <td
                  key={`${item.profitOrLoss}-${item.min}`}
                  className={classNames(styles.table__coll, styles.table__botCol)}
                >
                  <CurrentPL value={item.profitOrLoss} currencyCode={currencyCode} wightNormal />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  return (
    <div className={classNames('biab_pl-table-wrapper', styles.contentContainer)}>
      <table className={classNames('biab_pl-table', styles.table)}>
        <thead>
          <tr>
            <th className={styles.table__title}>{`${t('PLTable.numberOf')} ${t(units)}`}</th>
            {Object.values(values).map(item => (
              <td key={`${item.min} - ${item.max}`} className={classNames(styles.table__coll, styles.table__topCol)}>
                {item.min === item.max ? item.min : `${item.min} - ${item.max}`}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className={styles.table__title}>{t('PLTable.PL')}</th>
            {Object.values(values).map(item => (
              <td
                key={`${item.profitOrLoss}-${item.max}`}
                className={classNames(styles.table__coll, styles.table__botCol)}
              >
                <CurrentPL value={item.profitOrLoss} currencyCode={currencyCode} />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OverLineTable;
