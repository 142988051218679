import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Collapse from 'components/Collapse';
import { ElementNames } from 'constants/app';
import useElementSize from 'hooks/useElementSize';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { getNavigationInitialLoading, getNavigationSports } from 'redux/modules/asianSportsNavigation/selectors';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';
import { LEFT_PANEL_COLLAPSE_GROUP_ASIAN } from 'redux/modules/collapse/constants';

import NavSkeleton from '../Skeleton/NavSkeleton';

import Content from './components/Content';

import styles from './styles.module.scss';

type AsianSportSectionProps = {
  isCashOutPage?: boolean;
};

const AsianSportSection = ({ isCashOutPage = false }: AsianSportSectionProps) => {
  const dispatch = useDispatch();

  const isMobile = useSelector(getIsMobileAsianView);
  const translation = useSelector(getTranslation);
  const sports = useSelector(getNavigationSports);
  const initialLoading = useSelector(getNavigationInitialLoading);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: ElementNames.ASIAN_NAVIGATION_SPORTS_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: ElementNames.ASIAN_NAVIGATION_SPORTS_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div ref={componentRef}>
      {initialLoading ? (
        <NavSkeleton withDelay={150} itemsCount={10} />
      ) : (
        <>
          {!isMobile &&
            !!sports.length &&
            sports.map(s => (
              <Collapse
                key={s.id}
                testId={`sport-${s.id}-section`}
                customClass={styles.sports__collapse}
                group={LEFT_PANEL_COLLAPSE_GROUP_ASIAN}
                collapse={s.id}
                title={s.translations[translation]}
                withoutMargin
                withoutCookie
              >
                <Content sportId={s.id} isCashOutPage={isCashOutPage} />
              </Collapse>
            ))}
        </>
      )}
    </div>
  );
};

export default AsianSportSection;
