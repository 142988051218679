import { ChangeEvent, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import AccountSettingsActions from 'components/AccountSettingsActions/AccountSettingsActions';
import AccountSettingsError from 'components/AccountSettingsError/AccountSettingsError';
import { componentsBranding, mobileComponents } from 'constants/branding';
import { quickBetsTableHeader } from 'constants/quickBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { useQuickBets } from 'hooks/useQuickBets';
import { getCurrency, getIsExchangeGamesEnabled, getIsExchangeSportsEnabled } from 'redux/modules/appConfigs/selectors';
import { removeUpdateSettingsError } from 'redux/modules/user';
import { getIsUpdateSettingsError } from 'redux/modules/user/selectors';

import FloatingLabelInput from '../forms/FloatingLabelInput/FloatingLabelInput';

import styles from './AccountSettingsQuickBets.module.scss';

type AccountSettingsQuickBetsProps = {
  isMobile?: boolean;
};

const AccountSettingsQuickBets = ({ isMobile }: AccountSettingsQuickBetsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { gamesMaxBetSize, gamesMinBetSize, gamesStep, step, minBetSize, maxBetSize } = useSelector(getCurrency);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isUpdateSettingsError = useSelector(getIsUpdateSettingsError);

  const {
    tabs,
    activeTab,
    setActiveTab,
    editMode,
    setEditMode,
    formState,
    handleSetInputValue,
    handleSaveQuickBets,
    handleResetQuickBets,
    handleCancelQuickBets
  } = useQuickBets({ isDesktopSettings: !isMobile });

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const isWithErrors = formState[activeTab.value].errors.some(el => el.translationKey);
  const isTabsEnabled = exchangeSportsEnabled && exchangeGamesEnabled;
  const isSaveBtnDisabled = isWithErrors || !formState[activeTab.value].valuesChanged;

  const headerValuesMap = {
    'min-bet': minBetSize,
    'max-bet': maxBetSize,
    step: step,
    'games-min-bet': gamesMinBetSize,
    'games-max-bet': gamesMaxBetSize,
    'games-step': gamesStep
  };

  const handleSave = () => {
    setIsSaveLoading(true);
    handleSaveQuickBets({
      onFinish: () => {
        setIsSaveLoading(false);
      }
    });
  };

  return (
    <>
      {isTabsEnabled && (
        <div
          className={classNames(styles.quickBets__tabs, {
            [componentsBranding.TAB_CONTAINER]: !isMobile,
            [styles.quickBets__tabs__mobile]: isMobile,
            [mobileComponents.SWITCH_TABS]: isMobile
          })}
        >
          {Object.values(tabs).map(tab => {
            return (
              <button
                key={tab.value}
                className={classNames(styles.quickBets__tabs__tab, {
                  [styles.quickBets__tabs__tab__mobile]: isMobile,
                  [componentsBranding.TAB]: !isMobile,
                  [componentsBranding.ACTIVE]: tab.value === activeTab.value && !isMobile,
                  [styles.quickBets__tabs__tab__active]: tab.value === activeTab.value,
                  [mobileComponents.SELECTED]: isMobile && tab.value === activeTab.value
                })}
                onClick={() => setActiveTab(tab)}
              >
                {t(tab.translationKey)}
              </button>
            );
          })}
        </div>
      )}
      <div
        className={classNames(styles.quickBets__bets__header, {
          [styles.quickBets__bets__header__mobile]: isMobile
        })}
      >
        {quickBetsTableHeader.map(({ id, labelKey }) => (
          <div key={id} className={styles.quickBets__bets__header__col}>
            <p
              className={classNames(styles.quickBets__bets__header__title, {
                [styles.quickBets__bets__header__title__mobile]: isMobile
              })}
            >
              {t(labelKey)}
            </p>
            <QuickBetsHeaderValue
              value={headerValuesMap[`${activeTab.value === tabs.games?.value ? 'games-' : ''}${id}`]}
              isMobile={isMobile}
            />
            {id !== 'step' && (
              <hr
                className={classNames(styles.quickBets__bets__header__col__divider, {
                  [styles.quickBets__bets__header__col__divider__mobile]: isMobile
                })}
              />
            )}
          </div>
        ))}
      </div>
      {[
        [1, 4],
        [2, 5],
        [3, 6]
      ].map((group, groupIndex) => {
        const leftButtonError = formState[activeTab.value].errors[group[0] - 1];
        const rightButtonError = formState[activeTab.value].errors[group[1] - 1];

        return (
          <Fragment key={groupIndex}>
            <div
              className={classNames(styles.quickBets__buttons, {
                [styles.quickBets__buttons__mobile]: isMobile
              })}
            >
              {group.map((value, index) => {
                const buttonValue = value - 1;

                return (
                  <div
                    key={index}
                    className={classNames(styles.quickBets__button__wrapper, {
                      [styles.quickBets__button__wrapper__mobile]: isMobile
                    })}
                  >
                    {!isMobile && (
                      <p className={styles.quickBets__button__label}>
                        {t(`account.settings.quickBets.columns.button${value}`)}
                      </p>
                    )}
                    {isMobile ? (
                      <FloatingLabelInput
                        key={activeTab.value} // Need to re-render floating input and to not show effect within switching between tabs
                        id={buttonValue.toString()}
                        value={formState[activeTab.value].values[buttonValue] ?? ''}
                        label={t(`account.settings.quickBets.columns.button${value}`)}
                        containerClassName={classNames(mobileComponents.INPUT, {
                          [styles.quickBets__floatingInput__disabled]: !editMode[activeTab.value],
                          [styles.quickBets__floatingInput__error]:
                            !!formState[activeTab.value].errors[buttonValue]?.translationKey,
                          [mobileComponents.ERROR]: !!formState[activeTab.value].errors[buttonValue]?.translationKey
                        })}
                        name={buttonValue.toString()}
                        inputClassName={styles.quickBets__floatingInput__input}
                        labelClassName={styles.quickBets__floatingInput__label}
                        labelActiveClassName={styles.quickBets__floatingInput__label__active}
                        disabled={!editMode[activeTab.value]}
                        onChangeValue={event => editMode[activeTab.value] && handleSetInputValue(event, true)}
                      />
                    ) : (
                      <QuickBetsInput
                        name={buttonValue.toString()}
                        className={classNames(styles.quickBets__button__input, {
                          [styles.quickBets__button__input__error]:
                            formState[activeTab.value].errors[buttonValue]?.translationKey,
                          [componentsBranding.FORM_INPUT]: !isMobile,
                          [componentsBranding.ERROR]:
                            formState[activeTab.value].errors[buttonValue]?.translationKey && !isMobile
                        })}
                        disabled={!editMode[activeTab.value]}
                        value={formState[activeTab.value].values[buttonValue] ?? ''}
                        onChange={event => editMode[activeTab.value] && handleSetInputValue(event, true)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            {leftButtonError?.translationKey && (
              <AccountSettingsError
                error={
                  leftButtonError.options
                    ? t(leftButtonError.translationKey, leftButtonError.options)
                    : t(leftButtonError.translationKey)
                }
                isMobile={isMobile}
              />
            )}
            {rightButtonError?.translationKey && (
              <AccountSettingsError
                error={
                  rightButtonError.options
                    ? t(rightButtonError.translationKey, rightButtonError.options)
                    : t(rightButtonError.translationKey)
                }
                isMobile={isMobile}
              />
            )}
          </Fragment>
        );
      })}
      <AccountSettingsActions
        isEditMode={editMode[activeTab.value]}
        onCancel={() => {
          handleCancelQuickBets();
        }}
        onSave={handleSave}
        onEdit={() => setEditMode({ ...editMode, [activeTab.value]: true })}
        onReset={handleResetQuickBets}
        isSaveBtnDisabled={isSaveBtnDisabled}
        isSaveLoading={isSaveLoading}
        isMobile={isMobile}
        containerOrEditClassName={styles.quickBets__actions}
      />
      {isUpdateSettingsError && (
        <AccountSettingsError
          error={t('account.settings.changesNotSaved')}
          onClose={() => dispatch(removeUpdateSettingsError())}
          containerClassName={styles.quickBets__notSavedError}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

export default AccountSettingsQuickBets;

function QuickBetsHeaderValue({ value, isMobile }: { value: number; isMobile?: boolean }) {
  const { noFormattedAmount: parsedValue } = useFormatCurrency(value || 0, '', {
    noCommas: true,
    isCheckIndian: true,
    noRounding: true,
    ignorePrecision: true
  });

  return (
    <p
      className={classNames(styles.quickBets__bets__header__value, {
        [styles.quickBets__bets__header__value__mobile]: isMobile
      })}
    >
      {value ? parsedValue : ''}
    </p>
  );
}

function QuickBetsInput({
  name,
  className,
  disabled,
  onChange,
  value
}: {
  name: string;
  className: string;
  disabled: boolean;
  value: string | number;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
}) {
  const { noFormattedAmount: parsedValue } = useFormatCurrency(value, '', {
    noCommas: true,
    isCheckIndian: true,
    noRounding: true,
    ignorePrecision: true
  });

  return (
    <input
      name={name}
      className={className}
      disabled={disabled}
      value={disabled ? parsedValue : value}
      onChange={onChange}
    />
  );
}
