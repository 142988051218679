import { Fragment, useMemo } from 'react';
import { sortBy } from 'lodash';

import { BetsStatusesTypes, CurrentBetActions } from 'constants/app';
import { MatchTypes } from 'constants/bets';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { BetSide } from 'types/myBets';

import OpenedBet from '../OpenedBet';
import OpenedBetsHeader from '../OpenedBetsHeader';

const OpenedBetsMatchedBySide = ({
  bets,
  side
}: {
  bets: TCurrentBet[];
  side: BetSide;
  isMultiMarketView: boolean;
}) => {
  const currentBets = useMemo(() => sortBy(bets, 'placedDate').reverse(), [bets]);

  const hideOpenedBetsHeader = useMemo(() => {
    return currentBets.every(
      bet => bet.action === CurrentBetActions.CANCELLING && bet.offerState === BetsStatusesTypes.CANCELLED
    );
  }, [currentBets]);

  if (!currentBets.length) {
    return null;
  }

  return (
    <>
      {currentBets.map((bet, index) => (
        <Fragment key={`${bet.offerId}${bet.isCombined ? '_combined' : ''}`}>
          {!hideOpenedBetsHeader && (
            <OpenedBetsHeader
              bet={bet}
              prevBet={currentBets[index - 1] ?? null}
              side={side}
              matchType={MatchTypes.MATCHED}
            />
          )}
          <OpenedBet
            bet={bet}
            matchType={MatchTypes.MATCHED}
            betIndex={0}
            isFirst={index === 0}
            isLast={currentBets.length === index + 1}
            side={side}
          />
        </Fragment>
      ))}
    </>
  );
};

export default OpenedBetsMatchedBySide;
