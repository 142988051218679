import { TAsianMobileNavigationItem } from 'types/asianView';

import { InputFieldTypes } from './betslip';
import { MARKET_TYPES } from './marketTypes';
import { BetSides } from './myBets';

export const MIN_WIDTH_RUNNER = 184;
export const FETCH_COUNT_MARKETS_INTERVAL = 60000;
export const FETCH_EVENTS_COUNTERS_BY_TIME_FRAME_INTERVAL = 60000;
export const MOBILE_VIEW_BY_DROPDOWN_MARGIN = 20;
export const MOBILE_VIEW_BY_DROPDOWN_WIDTH = 340;
export const QUICK_BETTING_LEFT_RIGHT_PADDING = 360;
export const QUICK_BETTING_BOTTOM_PADDING = 200;
export const QUICK_BETTING_PROGRESS_INTERVAL = 1000;
export const QUICK_BETTING_PLACED_BET_LOADING = 1000;
export const MOBILE_PLACEMENT_PROGRESS_INTERVAL = 1000;
export const MOBILE_PLACEMENT_MESSAGES_TIMEOUT = 5000;
export const MOBILE_PLACEMENT_MESSAGES_VALIDATION_TIMEOUT = 10000;

export const QUICK_BETTING_ERROR_NOTIFICATION_REMOVE_TIME_OUT = 10000;
export const QUICK_BETTING_SUCCESS_NOTIFICATION_REMOVE_TIME_OUT = 5000;

export const AsianBettingActions = {
  PLACE: 'PLACE',
  CONFIRM: 'CONFIRM',
  PLACEMENT: 'PLACEMENT',
  PROGRESS: 'PROGRESS',
  PLACED: 'PLACED',
  HIDDEN: 'HIDDEN',
  VALIDATION: 'VALIDATION'
} as const;

export const AsianPlacedQuickBettingStatuses = {
  PLACE: 'PLACE',
  CREATED: 'CREATED',
  PLACED: 'PLACED',
  ERROR: 'ERROR'
} as const;

export const AsianPlacedBetMessageTypes = {
  ERROR: 'ERROR',
  PLACED: 'PLACED',
  PLACED_WITH_BETTER_ODDS: 'PLACED_WITH_BETTER_ODDS'
} as const;

export const AsianBetslipTabs = {
  BET_SLIP: 'BET_SLIP',
  BET_LIST: 'BET_LIST',
  CASH_OUT: 'CASH_OUT'
} as const;

export const AsianEventsCounters = {
  numberOfInPlayEvents: 'numberOfInPlayEvents',
  numberOfTodayEvents: 'numberOfTodayEvents',
  numberOfOutrights: 'numberOfOutrights',
  numberOfEarlyEvents: 'numberOfEarlyEvents'
} as const;

export const AsianMobileNavTypes = {
  LIVE: 'live',
  TODAY: 'today',
  OUTRIGHTS: 'outrights',
  EARLY: 'early'
} as const;

export const AsianSortingTypes = {
  NORMAL: 'NORMAL',
  BY_TIME: 'BY_TIME'
} as const;

export const AsianLineViewTypes = {
  SINGLE: 'singleLine',
  DOUBLE: 'doubleLine'
} as const;

export const AsianCorrectScoreMarkets = {
  CORRECT_SCORE: 'CORRECT_SCORE',
  HALF_TIME_SCORE: 'HALF_TIME_SCORE'
} as const;

export const AsianCorrectScoreRunners = {
  HOME: 'HOME',
  DRAW: 'DRAW',
  AWAY: 'AWAY',
  ANY_OTHER: 'ANY_OTHER',
  OTHER: 'OTHER'
} as const;

export const AsianCorrectScoreAnyOthers = {
  AOH: 'Any Other Home Win',
  AOA: 'Any Other Away Win',
  AOD: 'Any Other Draw'
} as const;

export const AsianViewMarketLinks = {
  HDP_AND_OU: 'hdpAndOu',
  MATCH_ODDS_HALF_TIME: 'matchOddsHalfTime',
  CORRECT_SCORE: 'correctScore',
  ODD_EVEN: 'oddEven',
  TOTAL_GOALS: 'totalGoal',
  HALF_TIME_FULL_TIME: 'halfTimeFullTime',
  OUTRIGHT: 'outright'
} as const;

export const AsianViewPlacementPages = {
  hdpAndOu: 'HDP_OU',
  search: 'SEARCH',
  favs: 'FAVS',
  singleEvent: 'SINGLE_EVENT',
  singleCompetition: 'SINGLE_COMPETITION',
  matchOddsHalfTime: 'MATCH_ODDS',
  correctScore: 'CORRECT_SCORE',
  oddEven: 'ODD_EVEN',
  halfTimeFullTime: 'HALFTIME_FULLTIME',
  totalGoal: 'TOTAL_GOALS',
  outright: 'OUTRIGHTS',
  cashOut: 'CASH_OUT',
  betList: 'BET_LIST'
};

export const AsianViewTimeFilters = {
  LIVE: 'live',
  Today: 'today',
  Early: 'early',
  ALL: 'all'
} as const;

export const AsianViewTimeFrames = {
  ALL: 'all',
  WEEK: 'week',
  DAY_1: 'day1',
  DAY_2: 'day2',
  DAY_3: 'day3',
  DAY_4: 'day4',
  DAY_5: 'day5',
  DAY_6: 'day6',
  DAY_7: 'day7'
} as const;

export const AsianViewSections = {
  TOP: 'top',
  MAIN: 'main',
  FULL_TIME: 'fullTime',
  HALF_TIME: 'halfTime',
  CASH_OUT: 'cashOut'
} as const;

export const AsianViewSortings = {
  NORMAL: 'normal',
  BY_TIME: 'byTime'
} as const;

export const SORTING_FILTER_LABELS = {
  [AsianSortingTypes.NORMAL]: 'asianView.labels.sortingFilter.normal',
  [AsianSortingTypes.BY_TIME]: 'asianView.labels.sortingFilter.byTime'
};

export const SORTING_FILTER_ICONS = {
  [AsianSortingTypes.NORMAL]: 'av-icon av-icon-normal-sorting',
  [AsianSortingTypes.BY_TIME]: 'av-icon av-icon-sorting-by-time'
};

export const LINE_VIEW_FILTER_LABELS = {
  [AsianLineViewTypes.SINGLE]: 'asianView.labels.viewDropdown.singleLine',
  [AsianLineViewTypes.DOUBLE]: 'asianView.labels.viewDropdown.doubleLine'
};

export const LINE_VIEW_FILTER_ICONS = {
  [AsianLineViewTypes.SINGLE]: 'av-icon av-icon-single-line',
  [AsianLineViewTypes.DOUBLE]: 'av-icon av-icon-double-line'
};

export const ASIAN_VIEW_LEAGUES_FILTER = 'ASIAN_VIEW_LEAGUES_FILTER';

export const ASIAN_VIEW_LIST_SIZE = 10;

export const ASIAN_VIEW_PAGE_PARAM = 'page';
export const ASIAN_VIEW_CASH_OUT_TAB_PAGE_PARAM = 'cashOutTabPage';
export const ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE = 'betSlipCashOutPage';

export const ASIAN_VIEW_MIDDLE_SECTION_CLASS = 'biab_middle-section';

export const CORRECT_SCORE_MARKETS_LABELS = {
  [AsianCorrectScoreMarkets.CORRECT_SCORE]: 'asianView.labels.correctScore.fullTime',
  [AsianCorrectScoreMarkets.HALF_TIME_SCORE]: 'asianView.labels.correctScore.firstHalf'
};

export const CORRECT_SCORE_RUNNERS_LABELS = {
  [AsianCorrectScoreRunners.HOME]: 'asianView.labels.correctScore.home',
  [AsianCorrectScoreRunners.DRAW]: 'asianView.labels.correctScore.draw',
  [AsianCorrectScoreRunners.AWAY]: 'asianView.labels.correctScore.away',
  [AsianCorrectScoreRunners.ANY_OTHER]: 'asianView.labels.correctScore.other',
  [AsianCorrectScoreRunners.OTHER]: ''
};

export const CORRECT_SCORE_ANY_OTHER_LABELS = {
  [AsianCorrectScoreAnyOthers.AOH]: 'asianView.labels.market.name.AOH',
  [AsianCorrectScoreAnyOthers.AOA]: 'asianView.labels.market.name.AOA',
  [AsianCorrectScoreAnyOthers.AOD]: 'asianView.labels.market.name.AOD'
};

export const COMPETITION_KEY_DIVIDER = '_';

export const ASIAN_VIEW_TABS = [
  AsianViewSections.MAIN,
  AsianViewSections.FULL_TIME,
  AsianViewSections.HALF_TIME,
  AsianViewSections.CASH_OUT
];

export const ASIAN_FAVOURITE_TYPE_PARAM = 'asianFavouriteType';
export const ASIAN_FAVOURITE_ID_PARAM = 'asianFavouriteId';
export const ASIAN_FAVOURITE_SPORT_ID_PARAM = 'asianFavouriteSportId';

export const ASIAN_POPULAR_LINK_COMPETITION_ID = 'avPopLinkCompetitionId';
export const ASIAN_POPULAR_LINK_SPORT_ID = 'avPopLinkSportId';

export const ASIAN_OUTRIGHT_MARKET_ID = 'avOutrightMarketId';
export const ASIAN_OUTRIGHT_COMPETITION_ID = 'avOutrightCompetitionId';

export const ASIAN_SINGLE_VIEW_COMPETITION_ID = 'avSingleViewCompetitionId';
export const ASIAN_SINGLE_VIEW_EVENT_ID = 'avSingleViewEventId';
export const ASIAN_SINGLE_VIEW_SPORT_ID = 'avSingleViewSportId';
export const COLUMNS_NAMES_HALF_TIME_FULL_TIME = ['HH', 'HD', 'HA', 'DH', 'DD', 'DA', 'AH', 'AD', 'AA'];

export const TOTAL_GOALS_HEADER = ['1+', '2+', '3+', '4+', '5+', '6+', '7+'];

export const ASIAN_VIEW_AUTO_COLLAPSED_RIGHT_SIDE_WIDTH = 1440;

export const ASIAN_VIEW_MOBILE_MIN_WIDTH = 768;

export const ASIAN_VIEW_MOBILE_WIDTH = 1224;

export const ASIAN_BETSLIP_TABS = [
  {
    title: 'asianView.labels.betslip.tabs.betSlip',
    key: AsianBetslipTabs.BET_SLIP
  },
  {
    title: 'asianView.labels.betslip.tabs.betList',
    key: AsianBetslipTabs.BET_LIST
  },
  {
    title: 'asianView.labels.betslip.tabs.cashOut',
    key: AsianBetslipTabs.CASH_OUT
  }
];

export const TAB_COLUMN_COUNTER: Record<string, number | undefined> = {
  [MARKET_TYPES.matchOddsAndOu]: 3,
  [MARKET_TYPES.firstGoalScore]: 3,
  [MARKET_TYPES.totalGoals]: 3
};

export const ASIAN_VIEW_CASH_OUT_LINK_NAME = 'cashOut';

export const ASIAN_VIEW_TABS_LABELS: Record<string, string> = {
  [AsianViewSections.MAIN]: 'asianView.labels.tab.main',
  [AsianViewSections.FULL_TIME]: 'asianView.labels.tab.fullTime',
  [AsianViewSections.HALF_TIME]: 'asianView.labels.tab.firstHalf',
  [ASIAN_VIEW_CASH_OUT_LINK_NAME]: 'asianView.labels.tab.cashOut'
};

export const DESKTOP_NAVIGATION_HEIGHT = 30;
export const MOBILE_LEFT_NAVIGATION_HEIGHT = 300;

export const ASIAN_BETSLIP_PLACEHOLDERS: Record<string, Record<string, string>> = {
  [InputFieldTypes.SIZE]: {
    [BetSides.Back]: 'asianView.labels.betslip.stake',
    [BetSides.Lay]: 'asianView.labels.betslip.profit'
  },
  [InputFieldTypes.PROFIT]: {
    [BetSides.Back]: 'asianView.labels.betslip.profit',
    [BetSides.Lay]: 'asianView.labels.betslip.risk'
  }
};

export const ASIAN_MOBILE_NAVIGATION: TAsianMobileNavigationItem[] = [
  {
    type: AsianMobileNavTypes.LIVE,
    label: 'asianView.labels.timeFilter.live',
    counter: AsianEventsCounters.numberOfInPlayEvents
  },
  {
    type: AsianMobileNavTypes.TODAY,
    label: 'asianView.labels.timeFilter.today',
    counter: AsianEventsCounters.numberOfTodayEvents
  },
  {
    type: AsianMobileNavTypes.OUTRIGHTS,
    label: 'asianView.labels.marketLinks.outright',
    counter: AsianEventsCounters.numberOfOutrights
  },
  {
    type: AsianMobileNavTypes.EARLY,
    label: 'asianView.labels.timeFilter.early',
    counter: AsianEventsCounters.numberOfEarlyEvents
  }
];

export const ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME = 'ASIAN_VIEW_SELECTED_BETS';

export const ASIAN_VIEW_DELETE_MATCHED_BETS_TIMEOUT = 10000;

export const ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE = 'ASIAN_VIEW_PLACED_QUICK_BETS_STORAGE';
export const ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE = 'ASIAN_VIEW_PLACED_MOBILE_BETS_STORAGE';

export const ASIAN_SELECTED_BETS_AMOUNT_LIMIT = 10;

export const ASIAN_VIEW_PL_TABLE_HT_MARKETS = [
  'FIRST_HALF_GOALS_05',
  'FIRST_HALF_GOALS_15',
  'FIRST_HALF_GOALS_25',
  'HALF_TIME',
  'HALF_TIME_SCORE'
];

export const ASIAN_VIEW_PL_TABLE_FT_MARKETS = [
  'ASIAN_HANDICAP',
  'ALT_TOTAL_GOALS',
  'MATCH_ODDS',
  'CORRECT_SCORE',
  'ODD_OR_EVEN',
  'BOTH_TEAMS_TO_SCORE',
  'DOUBLE_CHANCE',
  'TEAM_A_WIN_TO_NIL',
  'TEAM_B_WIN_TO_NIL',
  'OVER_UNDER_05',
  'OVER_UNDER_15',
  'OVER_UNDER_25',
  'OVER_UNDER_35',
  'OVER_UNDER_45',
  'OVER_UNDER_55',
  'OVER_UNDER_65',
  'OVER_UNDER_75',
  'OVER_UNDER_85',
  'MATCH_ODDS_AND_OU_25',
  'DRAW_NO_BET'
];

export const ASIAN_VIEW_PL_TABLE_AVAILABLE_MARKETS =
  ASIAN_VIEW_PL_TABLE_HT_MARKETS.concat(ASIAN_VIEW_PL_TABLE_FT_MARKETS);

export const AV_QUERY_LENGTH_TO_DISPLAY_SEARCH_CONTENT = 3;
export const AV_PL_TABLE_POP_UP_MARKETS_ONE_COLUMN_SIZE = 10;

export const AV_LANDSCAPE_LEFT_PANEL_WIDTH = 111;

export const AV_MOBILE_MARKET_SELECTIONS_COLUMNS_COUNT = 1;
export const AV_OUTRIGHT_DESKTOP_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT = 2;
export const AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_2 = 2; // for screen width 768px - 999px
export const AV_LANDSCAPE_MARKET_SELECTIONS_COLUMNS_COUNT_3 = 3; // for screen width 1000px - 1224px

export const AV_BET_SLIP_ODDS_HIGHLIGHT_TIMEOUT = 3000;

export const DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS = 1;

export const DEFAULT_ASIAN_VIEW_PAGINATION_PAGE = 0;
export const ASIAN_VIEW_BET_SLIP_BET_IDENTIFIER_SEPARATOR = '__';
export const AsianViewSelectedBetStates = {
  selected: 'SELECTED',
  placing: 'PLACING',
  placed: 'PLACED',
  unplaced: 'UNPLACED'
} as const;

export const AsianPopularLinkContexts = {
  ASIAN_VIEW_COMPETITION: 'ASIAN_VIEW_COMPETITION',
  ASIAN_VIEW_POPULAR_LINK: 'ASIAN_VIEW_POPULAR_LINK'
} as const;

export const AsianPopularLinkEntryTypes = {
  COMPETITION: 'COMPETITION',
  CUSTOM_POP_UP: 'CUSTOM_POP_UP'
} as const;
