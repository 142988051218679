import { useCookies } from 'react-cookie';

import { CookieNames } from 'constants/app';
import { ExchangeTypes, ExchangeTypeTabButtons, PLExchangeTypes, PLTabButtons } from 'constants/myBets';
import { PLBetType } from 'types/myBets';

import useAccountProducts from './useAccountProducts';
import useAccountUrlParams from './useAccountUrlParams';

const useActivePLProduct = () => {
  const { isProfitLossPage } = useAccountUrlParams();

  const [cookies] = useCookies([CookieNames.PROFITANDLOSS_BETTYPE]);
  const cookieValue = cookies.PROFITANDLOSS_BETTYPE ?? (cookies.PROFITANDLOSS_BETTYPE as PLBetType);

  const { isAsianViewActive, isSportsEnabled, isGamesEnabled } = useAccountProducts();

  const availableProducts = (isProfitLossPage ? PLTabButtons : ExchangeTypeTabButtons).filter(tab => {
    return (
      (isAsianViewActive && (tab === ExchangeTypes.AsianView || tab === PLExchangeTypes.AsianView)) ||
      (isGamesEnabled && (tab === ExchangeTypes.SportsGames || tab === PLExchangeTypes.Games)) ||
      (isSportsEnabled && (tab === ExchangeTypes.SportsGames || tab === PLExchangeTypes.Sports))
    );
  });

  return availableProducts.includes(cookieValue) ? cookieValue : availableProducts[0];
};

export default useActivePLProduct;
