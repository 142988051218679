import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { unescape } from 'lodash';

import GameDerbyRacingCartItem from 'components/GameDerbyRacingCartItem';
import { GamePages } from 'constants/games';
import { MyBetsLocations } from 'constants/myBets';
import { getSelectedGamePage } from 'redux/modules/games/selectors';
import { TGameData } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface IGameDerbyRacingFirstSection {
  data: TGameData;
}

const GameDerbyRacingFirstSection = ({ data }: IGameDerbyRacingFirstSection) => {
  const { t } = useTranslation();
  const selectedGamePage = useSelector(getSelectedGamePage);
  const isResultPage = selectedGamePage === GamePages.RESULTS;
  const isGamePage = selectedGamePage === GamePages.GAME;
  const location = useLocation();
  const isMyBetPage = location.pathname.includes(MyBetsLocations.MyBets);
  const emptyArray = new Array(3).fill(null);
  const cards = data.properties?.[0]?.value?.split(',') || new Array(12).fill(null);
  const clubs = cards.slice(0, 3);
  const diamonds = cards.slice(3, 6);
  const hearts = cards.slice(6, 9);
  const spades = cards.slice(9, 12);

  const items = [
    {
      name: 'spades',
      data: spades
    },
    {
      name: 'hearts',
      data: hearts
    },
    {
      name: 'clubs',
      data: clubs
    },
    {
      name: 'diamonds',
      data: diamonds
    }
  ];

  const getCards = (values: string[]) => {
    if (values.length) {
      return values;
    }
    return emptyArray;
  };

  return (
    <div
      data-tooltip-id={'tooltip'}
      data-tooltip-html={isGamePage && !isMyBetPage ? unescape(t('tooltip.games.handicapCards')) : ''}
      className={classnames(styles.firstSection, 'biab_games-player', {
        [styles.resultsWrapper]: isResultPage || isMyBetPage,
        'cursor-help': isGamePage && !isMyBetPage
      })}
    >
      <p>{t('games.results.handicapCards')}</p>
      <div className={styles.main}>
        {items.map(item => (
          <div
            key={item.name}
            className={classnames(styles.section, `biab_games-handicap-cards-block biab_suit-${item.name}`)}
          >
            <div className={classnames(styles.iconWrapper, `biab_suit-icon biab_suit-${item.name}-icon`)}>
              <img src={require(`assets/images/games/signs/${item.name}.svg`)} alt="" />
            </div>
            {getCards(item.data).map((v, index) => (
              <GameDerbyRacingCartItem key={`${item.name}-${index}`} value={v} />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameDerbyRacingFirstSection;
