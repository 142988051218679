import { Dispatch, HTMLAttributes, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { map } from 'lodash';

import RadioButton from 'components/RadioButton';
import { PersistenceTypes } from 'constants/bets';
import { betslipBranding, mobileBetslip } from 'constants/branding';
import { BetSides } from 'constants/myBets';
import { getIsMobile } from 'redux/modules/appConfigs/selectors';
import { PersistenceType } from 'types/bets';
import { BetSide } from 'types/myBets';

import styles from './styles.module.scss';

type BetPersistenceTypeProps = {
  offerId: number;
  persistenceType: PersistenceType;
  changePersistenceType: Dispatch<SetStateAction<PersistenceType>>;
  side: BetSide;
  isDisabled: boolean;
  isFancySwapColors?: boolean;
  containerClassName?: HTMLAttributes<HTMLDivElement>['className'];
  textClassName?: HTMLAttributes<HTMLElement>['className'];
};

const BetPersistenceType = ({
  offerId,
  persistenceType,
  changePersistenceType,
  side,
  isDisabled,
  isFancySwapColors,
  containerClassName = '',
  textClassName = ''
}: BetPersistenceTypeProps) => {
  const { t } = useTranslation();

  const isMobile = useSelector(getIsMobile);

  const isBack = side === BetSides.Back;

  const isBackColor = (isFancySwapColors ? !isBack : isBack) && !isDisabled;
  const isLayColor = (isFancySwapColors ? isBack : !isBack) && !isDisabled;

  const handleRadioChange = (typeItem: PersistenceType) => {
    if (!isDisabled) {
      changePersistenceType(typeItem);
    }
  };

  return (
    <div
      className={classNames(
        styles.container,
        betslipBranding.PERSISTENCE_TYPE,
        {
          [styles.container__back]: isBackColor,
          [styles.container__lay]: isLayColor,
          [styles.container__disabled]: isDisabled,
          [mobileBetslip.BET_HEADER]: isMobile,
          [betslipBranding.DISABLED]: isDisabled,
          [betslipBranding.BACK]: isBackColor,
          [betslipBranding.LAY]: isLayColor
        },
        containerClassName
      )}
    >
      <p className={classNames(styles.atInPlay, textClassName)}>{t('betslip.labels.atInPlay')}:</p>
      <div className={styles.container__right}>
        {map(PersistenceTypes, typeItem => (
          <RadioButton
            key={typeItem}
            name={`persistenceType_${offerId}`}
            value={typeItem}
            checked={persistenceType === typeItem}
            disabled={isDisabled}
            onChange={() => handleRadioChange(typeItem)}
          >
            {t(`betslip.labels.${typeItem === PersistenceTypes.LAPSE ? 'cancel' : 'keep'}`)}
          </RadioButton>
        ))}
      </div>
    </div>
  );
};

export default BetPersistenceType;
